import React from 'react';

const ClockIcon = props => {
	return (
		<div id="clockIcon" onClick={(e) => {props.onClick(e)}}>
			<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
				<g id="Final" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="Add-Modules-(Fund-Details,-Statistics-&amp;-Performance)" transform="translate(-1084.000000, -951.000000)" fill="#7E2D40">
						<g id="Group-9" transform="translate(982.000000, 951.000000)">
							<g id="Icon/Clock" transform="translate(102.000000, 0.000000)">
								<g id="Shape">
									<path d="M10,0 C4.48818182,0 0,4.48909091 0,10 C0,15.5109091 4.48909091,20 10,20 C15.5109091,20 20,15.5109091 20,10 C20,4.48909091 15.5109091,0 10,0 Z M10,18.1818182 C5.48909091,18.1818182 1.81818182,14.5109091 1.81818182,10 C1.81818182,5.48909091 5.48909091,1.81818182 10,1.81818182 C14.5109091,1.81818182 18.1818182,5.48909091 18.1818182,10 C18.1818182,14.5109091 14.5109091,18.1818182 10,18.1818182 Z" fill-rule="nonzero"></path>
									<path d="M10.9090909,9.62454545 L10.9090909,4.54545455 C10.9090909,4.04545455 10.5,3.63636364 10,3.63636364 C9.5,3.63636364 9.09090909,4.04545455 9.09090909,4.54545455 L9.09090909,10 C9.09090909,10.2390909 9.18727273,10.4718182 9.35818182,10.6418182 L12.0854545,13.3690909 C12.2618182,13.5454545 12.4945455,13.6363636 12.7272727,13.6363636 C12.96,13.6363636 13.1936364,13.5454545 13.3690909,13.3690909 C13.7209091,13.0172727 13.7209091,12.4372727 13.3690909,12.0845455 L10.9090909,9.62454545 Z" fill-rule="nonzero"></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default ClockIcon;

import React from "react";
import { PropTypes } from "prop-types";

import cloneDeep from "lodash/cloneDeep";

import * as Constant from "../../../utils/constants";
import * as ValidationMessages from '../../../utils/validationMessages';
import * as CustomModalStyles from '../../../utils/customModalStyles';
import * as utilityFunctions from "../../../utils/commonMethods";

import AddIcon from "../../common/Icons/AddIcon";
import ModuleCheckIcon from "../../common/Icons/ModuleCheckIcon";
import LockIcon from "../../common/Icons/LockIcon";
import ModuleAddIcon from "../../common/Icons/ModuleAddIcon";
import Tabs from "../../common/subcomponents/Tabs";
import FundReturnsTooltip from './fundReturnsTooltip';
import ErrorMessageModal from "../../common/modal/ErrorMessageModal";

import ClockIcon from '../../common/Icons/clockIcon.js';

import Clock from '../../../images/Clock.png';

export default class RightRail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionMasterData: {},
      selectedTab: "Fund Details, Statistics & Performance",
      isOpen: false,
      isOpenCM: false,
      isOpenCR: false,
    };
  }

  openAlertModal = (isCM) => {
    if (isCM === true) {
      this.setState({ isOpenCM: true });
    } else {
      this.setState({ isOpen: true });
    }
    
  }

  componentDidMount() {
    document.getElementsByClassName("module-container")[0].addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    document.getElementsByClassName("module-container")[0].removeEventListener('scroll', this.handleScroll);
  }
  
  closeAlertModal = (isCM) => {
    if (isCM === true) {
      this.setState({ isOpenCM: false });
      this.setState({ isOpenCR: false });
    } else {
      this.setState({ isOpen: false });
    }
  }
  
  getDateRange = () => {
    if(this.checkForContributionToRisk()){
    let count = 0;
    let labelText = '';
    let p1Length = this.props.sections.Portfolios[0].Portfolio.length;
    let p2Length = this.props.sections.Portfolios[1].Portfolio.length;
    let dateRangeData = this.props.contributionToRiskDtRange;
    if (p1Length > 0 && p2Length > 0) {
      dateRangeData.forEach(element => {
        if (count === 0 && p1Length > 0) {
          labelText += '<b>Portfolio One:</b> ' + utilityFunctions.convertFromOADate(element.dateBegin) + '-' + utilityFunctions.convertFromOADate(element.dateEnd) + ', ';
        } else if (count > 0 && p2Length > 0) {
          labelText += '<b>Portfolio Two:</b> ' + utilityFunctions.convertFromOADate(element.dateBegin) + '-' + utilityFunctions.convertFromOADate(element.dateEnd);
        }
        count++;
      });
    } else {
      labelText += ( p1Length > 0 ? '<b>Portfolio One:</b> ' : '<b>Portfolio Two:</b> ') + utilityFunctions.convertFromOADate(dateRangeData[0].dateBegin) + '-' + utilityFunctions.convertFromOADate(dateRangeData[0].dateEnd);
    }
    return <div style={{'font-weight':'normal'}} dangerouslySetInnerHTML={{__html: labelText}} >
    </div>;
    }
    return ''; 
  }

  checkForContributionToRisk = () => {
    let enabled =false;
    if (this.props.isFromPortfolio) {
      if(this.props.isHypostateMaintainInitialAlloc || this.props.eventscount === 0){
        enabled = true;
        return enabled;
      }
    }
      return enabled;
  }

  handleScroll = (event) => {
    this.inputRef && this.inputRef.setState({
      show: false
    })
    this.inputRef1 && this.inputRef1.setState({
      show: false
    })
    this.inputRef2 && this.inputRef2.setState({
      show: false
    })
  } 

  UNSAFE_componentWillMount() {
    let stateSection = cloneDeep(Constant.sectionMasterData);
    this.setState({
      sectionMasterData: stateSection
    });
  }

  loadTabUI = (e) => {
    if (this.state.selectedTab !== e.target.innerText) {
      this.setState(
        {
          selectedTab: e.target.innerText
        },
        () => {
          document.getElementsByClassName("module-container")[0].scrollTo(0, 0);
        }
      );
    }
  }

  keepCloning(objectpassed) {
    if (objectpassed === null || typeof objectpassed !== 'object') {
      return objectpassed;
    }
    const temporary = objectpassed.constructor();
    for (const key in objectpassed) {
      if (objectpassed.hasOwnProperty(key)) {
        temporary[key] = this.keepCloning(objectpassed[key]);
      }
    }
    return temporary;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const masterSections = nextProps.sections;
    let sectionsData = this.keepCloning(masterSections);

    //for sorting sections and subsections in the right Rail..
    sectionsData.sections && Array.isArray(sectionsData.sections) &&
      sectionsData.sections.sort((a, b) => {
        return a.sectionid - b.sectionid;
      });
    for (let index = 0; index < sectionsData.sections.length; index++) {
      Array.isArray(sectionsData.sections[index].subsections) &&
        sectionsData.sections[index].subsections.sort((a, b) => {
          return a.id - b.id;
        });
    }
    if(!this.checkForContributionToRisk() && this.props.isFromPortfolio) {
      sectionsData.sections.find(x=> x.sectionname == 'Contribution to Risk').isActive = false;
      sectionsData.sections.find(x=>x.sectionname=='Contribution to Risk').subsections[0].isActive=false;
    }
    
    this.setState({
      sectionMasterData: sectionsData
    });
  }

  enableAddSubsection(item) {
    let enableSubSections = true;
    if (item.sectionname === "Rolling Time Frames" && !this.props.enableFundReturns) {
      enableSubSections = false;
    } else if ((item.sectionname === "Correlation Matrix" && !this.props.enableFundReturnsCM)) {
      enableSubSections = false;
    } 
    return enableSubSections;
  }

  renderSubSections(item, disable) {
    return (
      item.subsections &&
      item.subsections.map(subsection => {
        if(!((subsection.name === 'Sustainability Rating' || subsection.name === 'Sustainability Rating Intl') && Constant.showSustainabilityRating === false))
        return (
          <li className={subsection.isActive ? "border-li" : "cardContainer"}>
            <div style={(item.subsections.length>6 && subsection.id===5)?
            {
              "margin-left":"0px",
              opacity: this.setOpacity(subsection.name, disable, true)
            }:
           {
            opacity: this.setOpacity(subsection.name, disable, true)
          }}>
              <span className={"sub-section-name"} title={subsection.subsectiondisplayname}>
                {item.sectionname == 'Regional Inflection Points - Select a Maximum of 2' ? subsection.name: subsection.subsectiondisplayname}
              </span>
              {/* eslint-disable no-nested-ternary */}
              {subsection.canbeAddedAndLocked ? (
                <div
                  className={"sub-section-icon"}
                  data-name={subsection.name}
                  onClick={this.props.addSections}
                >
                  {subsection.isActive ? <ModuleCheckIcon /> : <AddIcon />}
                  <span>&nbsp;&nbsp;</span>
                  <span className={"sub-section-icon"}>
                    <LockIcon />
                  </span>{" "}
                </div>
              ) : subsection.isLocked ? (
                <span className={"sub-section-icon"}>
                  <LockIcon />
                </span>
              ) : (
                    <div
                      className={"sub-section-icon"}
                      data-name={subsection.name}
                      onClick={this.setOpacity(subsection.name, disable, true) === 0.5 ? null : (this.enableAddSubsection(item) ? this.props.addSections : null)}
                    >
                      {subsection.isActive ? <ModuleCheckIcon /> : <AddIcon />}
                    </div>
                  )}
            </div>
			<div style={(item.subsections.length>6 && subsection.id===5)?
            {
              "margin-left":"0px",
              opacity: this.setOpacity(subsection.name, disable, true)
            }:
           {
            opacity: this.setOpacity(subsection.name, disable, true)
          }}
		  className={this.props.isFromPortfolio ? Constant.portfolioSectionImages[subsection.name]:Constant.sectionImages[subsection.name]} >
			
			</div>
			{this.setOpacity(subsection.name, disable, true) === 0.2 && 
			["Corporate and Soverign", "ESG Risk Score Distribution"].includes(subsection.name) && 
			<span className={"thumbnailText"}>Add Morningstar sustainability Rating first</span>}
          </li>
        ); 
      })
    );
  }

  handleChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    //check if user is unselecting the last checked checkbox...
    if (!isChecked) {
      let checkboxCount = this.props.checkedItems.size;
      if (checkboxCount === 1) {
        this.setState({
          isOpen: true
        });
      } else {
        this.setState({
          isOpen: false
        });
        this.props.setDefaultTimePeriodLabelAndCheckBox(item, isChecked, false, false,"Rolling Time Frames");
      }
    } else {
      this.props.setDefaultTimePeriodLabelAndCheckBox(item, isChecked, false, false, "Rolling Time Frames");
    }
  }

  handleChangeCM = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    //check if user is unselecting the last checked checkbox...
    if (!isChecked) {
      let checkboxCount = this.props.checkedItemsCM.size;
      if (checkboxCount === 1) {
        this.setState({
          isOpenCM: true
        });
      } else {
        this.setState({
          isOpenCM: false
        });
        this.props.setDefaultTimePeriodLabelAndCheckBox(item, isChecked, false, true , "Correlation Matrix" );
      }
    } else {
      this.props.setDefaultTimePeriodLabelAndCheckBox(item, isChecked, false, true ,"Correlation Matrix" );
    }
  }

  
  handleChangeCR = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    //check if user is unselecting the last checked checkbox...
    if (!isChecked) {
      let checkboxCount = this.props.checkedItemsCR.size;
      if (checkboxCount === 1) {
        this.setState({
          isOpenCR: true
        });
      } else {
        this.setState({
          isOpenCR: false
        });
        this.props.setDefaultTimePeriodLabelAndCheckBox(item, isChecked, false, true ,"Contribution to Risk");
      }
    } else {
      this.props.setDefaultTimePeriodLabelAndCheckBox(item, isChecked, false, true, "Contribution to Risk");
    }
  }

  disableSection(item) {
    let disable_sections = [];
    item.forEach(
      section => {
        this.updateDisabledSections(section, disable_sections);
          
		if(section.sectionname === "Sustainability Ratings"){
			let morningstarSRStatus = section.subsections.filter(x => x.name === 'Morningstar Sustainability Ratings')[0].isActive;

      this.checkMorningstarSRStatus(morningstarSRStatus, disable_sections);
		}

        if (section.sectionname === "Regional Inflection Points - Select a Maximum of 2" && section.isActive) {
          let activeSectionsCount = section.subsections.filter(x => x.isActive).length;
          if (activeSectionsCount === 2) {
            section.subsections.forEach(subsec => {
              this.disableInactiveSections(subsec, disable_sections);
            })
          }
        }
      }
    );
    return disable_sections;
  }

  updateDisabledSections = (section, disable_sections) => {
    if (section.sectionname === "Equity & Multi-Asset Inflection Points" && section.isActive) {
      disable_sections.push("FixedIncome");
    }

    if (section.sectionname === "Fixed Income Inflection Points" && section.isActive) {
        disable_sections.push("Equity");
      }

    if (section.sectionname === "Rolling Time Frames" && !this.props.enableFundReturns) {
        disable_sections.push("FundReturns");
      }

    if (section.sectionname === "Correlation Matrix" && !this.props.enableFundReturnsCM) { 
        disable_sections.push("CorrelationMatrix");
      }

    if (section.sectionname === "Contribution to Risk" && !this.checkForContributionToRisk()) {
        disable_sections.push("ContributiontoRisk");
      }
  }

  checkMorningstarSRStatus = (morningstarSRStatus, disable_sections) => {
    if (!morningstarSRStatus) {
      disable_sections.push("Corporate and Soverign") &&
      disable_sections.push("ESG Risk Score Distribution");
    }
  }

  disableInactiveSections = (subsec, disable_sections) => {
    if (!subsec.isActive) {
      if (subsec.name == 'United States Market Recovery') {
        disable_sections.push('UnitedStatesMarketRecovery');
      } else if (subsec.name == 'Global Market Recovery') {
        disable_sections.push('GlobalMarketRecovery');
      } else if (subsec.name == 'European Market Recovery') {
        disable_sections.push('EuropeanMarketRecovery');
      } else if (subsec.name == 'Fixed Income Inflection Points') {
        disable_sections.push('FixedIncomeInflectionPoints');
      }
    }
  }

  setOpacity(sectionname, disable_sections, isSubSectionName) {
    let opacity = 1;

    if (isSubSectionName) {
      if (sectionname === "United States Market Recovery" && disable_sections.includes("UnitedStatesMarketRecovery")) {
        opacity = 0.5;
      }
      if (sectionname === "Global Market Recovery" && disable_sections.includes("GlobalMarketRecovery")) {
        opacity = 0.5;
      }
      if (sectionname === "European Market Recovery" && disable_sections.includes("EuropeanMarketRecovery")) {
        opacity = 0.5;
      }
      if (sectionname === "Fixed Income Inflection Points" && disable_sections.includes("FixedIncomeInflectionPoints")) {
        opacity = 0.5;
      }
	  if (sectionname === "Corporate and Soverign" && disable_sections.includes("Corporate and Soverign")) {
        opacity = 0.2;
      }
	  if (sectionname === "ESG Risk Score Distribution" && disable_sections.includes("ESG Risk Score Distribution")) {
        opacity = 0.2;
      }
    } else {

      if (sectionname === "Equity & Multi-Asset Inflection Points" && disable_sections.includes("Equity")) {
        opacity = 0.5;
      }
      if (sectionname === "Fixed Income Inflection Points" && disable_sections.includes("FixedIncome")) {
        opacity = 0.5;
      }
      if (sectionname === "Rolling Time Frames" && disable_sections.includes("FundReturns")) {
        opacity = 0.5;
	}
	if (sectionname === "Correlation Matrix" && disable_sections.includes("CorrelationMatrix")) {
	  opacity = 0.5;
	}
      if (sectionname === "Contribution to Risk" && disable_sections.includes("ContributiontoRisk")) {
        opacity = 0.5;
      }
    }
    return opacity;
  }

  setTimePeriodHeader() {
    let labelText = "";
    if (this.props.enableFundReturns) {
      labelText = this.props.timePeriodDisplayLabel;
    }
    return labelText;
  }

  setTimePeriodHeaderCM() {
    let labelText = "";
    if (this.props.enableFundReturnsCM) {
      labelText = this.props.timePeriodDisplayLabelCM;
    }
    return labelText;
  }

  setTimePeriodHeaderCR() {
    let labelText = "";
    if (this.props.enableFundReturnsCR) {
      labelText = this.props.timePeriodDisplayLabelCR;
    }
    return labelText;
  }

  enabledSPSubSection = (item) => {
    if (!this.props.isFromPortfolio && this.props.isInternationalUser) {
      item.subsections = item.subsections.filter(subsection => subsection.name !== 'Total & Load Adjusted Returns')
    }

    return item;
  }

  render() {
    const tabsListFundCompare = [
      "Fund Details, Statistics & Performance",
      "Sustainability Ratings",
      "Fixed Income & Equity Overview",
      "Market Inflection Points"
    ];
    const tabsList = [
      "Fund Details, Statistics & Performance",
      "Fixed Income & Equity Overview",
      "Market Inflection Points"
    ];

    const svgStyles = {
      "vertical-align": "sub"
    };

    let disableTimePeriod = { "float": "right" };
    if(!this.checkForContributionToRisk()){
        disableTimePeriod = {
          'pointer-events': 'none',
          opacity: 0.4,
          'float': 'right'
    };
  }
    let disable = this.disableSection(this.state.sectionMasterData.sections);

    return (
      <div>
        <Tabs
          tabsList={this.props.isFromPortfolio ? tabsList : tabsListFundCompare}
          onClick={this.loadTabUI}
          selectedTab={this.state.selectedTab}
        />
        <div className={"module-container"}>
          <ErrorMessageModal
            isOpen={this.state.isOpen}
            customStyles={CustomModalStyles.ErrorMessageModalStyles}
            closeAlertModal={() => {this.closeAlertModal(false)}}
            openAlertModal={() => {this.openAlertModal(false)}}
            errorMessage={ValidationMessages.requiredRollingPeriod}
            requiredErrorHeader={ValidationMessages.requiredErrorHeader}
          />
          <ErrorMessageModal
            isOpen={this.state.isOpenCM}
            customStyles={CustomModalStyles.ErrorMessageModalStyles}
            closeAlertModal={() => {this.closeAlertModal(true, "CM")}}
            openAlertModal={() => {this.openAlertModal(true , "CM")}}
            errorMessage={ValidationMessages.requiredTimePeriod}
            requiredErrorHeader={ValidationMessages.requiredTimePeriodErrorHeader}
          />
            <ErrorMessageModal
            isOpen={this.state.isOpenCR}
            customStyles={CustomModalStyles.ErrorMessageModalStyles}
            closeAlertModal={() => {this.closeAlertModal(true,"CR" )}}
            openAlertModal={() => {this.openAlertModal(true,"CR")}}
            errorMessage={ValidationMessages.requiredTimePeriod}
            requiredErrorHeader={ValidationMessages.requiredTimePeriodErrorHeader}
          />
          {this.state.sectionMasterData.sections.map(item => (
            <div
              role="presentation"
              className={"module-section"}
              style={{
                display:
                  item.tabName === this.state.selectedTab ? "block" : "none",
                opacity: this.setOpacity(item.sectionname, disable, false)
              }}
            >
              <div className={"divSection"}>
                <span className={"labelHead"}> {item.sectiondisplayname}
                </span>

                {item.isLocked ? (
                  <span className={"icon"}>
                    <LockIcon />
                  </span>
                ) : (
                    <span
                      className={"icon"}
                      data-name={item.sectionname}
                      onClick={this.setOpacity(item.sectionname, disable, false) === 0.5 ? null : this.props.addSections}
                      style={{display:item.sectionname === "Regional Inflection Points - Select a Maximum of 2" ? "none" : "block"}}
                    >
                      Add Section{" "}
                      {item.isActive ? (
                        <ModuleCheckIcon style={svgStyles} />
                      ) : (
                          <AddIcon style={svgStyles} />
                        )}
                    </span>
                  )}


                {item.sectionname === "Rolling Time Frames" ?
                  <div style={{ "float": "right" }}>
                    <div style={{ display: 'block' }}>
                      <span className={'labelTimePeriod'}>{this.setTimePeriodHeader()}</span>
                      <span className={"clockTimePeriod"}>
                        {this.props.enableFundReturns ?
                          <FundReturnsTooltip checkboxes={this.props.timePeriodOptions}
                            checkedItems={this.props.checkedItems}
                            enableClick={this.props.enableFundReturns}
                            handleChange={this.handleChange}
                            label ="Select Rolling Period"
                            setRef={(component) =>
                              (this.inputRef = component)}
                          />
                          :
                          <img src={Clock} />
                        }
                      </span>
                    </div>
                  </div>
                  : <span> </span>
                }

                {item.sectionname === "Correlation Matrix" ?
                  <div style={{ "float": "right" }}>
                    <div style={{ display: 'block' }}>
                      <span className={'labelTimePeriod'}>{this.setTimePeriodHeaderCM()}</span>
                      <span className={"clockTimePeriod"}>
                        {this.props.enableFundReturnsCM ?
                          <FundReturnsTooltip checkboxes={this.props.timePeriodOptionsCM}
                            checkedItems={this.props.checkedItemsCM}
                            enableClick={this.props.enableFundReturnsCM}
                            handleChange={this.handleChangeCM}
                            label ="Select Time Period"
                            setRef={(component) =>
                              (this.inputRef1 = component)}
                          />
                          :
                          <img src={Clock} />
                        }
                      </span>
                    </div>
                  </div>
                  : <span> </span>
                }
                {item.sectionname === "Contribution to Risk" ?
                  <div style={disableTimePeriod}>
                    <div style={{ display: 'block' }}>
                    <span className={'labelTimePeriod'}>{this.setTimePeriodHeaderCR()}</span>
                      <span className={"clockTimePeriod"}>
                        {this.props.enableFundReturnsCR ?
                          <FundReturnsTooltip checkboxes={this.props.timePeriodOptionsCR}
                            checkedItems={this.props.checkedItemsCR}
                            enableClick={this.props.enableFundReturnsCR}
                            handleChange={this.handleChangeCR}
                            label ="Select Time Period"
                            setRef={(component) =>
                              (this.inputRef2 = component)}
                          />
                          :
                          <img src={Clock} />
                        }
                      </span>
                    </div>
                  </div>
                  : <span> </span>
                }
              </div>
              {item.sectionname === "Standardized Performance"
                ?
                <ul className={"sub-sections"}>{this.renderSubSections(this.enabledSPSubSection(item), disable)}</ul>
                :
                <ul className={"sub-sections"}>{this.renderSubSections(item, disable)}</ul>
              }
              
              <div>
                { (!this.checkForContributionToRisk() && item.sectionname === "Contribution to Risk") ? 
                <p className={'validationmsg'}> 
                 {ValidationMessages.ContributioToRiskModuleDisableMsg} 
                 </p>
                :
                  (!this.props.enableFundReturns && item.sectionname === "Rolling Time Frames") ||
                 (!this.props.enableFundReturnsCM && item.sectionname === "Correlation Matrix") 
                 ? 
                 <p className={'validationmsg'}> 
                 {ValidationMessages.FundReturnsInsufficientDataMsg} 
                 </p> : ""
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
RightRail.propTypes = {
  addSections: PropTypes.func,
  sections: PropTypes.object,
  timePeriodOptions: PropTypes.object,
  timePeriodDisplayLabel: PropTypes.string,
  checkedItems: PropTypes.object,
  setDefaultTimePeriodLabelAndCheckBox: PropTypes.func
};


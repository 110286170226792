import React from 'react';
import { PropTypes } from 'prop-types';

import { AsyncTypeahead,Token } from 'react-bootstrap-typeahead';
import { auto } from '../../../../node_modules/react-bootstrap-typeahead/css/Typeahead.min.css';
import Menu from '../../../../node_modules/react-bootstrap-typeahead/lib/Menu.react';
import MenuItem from '../../../../node_modules/react-bootstrap-typeahead/lib/MenuItem.react';
import Highlighter from '../../../../node_modules/react-bootstrap-typeahead/lib/Highlighter.react';
import { groupBy, map } from 'lodash';

import searchInputStyles from '../../../styles/components/selectFundComparison/fundSearchInput.scss';

export default class FundSearchInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			searchText: '',
			options: [],
			emptyLabel: ''
		};
	}
	componentDidUpdate(prevProps) {
		//Iterate over selected state values
		this.props.isInternationalUser  && this.props.selected && this.props.selected.forEach(item => {
			// Find the element with data-name attribute matching the name property
			const element = document.querySelector(`.data-pill[data-xid="${item.xid}"]`);
			// If the element is found, change its background color to grey
			if (item.suggested) {
				element.parentNode.style.backgroundColor = 'grey'
			} else{
				element.parentNode.style.backgroundColor = '#7e2d40'
			}
		});
		if (prevProps.selected !== this.props.selected) {
			// Perform your desired actions here
			return true;
		}
		return false;
	}
		// Add your other logic here
	
	_renderMenu(results, menuProps) {
		let idx = 0;
		const grouped = groupBy(results, r => r.region);
		const items = Object.keys(grouped).map(region => {
			return [
				<Menu.Header key={`${region}`}>{region}</Menu.Header>,
				map(grouped[region], state => {
					const item = (
						<MenuItem key={idx} option={state} position={idx}>
							<div className={'menu-left'}>
								{
									state.name && state.name.includes('®')
										? <Highlighter search={menuProps.text.toUpperCase().replace(
											state.name.substring(state.name.substring(-1, state.name.indexOf('®')).lastIndexOf(' ') + 1, state.name.indexOf('®')),
											state.name.substring(state.name.substring(-1, state.name.indexOf('®')).lastIndexOf(' ') + 1, state.name.indexOf('®') + 1)
										)}>{state.name}</Highlighter> :
										<Highlighter search={menuProps.text}>{state.name}</Highlighter>
								}
							</div>
							<div className={'menu-right'}>
								<Highlighter search={`${state.isInternationalUser ? menuProps.text === state.symbol ? state.symbol : "" : menuProps.text}`}>
									{state.symbol}
								</Highlighter>
							</div>
						</MenuItem>
					);

					idx++;
					return item;
				})
			];
		});

		return <Menu {...menuProps}>{items}</Menu>;
	}

	_handleSearch = (query) => {
		const value = this.props.isInternationalUser ? query.trim(): query;
		let labelText = 'Looking for funds';
		switch (this.props.fundtype) {
			case 'MFS Funds':
				labelText = 'Looking for MFS funds';
				break;
			case 'MVP':
				labelText = 'Looking for Funds & ETFs';
				break;
			default:
				labelText = 'Looking for Funds & ETFs';
		}
		if(this.props.isInternationalUser){
			this.setState({ isLoading: value.length > 0 ? true : false, selectedText: query, emptyLabel: value.length > 0 ? labelText : 'No matches found.'});
		}else{
			this.setState({ isLoading: true, selectedText: query, emptyLabel: labelText });
		}

		if (value && value.length > 0) {
			this.props.requestSymbolSearchApi(value, this.props.fundtype);
			this.props.removeUndoNotifications()
		}
	}

	_inputChange = (text) => {
		let labelText = 'Looking for funds';
		this.props.removeUndoNotifications()
		switch (this.props.fundtype) {
			case 'MFS Funds':
				labelText = 'Looking for MFS funds';
				break;
			case 'MVP':
				labelText = 'Looking for Funds & ETFs';
				break;
			default:
				labelText = 'Looking for Funds & ETFs';
		}
		this.setState({ options: [], emptyLabel: labelText, searchText: text.toLowerCase() });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.options !== nextProps.options && nextProps.options.length !== 0) {
			let ddlOptions = [];
			nextProps.options.forEach(item => {
				item.isInternationalUser = this.props.isInternationalUser;
				if (this.props.isInternationalUser) {
					item.name = item.name.replace('®','');
				}
				ddlOptions.push(item);
			});
			this.setState({
				isLoading: false,
				options: ddlOptions
			});
		}

		if (nextProps.options && nextProps.options.length === 0) {
			this.setState({
				isLoading: false,
				emptyLabel: 'No matches found.'
			});
		}

		if (nextProps && nextProps.disableSearchInput) {
			this._typeahead.clear();
		}
		if (nextProps && nextProps.clearSearchInput) {
			this._typeahead.clear();
		}
	}

	_renderToken = (option, props, index) => {
		return (
		  <Token
			key={index}
			onRemove={this.props.removeSearchedFund}>
			<div onClick={(e) => this.selectPill(e)} className={this.props.isInternationalUser ? 'data-pill' : 'us-data-pill'} data-fundName={`${option.name}`}  data-xid={`${option.xid}`} title={`${this.props.isInternationalUser ? option.name : option.symbol}`} data-symbol={`${option.symbol}`}>{`${this.props.isInternationalUser ? option.name : option.symbol}`}</div>
		  </Token>
		);
	  }
	  selectPill = (e) => {
		// Handle the removal of the extra pill-like token
		if (this.props.isInternationalUser && e.target.parentNode.style.backgroundColor == 'grey' ) {
			e.target.parentNode.style.backgroundColor = '';
			let customPill = {
				//name: e.target.getAttribute('data-fundName'),
				symbol: e.target.getAttribute('data-symbol'),
				xid: e.target.getAttribute('data-xid'),
				//region:'',
				//isInternationalUser: true,
				//suggested: false
			}
			// let funds = [];
			// funds.push(customPill)
			// funds = [...funds,...this.props.selected];
			this.props.addSuggestedFund(customPill.xid, customPill.symbol);
		}
	  }

	render() {
		let labelText = 'Looking for funds';
		
		switch (this.props.fundtype) {
			case 'MFS Funds':
				labelText = 'Looking for MFS funds';
				break;
			case 'MVP':
				labelText = 'Looking for Funds & ETFs';
				break;
			default:
				labelText = 'Looking for Funds & ETFs';
		}
		return (
			<div className={'fundSearchInput'}>
				<AsyncTypeahead
					labelKey={option => `${option.symbol}`}
					filterBy={this.props.isInternationalUser ? ['symbol', 'name'] : ['symbol', 'xid']}
					renderMenu={this._renderMenu}
					multiple
					options={this.state.options}
					placeholder={this.props.isInternationalUser ? "Search by fund name or ISIN. If searching by ISIN, the complete ISIN is required." : "Search by name or symbol."}
					minLength={1}
					delay={1000}
					caseSensitive={false}
					maxHeight={'450px'}
					onChange={this.props.GetSelectedFunds}
					ref={component =>
						(this._typeahead = component
							? component.getInstance()
							: this._typeahead)
					}
					onKeyDown={this.props.actualSearch}
					onSearch={this._handleSearch}
					clearButton
					isLoading={this.state.isLoading}
					useCache={false}
					promptText={labelText}
					searchText={labelText}
					ignoreDiacritics={true}
					emptyLabel={this.state.emptyLabel}
					onInputChange={this._inputChange}
					disabled={this.props.disableSearchInput}
					selectHintOnEnter
					renderToken={this._renderToken}
					selected={this.props.selected}
				/>
			</div>
		);
	}
}

FundSearchInput.PropTypes = {
	options: PropTypes.object,
	GetSelectedFunds: PropTypes.func,
	enableFilterScreenerIfSearchEmptyOnFocus: PropTypes.func,
	actualSearch: PropTypes.func,
	requestSymbolSearchApi: PropTypes.func,
	fundtype: PropTypes.string,
	disableSearchInput: PropTypes.boolean,
	clearSearchInput: PropTypes.boolean,
	removeSearchedFund: PropTypes.func,
	selected: PropTypes.object,
	isPortfolio: PropTypes.bool, 
	removeUndoNotifications: PropTypes.func,
	addSuggestedFund: PropTypes.func
};

import Types from './types';
import * as urlMethods from "../utils/urlMethods";
import * as format from '../utils/format'

const recieveSymbolSearchApi = response => ({
	type: Types.RECIEVE_SYMBOLSEARCH,
	response
});

const setAccessToken = accessToken => ({
	type: Types.SET_ACCESS_TOKEN,
	accessToken: accessToken
});

const receiveFundComparisonPDF = response => ({
	type: Types.RECEIVE_FUNDCOMPARISON_PDF,
	response
});

const receiveFundComparisonPDFOnSelectModal = response => ({
	type: Types.RECEIVE_FUNDCOMPARISON_PDF_ON_SELECTMODAL,
	response
});

const receiveSuccessSaveTemplateDetailsApi = response => ({
	type: Types.RECEIVE_SUCCESS_SAVETEMPLATEDETAILS,
	response
});

const receiveSuccessSaveTemplateApi = response => ({
	type: Types.RECEIVE_SUCCESS_SAVETEMPLATE,
	response
});

const receiveSuccessGetTemplatesApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_TEMPLATES,
	response
});

const receiveSuccessGetTemplatesByIdApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_TEMPLATES_BY_ID,
	response
});

const receiveSuccessDeleteTemplateApi = response => ({
	type: Types.RECEIVE_SUCCESS_DELETETEMPLATE,
	response
});

const receiveSuccessGetScreenerDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_SCREENERDATA,
	response
});

const receiveSuccessGetScreenerDataOnCategoryNamesApi = response => ({
	type: Types.RECEIVE_SUCCESS_SCREENERDATA_MSTARCATEGORY,
	response
});

const receiveSuccessGetScreenerDataFundNameApi = response => ({
	type: Types.RECEIVE_SUCCESS_SCREENERDATAFUNDNAME,
	response
});

const receiveSuccessGetFundPerformanceAsOfDateeApi = response => ({
	type: Types.RECEIVE_SUCCESS_SCREENERFUND_PERFORMANCEASOFDATE,
	response
});

const receiveSuccessScreenerMasterDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_SCREENER_MASTER_DATA,
	response
});


const receiveSuccessValidRollingReturnsDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_VALID_ROLLING_RETURNS_DATA,
	response
});

const receiveSuccessValidCMDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_VALID_CM_DATA,
	response
});

const receiveDecodeApi = response => ({
	type: Types.RECIEVE_DECODE,
	response
});

const onGenericApiFailure = response => ({
	type: Types.GENERIC_FAILURE,
	data: response.status
});

const receiveGetBenchmarksApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_BENCHMARKS,
	response
});

const receiveGetPrimaryBenchmarkApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_PRIMARY_BENCHMARK,
	response
});

const receiveSuccessScreenerFiltersDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_SCREENERFILTERS_DATA,
	response
});


const requestFundComparisonPdf = inputParams => ({
	type: Types.REQUEST_FUNDCOMPARISON_PDF_API,
	endPoint: `/GenerateFundComparisonPDF?containerId=${inputParams.containerId}&reportId=${inputParams.reportId}&environmentName=${urlMethods.getEnvironmentName()}&showReportErrors=${format.getQueryString('..showdebuginfo..')}`,
	params:
		inputParams.templateData,
	ajaxType: 'POST',
	onSuccess: receiveFundComparisonPDF
});

const requestFundComparisonPdfonSelectModal = inputParams => ({
	type: Types.REQUEST_FUNDCOMPARISON_PDF_API,
	endPoint: `/GenerateFundComparisonPDF?containerId=${inputParams.containerId}&reportId=${inputParams.reportId}&environmentName=${urlMethods.getEnvironmentName()}&showReportErrors=${format.getQueryString('..showdebuginfo..')}`,
	params:
		inputParams.templateData,
	ajaxType: 'POST',
	onSuccess: receiveFundComparisonPDFOnSelectModal
});

const requestSaveTemplateDetailsApi = templateData => ({
	type: Types.API_REQUEST_SAVETEMPLATEDETAILS_API,
	endPoint: '/saveTemplate',
	params: templateData,
	ajaxType: 'POST',
	onSuccess: receiveSuccessSaveTemplateDetailsApi
});


const requestSaveTemplateApi = templateData => ({
	type: Types.API_REQUEST_SAVETEMPLATE_API,
	endPoint: '/saveTemplate',
	params: templateData,
	ajaxType: 'POST',
	onSuccess: receiveSuccessSaveTemplateApi
});

const requestGetTemplatesApi = (reportType) => ({
	type: Types.API_REQUEST_SUCCESS_GET_TEMPLATES_API,
	endPoint: '/getTemplates',
	params: { templateId: 0, reportType: reportType },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetTemplatesApi
});

const requestGetTemplatesByIdApi = (templateId, reportType) => ({
	type: Types.RECEIVE_SUCCESS_GET_TEMPLATES_BY_ID_API,
	endPoint: '/getTemplates',
	params: { templateId: templateId, reportType: reportType },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetTemplatesByIdApi
});


const requestScreenerDataApi = (screenerType, screenInput, _isInternationalUser, _currency) => ({
	type: Types.REQUEST_SUCCESS_GET_SCREENERDATA_API,
	endPoint: `${'/screen' + '?type='}${screenerType}&isInternationalUser=${_isInternationalUser}&currency=${_currency}`,
	params: screenInput,
	ajaxType: 'POST',
	onSuccess: receiveSuccessGetScreenerDataApi
});

const requestScreenerMstarCategoryDataApi = (msdMorningstarCategoryName, _currency) => ({
	type: Types.REQUEST_GET_TOPFUNDSBYNETASSEST_API,
	endPoint: '/getTopFundsbyNetAssest',
	params: { msdMorningstarCategoryName: msdMorningstarCategoryName, currency: _currency, noOfResults: 5  },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetScreenerDataOnCategoryNamesApi
});

const requestScreenerDataFundNameApi = (screenerType, screenInput, _isInternationalUser, _currency) => ({
	type: Types.REQUEST_SUCCESS_GET_SCREENERDATAFUNDNAME_API,
	endPoint: `${'/screen' + '?type='}${screenerType}&isInternationalUser=${_isInternationalUser}&currency=${_currency}`,
	params: screenInput,
	ajaxType: 'POST',
	onSuccess: receiveSuccessGetScreenerDataFundNameApi
});

const requestScreenerGetFundPerformanceAsOfDateApi = (screenerType, screenInput, _isInternationalUser, _currency) => ({
	type: Types.REQUEST_SUCCESS_GET_SCREENERFUND_PERFORMANCEASOFDATE_API,
	endPoint: `${'/screen' + '?type='}${screenerType}&isInternationalUser=${_isInternationalUser}&currency=${_currency}`,
	params: screenInput,
	ajaxType: 'POST',
	onSuccess: receiveSuccessGetFundPerformanceAsOfDateeApi
});

const requestGetScreenerMasterDataApi = (fieldNames, type, _isInternationalUser) => ({
	type: Types.REQUEST_SUCCESS_GET_SCREENER_MASTER_DATA_API,
	endPoint: '/getScreenerMasterFieldValues',
	params: { field: fieldNames, type: type, isInternationalUser: _isInternationalUser },
	ajaxType: 'GET',
	onSuccess: receiveSuccessScreenerMasterDataApi
});


const requestValidRollingReturnPeriodDataApi = (screenInput, _isInternationalUser, _fundShareClassIds, _benchmarkWsodIssue) => ({
	type: Types.REQUEST_SUCCESS_GET_VALID_ROLLING_RETURNS_DATA_API,
	endPoint: '/getCommonDataPeriod',
	params: {wsodIssueList : screenInput, isInternationalUser: _isInternationalUser, fundShareClassIds: _fundShareClassIds, benchmarkWsodIssue: _benchmarkWsodIssue },
	ajaxType: 'GET',
	onSuccess: receiveSuccessValidRollingReturnsDataApi
});

const requestValidCMPeriodDataApi = (screenInput, _isInternationalUser, _fundShareClassIds, _benchmarkWsodIssue) => ({
	type: Types.REQUEST_SUCCESS_GET_VALID_ROLLING_RETURNS_DATA_API,
	endPoint: '/getCommonDataPeriodCM',
	params: {wsodIssueList : screenInput, isInternationalUser: _isInternationalUser, fundShareClassIds: _fundShareClassIds, benchmarkWsodIssue: _benchmarkWsodIssue },
	ajaxType: 'GET',
	onSuccess: receiveSuccessValidCMDataApi
});

const requestDeleteTemplateApi = templateID => ({
	type: Types.API_REQUEST_DELETETEMPLATE_API,
	endPoint: '/deleteTemplate',
	params: { templateId: templateID },
	ajaxType: 'DELETE',
	onSuccess: receiveSuccessDeleteTemplateApi
});

const requestSymbolSearchApi = (input, fundtype) => ({
	type: Types.REQUEST_SYMBOLSEARCH_API,
	endPoint: '/xref/search',
	params: { searchText: input, fundType: fundtype },
	ajaxType: 'GET',
	onSuccess: recieveSymbolSearchApi
});

const requestDecodeApi = input => ({
	type: Types.API_REQUEST_DECODE_API,
	endPoint: '/xref/decode',
	params: { key: input },
	ajaxType: 'GET',
	onSuccess: receiveDecodeApi
});

const requestGetBenchmarksApi = (input, _isMFSInternational, _currency) => ({
	type: Types.API_REQUEST_GET_BENCHMARKS_API,
	endPoint: '/getBenchmarks',
	params: { searchString: input, isMFSInternational: _isMFSInternational, currency: _currency },
	ajaxType: 'GET',
	onSuccess: receiveGetBenchmarksApi
});

const requestGetPrimaryBenchmarkApi = (input, _isMFSInternational, _currency) => ({
	type: Types.API_REQUEST_GET_PRIMARY_BENCHMARK_API,
	endPoint: '/getPrimaryBenchmarks',
	params: { wsodIssueList: input, isMFSInternational: _isMFSInternational, currency: _currency  },
	ajaxType: 'GET',
	onSuccess: receiveGetPrimaryBenchmarkApi
});

const requestGetMfsPrimaryBenchmarkApi = (input, _isMFSInternational, _currency) => ({
	type: Types.API_REQUEST_GET_PRIMARY_BENCHMARK_API,
	endPoint: '/getPrimaryBenchmarks',
	params: { wsodIssueList: input, isMFSInternational: _isMFSInternational, currency: _currency  },
	ajaxType: 'GET',
	onSuccess: receiveGetMfsPrimaryBenchmarkApi
});

const receiveGetMfsPrimaryBenchmarkApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_MFS_PRIMARY_BENCHMARK,
	response
});

const setTemplateDataset = response => ({
	type: Types.SET_TEMPLATE_DATASET,
	response
});

const setUserType = response => ({
	type: Types.SET_USER_TYPE,
	response
});

const updateTemplateInternalDetails = response => ({
	type: Types.UPDATE_TEMPLATE_INTERNAL_DETAILS,
	response
});

const resetScreenerFiltersOutput = response => ({
	type: Types.RESET_SCREENER_FILTERS_OUTPUT,
	response
});


const requestScreenerFiltersDataApi = (screenerType, screenInput, _isInternationalUser) => ({
	type: Types.REQUEST_SUCCESS_GET_SCREENERDATA_API,	
	endPoint: `${'/screen' + '?type='}${screenerType}&isInternationalUser=${_isInternationalUser}`,
	params: screenInput,
	ajaxType: 'POST',
	onSuccess: receiveSuccessScreenerFiltersDataApi
});

const requestMfsSiteInactiveFlag = (input) => ({
	type: Types.API_REQUEST_MFSSITEINACTIVE_API,
	endPoint: '/isMfsSiteInactive?access_token=demo-token',
	params: '',
	ajaxType: 'GET',
	onSuccess: recieveSuccessMfsInactiveFlagApi
  })

  const recieveSuccessMfsInactiveFlagApi = response => ({
	type: Types.RECEIVE_SUCCESS__MFSSITEINACTIVEFLAG,
	response
  })

  const requestMfsUserData = () => ({
	type: Types.API_REQUEST_MFS_USER_DATA_API,
	endPoint: '/userData',
	ajaxType: 'GET',
	onSuccess: recieveSuccessMfsUserDataApi
  })

  const recieveSuccessMfsUserDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_MFS_USER_DATA,
	response
  })

  const requestXrefByTerm = (term) => ({
	type: Types.API_REQUEST_XREF_USER_DATA_API,
	endPoint: 'xref/v1/venues?term="' + encodeURIComponent(term) + '"&classificationIds=5,3&exchangeIds=2825&limit=15',
	ajaxType: 'GET',
	onSuccess: recieveSuccessXrefUserDataApi
  })

  const requestXrefByXids = (xids) => ({
	type: Types.API_REQUEST_XREF_USER_DATA_API,
	endPoint: 'xref/v1/venue-xids?venueXids=' + encodeURIComponent(xids),
	ajaxType: 'GET',
	onSuccess: recieveSuccessXrefFundDataApi
  })

  const recieveSuccessXrefUserDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_XREF_USER_DATA,
	response
  })

  const recieveSuccessXrefFundDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_XREF_FUND_DATA,
	response
  })

  const requestXrefByTermETF = (term) => ({
	type: Types.API_REQUEST_XREF_USER_DATA_ETF_API,
	endPoint: 'xref/v1/venues?term="' + encodeURIComponent(term) + '"&classificationIds=18&exchangeIds=2825&limit=15',
	ajaxType: 'GET',
	onSuccess: recieveSuccessXrefUserDataApis
  });

const recieveSuccessXrefUserDataApis = response => ({
	type: Types.RECEIVE_SUCCESS_XREF_USER_DATA_ETF,
	response
  });

export {
	requestFundComparisonPdf,
	requestFundComparisonPdfonSelectModal,
	requestSaveTemplateDetailsApi,
	requestSaveTemplateApi,
	setAccessToken,
	receiveFundComparisonPDF,
	receiveFundComparisonPDFOnSelectModal,
	receiveSuccessSaveTemplateDetailsApi,
	receiveSuccessSaveTemplateApi,
	onGenericApiFailure,
	requestGetTemplatesApi,
	receiveSuccessGetTemplatesApi,
	requestGetTemplatesByIdApi,
	receiveSuccessGetTemplatesByIdApi,
	requestDeleteTemplateApi,
	receiveSuccessDeleteTemplateApi,
	requestScreenerDataApi,
	receiveSuccessGetScreenerDataApi,
	requestScreenerMstarCategoryDataApi,
	receiveSuccessGetScreenerDataOnCategoryNamesApi,
	requestSymbolSearchApi,
	recieveSymbolSearchApi,
	requestDecodeApi,
	receiveDecodeApi,
	requestGetBenchmarksApi,
	receiveGetBenchmarksApi,
	requestGetPrimaryBenchmarkApi,
	receiveGetPrimaryBenchmarkApi,
	requestGetScreenerMasterDataApi,
	receiveSuccessScreenerMasterDataApi,
	setTemplateDataset,
	updateTemplateInternalDetails,
	requestValidRollingReturnPeriodDataApi,
	requestValidCMPeriodDataApi,
	receiveSuccessValidRollingReturnsDataApi,
	receiveSuccessValidCMDataApi,
	resetScreenerFiltersOutput,
	requestScreenerFiltersDataApi,
	receiveSuccessScreenerFiltersDataApi,
	requestMfsSiteInactiveFlag,
	recieveSuccessMfsInactiveFlagApi,
	requestMfsUserData,
	setUserType,
	requestScreenerDataFundNameApi,
    receiveSuccessGetScreenerDataFundNameApi,
	requestXrefByTerm,
	requestXrefByTermETF,
	requestGetMfsPrimaryBenchmarkApi,
	receiveGetMfsPrimaryBenchmarkApi,
	requestXrefByXids,
	receiveSuccessGetFundPerformanceAsOfDateeApi,
	requestScreenerGetFundPerformanceAsOfDateApi
};

import 'babel-polyfill';
import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';
import xmlToJSON from "xmljson";
import lodash from 'lodash';
import moment from "moment";
import { Link } from 'react-router';

import Select, { components } from 'react-select';

import * as Constant from '../../../utils/constants';
import * as ValidationMessages from '../../../utils/validationMessages';
import * as utilityFunctions from "../../../utils/commonMethods";
import * as CustomModalStyles from '../../../utils/customModalStyles';

import RightRail from '../../selectFundComparison/subComponents/rightRail';
import SaveModal from '../../common/modal/saveModal.js';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import ReplaceTemplateModal from '../../common/modal/ReplaceTemplateModal';
import SaveComparisonPortfolioModal from '../../common/modal/saveComparisonPortfoliosModal.js';
import SelectTemplateWarningModal from '../../common/modal/SelectTemplateWarningModal.js';
import ValidationModal from '../../common/modal/ValidationModal.js';
import HypoChangesAlertModal from "../../common/modal/HypoChangesAlertModal";
import EventPortfolioChangeAlertModal from "../../common/modal/EventPortfolioChangeAlertModal";
import ValidationModalTabs from '../../common/modal/ValidationModalTabs.js';
import DownloadingSpinnerModal from "../../common/modal/DownloadingSpinnerModal";

import { SCREENER_TYPES } from '../../../utils/screenerConstants';
import { reportTypes, blendedBenchmarks, blendedBenchmarksMil } from "../../../utils/constants";

import * as Format from '../../../utils/format';
import { getFormattedEtfFunds } from '../../../utils/helpers';

import modalStyles from '../../../styles/components/portfolio/SelectPortfolioFundsModal.scss';
import BenchMarkValidationModal from '../../common/modal/ValidationModal.js';

const initialState = {
    saveModalIsOpen: false,
			replaceModalIsOpen: false,
			confirmationModalIsOpen: false,
			isSaveDisabled: true,
			templateResult: {},
			templateData: {},
			statusMessage: '',
			searchResults: {},
			wsodIssue: 0,
			mfsFundError: false,
			benchmarkError: false,
			screenerOutput: [],
			screenerFiltersOutput: [],
			benchmarks: [],
			isFIFAdded: 0,
			screenerMasterData: {},
			templateName: '',
			templateNameP2: '',
			isSaveDisabledP2: true,
			addPills: false,
			pills: [],
			showAddAllTooltipP1: false,
			showAddAllTooltipP2: false,
			statusMessageP2: '',
			portfolioTypeSelected: "None",
			defaultSelectedPortfolioTwoText: "Select Portfolio Two",
			defaultSelectedPortfolioOneText: "Select Portfolio One",
			isShowBenchmarkResults: false,
			blendedBenchmarks: blendedBenchmarks, // non-blended benchmark from API
			defaultSelectedPortfolioOneId: -1,
			templateWarningModalIsOpen: false,
			defaultSelectedPortfolioTwoId: -1,
			defaultSelectedPortfolioOneDesc: '',
			defaultSelectedPortfolioTwoDesc: '',
			savePortfolioModalIsOpen: false,
			P1: [],
			P2: [],
			radioSaveReplaceSelectedP1: "Save as New",
			radioSaveReplaceSelectedP2: "Save as New",
			customPortfolioModalStyle: '',
			currentP1Name: '',
			currentP2Name: '',
			currentP1Id: -1,
			currentP2Id: -1,
			oldScreenerData: [],
			isFirstTimeComparisonPortfolioEdit: false, // one time used for showing comparison portfolio funds
			undoScreenerData: [],
			showHypotheticals: false,
			p1Allocations: {},
			p2Allocations: {},
			hypotheticalState: {
				portfolios: [],
				totalInvestment: '',
				periodId: { Key: '1', Value: '' },
				endPeriod: '',
			},
			portoliosErrors: [],
			validationModalIsOpen: false,
			portfoliosDetails: {
				p1: [],
				p2: []
			},
			isAlertModalOpen: false,			
			eventValidationModalIsOpen: false,
			oldScreenerOutput: [],			
			isFromHypotheticals: false,			
			validationModalIsOpenTabs: false,
			tabErrors: [],
			isFirstTimeToHypotheticals: true,
			oldestInceptionDates: [],
			isOldestInceptionDatesChanged: false,
			resetInitialInvestment: false,
			eventsCount:0,
			isLoading: false,
			isIncludeCashInPortfolios: false,
			performanceAsOfDate:0,
			benchmarkValidationModalIsOpen: false,
			errors: [],
			isFromSelectBenchmark: false,
			preventDefaultTimePeriod: false
}

class SelectPortfolioFundsModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = cloneDeep(initialState);

		this.removeAllFunds = false;
		this.undoSelectedTemplateP1 = "",
			this.undoSelectedTemplateP2 = "",
			this.undoSelectedTemplateP1Id = "",
			this.undoSelectedTemplateP2Id = "",
			this.isPortfolio1Saved = false,
			this.isPortfolio2Saved = false,
			this.isPortfolioAllSaved = false,
			this.isPortfolioToSave = true,
			this.selectedTemplateP1 = 'Select Portfolio One',
			this.selectedTemplateP2 = 'Select Portfolio Two',
			this.selectedTemplateP1Value = -1,
			this.selectedTemplateP2Value = -1,
			this.showBenchmark = false,
			this.singlePortfolioSaveAllowed = true,
			this.p1Allocations = {},
			this.p2Allocations = {},
			this.modalTobeShown = '';	
			this.isFromModuleSelection = false;
			this.isLoadingReqd = true;	
			this.isPortfolioFundChangeDone = false;		
			this.isPortfolioFundChangeFromModuleDone = false;		
			this.isCalledFromFundSearchbar = false;
			this.comparisonOldestInceptionDateResult = {};
			
	}
	firstTimeToHypotheticals = (value) => {
		this.setState({isFirstTimeToHypotheticals: value});
	}
	closeValidationModalTabs = () => {
		this.setState({ validationModalIsOpenTabs: false, tabErrors: [] });
	}

	setValidationModalTabs = (tabErrors) => {
		this.setState({ validationModalIsOpenTabs: true, tabErrors: tabErrors });
	}
	validateSameDayEvent = () => {
		let error = ['You already have an event scheduled on the selected date. Please edit the existing event or select a different date to create a new event.'];;
		this.setState({ validationModalIsOpenTabs: true, tabErrors: error });
	}
	componentDidMount() {		
		let portfoflioTemplateResult = Constant.portfolioTemplateResult;
		let portfolioTemplateData = Constant.portfolioTemplateData;	
		// [for-port] For international user and admin, add language property
		if (this.props.userType.response === 'International') {
			portfolioTemplateData.language = 'en-GB';
			portfoflioTemplateResult.language = 'en-GB';
			portfolioTemplateData.currency = "USD";
			portfoflioTemplateResult.currency = "USD";
		}	
		if (portfolioTemplateData.Portfolios.length == 1) {
			portfolioTemplateData.Portfolios.push({ Portfolio: [] });
		}
		this.setState({
			templateResult: portfoflioTemplateResult,
			templateData: portfolioTemplateData,
			defaultSelectedPortfolioOneText: 'Select Portfolio One',
			defaultSelectedPortfolioTwoText: 'Select Portfolio Two',
		});
		const screenerInput = Constant.screenerInputs;
		screenerInput.arguments[0].conditions[0].values = ['302433'];
		this.props.requestPerformanceAsOfScreenerDataApi(SCREENER_TYPES.MutualFund, screenerInput, (this.props.userType.response === 'International') ? 'yes' : 'no', (this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD');	
		document.getElementsByClassName("ReactModalPortal")[1].addEventListener('mousedown', this.handleTooltipClose, true);
	}

	componentWillUnmount() {
		document.getElementsByClassName("ReactModalPortal")[1].removeEventListener('mousedown', this.handleTooltipClose);
	}
	//logic to handle tooltip on Add All for PvP comparison
	handleTooltipClose = (event) => {
		if (this.state.showAddAllTooltipP1 || this.state.showAddAllTooltipP2) {
			if (event.target.getAttribute('data-tooltip') != 'close' && event.target.className != 'tooltip-inner' && event.target.className != 'tooltip-arrow') {
				this.setState({
					showAddAllTooltipP1: false,
					showAddAllTooltipP2: false,
				})
			}
		}
	}

	comingFromHypotheticals = (value) => {
		this.setState({isFromHypotheticals: value, oldScreenerOutput: this.state.screenerOutput});
	}

	removeAllEventsFromModules() {
		this.isPortfolioFundChangeFromModuleDone = true;
		this.props.requestRemoveAllEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0], 'Fee');
    	this.props.requestRemoveAllEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1], 'Fee');

		this.props.requestGetEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
    	this.props.requestGetEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
		
		//For Fund investment schedules
		this.props.requestGetInvestmentSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
		this.props.requestGetInvestmentSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
	
		//For Fund Withdrawal schedules
		this.props.requestGetWithdrawalSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
		this.props.requestGetWithdrawalSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);	

		this.isLoadingReqd = true;
		this.setState({
			eventValidationModalIsOpen: false, isFromHypotheticals: false, oldScreenerOutput: this.state.screenerOutput, 			
			resetInitialInvestment: true // To hard reset initial investment redistribution
		});
	}

	removeAllEvents = () => {
		if(!this.state.preventDefaultTimePeriod){
			this.isPortfolioFundChangeDone = true;
			}
		this.props.requestRemoveAllEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0], 'Fee');
    	this.props.requestRemoveAllEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1], 'Fee');

		this.props.requestGetEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
    	this.props.requestGetEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
		
		//For Fund investment schedules
		this.props.requestGetInvestmentSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
		this.props.requestGetInvestmentSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
	
		//For Fund investment schedules
		this.props.requestGetWithdrawalSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
		this.props.requestGetWithdrawalSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
	
		this.afterValidateShowBenchmark();		
		this.setState({
			eventValidationModalIsOpen: false, isFromHypotheticals: false, oldScreenerOutput: this.state.screenerOutput, 			
			resetInitialInvestment: true // To hard reset initial investment redistribution			
		});
		this.isLoadingReqd = true;
	}
	closeEventValidationModal = () => {
		this.setState({ eventValidationModalIsOpen: false});
	}
	closeComparisonModal = () => {
		this.openHypoAlertModal();			
	}
	openHypoAlertModal = () => {
		this.setState({isAlertModalOpen: true});
	}
	backToComparisonModal = () => {
		this.setState({isAlertModalOpen: false});
	}
	closeAlertModal = () => {
		this.setState({isAlertModalOpen: false});
		this.props.resetPortfolioComparisonScreenerData();
		this.props.closeModal();
		if(!this.props.isFromPortfolio) {
			this.setState(cloneDeep(initialState));
		}
	}

	/**
	 * portIndex valid values: 1, 2
	 */
	addAllFunds = (portIndex) => {
		let screenerData = cloneDeep(this.state.screenerOutput);
		let newtemplateDataState = Object.assign({}, this.state.templateData);
		const portfolioFunds = []

		screenerData.forEach(item => {
			if (portIndex === 1) {
				item.isActive = false;	
			} else if (portIndex === 2) {
				item.isActiveP2 = false;
			}
		});

		if (newtemplateDataState.Portfolios.length === 1) {
			newtemplateDataState.Portfolios.push({ Portfolio: [] });
		}

		screenerData.forEach((item, index) => {
			if (index < 20) {
				if (portIndex === 1) {
					item.isActive = true;	
				} else if (portIndex === 2) {
					item.isActiveP2 = true;
				}
				let obj = {
					symbol: item.MFSSymbol,
					wsodissue: item.MSDWSODIssue,
					fundshareClassId: item.MSDFundShareClassID,
					fundfamily: 'OtherFund',
					name: item.MSDFundName
				}
				portfolioFunds.push(obj)
			}
		});
		if (screenerData.length > 20) {
			if (portIndex === 1) {
				this.setState({
					showAddAllTooltipP1: true
				});
			} else if (portIndex === 2) {
				this.setState({
					showAddAllTooltipP2: true
				});
			}
		}		
		newtemplateDataState.Portfolios[portIndex-1].Portfolio = portfolioFunds;
		this.setState({ templateData: newtemplateDataState, screenerOutput: screenerData });
		document.getElementsByClassName('rbt-input-main')[0].click();
	}

	pushAllFund = (data, oldResultTableData, isInternationalUser) => {
		this.removeAllFunds = true
		let pillsData = [];
		oldResultTableData.forEach(function (element) {
            if (isInternationalUser === true) {
                pillsData.push({
                    xid: element.MSDWSODIssue,
					symbol: element.MFSSymbolISIN,
					name: element.MSDFundName
                });
            } else {
                pillsData.push({
                    xid: element.MSDWSODIssue,
                    symbol: element.MFSSymbol
                });
            }   
		});

		if (isInternationalUser === true) {
			if (oldResultTableData.findIndex(fund => { return Constant.CustomFundXids.includes(fund.MSDWSODIssue)}) === -1) {
				this.setState({ isIncludeCashInPortfolios: false });
			} else {
				this.setState({ isIncludeCashInPortfolios: true });
			}
		} else {
			if (oldResultTableData.findIndex(fund => { return fund.MFSSymbol === Constant.CustomFunds.Cash.MFSSymbol}) === -1) {
				this.setState({ isIncludeCashInPortfolios: false });
			} else {
				this.setState({ isIncludeCashInPortfolios: true });
			}
		}
		
		this.setState({
			screenerOutput: oldResultTableData, addPills: false, pills: pillsData,
			defaultSelectedPortfolioOneText: this.undoSelectedTemplateP1, defaultSelectedPortfolioTwoText: this.undoSelectedTemplateP2
		});
	}

	pushFund = (data, oldResultTableData) => {
		let screenerData = cloneDeep(this.state.screenerOutput);
		const newtemplateDataState = Object.assign({}, this.state.templateData);
		const portfolioFunds = this.state.templateData.Portfolios[0].Portfolio;
		let portfolioFunds2 = null;
		if (this.props.modalIsPortfolioCompare) {
			portfolioFunds2 = this.state.templateData.Portfolios[1] ? this.state.templateData.Portfolios[1].Portfolio : [];
		}

		screenerData.forEach(item => {
			if (item["isActive"] || item["isActiveP2"]) {
				let obj = {
					symbol: item.MFSSymbol,
					wsodissue: item.MSDWSODIssue,
					fundshareClassId: item.MSDFundShareClassID,
					fundfamily: 'OtherFund',
					name: item.MSDFundName
				}
				item["isActive"] ? portfolioFunds.push(obj) : portfolioFunds2.push(obj);
			}
		});

		let _isIncludeCashInPortfolios = false;
		if (this.props.userType.response === 'International') {
			if (data.findIndex(fund => { return Constant.CustomFundXids.includes(fund.MSDWSODIssue)}) > -1) {
				_isIncludeCashInPortfolios = true;
			}
		} else {
			if (data.findIndex(fund => { return fund.MFSSymbol === Constant.CustomFunds.Cash.MFSSymbol}) > -1) {
				_isIncludeCashInPortfolios = true;
			}
		}
		
		this.setState({ templateData: newtemplateDataState, screenerOutput: screenerData, isIncludeCashInPortfolios: _isIncludeCashInPortfolios });
	}

	setPrimaryBenchmark(data) {
		const newtemplateResultState = Object.assign({}, this.state.templateResult);
		const primaryBenchmark = [{ symbol: data.name, wsodissue: data.wsod, fundfamily: 'PrimaryBenchmark' }];
		newtemplateResultState.funds.primarybenchmark = primaryBenchmark;
		this.setState({ templateResult: newtemplateResultState });
	}

	getPrimaryProspectusBenchmarkData(data) {
		if (this.state.templateResult.funds.primarybenchmark.length > 0
			&& data.wsod !== this.state.templateResult.funds.primarybenchmark[0].wsodissue) {
			this.setPrimaryBenchmark(data);
		} else if (this.state.templateResult.funds.primarybenchmark.length === 0) {
			this.setPrimaryBenchmark(data);
		}
	}
	disableScrolling = () => {
		document.body.style.overflow = 'hidden';
	}

	enableScrolling = () => {
		document.body.style.overflow = 'auto';
	}


	handleEnterClickOnTextbox = (e) => {
		if (e.key === 'Enter' && !this.props.modalIsPortfolioCompare) {
			this.saveTemplateSettings();
		}
	}

	handleDeleteFundClick(e) {
		const wsodIssue = e.target.getAttribute('data-wsodIssue');
		const symbol = e.target.getAttribute('data-symbol');
		const listId = e.target.getAttribute('data-listId');
		const newtemplateDataState = Object.assign({}, this.state.templateData);

		const portfolioFunds = this.state.templateData.Portfolios[0].Portfolio
		let removeIndex = -1;
		removeIndex = portfolioFunds.findIndex(i => {
			return i.symbol.toString() === symbol;
		});
		if (removeIndex > -1) {
			portfolioFunds.splice(removeIndex, 1);
		}
		newtemplateDataState.Portfolios[0].Portfolio = portfolioFunds;

		let screenerData = cloneDeep(this.state.screenerOutput);
		screenerData.forEach(item => {
			if (item.MSDWSODIssue && item.MSDWSODIssue.toString() === wsodIssue) {
				item["isActive"] = false;
			}
		});
		this.setState({ templateData: newtemplateDataState, screenerOutput: screenerData });
	}

	requestGetPrimaryBenchmarkApi(input) {
		// used in line 4515
	}

	handlePortfolioSaveReplaceChange = (event) => {
		let portfoliotype = event.target.attributes["data-portfoliotype"].value;
		if (portfoliotype === 'P1') {
			if (event.target.value !== 'Replace') {
				document.getElementById('txt-description').value = '';
				document.getElementById('search').value = '';
				this.setState({ isSaveDisabled: true, statusMessage: '' });
			} else {
				this.setState({ isSaveDisabled: false, statusMessage: '' });
			}
			this.setState({ radioSaveReplaceSelectedP1: event.target.value });
		} else if (portfoliotype === 'P2') {
			if (event.target.value !== 'Replace') {
				document.getElementById('txt-descriptionP2').value = '';
				document.getElementById('searchP2').value = '';
				this.setState({ isSaveDisabledP2: true, statusMessageP2: '' });
			} else {
				this.setState({ isSaveDisabledP2: false, statusMessageP2: '' });
			}

			this.setState({ radioSaveReplaceSelectedP2: event.target.value });
		}
	}
	openSaveOrReplaceModal = () => {
		this.setState({ isFromSelectBenchmark: false });
		this.props.requestMfsSiteInactiveFlag();
		this.isCalledFromFundSearchbar = false;
		if (this.props.modalIsPortfolioCompare && !(this.state.isShowBenchmarkResults || this.state.showHypotheticals)) {
			this.requestOldestInceptionDates();
		} else {
			if (this.props.modalIsPortfolioCompare && (this.state.isShowBenchmarkResults || this.state.showHypotheticals)) {
				if (this.props.editComparisonTemplateId > 0 || this.props.ComparisonPortfolioTemplateId > 0) {
					this.openReplaceModal();
				} else {
					this.openSaveModal();
				}
			} else {
				this.singlePortfolioSaveAllowed = true; 
				let isSingleFundCash = this.props.userType.response === 'International' ? (this.state.screenerOutput.length === 1 && Constant.CustomFundXids.includes(this.state.screenerOutput[0].MSDWSODIssue))
					: (this.state.screenerOutput.length === 1 && this.state.screenerOutput[0].MFSSymbol === Constant.CustomFunds.Cash.MFSSymbol);
				if (isSingleFundCash) {
					if(!this.props.modalIsPortfolioCompare) {
						if (this.props.TemplateId > 0) {
							this.openReplaceModal();
						} else {
							this.openSaveModal();
						}
					}
				} else {
					this.requestOldestInceptionDates();
				}
			}
		}
	}

	validateDuplicationBeforeFundAddition(symbol) {
		let isValid = true;

		//Check in Fund in Focus section
		if (
			this.state.templateResult.funds.fundinfocus.length > 0 &&
			this.state.templateResult.funds.fundinfocus[0].symbol === symbol
		) {
			isValid = false;
		} else if (
			this.state.templateResult.funds.benchmark.length > 0 &&
			this.state.templateResult.funds.benchmark[0].symbol === symbol
		) {
			isValid = false;
		} else {
			const nonFundInFocusData = this.state.templateResult.funds.nonfundinfocus;
			let removeIndex = -1;

			removeIndex = nonFundInFocusData.findIndex(i => {
				return i.symbol === symbol;
			});

			if (removeIndex > -1) {
				isValid = false;
			}
		}
		return isValid;
	}

	AddFundToPortfolio(flag, symbol, wsodIssue) {
		const data = {
			symbol: symbol,
			wsodissue: wsodIssue,
			fundfamily: flag
		};
		const newtemplateDataState = Object.assign({}, this.state.templateData);
		newtemplateDataState.Portfolios[0].Portfolio.push(data);
		if (this.props.modalIsPortfolioCompare) {
			newtemplateDataState.Portfolios[1].Portfolio.push(data);
		}

		this.setState({ templateData: newtemplateDataState });
	}

	removeFund = (symbol) => {
		let screenerData = cloneDeep(this.state.screenerOutput);
		const newtemplateResultData = Object.assign({}, this.state.templateData);
		const portfolioFunds = this.state.templateData.Portfolios[0].Portfolio;
		let portfolioFunds2 = null;
		if (this.props.modalIsPortfolioCompare) {
			portfolioFunds2 = this.state.templateData.Portfolios[1] ? this.state.templateData.Portfolios[1].Portfolio : [];
		}

		let removeIndex = -1;
		removeIndex = portfolioFunds.findIndex(i => {
			return i.symbol && i.symbol.toString() === symbol;
		});
		removeIndex > -1 && portfolioFunds.splice(removeIndex, 1);			
	

		if (this.props.modalIsPortfolioCompare) {
			removeIndex = portfolioFunds2.findIndex(i => {
				return i.symbol && i.symbol.toString() === symbol;
			});
			removeIndex > -1 && portfolioFunds2.splice(removeIndex, 1);
		}

		this.props.modalIsPortfolioCompare && newtemplateResultData.Portfolios.length == 1 && newtemplateResultData.Portfolios.push({ Portfolio: [] });
	

		if (symbol !== undefined) {
			newtemplateResultData.Portfolios[0].Portfolio = portfolioFunds;
			if (this.props.modalIsPortfolioCompare) {
				newtemplateResultData.Portfolios[1].Portfolio = portfolioFunds2;
            }
			let removeIndexScreener = screenerData.findIndex(i => {
				return this.props.userType.response === 'International' ? i.MFSSymbolISIN === symbol : i.MFSSymbol === symbol;
			});
			removeIndexScreener > -1 && screenerData.splice(removeIndexScreener, 1);
			
		} else {
			this.undoSelectedTemplateP1 = this.state.defaultSelectedPortfolioOneText
			this.undoSelectedTemplateP2 = this.state.defaultSelectedPortfolioTwoText
			this.undoSelectedTemplateP1Id = this.state.defaultSelectedPortfolioOneId
			this.undoSelectedTemplateP2Id = this.state.defaultSelectedPortfolioTwoId
			//reset dropdown if clear all funds.
			this.resetDropdown();

			this.setState({
				defaultSelectedPortfolioOneText: "Select Portfolio One",
				defaultSelectedPortfolioTwoText: "Select Portfolio Two",
			});
			newtemplateResultData.Portfolios[0].Portfolio = []
			if (this.props.modalIsPortfolioCompare) {
				newtemplateResultData.Portfolios[1].Portfolio = []
			}
		}
		this.setState({ screenerOutput: symbol !== undefined ? screenerData : [], templateData: newtemplateResultData });
		symbol === undefined || screenerData.length === 0 && this.setState({ isSaveDisabled: true });
		document.getElementsByClassName('rbt-input-main')[0].click();
	}

	resetDropdown() {
		this.selectedTemplateP1 = 'Select Portfolio One';
		this.selectedTemplateP2 = 'Select Portfolio Two';
		this.selectedTemplateP1Value = -1;
		this.selectedTemplateP2Value = -1;
	}
	toggleCompareButton = (e) => {
		const flag = e.currentTarget.getAttribute('data-flag');
		const symbol = e.currentTarget.getAttribute('data-symbol');
		const wsodIssue = e.currentTarget.getAttribute('data-wsodIssue');
		const portfolioName = e.currentTarget.getAttribute('data-portfolio');
		const fundName = e.currentTarget.getAttribute('data-fundName');
		const fundshareClassId = e.currentTarget.getAttribute('data-fundShareClassId');
		let screenerData = cloneDeep(this.state.screenerOutput);
		const newtemplateResultData = Object.assign({}, this.state.templateData);
		if (newtemplateResultData.Portfolios.length == 1) {
			newtemplateResultData.Portfolios.push({ Portfolio: [] });
		}
		if (flag != "Benchmark") {
			screenerData.forEach(item => {
				if (item.MSDWSODIssue && item.MSDWSODIssue.toString() == wsodIssue) {
					if (portfolioName === 'P1') {
						if (item["isActive"]) {
							const portfolioFunds = this.state.templateData.Portfolios[0].Portfolio;
							let removeIndex = -1;
							removeIndex = portfolioFunds.findIndex(i => {
								return i.wsodissue.toString() === wsodIssue;
							});
							if (removeIndex > -1) {
								portfolioFunds.splice(removeIndex, 1);
							}
							newtemplateResultData.Portfolios[0].Portfolio = portfolioFunds;
							item["isActive"] = false;
						} else {
							if (screenerData.filter(x => x.isActive).length < 20) {

								const data = {
									symbol: symbol,
									wsodissue: wsodIssue,
									fundfamily: flag,
									name: fundName,
									fundshareClassId: fundshareClassId
								};

								newtemplateResultData.Portfolios[0].Portfolio.push(data);

								item["isActive"] = true;
							}
						}
					} else if (portfolioName === 'P2') {
						if (item["isActiveP2"]) {
							const portfolioFunds = this.state.templateData.Portfolios[1].Portfolio;
							let removeIndex = -1;
							removeIndex = portfolioFunds.findIndex(i => {
								return i.wsodissue.toString() === wsodIssue;
							});
							if (removeIndex > -1) {
								portfolioFunds.splice(removeIndex, 1);
							}
							newtemplateResultData.Portfolios[1].Portfolio = portfolioFunds;
							item["isActiveP2"] = false;
						} else {
							if (screenerData.filter(x => x.isActiveP2).length < 20) {
								const data = {
									symbol: symbol,
									wsodissue: wsodIssue,
									fundfamily: flag,
									name: fundName,
									fundshareClassId: fundshareClassId
								};

								newtemplateResultData.Portfolios[1].Portfolio.push(data);
								item["isActiveP2"] = true;
							}
						}
					}
				}
			});
			this.setState({
				screenerOutput: screenerData, templateData: newtemplateResultData
			});
		} else {
			const data = {
				symbol: symbol,
				wsodissue: wsodIssue,
				weightage: "1.0"
			};
			let disableButtonStyle = {
				'pointer-events': 'none',
				opacity: 0.4
			};
			let index = -1;
			index = this.state.benchmarks.findIndex(i => {
				return i.name.toString() === data.symbol;
			});
			if (index > -1) {
				if (this.state.benchmarks[index].isChecked) {
					this.state.benchmarks[index].isChecked = false;
					this.state.benchmarks.forEach(item =>
						item["isChecked"] = false
					)
				} else {
					this.state.benchmarks.forEach(item =>
						item["isChecked"] = false
					)
					this.state.benchmarks[index].isChecked = true;
					let benchmark = this.state.benchmarks[index];
					let WsodIssues = '';
					if(benchmark.blendedBenchmarkNames !== ''){
						benchmark.benchmarks.map(item=>{
							return WsodIssues += item.wsodissue + ',';
						})
					} else {
						WsodIssues = benchmark.xId;
					}
					this.props.requestBenchmarkLatestDate(WsodIssues);
				}
			} else {
				this.state.benchmarks.forEach(item =>
					item["isChecked"] = false
				)
			}

			this.setState({
				screenerOutput: screenerData, templateData: newtemplateResultData, benchmarks: this.state.benchmarks
			});

		}
	}

	// Returns blended and non-blended benchmarks combined
	getCombinedBenchmarks(data) {
		let combinedBenchmarks = []; // filtered benchmarks with search
		let nonblendedBenchmarks = data; // from API, in the end will have both non-blended and blended benchmarks
		let blendedBenchmarks = this.state.blendedBenchmarks; // from static json

		// Add blended benchmark from static json to combineBenchmarks
		// blendedBenchmarks to be at the top
		blendedBenchmarks.forEach(function (elem) {
			elem.blendedBenchmarkNames = '';
			elem.benchmarks.forEach(function (item) {
				elem.blendedBenchmarkNames += item.symbol + " " + (item.weightage * 100) + "%, ";
				elem.xId = item.wsodissue + ",";
			});
			elem.blendedBenchmarkNames = elem.blendedBenchmarkNames.slice(0, -2);
			combinedBenchmarks.push(elem);
		});

		// Update non-blended benchmark with "blendedBenchmarkName" and add non-blended benchmark from API to combineBenchmarks
		nonblendedBenchmarks.forEach(function (elem) {
			elem.blendedBenchmarkNames = '';
			elem.xId = elem.xId.toString();
			combinedBenchmarks.push(elem);
		});

		return combinedBenchmarks;
	}

	openSavePortfolioModal() {
		let screenerData = cloneDeep(this.state.screenerOutput);
		let p1 = [];
		let p2 = [];
		screenerData.filter(x => x.isActive).map(item => {
			let obj = {
				symbol: item.MFSSymbol,
				wsodissue: item.MSDWSODIssue,
				fundshareClassId: item.MSDFundShareClassID,
				fundfamily: 'OtherFund',
				name: item.MSDFundName
			}
			p1.push(obj);
		});

		screenerData.filter(x => x.isActiveP2).map(item => {
			let obj = {
				symbol: item.MFSSymbol,
				wsodissue: item.MSDWSODIssue,
				fundshareClassId: item.MSDFundShareClassID,
				fundfamily: 'OtherFund',
				name: item.MSDFundName
			}
			p2.push(obj);
		});

		let descriptionP1 = '', descriptionP2 = '';
		if (this.state.defaultSelectedPortfolioOneId > -1) {
			descriptionP1 = (this.props.portfolioNamesList.filter(x => x.templateId == this.state.defaultSelectedPortfolioOneId))[0].description;
		}
		if (this.state.defaultSelectedPortfolioTwoId > -1) {
			descriptionP2 = (this.props.portfolioNamesList.filter(x => x.templateId == this.state.defaultSelectedPortfolioTwoId))[0].description;
		}

		this.setState({
			P1: p1,
			P2: p2,
			savePortfolioModalIsOpen: true,
			replaceModalIsOpen: false,
			statusMessage: '',
			statusMessageP2: '',
			defaultSelectedPortfolioOneDesc: descriptionP1,
			defaultSelectedPortfolioTwoDesc: descriptionP2,
			radioSaveReplaceSelectedP1: "Save as New",
			radioSaveReplaceSelectedP2: "Save as New",
			isSaveDisabled: true,
			isSaveDisabledP2: true
		});

		this.getCustomModalStyle(this.props.modalIsPortfolioCompare, p1, p2)
	}

	openSaveModal = () => {
		this.setState({
			saveModalIsOpen: true,
			replaceModalIsOpen: false,
			statusMessage: ''
		});
	}

	openReplaceModal = () => {
		this.setState({
			replaceModalIsOpen: true
		});
	}

	closeReplaceModal = () => {
		this.setState({
			replaceModalIsOpen: false
		});
	}

	closeSaveModal = () => {
		if (this.props.modalIsPortfolioCompare && !this.state.isShowBenchmarkResults) {
			if(this.props.hypotheticalStatePvP && this.props.hypotheticalStatePvP.totalInvestment) {
				this.props.updatePortfolioNameList(this.state.hypotheticalState.totalInvestment ? this.state.hypotheticalState.totalInvestment : this.props.hypotheticalStatePvP.totalInvestment);
			} else  {
			this.props.requestGetTemplatesApi((this.props.userType.response === 'International') ? reportTypes.mil_singleportfolio : reportTypes.singleportfolio);
			}
			this.setState({
				P1: [],
				P2: [],
				savePortfolioModalIsOpen: false,
				replaceModalIsOpen: false,
				statusMessage: '',
				statusMessageP2: '',
			});
		} else {
			this.setState({
				saveModalIsOpen: false,
				statusMessage: '',
				replaceModalIsOpen: false,
				isSaveDisabled: true,
				templateName: ''
			});
		}
	}

	closetemplateWarningModal = (isCancelClicked) => {
        if (isCancelClicked) {
            this.portfolioOneSelected = false;
            this.portfolioTwoSelected = false;
        }//if loop is executed when we click on cancel button (by binding onclick event)
        this.setState({
            templateWarningModalIsOpen: false,
            validationModalIsOpen: false
        });
    }

	closeValidationModal = () => {
		this.showBenchmark = false;
		this.singlePortfolioSaveAllowed = false;
		this.setState({validationModalIsOpen: false});
	}

	closeBenchmarkValidationModal = () => {
		this.setState({benchmarkValidationModalIsOpen: false});

		let isSuccessful = this.validatePortfolios(this.props.oldestInceptionDates);
		this.setState({ validationModalIsOpen: !isSuccessful });
		//to make changes here for popup modal
		if (isSuccessful) {
			if (this.props.modalIsPortfolioCompare) {
				//validate for event validation then
				if (this.state.isFromHypotheticals) {
					if (!lodash.isEqual(this.state.oldScreenerOutput.filter(x => x.isActive || x.isActiveP2), this.state.screenerOutput.filter(x => x.isActive || x.isActiveP2))) {
						//Remove all events when Portfolio funds change
						this.removeAllEvents();
					} else {
						this.afterValidateShowBenchmark();
					}
				} else {
				    if(this.isFromModuleSelection) {
						this.afterValidateShowBenchmark();
					} else {
						this.removeAllEvents();
					}
				}
			} else {
				this.afterValidateShowBenchmark();
			}
		}
	}

	closeConfirmationModal = () => {
		this.setState({
			confirmationModalIsOpen: false,
			statusMessage: ''
		});
		this.isPortfolio1Saved = false;
		this.isPortfolio2Saved = false;
		this.isPortfolioAllSaved = false;
		this.isPortfolioToSave = false;
	}
	updateInvestments = () => {
		this.setComparisonInvestmentDetails();
	}
	replaceTemplateSettings = () => {
		this.props.requestMfsSiteInactiveFlag();
		const newTemplateData = Object.assign({}, this.state.templateData);
		newTemplateData.isMarketingPage = this.state.templateResult.isMarketingPage !== undefined ?
		this.state.templateResult.isMarketingPage : true;
		//initializing sections for old templates
		newTemplateData.sections = this.state.templateResult.sections !== undefined ? this.state.templateResult.sections : (this.props.userType.response === 'International' ? Constant.sectionMasterDataIntl.sections : Constant.sectionMasterData.sections);
		const templateSettingsData = {
			templateName: this.state.templateName.length > 0 ? this.state.templateName.trim() : this.props.TemplateName.trim(),
			reportType: (this.props.userType.response === 'International') ? Constant.reportTypes.mil_singleportfolio : Constant.reportTypes.singleportfolio 
		};
		this.setState({
			statusMessage: ''
		});		

		templateSettingsData.templateData = JSON.stringify(this.setPortfoliocomparisonTemplateData());
		if (this.props.modalIsPortfolioCompare) {
			//Adding benchmark
			if (this.props.ComparisonPortfolioTemplateId) {
				templateSettingsData.templateId = this.props.ComparisonPortfolioTemplateId;
				templateSettingsData.templateName = this.state.templateName.length > 0 ? this.state.templateName.trim() : this.props.ComparisonPortfolioTemplateName.trim();
			} else {
				templateSettingsData.templateId = this.props.editComparisonTemplateId;
				templateSettingsData.templateName = this.props.editComparisonTemplateName.trim();
			}
			templateSettingsData.containerId = this.props.comparsionWorkingContainerIds;
			templateSettingsData.saveType = "replace";			
			templateSettingsData.reportType = (this.props.userType.response === 'International') ? Constant.reportTypes.mil_portfoliocomparison : Constant.reportTypes.portfoliocomparison;
			this.props.requestSaveComparisonPortfolioTemplateDetailsApi(templateSettingsData);
		} else {
			templateSettingsData.templateId = this.props.TemplateId;
			templateSettingsData.reportType = (this.props.userType.response === 'International') ? Constant.reportTypes.mil_singleportfolio : Constant.reportTypes.singleportfolio;
			this.props.requestSaveTemplateDetailsApi(templateSettingsData);
		}
	}

	setPortfoliocomparisonTemplateData() {
		let newTemplateData = ""
		newTemplateData = cloneDeep(Constant.portfolioTemplateData);
		if(this.props.userType.response === 'International' && !this.props.modalIsPortfolioCompare) {
			newTemplateData.language = "en-GB";
			newTemplateData.currency = "USD";
		}		


		newTemplateData.balanceInitial = this.props.balanceInitial;
		let obj = this.state.benchmarks.filter(x => x.isChecked);
		newTemplateData.benchmark.benchmarks = [];//to reset benchmark
		newTemplateData.benchmark.isChecked = true;
		if (this.props.modalIsPortfolioCompare) {
			if (obj.length > 0) {
				if (obj[0].blendedBenchmarkNames !== '') {
					newTemplateData.benchmark.name = obj[0].name;
					newTemplateData.benchmark.benchmarks = obj[0].benchmarks;
				} else {
					newTemplateData.benchmark.name = '';
					newTemplateData.benchmark.benchmarks.push({
						symbol: obj[0].name,
						wsodissue: obj[0].xId,
						weightage: "1.0"
					});
				}
			}
		}

		newTemplateData.Portfolios[0].Portfolio = [];
		if (newTemplateData.Portfolios.length == 1) {
			newTemplateData.Portfolios.push({ Portfolio: [] });
		} else {
			newTemplateData.Portfolios[1].Portfolio = [];
		}
		let screenerData = cloneDeep(this.state.screenerOutput);
		screenerData.filter(x => x.isActive).map(item => {
			let obj = {
				symbol: item.MFSSymbol,
				wsodissue: item.MSDWSODIssue,
				fundshareClassId: item.MSDFundShareClassID,
				fundfamily: 'OtherFund',
				name: item.MSDFundName
			};
			newTemplateData.Portfolios[0].Portfolio.push(obj);
		});
		screenerData.filter(x => x.isActiveP2).map(item => {
			let obj = {
				symbol: item.MFSSymbol,
				wsodissue: item.MSDWSODIssue,
				fundshareClassId: item.MSDFundShareClassID,
				fundfamily: 'OtherFund',
				name: item.MSDFundName
			};
			newTemplateData.Portfolios[1].Portfolio.push(obj);
		});

		let p1= {funds:[]};
		let p2= {funds:[]};
		let p1OldestShareClassDates= [];
		let p2OldestShareClassDates= [];
		let p1Count = 0;
		let p2Count = 0;
		let asOfPerformanceDate = 0;
		let result = this.getComparisonOldestInceptionDate(this.state.oldestInceptionDates, asOfPerformanceDate, p1, p2, p1Count, p2Count, p1OldestShareClassDates, p2OldestShareClassDates)

		p1OldestShareClassDates = result.p1OldestShareClassDates;
		p2OldestShareClassDates = result.p2OldestShareClassDates;
		p1Count = result.p1Count;
		p2Count = result.p2Count;
		asOfPerformanceDate = result.asOfPerformanceDate;
		p1OldestShareClassDates.sort(function(a, b){return a-b});
		p2OldestShareClassDates.sort(function(a, b){return a-b});
		newTemplateData.endPeriod = asOfPerformanceDate;
		if(p1Count > 0 && p2Count > 0){
			newTemplateData.startPeriod = p1OldestShareClassDates[p1Count-1] < p2OldestShareClassDates[p2Count-1] ? p2OldestShareClassDates[p2Count-1] : p1OldestShareClassDates[p1Count-1];
			
		} else {
			newTemplateData.startPeriod = p1Count > 0 ? p1OldestShareClassDates[p1Count-1]: p2OldestShareClassDates[p2Count-1];
		}

		return newTemplateData;
	}
	//used when we move from Portfolio fund selection to Benchmark screen
	setComparisonInvestmentDetailsFromScreenerOutput() {
		let screenerData = cloneDeep(this.state.screenerOutput);
		Constant.portfolioTemplateData.Portfolios[0].Portfolio = [];
        Constant.portfolioTemplateData.Portfolios[1].Portfolio = [];
		const _templateData = cloneDeep(Constant.portfolioTemplateData);		
		screenerData.map(item => {

			if (item.isActive) {
				_templateData.Portfolios[0].Portfolio.push({
					symbol: item.MFSSymbol,
					wsodissue: item.MSDWSODIssue,
					fundshareClassId: item.MSDFundShareClassID,
					fundfamily: 'OtherFund',
					name: item.MSDFundName
				})
			}
			if (item.isActiveP2) {
				_templateData.Portfolios[1].Portfolio.push({
					symbol: item.MFSSymbol,
					wsodissue: item.MSDWSODIssue,
					fundshareClassId: item.MSDFundShareClassID,
					fundfamily: 'OtherFund',
					name: item.MSDFundName
				})
			}
			return _templateData;
		})
		_templateData.startPeriod = this.state.hypotheticalState.startPeriod;
		_templateData.endPeriod = this.state.hypotheticalState.endPeriod;
		_templateData.balanceInitial = this.props.balanceInitial;
		this.modifyInvestments(_templateData);
	}

	setComparisonInvestmentDetails = () => {
		let templateData = { Portfolios: [], balanceInitial: 100000 };
		let screenerData = cloneDeep(this.state.screenerOutput);
		Constant.portfolioTemplateData.Portfolios[0].Portfolio = [];
		Constant.portfolioTemplateData.Portfolios[1].Portfolio = [];
		const _templateData = cloneDeep(Constant.portfolioTemplateData);
		screenerData.map(item => {

			if (item.isActive) {
				_templateData.Portfolios[0].Portfolio.push({
					symbol: item.MFSSymbol,
					wsodissue: item.MSDWSODIssue,
					fundshareClassId: item.MSDFundShareClassID,
					fundfamily: 'OtherFund',
					name: item.MSDFundName
				})
			}
			if (item.isActiveP2) {
				_templateData.Portfolios[1].Portfolio.push({
					symbol: item.MFSSymbol,
					wsodissue: item.MSDWSODIssue,
					fundshareClassId: item.MSDFundShareClassID,
					fundfamily: 'OtherFund',
					name: item.MSDFundName
				})
			}
			return _templateData;
		})
		let p1Wsod = _templateData.Portfolios[0].Portfolio.map(f => f.wsodissue).sort();
		let p2Wsod = _templateData.Portfolios[1].Portfolio.map(f => f.wsodissue).sort();

		let p1HypoWsod = [];
		let p2HypoWsod = [];
		if (this.state.hypotheticalState.portfolios && this.state.hypotheticalState.portfolios.length > 0) {
			p1HypoWsod = this.state.hypotheticalState.portfolios[0].funds.map(f => f.wsodissue).sort();
			p2HypoWsod = this.state.hypotheticalState.portfolios[1].funds.map(f => f.wsodissue).sort();
		}
		if (!(lodash.isEqual(p1Wsod, p1HypoWsod) && lodash.isEqual(p2Wsod, p2HypoWsod))) {
			_templateData.startPeriod = this.state.hypotheticalState.startPeriod;
			_templateData.endPeriod = this.state.hypotheticalState.endPeriod;
			_templateData.balanceInitial = this.props.balanceInitial;
			this.modifyInvestments(_templateData);
		} else {
			if (this.state.hypotheticalState.portfolios && this.state.hypotheticalState.portfolios.length > 0) {
				templateData.Portfolios = this.state.hypotheticalState.portfolios;
				templateData.balanceInitial = this.state.hypotheticalState.totalInvestment;
				for (let i = 0; i < this.state.hypotheticalState.portfolios.length; i++) {
					templateData.Portfolios[i].Portfolio = templateData.Portfolios[i].funds;
				}
				templateData.startPeriod = this.state.hypotheticalState.startPeriod;
				templateData.endPeriod = this.state.hypotheticalState.endPeriod;
			}

			this.modifyInvestments(templateData);
		}
	}

	saveTemplateSettings = () => {
		this.props.requestMfsSiteInactiveFlag();
		// Get description
		const txtDescriptionValue = document.getElementById('txt-description').value;

		// If template name is not empty (prosp and state)
		if (this.props.TemplateName.trim() !== '' || this.state.templateName.trim() !== '') {

			// If template name is greater than 50 characters, disable "Save" button and show error name msg
			if (this.props.TemplateName.trim().length > 50 || this.state.templateName.length > 50) {
				this.setState({
					isSaveDisabled: true,
					statusMessage: ValidationMessages.PortfolioTemplateLengthCheck
				});
			} else if (txtDescriptionValue.length > 120) { // If template description is greater than 120 characters, disable "Save" button and show error desc msg
				this.setState({
					isSaveDisabled: true,
					statusMessage: ValidationMessages.PortfolioDescriptionLengthCheck
				});
			} else { // If template name & description are valid
				let newTemplateData = ""

				// Get the portfolio comparison template data
				newTemplateData = this.setPortfoliocomparisonTemplateData();
				
				newTemplateData.isMarketingPage = this.state.templateResult.isMarketingPage;

				// set the sections from Constants
				newTemplateData.sections = Constant.portfolioTemplateData.sections;

				// Create template settings: Template (id, name & data), reportType, desc
				const templateSettingsData = {
					templateId: 0,
					templateName: this.state.templateName.length > 0 ? this.state.templateName.trim() : this.props.TemplateName.trim(),
					templateData: JSON.stringify(newTemplateData),
					reportType: this.props.modalIsPortfolioCompare ? Constant.reportTypes.portfoliocomparison : Constant.reportTypes.singleportfolio, // Saving Single Portfolio
					description: document.getElementById('txt-description').value
				};

				//Check whether it's for International user
				if(this.props.userType.response === 'International') {
					templateSettingsData.reportType = (templateSettingsData.reportType == Constant.reportTypes.singleportfolio) ?
													  Constant.reportTypes.mil_singleportfolio : Constant.reportTypes.mil_portfoliocomparison;

				}

				// Disbale Save button, status message set to empty
				this.setState({
					isSaveDisabled: true,
					statusMessage: ''
				});

				// Portfolio Compare
				if (this.props.modalIsPortfolioCompare) {

					// For "Save As New" comparison template
					if (this.props.TemplateId > 0 || this.props.ComparisonPortfolioTemplateId > 0) {
						templateSettingsData.containerId = this.props.comparsionWorkingContainerIds;
						templateSettingsData.saveType = "saveasnew";
					} else {
						// For comparison template to be saved from scratch
						templateSettingsData.containerId = this.props.comparisonContainerIds;
						templateSettingsData.workingCopyContainerId = this.props.comparsionWorkingContainerIds;
						templateSettingsData.saveType = "new";
					}
					this.props.requestSaveComparisonPortfolioTemplateDetailsApi(templateSettingsData);
				} else {
					this.props.requestSaveTemplateDetailsApi(templateSettingsData);
				}
			}
		}
	}
	replacePortfolioTemplateSettings = (event) => {
		let portfoliotype = event.target.attributes["data-portfoliotype"].value;
		this.replacePortfolioSettings(portfoliotype);
	}

	replacePortfolioSettings(portfoliotype) {
		let newTemplateData =cloneDeep(Constant.portfolioTemplateData);
		let _templateId = -1;
		let _templateName = '';
		if (portfoliotype === 'P1') {
			newTemplateData.Portfolios[0].Portfolio = this.state.P1;
			newTemplateData.Portfolios[1].Portfolio = [];
			_templateId = this.state.currentP1Id;
			_templateName = this.state.currentP1Name;
		} else if (portfoliotype === 'P2') {
			newTemplateData.Portfolios[0].Portfolio = this.state.P2;
			newTemplateData.Portfolios[1].Portfolio = [];
			_templateId = this.state.currentP2Id;
			_templateName = this.state.currentP2Name;
		}
		newTemplateData.isMarketingPage = this.props.isMarketingPage;
		//initializing sections for old templates
		newTemplateData.sections = this.state.templateResult.sections !== undefined ? this.state.templateResult.sections : Constant.portfolioTemplateData.sections;
		const templateSettingsData = {
			templateId: _templateId,
			templateName: _templateName,
			templateData: JSON.stringify(newTemplateData),
			reportType: Constant.reportTypes.singleportfolio
		};

		if (portfoliotype == 'P1') {
			this.setState({
				statusMessage: 'Portfolio Saved',
				statusMessageP2: '',
				isSaveDisabled: true
			});
			this.props.requestSaveTemplateDetailsApi(templateSettingsData);
		}
		if (portfoliotype == 'P2') {
			this.setState({
				statusMessage: '',
				statusMessageP2: 'Portfolio Saved',
				isSaveDisabledP2: true
			});
			this.props.requestSaveTemplateDetailsP2Api(templateSettingsData);
		}
	}

	saveReplaceAllTemplateSettings = (event) => {
		this.isPortfolioAllSaved = true;
		if (this.state.P1.length > 0) {
			if ((this.state.defaultSelectedPortfolioOneId >= -1) && this.state.radioSaveReplaceSelectedP1 !== "Replace") {
				const txtDescriptionValue = document.getElementById('txt-description').value;
				this.savePortfolio(txtDescriptionValue, 'txt-description', 1);
			}
		}
		if (this.state.P2.length > 0) {
			if ((this.state.defaultSelectedPortfolioTwoId >= -1) && this.state.radioSaveReplaceSelectedP2 !== "Replace") {
				const txtDescriptionValue = document.getElementById('txt-descriptionP2').value;
				this.savePortfolio(txtDescriptionValue, 'txt-descriptionP2', 2);
			}
		}

		if (this.state.P1.length > 0) {
			if ((this.state.defaultSelectedPortfolioOneId > -1) && this.state.radioSaveReplaceSelectedP1 === "Replace") {
				this.replacePortfolioSettings('P1');
			}
		}
		if (this.state.P2.length > 0) {
			if ((this.state.defaultSelectedPortfolioTwoId > -1) && this.state.radioSaveReplaceSelectedP2 === "Replace") {
				this.replacePortfolioSettings('P2');
			}
		}

	}


	savePortfolioTemplateSettings = (event) => {
		let portfoliotype = event.target.attributes["data-portfoliotype"].value;
		let description = (portfoliotype == "P1") ? 'txt-description' : 'txt-descriptionP2';
		const txtDescriptionValue = document.getElementById(description).value;
		if (portfoliotype == "P1" && this.state.templateName.trim() !== '') {
			this.savePortfolio(txtDescriptionValue, description, 1);
		} else if (portfoliotype == "P2" && this.state.templateNameP2.trim() !== '') {
			this.savePortfolio(txtDescriptionValue, description, 2);
		}
	}
	
	savePortfolio = (txtDescriptionValue, description, portIndex) => {
		let templateName = portIndex == 1 ? this.state.templateName: this.state.templateNameP2;
		if (templateName.length > 50 || txtDescriptionValue.length > 120) {
			if (portIndex == 1) {
				this.setState({
					isSaveDisabled: true,
					statusMessage: ValidationMessages.PortfolioTemplateLengthCheck
				});
			} else if (portIndex == 2) {
				this.setState({
					isSaveDisabledP2: true,
					statusMessageP2: ValidationMessages.PortfolioTemplateLengthCheck
				});
			}
		} else {
			let newTemplateData = cloneDeep(Constant.portfolioTemplateData);
			newTemplateData.Portfolios[0].Portfolio = portIndex == 1 ? this.state.P1: this.state.P2;
			newTemplateData.Portfolios[1].Portfolio = [];
			newTemplateData.isMarketingPage = this.state.templateResult.isMarketingPage;
			newTemplateData.sections = (this.props.userType.response === 'International' ? Constant.sectionMasterDataIntl.sections: Constant.sectionMasterData.sections);
			const templateSettingsData = {
				templateId: 0,
				templateData: JSON.stringify(newTemplateData),
				reportType: Constant.reportTypes.singleportfolio,
				description: document.getElementById(description).value
			};

			// handle the international user case
			templateSettingsData.reportType = (this.props.userType.response === 'International') ? Constant.reportTypes.mil_singleportfolio : Constant.reportTypes.singleportfolio;

			templateSettingsData.templateName = portIndex == 1 ? (templateName.length > 0 ? templateName.trim() : this.props.TemplateName.trim()): templateName.trim();
			if (portIndex == 1) {
				this.setState({
					isSaveDisabled: true,
					statusMessage: ''
				});
			} else if (portIndex == 2) {
				this.setState({
					isSaveDisabledP2: true,
					statusMessageP2: ''
				});
			}
			portIndex == 1 ? this.props.requestSaveTemplateDetailsApi(templateSettingsData): this.props.requestSaveTemplateDetailsP2Api(templateSettingsData);
		}
	}

	goToHomePage = () => {
		window.location.reload();
	}

	/**
	 * Get sections for portfolio comparison modified as per ticket: MFS-1601
	 * Note: We are updating name also because the pdf code uses this also.
	 */
	getSectionsForPortfolioComparison = () => {

		let sections = cloneDeep((this.props.userType.response === 'International' ? Constant.sectionMasterDataIntl.sections: Constant.sectionMasterData.sections));

		// change the section order
		sections.forEach(section => {
			if (section.tabName == 'Fund Details, Statistics & Performance') {
				if (section.sectionid == '5') {
					section.sectionid = '12';
				}
			
			}
		})

		// Set section and sub-section as active previously selected by user
		if (this.props.templateData && this.props.templateData.sections) {
			// Loop through prev selected saved sections
			this.props.templateData.sections.forEach(section => {
				if (section.isActive) {
					section.subsections.forEach(subsection => {
						if (subsection.isActive) {
							// Make same section and sub-section active as previously saved by user
							sections.forEach(sectionNew => {
								if (sectionNew.sectionid === section.sectionid) {
									sectionNew.isActive = true;
									sectionNew.inputtype = section.inputtype;
									sectionNew.subsections.forEach(subsectionNew => {
										if (subsectionNew.id === subsection.id) {
											subsectionNew.isActive = true;
										}
									})
								}
							})
						}
					})
				}
			});
			this.props.templateData.sections.forEach(section => {
				if (section.isActive) {
					let itemIndex = sections.findIndex(x=> x.sectionid == section.sectionid);
					if(itemIndex > -1) {
						let item = sections.find(x=> x.sectionid == section.sectionid);
					
						section.subsections.forEach(subsection => {
							let subItemIndex = item.subsections.findIndex(x=> x.id == subsection.id);
							if(subItemIndex > -1){
								let subItem = item.subsections.find(x=> x.id == subsection.id);
								item.subsections.splice(subItemIndex, 1);
								item.subsections.splice(item.subsections.length, 0, subItem);
							}
						})
						sections.splice(itemIndex, 1);
						sections.splice(sections.length, 0, item);
					}

				}
			});

		}
		sections.forEach(section => {
			if (section.sectionname === 'Funds In Focus') {
				if(this.props.userType.response === 'International') {
					section.sectiondisplayname = 'Portfolios in Focus'
				}
				section.subsections.forEach(subsection => {
					if (subsection.name === 'Fund Basic' || subsection.name === 'Fund Basics') {
						// Fund Basics module has been replaced with Planning Assumptions
						if(this.props.userType.response === 'International') {
							subsection.id = 4;
						} else {
							subsection.id = 3;
						}
						subsection.name = 'Planning Assumptions';
						subsection.subsectiondisplayname = 'Planning Assumptions';
					}
					if (subsection.name === 'MIFID 5 Year Rolling Returns') {
						// MIFID 5 Year Rolling Returns module has been replaced with 5 Year Rolling Returns
						subsection.id=4;
						subsection.name = '5 Year Rolling Returns';
						subsection.subsectiondisplayname = '5 Year Rolling Returns';
					}
				})
			} else if (section.sectionname === 'Performance') {
				section.subsections.forEach(subsection => {
					if (subsection.name === 'Calendar Year Returns') {
						// Calendar year returns has been replaced with Investment Details
						subsection.name = 'Investment Details';
						subsection.subsectiondisplayname = 'Investment Details';
					}
				})
			} else if (section.sectionname === 'Risk & Performance Statistics') {
				// M* Percentile Ranking has been removed from Risk and Performance
				section.subsections = section.subsections.filter(subsection => {
					return (!(subsection.name === 'Morningstar Percentile Rank' || subsection.name === 'Sustainability Rating Intl' || subsection.name === 'Sustainability Rating'));
				})
			} else if (section.sectionname === 'Standardized Performance') {
				if(this.props.userType.response === 'International'){	
					section.sectionname = 'Fund Information';	
					section.sectiondisplayname = 'Fund Information';
				}
				let mstarRatingsSubSection = '';
				let fund5YearRollingReturnSubsection = ''; 
				section.subsections.forEach(subsection => {
					if (subsection.name === 'Morningstar Ratings') {
						// M* rank has been replaced with Fund Dates table
						subsection.name = 'Fund Dates Table';
						subsection.subsectiondisplayname = 'Fund Dates';
						mstarRatingsSubSection = cloneDeep(subsection);
						mstarRatingsSubSection.name=  "Morningstar Ratings";
						mstarRatingsSubSection.subsectiondisplayname=  "Morningstar Ratings";
					}
					if(this.props.userType.response === 'International'){	
						fund5YearRollingReturnSubsection = cloneDeep(subsection);
						fund5YearRollingReturnSubsection.id = 1;
						fund5YearRollingReturnSubsection.name=  "Fund 5 Year Rolling Returns";
						fund5YearRollingReturnSubsection.subsectiondisplayname=  "Fund 5 Year Rolling Returns";			
					
					}
				})
				if(this.props.userType.response === 'International'){					
				section.subsections = section.subsections.filter(subsection => {
					return (!(subsection.name === 'Total & Load Adjusted Returns'));
				})
				}
				section.subsections.push(mstarRatingsSubSection)
				if(this.props.userType.response === 'International') {
					section.subsections.push(fund5YearRollingReturnSubsection)
				}
			} else if (section.sectionname === 'Correlation Matrix') {
				// Enable for Portfolio Comparison
			}
		})
		return sections;
	}
	validateInitialInvestment = (tabErrors) => {
		let PortfolioInvestmentSumP1 = 0;
		let PortfolioInvestmentSumP2 = 0;
		let FundsCountP1 = 0;
		let FundsCountP2 = 0;
		let count = 0;
		this.state.hypotheticalState.portfolios.map(portfolio => {
		  portfolio.funds.map(item => {
			if (count === 0) {
			  FundsCountP1++;
			  PortfolioInvestmentSumP1 = parseFloat(PortfolioInvestmentSumP1) + parseFloat(item.amountInvestedPercent);
			} else {
			  FundsCountP2++;
			  PortfolioInvestmentSumP2 = parseFloat(PortfolioInvestmentSumP2) + parseFloat(item.amountInvestedPercent);
			}
		  });
		  count++;
		});
		PortfolioInvestmentSumP1 = parseFloat(PortfolioInvestmentSumP1.toFixed(4));
		PortfolioInvestmentSumP2 = parseFloat(PortfolioInvestmentSumP2.toFixed(4));
		let errorMessage = 'Total Investment percentage is not equal to 100% for '
		let showMessage = false;
		if ( PortfolioInvestmentSumP1 !== 1 && FundsCountP1 > 0) {
		  showMessage = true;
		  errorMessage += 'Portfolio One' + (PortfolioInvestmentSumP2 !== 1 && FundsCountP2 > 0 ? ' and ' : '.');
		}
		if (PortfolioInvestmentSumP2 !== 1 && FundsCountP2 > 0) {
		  showMessage = true;
		  errorMessage += 'Portfolio Two.';
		}
		if(showMessage){
		  tabErrors.push(errorMessage);
		}
	  }
	
	  validateEventsStartDate = (tabErrors) => {
		let startDate = (this.state.hypotheticalState.startPeriod-1); // On UI we are showing start period + 1
		let isValid = true;
		//we are checking first event date of each event type as they are in ascending date order
		let events = [];
		events.push(this.props.schedules); 
		events.push(this.props.investmentSchedules);
		events.push(this.props.withdrawalSchedules);
		events.push(this.props.schedulesP2);
		events.push(this.props.investmentSchedulesP2);
		events.push(this.props.withdrawalSchedulesP2);

		events.forEach(item => {
			if(item.length > 0 && (parseInt(item[0].DateBegin, 10) < startDate)) {			
				isValid = false;			
				return;
			}			  
		})
		
		if(!isValid) {
			tabErrors.push('One or more events have a start date that precedes the initial investment. Please review events.');
		}		
	  }

	  eventsValidate = (tabErrors) => {
		let errorMessage = 'Unresolved fund(s) exist in ';
		let list = document.getElementsByClassName('dv-fund-events');
		let p1Count = 0;
		let p2Count = 0;
		let showMessage = false;
		for (const key in list) {
		  if (list.hasOwnProperty(key)) {
			const element = list[key];
			let portfolioIndex = element.getAttribute('data-index');
			if (element.getAttribute('data-is-invalid') === "true") {
			  if(portfolioIndex === "P1"){
				p1Count++; 
			  } else {
				p2Count++;
			  }
			  showMessage = true;
			}
		  }
		}
		if (p1Count > 0) {
		  errorMessage += 'Portfolio One' + (p2Count > 0 ? ' and ' : '.');
		}
		if (p2Count > 0) {
		  errorMessage += 'Portfolio Two.';
		}
		if(showMessage){
		  tabErrors.push(errorMessage);
		}
	  }

	navigateToModuleSelection = (e) => {
		this.props.requestMfsSiteInactiveFlag();
		this.setComparisonInvestmentDetails();
		// Get current object containing current state
		const newTemplateData =  cloneDeep(Constant.portfolioTemplateData);

		// Step 1: Set Portfolios data
		newTemplateData.Portfolios = [];
		for (let i = 0; i < this.state.hypotheticalState.portfolios.length; i++) {
			const portfolio = this.state.hypotheticalState.portfolios[i];
			const portfolioName = (i === 1) ? 'Portfolio 1': ((i === 2) ? 'Portfolio 2': '');
			newTemplateData.Portfolios.push({Portfolio: portfolio.funds, protfolioName: portfolioName});
		}

		// Step 2: Set balanceInitial
		newTemplateData.totalInvestment = this.state.hypotheticalState.totalInvestment;

		// Step 3: Set time period and period list
		newTemplateData.selectedPeriod = this.state.hypotheticalState.selectedPeriod ? this.state.hypotheticalState.selectedPeriod: {label: '3 Years', value: 41652};
		newTemplateData.periodList = this.state.hypotheticalState.periodList;

		// Step 4: Set benchmark
			newTemplateData.benchmark.benchmarks = [];
			let selectedBenchmark = this.state.benchmarks.find(item => { return item.isChecked === true });
			if(selectedBenchmark !== null){
				newTemplateData.benchmark = selectedBenchmark
			}
		//}
		newTemplateData.startPeriod = this.state.hypotheticalState.startPeriod - 1;//1 day prior to be gone to backend.
		newTemplateData.endPeriod =this.state.hypotheticalState.endPeriod; //keep it as it is
		newTemplateData.isFromModuleSelection = true;
		newTemplateData.hypotheticalStatePvP = this.state.hypotheticalState;
		newTemplateData.oldestInceptionDatesPvP = this.props.oldestInceptionDates
		// Step 5: Set "isMarketingPage"
		newTemplateData.isMarketingPage = this.props.isMarketingPage;
		if(this.props.userType.response === 'International'){
			newTemplateData.currency = this.state.templateResult.currency !== undefined ?
											this.state.templateResult.currency : "USD"
										
		}

		// Step 6: Set sections
		newTemplateData.sections = this.state.templateResult.sections !== undefined ? 
																					this.state.templateResult.sections : 
																					this.getSectionsForPortfolioComparison();

		if (newTemplateData.sections && newTemplateData.sections.length === 0) { // new template creation.
			newTemplateData.sections = cloneDeep(Constant.portfolioTemplateData.sections);
		}

		// Remove "Excess Return Correlation Matrix" group for Funds
		newTemplateData.sections = newTemplateData.sections.filter(section => {
			if (section.sectionname === "Correlation Matrix") {
				//subsections saved in DB
                let savedSubSections = Object.values(section.subsections);
				let matchedsubsection = savedSubSections.find(x => x.id === 1);
                  
				let filterSubSections = [
						{
							id: matchedsubsection.id,
							name: matchedsubsection.name,
							subsectiondisplayname: matchedsubsection.subsectiondisplayname,
							isLocked: true,
							isActive: matchedsubsection.isActive,
							canbeAddedAndLocked: false
						}
					]
					section.subsections = filterSubSections;
			}
			return section
		})

		// Step 7: Closes the "Select Funds" modal and benchmark modal
		if (this.props.closeModal) {
			this.setState({
				isShowBenchmarkResults: false,
			})
			this.props.closeModal();
		}

		// Step 8: Get all funds wsod issueId in the portfolios and request valid "Rolling Return Period"
		let funds = []
		for (let i = 0; i < this.state.hypotheticalState.portfolios.length; i++) {
			const portfolio = this.state.hypotheticalState.portfolios[i];
			if (portfolio && portfolio.funds && portfolio.funds.length > 0) {
				for (let j = 0; j < portfolio.funds.length; j++) {
					const fund = portfolio.funds[j];
					if (fund) {
						funds.push(fund.wsodissue);	
					}
				}
			}
		}
		let _fundshareclassId = [];
		for (let j = 0; j < newTemplateData.Portfolios.length; j++) {
			if (newTemplateData.Portfolios[j].Portfolio.length > 0) {
			const fund = newTemplateData.Portfolios[j].Portfolio[0].fundshareClassId;		
			if (fund) {
				_fundshareclassId.push(fund);	
			}
		}
		}
		this.props.requestValidRollingReturnPeriodDataApi(funds.toString(),this.props.userType.response === 'International'? 'yes' : 'no',_fundshareclassId);

		newTemplateData.modalIsPortfolioCompare = this.props.modalIsPortfolioCompare;
		
		// Step 11: Set the portfolios comparison data in global state for ModuleSelection page
		let eventCount = this.props.schedules.length + this.props.schedulesP2.length+this.props.investmentSchedules.length +
		this.props.investmentSchedulesP2.length + this.props.withdrawalSchedules.length + this.props.withdrawalSchedulesP2.length;

		// [2774]: Step 12: Add language property if user is international
		if(this.props.userType.response === 'International') {
			newTemplateData.language = this.props.templateData ? this.props.templateData.language : 'en-GB';
		}

		const inputParams = {
			templateDataPortfolio: JSON.stringify(newTemplateData),
			templateData: JSON.stringify({}),
			containerId: this.props.workingCopyConatainerIds,
			reportId: this.props.reportId,
			templateId: this.props.TemplateId,
			editComparisonTemplateId: this.props.editComparisonTemplateId, // Needed for "Use This Template" case
			editComparisonTemplateName: this.props.editComparisonTemplateName, // Needed for "Use This Template", in Replace modal
			templateName: this.props.TemplateName,
			shouldLoadDefaultTimePeriods: this.isPortfolioFundChangeDone || this.isPortfolioFundChangeFromModuleDone, //true, //shouldLoadDefaultTimePeriods
			shouldLoadDefaultCMTimePeriods: false, // this paramter is used for fund only
			screenerOutput:this.state.screenerOutput,
			portfolioScreenerOutput:this.state.screenerOutput,
			balanceInitial: this.props.balanceInitial,
			comparsionWorkingContainerIds:this.props.comparsionWorkingContainerIds,
			comparisonContainerIds:this.props.comparisonContainerIds,
			balanceInitial:this.props.balanceInitial,
			portfolioNamesList:this.props.portfolioNamesList,
			isPortfolio:true,
			eventsCount :eventCount
		};

		if(this.props.hypotheticalStatePvP && (this.props.hypotheticalStatePvP.startPeriod != this.state.hypotheticalState.startPeriod)) {
			inputParams.shouldLoadDefaultTimePeriods = true;
		}

		this.isPortfolioFundChangeDone = false;
		this.isPortfolioFundChangeFromModuleDone = false;
		this.props.setTemplateData(inputParams);
	}

	validatePortfolios(oldestInceptionDates) {
		let output = this.getPortfoliosOldestInceptionDate(oldestInceptionDates);
		let errorMessages = [];

		let _p1MoreThan3YFund = output.p1.filter(x=>x.diffInMonths >= 36);
		let _p2MoreThan3YFund = output.p2.filter(x=>x.diffInMonths >= 36);
		let _p1LessThan3MFund = output.p1.filter(x=>x.diffInMonths < 3);
		let _p2LessThan3MFund = output.p2.filter(x=>x.diffInMonths < 3);

		const PORTFOLIO_FIRST = 'Portfolio 1';
		const PORTFOLIO_SECOND = 'Portfolio 2';
		let messageText = '';
		let portfolioText = '';
		let messageText1 = 'not have sufficient data to allow for a comparison. Please select funds with data history listed below based on the extended performance inception date.';
		let fundsfailed = false;
		
		if((_p1MoreThan3YFund.length == 0 && output.p1.length > 0) || (_p1LessThan3MFund.length > 0)) {
			portfolioText =PORTFOLIO_FIRST;
			if(!this.props.modalIsPortfolioCompare) {
				portfolioText = 'Portfolio';
			}
			fundsfailed = true;
		}
		if((_p2MoreThan3YFund.length == 0 && output.p2.length > 0) || (_p2LessThan3MFund.length > 0)) {
			if (portfolioText == PORTFOLIO_FIRST) {
				portfolioText = portfolioText + ' and ' + PORTFOLIO_SECOND;
			} else {
				portfolioText = PORTFOLIO_SECOND;
			}
			fundsfailed = true;
		}

		if (fundsfailed) {
			messageText1 = (portfolioText.length < 15 ? ' does ' : ' do ') + messageText1;
			messageText = portfolioText + messageText1;
			
			errorMessages.push(messageText);
			errorMessages.push('At least one fund exist with more than 3 years of history');
			errorMessages.push('All funds must have a minimum of 3 months of history');

			if (_p1LessThan3MFund.length > 0) {
				errorMessages.push((this.props.modalIsPortfolioCompare ? PORTFOLIO_FIRST : portfolioText) + ': ' +  _p1LessThan3MFund.map(u => u.symbol).join(', '));
			}
			if (_p2LessThan3MFund.length > 0) {
				errorMessages.push(PORTFOLIO_SECOND + ': ' + _p2LessThan3MFund.map(u => u.symbol).join(', '));
			}
		}
		this.setState({portoliosErrors: errorMessages});	
	
		return errorMessages.length == 0;
	}

	getPortfoliosOldestInceptionDate(oldestInceptionDates) {
		let result = { p1: [], p2: [] };

		let reqdPerformanceDate = true;
		this.state.screenerOutput.map(item => {
				if (item.isActive) {
					oldestInceptionDates.map(inceptionDates => {
						if (inceptionDates.wsodIssue === item.MSDWSODIssue) {
							result.p1.push({
								symbol: item.MFSSymbol,
								wsodissue: item.MSDWSODIssue,
								inceptionDate: inceptionDates.inceptionDate,
								oldestShareClassInceptionDate: inceptionDates.oldestShareClassInceptionDate,
								diffInMonths: utilityFunctions.getDiffInMonths(utilityFunctions.getStartDateOfNextFullMonth(inceptionDates.oldestShareClassInceptionDate) -1, item.MSDPerformanceAsOfDate)
							});
						}
					});

				}
				if (item.isActiveP2) {
					oldestInceptionDates.map(inceptionDates => {
						if (inceptionDates.wsodIssue === item.MSDWSODIssue) {
							result.p2.push({
								symbol: item.MFSSymbol,
								wsodissue: item.MSDWSODIssue,
								inceptionDate: inceptionDates.inceptionDate,
								oldestShareClassInceptionDate: inceptionDates.oldestShareClassInceptionDate,
								diffInMonths: utilityFunctions.getDiffInMonths(utilityFunctions.getStartDateOfNextFullMonth(inceptionDates.oldestShareClassInceptionDate) -1, item.MSDPerformanceAsOfDate)
							});
						}
					});

			}
		});
		return result;
	}



	getComparisonOldestInceptionDate = (oldestInceptionDates, _asOfPerformanceDate, _p1, _p2, _p1Count, _p2Count, _p1OldestShareClassDates, _p2OldestShareClassDates) => {
		let asOfDateItems = [];
		let tempScreenerOutput = cloneDeep(this.state.screenerOutput)
		tempScreenerOutput = tempScreenerOutput.filter(x => x.isActive || x.isActiveP2)
		tempScreenerOutput.map(item => {
			if (asOfDateItems.indexOf(item.MSDPerformanceAsOfDate) < 0) {
				asOfDateItems.push(item.MSDPerformanceAsOfDate);
			}
			if (item.isActive) {
				oldestInceptionDates.map(inceptionDates => {
					if (inceptionDates.wsodIssue === item.MSDWSODIssue) {
						_p1.funds.push({
							name: item.MSDFundName,
							symbol: this.props.userType.response === 'International' ? item.MFSSymbolISIN : item.MFSSymbol,
							wsodissue: item.MSDWSODIssue,
							inceptionDate: inceptionDates.inceptionDate,
							oldestShareClassInceptionDate: inceptionDates.oldestShareClassInceptionDate,
							startPeriod: '',
							amountInvestedPercent: 0,
							isExtended: inceptionDates.oldestShareClassInceptionDate < inceptionDates.inceptionDate,
                            salesLoadDeferred: (item.MSDSalesLoadDeferred !== undefined ? item.MSDSalesLoadDeferred: -1),
                            fundshareClassId:item.MSDFundShareClassID,
						});
						_p1OldestShareClassDates.push(inceptionDates.oldestShareClassInceptionDate);
					}
				});
				_p1Count++;
			}
			if (item.isActiveP2) {
				oldestInceptionDates.map(inceptionDates => {
					if (inceptionDates.wsodIssue === item.MSDWSODIssue) {
						_p2.funds.push({
							name: item.MSDFundName,
							symbol: this.props.userType.response === 'International' ? item.MFSSymbolISIN : item.MFSSymbol,
							wsodissue: item.MSDWSODIssue,
							inceptionDate: inceptionDates.inceptionDate,
							oldestShareClassInceptionDate: inceptionDates.oldestShareClassInceptionDate,
							startPeriod: '',
							amountInvestedPercent: 0,
							isExtended: inceptionDates.oldestShareClassInceptionDate < inceptionDates.inceptionDate,
                            salesLoadDeferred: (item.MSDSalesLoadDeferred !== undefined ? item.MSDSalesLoadDeferred: -1),
                            fundshareClassId:item.MSDFundShareClassID,
						});
						_p2OldestShareClassDates.push(inceptionDates.oldestShareClassInceptionDate);
					}
				});
				_p2Count++;
			}
			if (_p1Count === 1 || _p2Count === 1) {
				_asOfPerformanceDate = item.MSDPerformanceAsOfDate ? item.MSDPerformanceAsOfDate : this.state.performanceAsOfDate ;
			}
		});

		_asOfPerformanceDate = asOfDateItems.length > 0 ? Math.min.apply(null, asOfDateItems.map(item => item)) : this.state.performanceAsOfDate ;

		const output = { asOfPerformanceDate: _asOfPerformanceDate, p1: _p1, p2: _p2, p1Count: _p1Count, p2Count: _p2Count, p1OldestShareClassDates: _p1OldestShareClassDates, p2OldestShareClassDates: _p2OldestShareClassDates }
		return output;
	}		

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(this.props.xidFundShareClassMappings != nextProps.xidFundShareClassMappings) {
			let dictFundshareWsod = {};
			nextProps.xidFundShareClassMappings.forEach(item => {				
				dictFundshareWsod[item.FundShareclassID] = item.XID;
			});

			let tempScreener = cloneDeep(this.state.screenerOutput);
			tempScreener.forEach(item=>{
				if (this.props.userType.response === 'International' && !Constant.CustomFundXids.includes(item.MSDWSODIssue)) {
					item.MSDWSODIssue = dictFundshareWsod[item.MSDFundShareClassID];
				}
			})

			this.setState({screenerOutput: tempScreener},() =>{
				this.beforeMovingToBenchmark();
			});						
		}
		if (
			nextProps.performanceAsOfScreenerData !== this.props.performanceAsOfScreenerData &&
			nextProps.performanceAsOfScreenerData && nextProps.performanceAsOfScreenerData.rows
		  ) {
			let dt = 0;
			nextProps.performanceAsOfScreenerData.rows.map(screenerItem => {
			  screenerItem.results.map(item => {
				if (item.field == "MSDPerformanceAsOfDate") {
				  dt = item.value
				}
			  });
			});
			this.setState({
			  performanceAsOfDate: dt
			});
		  }
		if (nextProps.mfsSiteInactive === true) {
			this.props.callMaintenace();			
		}
		if(this.props.isFromModuleSelection) {
			if(this.state.hypotheticalState && (this.state.hypotheticalState.totalInvestment > 0)){
			} else if(nextProps.hypotheticalStatePvP){
				this.isFromModuleSelection = nextProps.isFromModuleSelection;
				this.setState({hypotheticalState: nextProps.hypotheticalStatePvP, isLoading: true });
			}
		}
				
		//Remove all schedules Rebalances from Portfolio 1
		if (!this.state.showHypotheticals && this.props.schedules != nextProps.schedules && nextProps.schedules.length > 0) {
			let arrSchedules = this.getScheduleIds(nextProps.schedules);
			this.props.requestRemoveEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0], arrSchedules.join(','));
			this.setState({eventValidationModalIsOpen:false});
		}
	  	//Remove all schedules Rebalances from Portfolio 2
		if (!this.state.showHypotheticals && this.props.schedulesP2 != nextProps.schedulesP2 && nextProps.schedulesP2.length > 0) {
			let arrSchedules = this.getScheduleIds(nextProps.schedulesP2);
			this.props.requestRemoveEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[1], arrSchedules.join(','));
			this.setState({eventValidationModalIsOpen:false});
		}
		//Remove all schedules Fund/Portfolio Investment from Portfolio 1
		if (!this.state.showHypotheticals && this.props.investmentSchedules != nextProps.investmentSchedules && nextProps.investmentSchedules.length > 0) {
			let arrSchedules = this.getScheduleIds(nextProps.investmentSchedules);
			this.props.requestRemoveEventFrontinvestmentSchedulesApi(arrSchedules, this.props.workingCopyConatainerIds.split(',')[0]);
		}
		//Remove all schedules Fund/Portfolio Investment from Portfolio 2
		if (!this.state.showHypotheticals && this.props.investmentSchedulesP2 != nextProps.investmentSchedulesP2 && nextProps.investmentSchedulesP2.length > 0) {
			let arrSchedules = this.getScheduleIds(nextProps.investmentSchedulesP2);
			this.props.requestRemoveEventFrontinvestmentSchedulesApi(arrSchedules, this.props.workingCopyConatainerIds.split(',')[1]);
		}
		//Remove all schedules Fund/Portfolio Withdrawal from Portfolio 1
		if (!this.state.showHypotheticals && this.props.withdrawalSchedules != nextProps.withdrawalSchedules && nextProps.withdrawalSchedules.length > 0) {
			let arrSchedules = this.getScheduleIds(nextProps.withdrawalSchedules);
			this.props.requestRemoveEventFrontinvestmentSchedulesApi(arrSchedules, this.props.workingCopyConatainerIds.split(',')[0]);
		}
		//Remove all schedules Fund/Portfolio Withdrawal from Portfolio 2
		if (!this.state.showHypotheticals && this.props.withdrawalSchedulesP2 != nextProps.withdrawalSchedulesP2 && nextProps.withdrawalSchedulesP2.length > 0) {
			let arrSchedules = this.getScheduleIds(nextProps.withdrawalSchedulesP2);
			this.props.requestRemoveEventFrontinvestmentSchedulesApi(arrSchedules, this.props.workingCopyConatainerIds.split(',')[1]);
		}

		if (this.props.TemplateId === 0 && this.props.comparisonContainerIds != nextProps.comparisonContainerIds && nextProps.comparisonContainerIds !='') {
			if(!this.props.hypotheticalStatePvP) {
				this.props.requestGetHypoWorkingCopyContainerIdsApi(nextProps.comparisonContainerIds);
			}
		}

		/**
		 * [MFS-1876]: When user coming from PvP's "Use This Template" link, then create new working
		 * containerIds to be used in saving data for hypothetical tab.
		 */
		if (this.props.modalIsPortfolioCompare && this.props.TemplateId > 0 && this.props.comparisonContainerIds != nextProps.comparisonContainerIds && nextProps.comparisonContainerIds != '') {
			if(!this.props.hypotheticalStatePvP) {
				this.props.requestGetHypoWorkingCopyContainerIdsApi(nextProps.comparisonContainerIds);
			}
		}

        if(nextProps.workingCopyConatainerIds && this.props.workingCopyConatainerIds != nextProps.workingCopyConatainerIds &&
            nextProps.workingCopyConatainerIds.split(',')[0] !='undefined') {
			this.props.workingCopyContainerId(nextProps.workingCopyConatainerIds);
		}
		if (this.props.editComparisonTemplateId != nextProps.editComparisonTemplateId && (nextProps.editComparisonTemplateId > 0)) {
			this.setState({ isFirstTimeComparisonPortfolioEdit: true });			
		}
		if (this.props.isShowBenchmarkResults != nextProps.isShowBenchmarkResults && !nextProps.isShowBenchmarkResults) {
			this.setState({
				isShowBenchmarkResults: false
			});

		}

		if(this.props.comparisonPortfolioAllocations != nextProps.comparisonPortfolioAllocations && nextProps.comparisonPortfolioAllocations) {
			let dictWsodWeightage = {};			
			nextProps.comparisonPortfolioAllocations.investmentsListP1.map(item => {				
				return (dictWsodWeightage[item.wsod] = item.weightage);
			});
			this.p1Allocations = dictWsodWeightage;
			
			dictWsodWeightage = {};			
			nextProps.comparisonPortfolioAllocations.investmentsListP2.map(item => {			
				return (dictWsodWeightage[item.wsod] = item.weightage);
			});
			this.p2Allocations = dictWsodWeightage;
			this.modifyInvestments();
		}
		if (this.props.comparisonPortfolioInvestments != nextProps.comparisonPortfolioInvestments && nextProps.comparisonPortfolioInvestments) {
			let dictWsodWeightage = {};
			let dictWsodInvestments = {}
			nextProps.comparisonPortfolioInvestments.investmentsListP1.map(item => {
				return (dictWsodWeightage[item.wsod] = item.weightage);
			});
			nextProps.comparisonPortfolioInvestments.investmentsListP1.map(item => {
				return dictWsodInvestments[item.wsod] = item.investmentId;
			});
			
			this.setState({p1Allocations: dictWsodWeightage});
			this.setState({p1Investments: dictWsodInvestments});
			dictWsodWeightage = {};
			dictWsodInvestments = {}
			nextProps.comparisonPortfolioInvestments.investmentsListP2.map(item => {
				return (dictWsodWeightage[item.wsod] = item.weightage);
			});
			nextProps.comparisonPortfolioInvestments.investmentsListP2.map(item => {
				return dictWsodInvestments[item.wsod] = item.investmentId;
			});
			
			this.setState({p2Allocations: dictWsodWeightage});
			this.setState({p2Investments: dictWsodInvestments});
			
			this.setState({isLoading: false});

			if (this.props.updateBalanceInitial) {
				this.props.updateBalanceInitial(nextProps.comparisonPortfolioInvestments.balanceInitial);	
			}
		}

		if (this.props.showHypotheticals !== nextProps.showHypotheticals) {
			if (nextProps.showHypotheticals) {
				let wsodIssueArray = []
				this.state.screenerOutput.map(item => {
					if (item.isActive || item.isActiveP2) {
						wsodIssueArray.push(item.MSDWSODIssue);
					}
				});
				
				this.props.requestOldestInceptionDatesApi(wsodIssueArray.join(','), (this.props.userType.response === 'International') ? 'yes' : 'no');
			} else {
				this.setState({ showHypotheticals: false });
			}
		}
		if (this.props.isResetDropdowns != nextProps.isResetDropdowns && nextProps.isResetDropdowns) {
			this.setState({
				defaultSelectedPortfolioTwoText: "Select Portfolio Two",
				defaultSelectedPortfolioOneText: "Select Portfolio One"
			});
		}
		if (this.props.showAddAllTooltip != nextProps.showAddAllTooltip) {
			this.setState({ showAddAllTooltipP1: false, showAddAllTooltipP2: false });
		}
		//For save comparison portfolio template
		if (
			nextProps.saveComparisonPortfolioTemplateDetails !== this.props.saveComparisonPortfolioTemplateDetails &&
			nextProps.saveComparisonPortfolioTemplateDetails.success === 1
		) {
			if (nextProps.saveComparisonPortfolioTemplateDetails.workingCopyContainerId !== undefined) {
			}
			if (this.props.modalIsPortfolioCompare && this.state.isShowBenchmarkResults) {
				this.setState({
					confirmationModalIsOpen: true, saveModalIsOpen: false,
					replaceModalIsOpen: false
				});
			}

		} else if (nextProps.saveComparisonPortfolioTemplateDetails.success === 0 && nextProps.saveComparisonPortfolioTemplateDetails !== this.props.saveComparisonPortfolioTemplateDetails) {
			this.setState({
				confirmationModalIsOpen: false,
				statusMessage: nextProps.saveComparisonPortfolioTemplateDetails.returnMessage
			});
		}

		if (
			nextProps.saveTemplateDetails !== this.props.saveTemplateDetails &&
			nextProps.saveTemplateDetails.success === 1
		) {
			if (this.props.modalIsPortfolioCompare && !this.state.isShowBenchmarkResults) {
				this.isPortfolio1Saved = true;

				this.setState({
					confirmationModalIsOpen: false, saveModalIsOpen: false,
					replaceModalIsOpen: false,
					statusMessage: 'Portfolio Saved',
					currentP1Id: nextProps.saveTemplateDetails.templateId,
					currentP1Name: nextProps.saveTemplateDetails.templateName
				});

				this.checkForPortfolioConfirmationModal();
			} else {
				this.setState({
					confirmationModalIsOpen: true, saveModalIsOpen: false,
					replaceModalIsOpen: false
				});
			}

		} else if (nextProps.saveTemplateDetails.success === 0 && nextProps.saveTemplateDetails !== this.props.saveTemplateDetails) {
			this.isPortfolio1Saved = false;
			this.setState({
				confirmationModalIsOpen: false,
				statusMessage: nextProps.saveTemplateDetails.returnMessage
			});
		}

		if (
			nextProps.saveTemplateDetailsP2 !== this.props.saveTemplateDetailsP2 &&
			nextProps.saveTemplateDetailsP2.success === 1
		) {
			if (this.props.modalIsPortfolioCompare && !this.state.isShowBenchmarkResults) {
				this.isPortfolio2Saved = true;
				this.setState({
					confirmationModalIsOpen: false, saveModalIsOpen: false,
					replaceModalIsOpen: false,
					statusMessageP2: 'Portfolio Saved',
					currentP2Id: nextProps.saveTemplateDetailsP2.templateId,
					currentP2Name: nextProps.saveTemplateDetailsP2.templateName
				});

				this.checkForPortfolioConfirmationModal();
			} else {
				this.setState({
					confirmationModalIsOpen: true, saveModalIsOpen: false,
					replaceModalIsOpen: false
				});
			}
		} else if (nextProps.saveTemplateDetailsP2.success === 0 && nextProps.saveTemplateDetailsP2 !== this.props.saveTemplateDetailsP2) {
			this.isPortfolio2Saved = false;
			this.setState({
				confirmationModalIsOpen: false,
				statusMessageP2: nextProps.saveTemplateDetailsP2.returnMessage
			});
		}


		// to receive predictive search results.
		if (this.props.userType.response === 'International') {
            if ((nextProps.xref !== this.props.xref || nextProps.xref !== undefined) && nextProps.xrefETF !== undefined) {
				let item = nextProps.xref;
				if((nextProps.xref.items && nextProps.xref.items.length >0 ) || (nextProps.xrefETF.items && nextProps.xrefETF.items.length>0)) {
					item = (nextProps.xref.items).concat(nextProps.xrefETF.items);
				}
				this.setState({
                	searchResults: getFormattedEtfFunds(item)
                });
            }
        } else {    
            if (nextProps.symbolSearchResults !== this.props.symbolSearchResults) {
                this.setState({
                    searchResults: nextProps.symbolSearchResults
                });
            }
        }

		//To get benchmarks results
		if (this.props.modalIsPortfolioCompare && nextProps.benchmarks !== this.props.benchmarks) {
			this.cwrpBenchmarkData(nextProps);
		}

		//process wsodIsues:
		if (
			nextProps.wsodIssue !== undefined &&
			nextProps.wsodIssue !== this.props.wsodIssue
		) {
			this.cwrpTemplateDataBind(nextProps.wsodIssue);
		}
		if (nextProps.fundShareclassId && nextProps.fundShareclassId !== "" &&
		nextProps.fundShareclassId !== this.props.fundShareclassId &&
		 this.props.userType.response === 'International') {
		 this.cwrpTemplateFundsEtfDataBind(nextProps.fundShareclassId);
		 this.props.setFundShareclassId("");
	 }
		if (!nextProps.modalIsOpen) {
			this.setState({
				templateData: {
					Portfolios: [{
						Portfolio: [],
					}]
				},
				screenerOutput: [],
				oldScreenerOutput: [],
				isFromHypotheticals: false,
				hypotheticalState: {
					portfolios: [],
					totalInvestment: '',
					periodId: { Key: '1', Value: '' },
					endPeriod: '',
				},
				isFirstTimeToHypotheticals: true,
				oldestInceptionDates: [],
				isOldestInceptionDatesChanged: false,
				resetInitialInvestment: false
			});
		}		

		if (
			nextProps.screenerOutput !== this.props.screenerOutput &&
			nextProps.screenerOutput.rows
		) {
			let screenerData = this.cwrpFillScreenerData(nextProps , nextProps.screenerOutput);
		}
		if (
			nextProps.sceenerOutputFromModule !== this.props.sceenerOutputFromModule &&
			nextProps.sceenerOutputFromModule.rows
		) {
			let screenerData = this.cwrpFillScreenerData(nextProps,nextProps.sceenerOutputFromModule);			
		}

		if (
			nextProps.screenerFiltersOutput !== this.props.screenerFiltersOutput
			&& nextProps.screenerFiltersOutput.rows
		) {
			this.cwrpFillFilterScreenerData(nextProps);
		}



		// receive master data from Screener
		if (
			nextProps.screenerMasterData !== this.props.screenerMasterData
		) {
			this.setState({
				screenerMasterData: nextProps.screenerMasterData
			});
		}

		if (this.state.savePortfolioModalIsOpen || this.state.confirmationModalIsOpen) { //Changes for MFS - 2596
		} else if (!lodash.isEqual(this.props.templateData, nextProps.templateData) && !this.state.isShowBenchmarkResults && !this.props.showHypotheticals) {
				this.cwrpBindTemplateData(nextProps);
		}
		let isNewPortfolioRequested = false;
		// Getting portfolio template results
		if ((this.props.portfolioTemplate !== nextProps.portfolioTemplate)) {
			let screenerData = this.cwrpFetchScreenerData(screenerData, nextProps);
			isNewPortfolioRequested = true;
		}
		if(!isNewPortfolioRequested && !nextProps.isFetchingPortfolioComparisonData){
			// Getting portfolio two  =template Screener results
			if (((!lodash.isEqual(nextProps.portfolioTwoComparisonScreenerData, this.props.portfolioTwoComparisonScreenerData)) || nextProps.portfolioTwoComparisonScreenerData.rows) &&
				this.portfolioTwoSelected) {
				if (nextProps.portfolioTwoComparisonScreenerData && nextProps.portfolioTwoComparisonScreenerData.rows) {
					this.cwrpBindScreenerDataP2(nextProps);
				}
				this.portfolioTwoSelected = false;
				this.setState({
					isLoading: false
				});
			}
			// Getting portfolio One template Screener results
			if (((!lodash.isEqual(nextProps.portfolioComparisonScreenerData, this.props.portfolioComparisonScreenerData)) ||
					nextProps.portfolioComparisonScreenerData.rows) && 
					this.portfolioOneSelected) {
				if (nextProps.portfolioComparisonScreenerData && nextProps.portfolioComparisonScreenerData.rows) {
					this.cwrpBindScreenerDataP1(nextProps);
				}
				this.portfolioOneSelected = false;
				this.setState({
					isLoading: false
				});
			}
		}
		
		if (this.props.oldestInceptionDates !== nextProps.oldestInceptionDates) {
			if (this.isFromModuleSelection && nextProps.isFromModuleSelection &&
				(!lodash.isEqual(this.state.templateData.Portfolios[0].Portfolio, nextProps.hypotheticalStatePvP.portfolios[0].Portfolio) ||
					!lodash.isEqual(this.state.templateData.Portfolios[1].Portfolio, nextProps.hypotheticalStatePvP.portfolios[1].Portfolio))
			) {
				this.isFromModuleSelection = false;
				// "resetModuleSelectionFlag()" is being called but nextProps.isFromModuleSelection is not being set to false.
				this.props.resetModuleSelectionFlag();
				this.removeAllEventsFromModules();
				this.setState({ oldestInceptionDates: nextProps.oldestInceptionDates, isOldestInceptionDatesChanged: true });
			}

			if (!lodash.isEqual(this.props.oldestInceptionDates, nextProps.oldestInceptionDates) && !lodash.isEqual(nextProps.oldestInceptionDates, nextProps.oldestInceptionDatesPvP)) {				
				if(nextProps.isFromModuleSelection) {
					this.isFromModuleSelection = false;
					this.props.resetModuleSelectionFlag();
				}
				this.setState({ oldestInceptionDates: nextProps.oldestInceptionDates, isOldestInceptionDatesChanged: true });
			}
			if (!this.state.showHypotheticals) {
				if (nextProps.oldestInceptionDates.length > 0) {

                    if (this.state.isFromSelectBenchmark && this.props.userType.response === 'International') {
						//this.state.errors = ["Performance data is not available to the inception of all funds in your report. Funds will be included based on performance availability"];
						let wsodIssueFundName = {};
						let dictFundshareWsod = {};			
						this.props.xidFundShareClassMappings.forEach(item => {
							if (dictFundshareWsod[item.FundShareclassID] === undefined) {
								dictFundshareWsod[item.FundShareclassID] = item.XID;
							}
						});
	
						//To add items for the wsidissues t check  
						this.state.templateData.Portfolios[0].Portfolio.forEach(item => {
							if (!Constant.CustomFundXids.includes(parseInt(item.wsodissue))) {
								item.wsodissue = dictFundshareWsod[item.fundshareClassId];
							}
							wsodIssueFundName[item.wsodissue] = item.name;
						});
	
						this.state.templateData.Portfolios[1].Portfolio.forEach(item => {
							if (!Constant.CustomFundXids.includes(parseInt(item.wsodissue))) {
								item.wsodissue = dictFundshareWsod[item.fundshareClassId];
							}
							if (wsodIssueFundName[item.wsodissue] === undefined) {
								wsodIssueFundName[item.wsodissue] = item.name;
							}
						});
	
						// MFS-3280
						// nextProps.oldestInceptionDates.forEach(item => {
						// 	if (item.oldestShareClassInceptionDate > item.inceptionDate) {
						// 		let errMsg = wsodIssueFundName[item.wsodIssue.toString()] + ", Inception " + utilityFunctions.formatOADate(item.inceptionDate, "DD/MM/YYYY") +
						// 			", Performance " + utilityFunctions.formatOADate(item.oldestShareClassInceptionDate, "DD/MM/YYYY");
						// 		this.state.errors.push(errMsg);
						// 	}
						// });
					}
					
					if (this.state.errors.length > 1 && this.state.isFromSelectBenchmark) {
						// this.setState({
						// 	benchmarkValidationModalIsOpen: true,
						// 	isFromSelectBenchmark: false
						// });
					} else {
						let isSuccessful = this.validatePortfolios(nextProps.oldestInceptionDates);
						this.setState({ validationModalIsOpen: !isSuccessful });
	
						//to make changes here for popup modal
						if (isSuccessful) {
							if (this.props.modalIsPortfolioCompare) {
								//validate for event validation then
								if (this.state.isFromHypotheticals) {
									if (!lodash.isEqual(this.state.oldScreenerOutput.filter(x => x.isActive || x.isActiveP2), this.state.screenerOutput.filter(x => x.isActive || x.isActiveP2))) {
										//Remove all events when Portfolio funds change
										this.removeAllEvents();
									} else {
										this.afterValidateShowBenchmark();
									}
								} else {
									if(this.isFromModuleSelection) {
										this.afterValidateShowBenchmark();
									} else {
										this.removeAllEvents();
									}
								}
	
							} else {
								this.afterValidateShowBenchmark();
							}
						}
					}
				}
			}
			if (this.state.showHypotheticals) {
				if (!this.isFromModuleSelection) {
					if (this.state.resetInitialInvestment || this.state.isOldestInceptionDatesChanged) {
						this.cwrpHandleHypotheticals(this.state.oldestInceptionDates);
						this.setState({ isOldestInceptionDatesChanged: false, resetInitialInvestment: false, isLoading: false });
					}
				} else {this.setState({isLoading: false});}
			}
	}
		if (!nextProps.modalIsOpen) {
			this.resetDropdown();			
			this.state.blendedBenchmarks.forEach(function (elem) {
				elem.isChecked = false;
			});
			this.setState({ pills: [], undoScreenerData: [], benchmarks: [], blendedBenchmarks: this.state.blendedBenchmarks, screenerOutput: [] ,isShowBenchmarkResults: false });
			this.isPortfolioFundChangeDone = false;		
			this.isPortfolioFundChangeFromModuleDone = false;
		}
		if(this.props.screenerOutput != nextProps.screenerOutput && nextProps.screenerOutput.rows && nextProps.screenerOutput.rows.length === 0 && this.props.userType.response === 'International'){
		if (document.getElementsByClassName('rbt-token rbt-token-removeable').length > 0) {	
          let xidValue = document.getElementsByClassName(
				'rbt-token rbt-token-removeable'
			)[document.getElementsByClassName(
				'rbt-token rbt-token-removeable'
			).length-1].firstChild.getAttribute("data-xid");

			if (!Constant.CustomFundXids.includes(parseInt(xidValue))){  //Checking for Cash
			document.getElementsByClassName(
				'rbt-close rbt-token-remove-button'
			)[document.getElementsByClassName(
				'rbt-close rbt-token-remove-button'
			).length-1].click();
			this.isCalledFromFundSearchbar = false;
			}
		}
        }
	}

	getScheduleIds(schedules) {
		let arrSchedules = [];
		schedules.forEach(schedule => {
			arrSchedules.push(schedule.Id);
		});
		return arrSchedules;
	}

	cwrpHandleHypotheticals(oldestInceptionDates) {
			let hypotheticalState = {portfolios:[]}
			let p1= {funds:[]};
			let p2= {funds:[]};
			let p1OldestShareClassDates= [];
			let p2OldestShareClassDates= [];
			let p1Count = 0;
			let p2Count = 0;
			let p1Investment = 0;
			let p2Investment = 0;
			let asOfPerformanceDate = 0;
			let result = this.getComparisonOldestInceptionDate(oldestInceptionDates, asOfPerformanceDate, p1, p2, p1Count, p2Count, p1OldestShareClassDates, p2OldestShareClassDates)
			this.comparisonOldestInceptionDateResult = result; // used when user change benchmark, no need to call API
			p1 = result.p1;
			p2 = result.p2;
			asOfPerformanceDate = result.asOfPerformanceDate;
			p1OldestShareClassDates = result.p1OldestShareClassDates;
			p2OldestShareClassDates = result.p2OldestShareClassDates;
			p1Count = result.p1Count;
			p2Count = result.p2Count;
			
			hypotheticalState.totalInvestment = this.props.balanceInitial;
			
			hypotheticalState.endPeriod = asOfPerformanceDate;
			let sortData = function(a, b) {
				const arrProp = ['inceptionDate', 'symbol'];
			
				for (let index = 0; index < arrProp.length; index++) {
					if (a[arrProp[index]] < b[arrProp[index]]) {
						return -1;
					}
					if (a[arrProp[index]] > b[arrProp[index]]) {
						return 1;
					}
				}
				return 0;
			};
			p1.funds.sort(sortData);
			p2.funds.sort(sortData);
			hypotheticalState.portfolios.push(p1);
			hypotheticalState.portfolios.push(p2);

			p1OldestShareClassDates.sort(function(a, b){return a-b});
			p2OldestShareClassDates.sort(function(a, b){return a-b});
			
			//Equal distributon of allocation for funds
			this.redistributeValidFunds(hypotheticalState);

			if(p1Count>0){
				p1.oldestShareClassInceptionDate = p1OldestShareClassDates[0];
			}
			if(p2Count>0){
				p2.oldestShareClassInceptionDate = p2OldestShareClassDates[0];
			}
			
			let oldestShareClassDate = 0;
			let oldestCommon = 0;
			if(p1Count > 0 && p2Count > 0){
				hypotheticalState.startPeriod = p1OldestShareClassDates[p1Count-1] < p2OldestShareClassDates[p2Count-1] ? utilityFunctions.getStartDateOfNextFullMonth(p2OldestShareClassDates[p2Count-1]) : utilityFunctions.getStartDateOfNextFullMonth(p1OldestShareClassDates[p1Count-1]);
				oldestShareClassDate = p1OldestShareClassDates[0] > p2OldestShareClassDates[0] ? p1OldestShareClassDates[0] : p2OldestShareClassDates[0];
				oldestCommon = p1OldestShareClassDates[0] > p2OldestShareClassDates[0] ? p1OldestShareClassDates[0] : p2OldestShareClassDates[0];
			} else {
				hypotheticalState.startPeriod = p1Count > 0 ? utilityFunctions.getStartDateOfNextFullMonth(p1OldestShareClassDates[p1Count-1]) : utilityFunctions.getStartDateOfNextFullMonth(p2OldestShareClassDates[p2Count-1]);
				oldestShareClassDate = p1Count > 0 ? p1OldestShareClassDates[0] : p2OldestShareClassDates[0];
				oldestCommon = p1Count > 0 ? p1OldestShareClassDates[0] : p2OldestShareClassDates[0];
			}
			let benchmarkPricingStartdate= utilityFunctions.getStartDateOfNextFullMonth(this.props.benchmarkLatestDate.inceptionDate);
			hypotheticalState.earliestCommon = hypotheticalState.startPeriod < benchmarkPricingStartdate ? benchmarkPricingStartdate: hypotheticalState.startPeriod;
			let validCommonDataPeriodArr = [];

            //Set earliestCommon to 3 years earlier if date is less than 3 years
            hypotheticalState.earliestCommon = utilityFunctions.getDateIfLessThan3Years(hypotheticalState.earliestCommon, asOfPerformanceDate);

			hypotheticalState.oldestCommon = oldestCommon < this.props.benchmarkLatestDate.inceptionDate ? utilityFunctions.getStartDateOfNextFullMonth(this.props.benchmarkLatestDate.inceptionDate) : utilityFunctions.getStartDateOfNextFullMonth(oldestCommon);
			 
			const beginDateIndexorFund = oldestShareClassDate < this.props.benchmarkLatestDate.inceptionDate ? this.props.benchmarkLatestDate.inceptionDate  : oldestShareClassDate;
			const beginDate = utilityFunctions.getStartDateOfNextFullMonth(beginDateIndexorFund);
			hypotheticalState.indexInception = beginDate;
			const endDate = asOfPerformanceDate;
			const diffInMonths = utilityFunctions.getDiffInMonths(beginDate -1, endDate);

			const beginDateEarliest = utilityFunctions.getStartDateOfNextFullMonth(hypotheticalState.earliestCommon);
			const diffInMonthsEarliest = utilityFunctions.getDiffInMonths(beginDateEarliest -1, endDate);

			if (diffInMonthsEarliest >= 36) {
				validCommonDataPeriodArr.push({label: 'Earliest Common', value: 0});
				hypotheticalState.selectedPeriod = {label: 'Earliest Common', value: 0};
			}
			// Now we need to show chart of minimum 11 months time period
			if (diffInMonths >= 36) {
				let dateValue= utilityFunctions.getPreviousPeriodYear(endDate, 3);
				if(diffInMonthsEarliest < 36) {
					hypotheticalState.startPeriod = dateValue;
					hypotheticalState.selectedPeriod = {label: '3 Years', value: dateValue};
				}
				validCommonDataPeriodArr.push({label: '3 Years', value: dateValue});
			}
			if (diffInMonths >= 60) {
				validCommonDataPeriodArr.push({label: '5 Years', value: utilityFunctions.getPreviousPeriodYear(endDate, 5)});
			}
			if (diffInMonths >= 84) {
				validCommonDataPeriodArr.push({label: '7 Years', value: utilityFunctions.getPreviousPeriodYear(endDate, 7)});
			}
			if (diffInMonths >= 120) {
				validCommonDataPeriodArr.push({label: '10 Years', value: utilityFunctions.getPreviousPeriodYear(endDate, 10)});
			}
			if (diffInMonths >= 180) {
				validCommonDataPeriodArr.push({label: '15 Years', value: utilityFunctions.getPreviousPeriodYear(endDate, 15)});
			}
			validCommonDataPeriodArr.push({label: 'Custom',value: -1});

			hypotheticalState.periodList = validCommonDataPeriodArr;
			hypotheticalState.showCustomCalendar = false;
			hypotheticalState.customDate = 0;
			hypotheticalState.Id = utilityFunctions.getRandomNumber();

			// Maintain Initial Allocation
			hypotheticalState.maintainInitialAlloc = {
				isCheck: false,
				portOneFreq: { label: '', value: '' },
				portTwoFreq: { label: '', value: '' },
			};

			this.setState({hypotheticalState: hypotheticalState});
	}



	afterValidateShowBenchmark() {		
		if (this.showBenchmark) {
			this.setComparisonInvestmentDetailsFromScreenerOutput();			
			this.setState({isShowBenchmarkResults: true, isLoading: true});
			
			this.props.showBenchmarksModal();
		}
		if (this.props.modalIsPortfolioCompare && !(this.showBenchmark || this.state.showHypotheticals)) {
			this.openSavePortfolioModal();
		}

		if(!this.props.modalIsPortfolioCompare && this.singlePortfolioSaveAllowed) {
			if (this.props.TemplateId > 0) {
				this.openReplaceModal();
			} else {
				this.openSaveModal();
			}
		}
		this.showBenchmark = false;
	}

	bindPeriodStartDate = (period, showCustomCalendar, customDateValue, callback) => {
		let hypotheticalState = cloneDeep(this.state.hypotheticalState);
		let customDate = customDateValue ? customDateValue : 0;
		if(period.value===0 ){
			hypotheticalState.startPeriod = hypotheticalState.earliestCommon;
		} else if(period.value===-1) {
			if(customDate===0){
				customDate = hypotheticalState.earliestCommon;
			}
			hypotheticalState.startPeriod = customDate;
		} else {
			hypotheticalState.startPeriod = period.value;
		}
		hypotheticalState.selectedPeriod = period;
		hypotheticalState.showCustomCalendar = showCustomCalendar;
		hypotheticalState.customDate = customDate;
		
		if (callback) {
			this.setState({hypotheticalState:hypotheticalState}, callback);
		} else {
			this.setState({hypotheticalState:hypotheticalState});
		}
	}
	cwrpBindScreenerDataP1(nextProps){
		let screenerData = cloneDeep(this.state.screenerOutput);

			if (Object.keys(this.props.portfolioComparisonScreenerData).length > 0) {
				this.props.portfolioComparisonScreenerData.rows.map(screenerItem => {
					let _recordIndex = -1;
					screenerItem.results.map(item => {
						if (item.field == "MSDWSODIssue") {
							_recordIndex = screenerData.findIndex(
								x => x.MSDWSODIssue == item.value
							);
						}
					});
					if (_recordIndex > -1) {
						let item = screenerData[_recordIndex];
						if (item.isActive && item.isActiveP2) {
							screenerData[_recordIndex].isActive = false;
						} else if (!item.isActive && item.isActiveP2) {
							screenerData[_recordIndex].isActive = false;
						} else {
							screenerData.splice(_recordIndex, 1);
						}
					}
				});
			}

			screenerData.forEach(item => {
				item["isActive"] = false;
			});
			if (Object.keys(nextProps.portfolioComparisonScreenerData).length > 0) {
				screenerData = this.setSelectedTemplateScreenerData(nextProps.portfolioComparisonScreenerData, screenerData, "P1")
			}
			screenerData = this.includeCashInScreenerDataForCompPort(screenerData, nextProps.portfolioTemplate, 1);
			this.setPortfolioScreenerData(screenerData, 'P1')
	}
	cwrpBindScreenerDataP2(nextProps) {
		let screenerData = cloneDeep(this.state.screenerOutput);
		if (Object.keys(this.props.portfolioTwoComparisonScreenerData).length > 0) {
			this.props.portfolioTwoComparisonScreenerData.rows.map(screenerItem => {
				let _recordIndex = -1;
				screenerItem.results.map(item => {
					if (item.field == "MSDWSODIssue") {
						_recordIndex = screenerData.findIndex(x => x.MSDWSODIssue == item.value);
					}
				});
				if (_recordIndex > -1) {
					let item = screenerData[_recordIndex];
					if (item.isActive && item.isActiveP2) {
						screenerData[_recordIndex].isActiveP2 = false;
					} else if (item.isActive && !item.isActiveP2) {
						screenerData[_recordIndex].isActiveP2 = false;
					} else {
						screenerData.splice(_recordIndex, 1);
					}
				}
			});
		}
		screenerData.forEach(item => {
			item["isActiveP2"] = false;
		});
		if (Object.keys(nextProps.portfolioTwoComparisonScreenerData).length > 0) {
			screenerData = this.setSelectedTemplateScreenerData(nextProps.portfolioTwoComparisonScreenerData, screenerData, "P2");
		}
		screenerData = this.includeCashInScreenerDataForCompPort(screenerData, nextProps.portfolioTemplate, 2);
		this.setPortfolioScreenerData(screenerData, 'P2');
	}

	cwrpFetchScreenerData(screenerData, nextProps) {
		var screenerData = cloneDeep(this.state.screenerOutput);
		xmlToJSON.to_json(`<data>${nextProps.portfolioTemplate[0].xmlData}</data>`, (error, data) => {
			let funds = [];
			const portfolios = data.Portfolios[0] ? data.Portfolios[0].Portfolio :
				data.Portfolios.Portfolio;
			if (portfolios[0] && portfolios[0].symbol !== undefined) {
				for (const index in portfolios) {
					const fund = portfolios[index];
					funds.push(fund);
				}
			} else if (portfolios.symbol !== undefined) {
				funds.push(portfolios);
            }
            let screenerInput = '';
            if (this.props.userType.response === 'International') {
                screenerInput = Constant.screenerInternationalInputs;
                let _fundshareClassIdArray = funds.map(e => {
                    return e.fundshareClassId.toString();
                });
                _fundshareClassIdArray = _fundshareClassIdArray.filter((v, p) => _fundshareClassIdArray.indexOf(v) == p);
                screenerInput.arguments[0].conditions[0].values = _fundshareClassIdArray;
				screenerInput.arguments[1].conditions[0].values = utilityFunctions.getPerformanceEndDateValidation().toString().split(",");
            } else {
                screenerInput = Constant.screenerInputs;
                let _wsodStringArray = funds.map(e => {
                    return e.wsodissue.toString();
                });
                _wsodStringArray = _wsodStringArray.filter((v, p) => _wsodStringArray.indexOf(v) == p);
                screenerInput.arguments[0].conditions[0].values = _wsodStringArray;
            }
	
			if (this.state.portfolioTypeSelected == 'P1') {
				this.props.requestPortfolioComparisonScreenerDataApi(SCREENER_TYPES.MutualFund, screenerInput, (this.props.userType.response === 'International') ? 'yes' : 'no', (this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD');
			} else {
				this.props.requestPortfolioTwoComparisonScreenerDataApi(SCREENER_TYPES.MutualFund, screenerInput, (this.props.userType.response === 'International') ? 'yes' : 'no', (this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD');
			}
		});
		return screenerData;
	}

	cwrpBindTemplateData(nextProps) {
		let funds = [];
		let _wsodArray = [];
		const _templateData = cloneDeep(Constant.portfolioTemplateData);
		let portfolios = [];
		if (!this.props.modalIsPortfolioCompare) {
			portfolios = nextProps.templateData.Portfolios[0] ? nextProps.templateData.Portfolios[0].Portfolio :
				nextProps.templateData.Portfolios.Portfolio;
			if (portfolios[0] && portfolios[0].symbol !== undefined) {
					for (const key in portfolios) {
						if (portfolios.hasOwnProperty(key)) {
                            funds.push(portfolios[key]);
                            if (this.props.userType.response === 'International') { 
                                _wsodArray.push(portfolios[key].fundshareClassId);
                            } else {
                                _wsodArray.push(portfolios[key].wsodissue ? portfolios[key].wsodissue: portfolios[key].wsodIssue);
                            }
						}
					}
			} else if (portfolios.symbol !== undefined) {
                funds.push(portfolios);
                if (this.props.userType.response === 'International') {
                    _wsodArray.push(portfolios.fundshareClassId);
                } else {
                    _wsodArray.push(portfolios.wsodissue ? portfolios.wsodissue: portfolios.wsodIssue);
                }	
			}
			_templateData.Portfolios[0].Portfolio = funds;
			if (_templateData.Portfolios.length == 1) {
				_templateData.Portfolios.push({ Portfolio: [] });
			}

			if (nextProps.templateData.sections && nextProps.templateData.sections.length === 0) { // new template creation.
				let sectionMasterData = cloneDeep((this.props.userType.response === 'International' ? Constant.sectionMasterDataIntl.sections : Constant.sectionMasterData.sections));
				_templateData.sections = sectionMasterData;
			}

			if (this.props.userType.response === 'International') {
				_templateData.language = nextProps.templateData.language;
				_templateData.currency = nextProps.templateData.currency;
				this.state.templateResult.currency = nextProps.templateData.currency;
			}

			this.setState({
				templateData: _templateData, addPills: true
			});
            let screenerInput = '';
            if (this.props.userType.response === 'International') {
                screenerInput = Constant.screenerInternationalInputs;
				screenerInput.arguments[1].conditions[0].values = utilityFunctions.getPerformanceEndDateValidation().toString().split(",");
            } else {
                screenerInput = Constant.screenerInputs;
            }
			const _wsodStringArray = _wsodArray.map(e => {
				return e.toString();
			});
			// screener call
			if(_wsodArray.length > 0){
			screenerInput.arguments[0].conditions[0].values = _wsodStringArray;
			this.props.requestScreenerDataApi(SCREENER_TYPES.MutualFund, screenerInput, (this.props.userType.response === 'International') ? 'yes' : 'no',(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD');
			}
		} else {
			if (this.props.comparisonContainerIds == '') {
				if(!this.props.hypotheticalStatePvP) {
					this.props.requestGetHypoWorkingCopyContainerIdsApi(nextProps.comparisonContainerIds);
				}
			}

			for (let i = 0; i < Object.keys(nextProps.templateData.Portfolios).length; i++) {
				funds = [];
				portfolios = nextProps.templateData.Portfolios[i] ? nextProps.templateData.Portfolios[i].Portfolio :
					nextProps.templateData.Portfolios.Portfolio;
				if (portfolios[0] && portfolios[0].symbol !== undefined) {
					for (const key in portfolios) {
						if (portfolios.hasOwnProperty(key)) {
                            funds.push(portfolios[key]);
                            if (this.props.userType.response === 'International') {
                                _wsodArray.push(portfolios[key].fundshareClassId);
                            } else {
                                _wsodArray.push(portfolios[key].wsodissue ? portfolios[key].wsodissue: portfolios[key].wsodIssue);
                            }
						}
					}
				} else if (portfolios.symbol !== undefined) {
                    funds.push(portfolios);
                    if (this.props.userType.response === 'International') {
                        _wsodArray.push(portfolios.fundshareClassId);
                    } else {
                        _wsodArray.push(portfolios.wsodissue ? portfolios.wsodissue: portfolios.wsodIssue);
                    }
				}
				_templateData.Portfolios[i].Portfolio = funds;
				if (_templateData.Portfolios.length == 1) {
					_templateData.Portfolios.push({ Portfolio: [] });
				}
				
			}
			if (nextProps.templateData.sections && nextProps.templateData.sections.length === 0) { // new template creation.
				let sectionMasterData = cloneDeep((this.props.userType.response === 'International' ? Constant.sectionMasterDataIntl.sections : Constant.sectionMasterData.sections));
				_templateData.sections = sectionMasterData;
			}
			
			if (this.props.userType.response === 'International') {
				_templateData.language = nextProps.templateData.language;
				_templateData.currency = nextProps.templateData.currency;
				this.state.templateResult.currency = nextProps.templateData.currency;
			}
			this.setState({
				templateData: _templateData, addPills: true, screenerOutput:[]
            });
            
            let screenerInput = '';
            if (this.props.userType.response === 'International') {
                screenerInput = Constant.screenerInternationalInputs;
				screenerInput.arguments[1].conditions[0].values = utilityFunctions.getPerformanceEndDateValidation().toString().split(",");
            } else {
                screenerInput = Constant.screenerInputs;
            }
			const _wsodStringArray = _wsodArray.map(e => {
				return e.toString();
			});
			// screener call
			if(_wsodArray.length > 0){
			screenerInput.arguments[0].conditions[0].values = _wsodStringArray;
			this.props.requestScreenerDataApi(SCREENER_TYPES.MutualFund, screenerInput, (this.props.userType.response === 'International') ? 'yes' : 'no',(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD');
			}
		}
	}

	cwrpFillFilterScreenerData(nextProps) {
		let screenerFiltersOutput = {
			rows: [],
			matches: 0
		};
		nextProps.screenerFiltersOutput.rows.map(item => {
			let row = {};
			if (!item) {
				row = null;
			} else {
				item.results.map(x => {
					row[x.field] = x.value;
					return row;
				});
				row["isActive"] = false;
				screenerFiltersOutput["rows"].push(row);
			}
			return row;
		});
		screenerFiltersOutput["matches"] = nextProps.screenerFiltersOutput.matches;
		this.setState({
			screenerFiltersOutput: screenerFiltersOutput
		});
	}

	cwrpFillScreenerData(nextProps , screenerOutput) {
		let screenerData = cloneDeep(this.state.screenerOutput);
		screenerOutput.rows.map(item => {
			let row = {};
			if (!item) {
				row = null;
			} else {
				let _recordIndex = -1;
				item.results.map(item => {
					if (item.field == "MSDWSODIssue") {
						_recordIndex = screenerData.findIndex(x => x.MSDWSODIssue == item.value);
					}
				});
				if (_recordIndex < 0) {
				item.results.map(x => {
					row[x.field] = x.value;
					return row;
				});
				row["isActive"] = false;
				//Handle issue scenario: Pills appears back if user delete it
				//before fund row appear in table below
				screenerData.push(row);
				}
			}
			return row;
		});
		
		if (!this.isCalledFromFundSearchbar) {
			// Check if Cash Fund is present, then add the Cash fund in screenerData
			let isPortfolioCashFund = this.isPortfoliosContainCashFund(nextProps);
			if (isPortfolioCashFund.PortOne || isPortfolioCashFund.PortTwo) {
				if (this.props.userType.response === 'International') {
					screenerData = screenerData.filter(fund => { return !Constant.CustomFundXids.includes(fund.MSDWSODIssue)});
					let cash = {};
					switch (this.state.templateResult.currency ? this.state.templateResult.currency : "USD") {
						case 'USD':
							cash = Constant.CustomFunds.Cash;
							break;
						case 'CHF':
							cash = Constant.CustomFunds.CashCHF;
							break;
						case 'EUR':
							cash = Constant.CustomFunds.CashEUR;
							break;
						case 'GBP':
							cash = Constant.CustomFunds.CashGBP;
							break;
					}
					screenerData.push(cloneDeep(cash));
				} else {
					screenerData = screenerData.filter(fund => { return fund.MFSSymbol !== Constant.CustomFunds.Cash.MFSSymbol});
					screenerData.push(cloneDeep(Constant.CustomFunds.Cash));
				}
			}
		}

		if (nextProps.modalIsPortfolioCompare) {
			let portfolios = [];
			screenerData.forEach(item => {
				let templateData = nextProps.templateData !== undefined ? nextProps.templateData :  cloneDeep(Constant.portfolioTemplateData);
				for (let i = 0; i < Object.keys(templateData.Portfolios).length; i++) {
					portfolios = templateData.Portfolios[i] ? templateData.Portfolios[i].Portfolio :
						templateData.Portfolios.Portfolio;
					if (portfolios && portfolios.symbol !== undefined && (templateData.Portfolios[i].Portfolio.symbol !== undefined)) {
						portfolios = [];
						portfolios.push(templateData.Portfolios[i].Portfolio);
					}
					if (portfolios && portfolios[0] && portfolios[0].symbol !== undefined && !this.isCalledFromFundSearchbar) {
						for (const key in portfolios) {
							if (portfolios.hasOwnProperty(key)) {
								//For Portfolio 1
								if (i === 0) {
									if (item.MSDWSODIssue == parseInt((portfolios[key].wsodissue))) {
										item.isActive = true;
										break;
									}
								} else {
									//For Portfolio 2
									if (item.MSDWSODIssue == parseInt((portfolios[key].wsodissue))) {
										item.isActiveP2 = true;
										break;
									}
								}
							}
						}
					}
				}
			});
			if (this.props.editComparisonTemplateId > 0) {
				this.setState({ 
					oldScreenerOutput:screenerData,
					isFromHypotheticals: true,	
					isFirstTimeComparisonPortfolioEdit: false });
			}
		}
		this.isCalledFromFundSearchbar = false;
        let pillsData = [];
        if (this.props.userType.response === 'International') {
            screenerData.forEach(function (element) {
                pillsData.push({
                    xid: element.MSDWSODIssue,
					symbol: element.MFSSymbolISIN,
					name: element.MSDFundName
                });
            });
        } else {
            screenerData.forEach(function (element) {
                pillsData.push({
                    xid: element.MSDWSODIssue,
                    symbol: element.MFSSymbol
                });
            });
		}
		
		if (this.props.userType.response === 'International') {
			this.setState({			
				screenerOutput: screenerData, addPills: false, pills: pillsData, isLoading: false,
				isIncludeCashInPortfolios: (screenerData.filter(fund => { return Constant.CustomFundXids.includes(fund.MSDWSODIssue) }).length === 1)
			});
		} else {
			this.setState({			
				screenerOutput: screenerData, addPills: false, pills: pillsData, isLoading: false,
				isIncludeCashInPortfolios: (screenerData.filter(fund => { return fund.MFSSymbol === Constant.CustomFunds.Cash.MFSSymbol }).length === 1)
			});
		}
		
		return screenerData;
	}

	cwrpTemplateDataBind(wsodIssue) {
		const screenerInput = Constant.screenerInputs;
		let _wsodArray = [];
		const _decodeResponse = wsodIssue;
		_decodeResponse.forEach(element => {
			_wsodArray.push(element.data.data.xid);
		});
		this.setState({
			wsodIssue: _wsodArray
		});
		_wsodArray = _wsodArray.filter(xid => { return xid !== undefined });
		if (_wsodArray.length > 0) {
			const _wsodStringArray = _wsodArray.map(e => {
				return e.toString();
			});
			// screener call
			screenerInput.arguments[0].conditions[0].values = _wsodStringArray;
			this.props.requestScreenerDataApi(SCREENER_TYPES.MutualFund, screenerInput, (this.props.userType.response === 'International') ? 'yes' : 'no',(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD');
		}
	}
	cwrpTemplateFundsEtfDataBind(fundShareclassId) {
        // screener call
        if (fundShareclassId !== "") {
            let screenerInput = Constant.screenerInternationalInputs;
            screenerInput.arguments[0].conditions[0].values = [fundShareclassId];
			screenerInput.arguments[1].conditions[0].values = utilityFunctions.getPerformanceEndDateValidation().toString().split(",");
            this.props.requestScreenerDataApi(
				SCREENER_TYPES.MutualFund,
				screenerInput, 
				(this.props.userType.response === 'International') ? 'yes' : 'no',
				(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'
			);
        }
    }
	cwrpBenchmarkData(nextProps) {	
		 
		if (!lodash.isEqual(this.state.benchmarks, nextProps.benchmarks.benchmarks)) {
			const data = nextProps.benchmarks.benchmarks;
			let allBenchmarks = this.getCombinedBenchmarks(data);
			let previousSelectedBenchmark = this.state.benchmarks;
			// On Use this Template for comparison portfolio populate benchmark
			if(this.props.comparisonPortfolioBenchmarkResult.hasOwnProperty('benchmarks') && this.props.comparisonPortfolioBenchmarkResult.benchmarks == '') {

			} else if((this.state.benchmarks.length !== data.length) && this.state.benchmarks.length>0)  {
				let selBenchmark = previousSelectedBenchmark.find(x=>x.isChecked == true);
				// Set previously checked benchmark.
				let benchmarkName;
				if(selBenchmark && selBenchmark.groupId !== "" && selBenchmark.groupId !== undefined){
				benchmarkName = allBenchmarks.find(item => item.groupId == selBenchmark.groupId);
				allBenchmarks.forEach(item => item.isChecked = false);
				}	
				if(benchmarkName !== "" && benchmarkName !== undefined) { //Check if selected benchmark present in different currency 
				let index = allBenchmarks.findIndex(i => {
					return i.groupId === (benchmarkName.groupId);
				});
				if (index > -1) {
					allBenchmarks[index].isChecked = true;
				}
			}
			} else if (this.props.comparisonPortfolioBenchmarkResult.hasOwnProperty('benchmarks') || this.props.comparisonPortfolioBenchmarkResult.hasOwnProperty('xId')) {
				if(this.props.userType.response === 'International'){
					//groupId property not available in blended benchmark so comparing on basis of name
				let benchmarkName = '';
				if(this.props.comparisonPortfolioBenchmarkResult.hasOwnProperty('groupId')){
				benchmarkName = allBenchmarks.find(item => item.groupId == this.props.comparisonPortfolioBenchmarkResult.groupId);
				} else{
					benchmarkName = allBenchmarks.find(item => item.name == this.props.comparisonPortfolioBenchmarkResult.name);
				}
				// Set previously checked benchmark.
				allBenchmarks.forEach(item => item.isChecked = false);
				if(benchmarkName !== "" && benchmarkName !== undefined) {
				let index;
					if(benchmarkName.hasOwnProperty('groupId'))	{			
				index = allBenchmarks.findIndex(i => {
					return i.groupId === (benchmarkName.groupId);
				});
			}else{
				index = allBenchmarks.findIndex(i => {
					return i.name === (benchmarkName.name);
				});
			}
				if (index > -1) {
					allBenchmarks[index].isChecked = true;
				}
			}
			} else {
				// Set previously checked benchmark.
				allBenchmarks.forEach(item => item.isChecked = false);
				let name = this.props.comparisonPortfolioBenchmarkResult.name !== '' ? this.props.comparisonPortfolioBenchmarkResult.name : this.props.comparisonPortfolioBenchmarkResult.benchmarks[0].symbol;
				let index = allBenchmarks.findIndex(i => {
					return i.name.toString() === (name);
				});
				if (index > -1) {
					allBenchmarks[index].isChecked = true;
				}
			}
			} else {				
				// Set default benchmark as 'S&P 500 TR USD' if no benchmark is checked.
				if(this.props.userType.response === 'International'){ //TODO remove the check, currently getting different XID for same benchmark. 
				allBenchmarks.forEach((value) => {
					if(Constant.BenchmarkMILXids.includes(value.xId)) {
						value.isChecked = true;
						this.props.requestBenchmarkLatestDate(value.xId);
					}
				})
			} else	{
			allBenchmarks.forEach((value) => {
				if(Constant.BenchmarkUSXids.includes(value.xId)) {
					value.isChecked = true;
					this.props.requestBenchmarkLatestDate(value.xId);
				}
			})
			}
			}

			// Set checked benchmark at the top
			allBenchmarks = this.setCheckedBenchmarkAtTop(allBenchmarks);

			this.setState({
				benchmarks: allBenchmarks
			});
		}
	}

	checkForPortfolioConfirmationModal() {
		if (this.isPortfolio1Saved && this.isPortfolio2Saved && this.isPortfolioAllSaved) {

			this.setState({ confirmationModalIsOpen: true });
			this.closeSaveModal();
		}
	}
	setSelectedTemplateScreenerData(portfolioComparisonScreenerData, screenerData, portfolioType) {
		portfolioComparisonScreenerData.rows.map(item => {
			let row = {};
			let duplicateRow = false;
			if (!item) {
				row = null;
			} else {
				item.results.map(x => {
					row[x.field] = x.value;
					return row;
				});
				screenerData.forEach(item => {
					if (item.MSDWSODIssue == row["MSDWSODIssue"] || (this.props.userType.response === 'International' && item.MSDFundShareClassID == row["MSDFundShareClassID"]) ) {
						duplicateRow = true;
						if (this.state.portfolioTypeSelected == 'P1') {
							item["isActive"] = true;
						} else if (this.state.portfolioTypeSelected == 'P2') {
							item["isActiveP2"] = true;
						}
					}
				});
				if (portfolioType == 'P2') {
					row["isActiveP2"] = true;
				} else {
					row["isActive"] = true;
				}

				if (!duplicateRow) {
					screenerData.push(row);
				}
			}
			return row;
		});
		return screenerData;
	}


	setPortfolioScreenerData(screenerData, portfolioType) {
		let pillsData = [];
		this.setSelectedTemplateValue(portfolioType);
		const newtemplateDataState = Object.assign({}, this.state.templateData);

		let portfolioFunds = [];
		if (newtemplateDataState.Portfolios.length == 1) {
			newtemplateDataState.Portfolios.push({ Portfolio: [] });
        }
        if (this.props.userType.response === 'International') {
            screenerData.forEach(function (element) {
                pillsData.push({
                    xid: element.MSDWSODIssue,
					symbol: element.MFSSymbolISIN,
					name: element.MSDFundName
                });
            });
        } else {
            screenerData.forEach(function (element) {
                pillsData.push({
                    xid: element.MSDWSODIssue,
                    symbol: element.MFSSymbol
                });
            });
        }
		
		screenerData.forEach(item => {
			if ((item["isActive"] && portfolioType== "P1" ) || (item["isActiveP2"] && portfolioType == "P2")) {
				let obj = {
					symbol: item.MFSSymbol,
					wsodissue: item.MSDWSODIssue,
					fundshareClassId: item.MSDFundShareClassID,
					fundfamily: 'OtherFund',
					name: item.MSDFundName
				}
					portfolioFunds.push(obj);
				
			}
		});
		
		if( portfolioType== "P1"){
		newtemplateDataState.Portfolios[0].Portfolio = portfolioFunds;
		} else{
			newtemplateDataState.Portfolios[1].Portfolio = portfolioFunds;
		}
		this.setState({
			screenerOutput: screenerData, addPills: false, pills: pillsData, isLoading: false ,templateData: newtemplateDataState
		});
	}

	replaceTemplateData = () => {
		this.closetemplateWarningModal(false);
		this.setState({isLoading: true});
		this.props.requestGetSinglePortfoioTemplatesByIdApi(this.state.templateSelectedValue, (this.props.userType.response === 'International') ? reportTypes.mil_singleportfolio : reportTypes.singleportfolio);
	}

	setSelectedTemplateValue(portfolioType) {
        if (portfolioType === "P1") {
            this.setState({             
                defaultSelectedPortfolioOneText: this.selectedTemplateP1 != "" ?
                    this.selectedTemplateP1 : "Select Portfolio One",
                defaultSelectedPortfolioOneId: this.selectedTemplateP1Value
            });
        } else {
            this.setState({
                defaultSelectedPortfolioTwoText: this.selectedTemplateP2 != "" ?
                    this.selectedTemplateP2 : "Select Portfolio Two",
                defaultSelectedPortfolioTwoId: this.selectedTemplateP2Value
            });
        }
    }
	handleInputChange = (event) => {
		let name = event.target.attributes["data-name"].value;
		if (name === "search") {
			if (event.target.value.trim() !== '') {
				this.setState({
					isSaveDisabled: false,
					statusMessage: '',
					templateName: event.target.value
				});
			} else {
				this.setState({
					isSaveDisabled: true,
					statusMessage: '',
					templateName: ''
				});
			}
		} else {
			this.setState({
				isSaveDisabled: false,
				statusMessage: '',
			});
		}
	}
	handleInputChangePortfolio = (event) => {
		let name = event.target.attributes["data-name"].value;
		let portfoliotype = event.target.attributes["data-portfoliotype"].value;
		if (portfoliotype == "P1") {
			if (name === "search") {
				if ((this.state.P2.length > 0)) {
					const textboxP2 = document.getElementById('searchP2');
					if ((event.target.value.trim() == textboxP2.value.trim())) {
						this.setState({
							isSaveDisabled: true,
							statusMessage: '',
							templateName: ''
						});
						return;
					}
				}
				if (event.target.value.trim() !== '') {
					this.setState({
						isSaveDisabled: false,
						statusMessage: '',
						templateName: event.target.value
					});
				} else {
					this.setState({
						isSaveDisabled: true,
						statusMessage: '',
						templateName: ''
					});
				}
			} else {
				this.setState({
					isSaveDisabled: false,
					statusMessage: '',
				});
			}
		} else if (portfoliotype == "P2") {
			if (name === "search") {
				if ((this.state.P1.length > 0) && (event.target.value.trim() == document.getElementById('search').value.trim())) {
					this.setState({
						isSaveDisabledP2: true,
						statusMessageP2: '',
						templateNameP2: ''
					});
					return;
				}

				if (event.target.value.trim() !== '') {
					this.setState({
						isSaveDisabledP2: false,
						statusMessageP2: '',
						templateNameP2: event.target.value
					});
				} else {
					this.setState({
						isSaveDisabledP2: true,
						statusMessageP2: '',
						templateNameP2: ''
					});
				}
			} else {
				this.setState({
					isSaveDisabledP2: false,
					statusMessageP2: '',
				});
			}
		}
	}
	requestSymbolSearchApi = (searchtext, fundType) => {
		//this.props.requestSymbolSearchApi(searchtext, fundType);
        if (this.props.userType.response === 'International') {
            let token = Format.getQueryString("access_token") || localStorage.getItem('access_token');
            if (token !== null && token !== undefined && token !== "") {
				this.props.requestXrefByTerm(searchtext);
				this.props.requestXrefByTermETF(searchtext);
            }
        } else {
            this.props.requestSymbolSearchApi(searchtext, fundType);
        }
	}

	requestDecodeApi = (key) => {
		if (this.props.userType.response === 'International') {
            this.props.setFundShareclassId(key[0].key);
        } else {
		this.props.requestDecodeApi(key);
	}
}
	closeTooltip = (e) => {
		const portfolioName = e.currentTarget.getAttribute('data-portfolio');
		if (portfolioName === 'P1') {
			this.setState({ showAddAllTooltipP1: false });
		} else if(portfolioName === 'P2') {
			this.setState({ showAddAllTooltipP2: false });
		}
	}
	addFundsToFundResultsTable = (e) => {
		const flag = e.getAttribute('data-flag');
		const symbol = e.getAttribute('data-symbol');
		const wsodIssue = e.getAttribute('data-wsodIssue');
		let screenerFiltersOutput = cloneDeep(this.state.screenerFiltersOutput);
		let screenerData = cloneDeep(this.state.screenerOutput);//result in Select Fund Table.
		let selectedLength = screenerData && screenerData.length ? screenerData.length : 0;

		screenerFiltersOutput.rows.forEach(item => {
			if (item.MSDWSODIssue && item.MSDWSODIssue.toString() === wsodIssue) {
				if (item.isActive) {
					let _recordIndex = screenerData.findIndex(
						x => x.MSDWSODIssue == wsodIssue
					);
					screenerData.splice(_recordIndex, 1);
					item.isActive = false;
				} else {
					let fundResultsTableItem = cloneDeep(item);
					fundResultsTableItem.isActive = false;
					screenerData.push(fundResultsTableItem);
					item.isActive = true;
				}
			}
		});

		this.setState({
			screenerFiltersOutput: screenerFiltersOutput,
			screenerOutput: screenerData
		});
	}

	resetScreenerFiltersOutput = () => {
		this.setState({
			screenerFiltersOutput: [],
		}, () => this.props.resetScreenerFiltersOutput);
	}

	getTemplateForSelectedPortfolio = (value, portfolioType, portfolioName) => {
		let screenerData = {};
		if (portfolioType === 'P1') {
			this.selectedTemplateP1 = portfolioName;
			this.selectedTemplateP1Value = value;
			this.portfolioOneSelected = true;
		} else {
			this.selectedTemplateP2 = portfolioName,
			this.selectedTemplateP2Value = value
			this.portfolioTwoSelected = true;
		}

		this.setState({
			portfolioTypeSelected: portfolioType,
		});
		if (this.state.screenerOutput.filter(x => portfolioType == "P1" ? x.isActive : x.isActiveP2).length > 0) {
			this.setState({
				templateWarningModalIsOpen: true,
				templateSelectedValue: value
			})
		} else {
			this.setState({isLoading: true});
			this.props.requestGetSinglePortfoioTemplatesByIdApi(value, (this.props.userType.response === 'International') ? reportTypes.mil_singleportfolio : reportTypes.singleportfolio);
		}
	}

	componentWillUnmount() {
		this.setState({
			portfolioTypeSelected: 'None'
		});
	}

	requestOldestInceptionDates() {
		let wsodIssueArray = []
		this.state.screenerOutput.map(item => {
			if (item.isActive || item.isActiveP2) {
				wsodIssueArray.push(item.MSDWSODIssue);
			}
		});

		this.props.requestOldestInceptionDatesApi(wsodIssueArray.join(','), (this.props.userType.response === 'International') ? 'yes' : 'no');
	}
	loadPills = () => {
		let screenerData = cloneDeep(this.state.screenerOutput);		
        let pillsData = [];
        if (this.props.userType.response === 'International') {
            screenerData.forEach(function (element) {
                pillsData.push({
                    xid: element.MSDWSODIssue,
					symbol: element.MFSSymbolISIN,
					name: element.MSDFundName
                });
            });
        } else {
            screenerData.forEach(function (element) {
                pillsData.push({
                    xid: element.MSDWSODIssue,
                    symbol: element.MFSSymbol
                });
            });
        }
		
		this.setState({			
			addPills: false, pills: pillsData
		});
	}
	showBenchmarkResults = () => {
		this.setState({ isFromSelectBenchmark: true });
		this.props.requestMfsSiteInactiveFlag();
		//update for International
		if(this.props.userType.response === 'International') {
			let fundshareids = '';
			this.state.screenerOutput.forEach(item=>{
				fundshareids += item.MSDFundShareClassID +','
			});
			if(fundshareids.length > 2) {
				fundshareids = fundshareids.substring(0, fundshareids.length -1);
			}
			let currency = this.state.templateResult.currency ? this.state.templateResult.currency:"USD";

			this.props.requestInternationalXidByFundShareClassId(fundshareids, currency);
			//Set blended benchmark names and data depending on selected currency
			let tempBlendedBenchmark = cloneDeep(blendedBenchmarksMil.filter(x=>x.currency == currency));
			if(this.state.benchmarks.length > 0) {
				if(tempBlendedBenchmark && tempBlendedBenchmark.length>0) {
					let benchmarks = (this.state.benchmarks);
					tempBlendedBenchmark.forEach(blended=>{
						let temp = benchmarks.filter(x=>x.name == blended.name)[0];
						blended.blendedBenchmarkNames = '';
						blended.isChecked = temp.isChecked;
						blended.benchmarks.forEach(function (item) {
							blended.blendedBenchmarkNames += item.symbol + " " + (item.weightage * 100) + "%, ";
							blended.xId = item.wsodissue + ",";
						});
						temp.blendedBenchmarkNames = blended.blendedBenchmarkNames;
						temp.benchmarks = blended.benchmarks;
						temp.currency = currency;
						temp.xId = blended.xId;
						temp.isChecked = blended.isChecked;				
					})
				}
			}
			this.setState({blendedBenchmarks: tempBlendedBenchmark});
		} else {
			this.beforeMovingToBenchmark();
		}
	}

	beforeMovingToBenchmark = () => {
		this.showBenchmark = true;
		this.isCalledFromFundSearchbar = false;
		this.requestOldestInceptionDates();
	}

	modifyInvestments(data) {
		let templateSettings = { templateData: '', containerIds: '' };
		if(data.Portfolios.length === 0){
			data = this.setPortfoliocomparisonTemplateData();
		}
		data.startPeriod = data.startPeriod - 1;
		data.currency = this.state.templateResult.currency;
		templateSettings.templateData = JSON.stringify(data);
		templateSettings.isInternational = this.props.userType.response === 'International' ? 'yes' : 'no';
		templateSettings.containerIds = this.props.comparsionWorkingContainerIds ? this.props.comparsionWorkingContainerIds : this.props.containerId;
		this.props.requestUpdateInvestmentsDetailsApi(templateSettings);
	}

	showHypotheticals = () => {
		this.props.requestMfsSiteInactiveFlag();
		this.setState({userType:this.props.userType.response});
		this.setState({
			showHypotheticals: true,
			isShowBenchmarkResults: false,
			benchmarks: this.setCheckedBenchmarkAtTop(this.state.benchmarks),
		})		
		if(this.isLoadingReqd) {
			this.setState({isLoading: true});
			this.isLoadingReqd = false;
		}
		if (this.state.hypotheticalState.startPeriod) {
			this.setHypoStartDateWithBenchmark();
		}
		this.props.showHypotheticalsModal();
	}
	showComparisonPortfolioFundSelection() {
		if (this.state.showHypotheticals) {
			this.setComparisonInvestmentDetailsFromScreenerOutput()
		}
		//While coming back comparison portfolio fund selection screen load pills
		this.loadPills();
		
		this.setState({
			isShowBenchmarkResults: false,
			showHypotheticals: false
		})
	}
	redirectToSelectedModal = () => {
		this.setState({eventValidationModalIsOpen: false})
		if(this.modalTobeShown === 'hideBenchmarkAndHypotheticals') {
			this.showComparisonPortfolioFundSelection();
		} else if(this.modalTobeShown === 'hideHypotheticals') {
			this.setComparisonInvestmentDetailsFromScreenerOutput();

			this.setState({
				isShowBenchmarkResults: true,
				showHypotheticals: false,
				isLoading: true
			})
		}
	}
	showEventPortfolioChangeAlert(type) {
		this.modalTobeShown = type;
		this.setState({eventValidationModalIsOpen: true});		
	}
	hideBenchmarkAndHypotheticalsResults = () => {
		this.state.benchmarks = this.setCheckedBenchmarkAtTop(this.state.benchmarks);
		if (this.state.showHypotheticals) {
			this.showEventPortfolioChangeAlert('hideBenchmarkAndHypotheticals');
		} else {
			this.showComparisonPortfolioFundSelection();
		}
	}

	hideHypotheticalsResults = () => {		
		this.showEventPortfolioChangeAlert('hideHypotheticals');		
	}

	requestGetBenchmarksApi = (input) => {
		this.props.requestGetBenchmarksApi(input,
		(this.props.userType.response === 'International') ? 'yes' : 'no',
		(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'	);
	}

	getCustomModalStyle = (ismodalIsPortfolioCompare, portfolio1, portfolio2) => {
		let customStyle = null;
		let saveReplaceP1 = this.state.defaultSelectedPortfolioOneText != "Select Portfolio One";
		let saveReplaceP2 = this.state.defaultSelectedPortfolioTwoText != "Select Portfolio Two";
		this.isPortfolio1Saved = false;
		this.isPortfolio2Saved = false;
		this.isPortfolioAllSaved = false;
		this.isPortfolioToSave = portfolio1.length != 0 && portfolio2.length != 0;

		if (portfolio1.length === 0) {
			this.isPortfolio1Saved = true;
			this.isPortfolioAllSaved = true;
		}
		if (portfolio2.length === 0) {
			this.isPortfolio2Saved = true;
			this.isPortfolioAllSaved = true;
		}

		if (ismodalIsPortfolioCompare) {
			if (portfolio1.length > 0 && portfolio2.length > 0) {
				if (saveReplaceP1 && saveReplaceP2) {
					customStyle = CustomModalStyles.SelectPortfolioFundsModalStylesWithBothSaveNReplace
				} else if (!saveReplaceP1 && !saveReplaceP2) {
					customStyle = CustomModalStyles.SelectPortfolioFundsModalStylesWithNoSaveNReplace
				}
			}
			if (customStyle == null && (portfolio1.length > 0 || portfolio2.length > 0)) {
				if ((saveReplaceP1 && !saveReplaceP2) || (!saveReplaceP1 && saveReplaceP2)) {
					customStyle = CustomModalStyles.SelectPortfolioFundsModalStylesWithReplaceNsimpleSave
				}
				if ((portfolio1.length > 0 && portfolio2.length === 0)) {
					if ((saveReplaceP1)) {
						customStyle = CustomModalStyles.SelectPortfolioFundsModalStylesWithReplaceNnoFunds
					} else if (!saveReplaceP1) {
						customStyle = CustomModalStyles.SelectPortfolioFundsModalStylesWithSimpleSaveNnoFunds
					}
				} else if (portfolio1.length === 0 && portfolio2.length > 0) {
					if (saveReplaceP2) {
						customStyle = CustomModalStyles.SelectPortfolioFundsModalStylesWithReplaceNnoFunds
					} else if (!saveReplaceP2) {
						customStyle = CustomModalStyles.SelectPortfolioFundsModalStylesWithSimpleSaveNnoFunds
					}
				}

			}
		}
		let p1Name = this.state.defaultSelectedPortfolioOneText;
		let p2Name = this.state.defaultSelectedPortfolioTwoText;
		let p1Id = this.state.defaultSelectedPortfolioOneId;
		let p2Id = this.state.defaultSelectedPortfolioTwoId;
		if (this.state.defaultSelectedPortfolioOneId > 0 && this.isNotNullOrUndefined(this.state.currentP1Name)) {
			this.setState({ currentP1Name: p1Name, currentP1Id: p1Id });
		}
		if (this.state.defaultSelectedPortfolioTwoId > 0 && this.isNotNullOrUndefined(this.state.currentP2Name)) {
			this.setState({ currentP2Name: p2Name, currentP2Id: p2Id });
		}
		this.setState({ customPortfolioModalStyle: customStyle });
		return customStyle;
	}

	isNotNullOrUndefined(value) {
		return ((value != null) && typeof (value) !== "undefined");
	}
	
	setDefaultBenchmark = (benchmarks, defaultBenchmarkWsodId) => {
		let results = [];
		if (benchmarks && benchmarks.length > 0) {
			let searchBenchmark = benchmarks.find((element) => {
				return element.xId == defaultBenchmarkWsodId;
			})
			if (searchBenchmark !== undefined) {
				results.push(searchBenchmark);
				benchmarks.forEach((element) => {
					if (element.xId != defaultBenchmarkWsodId) {
						results.push(element);
					}
				})
				if (benchmarks.filter(x => x.isChecked).length == 0) {
					searchBenchmark.isChecked = true;
				}
			} 
		}
		return results;
	}

	/**
	 * [MFS-1551]: Update "Total Investment" in hypotheticalState and re-render.
	 * @param {number} totalInvestment 
	 */
	updateHypothicalStateTotalInvestment = (totalInvestment) => {
		if (totalInvestment && totalInvestment > 0) {
			let hypotheticalState = cloneDeep(this.state.hypotheticalState);
			hypotheticalState.totalInvestment = totalInvestment;
			hypotheticalState.IsFirstTime = false;
			let p1Count = hypotheticalState.portfolios[0].funds.filter((item) => { return item.Invalid !== true }).length;
			let p2Count = hypotheticalState.portfolios[1].funds.filter((item) => { return item.Invalid !== true }).length;
			hypotheticalState.portfolios[0].funds.map(item=>{
				if(item.Invalid) {
					item.amount = 0;
				} else{
					item.amount = (totalInvestment * item.amountInvestedPercent).toFixed(2)
				}
				return item;
			});
			if(p1Count > 0) {
				let totalAmount = this.sumAmounts(hypotheticalState.portfolios[0].funds);
				hypotheticalState.portfolios[0].funds.filter((item) => { return item.Invalid !== true })[p1Count-1].amount = parseFloat((hypotheticalState.totalInvestment - totalAmount).toFixed(2));				
			}

			hypotheticalState.portfolios[1].funds.map(item=>{
				if(item.Invalid) {
					item.amount = 0;
				} else{
					item.amount = (totalInvestment * item.amountInvestedPercent).toFixed(2)
				}
				return item;
			});
			if(p2Count > 0) {
				let totalAmount = this.sumAmounts(hypotheticalState.portfolios[1].funds);		
				hypotheticalState.portfolios[1].funds.filter((item) => { return item.Invalid !== true })[p2Count-1].amount = parseFloat((hypotheticalState.totalInvestment - totalAmount).toFixed(2));				
			}

			this.setState({
				hypotheticalState: hypotheticalState
			})
		}
	}
	/**
	 * [MFS-1551]: 
	 * @param {EventSource} evt 
	 * @param {Number} portfolioIndex 
	 * @param {Boolean} isPercent 
	 */
	updateHypothicalStateFundValuesPercents = (evt, portfolioIndex, isPercent) => {
		let value = evt.target.value !=="" ? evt.target.value : "0";
		// Percent Change
		if (isPercent) {
			let fundPercent = parseFloat(value);
			let fundSymbol = evt.target.getAttribute('data-symbol');
			if (fundSymbol && portfolioIndex > -1) {
				let hypotheticalState = cloneDeep(this.state.hypotheticalState);
				hypotheticalState.isFirstTime = false;
				hypotheticalState.portfolios[portfolioIndex].funds.forEach((element) => {
					if (element.symbol == fundSymbol) {
						element.amount = parseFloat(hypotheticalState.totalInvestment * (fundPercent/100));
						element.amountInvestedPercent = parseFloat((fundPercent/100));
					} else {
						element.amountInvestedPercent = parseFloat(element.amountInvestedPercent).toFixed(4);
						element.amount = parseFloat(hypotheticalState.totalInvestment * element.amountInvestedPercent).toFixed(2);
					}
				})
				this.setState({
					hypotheticalState: hypotheticalState,
				})
			}
		} else { // Dollar Change
			let fundValue = value.replace(/,/gi,'');
			let fundSymbol = evt.target.getAttribute('data-symbol');
			if (fundSymbol && portfolioIndex > -1) {
				let hypotheticalState = cloneDeep(this.state.hypotheticalState);
				hypotheticalState.isFirstTime = false;
				hypotheticalState.portfolios[portfolioIndex].funds.forEach((element) => {
					if (element.symbol == fundSymbol) {
						element.amount = parseFloat(fundValue);
						element.amountInvestedPercent = parseFloat((fundValue/hypotheticalState.totalInvestment));
					}
				})
				this.setState({
					hypotheticalState: hypotheticalState,
				})
			}
		}
	}

	updateUnavailableFunds = () => {
	
		// Use react method instead of javvascript
		let elem = document.getElementById('selectTimePeriod');
		let isPortfolio1FundInvalidExist = false;
		let isPortfolio2FundInvalidExist = false;
		
		if (elem) {
			let hypotheticalState = cloneDeep(this.state.hypotheticalState);
			let periodDate = elem.getAttribute('data-period');			
			
			// Set Invalid property to 'true' of invalid funds
			for (let i = 0; i < hypotheticalState.portfolios.length; i++) {
				hypotheticalState.portfolios[i] && hypotheticalState.portfolios[i].funds.forEach((element) => {					
					if (utilityFunctions.getStartDateOfNextFullMonth(element.oldestShareClassInceptionDate) > periodDate) {
						element.Invalid = true;
					} else {
						element.Invalid = false;
					}
				})
				
				if (i === 0) {
					isPortfolio1FundInvalidExist = hypotheticalState.portfolios[i] && hypotheticalState.portfolios[i].funds.filter(item => item.Invalid).length > 0;
				}
				if (i === 1) {
					isPortfolio2FundInvalidExist = hypotheticalState.portfolios[i] && hypotheticalState.portfolios[i].funds.filter(item => item.Invalid).length > 0;
				}
			}
			
			this.distributeValidFunds(hypotheticalState, !isPortfolio1FundInvalidExist, !isPortfolio2FundInvalidExist);
		}
	}
	
	distributeValidFunds(hypotheticalState, isP1Redistribute, isP2Redistribute) {
		// Redistribute the funds
		let p1 = hypotheticalState.portfolios && hypotheticalState.portfolios[0];
		let p2 = hypotheticalState.portfolios && hypotheticalState.portfolios[1];
		let p1Count = 0;
		let p2Count = 0;
		let p1Investment = 0;
		let p2Investment = 0;

		p1Count = p1.funds.filter((item) => { return item.Invalid !== true }).length;
		p2Count = p2.funds.filter((item) => { return item.Invalid !== true }).length;
		p1.funds.map(item => {
			item.investmentId=this.state.p1Investments[item.wsodissue]
		})
		if( p1Count > 0 ) {			
			p1Investment = (1 / p1Count);
			p1.funds.map(item => {
				if(item.Invalid) {
					item.amountInvestedPercent = 0;
					item.amount = 0;
				} else{
					item.amountInvestedPercent = isP1Redistribute ? item.amountInvestedPercent : p1Investment; 					
					item.amount = isP1Redistribute ? (hypotheticalState.totalInvestment * item.amountInvestedPercent ).toFixed(2) : (hypotheticalState.totalInvestment * p1Investment).toFixed(2);// p1Investment;;
				}
				
				return item;
			});

			let totalAmount = this.sumAmounts(p1.funds);			
			p1.funds.filter((item) => { return item.Invalid !== true })[p1Count-1].amount = parseFloat((hypotheticalState.totalInvestment - totalAmount).toFixed(2));			
		}
		p2.funds.map(item => {
			item.investmentId=this.state.p2Investments[item.wsodissue]
		})
		if( p2Count > 0 ) {			
			p2Investment = (1 / p2Count);
			p2.funds.map(item => {
				if(item.Invalid) {
					item.amountInvestedPercent = 0;
					item.amount = 0;
				} else{
					item.amountInvestedPercent = isP2Redistribute ? item.amountInvestedPercent : p2Investment;					
					item.amount = isP2Redistribute ? (hypotheticalState.totalInvestment * item.amountInvestedPercent).toFixed(2) : (hypotheticalState.totalInvestment * p2Investment).toFixed(2);// p2Investment;;
				}
				
				return item;
			});

			let totalAmount = this.sumAmounts(p2.funds);			
			p2.funds.filter((item) => { return item.Invalid !== true })[p2Count-1].amount = parseFloat((hypotheticalState.totalInvestment - totalAmount).toFixed(2));
		}

		for (let i = 0; i < hypotheticalState.portfolios.length; i++) {
			if (hypotheticalState.portfolios[i]) {
				hypotheticalState.portfolios[i] = (i === 0) ? p1: p2;
			}
		}

		// Update state
		this.setState({
			hypotheticalState: hypotheticalState
		})
	}

	redistributeValidFunds = (hypotheticalState, isP1Redistribute, isP2Redistribute) => {

		// Redistribute the funds
		let p1 = hypotheticalState.portfolios && hypotheticalState.portfolios[0];
		let p2 = hypotheticalState.portfolios && hypotheticalState.portfolios[1];
		let p1Count = 0;
		let p2Count = 0;
		let p1Investment = 0;
		let p2Investment = 0;

		p1Count = p1.funds.filter((item) => { return item.Invalid !== true }).length;
		p2Count = p2.funds.filter((item) => { return item.Invalid !== true }).length;
		p1.funds.map(item => {
			item.investmentId=this.state.p1Investments[item.wsodissue]
		})
		if( p1Count > 0 ) {
			p1Investment = (1 / p1Count);
			p1.funds.map(item => {
				if(item.Invalid) {
					item.amountInvestedPercent = 0;
					item.amount = 0;
				} else{
					item.amountInvestedPercent = isP1Redistribute ? p1Investment : this.state.p1Allocations[item.wsodissue];// p1Investment;
					item.amount = isP1Redistribute ? (hypotheticalState.totalInvestment / p1Count).toFixed(2) : (hypotheticalState.totalInvestment * this.state.p1Allocations[item.wsodissue]).toFixed(2);// p1Investment;;
				}
				
				return item;
			});

			let totalAmount = this.sumAmounts(p1.funds);			
			p1.funds.filter((item) => { return item.Invalid !== true })[p1Count-1].amount = parseFloat((hypotheticalState.totalInvestment - totalAmount).toFixed(2));			
		}
		p2.funds.map(item => {
			item.investmentId=this.state.p2Investments[item.wsodissue]
		})
		if( p2Count > 0 ) {			
			p2Investment = (1 / p2Count);
			p2.funds.map(item => {
				if(item.Invalid) {
					item.amountInvestedPercent = 0;
					item.amount = 0;
				} else{
					item.amountInvestedPercent = isP2Redistribute ? p2Investment : this.state.p2Allocations[item.wsodissue];// p2Investment;
					item.amount = isP2Redistribute ? (hypotheticalState.totalInvestment / p2Count).toFixed(2) : (hypotheticalState.totalInvestment * this.state.p2Allocations[item.wsodissue]).toFixed(2);// p2Investment;;
				}
				
				return item;
			});

			let totalAmount = this.sumAmounts(p2.funds);			
			p2.funds.filter((item) => { return item.Invalid !== true })[p2Count-1].amount = parseFloat((hypotheticalState.totalInvestment - totalAmount).toFixed(2));
		}

		for (let i = 0; i < hypotheticalState.portfolios.length; i++) {
			if (hypotheticalState.portfolios[i]) {
				hypotheticalState.portfolios[i] = (i == 0) ? p1: p2;
			}
		}

		// Update state
		this.setState({
			hypotheticalState: hypotheticalState
		})
	}

	sumAmounts(funds) {
		let amount = 0;
		let funds2 = funds.filter((item) => { return item.Invalid !== true });
		for (let i = 0; i < funds2.length - 1; i++) {
			if (!funds2[i].Invalid) {
				amount += parseFloat(funds2[i].amount)
			}					
		}
		return amount;
	}
	sumAllocations(funds) {
		let allocation = 0;
				for (let i = 0; i < funds.length - 1; i++) {
					if (!funds[i].Invalid) {
						allocation += parseFloat(funds[i].amountInvestedPercent)
					}					
				}
				return allocation;
	}
	adjustFundPercentageHypotheticalState() {
		let hypotheticalState = cloneDeep(this.state.hypotheticalState);
	}

	setCheckedBenchmarkAtTop = (benchmarks) => {
		
		let checkedBenchmarks = [];
		let uncheckedBenchmarks = [];

		benchmarks = this.sortBenchmarks(benchmarks);
		
		benchmarks.forEach(item => {
			(item.isChecked && item.isChecked === true) ? checkedBenchmarks.push(item): uncheckedBenchmarks.push(item);
		})
		
		return checkedBenchmarks.concat(uncheckedBenchmarks);
	}

	sortBenchmarks = (benchmarks) => {

		let blendedBenchmarks = [];
		let nonblendedBenchmarks = [];
		
		benchmarks.forEach(item => {
			item.blendedBenchmarkNames === '' ? nonblendedBenchmarks.push(item): blendedBenchmarks.push(item);
		})

		let sortBenchmarksByName = (a, b) => {
			const arrProp = ['name'];
			for (let index = 0; index < arrProp.length; index++) {
				if (a[arrProp[index]] < b[arrProp[index]]) {
					return -1;
				}
				if (a[arrProp[index]] > b[arrProp[index]]) {
					return 1;
				}
			}
			return 0;
		};

		blendedBenchmarks.sort(sortBenchmarksByName);
		nonblendedBenchmarks.sort(sortBenchmarksByName);
		
		return blendedBenchmarks.concat(nonblendedBenchmarks);
	}

	/**
	 * [MFS-1551]: Update "Total Investment" in hypotheticalState and re-render.
	 * @param {number} totalInvestment 
	 */
	updateHypothicalStateMaintainInitialAlloc = (hypoStateInitAlloc) => {
		let hypotheticalState = cloneDeep(this.state.hypotheticalState);
		hypotheticalState.maintainInitialAlloc = hypoStateInitAlloc;
		this.setState({
			hypotheticalState: hypotheticalState
		})
	}

	onClickModuleSelection = (e) => {
		let tabErrors = [];
		this.validateInitialInvestment(tabErrors);
		this.eventsValidate(tabErrors);
		this.validateEventsStartDate(tabErrors);

		if (tabErrors.length > 0) {
			e.preventDefault();
			this.setValidationModalTabs(tabErrors);
			return false;
		}

		// Maintain Initial Allocation
		this.childHypoInitAlloc.childUpdateEventsInitAlloc();
	}

	/**
	 * When user check/uncheck "Include Cash in Portfolios"
	 * @param {object} evt event object
	 */
	onChangeIncludeCashInPortfolios = (isChecked) => {
		this.isCalledFromFundSearchbar = true;
		let _screenerOutput = cloneDeep(this.state.screenerOutput);
		if (isChecked) {
			let cash = Constant.CustomFunds.Cash;
			if (this.props.userType.response === 'International') {
				_screenerOutput = _screenerOutput.filter((fund)=> {return !Constant.CustomFundXids.includes(fund.MSDWSODIssue)});
				switch (this.state.templateResult.currency ? this.state.templateResult.currency : "USD") {
					case 'USD':
						cash = Constant.CustomFunds.Cash;
						break;
					case 'CHF':
						cash = Constant.CustomFunds.CashCHF;
						break;
					case 'EUR':
						cash = Constant.CustomFunds.CashEUR;
						break;
					case 'GBP':
						cash = Constant.CustomFunds.CashGBP;
						break;
				}
			} else {
				_screenerOutput = _screenerOutput.filter((fund)=> {return fund.MFSSymbol !== Constant.CustomFunds.Cash.MFSSymbol});
			}
			cash.isActive = false;
		    cash.isActiveP2 = false;
			_screenerOutput.push(cloneDeep(cash));
		} else {
			if (this.props.userType.response === 'International') {
				_screenerOutput = _screenerOutput.filter((fund)=> {return !Constant.CustomFundXids.includes(fund.MSDWSODIssue)});
	            this.state.pills = this.state.pills.filter(pill => { return !Constant.CustomFundXids.includes(pill.xid)});
			} else {
				_screenerOutput = _screenerOutput.filter((fund)=> {return fund.MFSSymbol !== Constant.CustomFunds.Cash.MFSSymbol});
			}
		}
		this.setState({
			isIncludeCashInPortfolios: isChecked,
			screenerOutput: _screenerOutput,
		})
	}

	/**
	 * Called when user click on 'X' to remove the fund
	 */
	onRemoveCashInPortfolios = () => {
		this.setState({
			isIncludeCashInPortfolios: false
		})
	}

	/**
	 * Returns object indicating whether portfolio 1 and 2 contains "Cash" Fund or not
	 * Return: { PortOne: bool, PortOne: bool }
	 * @param {object} nextProps 
	 */
	isPortfoliosContainCashFund = (nextProps) => {
		let isPortOneCashFundPresent = false;
		let isPortTwoCashFundPresent = false;
		if (nextProps && nextProps.templateData && nextProps.templateData.Portfolios) {
			let ports = nextProps.templateData.Portfolios;
			if (ports[0] && ports[0].Portfolio) {
				if (ports[0].Portfolio.length > 0) {
					if (this.props.userType.response === 'International') {
						const cashFundXids = ["220205352","622322921","622293033","622326501"];
						isPortOneCashFundPresent = ports[0].Portfolio.filter(fund => { return (Constant.CustomFundXids.includes(fund.wsodissue) || cashFundXids.includes(fund.wsodissue)) }).length > 0;
					} else {
						isPortOneCashFundPresent = ports[0].Portfolio.filter(fund => { return fund.symbol === Constant.CustomFunds.Cash.MFSSymbol }).length > 0;
					}	
				}
			}
			if (ports[1] && ports[1].Portfolio) {
				if (ports[1].Portfolio.length > 0) {
					if (this.props.userType.response === 'International') {
						isPortTwoCashFundPresent = ports[1].Portfolio.filter(fund => { return Constant.CustomFundXids.includes(fund.wsodissue) }).length > 0;
					} else {
						isPortTwoCashFundPresent = ports[1].Portfolio.filter(fund => { return fund.symbol === Constant.CustomFunds.Cash.MFSSymbol }).length > 0;
					}
				}
			}
		}
		return {
			PortOne: isPortOneCashFundPresent,
			PortTwo: isPortTwoCashFundPresent
		};
	}

	includeCashInScreenerDataForCompPort = (screenerData, portfolioTemplate, portfolioIndex) => {
		let isCashInPortfolio = null;
		xmlToJSON.to_json(`<data>${portfolioTemplate[0].xmlData}</data>`, (error, data) => {
			let funds = [];
			const portfolios = data.Portfolios[0] ? data.Portfolios[0].Portfolio : data.Portfolios.Portfolio;
			if (portfolios[0] && portfolios[0].symbol !== undefined) {
				for (const index in portfolios) {
					const fund = portfolios[index];
					funds.push(fund);
				}
			} else if (portfolios.symbol !== undefined) {
				funds.push(portfolios);
			}
			isCashInPortfolio = this.props.userType.response === 'International' ? funds.filter(f => { return (Constant.CustomFundXids.includes(f.MSDWSODIssue) || Constant.CustomFundXids.includes(parseInt(f.wsodissue)))}).length === 1
				: funds.filter(f => { return f.symbol === Constant.CustomFunds.Cash.MFSSymbol}).length === 1;
		});
		if (isCashInPortfolio) {
			let cash = this.props.userType.response === 'International' ?  screenerData.find(fund => { return Constant.CustomFundXids.includes(fund.MSDWSODIssue) })
				: screenerData.find(fund => { return fund.MFSSymbol === Constant.CustomFunds.Cash.MFSSymbol });
			screenerData = this.props.userType.response === 'International' ? screenerData.filter(fund => { return !Constant.CustomFundXids.includes(fund.MSDWSODIssue) })
				: screenerData.filter(fund => { return fund.MFSSymbol !== Constant.CustomFunds.Cash.MFSSymbol });
			if (cash) {
				if (portfolioIndex === 1) {
					cash.isActive = true;
				} else if (portfolioIndex === 2) {
					cash.isActiveP2 = true;
				}
			} else {
				if (this.props.userType.response === 'International') {
					let cashByCurr = {};
					switch (this.state.templateResult.currency ? this.state.templateResult.currency : "USD") {
						case 'USD':
							cashByCurr = Constant.CustomFunds.Cash;
							break;
						case 'CHF':
							cashByCurr = Constant.CustomFunds.CashCHF;
							break;
						case 'EUR':
							cashByCurr = Constant.CustomFunds.CashEUR;
							break;
						case 'GBP':
							cashByCurr = Constant.CustomFunds.CashGBP;
							break;
					}
					cash = cloneDeep(cashByCurr);
				} else {
					cash = cloneDeep(Constant.CustomFunds.Cash);
				}
				if (portfolioIndex === 1) {
					cash.isActive = true;
					cash.isActiveP2 = false;
				} else if (portfolioIndex === 2) {
					cash.isActive = false;
					cash.isActiveP2 = true;
				}
			}
			screenerData.push(cash);
		}
		let _isIncludeCashInPortfolios =  this.props.userType.response === 'International' ? screenerData.filter(fund => {return Constant.CustomFundXids.includes(fund.MSDWSODIssue) }).length === 1
			: screenerData.filter(fund => {return fund.MFSSymbol === Constant.CustomFunds.Cash.MFSSymbol }).length === 1;
		this.setState({ isIncludeCashInPortfolios: _isIncludeCashInPortfolios });
		return screenerData;
	}

	calledFromFundSearchbar = (isCalled) => {
		this.isCalledFromFundSearchbar = isCalled;
	}

	setHypoStartDateWithBenchmark = () => {

		let p1 = {funds:[]};
		let p2 = {funds:[]};
		let p1OldestShareClassDates = [];
		let p2OldestShareClassDates = [];
		let p1Count = 0;
		let p2Count = 0;
		let asOfPerformanceDate = 0;
		
		let hypotheticalState = cloneDeep(this.state.hypotheticalState);

		// If user is coming from Module Selection
		if (Object.keys(this.comparisonOldestInceptionDateResult).length === 0) {
			let result = this.getComparisonOldestInceptionDate(this.props.oldestInceptionDates, asOfPerformanceDate, p1, p2, p1Count, p2Count, p1OldestShareClassDates, p2OldestShareClassDates);
			this.comparisonOldestInceptionDateResult = result;
		}
		
		let result = this.comparisonOldestInceptionDateResult;

		p1Count = result.p1Count;
		p2Count = result.p2Count;
		p1OldestShareClassDates = result.p1OldestShareClassDates;
		p2OldestShareClassDates = result.p2OldestShareClassDates;
		asOfPerformanceDate = result.asOfPerformanceDate;
		
		let sort = function (a, b) { return a - b }
		p1OldestShareClassDates.sort(sort);
		p2OldestShareClassDates.sort(sort);

		let oldestShareClassDate = 0;
		if (p1Count > 0 && p2Count > 0) {
			hypotheticalState.startPeriod = p1OldestShareClassDates[p1Count - 1] < p2OldestShareClassDates[p2Count - 1] ? utilityFunctions.getStartDateOfNextFullMonth(p2OldestShareClassDates[p2Count - 1]) : utilityFunctions.getStartDateOfNextFullMonth(p1OldestShareClassDates[p1Count - 1]);
			oldestShareClassDate = p1OldestShareClassDates[0] > p2OldestShareClassDates[0] ? p1OldestShareClassDates[0] : p2OldestShareClassDates[0];
		} else {
			hypotheticalState.startPeriod = p1Count > 0 ? utilityFunctions.getStartDateOfNextFullMonth(p1OldestShareClassDates[p1Count - 1]) : utilityFunctions.getStartDateOfNextFullMonth(p2OldestShareClassDates[p2Count - 1]);
			oldestShareClassDate = p1Count > 0 ? p1OldestShareClassDates[0] : p2OldestShareClassDates[0];
		}

		let benchmarkPricingStartdate = utilityFunctions.getStartDateOfNextFullMonth(this.props.benchmarkLatestDate.inceptionDate);
		hypotheticalState.earliestCommon = hypotheticalState.startPeriod < benchmarkPricingStartdate ? benchmarkPricingStartdate : hypotheticalState.startPeriod;
        
        //Set earliestCommon to 3 years earlier if date is less than 3 years
        hypotheticalState.earliestCommon = utilityFunctions.getDateIfLessThan3Years(hypotheticalState.earliestCommon, asOfPerformanceDate);

        hypotheticalState.startPeriod = hypotheticalState.earliestCommon;
		
		const beginDateIndexorFund = oldestShareClassDate < this.props.benchmarkLatestDate.inceptionDate ? this.props.benchmarkLatestDate.inceptionDate : oldestShareClassDate;
		const beginDate = utilityFunctions.getStartDateOfNextFullMonth(beginDateIndexorFund);
		const endDate = asOfPerformanceDate;
		const diffInMonths = utilityFunctions.getDiffInMonths(beginDate - 1, endDate);
		const beginDateEarliest = utilityFunctions.getStartDateOfNextFullMonth(hypotheticalState.earliestCommon);
		const diffInMonthsEarliest = utilityFunctions.getDiffInMonths(beginDateEarliest - 1, endDate);

		hypotheticalState.indexInception = beginDate;

		let validCommonDataPeriodArr = [];
		if (diffInMonthsEarliest >= 36) {
			validCommonDataPeriodArr.push({ label: 'Earliest Common', value: 0 });
			hypotheticalState.selectedPeriod = { label: 'Earliest Common', value: 0 };
		}
		// Now we need to show chart of minimum 11 months time period
		if (diffInMonths >= 36) {
			let dateValue = utilityFunctions.getPreviousPeriodYear(endDate, 3);
			if (diffInMonthsEarliest < 36) {
				hypotheticalState.startPeriod = dateValue;
				hypotheticalState.selectedPeriod = { label: '3 Years', value: dateValue };
			}
			validCommonDataPeriodArr.push({ label: '3 Years', value: dateValue });
		}
		if (diffInMonths >= 60) {
			validCommonDataPeriodArr.push({ label: '5 Years', value: utilityFunctions.getPreviousPeriodYear(endDate, 5) });
		}
		if (diffInMonths >= 84) {
			validCommonDataPeriodArr.push({ label: '7 Years', value: utilityFunctions.getPreviousPeriodYear(endDate, 7) });
		}
		if (diffInMonths >= 120) {
			validCommonDataPeriodArr.push({ label: '10 Years', value: utilityFunctions.getPreviousPeriodYear(endDate, 10) });
		}
		if (diffInMonths >= 180) {
			validCommonDataPeriodArr.push({ label: '15 Years', value: utilityFunctions.getPreviousPeriodYear(endDate, 15) });
		}
		validCommonDataPeriodArr.push({ label: 'Custom', value: -1 });

		hypotheticalState.periodList = validCommonDataPeriodArr;
		hypotheticalState.showCustomCalendar = false;
		hypotheticalState.customDate = 0;
		hypotheticalState.Id = utilityFunctions.getRandomNumber();

		if (hypotheticalState.earliestCommon === this.state.hypotheticalState.earliestCommon) {
			hypotheticalState.startPeriod = this.state.hypotheticalState.startPeriod;
			hypotheticalState.selectedPeriod = this.state.hypotheticalState.selectedPeriod; 
			hypotheticalState.customDate = this.state.hypotheticalState.customDate;
			hypotheticalState.showCustomCalendar = this.state.hypotheticalState.showCustomCalendar;
		}

		this.setState({ hypotheticalState: hypotheticalState });
	}
	
	changeCurrency =  (values, e) => {
		// set preventDefaultTimePeriod flag to prevent default time period being set only incase
		// change flow is from ModuleSelection.   
		if (!this.isPortfolioFundChangeDone && this.isFromModuleSelection ) {
		this.state.preventDefaultTimePeriod=true;
		}
		const value = values.value;
		if (this.state.templateResult.currency !== value) {
			this.state.templateData.currency = value;
			this.state.templateResult.currency = value;
			this.setState({
				templateResult: this.state.templateResult,
				templateData: this.state.templateData
			});
			if (this.props.userType.response === 'International') {
				let cash = {};
				let oldScreenerCashOutput = this.state.screenerOutput.filter(fund => { return Constant.CustomFundXids.includes(fund.MSDWSODIssue) });
				this.state.screenerOutput = this.state.screenerOutput.filter(fund => { return !Constant.CustomFundXids.includes(fund.MSDWSODIssue)});
				let xidItems = [220205352,622322921,622293033,622326501];
				this.state.pills = this.state.pills.filter(pill => { return !xidItems.includes(pill.xid)});
				if (oldScreenerCashOutput.length > 0) {
					let isActive = oldScreenerCashOutput[0].isActive;
					let isActiveP2 = oldScreenerCashOutput[0].isActiveP2;
					switch (value) {
						case 'USD':
							cash = Constant.CustomFunds.Cash;
							break;
						case 'CHF':
							cash = Constant.CustomFunds.CashCHF;
							break;
						case 'EUR':
							cash = Constant.CustomFunds.CashEUR;
							break;
						case 'GBP':
							cash = Constant.CustomFunds.CashGBP;
							break;
					}
					if (isActive != undefined) {
						cash.isActive = isActive;
					}
					if (isActiveP2 != undefined) {
						cash.isActiveP2 = isActiveP2;
					}
					this.state.pills.push({
						xid: cash.MSDWSODIssue, 
						symbol: cash.MFSSymbol,
						name: cash.MSDFundName
					});
					this.state.screenerOutput.push(cash);
				}
			}
		}
	}

	render() {
		let disableButtonStyle,disableCompareButtonStyle,benchmarkDisableButtonStyle, modulesDisableButtonStyle, hypotheticalsDisableButtonStyle;
		disableCompareButtonStyle = benchmarkDisableButtonStyle = modulesDisableButtonStyle = hypotheticalsDisableButtonStyle = {
			'pointer-events': 'none',
			opacity: 0.4
		};

		if (this.props.modalIsPortfolioCompare) {
			if (this.state.screenerOutput.filter(x => x.isActive).length === 0 && this.state.screenerOutput.filter(x => x.isActiveP2).length === 0) {
				disableButtonStyle = {
					'pointer-events': 'none',
					opacity: 0.4
				};
			}
		} else if (this.state.screenerOutput.filter(x => x.isActive).length == 0) {
			disableButtonStyle = {
				'pointer-events': 'none',
				opacity: 0.4
			};
		}
		if (this.state.templateData && this.props.modalIsPortfolioCompare) {
			if (this.state.benchmarks && this.state.benchmarks.filter(x => x.isChecked).length > 0 &&
				(this.state.screenerOutput.filter(x => x.isActive).length > 0 || this.state.screenerOutput.filter(x => x.isActiveP2).length > 0
				)) {
				disableCompareButtonStyle = {}
			}
		}

		if (this.props.modalIsPortfolioCompare && this.state.showHypotheticals) {
			disableCompareButtonStyle = {
				'pointer-events': 'none',
				opacity: 0.4
			};
		}
		
		modulesDisableButtonStyle = {}

		this.props.modalIsOpen ? this.disableScrolling() : this.enableScrolling();

		const DropdownIndicator = props => {
			return (
				components.DropdownIndicator && (
					<components.DropdownIndicator {...props}>
						<i
							className="dropdown"
							aria-hidden="true"
						/>
					</components.DropdownIndicator>
				)
			);
		};

		const customStyles = {
			control: (base, state) => ({
				...base,
				border: '1px solid rgba(34,36,38,.15) !important',
				boxShadow: '0 !important',
				'&:hover': {
					border: '1px solid rgba(34,36,38,.15) !important'
				},
				height: '36px',
				'min-height': '36px'
			}),
			option: (provided, state) => ({
				...provided,
				background: state.isSelected ? '#f4f4f4' : (state.isFocused ? '#f4f4f4' : '#FFF'),
				color: '#333',
				'font-weight': state.isSelected ? 'bold' : 'normal'
			}),
			menu: styles => ({ ...styles, margin: '0px' }),
			noOptionsMessage: styles => ({ ...styles, 'text-align': 'left', color: 'rgba(0,0,0,.4)' }),
			singleValue: base => ({ ...base, 'font-size':'16px'})
		}
		const customTheme = {
			borderRadius: 0,
			border: 0,
			colors: {
				primary: '#f4f4f4',
				primary50: '#f4f4f4',
				neutral0: '#FFF',
			},
		}
	
		const SingleValue = ({ children, ...props }) => (
			<components.SingleValue {...props}><span style={{'color':'#7e2d40'}}><b>Currency: </b></span>{children}</components.SingleValue>
		);
		
		return (
			<Modal
				isOpen={this.props.modalIsOpen}
				style={this.props.modalIsPortfolioCompare ? CustomModalStyles.SelectPortfolioFundsModalStyles : CustomModalStyles.SelectFundModalStyles}

				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
			>
				<div className={'selectPortfolioFundModal'}>
					{/* Header */}
					<div className={'headerContainer'}>
						<div className={'header'}>
							<div className={'header-text'}>
								{this.props.modalIsPortfolioCompare ?
									(this.state.isShowBenchmarkResults ? 'Select a Benchmark' : (this.state.showHypotheticals ? 'Select Your Hypotheticals' : 'Build Your Comparison')) : 'Build A Portfolio'}
							</div>
							<div className={'close-img'} onClick={this.closeComparisonModal} />
						</div>
					</div>
					{/* Buttons Container */}
					<div className={'buttonContainer'}>
						<div className={'buttons moduleSelection'}>
						
							{/* Bread Crumb */}
							{this.props.modalIsPortfolioCompare && this.state.isShowBenchmarkResults ?
								<div className={'breadCrumb link'}>
									<a onClick={this.hideBenchmarkAndHypotheticalsResults}>Add Portfolios </a> > Add Benchmark
									</div>
								:
								this.props.modalIsPortfolioCompare && this.state.showHypotheticals &&
								<div className={'breadCrumb link'}>
									<a onClick={this.hideBenchmarkAndHypotheticalsResults}>Add Portfolios </a> > <a onClick={this.hideHypotheticalsResults}>  Add Benchmark </a> > Add Hypotheticals
									</div>

							}
							{this.props.userType.response === 'International' && this.props.modalIsPortfolioCompare && !this.state.isShowBenchmarkResults && !this.state.showHypotheticals ? 
							<div className="label-style">
								<Select
									name="Currency"
									className="dropdown-mfs"
									theme={customTheme}
									styles={customStyles}
									isSearchable={false}
									defaultValue={{ label: this.state.templateResult.currency ? this.state.templateResult.currency:"USD", value: this.state.templateResult.currency ? this.state.templateResult.currency:"USD" }}
									value={{ label: this.state.templateResult.currency ? this.state.templateResult.currency:"USD", value: this.state.templateResult.currency ? this.state.templateResult.currency:"USD" }}
									onChange={this.changeCurrency}
									options={Constant.CurrencyList}
									components={{ DropdownIndicator, SingleValue }}
									//getOptionValue={option => `<b>Cash:</b> ${option}`}
									id="ddnCurrency" />
							</div> 
							: ''}
							{/* SELECT BENCHMARK */}
							{this.props.modalIsPortfolioCompare && !this.state.isShowBenchmarkResults && this.state.showHypotheticals ? null
								: (this.props.modalIsPortfolioCompare && !this.state.isShowBenchmarkResults) &&
								<button className={'save-portfolio-button'}
									onClick={lodash.debounce(async () => {
										await this.showBenchmarkResults()
							}, 100)}
									style={this.state.screenerOutput.filter(x => x.isActive).length > 0 || this.state.screenerOutput.filter(x => x.isActiveP2).length > 0
										? {} : benchmarkDisableButtonStyle}
								>
									SELECT BENCHMARK
								</button>

							}



							{/* SELECT HYPOTHETICALS */}
							{this.props.modalIsPortfolioCompare && this.state.isShowBenchmarkResults ?
								<button className={'save-portfolio-button'}
									onClick={this.showHypotheticals}
									style ={this.state.portoliosErrors.length > 0 || this.state.benchmarks.filter(item => {return item.isChecked}).length === 0  ? hypotheticalsDisableButtonStyle : {}}
								>
									SELECT HYPOTHETICALS
								</button> : ''}
							{/* SELECT MODULES */}
							<Link 
								to={'/Modules?p=1'}
								onClick={this.navigateToModuleSelection}
								style={{'display':'none'}}
								className={this.props.modalIsPortfolioCompare && this.state.showHypotheticals && 'select-modules-button'}
								id="btnSelectModules"
							>
								{this.props.modalIsPortfolioCompare && (this.state.showHypotheticals) && 'SELECT MODULES'}
							</Link>
							<Link 
								onClick={this.onClickModuleSelection}
								style={modulesDisableButtonStyle}
								className={this.props.modalIsPortfolioCompare && this.state.showHypotheticals && 'select-modules-button'}
							>
								{this.props.modalIsPortfolioCompare && (this.state.showHypotheticals) && 'SELECT MODULES'}
							</Link>
							{/* SAVE PORTFOLIO */}
							{!(this.state.isShowBenchmarkResults || this.state.showHypotheticals) ?
								<button className={'save-portfolio-button'}
									onClick={this.openSaveOrReplaceModal}
									style={disableButtonStyle}
								>
									{this.props.modalIsPortfolioCompare ? 'SAVE PORTFOLIOS' : 'SAVE PORTFOLIO'}
								</button> : ''
							}
						</div>
					</div>
					<div className={'body'}>
						<div className={'right-rail'}>
							<RightRail
								screenerData={this.state.screenerOutput}
								screenerFiltersOutput={this.state.screenerFiltersOutput}
								toggleCompareButton={this.toggleCompareButton}
								removeFund={this.removeFund}
								pushFund={this.pushFund}
								requestSymbolSearchApi={this.requestSymbolSearchApi}
								searchResults={this.state.searchResults}
								requestDecodeApi={this.requestDecodeApi}
								wsodIssue={this.state.wsodIssue}
								portfolioBenchmarksResults={this.state.benchmarks}
								requestGetBenchmarksApi={this.requestGetBenchmarksApi}
								primaryBenchmarksResult={this.props.primaryBenchmark}
								requestGetPrimaryBenchmarkApi={this.requestGetPrimaryBenchmarkApi}
								getPrimaryProspectusBenchmarkData={this.getPrimaryProspectusBenchmarkData}
								screenerMasterData={this.state.screenerMasterData}
								requestScreenerDataApi={this.props.requestScreenerDataApi}
								requestScreenerFiltersDataApi={this.props.requestScreenerFiltersDataApi}
								requestGetScreenerMasterDataApi={this.props.requestGetScreenerMasterDataApi}
								resetScreenerFiltersOutput={this.resetScreenerFiltersOutput}
								addAllFunds={this.addAllFunds}
								showAddAllTooltipP1={this.state.showAddAllTooltipP1}
								showAddAllTooltipP2={this.state.showAddAllTooltipP2}
								isPortfolio={true}
								isPortfolioCompare={this.props.modalIsPortfolioCompare}
								addFundsToFundResultsTable={this.addFundsToFundResultsTable}
								pills={this.state.pills}
								closeTooltip={this.closeTooltip}
								portfolioNamesList={this.props.portfolioNamesList}
								getTemplateForSelectedPortfolio={this.getTemplateForSelectedPortfolio}
								defaultSelectedPortfolioOneText={this.state.defaultSelectedPortfolioOneText}
								defaultSelectedPortfolioTwoText={this.state.defaultSelectedPortfolioTwoText}
								isShowBenchmarkResults={this.state.isShowBenchmarkResults}
								pushAllFund={this.pushAllFund}
								showHypotheticals={this.state.showHypotheticals}
								bindPeriodStartDate={this.bindPeriodStartDate}
								hypotheticalState={this.state.hypotheticalState}
								updateHypothicalStateTotalInvestment={this.updateHypothicalStateTotalInvestment}
								updateHypothicalStateFundValuesPercents={this.updateHypothicalStateFundValuesPercents}
								updateInvestments={this.updateInvestments}
								hideBenchmarkAndHypotheticalsResults={this.hideBenchmarkAndHypotheticalsResults}
								updateUnavailableFunds={this.updateUnavailableFunds}
								bindPeriodDropDown= {this.bindPeriodDropDown}
								workingCopyConatainerIds = {this.props.workingCopyConatainerIds}								
								comingFromHypotheticals = {this.comingFromHypotheticals}								
								validateInitialInvestment={this.validateInitialInvestment}
								eventsValidate={this.eventsValidate}
								setValidationModalTabs={this.setValidationModalTabs}
								firstTimeToHypotheticals={this.firstTimeToHypotheticals}
								isFirstTimeToHypotheticals = {this.state.isFirstTimeToHypotheticals}
								updateHypothicalStateFeeSchedules={this.updateHypothicalStateFeeSchedules}
								updatePortfolioFundSalesChargeType={this.updatePortfolioFundSalesChargeType}
								updatePortfolioFundBreakpointOrCustom={this.updatePortfolioFundBreakpointOrCustom}
								validateSameDayEvent = {this.validateSameDayEvent} 
								isFromModuleSelection={this.props.isFromModuleSelection}
								indexInception={this.props.benchmarkLatestDate}
								updateHypothicalStateMaintainInitialAlloc={this.updateHypothicalStateMaintainInitialAlloc}
								hypoInitAlloc={ref1 => this.childHypoInitAlloc = ref1}
								onChangeIncludeCashInPortfolios={this.onChangeIncludeCashInPortfolios}
								onRemoveCashInPortfolios={this.onRemoveCashInPortfolios}
								isIncludeCashInPortfolios={this.state.isIncludeCashInPortfolios}
								calledFromFundSearchbar={this.calledFromFundSearchbar}
								setComparisonInvestmentDetails={this.setComparisonInvestmentDetails}
								comparisonPortfolioInvestments={this.props.comparisonPortfolioInvestments}
								currencySymbol={(this.props.userType.response === 'International') ? utilityFunctions.getCurrencySymbolFromCurrency(this.state.templateResult.currency) : '$'}
								isInternationalUser={this.props.userType.response === 'International'}
								currency={this.state.templateResult.currency ? this.state.templateResult.currency:'USD'}
								isMarketingPage ={this.state.isMarketingPage}
							/>
							<ReplaceTemplateModal
								templateName={this.props.modalIsPortfolioCompare ? (this.props.ComparisonPortfolioTemplateName ? this.props.ComparisonPortfolioTemplateName : this.props.editComparisonTemplateName) : this.props.TemplateName}
								openSaveModal={this.openSaveModal}
								closeReplaceModal={this.closeReplaceModal}
								customStyles={CustomModalStyles.ReplaceTemplateModalStyles}
								saveTemplateSettings={this.replaceTemplateSettings}
								replaceModalIsOpen={this.state.replaceModalIsOpen}
								isPortfolio={true}
								isPortfolioCompare={this.props.modalIsPortfolioCompare}
							/>
							<SaveModal
								isSaveDisabled={this.state.isSaveDisabled}
								saveTemplateSettings={this.saveTemplateSettings}
								customStyles={CustomModalStyles.SaveModalStyles}
								closeSaveModal={this.closeSaveModal}
								saveModalIsOpen={this.state.saveModalIsOpen}
								handleInputChange={this.handleInputChange}
								status={this.state.statusMessage}
								handleEnterClickOnTextbox={this.handleEnterClickOnTextbox}
								isPortfolio={true}
								isPortfolioCompare={this.props.modalIsPortfolioCompare}
							/>


							<SaveComparisonPortfolioModal
								isSaveDisabled={this.state.isSaveDisabled}
								isSaveDisabledP2={this.state.isSaveDisabledP2}
								saveTemplateSettings={this.savePortfolioTemplateSettings}
								replaceTemplateSettings={this.replacePortfolioTemplateSettings}
								customStyles={this.state.customPortfolioModalStyle}
								closeSaveModal={this.closeSaveModal}
								saveModalIsOpen={this.state.savePortfolioModalIsOpen}
								handleInputChangePortfolio={this.handleInputChangePortfolio}
								status={this.state.statusMessage}
								statusP2={this.state.statusMessageP2}
								isReplaceP1={this.state.defaultSelectedPortfolioOneText === "Select Portfolio One"}
								isReplaceP2={this.state.defaultSelectedPortfolioTwoText === "Select Portfolio Two"}
								p1Name={this.state.currentP1Name}
								p2Name={this.state.currentP2Name}
								handleEnterClickOnTextbox={this.handleEnterClickOnTextbox}
								templateIdP1={this.state.defaultSelectedPortfolioOneText !== "Select Portfolio One"}
								templateIdP2={this.state.defaultSelectedPortfolioTwoText !== "Select Portfolio Two"}
								isPortfolio={true}
								p1Count={this.state.P1.length}
								p2Count={this.state.P2.length}
								onSaveReplaceChanged={this.handlePortfolioSaveReplaceChange}
								radioSaveReplaceSelectedP1={this.state.radioSaveReplaceSelectedP1}
								radioSaveReplaceSelectedP2={this.state.radioSaveReplaceSelectedP2}
								p1Description={this.state.defaultSelectedPortfolioOneDesc}
								p2Description={this.state.defaultSelectedPortfolioTwoDesc}
								saveReplaceAllTemplateSettings={this.saveReplaceAllTemplateSettings}
								isPortfoliosToSave={this.isPortfolioToSave}
							/>
							<SelectTemplateWarningModal
								replaceTemplateData={this.replaceTemplateData}
								customStyles={CustomModalStyles.SaveModalStyles}
								closetemplateWarningModal={this.closetemplateWarningModal}
								templateWarningModalIsOpen={this.state.templateWarningModalIsOpen}
							/>
							<ConfirmationModal
								userType={this.props.userType}
								goToHomePage={this.goToHomePage}
								closeConfirmationModal={this.closeConfirmationModal}
								confirmationModalIsOpen={this.state.confirmationModalIsOpen}
								isPortfolio={true}
								isPortfolioCompare={(this.isPortfolio1Saved && this.isPortfolio2Saved && this.isPortfolioAllSaved) ? false : this.props.modalIsPortfolioCompare}
								showBuildCompareButton={(this.isPortfolio1Saved && this.isPortfolio2Saved && this.isPortfolioAllSaved)}
								isPortfoliosToSave={this.isPortfolioToSave}
							/>
							<ValidationModal
								errorMessages={this.state.portoliosErrors}
								customStyles={CustomModalStyles.ValidationModalStyles}
								closeValidationModal={this.closeValidationModal}
								validationModalIsOpen={this.state.validationModalIsOpen}
							/>
							<BenchMarkValidationModal
								errorMessages={this.state.errors}
								customStyles={CustomModalStyles.ValidationModalStyles}
								closeValidationModal={this.closeBenchmarkValidationModal}
								validationModalIsOpen={this.state.benchmarkValidationModalIsOpen}
								isWithdrawalModal={this.state.errors.length > 0}
							/>
							<HypoChangesAlertModal
								isAlertModalOpen={this.state.isAlertModalOpen}
								hypoAlertModalStyles={CustomModalStyles.SaveAlertModalStyles}
								retainComparisonModal={this.backToComparisonModal}								
								redirectToLandingPage={this.closeAlertModal}
							/>

							<EventPortfolioChangeAlertModal								
								customStyles={CustomModalStyles.SaveAlertModalStyles}
								cancelEventValidation={this.closeEventValidationModal}
								confirmToChangePortfolio={this.redirectToSelectedModal}
								isAlertModalOpen={this.state.eventValidationModalIsOpen}
								isInternationalUser={this.props.userType.response === 'International'}
							/>	
							<ValidationModalTabs
								tabErrors={this.state.tabErrors}
								customStyles={CustomModalStyles.ValidationModalStyles}
								closeValidationModalTabs={this.closeValidationModalTabs}
								validationModalIsOpenTabs={this.state.validationModalIsOpenTabs}
							/>
							<DownloadingSpinnerModal
								downloadSpinnerModalIsOpen={this.state.isLoading}
								title = {"Loading..."}
							/>	
						</div>
					</div>

				</div>
			</Modal>
		)
	}
}
SelectPortfolioFundsModal.propTypes = {
	modalIsOpen: PropTypes.bool,
	modalIsPortfolioCompare: PropTypes.bool,
	closeModal: PropTypes.func,
	containerId: PropTypes.string,
	reportId: PropTypes.string,
	startNewFundComparison: PropTypes.bool,
	portfolioNamesList: PropTypes.Object,
	showBenchmarksModal: PropTypes.func,
	showHypotheticalsModal: PropTypes.func,
	workingCopyContainerId: PropTypes.func,
	updateBalanceInitial: PropTypes.func,	
	comingFromHypotheticals: PropTypes.func,
	firstTimeToHypotheticals: PropTypes.func,
	resetModuleSelectionFlag: PropTypes.func,
	callMaintenace: PropTypes.func,
	isMarketingPage: PropTypes.bool
};

export default SelectPortfolioFundsModal;
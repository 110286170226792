import React from 'react';
import Modal from 'react-modal';
import Select, { components } from 'react-select';
import { PropTypes } from 'prop-types';

import DatePicker from 'react-datepicker';
import moment from "moment";
import { cloneDeep } from "lodash";
import numeral from 'numeral';
import * as Constant from '../../../utils/constants';
import * as utilityFunctions from "../../../utils/constants";
import * as utilityMethods from "../../../utils/commonMethods";
import * as CustomModalStyles from '../../../utils/customModalStyles';

import RebalanceTableDetails from './RebalanceTableDetails';
import ValidationModal from '../../common/modal/ValidationModal.js';

import ResultSetTooLargeIcon from '../../common/Icons/ResultSetTooLargeIcon';
import BackArrowIcon from '../../common/Icons/BackArrowIcon';
import ExpandedArrow from '../../common/Icons/ExpandedArrow';
import ClosedArrow from '../../common/Icons/ClosedArrow';
import CalendarIcon from "../../common/Icons/CalendarIcon";
import closeSVG from "../../../images/Icon/Close.svg";

import screenerFlyoutStyles from '../../../styles/components/screenerFlyout.scss';

export default class PortWithdrawalFlyout extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			selectedFrequencyType: "Monthly",
			disableSaveEventButton: false,
			fundInvestmentEvent: [],
			includeRebalance: false,
			rebalanceValid: false,
			rebalanceOpen: true,
			validationModalIsOpen: false,
            errors: [],
            portSchedule: {
                invest: 0.00,
                frequency: { label: 'Every 1 Month', value: '1' },
                endDate: this.props.endDate,
                dateBegin: utilityMethods.convertToOADate(this.props.selectedEventDate)-1,
                increaseBy: { label: '%', value: '%' },
                increaseByValue: 0,
                increaseFrequency: { label: 'Every 1 Month', value: 1 }
            }
		};
		this.isRebalanceValidForSelectedPeriod = false;
		this.showWarningLabelOnChkUncheck = false;
		this.defaultIncreaseByType = { label: '%', value: '%' }
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(this.props.openEventFlyout != nextProps.openEventFlyout && nextProps.openEventFlyout) {
			if(nextProps.index === 0) {	 
				this.isRebalanceValidForSelectedPeriod = this.props.schedules && this.props.schedules.filter(x => x.DateBegin <= utilityMethods.convertToOADate(this.props.selectedEventDate) - 1).length > 0;
				this.state.includeRebalance = this.isRebalanceValidForSelectedPeriod;
				if(this.state.portSchedule.frequency.value !== 'One-Time') {
					this.setState({rebalanceValid : false});
				}	
			} else if(nextProps.index === 1) {	 
				this.isRebalanceValidForSelectedPeriod = this.props.schedulesP2 && this.props.schedulesP2.filter(x => x.DateBegin <= utilityMethods.convertToOADate(this.props.selectedEventDate) - 1).length > 0;
				this.state.includeRebalance = this.isRebalanceValidForSelectedPeriod;
				if(this.state.portSchedule.frequency.value !== 'One-Time') {
					this.setState({rebalanceValid : false});
				}
				this.showWarningLabelOnChkUncheck = false;
			}
			this.hypotheticalSaveEventState = null;
		}
	}
	resetFlyout = () => {
		this.setState({
			selectedFrequencyType: "Monthly",
			fundInvestmentEvent: [],
			includeRebalance: false,
			disableSaveEventButton: false,
			rebalanceValid: false,
			portSchedule: {
				invest: 0.00,
				frequency: { label: 'Every 1 Month', value: '1' },
				endDate: this.props.endDate,
				dateBegin: utilityMethods.convertToOADate(this.props.selectedEventDate)-1,
				increaseBy: { label: '%', value: '%' },
				increaseByValue: 0,
				increaseFrequency: { label: 'Every 1 Month', value: 1 }
			}
		});
		this.isRebalanceValidForSelectedPeriod = false;	
	}

	handleRebalanceAccordionClick = () => {
		let rebalanceOpen = !(this.state.rebalanceOpen);
		this.setState({
			rebalanceOpen: rebalanceOpen
		});
	}
	handleHeaderAccordionClick = (e, index) => {
		let fundInvestmentEvent = cloneDeep(this.state.fundInvestmentEvent);
		fundInvestmentEvent[index].isOpen = !(fundInvestmentEvent[index].isOpen);
		this.setState({
			fundInvestmentEvent: fundInvestmentEvent
		});
	}
	handleInvestListChange(option, dataName) {

		// Since this methed is called by multiple controls, so we have to take initial value 
		// from state.
		let rebalanceValid = this.state.rebalanceValid;

		// In case of frequency, we have make it false so that if 'One-Time' is selected, 
		// only then it is true.
		if (dataName === 'frequency') {
			rebalanceValid = false;
			if (option.value === 'One-Time') {
				rebalanceValid = true;
			}
		}
		
		let portfolioscd = cloneDeep(this.state.portSchedule);
		if(dataName === 'endDate') {	
			portfolioscd[dataName] = utilityMethods.convertToOADate(option) - 1;
		} else {
			portfolioscd[dataName] = option;
		}
        this.setState({
			portSchedule: portfolioscd,
			rebalanceValid: rebalanceValid
		}, () => this.updateFundInvestmentEventState(true));
	}
	handleInvestChange = (event) => {
		let dataName = event.target.getAttribute('data-name');
		this.handleInvestListChange(event.target.value, dataName);
	}
	handleCheckChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		if(this.isRebalanceValidForSelectedPeriod && target.type === 'checkbox' && !target.checked) {
			this.showWarningLabelOnChkUncheck = true;
		} else if(this.state.includeRebalance && target.type === 'checkbox' && !target.checked) { 
			this.showWarningLabelOnChkUncheck = true;
		} else {
			this.showWarningLabelOnChkUncheck = false;
		}
		this.isRebalanceValidForSelectedPeriod = false;
		this.setState({
			includeRebalance: value
		});
		this.updateStateRebalanceValid();
	}
	addFundInvestmentEvent = () => {
		let wsodIssue = document.getElementById("selectFund").childNodes[1].value;
		let fundInvestmentEvent = cloneDeep(this.state.fundInvestmentEvent);
		let data = this.props.hypotheticalState;
		data.portfolios[this.props.index].funds.forEach(item => {
			if (wsodIssue === item.wsodissue.toString()) {
				let wsodIndex = fundInvestmentEvent.findIndex(x => {
					return x.wsodissue.toString() === wsodIssue;
				});
				if (wsodIndex > -1) {
					if (fundInvestmentEvent[wsodIndex].data.length < 2) {
						fundInvestmentEvent[wsodIndex].data.push({
							invest: 0.00,
							frequency: fundInvestmentEvent[wsodIndex].data[0].frequency.value === 'One-Time' ? { label: 'Every 1 Month', value: '1' } : { label: 'One-Time', value: 'One-Time' },
							endDate: this.props.endDate,
							dateBegin: utilityMethods.convertToOADate(this.props.selectedEventDate)-1,
							increaseBy: { label: '%', value: '%' },
							increaseByValue: 0,
							increaseFrequency: { label: 'Every 1 Month', value: 1 }
						});
					}
				} else {
					fundInvestmentEvent.push({
						wsodissue: item.wsodissue,
						symbol: item.symbol,
						name: item.name,
						investmentId: item.investmentId,
						isOpen: true,
						data: [{
							invest: 0.00,
							frequency: { label: 'Every 1 Month', value: 1 },
							endDate: this.props.endDate,
							dateBegin : utilityMethods.convertToOADate(this.props.selectedEventDate)-1,
							increaseBy: { label: '%', value: '%' },
							increaseByValue: 0,
							increaseFrequency: { label: 'Every 1 Month', value: 1 }
						}]
					})
				}
				this.setState({
					fundInvestmentEvent: fundInvestmentEvent,
					rebalanceValid: false
				});
			}
		});

	}

	resetTab = () => {
		this.props.closeEventFlyout();
	}

	closeFlyout = () => {
		this.resetFlyout();
		this.props.closeEventFlyout();
	}

	onChangeFunds(e) {
		if (document.getElementById("selectFund") && document.getElementById("selectFund").childNodes[1].value === '') {
			document.getElementsByClassName('investment-add-button')[0].style.opacity = '0.4'
			document.getElementsByClassName('investment-add-button')[0].style['pointer-events'] = 'none'
		} else {
			document.getElementsByClassName('investment-add-button')[0].style.opacity = '1'
			document.getElementsByClassName('investment-add-button')[0].style['pointer-events'] = 'all'
		}
	}

	onSelectedFrequencyType = (e) => {
		let value = document.getElementById('percentageTotal').getAttribute('data-percentageTotal');
		this.validatePercentValueTotal(value);
	}

	validatePercentValueTotal = (value) => {
		if (document.getElementsByClassName('event-save-button')[0]) {
			if(this.state.includeRebalance === true && this.state.rebalanceValid && this.state.fundInvestmentEvent.length > 0){
			if (parseInt(value) !== 100 || document.getElementById("selectFrequency").childNodes[1].value === '') {
				document.getElementsByClassName('event-save-button')[0].style.opacity = '0.4'
				document.getElementsByClassName('event-save-button')[0].style['pointer-events'] = 'none'
			} else {
				document.getElementsByClassName('event-save-button')[0].style.opacity = '1'
				document.getElementsByClassName('event-save-button')[0].style['pointer-events'] = 'all'
			}
		}else if (this.state.fundInvestmentEvent.length > 0) {
				document.getElementsByClassName('event-save-button')[0].style.opacity = '1'
				document.getElementsByClassName('event-save-button')[0].style['pointer-events'] = 'all'
			}
		}
	}

	saveEventsHypotheticalState = (hypoState) => {
		this.hypotheticalSaveEventState = hypoState;
	}
	setAllocations() {
		let fund = document.getElementsByClassName('portfolio-invested text-right event-set-amount')
		const dictSymbolWeightage = {};
		for (let count = 0; count < fund.length; count++) {
			dictSymbolWeightage[fund[count].getAttribute('data-symbol')] = parseFloat((parseFloat(fund[count].getAttribute('data-raw-value')) * .01).toFixed(4));
		}
		return dictSymbolWeightage;
	}
	closeValidationModal = () => {		
		this.setState({validationModalIsOpen: false});
	}
	validateFundInvestmentFrequency = () => {
		let error = ['Please ensure that all percent values are less than or equal to 100.'];
		this.setState({ validationModalIsOpen: true, errors: error });
	}

	validateWithdrawalAmount = (errorMsg) => {
		this.setState({ validationModalIsOpen: true, errors: errorMsg });
	}

	// When user click on "Save Event" when adding investmemt fund
	saveEvent = () => {
		this.updateFundInvestmentEventState(false);
		let fundInvestment = [];
		let isInvalid = false;
		let invalidWithdrawalFundsList = [];
		this.state.fundInvestmentEvent.forEach(item => {
			item.data.forEach(x => {				
				if (x.increaseBy.value === "%") {
					if (parseFloat(x.increaseByValue) > 100) {
						return isInvalid = true;
					}
				}
			});
		});

		if(!isInvalid) {
			//Set each fund allocation is as per final allocation at that time
			let tmpfundInvestment = this.state.fundInvestmentEvent;
			let ctrAllFundsAllocations = (this.props.index === 0) ? this.props.ctrAllFundsAllocationsP1 : this.props.ctrAllFundsAllocationsP2;
			ctrAllFundsAllocations.forEach(f=>{
				let investment = tmpfundInvestment.filter(item=>item.wsodissue === f.wsodIssue)[0];				
				investment.data[0].invest = (this.state.portSchedule.invest * f.allocationPer).toFixed(2);
				//To set end date is greater than end date of portfolio then, set portfolio end date
				if(investment.data[0].endDate > this.props.endDate) {
					investment.data[0].endDate = this.props.endDate;
				}				
				fundInvestment.push(investment);
			});

			let amount = 0;
			for (let i = 0; i < tmpfundInvestment.length - 1; i++) {
				amount += parseFloat(tmpfundInvestment[i].data[0].invest)
			}
			tmpfundInvestment[tmpfundInvestment.length - 1].data[0].invest = parseFloat((this.state.portSchedule.invest - amount).toFixed(2));
			
			//Check if all withdrawal amounts for funds are valid			
			fundInvestment.forEach(f=>{
				let withdrawalamount = this.props.fundsPresentAmountList.filter(item=>item.wsodIssue == f.wsodissue)[0].investAmount;
				amount = withdrawalamount.toFixed(2);
				let _amountEntered = parseFloat(f.data[0].invest);
				_amountEntered > amount &&
          		invalidWithdrawalFundsList.push({
           		 	symbol: f.symbol,
            		wsodissue: f.wsodissue,
            		amountEntered: numeral(_amountEntered).format("0,0.00"),
           			amountCanBeWithdrawn: numeral(amount).format("0,0.00"),
          		});
					
			});
			
		}

		if (isInvalid) {
			this.validateFundInvestmentFrequency();
		} else if(invalidWithdrawalFundsList.length > 0) {
			let errorMsg = [];
			let currencySymbol = this.props.currencySymbol;			
			errorMsg.push('The entered withdrawal amount exceeds the value of the following fund(s):');
			invalidWithdrawalFundsList.forEach(item=>{
				errorMsg.push(item.symbol +", withdrawal entered " + currencySymbol + item.amountEntered+", available for withdrawal "+currencySymbol +item.amountCanBeWithdrawn);
			});
			this.validateWithdrawalAmount(errorMsg);			
		} else {
			let schedules = {};
			if (this.state.rebalanceValid === true && this.state.includeRebalance === true && this.state.fundInvestmentEvent.length > 0) {
				let investmentsWithAllocations = [];
				let data = this.hypotheticalSaveEventState ? this.hypotheticalSaveEventState: this.props.hypotheticalState;
				schedules.dateBegin = utilityMethods.convertToOADate(this.props.selectedEventDate);
				schedules.dateEnd = data.endPeriod;
				schedules.frequency = document.getElementById("selectFrequency").childNodes[1].value;
				schedules.snapPeriods = "End";
				schedules.includeFees = false;
				this.dictSymbolWeightage = this.setAllocations();
				data.portfolios[this.props.index].funds.forEach(item => {
					investmentsWithAllocations.push({
						allocation: this.dictSymbolWeightage[item.symbol] == undefined ? 0 : this.dictSymbolWeightage[item.symbol],
						investmentId: item.investmentId
					})
				})
				schedules.investmentsWithAllocations = investmentsWithAllocations;

				// If already a rebalance exists on same start date, then delete it
				let sameRebalanceOnStartDate = [];
				sameRebalanceOnStartDate = this.setSameRebalanceOnStartDateOnSaveEvent(schedules);
				sameRebalanceOnStartDate.length > 0 &&
          		this.props.handleRebalanceAdjustment(
            		this.props.index,
            		this.props.workingCopyConatainerId,
            		null,
            		sameRebalanceOnStartDate[0].Id
          		);
				
				this.props.requestSaveEventTemplateDetailsApi(this.props.index, this.props.workingCopyConatainerId, schedules, '');
			}

			this.props.requestSaveEventTemplateDetailsApi(this.props.index, this.props.workingCopyConatainerId, null, '', this.state.fundInvestmentEvent[0].data[0]);						

			//Set each fund allocation is as per final allocation at that time
			// let tmpfundInvestment = this.state.fundInvestmentEvent;
			// let fundInvestment = [];
			// let ctrAllFundsAllocations = (this.props.index === 0) ? this.props.ctrAllFundsAllocationsP1 : this.props.ctrAllFundsAllocationsP2;
			// ctrAllFundsAllocations.forEach(f=>{
			// 	let investment = tmpfundInvestment.filter(item=>item.wsodissue === f.wsodIssue)[0];				
			// 	investment.data[0].invest = (this.state.portSchedule.invest * f.allocationPer).toFixed(2);
			// 	//To set end date is greater than end date of portfolio then, set portfolio end date
			// 	if(investment.data[0].endDate > this.props.endDate) {
			// 		investment.data[0].endDate = this.props.endDate;
			// 	}				
			// 	fundInvestment.push(investment);
			// });

			let investmentSchedules = JSON.stringify(fundInvestment);
			this.props.requestSaveWithdrawalEventApi(investmentSchedules, this.props.workingCopyConatainerId);
			this.props.calledSave(this.props.selectedPortfolio);
			this.closeFlyout();
		}
	}

	setSameRebalanceOnStartDateOnSaveEvent = (schedules) => {
		let sameRebalanceOnStartDate = []
		if (this.props.index === 0) {
			sameRebalanceOnStartDate = this.props.schedules.filter(item => { return item.DateBegin === schedules.dateBegin-1 });
		} else {
			sameRebalanceOnStartDate = this.props.schedulesP2.filter(item => { return item.DateBegin === schedules.dateBegin-1 });
		}
		return sameRebalanceOnStartDate;
	}

	handleDeleteInvestmentRow = (e) => {
		let fundInvestmentEvent = cloneDeep(this.state.fundInvestmentEvent);
		const dataIndex = e.currentTarget.getAttribute('data-index1');
		const index = e.currentTarget.getAttribute('data-index');
		if(fundInvestmentEvent[index].data.length === 1){
			fundInvestmentEvent.splice(index,1)
		} else{
		fundInvestmentEvent[index].data.splice(dataIndex,1)
		}
		let rebalanceValid = true;
		fundInvestmentEvent.map(item=>{
			return item.data.map(item1=>{
				if(item1.frequency.value !== 'One-Time'){
					return rebalanceValid = false;
				}
			})
		})
		this.setState({fundInvestmentEvent :fundInvestmentEvent, rebalanceValid: rebalanceValid})
	}

	updateStateRebalanceValid = () => {
		let rebalanceValid = true;
		if (this.state.portSchedule.frequency.value !== 'One-Time') {
			rebalanceValid = false;
		}
		this.setState({ rebalanceValid: rebalanceValid });
	}

	updateFundInvestmentEventState = (isUseSetState) => {
		// Distribute portfolio invest amount among funds based on allocation percent
		let fundInvestmentEvent = [];
		let _hypoState = this.hypotheticalSaveEventState ? this.hypotheticalSaveEventState: this.props.hypotheticalState;
		_hypoState.portfolios[this.props.index].funds.forEach(fund => {
			let portSchedule = cloneDeep(this.state.portSchedule);
			portSchedule.dateBegin = utilityMethods.convertToOADate(this.props.selectedEventDate) - 1 ;			
			fundInvestmentEvent.push({
				wsodissue: fund.wsodissue,
				symbol: fund.symbol,
				name: fund.name,
				investmentId: fund.investmentId,
				isOpen: true,
				data: [portSchedule]
			});
		});
		if (isUseSetState) {
			this.setState({
				fundInvestmentEvent: fundInvestmentEvent
			});	
		} else {
			this.state.fundInvestmentEvent = fundInvestmentEvent;
		}
	}
	render() {
		const DropdownIndicator = props => {
			return (
				components.DropdownIndicator && (
					<components.DropdownIndicator {...props}>
						<i
							className="dropdown"
							aria-hidden="true"
						/>
					</components.DropdownIndicator>
				)
			);
		};
		let disableButtonStyle = {
			'pointer-events': 'none',
			opacity: 0.4
		};
		return (
			<Modal
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				isOpen={this.props.openEventFlyout}
				style={this.props.customStyles}
				onRequestClose={this.closeFlyout}
			>
				<div className={'screenerflyout-styles investment-flyout'}>
					<span className={'event-flyout-header'}>
						<span className={'screener-flyout-title-backArrow'} onClick={this.closeFlyout} ><BackArrowIcon /></span>
						<span className={'screener-flyout-title'}>Portfolio Withdrawal starting on {this.props.selectedEventDate != '' ? ((this.props.userType==='International') ? <span className={'event-selected-date'}> {utilityMethods.formatOADate(utilityMethods.convertToOADate(this.props.selectedEventDate),"DD MMM, YYYY")} </span> : <span className={'event-selected-date'}> {this.props.selectedEventDate} </span>) : ''}</span>
						<span className={'span-save-button'}>
							<button
								onClick={this.saveEvent}
								data-portfoliotype={this.props.selectedPortfolio}
								className={'event-save-button'}
								style={this.state.fundInvestmentEvent.length > 0 ? {} : disableButtonStyle}>
							
								SAVE EVENT
								</button>

						</span>
						<span className={'screener-flyout-horizontal'} >
							<div className={'investment-message'}>
							A portfolio withdrawal will be withdraw money from your funds according the the most recently set allocation. The allocations are displayed below. If you include a rebalance in this event your investment will be distributed based on the rebalance allocation.
							</div>
							<div className={'filter-styles portfolio-investment-div'}>
                                {/* Filter code Start */}
                                <div className={'portfolio-invest-module'}>
                                    <div className={'tile'}>
                                        <div className={'control-list'}>
                                            <div className={'invest-label padding-left-invest'}>
											Withdrawal
                                            </div>
                                            <div className={'invest-control'}>
                                                {this.props.currencySymbol} <input className={'fund-invest'}
                                                    type="text"
                                                    onBlur={this.onBlurFundInvest}
                                                    onKeyDown={utilityMethods.allowOnlyNumbers}
                                                    data-raw-value={this.state.portSchedule.invest}
                                                    data-name={'invest'}
                                                    onChange={this.handleInvestChange}
                                                    value={this.state.portSchedule.invest}
                                                />
                                            </div>
                                        </div>
                                        <div className={'control-list'}>
                                            <div className={'invest-label'}>
                                                Frequency
                                            </div>
                                            <div className={'invest-control'}>
                                                <Select
                                                    name="select-time-period\"
                                                    className="dropdown-mfs"
                                                    theme={utilityFunctions.customTheme}
                                                    styles={utilityFunctions.customStyles}
                                                    isSearchable={false}
                                                    onChange={e => this.handleInvestListChange(e, 'frequency')}
                                                    options={utilityFunctions.fundInvestFrequencyTypes}
                                                    components={{ DropdownIndicator }}
                                                    defaultValue={this.state.portSchedule.frequency}
                                                    value={this.state.portSchedule.frequency}
                                                    id="fundInvestFrequency" />
                                            </div>
                                        </div>
                                        <div className={'control-list'}>
                                            <div className={'invest-label end-date'}>
                                                End Date
                                            </div>
                                            <div className={'invest-control'}>
                                                <CalendarIcon />
                                                <DatePicker
                                                    selected={new Date(moment(utilityMethods.formatOADate(parseInt(this.state.portSchedule.endDate) + 1)))}
                                                    onChange={e => this.handleInvestListChange(e, 'endDate')}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker={true}
                                                    shouldCloseOnSelect={true}
                                                    placeholderText={"End Date"}
                                                    showDisabledMonthNavigation
                                                    disabled={this.state.portSchedule.frequency.value === 'One-Time'}
                                                />
                                            </div>
                                        </div>
                                        <div className={'control-list'}>
                                            <div className={'invest-label'}>
                                                Increase by
                                            </div>
                                            <div className={`invest-control increase-by-type ${this.state.portSchedule.frequency.value === 'One-Time' ? 'disabled-style' : ''}`}>
                                                <Select
                                                    name="increase-by-type"
                                                    className="dropdown-mfs"
                                                    theme={utilityFunctions.customTheme}
                                                    styles={utilityFunctions.customStyles}
                                                    isSearchable={false}
                                                    onChange={e => this.handleInvestListChange(e, 'increaseBy')}
                                                    options={[
														{ label: '%', value: '%' },
														{ label: this.props.currencySymbol, value: this.props.currencySymbol }
													]}
                                                    components={{ DropdownIndicator }}
                                                    defaultValue={this.defaultIncreaseByType}
                                                    value={this.state.portSchedule.increaseBy}
                                                    id="fundIncreaseByType"
                                                    isDisabled={this.state.portSchedule.frequency.value === 'One-Time'}
                                                />
                                            </div>
                                            <div className={'invest-control fund-increase-by'}>
                                                <input className={'fund-invest'}
                                                    type="text"
                                                    onBlur={this.onBlurFundInvest}
                                                    onKeyDown={utilityMethods.allowOnlyNumbers}
                                                    data-raw-value={this.state.portSchedule.invest}
                                                    data-name={'increaseByValue'}
                                                    onChange={this.handleInvestChange}
                                                    value={this.state.portSchedule.increaseByValue}
                                                    disabled={this.state.portSchedule.frequency.value === 'One-Time'}
                                                />
                                            </div>
                                        </div>
                                        <div className={'control-list'}>
                                            <div className={'invest-label'}>
                                                Increase Frequency
                                            </div>
                                            <div className={`invest-control ${this.state.portSchedule.frequency.value === 'One-Time' ? 'disabled-style' : ''}`}>
                                                <Select
                                                    name="increase-by-frequency"
                                                    className="dropdown-mfs"
                                                    theme={utilityFunctions.customTheme}
                                                    styles={utilityFunctions.customStyles}
                                                    isSearchable={false}
                                                    onChange={e => this.handleInvestListChange(e, 'increaseFrequency')}
                                                    options={utilityFunctions.fundInvestFrequencyTypesIncrease}
                                                    components={{ DropdownIndicator }}
                                                    defaultValue={this.state.portSchedule.frequency}
                                                    value={this.state.portSchedule.increaseFrequency}
                                                    id="fundIncreaseByFrequency"
                                                    isDisabled={this.state.portSchedule.frequency.value === 'One-Time'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'chkBoxDiv'}>
										<input
											id={'chkIncludeRebalance'}
											type="checkbox"
											checked={this.state.portSchedule.frequency.value === 'One-Time' && (this.isRebalanceValidForSelectedPeriod || this.state.includeRebalance)}
											onChange={this.handleCheckChange}
											disabled={this.state.portSchedule.frequency.value !== 'One-Time'}
                                            />
										<label className={'lblIncludeRebalance'}>Include Rebalance</label>
										{this.showWarningLabelOnChkUncheck && document.getElementById('chkIncludeRebalance') && !document.getElementById('chkIncludeRebalance').checked &&
											<label className={'rebalanceWarning'}>By un-selecting you're portfolio will no longer be kept in balance</label>
										}
									</div>
                                </div>
                                {/* Filter code End*/}
							</div>
						</span>
					</span>
					<div className={'filter-styles fund-investment-div'}>
						{/* Show Rebalance always */}
						<div id={'includeRebalace'} className={'includeRebalance'}>
							<div class="accordion" onClick={this.handleRebalanceAccordionClick}>
								<div class="symbol">
									<span className={'arrow'}>{this.state.rebalanceOpen ? <ExpandedArrow /> : <ClosedArrow />}</span>
									<label className={'symbolChar'}>View Allocation or Adjust Rebalance</label>
								</div>
								<div class="event">
									{this.state.rebalanceValid ? '' : 'Rebalance Unavailable'}
								</div>
								<div class="subtitle">
									Allocations Based on Previous Allocation
								</div>
							</div>
							<div style={this.state.rebalanceOpen ? (this.state.rebalanceValid && this.state.includeRebalance ? {} : disableButtonStyle): {display:'none'}}>
							<span className={'investment-flyout-horizontal'}>
								<div className={'filter-styles investment-filter-styles'}>
									<div className={'label-frequency'}>Frequency </div>
									<div className="label-style event-label-style">
										<Select
											name="select-time-period\"
											className="dropdown-mfs"
											theme={utilityFunctions.customTheme}
											styles={utilityFunctions.customStyles}
											isSearchable={false}
											defaultValue={this.props.editFrequencyType}
											onChange={this.onSelectedFrequencyType}
											options={utilityFunctions.frequencyTypes}
											components={{ DropdownIndicator }}
											id="selectFrequency" />
									</div>
								</div>
							</span>
							<div className={'filter-styles'}>
								<div className={'portfolio-investment2 investment-table-details event-table-details'}>
									{this.props.hypotheticalState.portfolios && this.props.hypotheticalState.portfolios.length > 0 &&
										<RebalanceTableDetails
											updateHypothicalStateFundValuesPercents={this.props.updateHypothicalStateFundValuesPercents}
											index={this.props.selectedPortfolio === 'One' ? 0 : 1}
											hypotheticalState={this.props.hypotheticalState}
											validatePercentValueTotal={this.validatePercentValueTotal}
											selectedEventDate={this.props.selectedEventDate}
											saveEventsHypotheticalState={this.saveEventsHypotheticalState}
											autoDistribute={this.props.autoDistribute}
											includeRebalance= {this.state.includeRebalance}
											rebalanceValid ={this.state.rebalanceValid && this.state.fundInvestmentEvent.length > 0}
											userType={this.props.userType}
										/>}
								</div>
							</div>
							</div>
						</div>
					</div>
					<ValidationModal
								errorMessages={this.state.errors}
								customStyles={CustomModalStyles.ValidationModalStyles}
								closeValidationModal={this.closeValidationModal}
								validationModalIsOpen={this.state.validationModalIsOpen}
								isWithdrawalModal={this.state.errors.length>0}
							/>
				</div>
				
			</Modal>)
	}
}

PortWithdrawalFlyout.propTypes = {
	openEventFlyout: PropTypes.bool,
	customStyles: PropTypes.object,
	closeEventFlyout: PropTypes.func,
	calledSave: PropTypes.func,
	autoDistribute: PropTypes.func,
	fundsData: PropTypes.object,
	validateFundInvestmentFrequency: PropTypes.func
};
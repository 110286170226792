import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router'; 

import logoutModalStyles from '../../../styles/components/modal/logoutModal.scss';

class LogoutModal extends React.PureComponent {
	render() {
		return (
			<Modal
				shouldCloseOnOverlayClick
				shouldCloseOnEsc
				isOpen={this.props.isUserLoggedOut}
				style={this.props.customStyles}
			>
				<div className={'logoutModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'header-text'}>Logout</div>
							</div>
						</div>
						<div className={'logout-warning'}>
              Are you sure you want to logout?
						</div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<div
									onClick={this.props.closeLogOutModal}
									className={'cancel-button'}
								>
									<span>CANCEL</span>
								</div>
								<Link to={'/LogOut'}
									className={'logout-button'}
								>
									<span>LOG OUT</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

LogoutModal.propTypes = {
	isUserLoggedOut: PropTypes.bool,
	customStyles: PropTypes.object,
	logoutUser: PropTypes.func,
	closeLogOutModal: PropTypes.func
};

export default LogoutModal;

import React from 'react';
import { PropTypes } from 'prop-types';

import numeral from 'numeral';

import * as utilityFunctions from "../../../../utils/commonMethods";

import portfolioInvestmentStyle from '../../../../styles/components/portfolio/PortfolioInvestmentTable.scss';


export default class PortfolioInvestmentTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      InvestmentType: 1, // 1: dollar ($), 2: Percentage (%)
    };
  }

  dateFormatter(msDate, format) {
    if (!msDate) {
      return "--";
    }
    if (format) {
      return utilityFunctions.formatOADate(msDate, format);
    } else {
      return utilityFunctions.convertFromOADate(msDate);
    }
  }

  dateFormatterIntl(msDate, format) {
    if (!msDate) {
      return "--";
    }    
    if (format) {
      return utilityFunctions.formatOADate(msDate, format);
    } else {
      return utilityFunctions.convertFromOAIntlDate(msDate);
    }
  }  

  componentDidMount() {
    this.populateFundValues();
  }

  componentDidUpdate() {
    this.populateFundValues();
  }

  // Populate fund dollars textbox from original values
  populateFundValues = () => {
    let tables = document.querySelectorAll(".portfolio-table");
    let that = this; //  because of forEach loop
    tables.forEach(function (table) {
      let rowsCount = table.rows.length;
      for (let i = 0; i < rowsCount; i++) {
        let row = table.rows[i];
        for (let j = 0; j < row.cells.length; j++) {
          if (j == 2 || j == 3) {
            let cell = row.cells[j];
            let element = cell.children[0];
            if (element && element.tagName === 'DIV') {
              let element1 = element.children[0];
              if (element1 && element1.tagName === 'INPUT' && element1.type === 'text') {
                let rawValue = element1.getAttribute('data-raw-value');
                element1.value = that.formatInvestmentDollar(parseFloat(rawValue), '.00');
              }
            }
          }
        }
      }
    });
  }

  /**
   * Format the number to be shown with decimal
   * @param {Number} dollarAmount for example, 1234567.89
   * @param {string} decimalPlaces for example, '.00' for 2 decimal places
   */
  formatInvestmentDollar = (dollarAmount, decimalPlaces) => {
    return numeral(dollarAmount).format('0,0' + decimalPlaces);
  }

  // Returns object having total of dollars and percents { Dollar, Percentage }
  getFundValuesTotal = () => {

    let dollar = 0;
    let percentage = 0;

    this.props.hypotheticalState.portfolios[this.props.index].funds.forEach(item => {
      if (item.Invalid !== true) {
        dollar = parseFloat(parseFloat(dollar) + parseFloat(item.amount)).toFixed(2);        
        percentage += parseFloat((item.amountInvestedPercent * 100));  
      }
    });

    return {
      Dollar: dollar,
      Percentage: parseFloat(percentage.toFixed(10))
    };
  }

  // Returns true if user has key press numbers
  isNumberKeyPressed = (e) => {
    let result = false;
    let keyCode = e.which;
    if (e.type == 'blur' || ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105))) {
      result = true;
    }
    return result;
  }

  // When user select either '$' or '%'
  onClickInvestmentType = (e) => {
    let InvestmentType = e.target.value;
    this.setState({
      InvestmentType: InvestmentType  // 1: dollar ($), 2: Percentage (%)
    })
  }

  // When user focus out from '$' textbox
  onBlurInvestmentDollar = (e) => {
    if (this.isNumberKeyPressed(e)) {
      let portfolioIndex = this.props.index;
      this.props.updateHypothicalStateFundValuesPercents(e, portfolioIndex, false);
    }
  }

  // When user focus out from '%' textbox
  onBlurInvestmentPercent = (e) => {
    if (this.isNumberKeyPressed(e)) {
      let portfolioIndex = this.props.index;
      this.props.updateHypothicalStateFundValuesPercents(e, portfolioIndex, true);
    }
  }

  render() {
    let isFirstTime = this.props.isFirstTime === true;
    isFirstTime = false;
    let total = this.getFundValuesTotal();
    let classValidFundValuesPercents = (total.Dollar != this.props.hypotheticalState.totalInvestment) ? 'fund-error' : '';

    return (
      this.props.hypotheticalState.portfolios[this.props.index].funds.length > 0 ?
        <div className={'PortfolioInvestmentTable'}>
          <div className={'portfolio-heading'}>
            <label className={'portfolio-name'}>{this.props.index === 0 ? 'Portfolio One' : 'Portfolio Two'}</label>
            <a className={'portfolio-edit-link'} onClick={this.props.hideBenchmarkAndHypotheticalsResults}>Edit</a>
            <span className={'porfolio-inception'}>{(this.props.userType === 'International') ? this.dateFormatterIntl(this.props.hypotheticalState.portfolios[this.props.index].oldestShareClassInceptionDate) : this.dateFormatter(this.props.hypotheticalState.portfolios[this.props.index].oldestShareClassInceptionDate)}</span>
          </div>
          <table className={'portfolio-table'}>
            <thead>
              {/* Fund / Inception Date */}
              <th width="40%">{'Fund / '}{(this.props.userType==='International') ? 'Perf. Start Date' : 'Inception Date'}</th>
              {/* Period */}
              <th width="20%">Period</th>
              {/* Invested - Dollar */}
              <th width="20%" className={'text-right'}>
                <span className={'invested-text'}>{this.props.currencySymbol + ' Invested'}</span>
                <input className={'portfolio-radio'} type="radio" checked={this.state.InvestmentType == 1} value="1" onClick={this.onClickInvestmentType}></input>
              </th>
              {/* Invested - Percent */}
              <th width="20%" className={'text-right'}>
                <span className={'invested-text'}>% Invested</span>
                <input className={'portfolio-radio'} type="radio" checked={this.state.InvestmentType == 2} value="2" onClick={this.onClickInvestmentType}></input>
              </th>
            </thead>
            <thead className={"thead-highlight"}>
              <th></th>
              <th>{(this.props.userType === 'International') ? this.dateFormatterIntl(this.props.hypotheticalState.startPeriod, "DD/MM/YYYY") : this.dateFormatter(this.props.hypotheticalState.startPeriod, "MM/DD/YYYY")} -<br></br>{(this.props.userType === 'International') ? this.dateFormatterIntl(this.props.hypotheticalState.startPeriod, "DD/MM/YYYY") : this.dateFormatter(this.props.hypotheticalState.endPeriod, "MM/DD/YYYY")}</th>
              <th className={'text-right ' + classValidFundValuesPercents}>{this.props.currencySymbol + ' ' + this.formatInvestmentDollar(total.Dollar, '.00')}</th>
              <th className={'text-right ' + classValidFundValuesPercents}>{isFirstTime ? '100.00' : this.formatInvestmentDollar(total.Percentage, '.00')}%</th>
            </thead>
            {this.props.hypotheticalState.portfolios[this.props.index].funds.map(item => {
              return <tr className={item.Invalid || (item.amountInvestedPercent == 0) ? 'invalid-fund': '' } data-is-invalid={item.Invalid !== true ? 'true': 'false'}>
                {/* Fund / Inception Date  */}
                <td>
                    <label className={'fund-symbol'}>{item.symbol}</label>
                    <span className={`fund-inception-date ${item.isExtended ? 'underline-text' : ''}`} title={item.isExtended ? ((this.props.userType === 'International') ? this.dateFormatterIntl(item.oldestShareClassInceptionDate) : this.dateFormatter(item.oldestShareClassInceptionDate)): ''} >{(this.props.userType === 'International') ? this.dateFormatterIntl(item.oldestShareClassInceptionDate) : this.dateFormatter(item.inceptionDate)}{item.isExtended && '*'}</span>
                    <br />
                    <span className={'fund-name'} title={item.name}>{item.name}</span>
                </td>
                {/* Period */}
                <td>
                  { item.Invalid === true ? '--' :((this.props.userType === 'International') ? this.dateFormatterIntl(this.props.hypotheticalState.startPeriod, "DD/MM/YYYY") : this.dateFormatter(this.props.hypotheticalState.startPeriod, "MM/DD/YYYY"))} <br></br>{item.Invalid === true ? '' :((this.props.userType === 'International') ? '-' + this.dateFormatterIntl(this.props.hypotheticalState.endPeriod, "DD/MM/YYYY") : '-' + this.dateFormatter(this.props.hypotheticalState.endPeriod, "MM/DD/YYYY"))}
                </td>
                {/* Invested - Dollar (Calculated from Percentage)  */}
                <td className={'text-right'}>
                   {
                     item.Invalid === true ? '--':
                     <div>
                        {this.props.currencySymbol} {this.state.InvestmentType == 1 ?
                          <input className={'portfolio-invested text-right'}
                            type="text"
                            onBlur={this.onBlurInvestmentDollar}
                            onKeyDown={utilityFunctions.allowOnlyNumbers}
                            data-symbol={item.symbol}
                            data-raw-value={(item.amount)}
                          /> :
                          <span data-raw-value={(item.amount)}>
                            {(this.formatInvestmentDollar( item.amount, '.00'))}
                          </span>}
                     </div>
                   }
                  
                </td>
                {/* Invested - Percent */}
                <td className={'text-right'}>
                  {
                    item.Invalid === true ? '--':
                    <div>
                     { this.state.InvestmentType == 2 ?
                    <input className={'portfolio-invested text-right'}
                      type="text"
                      onBlur={this.onBlurInvestmentPercent}
                      onKeyDown={utilityFunctions.allowOnlyNumbers}
                      data-symbol={item.symbol}
                      data-raw-value={(item.amountInvestedPercent * 100).toFixed(2)}
                    /> :
                    <span data-raw-value={(item.amountInvestedPercent * 100).toFixed(2)}>
                      {(this.formatInvestmentDollar(item.amountInvestedPercent * 100, '.00'))}
                     </span>} %  </div>
                  }
                </td>
              </tr>
            })
            }

          </table>
        </div> : <div>No funds selected for this portfolio</div>
    );
  }
}

PortfolioInvestmentTable.PropTypes = {
  hypotheticalState: PropTypes.object,
  index: PropTypes.number
};

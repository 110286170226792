import React from 'react';

import { Button, OverlayTrigger, ButtonToolbar, Tooltip } from 'react-bootstrap';

import CloseWhiteTooltipIcon from '../../common/Icons/CloseWhiteTooltipIcon';
import GripperWhiteTooltipIcon from '../../common/Icons/GripperWhiteTooltipIcon';
import LineWhiteTooltipIcon from '../../common/Icons/LineWhiteTooltipIcon';

import tooltipStyles from '../../../styles/components/InfotoolTip.scss';

class InfoTooltip extends React.Component {
	tooltip() {
		return (
			<Tooltip>
				<div>
					<p className={'header-title-text-tooltip'}>Help</p>
					<p className={'header-text-tooltip-FIF'}>FUND IN FOCUS </p>
					<p className={'info-tooltip-text-description'}>
						Any MFS fund can be added as the fund in focus. Funds outside the
						MFS fund family cannot be added as the fund in focus.
					</p>
					<p className={'info-tooltip-line-break'}>
						<LineWhiteTooltipIcon />
					</p>
					<p className={'header-text-tooltip'}>COMPARED TO </p>
					<p className={'info-tooltip-text-description'}>
						A maximum of 4 Mutual Funds or ETFs can be added to the
						comparison.
					</p>
					<p className={'info-tooltip-line-break'}>
						<LineWhiteTooltipIcon />
					</p>
					<p className={'header-text-tooltip'}>CALCULATION BENCHMARK</p>
					<p className={'info-tooltip-text-description'}>
						The benchmark that will be the basis of all calculations in the
						comparison.
					</p>
					<p className={'info-tooltip-line-break'}>
						<LineWhiteTooltipIcon />
					</p>
					<div className="container-tooltip-image-text">
						<p className={'header-text-tooltip'}> GRIPPER ICON </p>
						<p className={'gripper-img'}>
							<GripperWhiteTooltipIcon />
						</p>
					</div>
					<p className={'info-tooltip-text-description'}>
						Easily change fund order by clicking on the gripper and dragging the
						comparison tile into position.
					</p>
					<p className={'info-tooltip-line-break'}>
						<LineWhiteTooltipIcon />
					</p>
					<div className="container-tooltip-image-text">
						<p className={'header-text-tooltip'}> DELETE ICON</p>
						<p className={'close-img-tooltip'}>
							<CloseWhiteTooltipIcon />
						</p>
					</div>
					<p className={'info-tooltip-text-description'}>
						Easily remove funds from the comparison by clicking on the delete
						icon.
					</p>
				</div>

			</Tooltip>
		);
	}

	positionerInstance() {
		return (
			<ButtonToolbar>
				<OverlayTrigger
					ref="tooltipTrigger"
					placement="bottom"
					overlay={this.tooltip()}
					rootClose
					trigger="click"
				>
					<p id="infoIcon" className={'info-img'} />
				</OverlayTrigger>
			</ButtonToolbar>
		);
	}

	render() {
		return <div>{this.positionerInstance()}</div>;
	}
}

export default InfoTooltip;

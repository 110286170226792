function getUrlParameterValueByName (name, caseSensitive = false) {
	const url = window.location;

	/* eslint-disable */
	if (!caseSensitive) url.toLowerCase;
	const cleanedName = name.replace(/[\[\]]/g, '\\$&');
	if (!caseSensitive) cleanedName.toLowerCase;
	/* eslint-enable */
	const regex = new RegExp(`[?&]${cleanedName}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);

	if (!results) {
		return null;
	}

	return results[2] ? decodeURIComponent(results[2].replace(/\+/g, ' ')) : '';
}

export const isDebugInfoOn = () => {
	return getUrlParameterValueByName('..showdebuginfo..') === 'on';
};

export const showDashboard = () => {
	return getUrlParameterValueByName('showdashboard') === 'true';
};

export const getTokenFromQueryString = () => {
	return getUrlParameterValueByName('token', true);
};

import { createTypes } from 'reduxsauce';

export default createTypes(`
    REQUEST_FUNDCOMPARISON_PDF_API
    RECEIVE_FUNDCOMPARISON_PDF
    API_REQUEST_SAVETEMPLATEDETAILS_API
    API_REQUEST_SAVECOMPARISONPORTFOLIOTEMPLATEDETAILS_API
    RECEIVE_SUCCESS_SAVETEMPLATEDETAILS
    RECEIVE_SUCCESS_SAVETEMPLATE
    API_REQUEST_SAVETEMPLATE_API
    API_REQUEST_DELETETEMPLATE_API
    RECEIVE_SUCCESS_DELETETEMPLATE
    API_REQUEST_SUCCESS_GET_TEMPLATES_API
    API_REQUEST_GET_BENCHMARKS_API
    API_REQUEST_GET_PRIMARY_BENCHMARK_API
    RECEIVE_SUCCESS_GET_PRIMARY_BENCHMARK
    RECEIVE_SUCCESS_GET_BENCHMARKS
    RECEIVE_SUCCESS_GET_TEMPLATES
    RECEIVE_SUCCESS_GET_TEMPLATES_BY_ID_API
    RECEIVE_SUCCESS_GET_TEMPLATES_BY_ID
    RECEIVE_FUNDCOMPARISON_PDF_ON_SELECTMODAL
    REQUEST_SUCCESS_GET_SCREENERDATA_API
    RECEIVE_SUCCESS_SCREENERDATA
    REQUEST_SUCCESS_GET_SCREENER_MASTER_DATA_API
    RECEIVE_SUCCESS_GET_SCREENER_MASTER_DATA
    REQUEST_SYMBOLSEARCH_API
    API_REQUEST_DECODE_API
    RECIEVE_DECODE
    RECIEVE_SYMBOLSEARCH
    SET_ACCESS_TOKEN
    GENERIC_FAILURE
    SET_TEMPLATE_DATASET
    UPDATE_TEMPLATE_INTERNAL_DETAILS
    REQUEST_SUCCESS_GET_VALID_ROLLING_RETURNS_DATA_API
	RECEIVE_SUCCESS_GET_VALID_ROLLING_RETURNS_DATA
	RECEIVE_SUCCESS_GET_VALID_CM_DATA
    REQUEST_PORTFOLIOCOMPARISON_PDF_API
    RECEIVE_PORTFOLIOCOMPARISON_PDF
    RESET_SCREENER_FILTERS_OUTPUT
    RECEIVE_SUCCESS_SCREENERFILTERS_DATA
    RECEIVE_SUCCESS_GET_SINGLEPORTFOLIOTEMPLATES
    RECEIVE_SUCCESS_GET_PORTFOLIO_TEMPLATES_BY_ID
    RECEIVE_SUCCESS_GET_PORTFOLIO_COMPARISON_SCREENER_DATA
    REQUEST_SUCCESS_GET_PERFORMANCE_AS_OF_DATE_API
    RECIEVE_SUCCESS_GET_PERFORMANCE_AS_OF_DATE
    REQUEST_SUCCESS_GET_PORTFOLIOCOMPARISON_SCREENERDATA_API
    RECEIVE_SUCCESS_GET_PORTFOLIOCOMPARISON_SCREENER_DATA
    RECEIVE_SUCCESS_GET_PORTFOLIOTWOCOMPARISON_SCREENER_DATA
    REQUEST_SUCCESS_GET_PORTFOLIOTWOCOMPARISON_SCREENERDATA_API
    API_REQUEST_SAVETEMPLATEDETAILSP2_API
    RECEIVE_SUCCESS_SAVETEMPLATEDETAILSP2
    RECEIVE_SUCCESS_GET_PORTFOLIO_COMPARISON_TEMPLATES
    RECEIVE_SUCCESS_SAVE_COMPARISON_PORTFOLIO_TEMPLATE_DETAILS
    UPDATE_PORTFOLIOTEMPLATE_INTERNAL_DETAILS
    RECEIVE_SUCCESS__MFSSITEINACTIVEFLAG
    API_REQUEST_MFSSITEINACTIVE_API
    REQUEST_OLDEST_INCEPTION_DATES_API
    RECEIVE_SUCCESS_OLDEST_INCEPTION_DATES
    API_REQUEST_SUCCESS_GET_PORTFOLIOCONTAINER_API
    RECEIVE_SUCCESS_GET_PORTFOLIOCONTAINERIDS
    API_REQUEST_SUCCESS_GET_UPDATEINVESTMENTDETAILS_API
    RECEIVE_SUCCESS_GET_UPDATEINVESTMENTDETAILS
    API_REQUEST_SUCCESS_GET_UPDATEINVESTMENTDETAILSWITHCLONING_API
    RECEIVE_SUCCESS_GET_UPDATEINVESTMENTDETAILSWITHCLONING
    API_REQUEST_SUCCESS_GET_PORTFOLIOALLOCATIONS_API
    RECEIVE_SUCCESS_GET_PORTFOLIOALLOCATIONS
    API_REQUEST_SUCCESS_GET_WORKINGCOPYCONTAINERIDS_API
    RECEIVE_SUCCESS_GET_WORKINGCOPYCONTAINERIDS
    API_REQUEST_SAVEEVENTHYPOTHETICALDETAILS_API
    RECEIVE_SUCCESS_SAVEEVENT
    RECEIVE_SUCCESS_SAVEEVENT_WITHDRAWAL
    API_REQUEST_GETEVENTHYPOTHETICALSCHEDULES_API
    RECEIVE_SUCCESS_GETEVENTSCHEDULES
    API_REQUEST_REMOVEEVENTHYPOTHETICALSCHEDULES_API
    RECEIVE_SUCCESS_REMOVEEVENTSCHEDULES
    API_REQUEST_REMOVEEVENTHYPOTHETICALSCHEDULESP2_API
    RECEIVE_SUCCESS_REMOVEEVENTSCHEDULESP2
    API_REQUEST_GETEVENTHYPOTHETICALSCHEDULESP2_API
    RECEIVE_SUCCESS_GETEVENTSCHEDULESP2
    API_REQUEST_ADDEVENTHYPOTHETICALFEESCHEDULES_API
    RECEIVE_SUCCESS_ADDEVENTFEESCHEDULES
    API_REQUEST_ADDEVENTHYPOTHETICALFEESCHEDULESP2_API
    RECEIVE_SUCCESS_ADDEVENTFEESCHEDULESP2
    API_REQUEST_MODIFYEVENTHYPOTHETICALFEESCHEDULES_API
    RECEIVE_SUCCESS_MODIFYEVENTFEESCHEDULES
    API_REQUEST_MODIFYEVENTHYPOTHETICALFEESCHEDULESP2_API
    RECEIVE_SUCCESS_MODIFYEVENTFEESCHEDULESP2
    API_REQUEST_REMOVEEVENTHYPOTHETICALFEESCHEDULES_API
    RECEIVE_SUCCESS_REMOVEEVENTFEESCHEDULES
    API_REQUEST_REMOVEEVENTHYPOTHETICALFEESCHEDULESP2_API
    RECEIVE_SUCCESS_REMOVEEVENTFEESCHEDULESP2
    API_REQUEST_GETEVENTHYPOTHETICALFEESCHEDULES_API
    RECEIVE_SUCCESS_GETEVENTFEESCHEDULES
    API_REQUEST_GETEVENTHYPOTHETICALFEESCHEDULESP2_API
    RECEIVE_SUCCESS_GETEVENTFEESCHEDULESP2
    API_REQUEST_MODIFYEVENTHYPOTHETICALDETAILS_API
    RECEIVE_SUCCESS_MODIFYEVENT
    API_REQUEST_CONTRIBUTION_TO_RISK_API
    API_REQUEST_CONTRIBUTION_TO_RISKP2_API
    RECEIVE_SUCCESS_CONTRIBUTION_TO_RISK
    RECEIVE_SUCCESS_CONTRIBUTION_TO_RISKP2
    API_REQUEST_REMOVEALLEVENTHYPOTHETICALSCHEDULES_API
    API_REQUEST_REMOVEALLEVENTHYPOTHETICALSCHEDULESP2_API
    RECEIVE_SUCCESS_REMOVEALLEVENTSCHEDULES
    RECEIVE_SUCCESS_REMOVEALLEVENTSCHEDULESP2
    API_REQUEST_GETEVENTHYPOTHETICALFRONTFEESCHEDULES_API
    RECEIVE_SUCCESS_GETEVENTFRONTFEESCHEDULES
    API_REQUEST_SAVEEVENTADDINVESTMENT_API
    API_REQUEST_MODIFYEVENTHYPOTHETICALFRONTINVESTMENTSCHEDULES_API
    API_REQUEST_MODIFYEVENTHYPOTHETICALFRONTINVESTMENTSCHEDULESP2_API
    API_REQUEST_REMOVEEVENTHYPOTHETICALFRONTINVESTMENTSCHEDULES_API
    API_REQUEST_REMOVEEVENTHYPOTHETICALFRONTINVESTMENTSCHEDULESP2_API
    RECEIVE_SUCCESS_MODIFYEVENTFUNDINVESTMENTSCHEDULES
    RECEIVE_SUCCESS_MODIFYEVENTFUNDINVESTMENTSCHEDULESP2
    RECEIVE_SUCCESS_REMOVEEVENTFUNDINVESTMENTSCHEDULES
    RECEIVE_SUCCESS_REMOVEEVENTFUNDINVESTMENTSCHEDULESP2
    API_REQUEST_GETINVESTMENTHYPOTHETICALSCHEDULES_API
    RECEIVE_SUCCESS_GETINVESTMENTSCHEDULES
    API_REQUEST_GETINVESTMENTHYPOTHETICALSCHEDULESP2_API
    RECEIVE_SUCCESS_GETINVESTMENTSCHEDULESP2
    API_REQUEST_BENCHMARK_LATEST_DATE_API
    RECEIVE_SUCCESS_BENCHMARK_LATEST_DATE
    API_REQUEST_UPDATEEVENTHYPOTHETICALSCHEDULES_API
    RECEIVE_SUCCESS_UPDATEEVENTSCHEDULES
    API_REQUEST_MFS_USER_DATA_API
    RECEIVE_SUCCESS_MFS_USER_DATA
    API_REQUEST_SAVEPVPCOMPARISONTEMPLATEDETAILS_API
    RECEIVE_SUCCESS_SAVE_PVP_COMPARISON_TEMPLATE_DETAILS
    API_REQUEST_GETWITHDRAWALHYPOTHETICALSCHEDULES_API
    RECEIVE_SUCCESS_GETWITHDRAWALSCHEDULES
    API_REQUEST_GETWITHDRAWALHYPOTHETICALSCHEDULESP2_API
    RECEIVE_SUCCESS_GETWITHDRAWALSCHEDULESP2
    API_REQUEST_GETFUNDEVENTS_API
    API_REQUEST_GETFUNDEVENTSP2_API
    RECEIVE_SUCCESS_GETFUNDEVENTS
    RECEIVE_SUCCESS_GETFUNDEVENTSP2
    SET_USER_TYPE
    API_REQUEST_CONTRIBUTION_TO_RISK_FINAL_ALLOCATION_API
    RECEIVE_SUCCESS_CONTRIBUTION_TO_RISK_FINAL_ALLOCATION
    API_REQUEST_GET_FUND_AMOUNT_BY_DATE_API
    RECEIVE_SUCCESS_GET_FUND_AMOUNT_BY_DATE
    REQUEST_SUCCESS_GET_SCREENERDATAFUNDNAME_API
    RECEIVE_SUCCESS_SCREENERDATAFUNDNAME
    API_REQUEST_INTERNATIONAL_XID_BY_FUNDSHARECLASSID_API
    RECEIVE_SUCCESS_INTERNATIONAL_XID_BY_FUNDSHARECLASSID
    API_REQUEST_XREF_USER_DATA_API
    RECEIVE_SUCCESS_XREF_USER_DATA
    API_REQUEST_XREF_USER_DATA_ETF_API
	RECEIVE_SUCCESS_XREF_USER_DATA_ETF
    RECEIVE_SUCCESS_GET_MFS_PRIMARY_BENCHMARK
    RESET_PORTFOLIOCOMPARISON_SCREENERDATA
    RECEIVE_SUCCESS_XREF_FUND_DATA    
    REQUEST_SUCCESS_GET_SCREENERFUND_PERFORMANCEASOFDATE_API
    RECEIVE_SUCCESS_SCREENERFUND_PERFORMANCEASOFDATE
    RECEIVE_SUCCESS_SCREENERDATA_MSTARCATEGORY
    REQUEST_GET_TOPFUNDSBYNETASSEST_API
`);
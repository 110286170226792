export const TemplateLengthCheck =
  'Template name cannot be greater than 50 characters.';

export const FundReturnsInsufficientDataMsg =
  'Modules unavailable due to insufficient data.';

  export const ContributioToRiskModuleDisableMsg ='This module is not available based on the inclusion of events in your portfolio comparison.';
  //Changes for MFS-2788 - 'This module is not available based on the inclusion of events in your portfolio comparison.';

export const requiredRollingPeriod = 'Please select at least one rolling period.';

export const requiredErrorHeader = 'Rolling Period Required';

export const errorHeaderLogout = 'Sign In Error';

export const errorHeaderLogoutMessage = 'We were unable to log you in. Please try logging in again. If the issue continues please contact the system admins at DLPoweredbyIRISSpt@MFS.com.';

export const disclaimerText = 'Strategies with less than 3 calendar months of history will not be included in generated reports.';

export const disclaimerTextScreenerFlyout = 'Strategies with less than 3 calendar months of history will not be included \n in generated reports.';

export const PortfolioTemplateLengthCheck =
  'Portfolio name cannot be greater than 50 characters.';

export const PortfolioDescriptionLengthCheck =
  'Portfolio Description cannot be greater than 120 characters.';

  export const requiredTimePeriod = 'Please select at least one time period.';

  export const requiredTimePeriodErrorHeader = 'Time Period Required';

  export const threeYearsTrackRecordErrorMessage = "Below funds do not have sufficient data to allow for a comparison. Please select funds with at-least 3 years of data history.";
import React from 'react';
import Modal from 'react-modal';
import numeral from 'numeral';
import { PropTypes } from 'prop-types';

class LoadFeeScheduleModal extends React.PureComponent {
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.frontFeeSchedules === nextProps.frontFeeSchedules) {
            document.querySelector('.feeSchedulesContent').style.display = 'inline';
        }
    }

    componentDidUpdate() { 
        document.querySelector('.feeSchedulesContent').style.display = 'inline';
    }
    
	render() {
  
        let feeSchedules = this.props.frontFeeSchedules;
        
        return (
			<Modal
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				isOpen={this.props.feeSchedulesIsOpen}
				style={this.props.customStyles}
			>
                <div className={'saveModal loadFeeSchedulesModal'}>
					<div className={'main-container'}>
						<div className={'headerContent'}>
                            <div className={'headerContainer'}>
                                <div className={'header'}>
                                    <div className={'header-text'}>{this.props.feeTypeText} for {this.props.fundInfo.symbol} {this.props.fundInfo.name}</div>
                                </div>
                            </div>
                            <div className={'feeSchedulesContent'} style={{'display':'none'}}>
                                {feeSchedules.length === 0 ? 
                                    <div className={'no-result-found'}>{this.props.noScheduleFoundText}</div>:
                                    <table>
                                        <thead>
                                            <tr>
                                                <td width="25%">Low $</td>
                                                <td width="25%">High $</td>
                                                <td width="50%">Fee %</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {feeSchedules && feeSchedules.map(item => {
                                                        return <tr>
                                                                            <td>${numeral(parseFloat(item.lowbreakpoint)).format('0,0')}</td>
                                                                            <td>{(item.highbreakpoint ? '$' + numeral(parseFloat(item.highbreakpoint)).format('0,0'): '--')}</td>
                                                                            <td>{numeral(parseFloat(item.Value)).format('0,0.00')}%</td>
                                                                        </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
						<div className={'footerContainer'}>
                            <div className={'footer'}>
                                <button
                                    onClick={this.props.closeFeeScheduleModal}
                                    className={'cancel-button'}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
					</div>
				</div>
			</Modal>
		);
	}
}

LoadFeeScheduleModal.propTypes = {
	feeSchedulesIsOpen: PropTypes.bool,
	customStyles: PropTypes.object,
	frontFeeSchedules: PropTypes.array,
	fundInfo: PropTypes.object,
	closeFeeScheduleModal: PropTypes.func,
	feeTypeText: PropTypes.string,
	noScheduleFoundText: PropTypes.string,
};

export default LoadFeeScheduleModal;

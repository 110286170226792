import React from 'react';
import { PropTypes } from 'prop-types';
import Select, { components } from 'react-select';

import moment from "moment";
import lodash from 'lodash';
import { cloneDeep } from "lodash";

import * as utilityFunctions from "../../../utils/commonMethods";
import {BenchmarkResults , customTheme , customStyles, eventTypes, frequencyTypeDict, frequencyTypes, investmentFrequencyTypeDict, fundInvestFrequencyTypesIncrease } from "../../../utils/constants";
import CustomCalendar from '../subComponents/CustomCalendar'
import EventFlyout from './EventFlyout';
import InvestmentFlyout from './InvestmentFlyout';
import WithdrawalFlyout from './WithdrawalFlyout';
import PortWithdrawalFlyout from './PortWithdrawalFlyout';
import PortfolioInvestFlyout from './PortfolioInvestFlyout';

import * as CustomModalStyles from '../../../utils/customModalStyles';

import closeSVG from "../../../images/Icon/Close.svg";
import modifySvg from "../../../images/Icon/Edit.svg";

export default class Hypotheticals extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedEventDateP1: new Date(utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY")),
            selectedEventDateP2: new Date(utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY")),
            selectedEventTypeP1: null,
            selectedEventTypeP2: null,
            openEventFlyoutP1: false,
            openEventFlyoutP2: false,
            rebalanceData: {
				portfolios: [],
				totalInvestment: '',
				periodId: { Key: '1', Value: '' },
				endPeriod: '',
            },
            fundInvestmentEditData: {},
            fundsData:[],
            openInvestmentEventFlyoutP1: false,
            openInvestmentEventFlyoutP2: false,
            openWithdrawalEventFlyoutP1: false,
            openWithdrawalEventFlyoutP2: false,
            openPortWithdrawalEventFlyoutP1: false,
            openPortWithdrawalEventFlyoutP2: false,
            openPortfolioInvestEventFlyoutP1: false,
            openPortfolioInvestEventFlyoutP2: false,
        }
        
        this.selectedPortfolio= '';   
        
        this.workingCopyContainerIdOne = '';
        this.workingCopyContainerIdTwo = '';
        this.editscheduleIdP1 = '';
        this.editscheduleIdP2 = '';
        this.modifyEventDateP1 = 0;
        this.modifyEventDateP2 = 0;
        this.editFrequencyTypeP1 = '';
        this.editFrequencyTypeP2 = '';
        
        this.editDateEndP1 = 0;
        this.editDateEndP2 = 0;
        this.modifyP1 = false;
        this.attrP1EditScheduleIds = 0;
        this.arrP1EditscheduleIds = [];
        this.modifyP1WithdrawalDate = 0;
        this.modifyP2 = false;
        this.attrP2EditScheduleIds = 0;
        this.arrP2EditscheduleIds = [];
        this.modifyP2WithdrawalDate = 0;
    }

    componentDidMount() {
        // Set working containerIds in state
        let containerIds = this.props.workingCopyConatainerIds.split(',');
        this.state.workingCopyContainerIdOne = containerIds[0];
        this.state.workingCopyContainerIdTwo = containerIds[1];
        this.state.rebalanceData = this.props.hypotheticalState;
        
    }
    UNSAFE_componentWillReceiveProps(nextProps) { 
        if(this.props.hypoStartPeriod != nextProps.hypoStartPeriod) {
            this.state.selectedEventDateP1 = new Date(utilityFunctions.formatOADate(nextProps.hypoStartPeriod, "MMM DD, YYYY"));
            this.state.selectedEventDateP2 = new Date(utilityFunctions.formatOADate(nextProps.hypoStartPeriod, "MMM DD, YYYY"));
        }

        this.handleP1PropsUpdate(nextProps);
        
        if(this.props.p2CtrRandom != nextProps.p2CtrRandom && (this.props.fundP2CtrPositiveAllocations != nextProps.fundP2CtrPositiveAllocations)) {
            if(this.modifyP2) {
                this.modifyWithdrawalFlyout(this.attrP2EditScheduleIds, this.arrP2EditscheduleIds, nextProps.fundP2CtrPositiveAllocations, 2);
            } else {
                document.getElementById('selectTimePeriodP2').childNodes[1].value === 'Fund Withdrawal' &&
                    this.timePeriodSelectionFundWithdrawal(2,nextProps.fundP2CtrPositiveAllocations);
                document.getElementById('selectTimePeriodP2').childNodes[1].value !== 'Fund Withdrawal' &&
                    this.timePeriodSelectionPortWithdrawal(2,nextProps.fundP2CtrPositiveAllocations);
            
            }
        }
    }

    handleP1PropsUpdate = (nextProps) => {
        if(this.props.p1CtrRandom != nextProps.p1CtrRandom && (this.props.fundP1CtrPositiveAllocations != nextProps.fundP1CtrPositiveAllocations)) {
            if(this.modifyP1) {                
                this.modifyWithdrawalFlyout(this.attrP1EditScheduleIds, this.arrP1EditscheduleIds,nextProps.fundP1CtrPositiveAllocations, 1);                
            } else {
                if (document.getElementById('selectTimePeriodP1').childNodes[1].value === 'Fund Withdrawal') {
                    this.timePeriodSelectionFundWithdrawal(1,nextProps.fundP1CtrPositiveAllocations)
                } else {
                    this.timePeriodSelectionPortWithdrawal(1,nextProps.fundP1CtrPositiveAllocations);
                }
            }
        }
    }

    closeEventFlyout = (portIndex) => {
        let obj = {};
        if (portIndex == 1 ) {
            this.editscheduleIdP1 = '';
            this.modifyEventDateP1=0;
            this.editDateEndP1 = 0;
            obj = {
                openEventFlyoutP1: false,
                openInvestmentEventFlyoutP1: false,
                fundInvestmentEditData: {},

                openWithdrawalEventFlyoutP1: false,
                openPortWithdrawalEventFlyoutP1: false,
                openPortfolioInvestEventFlyoutP1: false
            };
        } else if (portIndex == 2 ) {
            this.editscheduleIdP2 = '';
            this.modifyEventDateP2=0;
            this.editDateEndP2 = 0;
            obj = {
                openEventFlyoutP2: false,
                openInvestmentEventFlyoutP2: false,
                fundInvestmentEditData: {},

                openWithdrawalEventFlyoutP2: false,
                openPortWithdrawalEventFlyoutP2: false,
                openPortfolioInvestEventFlyoutP2: false
            };
        }
        this.setState(obj);
    }

    autoDistribute = (portIndex) => {
        let date = null;
        if (portIndex == 1) {
            date = this.editscheduleIdP1 != '' && this.modifyEventDateP1 > 0 ? (parseFloat(this.modifyEventDateP1) + 1) : utilityFunctions.convertToOADate(this.state.selectedEventDateP1);
        } else if (portIndex == 2) {
            date = this.editscheduleIdP2 != '' && this.modifyEventDateP2 > 0 ? (parseFloat(this.modifyEventDateP2) + 1) : utilityFunctions.convertToOADate(this.state.selectedEventDateP2);
        }
        this.redistributeValidFunds(this.state.rebalanceData, date, 'P' + portIndex);
    }

    redistributeValidFunds(hypotheticalState, date, type) {
        let p1 = hypotheticalState.portfolios && hypotheticalState.portfolios[0];
        let p2 = hypotheticalState.portfolios && hypotheticalState.portfolios[1];
        let sortData = function (a, b) {
            const arrProp = ['amountInvestedPercent', 'name'];

            for (let index = 0; index < arrProp.length; index++) {
                if (a[arrProp[index]] > b[arrProp[index]]) {
                    return -1;
                }
                if (a[arrProp[index]] < b[arrProp[index]]) {
                    return 1;
                }
            }

            return 0;
        };
        //Redistribute for portfolio 1 funds
        if (type == 'P1') {
            let p1Count = 0, p1Investment = 0
            // Instead of filtering on Invalid flag, here we need to check oldestShareClassInceptionDate is behind our selected Event Rebalance Date for valid funds
            p1Count = p1.funds.filter((item) => { return utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) <= date }).length;
            if (p1Count > 0) {
                //To stop fluctuating while redistributing
                //Checking if(1/valid fund count) > (difference of 1 from {(ValidFundCount-1)*(1/valid fund count)}) then sort in desc order of amountInvestedPercent then desc order of fund name
                if (parseFloat((1 / p1Count).toFixed(4)) > parseFloat((1 - (parseFloat((1 / p1Count).toFixed(4))) * (p1Count - 1)).toFixed(4))) {
                    p1.funds = p1.funds.sort(sortData);
                }

                p1Investment = (1 / p1Count).toFixed(4);
                p1.funds.map(item => {
                    if (utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) > date) {
                        item.amountInvestedPercent = 0;
                    } else {
                        item.amountInvestedPercent = p1Investment;
                    }
                    return item;
                });

                let p1InvestmentPercent = p1Investment * p1Count;
                p1.funds.filter((item) => { return utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) <= date })[p1Count - 1].amountInvestedPercent = (parseFloat(p1Investment) + parseFloat(1 - p1InvestmentPercent)).toFixed(4);

            }
        } else if (type == 'P2') { //Redistribute for portfolio 2 funds
            let p2Count = 0, p2Investment = 0;
            // Instead of filtering on Invalid flag, here we need to check oldestShareClassInceptionDate is behind our selected Event Rebalance Date for valid funds
            p2Count = p2.funds.filter((item) => { return utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) <= date }).length;
            if (p2Count > 0) {
                //To stop fluctuating while redistributing
                //Checking if(1/valid fund count) > (difference of 1 from {(ValidFundCount-1)*(1/valid fund count)}) then sort in desc order of amountInvestedPercent then desc order of fund name
                if (parseFloat((1 / p2Count).toFixed(4)) > parseFloat((1 - (parseFloat((1 / p2Count).toFixed(4))) * (p2Count - 1)).toFixed(4))) {
                    p2.funds = p2.funds.sort(sortData);
                }

                p2Investment = (1 / p2Count).toFixed(4);
                p2.funds.map(item => {
                    if (utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) > date) {
                        item.amountInvestedPercent = 0;
                    } else {
                        item.amountInvestedPercent = p2Investment;
                    }
                    return item;
                });

                let p2InvestmentPercent = p2Investment * p2Count;
                p2.funds.filter((item) => { return utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) <= date })[p2Count - 1].amountInvestedPercent = (parseFloat(p2Investment) + parseFloat(1 - p2InvestmentPercent)).toFixed(4);

            }
        }
        // Set P1 and P2 funds, however, we are acting only on selected Portfolio and not doing anything in another portfolio
        for (let i = 0; i < hypotheticalState.portfolios.length; i++) {
            if (hypotheticalState.portfolios[i]) {
                hypotheticalState.portfolios[i] = (i == 0) ? p1 : p2;
            }
        }

        // Update state        
        this.setState({
            rebalanceData: hypotheticalState,
            distributeEventAlloc: true
        })
    }

    timePeriodSelectionRebalance(portfolioType) {
        if(portfolioType === 1){
        if (this.props.schedules.filter(x => x.DateBegin == utilityFunctions.convertToOADate(this.state.selectedEventDateP1) - 1).length > 0) {
            this.props.validateSameDayEvent();
        } else {
            this.rebalanceData = cloneDeep(this.props.hypotheticalState);
            let selectedSchedule = this.props.schedules.filter(x => x.DateBegin < utilityFunctions.convertToOADate(this.state.selectedEventDateP1));
            let p1AllocationDict = {};
            this.editFrequencyTypeP1 = 'Monthly';
            if (selectedSchedule.length > 0) {
                selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                    return p1AllocationDict[x.Id] = x.Allocation;
                });
                this.rebalanceData.portfolios[0].funds.map(item => {
                    return (item.amountInvestedPercent = p1AllocationDict[item.investmentId]);
                });
            }
            this.setState({
                openEventFlyoutP1: true,
                rebalanceData: this.rebalanceData
            });
        }
    } else {
        if (this.props.schedulesP2.filter(x => x.DateBegin == utilityFunctions.convertToOADate(this.state.selectedEventDateP2) - 1).length > 0) {
            this.props.validateSameDayEvent();            
        } else {
            this.rebalanceData = cloneDeep(this.props.hypotheticalState);

            let selectedSchedule = this.props.schedulesP2.filter(x => x.DateBegin < utilityFunctions.convertToOADate(this.state.selectedEventDateP2));
            let p2AllocationDict = {};
            this.editFrequencyTypeP2 = 'Monthly';
            if (selectedSchedule.length > 0) {
                selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                    return p2AllocationDict[x.Id] = x.Allocation;
                })
                this.rebalanceData.portfolios[1].funds.map(item => {
                    return (item.amountInvestedPercent = p2AllocationDict[item.investmentId]);
                });
            }

            this.setState({
                openEventFlyoutP2: true,
                rebalanceData: this.rebalanceData
            });
        }
    }
    }

    timePeriodSelectionFundInvestment(portfolioType) {
        if (portfolioType === 1) {
            if (this.props.investmentSchedules.filter(x => x.DateBegin == utilityFunctions.convertToOADate(this.state.selectedEventDateP1) - 1).length > 0) {
                this.props.validateSameDayEvent();
            } else {
                this.rebalanceData = cloneDeep(this.props.hypotheticalState);
                let fundsData = [];
                let selectedEventDate = this.state.selectedEventDateP1 != null ? utilityFunctions.convertToOADate(this.state.selectedEventDateP1)
                    : this.props.hypoStartPeriod;
                this.rebalanceData.portfolios[0].funds.map(item => {
                    let oldestShareClassInceptionDate = utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate);
                    oldestShareClassInceptionDate <= selectedEventDate &&
                        fundsData.push({ label: item.name, value: item.wsodissue });
                });
                let selectedSchedule = this.props.schedules.filter(x => x.DateBegin < utilityFunctions.convertToOADate(this.state.selectedEventDateP1));
                let p1AllocationDict = {};
                this.editFrequencyTypeP1 = 'Monthly';
                if (selectedSchedule.length > 0) {
                    selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                        return p1AllocationDict[x.Id] = x.Allocation;
                    });
                    this.rebalanceData.portfolios[0].funds.map(item => {
                        return (item.amountInvestedPercent = p1AllocationDict[item.investmentId]);
                    });
                }
                this.setState({
                    openInvestmentEventFlyoutP1: true,
                    rebalanceData: this.rebalanceData,
                    fundsData: fundsData
                });
            }
        } else {
            if (this.props.investmentSchedulesP2.filter(x => x.DateBegin == utilityFunctions.convertToOADate(this.state.selectedEventDateP2) - 1).length > 0) {
                this.props.validateSameDayEvent();
            } else {
                this.rebalanceData = cloneDeep(this.props.hypotheticalState);
                let fundsData = [];
                let selectedEventDate = this.state.selectedEventDateP2 != null ? utilityFunctions.convertToOADate(this.state.selectedEventDateP2)
                    : this.props.hypoStartPeriod;

                this.rebalanceData.portfolios[1].funds.map(item => {
                    let oldestShareClassInceptionDate = utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate);
                    oldestShareClassInceptionDate <= selectedEventDate &&
                        fundsData.push({ label: item.name, value: item.wsodissue });
                });
                let selectedSchedule = this.props.schedulesP2.filter(x => x.DateBegin < utilityFunctions.convertToOADate(this.state.selectedEventDateP2));
                let p2AllocationDict = {};
                this.editFrequencyTypeP2 = 'Monthly';
                if (selectedSchedule.length > 0) {
                    selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                        return p2AllocationDict[x.Id] = x.Allocation;
                    })
                    this.rebalanceData.portfolios[1].funds.map(item => {
                        return (item.amountInvestedPercent = p2AllocationDict[item.investmentId]);
                    });
                }

                this.setState({
                    openInvestmentEventFlyoutP2: true,
                    rebalanceData: this.rebalanceData,
                    fundsData: fundsData
                });
            }
        }
    }
    timePeriodSelectionPortWithdrawal = (portfolioType, fundCtrPositiveAllocations) => {
        if (portfolioType === 1) {
                this.rebalanceData = cloneDeep(this.props.hypotheticalState);
                let fundsData = [];
                let selectedEventDate = this.state.selectedEventDateP1 != null ? utilityFunctions.convertToOADate(this.state.selectedEventDateP1)
                    : this.props.hypoStartPeriod;
                this.rebalanceData.portfolios[0].funds.map(item => {
                    let oldestShareClassInceptionDate = utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate);
                    oldestShareClassInceptionDate <= selectedEventDate &&
                        fundsData.push({
                            label: item.name,
                            value: item.wsodissue
                        });
                    
                });
                let selectedSchedule = this.props.schedules.filter(x => x.DateBegin < (utilityFunctions.convertToOADate(this.state.selectedEventDateP1)));
                let p1AllocationDict = {};
                this.editFrequencyTypeP1 = 'Monthly';
                if (selectedSchedule.length > 0) {
                    selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                        return p1AllocationDict[x.Id] = x.Allocation;
                    });
                    this.rebalanceData.portfolios[0].funds.map(item => {
                        return (item.amountInvestedPercent = p1AllocationDict[item.investmentId]);
                    });
                }
                                
                this.setState({
                    openPortWithdrawalEventFlyoutP1: true,
                    rebalanceData: this.rebalanceData,
                    fundsData: fundsData
                });
        } else {
                this.rebalanceData = cloneDeep(this.props.hypotheticalState);
                let fundsData = [];
                let selectedEventDate = this.state.selectedEventDateP2 != null ? utilityFunctions.convertToOADate(this.state.selectedEventDateP2)
                    : this.props.hypoStartPeriod;

                this.rebalanceData.portfolios[1].funds.map(item => {
                    let oldestShareClassInceptionDate = utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate);
                    oldestShareClassInceptionDate <= selectedEventDate &&
                        fundsData.push({
                            label: item.name,
                            value: item.wsodissue
                        });
                    
                });
                let selectedSchedule = this.props.schedulesP2.filter(x => x.DateBegin < utilityFunctions.convertToOADate(this.state.selectedEventDateP2));
                let p2AllocationDict = {};
                this.editFrequencyTypeP2 = 'Monthly';
                if (selectedSchedule.length > 0) {
                    selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                        return p2AllocationDict[x.Id] = x.Allocation;
                    });
                    this.rebalanceData.portfolios[1].funds.map(item => {
                        return (item.amountInvestedPercent = p2AllocationDict[item.investmentId]);
                    });
                }
                                
                this.setState({
                    openPortWithdrawalEventFlyoutP2: true,
                    rebalanceData: this.rebalanceData,
                    fundsData: fundsData
                });
        }
    }
    timePeriodSelectionFundWithdrawal = (portfolioType, fundCtrPositiveAllocations) => {
        if (portfolioType === 1) {
                this.rebalanceData = cloneDeep(this.props.hypotheticalState);
                let fundsData = [];
                let selectedEventDate = this.state.selectedEventDateP1 != null ? utilityFunctions.convertToOADate(this.state.selectedEventDateP1)
                    : this.props.hypoStartPeriod;
                this.rebalanceData.portfolios[0].funds.map(item => {
                    let oldestShareClassInceptionDate = utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate);
                    if (oldestShareClassInceptionDate <= selectedEventDate) {
                        if (fundCtrPositiveAllocations.indexOf(item.wsodissue) > -1) {
                            fundsData.push({
                                label: item.name,
                                value: item.wsodissue
                            })
                        }
                    }
                });
                let selectedSchedule = this.props.schedules.filter(x => x.DateBegin < (utilityFunctions.convertToOADate(this.state.selectedEventDateP1)));
                let p1AllocationDict = {};
                this.editFrequencyTypeP1 = 'Monthly';
                if (selectedSchedule.length > 0) {
                    selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                        return p1AllocationDict[x.Id] = x.Allocation;
                    });
                    this.rebalanceData.portfolios[0].funds.map(item => {
                        return (item.amountInvestedPercent = p1AllocationDict[item.investmentId]);
                    });
                }
                this.fundsData = fundsData;
                                
                this.setState({
                    openWithdrawalEventFlyoutP1: true,
                    rebalanceData: this.rebalanceData,
                    fundsData: fundsData
                });
        } else {
                this.rebalanceData = cloneDeep(this.props.hypotheticalState);
                let fundsData = [];
                let selectedEventDate = this.state.selectedEventDateP2 != null ? utilityFunctions.convertToOADate(this.state.selectedEventDateP2)
                    : this.props.hypoStartPeriod;

                this.rebalanceData.portfolios[1].funds.map(item => {
                    let oldestShareClassInceptionDate = utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate);
                    if (oldestShareClassInceptionDate <= selectedEventDate) {
                        if (fundCtrPositiveAllocations.indexOf(item.wsodissue) > -1) {
                            fundsData.push({
                                label: item.name,
                                value: item.wsodissue
                            })
                        }
                    }
                });
                let selectedSchedule = this.props.schedulesP2.filter(x => x.DateBegin < utilityFunctions.convertToOADate(this.state.selectedEventDateP2));
                let p2AllocationDict = {};
                this.editFrequencyTypeP2 = 'Monthly';
                if (selectedSchedule.length > 0) {
                    selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                        return p2AllocationDict[x.Id] = x.Allocation;
                    });
                    this.rebalanceData.portfolios[1].funds.map(item => {
                        return (item.amountInvestedPercent = p2AllocationDict[item.investmentId]);
                    });
                }
                                
                this.setState({
                    openWithdrawalEventFlyoutP2: true,
                    rebalanceData: this.rebalanceData,
                    fundsData: fundsData
                });
        }
    }

    timePeriodSelectionPortfolioInvest(portfolioType) {
        if (portfolioType === 1) {
            this.rebalanceData = cloneDeep(this.props.hypotheticalState);
            let fundsData = [];
            let selectedSchedule = this.props.schedules.filter(x => x.DateBegin < (utilityFunctions.convertToOADate(this.state.selectedEventDateP1)));
            let p1AllocationDict = {};
            this.editFrequencyTypeP1 = 'Monthly';
            if (selectedSchedule.length > 0) {
                selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                    return p1AllocationDict[x.Id] = x.Allocation;
                });
                this.rebalanceData.portfolios[0].funds.map(item => {
                    return (item.amountInvestedPercent = p1AllocationDict[item.investmentId]);
                });
            }
            //For dropdown a fund to be available a fund must be in the selected portfolio AND have a current allocation greater than 0
            this.rebalanceData.portfolios[0].funds.filter(x => parseFloat(x.amountInvestedPercent)>0).map(item => {
                return fundsData.push({
                        label: item.name,
                        value: item.wsodissue
                    })
            });
            this.setState({
                openPortfolioInvestEventFlyoutP1: true,
                rebalanceData: this.rebalanceData,
                fundsData: fundsData
            });
        } else {
            this.rebalanceData = cloneDeep(this.props.hypotheticalState);
            let fundsData = [];
            let selectedSchedule = this.props.schedulesP2.filter(x => x.DateBegin < utilityFunctions.convertToOADate(this.state.selectedEventDateP2));
            let p2AllocationDict = {};
            this.editFrequencyTypeP2 = 'Monthly';
            if (selectedSchedule.length > 0) {
                selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                    return p2AllocationDict[x.Id] = x.Allocation;
                });
                this.rebalanceData.portfolios[1].funds.map(item => {
                    return (item.amountInvestedPercent = p2AllocationDict[item.investmentId]);
                });
            }
            //For dropdown a fund to be available a fund must be in the selected portfolio AND have a current allocation greater than 0
            this.rebalanceData.portfolios[1].funds.filter(x => parseFloat(x.amountInvestedPercent)>0).map(item => {
                return fundsData.push({
                        label: item.name,
                        value: item.wsodissue
                    })
            });
            this.setState({
                openPortfolioInvestEventFlyoutP2: true,
                rebalanceData: this.rebalanceData,
                fundsData: fundsData
            });
        }
    }

    openEventFlyout = (portIndex) => {
        let timePeriodValue = document.getElementById('selectTimePeriodP' + portIndex).childNodes[1].value;
        if (timePeriodValue === "Rebalance") {
            this.timePeriodSelectionRebalance(portIndex);
        } else if (timePeriodValue === "Fund Investment") {
            this.timePeriodSelectionFundInvestment(portIndex);
        } else if ((timePeriodValue === "Fund Withdrawal") || (timePeriodValue === "Portfolio Withdrawal")) {
            let date = (portIndex == 1) ? this.state.selectedEventDateP1: this.state.selectedEventDateP2;
            let selectedEventDate = date != null ? utilityFunctions.convertToOADate(date) : this.props.hypoStartPeriod;
            this.props.contributionToRiskFinalAllocation(portIndex, selectedEventDate); 
            this.props.getFundsAmountByDate(portIndex, selectedEventDate);        
        } else if (timePeriodValue === "Portfolio Investment") {
            this.timePeriodSelectionPortfolioInvest(portIndex);
        }
    }   

    modifyEventFlyout = (e, portIndex) => {
        
        let editscheduleId = e.currentTarget.getAttribute('data-scheduleId');
        let schedules = portIndex == 1 ?  this.props.schedules: this.props.schedulesP2;
        let selectedSchedule = schedules.filter(x => x.Id == editscheduleId);
        this.rebalanceData = cloneDeep(this.props.hypotheticalState);
        
        if (portIndex == 1) {
            this.editscheduleIdP1 = editscheduleId;
        } else if (portIndex == 2) {
            this.editscheduleIdP2 = editscheduleId;
        }

        if (selectedSchedule.length > 0) {
            if (portIndex == 1) {
                this.modifyEventDateP1 = selectedSchedule[0].DateBegin;
                this.editDateEndP1 = selectedSchedule[0].DateEnd;
                this.editFrequencyTypeP1 = frequencyTypes.find(item => { return item.value === selectedSchedule[0].Frequency });    
            } else if (portIndex == 2) {
                this.modifyEventDateP2 = selectedSchedule[0].DateBegin;
                this.editDateEndP2 = selectedSchedule[0].DateEnd;
                this.editFrequencyTypeP2 = frequencyTypes.find(item => { return item.value === selectedSchedule[0].Frequency });
            }
        }

        let p1AllocationDict = {};
        selectedSchedule[0].Investment.map(x => {
            return p1AllocationDict[x.Id] = x.Allocation;
        })
        this.rebalanceData.portfolios[portIndex-1].funds.map(item => {
            return (item.amountInvestedPercent = p1AllocationDict[item.investmentId]);
        });
            
        let obj = {};
        if (portIndex == 1) {
            obj = {
                openEventFlyoutP1: true,
                rebalanceData: this.rebalanceData
            }
        } else if (portIndex == 2) { 
            obj = {
                openEventFlyoutP2: true,
                rebalanceData: this.rebalanceData
            }
        }
        this.setState(obj);
    }

    onSelectedEventType = (selectedEventType, portIndex) => {
        let obj = {};
        if (portIndex == 1) {
            obj = { selectedEventTypeP1: selectedEventType.value }
        } else if (portIndex == 2) {
            obj = { selectedEventTypeP2: selectedEventType.value }
        }
        this.setState(obj);
        this.selectedPortfolio = portIndex ? 'One': 'Two';
    }

    EventDateChange = (date, portIndex) => {
        let obj = {};
        let selectedEventDate = (utilityFunctions.convertToOADate(date) < this.props.hypoStartPeriod 
            || utilityFunctions.convertToOADate(date) > moment(utilityFunctions.dateFromOADate(this.props.hypotheticalState.endPeriod)).subtract(3,'months').toOADate())                    
            ? new Date(moment(utilityFunctions.formatOADate(this.props.hypoStartPeriod))) : date;
        if (portIndex == 1) {
            obj = { selectedEventDateP1: selectedEventDate }
        } else if (portIndex == 2) {
            obj = { selectedEventDateP2: selectedEventDate }
        }
        this.setState(obj);
        this.selectedPortfolio = portIndex == 1 ? 'One': 'Two';
    }

    /**
     * Portfolio 2: When user clicks on modify button of fund investment
     * @param {event} e event object
     */
    modifyInvestmentFlyout = (e, portIndex) => {

        // Step 1: filter fund investment schedules based on click edit scheduleIds
        // On UI, we are grouping investment  by start date, so at one date there can be multiple investment events. So when 
        // user click on modify icon, we have to load all those investment events.
        let attrEditScheduleIds = e.target.parentElement.getAttribute('data-scheduleId'); // Comma-separated scheduleIds
        let dType = e.target.parentElement.getAttribute('data-type'); //Investment/Withdrawal Type
        let arrEditscheduleIds = attrEditScheduleIds !== '' ?  attrEditScheduleIds.split(','): [];

        // Step 1.1: Get all the schedules as per port index
        let schedules = portIndex == 1 ? this.props.schedules: this.props.schedulesP2;
        let investmentSchedules = portIndex == 1 ? this.props.investmentSchedules: this.props.investmentSchedulesP2;
        
        let selectedInvestments = [];
        if(dType === "Deposit") {
            arrEditscheduleIds.forEach(scheduleId => {
                selectedInvestments.push(investmentSchedules.find(x => x.Id == scheduleId));
            });
        } else {
            //If Withdrawal type, then call modifyWithdrawalFlyoutP2, required some parameters along with
            //CtrFinalAllocation call, onnly those funds to come up in dropdown with positive allocations
            if (portIndex == 1) {
                this.modifyP1 = true;
                this.attrP1EditScheduleIds = attrEditScheduleIds;
                this.arrP1EditscheduleIds = arrEditscheduleIds;
            } else if (portIndex == 2) {
                this.modifyP2 = true;
                this.attrP2EditScheduleIds = attrEditScheduleIds;
                this.arrP2EditscheduleIds = arrEditscheduleIds;
            }
            this.props.contributionToRiskFinalAllocation(portIndex, parseFloat(e.target.parentElement.getAttribute('data-date')));
            this.props.getFundsAmountByDate(portIndex, parseFloat(e.target.parentElement.getAttribute('data-date')));
            return;
        }
        // Step 2: Group investment schedules by Investment as single fund can have multiple data rows
        // grpInvestmentData: { investmentId1: [sch1, sch2], investmentId2: [sch3, sch4], investmentId3: [sch5] }
        let grpInvestmentData = selectedInvestments.reduce(function (r, a) {
            r[a.InvestmentId] = r[a.InvestmentId] || [];
            r[a.InvestmentId].push(a);
            return r;
        }, Object.create(null));

        // Step 3: Create fund investment object and set it to state "this.state.fundInvestmentEditData"
        let _fundInvestments = [];
        let _fundInvestmentData = null;
        let _data = null;

        let funds = this.props.hypotheticalState.portfolios[portIndex-1] && this.props.hypotheticalState.portfolios[portIndex-1].funds;
        if (funds && funds.length > 0) {
            for(let InvestmentId in grpInvestmentData){
                let _fund = funds.find(fund => fund.investmentId === InvestmentId);
                if (_fund) {
                    _fundInvestmentData = {};
                    
                    _fundInvestmentData.isOpen = true,
                    _fundInvestmentData.investmentId = _fund.investmentId,
                    _fundInvestmentData.name = _fund.name,
                    _fundInvestmentData.symbol = _fund.symbol,
                    _fundInvestmentData.wsodissue = _fund.wsodissue

                    if (grpInvestmentData[InvestmentId].length > 0) {
                        _data = [];
                        grpInvestmentData[InvestmentId].forEach(item => {
                            _data.push({
                                invest: item.CashAmount ? item.CashAmount: 0.00,
                                frequency: { label: (item.Description != 'One-Time') ? investmentFrequencyTypeDict[item.Frequency] : 'One-Time', value: (item.Description != 'One-Time') ? item.Frequency.replace('Months','') : 'One-Time' },
                                endDate: item.DateEnd,
                                dateBegin: item.DateBegin,
                                increaseBy: item.IncreaseCashPercent ? { label: '%', value: '%' }: (item.IncreaseCashAmount ? { label: this.props.currencySymbol, value: this.props.currencySymbol } : { label: '%', value: '%' }),
                                increaseByValue: item.IncreaseCashPercent ? (item.IncreaseCashPercent * 100).toFixed(2): (item.IncreaseCashAmount ? item.IncreaseCashAmount: 0),
                                increaseFrequency: (item.IncreaseFrequency ? (item.IncreaseFrequency.indexOf('Months') > -1 ? { label: 'Every ' + item.IncreaseFrequency.replace('Months', '') + ' Month', value: item.IncreaseFrequency.replace('Months', '')}:{ label: 'Calendar Year End', value: 'Calendar Year End' }): {label: 'Every 1 Month', value: '1' })
                            });
                        })
                    }
                    _fundInvestmentData.data = _data
                }
                _fundInvestments.push(_fundInvestmentData)
            }
        }

        // Step 4: if all fund investment have frequency "One-Time", filter rebalance that have same start date as fund investment.
        // Note: Above condition does not mean there will be rebalance if all investment have "One-Time".
        // Assumption: There cannot be two rebalance on same date.
        let _rebalance = null;
        let _isRebalanceValid = (selectedInvestments.filter(item => item.Description !== 'One-Time').length == 0);
        if (_isRebalanceValid) {
            selectedInvestments.forEach(fundInv => {
                if (!_rebalance) {
                    _rebalance = schedules.find(schedule => {
                        return parseInt(schedule.DateBegin) === parseInt(fundInv.DateBegin);
                    });    
                }
            })
        }
        
        // Step 5: Get funds from the portfolio that are older than investment start date.
        // User can create investment events for these funds
        let fundsData = [];
        
        let selectedEventDate = selectedInvestments[0].DateBegin;
        
        if(!this.rebalanceData) {
            this.rebalanceData = cloneDeep(this.props.hypotheticalState);
        }

        this.rebalanceData.portfolios[portIndex-1].funds.map(item => {
            let oldestShareClassInceptionDate = utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate);
            if (oldestShareClassInceptionDate <= selectedEventDate) {
                fundsData.push({
                    label: item.name,
                    value: item.wsodissue
                })
            }
        });
        
        // Step 6: Rebalance is either same as that of investment or rebalance that has begin date before investment start date
        let selectedSchedule = null;
        if (_isRebalanceValid && _rebalance) {
            selectedSchedule = [_rebalance];
        } else {
            selectedSchedule = schedules.filter(x => x.DateBegin === selectedInvestments[0].DateBegin);
        }
        
        // Step 7: Set rebalance schedule edit data
        if (portIndex == 1) {
            this.editscheduleIdP1 = '';
            this.editFrequencyTypeP1 = 'Every 1 Month';
            this.modifyEventDateP1 = selectedInvestments[0].DateBegin;
        } else if (portIndex == 2) {
            this.editscheduleIdP2 = '';
            this.editFrequencyTypeP2 = 'Every 1 Month';
            this.modifyEventDateP2 = selectedInvestments[0].DateBegin;
        }
        
        let p1AllocationDict = {};

        if (selectedSchedule.length > 0) {
            if (portIndex == 1) {
                this.editDateEndP1 = selectedSchedule[0].DateEnd;
                this.editscheduleIdP1 = selectedSchedule[0].Id;
                this.editFrequencyTypeP1 = frequencyTypes.find(item => { return item.value === selectedSchedule[0].Frequency });    
            } else if (portIndex == 2) {
                this.editDateEndP2 = selectedSchedule[0].DateEnd;
                this.editscheduleIdP2 = selectedSchedule[0].Id;
                this.editFrequencyTypeP2 = frequencyTypes.find(item => { return item.value === selectedSchedule[0].Frequency });
            }
            
            selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                return p1AllocationDict[x.Id] = x.Allocation;
            });
            this.rebalanceData.portfolios[portIndex-1].funds.map(item => {
                return (item.amountInvestedPercent = p1AllocationDict[item.investmentId]);
            });
        }

        // Step 8: If there are no rebalances then use the hypo state
        if (schedules.length == 0) {
            this.rebalanceData = cloneDeep(this.props.hypotheticalState);
        }

        // Step 9: Update state required for opening the investment flyout
        if(dType === "Deposit") {
            this.setState({
                openInvestmentEventFlyoutP1: (portIndex == 1),
                openInvestmentEventFlyoutP2: (portIndex == 2),
                rebalanceData: this.rebalanceData,
                fundsData: fundsData,
                fundInvestmentEditData: {
                    data: _fundInvestments, 
                    scheduleIds: attrEditScheduleIds,
                    rebalanceSchedule: _rebalance ? _rebalance: null,
                    includeRebalance: (_rebalance ? true: false)
                }
            });
        } else {
            this.setState({
                openWithdrawalEventFlyoutP1: (portIndex == 1),
                openWithdrawalEventFlyoutP2: (portIndex == 2),
                rebalanceData: this.rebalanceData,
                fundsData: fundsData,
                fundInvestmentEditData: {
                    data: _fundInvestments, 
                    scheduleIds: attrEditScheduleIds,
                    rebalanceSchedule: _rebalance ? _rebalance: null,
                    includeRebalance: (_rebalance ? true: false)
                }
            });
        }
    }

    modifyWithdrawalFlyout = (attrEditScheduleIds, arrEditscheduleIds, fundCtrPositiveAllocations, portIndex) => {

        // Step 1: filter fund investment schedules based on click edit scheduleIds
        // On UI, we are grouping investment  by start date, so at one date there can be multiple investment events. So when 
        // user click on modify icon, we have to load all those investment events.
        // let attrEditScheduleIds = e.target.parentElement.getAttribute('data-scheduleId'); // Comma-separated scheduleIds
        // let dType = e.target.parentElement.getAttribute('data-type'); //Investment/Withdrawal Type
        // let arrEditscheduleIds = attrEditScheduleIds !== '' ?  attrEditScheduleIds.split(','): [];
        
        let withdrawalSchedules = portIndex == 1 ? this.props.withdrawalSchedules: this.props.withdrawalSchedulesP2;
        let schedules = portIndex == 1 ? this.props.schedules: this.props.schedulesP2;
        
        let selectedInvestments = [];
        
        arrEditscheduleIds.forEach(scheduleId => {
            selectedInvestments.push(withdrawalSchedules.find(x => x.Id == scheduleId));
        });
        
        // Step 2: Group investment schedules by Investment as single fund can have multiple data rows
        // grpInvestmentData: { investmentId1: [sch1, sch2], investmentId2: [sch3, sch4], investmentId3: [sch5] }
        let grpInvestmentData = selectedInvestments.reduce(function (r, a) {
            r[a.InvestmentId] = r[a.InvestmentId] || [];
            r[a.InvestmentId].push(a);
            return r;
        }, Object.create(null));

        // Step 3: Create fund investment object and set it to state "this.state.fundInvestmentEditData"
        let _fundInvestments = [];
        let _fundInvestmentData = null;
        let _data = null;

        let funds = this.props.hypotheticalState.portfolios[portIndex-1] && this.props.hypotheticalState.portfolios[portIndex-1].funds;
        if (funds && funds.length > 0) {
            for(let InvestmentId in grpInvestmentData){
                let _fund = funds.find(fund => fund.investmentId === InvestmentId);
                if (_fund) {
                    _fundInvestmentData = {};
                    
                    _fundInvestmentData.isOpen = true;
                    _fundInvestmentData.investmentId = _fund.investmentId;
                    _fundInvestmentData.name = _fund.name;
                    _fundInvestmentData.symbol = _fund.symbol;
                    _fundInvestmentData.wsodissue = _fund.wsodissue;

                    if (grpInvestmentData[InvestmentId].length > 0) {
                        _data = [];
                        grpInvestmentData[InvestmentId].forEach(item => {
                            _data.push({
                                invest: item.CashAmount ? item.CashAmount: 0.00,
                                frequency: { label: (item.Description != 'One-Time') ? investmentFrequencyTypeDict[item.Frequency] : 'One-Time', value: (item.Description != 'One-Time') ? item.Frequency.replace('Months','') : 'One-Time' },
                                endDate: item.DateEnd,
                                dateBegin: item.DateBegin,
                                increaseBy: item.IncreaseCashPercent ? { label: '%', value: '%' }: (item.IncreaseCashAmount ? { label: this.props.currencySymbol, value: this.props.currencySymbol } : { label: '%', value: '%' }),
                                increaseByValue: item.IncreaseCashPercent ? (item.IncreaseCashPercent * 100).toFixed(2): (item.IncreaseCashAmount ? item.IncreaseCashAmount: 0),
                                increaseFrequency: (item.IncreaseFrequency ? (item.IncreaseFrequency.indexOf('Months') > -1 ? { label: 'Every ' + item.IncreaseFrequency.replace('Months', '') + ' Month', value: item.IncreaseFrequency.replace('Months', '')}:{ label: 'Calendar Year End', value: 'Calendar Year End' }): {label: 'Every 1 Month', value: '1' })
                            });
                        })
                    }
                    _fundInvestmentData.data = _data
                }
                _fundInvestments.push(_fundInvestmentData)
            }
        }

        // Step 4: if all fund investment have frequency "One-Time", filter rebalance that have same start date as fund investment.
        // Note: Above condition does not mean there will be rebalance if all investment have "One-Time".
        // Assumption: There cannot be two rebalance on same date.
        let _rebalance = null;
        let _isRebalanceValid = (selectedInvestments.filter(item => item.Description !== 'One-Time').length == 0);
        if (_isRebalanceValid) {
            selectedInvestments.forEach(fundInv => {
                if (!_rebalance) {
                    _rebalance = schedules.find(schedule => {
                        return parseInt(schedule.DateBegin) === parseInt(fundInv.DateBegin);
                    });    
                }
            })
        }
        
        // Step 5: Get funds from the portfolio that are older than investment start date.
        // User can create investment events for these funds
        let fundsData = [];
        
        let selectedEventDate = selectedInvestments[0].DateBegin;
        
        if(!this.rebalanceData) {
            this.rebalanceData = cloneDeep(this.props.hypotheticalState);
        }

        this.rebalanceData.portfolios[portIndex-1].funds.map(item => {
            let oldestShareClassInceptionDate = utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate);
            if (oldestShareClassInceptionDate <= selectedEventDate) {
                if (fundCtrPositiveAllocations.indexOf(item.wsodissue) > -1) {
                    fundsData.push({
                        label: item.name,
                        value: item.wsodissue
                    })
                }
            }
        });
        
        // Step 6: Rebalance is either same as that of investment or rebalance that has begin date before investment start date
        let selectedSchedule = null;
        if (_isRebalanceValid && _rebalance) {
            selectedSchedule = [_rebalance];
        } else {
            selectedSchedule = schedules.filter(x => x.DateBegin === selectedInvestments[0].DateBegin);
        }
        
        // Step 7: Set rebalance schedule edit data
        if (portIndex == 1) {
            this.editscheduleIdP1 = '';
            this.editFrequencyTypeP1 = 'Every 1 Month';
            this.modifyEventDateP1 = selectedInvestments[0].DateBegin;
        } else if (portIndex == 2) {
            this.editscheduleIdP2 = '';
            this.editFrequencyTypeP2 = 'Every 1 Month';    
            this.modifyEventDateP2 = selectedInvestments[0].DateBegin;
        }
        
        let p1AllocationDict = {};

        if (selectedSchedule.length > 0) {
            if (portIndex == 1) {
                this.editDateEndP1 = selectedSchedule[0].DateEnd;
                this.editscheduleIdP1 = selectedSchedule[0].Id;
                this.editFrequencyTypeP1 = frequencyTypes.find(item => { return item.value === selectedSchedule[0].Frequency });
            } else if (portIndex == 2) {
                this.editDateEndP2 = selectedSchedule[0].DateEnd;
                this.editscheduleIdP2 = selectedSchedule[0].Id;
                this.editFrequencyTypeP2 = frequencyTypes.find(item => { return item.value === selectedSchedule[0].Frequency });
            }
            selectedSchedule[selectedSchedule.length - 1].Investment.map(x => {
                return p1AllocationDict[x.Id] = x.Allocation;
            });
            this.rebalanceData.portfolios[portIndex-1].funds.map(item => {
                return (item.amountInvestedPercent = p1AllocationDict[item.investmentId]);
            });
        }

        // Step 8: If there are no rebalances then use the hypo state
        if (schedules.length == 0) {
            this.rebalanceData = cloneDeep(this.props.hypotheticalState);
        }

        //Reset variables
        if (portIndex == 1) {
            this.modifyP1 = false;
            this.attrP1EditScheduleIds = 0;
            this.arrP1EditscheduleIds = [];
            this.modifyP1WithdrawalDate = 0;  
        } else if (portIndex == 2) {
            this.modifyP2 = false;
            this.attrP2EditScheduleIds = 0;
            this.arrP2EditscheduleIds = [];
            this.modifyP2WithdrawalDate = 0;    
        }
        
        // Step 9: Update state required for opening the investment flyout
        this.setState({
            openWithdrawalEventFlyoutP1: (portIndex == 1),
            openWithdrawalEventFlyoutP2: (portIndex == 2),
            rebalanceData: this.rebalanceData,
            fundsData: fundsData,
            fundInvestmentEditData: {
                data: _fundInvestments, 
                scheduleIds: attrEditScheduleIds,
                rebalanceSchedule: _rebalance ? _rebalance: null,
                includeRebalance: (_rebalance ? true: false)
            }
        });
    }
    render(){
        let cssClass = '';

        let disableAddEventButtonStyle = {
			'pointer-events': 'none',
			opacity: 0.4
        };
        
		if (this.props.isSelected) {
			cssClass = 'display';
		} else {
			cssClass = 'no-display';
		}

        const DropdownIndicator = props => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <i
                            className="dropdown"
                            aria-hidden="true"
                        />
                    </components.DropdownIndicator>
                )
            );
        };

		return (
        <div className={cssClass}>
            <div className={'hypotheticalsModal eventsModal'}>
            <div className={'hypothetical-data'}>
                {/* Filter - Header */}
                <div className={'events-header'}>
            {this.props.hypotheticalState.portfolios[0].funds.length > 0 ? <div>
            <div className="select-investment-period">
                <span className="label-investment-period">Portfolio One</span>
                <div className= "custom-calendar">
                <CustomCalendar 
                  showCustomCalendar={this.state.showCustomCalendar}
                  hypotheticalState={this.props.hypotheticalState}
                  data-portfolio-type = "P1"
                  selectedTimePeriodDate={this.state.selectedEventDateP1!=  null ? this.state.selectedEventDateP1 :new Date(moment(utilityFunctions.formatOADate(this.props.hypoStartPeriod)))}
                  module ={this.props.selectedTab.toString().indexOf('Events') > -1 ? "Events" :""}
                  handleChange = {(e) => this.EventDateChange(e, 1)}
                 />
                 </div>
              </div>
              <div className={'filter-styles'}>
              <div className="label-style">
                <Select
                      name="select-time-period"
                      className="dropdown-mfs"
                      theme={customTheme}
                      styles={customStyles}
                      isSearchable={false}
                      defaultValue={this.state.selectedEventTypeP1}
                      onChange={(e) => this.onSelectedEventType(e, 1)}
                      options={eventTypes}
                      components={{ DropdownIndicator }}
                      id="selectTimePeriodP1" />
              </div>
						<div className={'dv-add-events'}>
								<button
									onClick={() => this.openEventFlyout(1)}
                                    className={'cancel-button'}
                                    style ={this.state.selectedEventTypeP1 !== null && this.state.selectedEventTypeP1 !== "Select an Event" ? {} :disableAddEventButtonStyle}
								>
									ADD EVENT
								</button>
						</div>
            </div></div>  : null }
                </div>
                <div className={'events-header'}>
                {this.props.hypotheticalState.portfolios[1].funds.length > 0 ? <div>
                <div className="select-investment-period">
                <span className="label-investment-period">Portfolio Two</span>
                <div className= "custom-calendar">
                <CustomCalendar 
                  showCustomCalendar={this.state.showCustomCalendar}
                  hypotheticalState={this.props.hypotheticalState}
                  data-portfolio-type = "P2"
                  selectedTimePeriodDate={this.state.selectedEventDateP2 !=  null ? this.state.selectedEventDateP2 : new Date(moment(utilityFunctions.formatOADate(this.props.hypoStartPeriod)))}
                  module ={this.props.selectedTab.toString().indexOf('Events') > -1 ? "Events" :""}
                  handleChange = {(e) => this.EventDateChange(e, 2)}
                 />
                 </div>
              </div>
              <div className={'filter-styles'}>
              <div className="label-style">
                <Select
                      name="select-time-period"
                      className="dropdown-mfs"
                      theme={customTheme}
                      styles={customStyles}
                      isSearchable={false}
                      defaultValue={this.state.selectedEventTypeP2}
                      onChange={(e) => this.onSelectedEventType(e, 2)}
                      options={eventTypes}
                      components={{ DropdownIndicator }}
                      id="selectTimePeriodP2" />
              </div>
							<div className={'dv-add-events'}>
								<button
									onClick={() => this.openEventFlyout(2)}
                                    className={'cancel-button'}
                                    style ={this.state.selectedEventTypeP2 !== null && this.state.selectedEventTypeP2 !== "Select an Event" ? {} : disableAddEventButtonStyle}
								>
									ADD EVENT
								</button>
							</div>
            </div> </div> : null}
                </div>
                    {/* Portfolio Investment table - Main */}
 
            {/* Portfolio Investment table - First */}
                <div className={'portfolio-investment1 event-portfolio'}>
                {this.props.hypotheticalState.portfolios[0].funds.length > 0 ? <div>
                    <div className={'PortfolioInvestmentTable'}>
                        <div className={'portfolio-heading'}>
                            <label className={'portfolio-name'}>Portfolio One</label>
                             <a className={'portfolio-edit-link'} onClick={this.props.hideBenchmarkAndHypotheticalsResults}>Edit</a>
                        </div>
                        { this.props.hypotheticalState.portfolios[0].funds.filter(x => !x.rebalance && (x.Invalid || x.amountInvestedPercent == 0)).length > 0 ?
                             <div className={'dv-event-introduction-txt'}>Please add an event to resolve the funds below in red </div> : 
                             <div className={'dv-event-introduction-txt'}>There are no events in this portfolio that need your attention</div> 
                        }
                    </div>
                    <div className={"dv-initial-investment"}>
                          <span> Initial Investment </span>
                          <span> {(this.props.userType==='International') ? utilityFunctions.formatOADate(this.props.hypotheticalState.startPeriod, "DD MMM, YYYY"): utilityFunctions.formatOADate(this.props.hypotheticalState.startPeriod, "MMM DD, YYYY")}</span>
                    </div>
                    { this.props.hypotheticalState.portfolios[0].funds.filter(x => !x.rebalance && (x.Invalid || x.amountInvestedPercent == 0)).length > 0 &&
                        this.props.hypotheticalState.portfolios[0].funds.filter(x => !x.rebalance && (x.Invalid || x.amountInvestedPercent == 0)).map(item => {
                         return <div className={item.amountInvestedPercent == 0 || item.Invalid ? 'dv-fund-events': '' } data-index={'P1'} data-is-invalid={item.amountInvestedPercent == 0 || item.Invalid === true} > 
                                <table>
                                    <tr>
                                        <td>
                                            <label className={'fund-symbol'}>{item.symbol}</label>
                                            <br />
                                            <span className={'fund-name'} title={item.name}>{item.name}</span>
                                        </td>
                                        <td>
                                            <label className={'fund-symbol'}>{(this.props.userType==='International') ? 'Perf. Start Date' : 'Inception Date'}</label>
                                            <br />
                                            <span className={`fund-inception-date ${item.isExtended ? 'underline-text' : ''}`} title={item.isExtended ? ((this.props.userType==='International') ? utilityFunctions.formatOADate(item.oldestShareClassInceptionDate, "DD MMM, YYYY") : utilityFunctions.formatOADate(item.oldestShareClassInceptionDate, "MMM DD, YYYY")) : ''} >{(this.props.userType==='International') ? utilityFunctions.formatOADate(item.oldestShareClassInceptionDate, "DD MMM, YYYY") : utilityFunctions.formatOADate(item.inceptionDate, "MMM DD, YYYY")}{item.isExtended && '*'}</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        })
                    }


                                {/* Portfolio Investment table - First - Rebalance, Investment */}
                                {this.props.eventDetailsAll && this.props.eventDetailsAll.map(item => {
                                    if (item.Type === 'Rebalance') {
                                        return (
                                            <div>
                                                <div className={'dv-rebalance-events'}>
                                                    <table className={'tbl-rebalance-events'}>
                                                        <tr>
                                                            <td>
                                                                <label className={'fund-symbol'}>Rebalance</label>
                                                                <br />
                                                                <span className={'fund-name'} >{frequencyTypes.find(freq => { return freq.value == item.Frequency }).label}</span>
                                                            </td>
                                                            <td>
                                                                <label className={'fund-symbol-1'}>Event Date</label>
                                                                <span
                                                                    data-containerId={this.state.workingCopyContainerIdOne}
                                                                    data-scheduleId={item.scheduleId}
                                                                    data-index={1}
                                                                    onClick={this.props.handleDeleteSchedule}
                                                                    className={'dv-event-delete'}
                                                                >                                                            
                                                                    <img src={closeSVG} />{" "}                                                                
                                                                </span>
                                                                <span
                                                                    data-containerId={this.state.workingCopyContainerIdOne}
                                                                    data-scheduleId={item.scheduleId}
                                                                    data-index={1}
                                                                    onClick={(e) => this.modifyEventFlyout(e,1)}
                                                                    className={'dv-event-delete'}
                                                                >
                                                                    <img src={modifySvg} />{" "}
                                                                    
                                                                </span>
                                                                <br />
                                                                <span className={'fund-name'}>{(this.props.userType === 'International') ? utilityFunctions.formatOADate(parseFloat(item.date) + 1, "DD MMM, YYYY") : utilityFunctions.formatOADate(parseFloat(item.date) + 1, "MMM DD, YYYY")} </span>
                                                            </td>
                                                            
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        )
                                    } else if (item.Type === 'Deposit' || item.Type === 'Withdrawal') {
                                        return (
                                            <div>
                                                <div className={'dv-rebalance-events'}>
                                                    <table className={'tbl-rebalance-events'}>
                                                        <tr>
                                                            <td>
                                                                <label className={'fund-symbol'}>{item.Title}</label>                                                            
                                                                {item.investmentInfo.map(details=>{                                                             
                                                                   return <span className={'fund-name'} style={{'padding-bottom': '10px' }}>{details}</span>                                                               
                                                                })}
                                                                {/* <span className={'fund-name'} >{item.investmentInfo[0]}</span> */}
                                                            </td>
                                                            <td>
                                                                <label className={'fund-symbol-1'}>Event Date</label>
                                                                <span
                                                                    data-containerId={this.state.workingCopyContainerIdOne}
                                                                    data-scheduleId={item.investmentScheduleId}
                                                                    data-index={1}
                                                                    onClick={this.props.handleDeleteInvestmentSchedule}
                                                                    className={'dv-event-delete'}
                                                                >                                                                
                                                                    <img src={closeSVG} />{" "}                                                                
                                                                </span>
                                                                <span
                                                                    data-containerId={this.state.workingCopyContainerIdOne}
                                                                    data-scheduleId={item.investmentScheduleId}
                                                                    data-index={1}
                                                                    data-type={item.Type}
                                                                    data-date={parseFloat(item.date) + 1}
                                                                    onClick={e => this.modifyInvestmentFlyout(e, 1)}
                                                                    className={'dv-event-delete'}
                                                                >
                                                                    <img src={modifySvg} />{" "}
                                                                    
                                                                </span>
                                                                <br />
                                                                <span className={'fund-name'}>{(this.props.userType === 'International') ? utilityFunctions.formatOADate(parseFloat(item.date) + 1, "DD MMM, YYYY") : utilityFunctions.formatOADate(parseFloat(item.date) + 1, "MMM DD, YYYY")} </span>
                                                            </td>
                                                            
                                                        </tr>
                                                    </table>
                                                </div>
                                                
                                            </div>
                                        )
                                    } else if(item.Type === 'FundTile') {
                                        {
                                            return <div className={"dv-rebalance-subtile"}>
                                                <div className= {'fund-tile'}>
                                                {item.convertedToNonZeroAllocation.length > 0 &&
                                                    <div>
                                                        <label className={'fund-symbol'}>Added to Portfolio</label>
                                                        <span className={item.convertedToZeroAllocation.length > 0 ? 'funds-added' : ''}>{item.convertedToNonZeroAllocation.join(', ') }</span>
                                                    </div>}
                                                    {item.convertedToZeroAllocation.length > 0 &&
                                                    <div>
                                                        <label className={'fund-symbol'}>Removed from Portfolio</label>
                                                        <span className={'funds-removed'} >{item.convertedToZeroAllocation.join(', ') }</span>
                                                    </div>}
                                                </div>
                                                <div className={'fund-tile-date'}>
                                                    <label className={'fund-symbol'}>Date</label>
                                                    <span className={'fund-name'} >{(this.props.userType === 'International') ? utilityFunctions.formatOADate(parseFloat(item.date) + 1, "DD MMM, YYYY") : utilityFunctions.formatOADate(parseFloat(item.date) + 1, "MMM DD, YYYY")}</span>
                                                </div>
                                            </div>
                                        }
                                    }
                                })}
                     <div className={"dv-final-portfolio"}>
                          <span> Final Portfolio </span>
                          <span> {(this.props.userType === 'International') ? utilityFunctions.formatOADate(this.props.hypotheticalState.endPeriod, "DD MMM, YYYY") : utilityFunctions.formatOADate(this.props.hypotheticalState.endPeriod, "MMM DD, YYYY")}</span>
                    </div> </div> : <div className="no-funds">No funds selected for this portfolio</div>}
                </div>
                {/* Portfolio Investment table - Second */}
                <div className={'portfolio-investment2 event-portfolio'}>
                {this.props.hypotheticalState.portfolios[1].funds.length > 0 ? <div>
                    <div className={'PortfolioInvestmentTable'}>    
                        <div className={'portfolio-heading'}>
                            <label className={'portfolio-name'}>Portfolio Two</label>
                            <a className={'portfolio-edit-link'} onClick={this.props.hideBenchmarkAndHypotheticalsResults}>Edit</a>
                        </div>
                        { this.props.hypotheticalState.portfolios.length>1 && this.props.hypotheticalState.portfolios[1].funds.filter(x => x.Invalid).length > 0 ?
                             <div className={'dv-event-introduction-txt'}>Please add an event to resolve the funds below in red </div> : 
                             <div className={'dv-event-introduction-txt'}>There are no events in this portfolio that need your attention</div> 
                        }
                    </div>
                        <div className={"dv-initial-investment"}>
                            <span> Initial Investment </span>
                            <span> {(this.props.userType==='International') ? utilityFunctions.formatOADate(this.props.hypotheticalState.startPeriod, "DD MMM, YYYY") : utilityFunctions.formatOADate(this.props.hypotheticalState.startPeriod, "MMM DD, YYYY")}</span>
                        </div>
                        { this.props.hypotheticalState.portfolios.length>1 && this.props.hypotheticalState.portfolios[1].funds.filter(x => !x.rebalance && (x.Invalid || x.amountInvestedPercent == 0)).length > 0 &&
                                            this.props.hypotheticalState.portfolios[1].funds.filter(x => !x.rebalance && (x.Invalid || x.amountInvestedPercent == 0)).map(item => {
                       return <div className={item.amountInvestedPercent == 0 || item.Invalid  ? 'dv-fund-events': '' } data-index={'P2'} data-is-invalid={item.amountInvestedPercent == 0 || item.Invalid === true} >
                            
                                <table>
                                    <tr>
                                        <td>
                                            <label className={'fund-symbol'}>{item.symbol}</label>
                                            <br />
                                            <span className={'fund-name'} title={item.name}>{item.name}</span>
                                        </td>
                                        <td>
                                            <label className={'fund-symbol'}>{(this.props.userType==='International') ? 'Perf. Start Date' : 'Inception Date'}</label>
                                            <br />
                                            <span className={`fund-inception-date ${item.isExtended ? 'underline-text' : ''}`} title={item.isExtended ? utilityFunctions.formatOADate(item.oldestShareClassInceptionDate, "MMM DD, YYYY") : ''} >{(this.props.userType==='International') ? utilityFunctions.formatOADate(item.oldestShareClassInceptionDate, "DD MMM, YYYY") : utilityFunctions.formatOADate(item.inceptionDate, "MMM DD, YYYY")}{item.isExtended && '*'}</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            })
                         }
                        {/* Portfolio Investment table - Second - Rebalance, Investment */}
                         {this.props.eventDetailsAllP2 && this.props.eventDetailsAllP2.map(item => {
                             if (item.Type === 'Rebalance') {
                                return (
                                    <div>
                                        <div className={'dv-rebalance-events'} >
                                            <table className={'tbl-rebalance-events'}>
                                                <tr>
                                                    <td>
                                                        <label className={'fund-symbol'}>Rebalance</label>
                                                        <br />
                                                        <span className={'fund-name'} >{frequencyTypes.find(freq => { return freq.value == item.Frequency }).label}</span>
                                                    </td>
                                                    <td>
                                                        <label className={'fund-symbol-1'}>Event Date</label>
                                                        <span
                                                            data-containerId={this.state.workingCopyContainerIdTwo}
                                                            data-scheduleId={item.scheduleId}
                                                            data-index={2}
                                                            className={'dv-event-delete'}
                                                            onClick={this.props.handleDeleteSchedule}
                                                        >                                                        
                                                            <img src={closeSVG} />{" "}                                                        
                                                        </span>
                                                        <span
                                                            data-containerId={this.state.workingCopyContainerIdTwo}
                                                            data-scheduleId={item.scheduleId}
                                                            data-index={2}
                                                            className={'dv-event-delete'}
                                                            onClick={(e) => this.modifyEventFlyout(e, 2)}
                                                        >
                                                            <img src={modifySvg} />{" "}
                                                                                                               
                                                        </span>
                                                        <br />
                                                        <span className={'fund-name'}>{(this.props.userType === 'International') ? utilityFunctions.formatOADate(parseFloat(item.date) + 1, "DD MMM, YYYY") : utilityFunctions.formatOADate(parseFloat(item.date) + 1, "MMM DD, YYYY")} </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>                                        
                                    </div>
                                )
                             } else if (item.Type === 'Deposit' || item.Type === 'Withdrawal') {
                                return (
                                    <div>
                                        <div className={'dv-rebalance-events'}>
                                            <table className={'tbl-rebalance-events'}>
                                                <tr>
                                                    <td>
                                                        <label className={'fund-symbol'}>{item.Title}</label>
                                                        {item.investmentInfo.map(details=>{                                                                
                                                                  return <span className={'fund-name'} style={{'padding-bottom': '10px' }}>{details}</span>                                                                
                                                                })}
                                                                {/* <span className={'fund-name'} >{item.investmentInfo[0]}</span> */}
                                                    </td>
                                                    <td>
                                                        <label className={'fund-symbol-1'}>Event Date</label>
                                                        <span
                                                            data-containerId={this.state.workingCopyContainerIdTwo}
                                                            data-scheduleId={item.investmentScheduleId}
                                                            data-index={2}
                                                            onClick={this.props.handleDeleteInvestmentSchedule}
                                                            className={'dv-event-delete'}
                                                        >                                                                
                                                            <img src={closeSVG} />{" "}                                         
                                                        </span>
                                                        <span
                                                            data-containerId={this.state.workingCopyContainerIdTwo}
                                                            data-scheduleId={item.investmentScheduleId}
                                                            data-index={2}
                                                            data-type={item.Type}
                                                            data-date={parseFloat(item.date) + 1}
                                                            onClick={e => this.modifyInvestmentFlyout(e, 2)}
                                                            className={'dv-event-delete'}
                                                        >
                                                            <img src={modifySvg} />{" "}
                                                            
                                                        </span>
                                                        <br />
                                                        <span className={'fund-name'}>{(this.props.userType === 'International') ? utilityFunctions.formatOADate(parseFloat(item.date) + 1, "DD MMM, YYYY") : utilityFunctions.formatOADate(parseFloat(item.date) + 1, "MMM DD, YYYY")} </span>
                                                    </td>
                                                    
                                                </tr>
                                            </table>
                                        </div>
                                        
                                    </div>
                                )
                             } else if(item.Type === 'FundTile') {
                                {
                                    return <div className={"dv-rebalance-subtile"}>
                                                <div className= {'fund-tile'}>
                                                {item.convertedToNonZeroAllocation.length > 0 &&
                                                    <div>
                                                        <label className={'fund-symbol'}>Added to Portfolio</label>
                                                        <span className={'funds-added'} >{item.convertedToNonZeroAllocation.join(', ') }</span>
                                                    </div>}
                                                    {item.convertedToZeroAllocation.length > 0 &&
                                                    <div>
                                                        <label className={'fund-symbol'}>Removed from Portfolio</label>
                                                        <span className={'funds-removed'} >{item.convertedToZeroAllocation.join(', ') }</span>
                                                    </div>}
                                                </div>
                                                <div className={'fund-tile-date'}>
                                                    <label className={'fund-symbol'}>Date</label>
                                                    <span className={'fund-name'} >{(this.props.userType === 'International') ? utilityFunctions.formatOADate(parseFloat(item.date) + 1, "DD MMM, YYYY") : utilityFunctions.formatOADate(parseFloat(item.date) + 1, "MMM DD, YYYY")}</span>
                                                </div>
                                            </div>
                                }
                            }
                        })}
                         <div className={"dv-final-portfolio"}>
                          <span> Final Portfolio </span>
                          <span> {(this.props.userType === 'International') ? utilityFunctions.formatOADate(this.props.hypotheticalState.endPeriod, "DD MMM, YYYY") : utilityFunctions.formatOADate(this.props.hypotheticalState.endPeriod, "MMM DD, YYYY")}</span>
                          </div> </div> :<div className="no-funds">No funds selected for this portfolio</div>}
                </div>
             </div>
        </div>
        <EventFlyout
            openEventFlyout={this.state.openEventFlyoutP1}
            closeEventFlyout={() => this.closeEventFlyout(1)}
            customStyles={CustomModalStyles.EventFlyoutModalStyles}
            selectedEventDate = {
                this.editscheduleIdP1 != '' && this.modifyEventDateP1 > 0 ? utilityFunctions.formatOADate(this.modifyEventDateP1, "MMM DD, YYYY") :
                (this.state.selectedEventDateP1!=  null ? utilityFunctions.formatOADate(utilityFunctions.convertToOADate(this.state.selectedEventDateP1), "MMM DD, YYYY")
                                               :utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY"))
                }
            selectedPortfolio={'One'}
            selectedEventType = {this.state.selectedEventTypeP1}
            hypotheticalState = {this.state.rebalanceData}
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}
            workingCopyConatainerId = {this.props.workingCopyConatainerIds.split(',')[0]}
            index = {0}
            calledSave={this.props.calledSave}
            editscheduleId={this.editscheduleIdP1}
            editFrequencyType={this.editFrequencyTypeP1}
            editDateBegin={this.modifyEventDateP1}
            editDateEnd={this.editDateEndP1}
            requestModifyEventTemplateDetailsApi = {this.props.requestModifyEventTemplateDetailsApi}
            autoDistribute={() => this.autoDistribute(1)}
            userType={this.props.userType}
		/>
        <EventFlyout
            openEventFlyout={this.state.openEventFlyoutP2}
            closeEventFlyout={() => this.closeEventFlyout(2)}
            customStyles={CustomModalStyles.EventFlyoutModalStyles}
            selectedEventDate={
                this.editscheduleIdP2 != '' && this.modifyEventDateP2 > 0 ? utilityFunctions.formatOADate(this.modifyEventDateP2, "MMM DD, YYYY") :
                (this.state.selectedEventDateP2!=  null ? utilityFunctions.formatOADate(utilityFunctions.convertToOADate(this.state.selectedEventDateP2), "MMM DD, YYYY")
                               :utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY"))
            }	
            selectedPortfolio={'Two'}
            selectedEventType = {this.state.selectedEventTypeP2}
            hypotheticalState = {this.state.rebalanceData}
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}
            workingCopyConatainerId = {this.props.workingCopyConatainerIds.split(',')[1]}
            index = {1}
            calledSave={this.props.calledSave}
            editscheduleId={this.editscheduleIdP2}
            editFrequencyType={this.editFrequencyTypeP2}
            editDateBegin={this.modifyEventDateP2}
            editDateEnd={this.editDateEndP2}
            requestModifyEventTemplateDetailsApi = {this.props.requestModifyEventTemplateDetailsApi}
            autoDistribute={() => this.autoDistribute(2)}
            userType={this.props.userType}
        />
        <InvestmentFlyout
            openEventFlyout={this.state.openInvestmentEventFlyoutP1}
            closeEventFlyout={() => this.closeEventFlyout(1)}
            customStyles={CustomModalStyles.EventFlyoutModalStyles}
            selectedEventDate = {
                this.editscheduleIdP1 != '' || this.modifyEventDateP1 > 0 ? utilityFunctions.formatOADate((parseInt(this.modifyEventDateP1) + 1), "MMM DD, YYYY") :
                (this.state.selectedEventDateP1!=  null ? utilityFunctions.formatOADate(utilityFunctions.convertToOADate(this.state.selectedEventDateP1), "MMM DD, YYYY")
                                               :utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY"))
                }
            selectedPortfolio={'One'}
            selectedEventType = {'New ' + this.state.selectedEventTypeP1}
            hypotheticalState = {this.state.rebalanceData}
            requestSaveInvestmentEventApi = {this.props.requestSaveInvestmentEventApi}
            workingCopyConatainerId = {this.props.workingCopyConatainerIds.split(',')[0]}
            index = {0}
            calledSave={this.props.calledSave}
            editscheduleId={this.editscheduleIdP1}
            editFrequencyType={this.editFrequencyTypeP1}
            editDateBegin={this.modifyEventDateP1}
            editDateEnd={this.editDateEndP1}
            requestModifyEventTemplateDetailsApi = {this.props.requestModifyEventTemplateDetailsApi}
            autoDistribute={() => this.autoDistribute(1)}  
            fundsData={this.state.fundsData}
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}
            fundInvestmentEditData = {this.state.fundInvestmentEditData}
            requestRemoveEventFrontinvestmentSchedulesApi={this.props.requestRemoveEventFrontinvestmentSchedulesApi}
            handleRebalanceAdjustment={this.props.handleRebalanceAdjustment}
            schedules={this.props.schedules}
            schedulesP2={this.props.schedulesP2}
            endDate={this.props.hypotheticalState.endPeriod}
            currencySymbol={this.props.currencySymbol}
            userType={this.props.userType}
		/>
        <InvestmentFlyout
            openEventFlyout={this.state.openInvestmentEventFlyoutP2}
            closeEventFlyout={() => this.closeEventFlyout(2)}
            customStyles={CustomModalStyles.EventFlyoutModalStyles}
            selectedEventDate={
                this.editscheduleIdP2 != '' || this.modifyEventDateP2 > 0 ? utilityFunctions.formatOADate((parseInt(this.modifyEventDateP2) + 1), "MMM DD, YYYY") :
                (this.state.selectedEventDateP2!=  null ? utilityFunctions.formatOADate(utilityFunctions.convertToOADate(this.state.selectedEventDateP2), "MMM DD, YYYY")
                               :utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY"))
            }	
            selectedPortfolio={'Two'}
            selectedEventType = {'New ' + this.state.selectedEventTypeP2}
            hypotheticalState = {this.state.rebalanceData}
            requestSaveInvestmentEventApi = {this.props.requestSaveInvestmentEventApi}
            workingCopyConatainerId = {this.props.workingCopyConatainerIds.split(',')[1]}
            index = {1}
            calledSave={this.props.calledSave}
            editscheduleId={this.editscheduleIdP2}
            editFrequencyType={this.editFrequencyTypeP2}
            editDateBegin={this.modifyEventDateP2}
            editDateEnd={this.editDateEndP2}
            requestModifyEventTemplateDetailsApi = {this.props.requestModifyEventTemplateDetailsApi}
            autoDistribute={() => this.autoDistribute(2)}      
            fundsData={this.state.fundsData}       
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}
            fundInvestmentEditData = {this.state.fundInvestmentEditData}
            requestRemoveEventFrontinvestmentSchedulesApi={this.props.requestRemoveEventFrontinvestmentSchedulesApi}
            handleRebalanceAdjustment={this.props.handleRebalanceAdjustment}
            schedules={this.props.schedules}
            schedulesP2={this.props.schedulesP2}
            endDate={this.props.hypotheticalState.endPeriod}
            currencySymbol={this.props.currencySymbol}
            userType={this.props.userType}
        />
        <WithdrawalFlyout
            openEventFlyout={this.state.openWithdrawalEventFlyoutP1}
            closeEventFlyout={() => this.closeEventFlyout(1)}
            customStyles={CustomModalStyles.EventFlyoutModalStyles}
            selectedEventDate = {
                this.editscheduleIdP1 != '' || this.modifyEventDateP1 > 0 ? utilityFunctions.formatOADate((parseInt(this.modifyEventDateP1) + 1), "MMM DD, YYYY") :
                (this.state.selectedEventDateP1!=  null ? utilityFunctions.formatOADate(utilityFunctions.convertToOADate(this.state.selectedEventDateP1), "MMM DD, YYYY")
                                               :utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY"))
                }
            selectedPortfolio={'One'}
            selectedEventType = {'New ' + this.state.selectedEventTypeP1}
            hypotheticalState = {this.state.rebalanceData}
            requestSaveWithdrawalEventApi = {this.props.requestSaveWithdrawalEventApi}
            workingCopyConatainerId = {this.props.workingCopyConatainerIds.split(',')[0]}
            index = {0}
            calledSave={this.props.calledSave}
            editscheduleId={this.editscheduleIdP1}
            editFrequencyType={this.editFrequencyTypeP1}
            editDateBegin={this.modifyEventDateP1}
            editDateEnd={this.editDateEndP1}
            requestModifyEventTemplateDetailsApi = {this.props.requestModifyEventTemplateDetailsApi}
            autoDistribute={() => this.autoDistribute(1)}  
            fundsData={this.state.fundsData}
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}
            fundInvestmentEditData = {this.state.fundInvestmentEditData}
            requestRemoveEventFrontwithdrawalSchedulesApi={this.props.requestRemoveEventFrontinvestmentSchedulesApi}
            handleRebalanceAdjustment={this.props.handleRebalanceAdjustment}
            schedules={this.props.schedules}
            schedulesP2={this.props.schedulesP2}
            endDate={this.props.hypotheticalState.endPeriod}
            fundsPresentAmountList = {this.props.fundsPresentAmountList}
            currencySymbol={this.props.currencySymbol}
            userType={this.props.userType}
		/>
        <WithdrawalFlyout
            openEventFlyout={this.state.openWithdrawalEventFlyoutP2}
            closeEventFlyout={() => this.closeEventFlyout(2)}
            customStyles={CustomModalStyles.EventFlyoutModalStyles}
            selectedEventDate={
                this.editscheduleIdP2 != '' || this.modifyEventDateP2 > 0 ? utilityFunctions.formatOADate((parseInt(this.modifyEventDateP2) + 1), "MMM DD, YYYY") :
                (this.state.selectedEventDateP2!=  null ? utilityFunctions.formatOADate(utilityFunctions.convertToOADate(this.state.selectedEventDateP2), "MMM DD, YYYY")
                               :utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY"))
            }	
            selectedPortfolio={'Two'}
            selectedEventType = {'New ' + this.state.selectedEventTypeP2}
            hypotheticalState = {this.state.rebalanceData}
            requestSaveWithdrawalEventApi = {this.props.requestSaveWithdrawalEventApi}
            workingCopyConatainerId = {this.props.workingCopyConatainerIds.split(',')[1]}
            index = {1}
            calledSave={this.props.calledSave}
            editscheduleId={this.editscheduleIdP2}
            editFrequencyType={this.editFrequencyTypeP2}
            editDateBegin={this.modifyEventDateP2}
            editDateEnd={this.editDateEndP2}
            requestModifyEventTemplateDetailsApi = {this.props.requestModifyEventTemplateDetailsApi}
            autoDistribute={() => this.autoDistribute(2)}      
            fundsData={this.state.fundsData}       
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}
            fundInvestmentEditData = {this.state.fundInvestmentEditData}
            requestRemoveEventFrontwithdrawalSchedulesApi={this.props.requestRemoveEventFrontinvestmentSchedulesApi}
            handleRebalanceAdjustment={this.props.handleRebalanceAdjustment}
            schedules={this.props.schedules}
            schedulesP2={this.props.schedulesP2}
            endDate={this.props.hypotheticalState.endPeriod}
            fundsPresentAmountList = {this.props.fundsPresentAmountList}
            currencySymbol={this.props.currencySymbol}
            userType={this.props.userType}
        />
        <PortfolioInvestFlyout
            openEventFlyout={this.state.openPortfolioInvestEventFlyoutP1}
            closeEventFlyout={() => this.closeEventFlyout(1)}
            customStyles={CustomModalStyles.EventPortFlyoutModalStyles}
            selectedEventDate = {
                this.editscheduleIdP1 != '' || this.modifyEventDateP1 > 0 ? utilityFunctions.formatOADate((parseInt(this.modifyEventDateP1) + 1), "MMM DD, YYYY") :
                (this.state.selectedEventDateP1!=  null ? utilityFunctions.formatOADate(utilityFunctions.convertToOADate(this.state.selectedEventDateP1), "MMM DD, YYYY")
                                               :utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY"))
                }
            selectedPortfolio={'One'}
            selectedEventType = {'New ' + this.state.selectedEventTypeP1}
            hypotheticalState = {this.state.rebalanceData}
            requestSaveInvestmentEventApi = {this.props.requestSaveInvestmentEventApi}
            workingCopyConatainerId = {this.props.workingCopyConatainerIds.split(',')[0]}
            index = {0}
            calledSave={this.props.calledSave}
            editscheduleId={this.editscheduleIdP1}
            editFrequencyType={this.editFrequencyTypeP1}
            editDateBegin={this.modifyEventDateP1}
            editDateEnd={this.editDateEndP1}
            requestModifyEventTemplateDetailsApi = {this.props.requestModifyEventTemplateDetailsApi}
            autoDistribute={() => this.autoDistribute(1)}  
            fundsData={this.state.fundsData}
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}            
            handleRebalanceAdjustment={this.props.handleRebalanceAdjustment}
            schedules={this.props.schedules}
            schedulesP2={this.props.schedulesP2}
            endDate={this.props.hypotheticalState.endPeriod}
            currencySymbol={this.props.currencySymbol}
            userType={this.props.userType}
		/>
        <PortfolioInvestFlyout
            openEventFlyout={this.state.openPortfolioInvestEventFlyoutP2}
            closeEventFlyout={() => this.closeEventFlyout(2)}
            customStyles={CustomModalStyles.EventPortFlyoutModalStyles}
            selectedEventDate={
                this.editscheduleIdP2 != '' || this.modifyEventDateP2 > 0 ? utilityFunctions.formatOADate((parseInt(this.modifyEventDateP2) + 1), "MMM DD, YYYY") :
                (this.state.selectedEventDateP2!=  null ? utilityFunctions.formatOADate(utilityFunctions.convertToOADate(this.state.selectedEventDateP2), "MMM DD, YYYY")
                               :utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY"))
            }	
            selectedPortfolio={'Two'}
            selectedEventType = {'New ' + this.state.selectedEventTypeP2}
            hypotheticalState = {this.state.rebalanceData}
            requestSaveInvestmentEventApi = {this.props.requestSaveInvestmentEventApi}
            workingCopyConatainerId = {this.props.workingCopyConatainerIds.split(',')[1]}
            index = {1}
            calledSave={this.props.calledSave}
            editscheduleId={this.editscheduleIdP2}
            editFrequencyType={this.editFrequencyTypeP2}
            editDateBegin={this.modifyEventDateP2}
            editDateEnd={this.editDateEndP2}
            requestModifyEventTemplateDetailsApi = {this.props.requestModifyEventTemplateDetailsApi}
            autoDistribute={() => this.autoDistribute(2)}      
            fundsData={this.state.fundsData}       
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}            
            handleRebalanceAdjustment={this.props.handleRebalanceAdjustment}
            schedules={this.props.schedules}
            schedulesP2={this.props.schedulesP2}
            endDate={this.props.hypotheticalState.endPeriod}
            currencySymbol={this.props.currencySymbol}
            userType={this.props.userType}
        />
        <PortWithdrawalFlyout
            openEventFlyout={this.state.openPortWithdrawalEventFlyoutP1}
            closeEventFlyout={() => this.closeEventFlyout(1)}
            customStyles={CustomModalStyles.EventPortFlyoutModalStyles}
            selectedEventDate = {
                this.editscheduleIdP1 != '' || this.modifyEventDateP1 > 0 ? utilityFunctions.formatOADate((parseInt(this.modifyEventDateP1) + 1), "MMM DD, YYYY") :
                (this.state.selectedEventDateP1!=  null ? utilityFunctions.formatOADate(utilityFunctions.convertToOADate(this.state.selectedEventDateP1), "MMM DD, YYYY")
                                               :utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY"))
                }
            selectedPortfolio={'One'}
            selectedEventType = {'New ' + this.state.selectedEventTypeP1}
            hypotheticalState = {this.state.rebalanceData}
            requestSaveWithdrawalEventApi = {this.props.requestSaveWithdrawalEventApi}
            workingCopyConatainerId = {this.props.workingCopyConatainerIds.split(',')[0]}
            index = {0}
            calledSave={this.props.calledSave}
            editscheduleId={this.editscheduleIdP1}
            editFrequencyType={this.editFrequencyTypeP1}
            editDateBegin={this.modifyEventDateP1}
            editDateEnd={this.editDateEndP1}
            requestModifyEventTemplateDetailsApi = {this.props.requestModifyEventTemplateDetailsApi}
            autoDistribute={() => this.autoDistribute(1)}  
            fundsData={this.state.fundsData}
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}            
            handleRebalanceAdjustment={this.props.handleRebalanceAdjustment}
            schedules={this.props.schedules}
            schedulesP2={this.props.schedulesP2}
            endDate={this.props.hypotheticalState.endPeriod}
            ctrAllFundsAllocationsP1 = {this.props.ctrAllFundsAllocationsP1}
            ctrAllFundsAllocationsP2 = {this.props.ctrAllFundsAllocationsP2}
            fundsPresentAmountList = {this.props.fundsPresentAmountList}
            currencySymbol={this.props.currencySymbol}
            userType={this.props.userType}
		/>
        <PortWithdrawalFlyout
            openEventFlyout={this.state.openPortWithdrawalEventFlyoutP2}
            closeEventFlyout={() => this.closeEventFlyout(2)}
            customStyles={CustomModalStyles.EventPortFlyoutModalStyles}
            selectedEventDate={
                this.editscheduleIdP2 != '' || this.modifyEventDateP2 > 0 ? utilityFunctions.formatOADate((parseInt(this.modifyEventDateP2) + 1), "MMM DD, YYYY") :
                (this.state.selectedEventDateP2!=  null ? utilityFunctions.formatOADate(utilityFunctions.convertToOADate(this.state.selectedEventDateP2), "MMM DD, YYYY")
                               :utilityFunctions.formatOADate(this.props.hypoStartPeriod, "MMM DD, YYYY"))
            }	
            selectedPortfolio={'Two'}
            selectedEventType = {'New ' + this.state.selectedEventTypeP2}
            hypotheticalState = {this.state.rebalanceData}
            requestSaveWithdrawalEventApi = {this.props.requestSaveWithdrawalEventApi}
            workingCopyConatainerId = {this.props.workingCopyConatainerIds.split(',')[1]}
            index = {1}
            calledSave={this.props.calledSave}
            editscheduleId={this.editscheduleIdP2}
            editFrequencyType={this.editFrequencyTypeP2}
            editDateBegin={this.modifyEventDateP2}
            editDateEnd={this.editDateEndP2}
            requestModifyEventTemplateDetailsApi = {this.props.requestModifyEventTemplateDetailsApi}
            autoDistribute={() => this.autoDistribute(2)}      
            fundsData={this.state.fundsData}       
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}            
            handleRebalanceAdjustment={this.props.handleRebalanceAdjustment}
            schedules={this.props.schedules}
            schedulesP2={this.props.schedulesP2}
            endDate={this.props.hypotheticalState.endPeriod}
            ctrAllFundsAllocationsP1 = {this.props.ctrAllFundsAllocationsP1}
            ctrAllFundsAllocationsP2 = {this.props.ctrAllFundsAllocationsP2}
            fundsPresentAmountList = {this.props.fundsPresentAmountList}
            currencySymbol={this.props.currencySymbol}
            userType={this.props.userType}
        />
        </div>
        );
    }
}

Hypotheticals.PropTypes = {
    calledSave: PropTypes.func,
    validateSameDayEvent: PropTypes.func,
    handleDeleteSchedule: PropTypes.func,
    handleDeleteInvestmentSchedule: PropTypes.func,
    contributionToRiskFinalAllocation: PropTypes.func,
    getFundsAmountByDate: PropTypes.func
  };
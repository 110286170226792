import { connect } from 'react-redux';

import * as actions from '../../actions/fundComparisonActions.js';
import * as portfolioActions from '../../actions/portfolioComparisonActions.js';

import ModuleSelection from './ModuleSelection';

import { reportTypes } from "../../utils/constants";

function mapStateToProps(state) {
	return {
		templateDataNew: state.fundComparisonReducer.templateDataset,
		pdfOutput:state.fundComparisonReducer.templateDataset.response.isPortfolio? 
		  state.portfolioComparisonReducer.pdfOutput : state.fundComparisonReducer.pdfOutputOnSelectModal ,
		saveTemplate: state.fundComparisonReducer.saveTemplate,
		TemplateId: state.fundComparisonReducer.TemplateId,
		TemplateName: state.fundComparisonReducer.TemplateName,
		validRollingReturnsPeriod: state.fundComparisonReducer.validRollingReturnsPeriod,
		validCMPeriod: state.fundComparisonReducer.validCMPeriod,
		errorCode: state.fundComparisonReducer.errorCode,
		portfolios: state.portfolioComparisonReducer.singlePortfolioTemplatesResult,
		comparisonPortfolios: state.portfolioComparisonReducer.portfolioComparisonTemplatesResult,
		fetching: state.fundComparisonReducer.fetching,
		benchmarks: state.fundComparisonReducer.benchmarksResults,
		saveComparisonPortfolioTemplateDetails: state.portfolioComparisonReducer.pvpSaveComparisonTemplateDetails,
		ComparisonPortfolioTemplateId: state.portfolioComparisonReducer.pvpComparisonTemplateId,
		ComparisonPortfolioTemplateName: state.portfolioComparisonReducer.pvpComparisonTemplateName,
		schedules: state.portfolioComparisonReducer.schedules,
		schedulesP2: state.portfolioComparisonReducer.schedulesP2,
		mfsSiteInactive: state.fundComparisonReducer.mfsSiteInactive,
		siteInactiveId: state.fundComparisonReducer.siteInactiveId,
		mfsUserData: state.fundComparisonReducer.mfsUserData,
		userType: state.fundComparisonReducer.userType,
		fundIds: state.fundComparisonReducer.xrefFund
	};
}

function mapDispatchToProps(dispatch) {
	return {
		requestGeneratePDFApi: inputParams => {
			return dispatch(
				inputParams.isPortfolioPdf?  portfolioActions.requestPortfolioComparisonPdf(inputParams)
						:actions.requestFundComparisonPdfonSelectModal(inputParams)
			);
		},
		requestSaveTemplateApi: templateData => {
			return dispatch(actions.requestSaveTemplateApi(templateData));
		},
		setTemplateData: result => {
			return dispatch(
				actions.setTemplateDataset(result));
		},
		setPvPTemplateData: result => {
			return dispatch(
				portfolioActions.setTemplateDataset(result));
		},
		updateTemplateInternalDetails: inputs => {
			return dispatch(actions.updateTemplateInternalDetails(inputs));
		},
		updatePortfolioTemplateInternalDetails: inputs => {
			return dispatch(portfolioActions.updatePortfolioTemplateInternalDetails(inputs));
		},
		requestGetTemplatesApi: (reportType,_templateId) => {
			if(reportType === reportTypes.singleportfolio) {
				return dispatch(portfolioActions.requestGetSinglePortfoioTemplatesApi(reportType));
			} else if(reportType === reportTypes.portfoliocomparison){
				return dispatch(portfolioActions.requestGetPortfolioComparisonTemplatesApi(reportType,_templateId));
			}else if(reportType === reportTypes.mil_singleportfolio){
				return dispatch(portfolioActions.requestGetSinglePortfoioTemplatesApi(reportType,_templateId));
			}else if(reportType === reportTypes.mil_portfoliocomparison){
				return dispatch(portfolioActions.requestGetPortfolioComparisonTemplatesApi(reportType,_templateId));
			}
		},
		requestSavePvPComparisonTemplateDetailsApi: templateData => {
			return dispatch(portfolioActions.requestSavePvPComparisonTemplateDetailsApi(templateData));
		},		
		requestGetEventSchedulesApi: (containerId) => {
		  return dispatch(portfolioActions.requestGetEventHypotheticalSchedulesApi(containerId))
		},
		requestGetEventSchedulesP2Api: (containerId) => {
		  return dispatch(portfolioActions.requestGetEventHypotheticalSchedulesP2Api(containerId))
		},
		requestMfsSiteInactiveFlag: inputs => {
			return dispatch(actions.requestMfsSiteInactiveFlag());
		},
		requestMfsUserData: () => {
			return dispatch(actions.requestMfsUserData());
		},
		setUserType: result => {
			return dispatch(
				actions.setUserType(result));
		},
		requestFundLevelIds: (xids) => {
			return dispatch(actions.requestXrefByXids(xids))
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleSelection);

import { connect } from 'react-redux';

import FundComparison from './FundComparison';

function mapStateToProps(state) {
	return {
		fundComparisonPdf: state.fundComparisonReducer.fundComparisonPdf
	};
}

export default connect(mapStateToProps)(FundComparison);

import React from 'react';
import { PropTypes } from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import * as utilityFunctions from "../../../utils/commonMethods";

import CompareButtonIcon from '../../common/Icons/CompareButtonIcon';
import FiveStarRatingIcon from '../../common/Icons/FiveStarRatingIcon';
import FourStarRatingIcon from '../../common/Icons/FourStarRatingIcon';
import ThreeStarRatingIcon from '../../common/Icons/ThreeStarRatingIcon';
import TwoStarRatingIcon from '../../common/Icons/TwoStarRatingIcon';
import OneStarRatingIcon from '../../common/Icons/OneStarRatingIcon';
import ModuleCheckIcon from "../../common/Icons/ModuleCheckIcon";

import screenerResultsTableStyles from '../../../styles/components/screenerResultsTable.scss';

export default class ScreenerResultsTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultText: ' ',
		};
	}

	addToBuilder = (cell, row) => {
		const html = (
			<div
				className={'add-icon'}
				onClick={this.props.addFundsToFundResultsTable}
				// eslint-disable-next-line no-nested-ternary
				data-flag={!this.props.isPortfolio ? row.MSDFundFamilyName === 'MFS' ? 'MFS' : (row.MSDFundFamilyName === 'Benchmark'
					? 'Benchmark' : 'OtherFund'):'OtherFund'}
				data-symbol={row.MFSSymbol}
				data-wsodIssue={row.MSDWSODIssue}
				data-isActive={row.isActive}
			>
				{row.isActive ? <ModuleCheckIcon /> : <CompareButtonIcon />}
			</div >
		);

		return html;
	}


	overallRatingColumn = (cell, row) => {
		let _customComponent = null;
		switch (cell) {
			case 5:
				_customComponent = <FiveStarRatingIcon />;
				break;

			case 4:
				_customComponent = <FourStarRatingIcon />;
				break;

			case 3:
				_customComponent = <ThreeStarRatingIcon />;
				break;

			case 2:
				_customComponent = <TwoStarRatingIcon />;
				break;

			case 1:
				_customComponent = <OneStarRatingIcon />;
				break;

			default:
				_customComponent = '--';
				break;

		}

		const html = (
			<div
				className={_customComponent === '--' ? '' : 'rating-star'}
			>
				{_customComponent}
			</div>
		);
		return html;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.screenerData &&
			nextProps.screenerData.rows
		) {
			this.setState({ defaultText: 'No results found, please search again!' });
		} else {
			let emptyDataText = 'Please use filter criteria to find funds and ETFs';
			this.setState({ defaultText: emptyDataText });
		}
	}

	componentDidMount() {
		if (
			this.props.screenerData &&
			this.props.screenerData.rows
		) {
			this.setState({ defaultText: 'No results found, please search again!' });
		} else {
			let emptyDataText = 'Please use filter criteria to find funds and ETFs';
			this.setState({ defaultText: emptyDataText });
		}
	}

	columnFormat(fieldValue, row, rowIdx, colIdx) {
		// fieldValue is column value
		// row is whole row object
		// rowIdx is index of row
		// colIdx is index of column
		switch (colIdx) {
			case 5:
				return 'fund-name-width';
			case 4:
				return 'result-table-star-rating';
			case 3:
				return 'default-column-width';
			case 2: //fund name column
				return 'fund-name-width';
			case 1:
				return 'default-column-width';
			case 0:
				return 'tdcompareStyle';

			default: return 'result-table-columnHeader-format'
		}
	}

	dateFormatter(msDate) {
		if (!msDate) {
			return "--";
		}
		return utilityFunctions.convertFromOADate(msDate);
	}

	render() {
		const data = this.props.screenerData && this.props.screenerData.rows;
		const _thStyle = { 'vertical-align': 'top' };

		return (
			<div>
				<div className={'tableData'}>
					<BootstrapTable
						data={this.props.resetScreenerFilters ? [] : data}
						options={{
							noDataText:
								this.state.defaultText
						}}
						containerClass='screenerResultsTableStyles'
					>
						<TableHeaderColumn
							isKey
							dataField="isActive"
							dataFormat={this.addToBuilder}
							columnClassName={this.columnFormat}
							width="10%"
						>
							Add to Builder
						</TableHeaderColumn>
						<TableHeaderColumn dataField="MFSSymbol" width="10%"
							thStyle={_thStyle}
						>
							Symbol
						</TableHeaderColumn>
						<TableHeaderColumn dataField="MSDFundName" width="25%"
							columnClassName={this.columnFormat}
							thStyle={_thStyle}
						>
							Fund Name
						</TableHeaderColumn>
						<TableHeaderColumn dataField="MSDInceptionDate" width="15%"
							dataFormat={this.dateFormatter}
							thStyle={_thStyle}
						>
							Inception <br />Date
						</TableHeaderColumn>
						<TableHeaderColumn dataField="MSDMorningstarOverallRating"
							dataFormat={this.overallRatingColumn}
							width="15%"
							thStyle={_thStyle}
						>
							Morningstar <br />Overall Rating
						</TableHeaderColumn>
						<TableHeaderColumn dataField="MSDMorningstarCategoryName" width="25%"
							columnClassName={this.columnFormat}
							thStyle={_thStyle}
						>
							Morningstar < br /> Category
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
			</div >
		);
	}
}

ScreenerResultsTable.PropTypes = {
	AddFundToLeftRail: PropTypes.func,
	fundtype: PropTypes.string,
	screenerData: PropTypes.object,
	resetScreenerFilters: PropTypes.boolean,
	isPortfolio: PropTypes.bool,
};
import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';

import genericModalStyles from '../../../styles/components/modal/SaveChangesAlertModal.scss';

class HypoChangesAlertModal extends React.PureComponent {
	render() {
		return (
			<Modal shouldCloseOnOverlayClick shouldCloseOnEsc
				isOpen={this.props.isAlertModalOpen} style={this.props.hypoAlertModalStyles} >
				<div className={'SaveChangesAlertModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'header-text'}>Alert</div>
							</div>
						</div>
						<div className={'lbl-alert-template'}> Are you sure you want to leave? All unsaved changes will be lost.</div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<button onClick={this.props.retainComparisonModal} className={'alert-cancel-button'}>CANCEL</button>
								<button onClick={this.props.redirectToLandingPage} className={'alert-save-button'}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>

		);
	}
}

HypoChangesAlertModal.propTypes = {
	isAlertModalOpen: PropTypes.bool,
	hypoAlertModalStyles: PropTypes.object,
	redirectToLandingPage: PropTypes.func,	
	retainComparisonModal: PropTypes.func
};

export default HypoChangesAlertModal;


import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';
import Select, { components } from 'react-select';

import moment from "moment";

import * as Constant from '../../../utils/constants';
import * as utilityFunctions from "../../../utils/constants";
import * as utilityMethods from "../../../utils/commonMethods";

import BackArrowIcon from '../../common/Icons/BackArrowIcon';
import EventTableDetails from './EventTableDetails';

import ResultSetTooLargeIcon from '../../common/Icons/ResultSetTooLargeIcon';
import HorizontalIcon from '../../common/Icons/HorizontalIcon';

import screenerFlyoutStyles from '../../../styles/components/screenerFlyout.scss';

export default class EventFlyout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedFrequencyType: "Monthly",
			disableSaveEventButton:false
		};
	}

	resetTab = () => {
		this.props.closeEventFlyout();
	}

	closeFlyout = () => {
		this.props.closeEventFlyout();
	}

	onSelectedFrequencyType = (e) => {
		let value= document.getElementById('percentageTotal').getAttribute('data-percentageTotal');
		this.validatePercentValueTotal(value);
	}

	validatePercentValueTotal = (value) => {
		if(document.getElementsByClassName('event-save-button')[0]) {
		if((value !== "100" && value !== "100.0000") || document.getElementById("selectFrequency").childNodes[1].value == ''){
		document.getElementsByClassName('event-save-button')[0].style.opacity='0.4'
		document.getElementsByClassName('event-save-button')[0].style['pointer-events']='none'
		} else{
			document.getElementsByClassName('event-save-button')[0].style.opacity='1'
			document.getElementsByClassName('event-save-button')[0].style['pointer-events']='all'
		}
	}
	}

	saveEventsHypotheticalState = (hypoState) => {
		this.hypotheticalSaveEventState = hypoState;		
	}
	setAllocations() {
		let fund = document.getElementsByClassName('portfolio-invested text-right event-set-amount')
		const dictSymbolWeightage = {};
		for(let count=0;count < fund.length; count++) {
			dictSymbolWeightage[fund[count].getAttribute('data-symbol')] =  parseFloat((parseFloat(fund[count].getAttribute('data-raw-value'))*.01).toFixed(4));
		}
		return dictSymbolWeightage;
	}
	saveEvent = () => {
		let data= this.props.hypotheticalState;
		let schedules ={}
		let investmentsWithAllocations = [];
		schedules.dateBegin = utilityMethods.convertToOADate(this.props.selectedEventDate);
		schedules.dateEnd =data.endPeriod;
		schedules.frequency = document.getElementById("selectFrequency").childNodes[1].value;
		schedules.snapPeriods = "End";
		schedules.includeFees = false;
		this.dictSymbolWeightage = this.setAllocations();
		data.portfolios[this.props.index].funds.forEach(item => {
			investmentsWithAllocations.push({
				allocation: this.dictSymbolWeightage[item.symbol] == undefined ? 0 : this.dictSymbolWeightage[item.symbol],
				investmentId: item.investmentId
			})
		})
		schedules.investmentsWithAllocations = investmentsWithAllocations;
		
		this.props.requestSaveEventTemplateDetailsApi(this.props.index, this.props.workingCopyConatainerId, schedules, '');
		this.props.calledSave(this.props.selectedPortfolio);
		this.props.closeEventFlyout();
	}

	editEvent = (e) => {
		let portfoliotype = e.target.getAttribute('data-portfoliotype');
		let data = this.props.hypotheticalState;
		let schedules = {}
		let investmentsWithAllocations = [];
		schedules.id = this.props.editscheduleId;
		schedules.dateBegin = this.props.editDateBegin;
		schedules.dateEnd =this.props.editDateEnd;
		schedules.frequency = document.getElementById("selectFrequency").childNodes[1].value;
		schedules.snapPeriods = "End";
		schedules.includeFees = false;
		this.dictSymbolWeightage = this.setAllocations();
		data.portfolios[this.props.index].funds.forEach(item => {
			investmentsWithAllocations.push({
				allocation: this.dictSymbolWeightage[item.symbol] == undefined ? 0 : this.dictSymbolWeightage[item.symbol],
				investmentId: item.investmentId
			})
		});		
		schedules.investmentsWithAllocations = investmentsWithAllocations;

		this.props.requestModifyEventTemplateDetailsApi(schedules,this.props.workingCopyConatainerId,portfoliotype);
		this.props.calledSave(this.props.selectedPortfolio);
		this.props.closeEventFlyout();
	}

	render() {
		const DropdownIndicator = props => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <i
                            className="dropdown"
                            aria-hidden="true"
                        />
                    </components.DropdownIndicator>
                )
            );
		};
		
		return (
			<Modal
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				isOpen={this.props.openEventFlyout}
				style={this.props.customStyles}
				onRequestClose={this.closeFlyout}
			>
				<div className={'screenerflyout-styles event-flyout'}>
					<span className={'event-flyout-header'}>
						<span className={'screener-flyout-title-backArrow'} onClick={this.closeFlyout} ><BackArrowIcon /></span>
						<span className={'screener-flyout-title'}>Rebalance Portfolio {this.props.selectedPortfolio} on {this.props.selectedEventDate!='' ? <span className={'event-selected-date'}> {(this.props.editscheduleId == '') ? 
						((this.props.userType==='International')? utilityMethods.formatOADate(utilityMethods.convertToOADate(this.props.selectedEventDate), "DD MMM, YYYY"):this.props.selectedEventDate) :((this.props.userType==='International')? utilityMethods.formatOADate((utilityMethods.convertToOADate(this.props.selectedEventDate) + 1), "DD MMM, YYYY"): utilityMethods.formatOADate((utilityMethods.convertToOADate(this.props.selectedEventDate) + 1), "MMM DD, YYYY"))} </span>: ''}</span>
					<span className={'span-save-button'}>
                                <button
									onClick={this.props.editscheduleId == '' ? this.saveEvent : this.editEvent}
                                    data-portfoliotype={this.props.selectedPortfolio}
									className={'event-save-button'}
                                >
                                    SAVE EVENT
								</button>
                           
                    </span>
					</span>
					
					<span className={'screener-flyout-horizontal'} >
						<div className={'filter-styles event-filter-styles'}>
							<div className={'label-frequency'}>Frequency </div>
							<div className="label-style event-label-style">
								<Select
									name="select-time-period\"
									className="dropdown-mfs"
									theme={utilityFunctions.customTheme}
									styles={utilityFunctions.customStyles}
									isSearchable={false}									
									defaultValue={this.props.editFrequencyType}
									onChange={this.onSelectedFrequencyType}
									options={utilityFunctions.frequencyTypes}
									components={{ DropdownIndicator }}
									id="selectFrequency" />
							</div>
						</div>	
					</span>
					<div className={'filter-styles'}>
						<div className={'portfolio-investment2 event-table-details'}>
							{this.props.hypotheticalState.portfolios && this.props.hypotheticalState.portfolios.length > 0 &&
								<EventTableDetails
								updateHypothicalStateFundValuesPercents={this.props.updateHypothicalStateFundValuesPercents}
								index={this.props.selectedPortfolio == 'One' ? 0 : 1}
								hypotheticalState={this.props.hypotheticalState}
								validatePercentValueTotal={this.validatePercentValueTotal}
								selectedEventDate = {this.props.selectedEventDate}
								saveEventsHypotheticalState = {this.saveEventsHypotheticalState}
								autoDistribute={this.props.autoDistribute}
								edit={this.props.editscheduleId != ''}
								includeRebalance= {true}
								userType={this.props.userType}
								/>}
           				</div>
					</div>

				</div>
			</Modal>)
	}
}

EventFlyout.propTypes = {
	openEventFlyout: PropTypes.bool,
	customStyles: PropTypes.object,
	closeEventFlyout: PropTypes.func,
	calledSave: PropTypes.func,
	autoDistribute: PropTypes.func
};

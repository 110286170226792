import { connect } from 'react-redux';

import * as mfsActions from '../../actions/fundComparisonActions.js';
import * as portfolioActions from '../../actions/portfolioComparisonActions.js';

import SelectFundsModal from './SelectFundsModal';


function mapStateToProps(state) {
	return {
		saveTemplateDetails: state.fundComparisonReducer.saveTemplateDetails,
		screenerOutput: state.fundComparisonReducer.screenerData,
		pdfOutput: state.fundComparisonReducer.pdfOutputOnSelectModal,
		symbolSearchResults: state.fundComparisonReducer.symbolSearchResults,
		wsodIssue: state.fundComparisonReducer.wsodIssue,
		benchmarks: state.fundComparisonReducer.benchmarksResults,
		primaryBenchmark: state.fundComparisonReducer.primaryBenchmarksResult,
		screenerMasterData: state.fundComparisonReducer.screenerMasterData,
		validRollingReturnsPeriod: state.fundComparisonReducer.validRollingReturnsPeriod,
		TemplateId: state.fundComparisonReducer.TemplateId,
		TemplateName: state.fundComparisonReducer.TemplateName,
		template: state.fundComparisonReducer.templateResult,
		screenerFiltersOutput: state.fundComparisonReducer.screenerFiltersOutput,
		mfsSiteInactive: state.fundComparisonReducer.mfsSiteInactive,
		siteInactiveId: state.fundComparisonReducer.siteInactiveId,
		userType: state.fundComparisonReducer.userType,
        fundNamesFromScreenerData: state.fundComparisonReducer.screenerDataFundName,
		xidFundShareClassMappings: state.portfolioComparisonReducer.xidFundShareClassMappings,
		xref: state.fundComparisonReducer.xref,
		xrefETF: state.fundComparisonReducer.xrefETF,
		mfsPrimaryBenchmark: state.fundComparisonReducer.mfsBenchmarksResults,
		oldestInceptionDates: state.portfolioComparisonReducer.oldestInceptionDates,
		performanceAsOfScreenerData: state.portfolioComparisonReducer.performanceAsOfScreenerData,
		screenerDataMstarCategory: state.fundComparisonReducer.screenerDataMstarCategory
	};
}

function mapDispatchToProps(dispatch) {
	return {
		requestSaveTemplateDetailsApi: templateData => {
			return dispatch(mfsActions.requestSaveTemplateDetailsApi(templateData));
		},
		requestScreenerDataApi: (screenerType, screenerCriteria, isInternationalUser, currency) => {
			return dispatch(
				mfsActions.requestScreenerDataApi(screenerType, screenerCriteria, isInternationalUser, currency)
			);
		},
		requestScreenerDataFundNameApi: (screenerType, screenerCriteria, isInternationalUser, currency) => {
			return dispatch(
				mfsActions.requestScreenerDataFundNameApi(screenerType, screenerCriteria, isInternationalUser, currency)
			);
		},
		requestGeneratePDFApi: inputParams => {
			return dispatch(
				mfsActions.requestFundComparisonPdfonSelectModal(inputParams)
			);
		},
		requestSymbolSearchApi: (input, fundType) => {
			return dispatch(mfsActions.requestSymbolSearchApi(input, fundType));
		},
		requestDecodeApi: key => {
			return dispatch(mfsActions.requestDecodeApi(key));
		},
		requestGetBenchmarksApi: (input, _isMFSInternational, _currency) => {
			return dispatch(mfsActions.requestGetBenchmarksApi(input, _isMFSInternational, _currency));
		},
		requestGetPrimaryBenchmarkApi: (input, _isMFSInternational, _currency) => {
			return dispatch(mfsActions.requestGetPrimaryBenchmarkApi(input, _isMFSInternational, _currency));
		},
		requestGetScreenerMasterDataApi: (fieldname, type, isInternationalUser) => {
			return dispatch(
				mfsActions.requestGetScreenerMasterDataApi(fieldname, type, isInternationalUser));
		},
		setTemplateData: result => {
			return dispatch(
				mfsActions.setTemplateDataset(result));
		},
		updateTemplateInternalDetails: inputs => {
			return dispatch(mfsActions.updateTemplateInternalDetails(inputs));
		},
		requestValidRollingReturnPeriodDataApi: (screenerCriteria, isInternationalUser, fundShareClassIds, benchmarkWsodIssue) => {
			return dispatch(
				mfsActions.requestValidRollingReturnPeriodDataApi(screenerCriteria, isInternationalUser, fundShareClassIds, benchmarkWsodIssue));
		},
		requestValidCMPeriodDataApi: (screenerCriteria, isInternationalUser, fundShareClassIds, benchmarkWsodIssue) => {
			return dispatch(
				mfsActions.requestValidCMPeriodDataApi(screenerCriteria, isInternationalUser, fundShareClassIds, benchmarkWsodIssue));
		},
		resetScreenerFiltersOutput: () => {
			return dispatch(
				mfsActions.resetScreenerFiltersOutput());
		},
		requestScreenerFiltersDataApi: (screenerType, screenerCriteria, isInternationalUser) => {
			return dispatch(
				mfsActions.requestScreenerFiltersDataApi(screenerType, screenerCriteria, isInternationalUser)
			);
		},
		requestMfsSiteInactiveFlag: inputs => {
			return dispatch(mfsActions.requestMfsSiteInactiveFlag());
        },
        requestXrefByTerm: (term) => {
            return dispatch(mfsActions.requestXrefByTerm(term))
		},
		requestInternationalXidByFundShareClassId: (fundShareClassIds, currency) => {
			return dispatch(
				portfolioActions.requestGetInternationalXidByFundShareClaasIdApi(fundShareClassIds, currency)
			);
        },
        requestXrefByTermETF: (term) => {
            return dispatch(mfsActions.requestXrefByTermETF(term))
		},
		requestGetMfsPrimaryBenchmarkApi: (input, _isMFSInternational, _currency) => {
			return dispatch(mfsActions.requestGetMfsPrimaryBenchmarkApi(input, _isMFSInternational, _currency));
		},
		requestOldestInceptionDatesApi: (wsodIssue, isInternational) => {
			return dispatch(portfolioActions.requestOldestInceptionDatesApi(wsodIssue, isInternational));
		},
		requestPerformanceAsOfScreenerDataApi: (screenerType, screenerCriteria, isInternationalUser) => {
			return dispatch(
				portfolioActions.requestPerformanceAsOfScreenerDataApi(screenerType, screenerCriteria, isInternationalUser)
			);
		},
		requestScreenerMstarCategoryDataApi: (msdMorningstarCategoryName, currency) => {
			return dispatch(
				mfsActions.requestScreenerMstarCategoryDataApi(msdMorningstarCategoryName, currency)
			);
		},
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SelectFundsModal);

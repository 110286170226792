import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';

import genericModalStyles from '../../../styles/components/modal/validationModalTabs.scss';

class ValidationModalTabs extends React.PureComponent {
	render() {
		return (
			<Modal
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				isOpen={this.props.validationModalIsOpenTabs}
				style={this.props.customStyles}
			>
                <div className={'validationModal'}>
                    <div className={'main-container'}>
                        <div className={'headerContainer'}>
                            <div className={'header'}>
                                <div className={'header-text'}>Alert(s)</div>
                            </div>
                        </div>
                        <div className={'validationMainModal'}>
                            <div className={'div-errormessage'}>
							<ul>
							{this.props.tabErrors.map(item=> {
								return <li>{item}</li>
							})}
							</ul>
                            </div>
                        </div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								
								<button
									onClick={this.props.closeValidationModalTabs}
									className={'save-button'}
								>
									OK
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

ValidationModalTabs.propTypes = {
	validationModalIsOpenTabs: PropTypes.bool,
	customStyles: PropTypes.object,
    closeValidationModalTabs: PropTypes.func,
    tabErrors: PropTypes.array,
};

export default ValidationModalTabs;

/* eslint-disable guard-for-in */
import "babel-polyfill";
import React from "react";
import { Link } from "react-router";
import { PropTypes } from 'prop-types';

import cloneDeep from "lodash/cloneDeep";

import Header from "../common/Header";
import LogoutModal from "../common/modal/LogoutModal";
import headerStyles from "../../styles/components/Dashboard.scss";
import DownloadingSpinnerModal from "../common/modal/DownloadingSpinnerModal";

import * as Format from "../../utils/format";
import * as urlMethods from "../../utils/urlMethods";
import * as Constant from "../../utils/constants";
import * as UsageReporting from "../../utils/usageReporting";
import * as CustomModalStyles from '../../utils/customModalStyles';

import PvPCover from '../../images/PvPCover.png';
import FvFCover from '../../images/FvFCover.png';
import * as Constants from "../../utils/constants";
import {getToken} from '../../utils/helpers'

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadSpinnerModalIsOpen: false,
      erroMessage: "",
      isUserLoggedOut: false
    };
  }

  UNSAFE_componentWillMount() {
    let _templateData = cloneDeep(Constant.templateData);
    this.setState({
      templateData: _templateData
    });
    try {
      UsageReporting.trackPage("Home Page", "");
    } catch (error) { }
  }
  callMaintenacePage = () => {
    this.props.router.push('/MaintenancePage');
  }
  componentDidMount() {
    this.props.requestMfsSiteInactiveFlag();
    this.props.requestMfsUserData();       
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.siteInactiveId !== nextProps.siteInactiveId && nextProps.siteInactiveId  > 0) {
      if (nextProps.mfsSiteInactive === true) {
        this.props.router.push('/MaintenancePage');
      }      
    }

    if (this.props.errorCode != nextProps.errorCode && nextProps.errorCode) {
      this.props.router.push({
        pathname: '/Logout',
        search: '?logoutError=true'
      });
    }
  }


  formatDate(cell, row) {
    return Format.dateMonthDateYear(cell);
  }

  logOut = () => {
    this.setState({
      isUserLoggedOut: true
    });
  }

  logoutUser = () => {
    localStorage.setItem("logout-token", localStorage.getItem("access_token"));
    if (document.querySelector("meta[name='access_token']")) {
      document.querySelector("meta[name='access_token']").value = "";
    }
    localStorage.removeItem("access_token");
    localStorage.setItem("loggedOut", true);
    const url = urlMethods.getEnvironment();
    const a = document.createElement("a");
    a.target = "_self";
    a.href = url;
    document.body.appendChild(a);
    a.click();
  }

  closeLogOutModal() {
    this.setState({
      isUserLoggedOut: false
    });
  }

  onClickInternational = () => {
    this.props.setUserType("International");
  }

  onClickNonInternational = () => {
    this.props.setUserType("");
  }

  resetComparePortfolios = () => {}

  render() {
    return (
      <div className={"dashboardPage"}>
        <Header 
        resetComparePortfolios={this.resetComparePortfolios} 
        onClickInternational={this.onClickInternational} 
        onClickNonInternational={this.onClickNonInternational} 
        logOut={this.logOut} activeTab={0}
         mfsUserData={this.props.mfsUserData} 
         mfsUSFvFinactive = {this.props.mfsUSFvFinactive} 
         mfsUSPvPinactive = {this.props.mfsUSPvPinactive} 
         mfsMILFvFinactive = {this.props.mfsMILFvFinactive}
         mfsMILPvPinactive = {this.props.mfsMILPvPinactive}/>
        <LogoutModal
          isUserLoggedOut={this.state.isUserLoggedOut}
          customStyles={CustomModalStyles.LogOutModalStyles}
          logoutUser={this.logoutUser}
          closeLogOutModal={this.closeLogOutModal}
        />
        <div className={"body"}>
          <div className={"bodyContainer"}>
            <div className={"sub-HeaderModule"}>
              <label className={"header-text"}>My Tools</label>
              <DownloadingSpinnerModal
                downloadSpinnerModalIsOpen={
                  this.state.downloadSpinnerModalIsOpen
                }
              />
            </div>
          </div>
        </div>

        <div className={"tableData tableStyle"}>

          {/* Fund Compare Tool */}
          {/* Only admin and USA user can see below link */}
          {this.props.mfsUserData && !this.props.mfsUSFvFinactive && (this.props.mfsUserData.userTier == 4 || this.props.mfsUserData.userTier == 6) &&
            <Link to="/home" className={'header-link'} onClick={this.onClickNonInternational}>
              <div className={"cover-block-1"}>
                <div className={"cover-image-div"}>
                  <img src={FvFCover} className={"cover-image"}></img>
                </div>
                <div className={"cover-data"}>
                  <div className={"cover-name"}>Fund Compare Tool</div>
                </div>
              </div>
            </Link>
            }
  
            {/* Portfolio Compare Tool */}
            {/* Only admin and USA user can see below link */}
            {this.props.mfsUserData && !this.props.mfsUSPvPinactive && (this.props.mfsUserData.userTier == 4 || this.props.mfsUserData.userTier == 6) && 
            <Link to="/portfolio" className={'header-link'} onClick={this.onClickNonInternational}>
              <div className={"cover-block-2"}>
                <div className={"cover-image-div"}>
                  <img src={PvPCover} className={"cover-image"}></img>
                </div>
                <div className={"cover-data"}>
                  <div className={"cover-name"}>Portfolio Compare Tool</div>
                </div>
              </div>
            </Link>
            }
  
            {/* International Fund Compare Tool */}
            {/* Only admin and inernaitonal user can see below link */}
            {this.props.mfsUserData && !this.props.mfsMILFvFinactive && (this.props.mfsUserData.userTier == 5 || this.props.mfsUserData.userTier == 6) &&
            <Link to="/FundInternational" className={'header-link'} onClick={this.onClickInternational}>
              <div className={"cover-block-1"}>
                <div className={"cover-image-div"}>
                  <img src={PvPCover} className={"cover-image"}></img>
                </div>
                <div className={"cover-data"}>
                  <div className={"cover-name"}>International Fund Compare Tool</div>
                </div>
              </div>
            </Link>
            }
  
            {/* International Portfolio Compare Tool */}
            {/* Only admin and inernaitonal user can see below link */}
            {this.props.mfsUserData && !this.props.mfsMILPvPinactive && (this.props.mfsUserData.userTier == 5 || this.props.mfsUserData.userTier == 6) &&
            <Link to="/PortfolioInternational" className={'header-link'} onClick={this.onClickInternational}>
            <div className={"cover-block-2"}>
              <div className={"cover-image-div"}>
                <img src={PvPCover} className={"cover-image"}></img>
              </div>
              <div className={"cover-data"}>
                <div className={"cover-name"}>International Portfolio Compare Tool</div>
              </div>
            </div>
          </Link>
            }

        </div>
        <div className={"powered-text"}>powered by <span>IRIS<sup>&reg;</sup></span></div>
      </div>
    );
  }
}

export default Dashboard;

/**
* appConstants.js defines constant values used throughout the react app
*/

export const CONFIG_SPINNER_DEFAULT = {
  className: 'ihs-markit-spinner',
  color: '#000',
  corners: 1,
  direction: 1,
  fps: 20,
  hwaccel: false,
  left: '50%',
  length: 8,
  lines: 12,
  opacity: 0.25,
  position: 'absolute',
  radius: 8,
  rotate: 0,
  scale: 1.00,
  shadow: false,
  speed: 1,
  top: '50%',
  trail: 60,
  width: 2,
  zIndex: 2e9
}

export const CONFIG_CONTENT_COMMON_DATA_REQUEST_DEFAULT = {
  spinnerConfig: CONFIG_SPINNER_DEFAULT
}

export const RESPONSE_BAD_REQUEST = {
  status: 400,
  statusText: 'Bad Request'
}

export const STATE_COMMON_DATA_REQUEST_DEFAULT = {
  data: {},
  dataRequestError: {},
  isDataRequestComplete: false
}

export const TEXT_ERROR = 'An Error Has Occurred'
export const TEXT_NO_DATA_AVAILABLE = 'No Data Available'

import React from 'react';
import { PropTypes } from 'prop-types';

import numeral from 'numeral';

import * as utilityFunctions from "../../../utils/commonMethods";

import cloneDeep from 'lodash/cloneDeep';

import portfolioInvestmentStyle from '../../../styles/components/portfolio/PortfolioInvestmentTable.scss';

export default class EventTableDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      InvestmentType: 1, // 1: dollar ($), 2: Percentage (%)
      symbolsWithZeroAllocation:[],
      symbolsWithZeroAllocationStatic:[]
    };
    this.symbolsWithZeroAllocation= [];
    this.symbolsWithZeroAllocationStatic= [];
  }

  dateFormatter(msDate, format) {
    if (!msDate) {
      return "--";
    }
    if (format) {
      return utilityFunctions.formatOADate(msDate, format);
    } else {
      return utilityFunctions.convertFromOADate(msDate);
    }
  }

  dateFormatterIntl(msDate, format) {
    if (!msDate) {
      return "--";
    }    
    if (format) {
      return utilityFunctions.formatOADate(msDate, format);
    } else {
      return utilityFunctions.convertFromOAIntlDate(msDate);
    }
  }  

  componentDidMount() {
    this.populateFundValues();
    let sortData = function(a, b) {
      const arrProp = ['amountInvestedPercent', 'name'];
    
      for (let index = 0; index < arrProp.length; index++) {
        if (a[arrProp[index]] < b[arrProp[index]]) {
          return -1;
        }
        if (a[arrProp[index]] > b[arrProp[index]]) {
          return 1;
        }
      }
      
      return 0;
    };
    this.props.hypotheticalState.portfolios[this.props.index].funds.sort(sortData);
    this.props.hypotheticalState.portfolios[this.props.index].funds.map(item=>{
      if(item.amountInvestedPercent <= 0){
        this.state.symbolsWithZeroAllocationStatic.push(item.symbol)
      }
    })
    this.setState({
      eventsHypotheticalState :  this.props.hypotheticalState
    })
  }

  componentDidUpdate() {
    this.populateFundValues();
    if (document.getElementById("selectFrequency")!=null && document.getElementById("selectFrequency").childNodes[1].value == '' && 
    this.props.includeRebalance === true){
			document.getElementsByClassName('event-save-button')[0].style.opacity='0.4'
			document.getElementsByClassName('event-save-button')[0].style['pointer-events']='none'
		}
  }

  // Populate fund dollars textbox from original values
  populateFundValues = () => {
    let tables = document.querySelectorAll(".portfolio-table");
    let that = this; //  because of forEach loop
    tables.forEach(function (table) {
      let rowsCount = table.rows.length;
      for (let i = 0; i < rowsCount; i++) {
        let row = table.rows[i];
        for (let j = 0; j < row.cells.length; j++) {
          if (j == 2 || j == 3) {
            let cell = row.cells[j];
            let element = cell.children[0];
            if (element && element.tagName === 'DIV') {
              let element1 = element.children[0];
              if (element1 && element1.tagName === 'INPUT' && element1.type === 'text') {
                let rawValue = element1.getAttribute('data-raw-value');
                element1.value = that.formatInvestmentDollar(parseFloat(rawValue), '.00');
              }
            }
          }
        }
      }
    });
  }

  UNSAFE_componentWillReceiveProps(){
    let sortData = function(a, b) {
      const arrProp = ['amountInvestedPercent', 'name'];
    
      for (let index = 0; index < arrProp.length; index++) {
        if (a[arrProp[index]] < b[arrProp[index]]) {
          return -1;
        }
        if (a[arrProp[index]] > b[arrProp[index]]) {
          return 1;
        }
      }
      
      return 0;
    };
    let funds = this.props.hypotheticalState.portfolios[this.props.index].funds;
    this.props.hypotheticalState.portfolios[this.props.index].funds = funds.sort(sortData);
    
    this.setState({
      eventsHypotheticalState :  this.props.hypotheticalState
    })
  }

  /**
   * Format the number to be shown with decimal
   * @param {Number} dollarAmount for example, 1234567.89
   * @param {string} decimalPlaces for example, '.00' for 2 decimal places
   */
  formatInvestmentDollar = (dollarAmount, decimalPlaces) => {
    return numeral(dollarAmount).format('0,0' + decimalPlaces);
  }

  // Returns object having total of dollars and percents { Dollar, Percentage }
  getFundValuesTotal = () => {

    let dollar = 0;
    let percentage = 0;

    this.state.eventsHypotheticalState && this.state.eventsHypotheticalState.portfolios[this.props.index].funds.forEach(item => {
      if (utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) <= (this.props.edit ? (utilityFunctions.convertToOADate(this.props.selectedEventDate)+ 1 ) : utilityFunctions.convertToOADate(this.props.selectedEventDate))) {
        dollar = parseFloat(parseFloat(dollar) + parseFloat(item.amount)).toFixed(2); 
        percentage = parseFloat(parseFloat(percentage) + ((parseFloat(item.amount) / parseFloat(this.state.eventsHypotheticalState.totalInvestment)) * 100));
      }
    });
    
    this.props.validatePercentValueTotal(percentage.toFixed(4).toString())

    return {
      Dollar: dollar,
      Percentage: parseFloat(percentage.toFixed(10))
    };
  }

  // Returns true if user has key press numbers
  isNumberKeyPressed = (e) => {
    let result = false;
    let keyCode = e.which;
    if (e.type == 'blur' || ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105))) {
      result = true;
    }
    return result;
  }

  // When user select either '$' or '%'
  onClickInvestmentType = (e) => {
    let InvestmentType = e.target.value;
    this.setState({
      InvestmentType: InvestmentType  // 1: dollar ($), 2: Percentage (%)
    })
  }

  // When user focus out from '$' textbox
  onBlurInvestmentDollar = (e) => {
    if (this.isNumberKeyPressed(e)) {
     let portfolioIndex = this.props.index;
     let value = e.target.value !=="" ? e.target.value : "0";
     if (parseFloat(value) == 0 ) {
      e.target.style.borderColor = '#BA0C30';
     } else{
      e.target.style.borderColor = '#000000';
     }
    }
  }

  // When user focus out from '%' textbox
  onBlurInvestmentPercent = (e) => {
    if (this.isNumberKeyPressed(e)) {
      let value = e.target.value !=="" ? e.target.value : "0";
      let portfolioIndex = this.props.index;
      let fundSymbol = e.target.getAttribute('data-symbol');
     if (parseFloat(value) == 0 ) {
      if( this.symbolsWithZeroAllocation.indexOf(fundSymbol) == -1){
        this.symbolsWithZeroAllocation.push(fundSymbol)
      }
     } else{
      this.symbolsWithZeroAllocation =this.symbolsWithZeroAllocation.filter(function(element){
          return element != fundSymbol;
      });
     }
      this.setState({
          symbolsWithZeroAllocation:this.symbolsWithZeroAllocation
        })
     this.updateHypothicalStateFundValuesPercents(e, portfolioIndex, true);
    }
  }

  /**
	 * [MFS-1551]: 
	 * @param {EventSource} evt 
	 * @param {Number} portfolioIndex 
	 * @param {Boolean} isPercent 
	 */
	updateHypothicalStateFundValuesPercents(evt, portfolioIndex, isPercent) {
		let value = evt.target.value !=="" ? evt.target.value : "0";
		// Percent Change
		if (isPercent) {
			let fundPercent = parseFloat(value);
			let fundSymbol = evt.target.getAttribute('data-symbol');
			if (fundSymbol && portfolioIndex > -1) {
				let hypotheticalState = cloneDeep(this.state.eventsHypotheticalState);
				hypotheticalState.isFirstTime = false;
				hypotheticalState.portfolios[portfolioIndex].funds.forEach((element) => {
					if (element.symbol == fundSymbol) {
            element.amountInvestedPercent = parseFloat((fundPercent/100));
          } else {
            element.amountInvestedPercent = parseFloat(element.amountInvestedPercent).toFixed(4);
          }
          element.amount = parseFloat(element.amountInvestedPercent) * parseFloat(hypotheticalState.totalInvestment);
				})
				this.setState({
					eventsHypotheticalState: hypotheticalState,
				},()=>  this.props.saveEventsHypotheticalState(this.state.eventsHypotheticalState))
      }
    }
  }
  floorFigure(figure, decimals){
    if (!decimals) decimals = 2;
    let d = Math.pow(10,decimals);
    return (parseInt(figure*d)/d).toFixed(decimals);
  }
  render() {
    //let isFirstTime = this.props.isFirstTime === true;
    let total = this.getFundValuesTotal();
    let classValidFundValuesPercents = (total.Percentage !== 100) ? 'fund-error' : '';
    if (this.state.eventsHypotheticalState) {
      classValidFundValuesPercents = (total.Dollar != this.props.hypotheticalState.totalInvestment) ? 'fund-error' : '';
    }
    return ( this.state.eventsHypotheticalState && 
      this.state.eventsHypotheticalState.portfolios[this.props.index].funds.length > 0 ?
       <div>
          <span className='lbl-allocation'>Allocation</span>
      
       <div className={'PortfolioInvestmentTable'}>
          <table className={'portfolio-table'}>
            <thead className={"thead-highlight"}>
              <th>Total Allocation <a onClick={this.props.autoDistribute} class="auto-distribute">Evenly Distribute</a></th>
              <th></th>
              <th className={'text-right ' + classValidFundValuesPercents} id='percentageTotal' data-percentageTotal={total.Percentage}>{this.formatInvestmentDollar(total.Percentage, '.00')}%</th>
            </thead>
            {this.state.eventsHypotheticalState.portfolios[this.props.index].funds.map(item => {
              return <tr className={utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) > utilityFunctions.convertToOADate(this.props.selectedEventDate) ? 'invalid-fund': '' } data-is-invalid={utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) < utilityFunctions.convertToOADate(this.props.selectedEventDate)? 'true': 'false'}>
                {/* Fund / Inception Date  */}
                <td>
                    <label className={'fund-symbol'}>{this.props.userType === 'International' ? item.name : item.symbol}</label>
                    <br />
                    <span className={'event-fund-name'} title={this.props.userType === 'International' ? item.symbol : item.name}>{this.props.userType === 'International' ? item.symbol : item.name}</span>
                </td>
                <td>
                {
                   utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) <= (this.props.edit ? (utilityFunctions.convertToOADate(this.props.selectedEventDate)+ 1 ) : utilityFunctions.convertToOADate(this.props.selectedEventDate)) ? (this.state.symbolsWithZeroAllocationStatic.includes(item.symbol) ? <div className={'message-event'}>Fund Available for Investment</div> : null):
                    <div><label className={'fund-symbol'}>{this.props.userType === 'International' ? 'Perf. Start Date' : 'Inception Date'}</label>
                      <br />
                      <span className={`event-fund-inception-date ${item.isExtended ? 'underline-text' : ''}`} title={item.isExtended ? this.dateFormatter(utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate)) : ''} >{(this.props.userType === 'International') ? this.dateFormatterIntl(item.oldestShareClassInceptionDate) : this.dateFormatter(item.inceptionDate)}{item.isExtended && '*'}</span>
                    </div>
                }
                </td>
                {/* Invested - Percent */}
                <td className={'text-right'}>
                  {
                    utilityFunctions.getStartDateOfNextFullMonth(item.oldestShareClassInceptionDate) > (this.props.edit ? (utilityFunctions.convertToOADate(this.props.selectedEventDate)+ 1 ) : utilityFunctions.convertToOADate(this.props.selectedEventDate)) ? '--':
                    <div>
                    <input className={'portfolio-invested text-right event-set-amount'}
                      type="text"
                      onBlur={this.onBlurInvestmentPercent}
                      onKeyDown={utilityFunctions.allowOnlyNumbers}
                      data-symbol={item.symbol}
                      data-raw-value={item.amountInvestedPercent <= 0 ? 0 : (item.amountInvestedPercent * 100).toFixed(2)}
                    /> %  </div>
                  }
                </td>
              </tr>
            })
            }

          </table>
        </div>  </div>: <div>No funds selected for this portfolio</div>
    )
  }
}

EventTableDetails.PropTypes = {
  hypotheticalState: PropTypes.object,
  index: PropTypes.number,
  autoDistribute: PropTypes.func
};

import { connect } from 'react-redux';

import * as actions from '../../actions/fundComparisonActions.js';
import * as portfolioActions from '../../actions/portfolioComparisonActions';

import hypotheticals from './hypotheticals';

function mapStateToProps(state) {
	return {
		 scheduleCount: state.portfolioComparisonReducer.scheduleCount,
		 scheduleCountId: state.portfolioComparisonReducer.scheduleCountId,
		 scheduleWithdrawalCountId: state.portfolioComparisonReducer.scheduleWithdrawalCountId,
		 schedules: state.portfolioComparisonReducer.schedules,
		 schedulesP2: state.portfolioComparisonReducer.schedulesP2,
		 investmentSchedules: state.portfolioComparisonReducer.investmentSchedules,
		 investmentSchedulesP2: state.portfolioComparisonReducer.investmentSchedulesP2,
		 withdrawalSchedules: state.portfolioComparisonReducer.withdrawalSchedules,
		 withdrawalSchedulesP2: state.portfolioComparisonReducer.withdrawalSchedulesP2,
		 removeScheduleResult: state.portfolioComparisonReducer.removeScheduleResult,
		 removeScheduleP2Result: state.portfolioComparisonReducer.removeScheduleP2Result,
		 // Fee Schedules
		 addFeeScheduleResult: state.portfolioComparisonReducer.addFeeScheduleResult,
		 addFeeScheduleP2Result: state.portfolioComparisonReducer.addFeeScheduleP2Result,
		 modifyFeeScheduleResult: state.portfolioComparisonReducer.modifyFeeScheduleResult,
		 modifyFeeScheduleP2Result: state.portfolioComparisonReducer.modifyFeeScheduleP2Result,
		 removeFeeScheduleResult: state.portfolioComparisonReducer.removeFeeScheduleResult,
		 removeFeeScheduleP2Result: state.portfolioComparisonReducer.removeFeeScheduleP2Result,
		 feeSchedules: state.portfolioComparisonReducer.feeSchedules,
		 feeSchedulesP2: state.portfolioComparisonReducer.feeSchedulesP2,
		 modifyScheduleCount: state.portfolioComparisonReducer.modifyScheduleCount,
		 modifyScheduleCountId: state.portfolioComparisonReducer.modifyScheduleCountId,
		 removeAllEventScheduleResult: state.portfolioComparisonReducer.removeAllEventScheduleResult,
		 removeAllEventScheduleP2Result: state.portfolioComparisonReducer.removeAllEventScheduleP2Result,
		 // Load Fee Schedules (Front, Deferred, Redemption)
		 frontFeeSchedules: state.portfolioComparisonReducer.frontFeeSchedules,
			// Fund Investment Schedules: Modify, Delete
			modifyFrontInvestmentScheduleResult: state.portfolioComparisonReducer.modifyFrontInvestmentScheduleResult,
			modifyFrontInvestmentScheduleP2Result: state.portfolioComparisonReducer.modifyFrontInvestmentScheduleP2Result,
			removeFrontInvestmentScheduleResult: state.portfolioComparisonReducer.removeFrontInvestmentScheduleResult,
			removeFrontInvestmentScheduleP2Result: state.portfolioComparisonReducer.removeFrontInvestmentScheduleP2Result,
			updateScheduleResult: state.portfolioComparisonReducer.updateScheduleResult,
			fundEvents: state.portfolioComparisonReducer.fundEvents,
			fundEventsP2: state.portfolioComparisonReducer.fundEventsP2,
			contributionToRisk: state.portfolioComparisonReducer.contributionToRisk,
			contributionToRiskP2: state.portfolioComparisonReducer.contributionToRiskP2,
			contributionToRiskFinalAllocation: state.portfolioComparisonReducer.contributionToRiskFinalAllocation,
			fundsPresentAmountList: state.portfolioComparisonReducer.fundsPresentAmountList,
			userType: state.fundComparisonReducer.userType
	};
}

function mapDispatchToProps(dispatch) {
	return {
		requestSaveEventTemplateDetailsApi: (schedule,containerId) => {
			return dispatch(portfolioActions.requestSaveEventHypotheticalDetailsApi(schedule,containerId));
		},
		requestSaveInvestmentEventApi: (schedule,containerId) => {
			return dispatch(portfolioActions.requestSaveInvestmentEventApi(schedule,containerId));
		},
		requestGetEventSchedulesApi: (containerId) => {
		  return dispatch(portfolioActions.requestGetEventHypotheticalSchedulesApi(containerId))
		},
		requestGetEventSchedulesP2Api: (containerId) => {
		  return dispatch(portfolioActions.requestGetEventHypotheticalSchedulesP2Api(containerId))
		},
		requestRemoveEventSchedulesApi: (containerId, scheduleId) => {
		  return dispatch(portfolioActions.requestRemoveEventHypotheticalSchedulesApi(containerId, scheduleId))
		},
		requestRemoveEventSchedulesP2Api: (containerId, scheduleId) => {
		  return dispatch(portfolioActions.requestRemoveEventHypotheticalSchedulesP2Api(containerId, scheduleId))
		},
		// Fee Schedules
		requestAddEventFeeSchedulesApi: (containerId, schedules) => {
		  return dispatch(portfolioActions.requestAddEventHypotheticalFeeSchedulesApi(containerId, schedules))
		},
		requestAddEventFeeSchedulesP2Api: (containerId, schedules) => {
		  return dispatch(portfolioActions.requestAddEventHypotheticalFeeSchedulesP2Api(containerId, schedules))
		},
		requestModifyEventFeeSchedulesApi: (containerId, schedule) => {
		  return dispatch(portfolioActions.requestModifyEventHypotheticalFeeSchedulesApi(containerId, schedule))
		},
		requestModifyEventFeeSchedulesP2Api: (containerId, schedule) => {
		  return dispatch(portfolioActions.requestModifyEventHypotheticalFeeSchedulesP2Api(containerId, schedule))
		},
		requestRemoveEventFeeSchedulesApi: (containerId, schedules) => {
		  return dispatch(portfolioActions.requestRemoveEventHypotheticalFeeSchedulesApi(containerId, schedules))
		},
		requestRemoveEventFeeSchedulesP2Api: (containerId, schedules) => {
		  return dispatch(portfolioActions.requestRemoveEventHypotheticalFeeSchedulesP2Api(containerId, schedules))
		},
		requestGetEventFeeSchedulesApi: (containerId) => {
		  return dispatch(portfolioActions.requestGetEventHypotheticalFeeSchedulesApi(containerId))
		},
		requestGetEventFeeSchedulesP2Api: (containerId) => {
			return dispatch(portfolioActions.requestGetEventHypotheticalFeeSchedulesP2Api(containerId))
		},
		requestModifyEventTemplateDetailsApi: (schedule,containerId) => {
			return dispatch(portfolioActions.requestModifyEventHypotheticalDetailsApi(schedule,containerId));
		},
		// Load Fee Schedules (Front, Deferred, Redemption)
		requestGetEventFrontFeeSchedulesApi: (wsodIssues) => {
		  return dispatch(portfolioActions.requestGetEventHypotheticalFrontFeeSchedulesApi(wsodIssues))
		},
		// Fund Investment Schedules: Modify, Delete
		requestModifyEventFrontinvestmentSchedulesApi: (schedules, containerId) => {
		  return dispatch(portfolioActions.requestModifyEventHypotheticalFrontInvestmentSchedulesApi(schedules, containerId))
		},
		requestModifyEventFrontinvestmentSchedulesP2Api: (schedules, containerId) => {
		  return dispatch(portfolioActions.requestModifyEventHypotheticalFrontInvestmentSchedulesP2Api(schedules, containerId))
		},
		requestRemoveEventFrontinvestmentSchedulesApi: (schedules, containerId) => {
		  return dispatch(portfolioActions.requestRemoveEventHypotheticalFrontInvestmentSchedulesApi(schedules, containerId))
		},
		requestRemoveEventFrontinvestmentSchedulesP2Api: (schedules, containerId) => {
		  return dispatch(portfolioActions.requestRemoveEventHypotheticalFrontInvestmentSchedulesP2Api(schedules, containerId))
		},
		requestGetInvestmentSchedulesApi: (containerId) => {
		  return dispatch(portfolioActions.requestGetInvestmentHypotheticalSchedulesApi(containerId))
		},
		requestGetInvestmentSchedulesP2Api: (containerId) => {
		  return dispatch(portfolioActions.requestGetInvestmentHypotheticalSchedulesP2Api(containerId))
		},
		requestUpdateEventSchedulesApi: (updateData) => {
		  return dispatch(portfolioActions.requestUpdateEventHypotheticalSchedulesApi(updateData))
		},
		requestSaveWithdrawalEventApi: (schedule,containerId) => {
			return dispatch(portfolioActions.requestSaveWithdrawalEventApi(schedule,containerId));
		},
		requestGetWithdrawalSchedulesApi: (containerId) => {
		  return dispatch(portfolioActions.requestGetWithdrawalHypotheticalSchedulesApi(containerId))
		},
		requestGetWithdrawalSchedulesP2Api: (containerId) => {
		  return dispatch(portfolioActions.requestGetWithdrawalHypotheticalSchedulesP2Api(containerId))
		},
		requestGetFundEventsApi: (containerId, dateBegin, dateEnd) => {
		  return dispatch(portfolioActions.requestGetFundEventsHypotheticalApi(containerId, dateBegin, dateEnd))
		},
		requestGetFundEventsP2Api: (containerId, dateBegin, dateEnd) => {
		  return dispatch(portfolioActions.requestGetFundEventsHypotheticalP2Api(containerId, dateBegin, dateEnd))
		},
		requestContributionToRiskApi: containerIds => {
			return dispatch(portfolioActions.requestContributionToRiskApi(containerIds));
		},
		requestContributionToRiskP2Api: containerIds => {
			return dispatch(portfolioActions.requestContributionToRiskP2Api(containerIds));
		},
		requestContributionToRiskFinalAllocationApi: (containerIds, timeInMonths, dateEnd) => {
			return dispatch(portfolioActions.requestContributionToRiskFinalAllocationApi(containerIds, timeInMonths, dateEnd));
		},
		requestFundAmountByDateApi: (investmentList, containerId, dateBegin, dateEnd) => {
			return dispatch(portfolioActions.requestFundAmountByDateApi(investmentList, containerId, dateBegin, dateEnd));
		},
		setUserType: result => {
			return dispatch(
				actions.setUserType(result));
		}
	 };
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(hypotheticals);

import React from 'react';

const primaryProspectusRibbonIconStyles = {
	height: '18px',
	width: '9px',
	'margin-bottom': '5px'
};

const PrimaryProspectusBenchmarkRibbonIcon = () => {
	return (
		<svg width="9px" height="18px" viewBox="0 0 9 18" version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={primaryProspectusRibbonIconStyles}>
			<title>Ribbon</title>
			<desc>Created with Sketch.</desc>
			<defs />
			<g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Select-Funds-(Benchmarks)" transform="translate(-997.000000, -405.000000)" fill="#7E2D40" fillRule="nonzero">
					<g id="Ribbon" transform="translate(997.000000, 405.000000)">
						<path d="M0,0 L0,18 L4.5,15 L9,18 L9,0 L0,0 Z M7.41225,4.611 L5.72775,5.82825 L6.36525,7.78275 C6.42975,7.97925 6.3495,8.04225 6.18825,7.93725 L4.50075,6.71625 L2.81325,7.93575 C2.65275,8.04075 2.57175,7.97775 2.63625,7.78125 L3.2745,5.82675 L1.59,4.611 C1.4385,4.491 1.473,4.395 1.6815,4.395 L3.744,4.392 L4.38,2.44425 C4.446,2.238 4.557,2.238 4.623,2.44425 L5.2605,4.392 L7.3215,4.395 C7.53075,4.395 7.5645,4.491 7.41375,4.611 L7.41225,4.611 Z" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default PrimaryProspectusBenchmarkRibbonIcon;

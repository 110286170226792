export const SCREENER_FIELDS_FUNDS = {
	CategoryName: 'MSDMorningstarCategoryName',
	FundName: 'MSDFundName',
	GrossExpenseRatio: 'MSDGrossExpenseRatio',
	IsActivelyManaged: 'MSDIsActivelyManaged',
	LastClose: 'MSDLastClose',
	MorningstarOverallRating: 'MSDMorningstarOverallRating',
	MorningstarRatingY3: 'MSDMorningstar3YrRating',
	MorningstarRatingY5: 'MSDMorningstar5YrRating',
	NetExpenseRatio: 'MSDProspectusNetExpenseRatio',
	PerformanceAsOfDate: 'MSDPerformanceAsOfDate',
	Risk: 'MSDRiskScore',
	Symbol: 'MSDUSAASymbol',
	TotalAssets: 'MSDFundNetAssets',
	TrailingReturnM1: 'MSDTrailingReturnM1',
	TrailingReturnM3: 'MSDTrailingReturnM3',
	TrailingReturnSinceInception: 'MSDTrailingReturnSinceInception',
	TrailingReturnY10: 'MSDTrailingReturnY10',
	TrailingReturnY1: 'MSDTrailingReturnY1',
	TrailingReturnY3: 'MSDTrailingReturnY3',
	TrailingReturnY5: 'MSDTrailingReturnY5'
};
export const SORT_ARGUMENTS = {
	Ascending: 'A',
	Descending: 'D'
};

export const SCREENER_FIELDS_MUTUALFUND = {
	CategoryName: 'MSDMorningstarCategoryName',
	FundName: 'MSDFundName',
	GrossExpenseRatio: 'MSDGrossExpenseRatio',
	IsActivelyManaged: 'MSDIsActivelyManaged',
	LastClose: 'MSDLastClose',
	MorningstarOverallRating: 'MSDMorningstarOverallRating',
	MorningstarRatingY3: 'MSDMorningstar3YrRating',
	MorningstarRatingY5: 'MSDMorningstar5YrRating',
	NetExpenseRatio: 'MSDProspectusNetExpenseRatio',
	PerformanceAsOfDate: 'MSDPerformanceAsOfDate',
	Risk: 'MSDRiskScore',
	Symbol: 'MSDUSAASymbol',
	TotalAssets: 'MSDFundNetAssets',
	TrailingReturnM1: 'MSDTrailingReturnM1',
	TrailingReturnM3: 'MSDTrailingReturnM3',
	TrailingReturnSinceInception: 'MSDTrailingReturnSinceInception',
	TrailingReturnY10: 'MSDTrailingReturnY10',
	TrailingReturnY1: 'MSDTrailingReturnY1',
	TrailingReturnY3: 'MSDTrailingReturnY3',
	TrailingReturnY5: 'MSDTrailingReturnY5',
	High52W: 'MSDHigh52WeekNAV',
	IsClosedToNewInvestors: 'MSDClosedToNewInvestors',
	IsNoLoad: 'MSDNoLoadFund',
	Low52W: 'MSDLow52WeekNAV',
	MinimumInvestment: 'MSDInitialInvestment',
	FundFamilyName: 'MSDFundFamilyName'
};

export const ISSUE_TYPES = {
	ETF: 'etf',
	MUTUAL_FUND: 'mutualfund',
	STOCK: 'stock',
	MFS_FUND: 'MFSfund'
};

export const SCREENER_TYPES = {
	Etf: ISSUE_TYPES.ETF,
	MutualFund: ISSUE_TYPES.MUTUAL_FUND,
	Stock: ISSUE_TYPES.STOCK,
	MFSFund: ISSUE_TYPES.MFS_FUND
};

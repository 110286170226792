import React from 'react';
import { Route, IndexRoute } from 'react-router';

import App from './App';
import Home from './components/HomePage/Container';
import ModuleSelection from './components/ModuleSelectionPage/Container';
import FundComparison from './components/fundComparison/Container';
import LogoutPage from './components/LogoutPage/Container';
import Portfolio from './components/portfolio/portfolioHomepage/Container';
import MaintenancePage from './components/common/MaintenancePage';
import Dashboard from './components/Dashboard/Container';
import FundInternational from './components/MFSFundInternational/Container';
import PortfolioInternational from './components/portfolio/portfolioHomepage/Container';
import JumpPage from './components/jumpPage/jumpPage';

export default(
	<Route exact path="/" component={App}>
		<IndexRoute component={Dashboard} />
		<Route path="/Dashboard" component={Dashboard} />
		<Route path="/Home" component={Home} />
		<Route path="/FundComparison" component={FundComparison} />
		<Route path="/Modules" component={ModuleSelection} />
		<Route path="/Logout" component={LogoutPage} />
		<Route path="/Portfolio" component={Portfolio} />
		<Route path="/Portfolio/:type" component={Portfolio} />
		<Route path="/MaintenancePage" component={MaintenancePage} />
		<Route path="/FundInternational" component={FundInternational} />
		<Route path="/PortfolioInternational" component={PortfolioInternational} />
		<Route path="/JumpPage" component={JumpPage} />
		<Route path="/PortfolioInternational/:type" component={PortfolioInternational} />
		{/* <Route exact path='/MutualFunds/:symbol/:wsodissue' component={MutualFunds} /> */}
	</Route>
);

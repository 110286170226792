/* eslint-disable guard-for-in */
import "babel-polyfill";
import React from "react";
import { Link } from "react-router";

import xmlToJSON from "xmljson";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { cloneDeep } from "lodash";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import Header from "../common/Header";
import SelectFundsModal from "../selectFundComparison/Container";

import DeleteTemplateModal from "../common/modal/DeleteTemplateModal";
import LogoutModal from "../common/modal/LogoutModal";
import DeleteConfirmationModal from "../common/modal/DeleteConfirmationModal";
import DownloadingSpinnerModal from "../common/modal/DownloadingSpinnerModal";
import ValidationModal from '../../components/common/modal/ValidationModal.js';

import * as Format from "../../utils/format";
import * as urlMethods from "../../utils/urlMethods";
import * as Constant from "../../utils/constants";
import * as UsageReporting from "../../utils/usageReporting";
import * as utilityFunctions from "../../utils/commonMethods";
import * as CustomModalStyles from '../../utils/customModalStyles';
import { reportTypes } from "../../utils/constants";
import { SCREENER_TYPES } from '../../utils/screenerConstants';

import closeSVG from "../../images/Icon/Close.svg";

import headerStyles from "../../styles/components/HomePage.scss";
import tableStyles from "../../styles/components/Table.scss";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      deleteModalIsOpen: false,
      deleteConfirmationModalIsOpen: false,
      downloadSpinnerModalIsOpen: false,
      validationModalPerformanceAsofDateOpen: false,
      containerId: "",
      reportId: "",
      isGeneratePDF: false,
      erroMessage: "",
      isUserLoggedOut: false,

      templateData: {},
      fundShareclassId: "",
      errors: []
    };
    
    this.pdfInputTemplateData = '';
    this.options = {
      defaultSortName: "lastUpdated", // default sort column name
      defaultSortOrder: "desc" // default sort order
    };    
  }

  UNSAFE_componentWillMount() {
    let _templateData = cloneDeep(Constant.templateData);
    // [for-fund] For international user and admin, add language property
    if (this.props.userType.response === 'International') {
      _templateData.language = 'en-GB';
    }
    this.setState({
      templateData: _templateData
    });
    try {
      UsageReporting.trackPage("Home Page", "");
    } catch (error) { }
  }

  handleDeleteClick = (id) => {
    this.props.requestMfsSiteInactiveFlag();
    this.props.updateTemplateInternalDetails({
      templateId: id,
      templateName: ""
    });

    this.setState({
      deleteModalIsOpen: true
    });
  }
  callMaintenacePage = () => {
    this.props.router.push('/MaintenancePage');
  }
  componentDidMount() {
    if(window.location.href.toLowerCase().indexOf("international") > -1){
      this.onClickInternational();
      this.props.requestGetTemplatesApi(reportTypes.mil_fundcomparison);
    } else {
      this.props.requestGetTemplatesApi(reportTypes.fundcomparison);
    }
      this.props.requestMfsSiteInactiveFlag();      
      this.props.requestMfsUserData();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.siteInactiveId !== nextProps.siteInactiveId && nextProps.siteInactiveId  > 0) {
      if (nextProps.mfsSiteInactive === true) {
        this.props.router.push('/MaintenancePage');
      }else if(nextProps.mfsUSFvFinactive){
        this.props.router.push('/');
      }      
    }

    if(this.props.screenerPerfDateId != nextProps.screenerPerfDateId && nextProps.fundAsOfDateFromScreenerData && this.state.downloadSpinnerModalIsOpen) {
      let performanceDateValidation = this.validateFundsPerformanceEndDate(nextProps.fundAsOfDateFromScreenerData);
      if (performanceDateValidation.length > 0) {
        //show alert popup modal
        this.state.errors = ["PDF cannot be generated as the template has funds with older performance end date. Start a new fund comparison or Use this template"];	
				this.setState({ downloadSpinnerModalIsOpen: false, validationModalPerformanceAsofDateOpen: true});			
      } else {
          if (this.state.isGeneratePDF) {
            let data = cloneDeep(this.pdfInputTemplateData);            
            const inputParams = {
              templateData: JSON.stringify(data),
              containerId: this.state.containerId,
              reportId: this.state.reportId
            };
            
            this.props.requestGeneratePDFApi(inputParams);

            this.setState({
              isGeneratePDF: false
            },
              () =>
                this.props.updateTemplateInternalDetails({
                  templateId: 0,
                  templateName: "",
                  resetFlag: true
                })
            );
          } 
        }
      //Moved to willreceiveprops nextProps.fundAsOfDateFromScreenerData
    }

    if (this.props.errorCode != nextProps.errorCode && nextProps.errorCode) {
      this.props.router.push({
        pathname: '/Logout',
        search: '?logoutError=true'
      });
    }
    if (this.props.template !== nextProps.template) {

      xmlToJSON.to_json(
        `<data>${nextProps.template[0].xmlData}</data>`,
        (error, data) => {
          const newTemplateData = data;
          const nonfundinfocus = [];

          if (data.funds.nonfundinfocus.symbol === undefined) {
            for (const index in data.funds.nonfundinfocus) {
              const nonFIF = data.funds.nonfundinfocus[index];
              nonfundinfocus.push(nonFIF);
            }
          } else {
            nonfundinfocus.push({
              fundfamily: data.funds.nonfundinfocus.fundfamily,
              symbol: data.funds.nonfundinfocus.symbol,
              wsodissue: data.funds.nonfundinfocus.wsodissue
            });
          }
          newTemplateData.funds.nonfundinfocus = nonfundinfocus;
          let section = data.sections ? Object.values(data.sections) : [];

          let selectedLeftRail = [];
          let finalArray = {
            sections: []
          };

          if (section && section.length > 0) {
            let sectionMasterValues = cloneDeep(
              Constant.sectionMasterData.sections
            );

            Object.keys(section).forEach(function (key) {
              sectionMasterValues.forEach(element => {
                if (
                  element.sectionname === section[key].sectionname &&
                  section[key].isActive === "true"
                ) {
                  element.isActive = section[key].isActive === "true";

                  const inputType = [];
                  inputType.push(section[key].inputtype);

                  section[key].isActive = section[key].isActive === "true"; // only pick active status from saved template
                  section[key].inputtype = inputType; //loading it as it is from saved template
                  section[key].isLocked = element.isLocked; //loading it from master config
                  section[key].canbeAddedAndLocked = element.canbeAddedAndLocked; //loading it from master config
                  section[key].sectionid = element.sectionid; //loading it from master config

                  //updating new display name in case template is saved with old display name
                  section[key].sectiondisplayname = element.sectiondisplayname;

                  let matchedsection = section[key];

                  //looping through subsections.....
                  let _configSubSections = element.subsections;//subsections in master config

                  //subsections saved in DB
                  let _savedSubSections = Object.values(
                    matchedsection.subsections
                  );

                  _configSubSections.forEach((subsection, configsubsectionindex) => {
                    let matchedsubsection = _savedSubSections.find(
                      x => x.name === subsection.name
                    );

                    let _subsectionIndex = _savedSubSections.findIndex(
                      x => x.name === subsection.name
                    );


                    if (_subsectionIndex != -1) {
                      _savedSubSections[_subsectionIndex].isActive =
                        matchedsubsection &&
                        matchedsubsection.isActive === "true";

                      _savedSubSections[_subsectionIndex].isLocked =
                        subsection.isLocked; //reading it from master config

                      _savedSubSections[
                        _subsectionIndex
                      ].canbeAddedAndLocked = subsection.canbeAddedAndLocked; //reading it from master config

                      _savedSubSections[_subsectionIndex].id = subsection.id; //reading it from master config

                      _savedSubSections[
                        _subsectionIndex
                      ].subsectiondisplayname = subsection.subsectiondisplayname; //reading it from master config

                    } else {
                      //get elements which are not selected from master config..
                      _savedSubSections.splice(configsubsectionindex, 0, subsection);
                    }
                    section[key].subsections = _savedSubSections;
                  });

                  selectedLeftRail.push(section[key]);//contains only active sections and subsections
                }
              });
            });

            finalArray.sections.unshift(selectedLeftRail); //insert all active elements to the array at position 0

            let filtered = sectionMasterValues.filter(function (item) {
              //get elements which are not selected from master config..
              return (
                !selectedLeftRail.includes(item.sectionname) &&
                item.isActive === false
              );
            });

            filtered.forEach(x => finalArray.sections[0].push(x));

            newTemplateData.sections = finalArray.sections[0];

          } else {
            newTemplateData.sections =
              Constant.sectionMasterData.sections
              ;
          }
          newTemplateData.isMarketingPage = data.isMarketingPage ?
            data.isMarketingPage === "true" :
            true;

          if(nextProps.userType.response === 'International') {
            newTemplateData.currency = data.currency ? data.currency : 'USD';
            newTemplateData.language = data.language ? data.language : 'en-GB';
          }

          this.setState({
            templateData: newTemplateData
          });

          if (this.state.isGeneratePDF) {            
            let funds = [];
            this.pdfInputTemplateData = cloneDeep(newTemplateData); // using since this.state.templateData.funds.fundinfocus is overridden somewhere
            funds.push(newTemplateData.funds.fundinfocus.wsodissue);
            newTemplateData.funds.nonfundinfocus.forEach(item => {
              funds.push(item.wsodissue)
            });
            const screenerInput = Constant.screenerInputs;
            screenerInput.arguments[0].conditions[0].values = funds;              
            this.props.requestScreenerGetFundPerformanceAsOfDateApi(SCREENER_TYPES.MutualFund, screenerInput, 'no', 'USD');           
          } else {
            this.setState({
              templateData: newTemplateData
            });
          }
        }
      );
    }
    if (this.props.deleteTemplateDetails !== nextProps.deleteTemplateDetails) {
      if (nextProps.deleteTemplateDetails.success === 1) {
        // Repopulate template again
        if (nextProps.userType.response === 'International') {
          this.props.requestGetTemplatesApi(reportTypes.mil_fundcomparison);
        } else {
          this.props.requestGetTemplatesApi(reportTypes.fundcomparison);
        }

        this.setState({
          deleteConfirmationModalIsOpen: true,
          deleteModalIsOpen: false
        });
        setTimeout(() => {
          this.setState({
            deleteConfirmationModalIsOpen: false
          });
        }, 3000);
      } else {
        this.setState({
          statusMessage: nextProps.deleteTemplateDetails.resultOutput.returnMessage,
          erroMessage: "We are sorry, template is not deleted. Please try later..."
        });
      }
    }
    // to Redirect to new TAB:

    if (
      nextProps.pdfOutput !== null &&
      nextProps.pdfOutput !== undefined &&
      nextProps.pdfOutput.pdfUrl !== ''
    ) {
      const source = nextProps.pdfOutput.pdfUrl;
      const outputObject = source !== null ? utilityFunctions.convertEncodedStringtoPDF(source) : '';
      if (outputObject !== null) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE specific
          this.setState({ downloadSpinnerModalIsOpen: false });
          window.navigator.msSaveOrOpenBlob(outputObject.blob);
        } else { //for all other browsers
          this.setState({ downloadSpinnerModalIsOpen: false });
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = outputObject.dataUrl;
          a.target = '_blank';
          a.click();
        }
      }
      nextProps.pdfOutput.pdfUrl = '';
    } else {
      if (nextProps.pdfOutput && nextProps.pdfOutput.errorCode === 400) {
        this.setState({ downloadSpinnerModalIsOpen: false });
      } else if(nextProps.pdfOutput && nextProps.pdfOutput.errorCode === 403) {
        this.props.router.push('/MaintenancePage');
      }
    }
  }
  
  validateFundsPerformanceEndDate(screenerData) {
		let performanceDateValidation = [];
		let fifPerformanceEndDate = '';
		let wsodissue = 0;
		let fundName = '';
		let performanceEndDate = '';
		let nonFif = {};
		let fiFWsodIssue = this.state.templateData.funds.fundinfocus.length >0 ? this.state.templateData.funds.fundinfocus[0].wsodissue : 0;
		let row = [];

		screenerData.rows.map(screenerItem => {
			screenerItem.results.map(item => {
				if (item.field == "MSDWSODIssue") {
					wsodissue = item.value;
				} else if (item.field == "MSDFundName") {
					fundName = item.value;
				} else if (item.field == "MSDPerformanceAsOfDate") {
					performanceEndDate = item.value;
				}
			});

			if (fiFWsodIssue == wsodissue) {
				fifPerformanceEndDate = performanceEndDate;
			} else {
				nonFif = {
					"fundname": fundName,
					"performanceEndDate": performanceEndDate
				};
				row.push(nonFif);
			}
		});

		row.forEach(item => {
			if (Math.abs(fifPerformanceEndDate - item.performanceEndDate) > 31) //Date diff between FiF and NFiF should not be more than 1 month
				performanceDateValidation.push(item.fundname);
		});
		return performanceDateValidation;
	}

	closeValidationPerformanceAsofDateModal = () => {	
		this.setState({
      validationModalPerformanceAsofDateOpen: false
		});
	}

  openModal = () => {
    this.props.requestMfsSiteInactiveFlag();
    //Reset data, when new template is being created.
    this.props.updateTemplateInternalDetails({
      templateId: 0,
      templateName: "",
      resetFlag: true
    });
    this.setState({
      modalIsOpen: true,
      errors: [],
      validationModalPerformanceAsofDateOpen: false
    });
    try {
      UsageReporting.trackPage("Fund Selection", "");
    } catch (error) { }
  }
  openDeleteModal = () => {
    this.props.requestMfsSiteInactiveFlag();
    this.setState({
      deleteModalIsOpen: true
    });
  }

  openDeleteConfirmationModal = () => {
    this.props.requestDeleteTemplateApi(this.props.TemplateId);
  }
  closeDeleteConfirmationModal = () => {
    this.setState({
      deleteConfirmationModalIsOpen: false
    });
  }
  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false
    }); //Reset templateId to 0,if cancelling Delete action
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      errors: [],
      validationModalPerformanceAsofDateOpen: false
    });
    if (this.props.userType.response === 'International') {
      this.props.requestGetTemplatesApi(reportTypes.mil_fundcomparison);
    } else {
      this.props.requestGetTemplatesApi(reportTypes.fundcomparison);
    }
    this.props.updateTemplateInternalDetails({
      templateId: 0,
      templateName: "",
      resetFlag: true
    });
  }

  formatDate(cell, row) {
    return Format.dateMonthDateYear(cell);
  }

  handleUseThisTemplateClick(row) {
    this.props.requestMfsSiteInactiveFlag();
    
    if (this.props.userType.response === 'International') {
      this.props.requestGetTemplatesByIdApi(row.templateId, reportTypes.mil_fundcomparison);
    } else {
      this.props.requestGetTemplatesByIdApi(row.templateId, reportTypes.fundcomparison);
    }
    this.setState({
      modalIsOpen: true,
      containerId: row.containerId,
      reportId: row.reportId,
      startNewFundComparison: false,
      validateFundsPerformanceEndDate: false,
      errors: [],
      validationModalPerformanceAsofDateOpen: false
    });
    try {
      UsageReporting.trackPage("Fund Selection", "");
    } catch (error) { }
  }

  useThisTemplateLink = (cell, row) => {
    return (
      <a
        onClick={() => this.handleUseThisTemplateClick(row)}
        className={"link"}
      >
        Use This Template
			</a>
    );
  }

  generatePDF = (row) => {
    this.handleGeneratePdf(row);
  }

  handleGeneratePdf = (row) => {
    this.setState({
      downloadSpinnerModalIsOpen: true
    });
    if (this.props.userType.response === 'International') {
      this.props.requestGetTemplatesByIdApi(row.templateId, reportTypes.mil_fundcomparison);
    } else {
      this.props.requestGetTemplatesByIdApi(row.templateId, reportTypes.fundcomparison);
    }
    this.setState({
      containerId: row.containerId,
      reportId: row.reportId,
      isGeneratePDF: true
    });
  }

  generatePDFLink = (cell, row) => {
    return (
      <Link onClick={() => this.generatePDF(row)} className={"link"}>
        Generate PDF
			</Link>
    );
  }

  deleteTemplateButton = (cell, row) => {
    return (
      <div
        id={`template-id-${cell}`}
        onClick={() => this.handleDeleteClick(cell)}
        className={"closeButton"}
      >
        <img src={closeSVG} />{" "}
      </div>
    );
  }

  logOut = () => {
    this.setState({
      isUserLoggedOut: true
    });
  }

  logoutUser = () => {
    localStorage.setItem("logout-token", localStorage.getItem("access_token"));
    if (document.querySelector("meta[name='access_token']")) {
      document.querySelector("meta[name='access_token']").value = "";
    }
    localStorage.removeItem("access_token");
    localStorage.setItem("loggedOut", true);
    const url = urlMethods.getEnvironment();
    const a = document.createElement("a");
    a.target = "_self";
    a.href = url;
    document.body.appendChild(a);
    a.click();
  }

  closeLogOutModal = () => {
    this.setState({
      isUserLoggedOut: false
    });
  }
  onClickInternational = () => {
    this.props.setUserType("International");
    this.props.requestGetTemplatesApi(reportTypes.mil_fundcomparison);
  }

  onClickNonInternational = () => {
    this.props.setUserType("");
    this.props.requestGetTemplatesApi(reportTypes.fundcomparison);
  }

  resetComparePortfolios = () => {}

  setFundShareclassId = (argFundShareclassId) => {
    this.setState({ fundShareclassId: argFundShareclassId });
  }

  render() {
    return (
      <div className={"homePage"}>
        <Header 
        resetComparePortfolios={this.resetComparePortfolios} 
        onClickInternational={this.onClickInternational} 
        onClickNonInternational={this.onClickNonInternational} 
        logOut={this.logOut} activeTab={this.props.userType.response === 'International'? 3: 1} 
        mfsUserData={this.props.mfsUserData}
        mfsUSFvFinactive = {this.props.mfsUSFvFinactive} 
        mfsUSPvPinactive = {this.props.mfsUSPvPinactive} 
        mfsMILFvFinactive = {this.props.mfsMILFvFinactive}
        mfsMILPvPinactive = {this.props.mfsMILPvPinactive}/>
        <LogoutModal
          isUserLoggedOut={this.state.isUserLoggedOut}
          customStyles={CustomModalStyles.LogOutModalStyles}gettemplates
          
          logoutUser={this.logoutUser}
          closeLogOutModal={this.closeLogOutModal}
        />
        <div className={"body"}>
          <div className={"bodyContainer"}>
            <div className={"sub-HeaderModule"}>
              <label className={"header-text"}>My {this.props.userType.response === 'International' ? 'International' : ''} Report Templates </label>
              <DeleteTemplateModal
                erroMessage={this.state.erroMessage}
                closeDeleteModal={this.closeDeleteModal}
                customStyles={CustomModalStyles.DeleteTemplateModalStyles}
                deleteHandleClick={this.openDeleteConfirmationModal}
                deleteModalIsOpen={this.state.deleteModalIsOpen}
                isPortfolio={false}
              />
              <DeleteConfirmationModal
                deleteConfirmationModalIsOpen={
                  this.state.deleteConfirmationModalIsOpen
                }
                closeDeleteConfirmationModal={this.closeDeleteConfirmationModal}
                deleteConfirmationMessage={'Template Deleted!'}
              />
              <ValidationModal
                errorMessages={this.state.errors}
                customStyles={CustomModalStyles.ValidationModalStyles}
                closeValidationModal={this.closeValidationPerformanceAsofDateModal}
                validationModalIsOpen={this.state.validationModalPerformanceAsofDateOpen}
                isWithdrawalModal={this.state.errors.length > 0}
              />
              <DownloadingSpinnerModal
                downloadSpinnerModalIsOpen={
                  this.state.downloadSpinnerModalIsOpen
                }
              />
              <button
                onClick={this.openModal}
                className={"fund-comparison-button"}
              >
                <Link className={"button-text"}>
                  START A NEW FUND COMPARISON
                </Link>
              </button>
              {/* TO-DO - Implement JSON for template data and pass to modal */}
              <SelectFundsModal
                modalIsOpen={this.state.modalIsOpen}
                containerId={this.state.containerId}
                reportId={this.state.reportId}
                templateData={this.state.templateData}
                closeModal={this.closeModal}
                startNewFundComparison={this.state.startNewFundComparison}
                callMaintenace={this.callMaintenacePage}
                fundShareclassId={this.state.fundShareclassId}
                setFundShareclassId={this.setFundShareclassId}
              />
            </div>
          </div>
        </div>
        <div className={"tableData tableStyle"}>
          {this.props.userType.response !== 'International' ? 
          
          
          <BootstrapTable
            data={this.props.templates}
            striped={false}
            hover={false}
            options={this.options}
            tableStyle={{ width: "100%" }}
          >
            <TableHeaderColumn
              isKey
              dataField="templateName"
              columnTitle={true}
              dataSort
              width="18%"
            >
              Report Name
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="lastUpdated"
              dataFormat={this.formatDate}
              dataSort
              width="17%"
            >
              Last Updated
            </TableHeaderColumn>
            <TableHeaderColumn dataField="mfsFund" dataSort width="15%">
              MFS Fund
            </TableHeaderColumn>
            <TableHeaderColumn dataField="fundCompare" width="25%">
              Funds Compared
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="templateId"
              dataFormat={this.useThisTemplateLink}
              width="10%"
            />
            <TableHeaderColumn
              dataField="templateId"
              dataFormat={this.generatePDFLink}
              width="10%"
            />
            <TableHeaderColumn
              dataField="templateId"
              dataFormat={this.deleteTemplateButton}
              width="5%"
            />
          </BootstrapTable>
          :
          <BootstrapTable
            data={this.props.templates}
            striped={false}
            hover={false}
            options={this.options}
            tableStyle={{ width: "100%" }}
          >
            <TableHeaderColumn
              isKey
              dataField="templateName"
              columnTitle={true}
              dataSort
              width="13%"
            >
              Report Name
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="lastUpdated"
              dataFormat={this.formatDate}
              dataSort
              width="17%"
            >
              Last Updated
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="language"              
              dataSort
              width="10%"
            >
              Language
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="currency"              
              dataSort
              width="10%"
            >
              Currency
            </TableHeaderColumn>
            <TableHeaderColumn dataField="mfsFund" dataSort width="10%">
              MFS Fund
            </TableHeaderColumn>
            <TableHeaderColumn dataField="fundCompare" width="17%">
              Funds Compared
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="templateId"
              dataFormat={this.useThisTemplateLink}
              width="11%"
            />
            <TableHeaderColumn
              dataField="templateId"
              dataFormat={this.generatePDFLink}
              width="8%"
            />
            <TableHeaderColumn
              dataField="templateId"
              dataFormat={this.deleteTemplateButton}
              width="4%"
            />
          </BootstrapTable>
          }
        </div>
      </div>
    );
  }
}

export default Home;

import React from 'react';

const resultSetTooLargeIconStyles = {
	height: '28px',
	width: '504px'
};

const ResultSetTooLargeIcon = props => {
	return (
		<svg
			width="504px"
			height="28px"
			viewBox="0 0 504 28"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Result Set Too Large </title>
			<defs />
			<g
				id="Final"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
			>
				<g
					id="Select-Funds-(All-Funds---Result-Set-Too-Large)"
					transform="translate(-523.000000, -684.000000)"
				>
					<g id="Icon-" transform="translate(524.000000, 685.000000)">
						<text
							id="Result-set-too-large"
							font-family="Calibri"
							font-size="20.8"
							font-weight="normal"
							fill="#A6A7A8"
						>
							<tspan x="44" y="20">
                Result set too large to display, please continue filtering
							</tspan>
						</text>
						<circle
							id="Oval"
							stroke="#A6A7A8"
							stroke-width="1.3"
							fill-rule="nonzero"
							cx="13"
							cy="13"
							r="13"
						/>
						<text
							id="!"
							font-family="Calibri-Bold, Calibri"
							font-size="18.2"
							font-weight="bold"
							fill="#A6A7A8"
						>
							<tspan x="10" y="19">
                !
							</tspan>
						</text>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ResultSetTooLargeIcon;

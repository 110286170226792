import React from 'react';
import { Button, OverlayTrigger, ButtonToolbar, Tooltip } from 'react-bootstrap';
import tooltipStyles from '../../../styles/components/CompareToFundsTooltip.scss';

class CompareToFundsTooltip extends React.Component {	
	tooltip() {		
		return (			
			<Tooltip bsClass={'compareToFundsTooltip'}>
				<p className={'tooltip-text-header'}>
					Compared To: 
					<br></br>
				</p>
				<ul className={'ulStyle'}>			
					{this.props.nonFifFundName && this.props.nonFifFundName.split(',').map(item => {
						return <li className={'tooltip-text-description'}>
							<span>
								{item}
							</span>
						</li>
					})}
				</ul>				
			</Tooltip>		
		);
	}

	positionerInstance() {
		return (
			<ButtonToolbar>
				<OverlayTrigger
					ref="tooltipTrigger"
					placement="bottom"
					overlay={this.tooltip()}
					rootClose
					trigger="hover"
				>
					<p id="infoIcon" className={'comparedToFunds'} >{this.props.fifFundName}</p>
				</OverlayTrigger>
			</ButtonToolbar>
		);
	}

	render() {
		return <div>{this.positionerInstance()}</div>;
	}
}

export default CompareToFundsTooltip;

import moment from 'moment';

export function convertEncodedStringtoPDF(sourceURL) {
    const outputObject = {};
    if (sourceURL.includes('data:application/pdf;base64,')) {
        const base64EncodedString = sourceURL.replace('data:application/pdf;base64,', '');
        const byteCharacters = window.atob(base64EncodedString);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
            type: 'application/pdf'
        });
        const dataUrl = window.URL.createObjectURL(blob);
        outputObject.dataUrl = dataUrl;
        outputObject.blob = blob;
        return outputObject;
    }
    return outputObject;
}
let DAY_MILLISECONDS = 86400000,
    MINUTE_MILLISECONDS = 60000,
    MS_DAY_OFFSET = 25569;

export function convertFromOADate(msDate) {
    if (!msDate) {
        return "";
    }
    let jO = new Date(((msDate - MS_DAY_OFFSET) * DAY_MILLISECONDS));
    let tz = jO.getTimezoneOffset();
    jO = new Date(((msDate - MS_DAY_OFFSET + (tz / (60 * 24))) * DAY_MILLISECONDS));

    return moment(jO).format("MMM DD, YYYY");
}

export function convertFromOAIntlDate(msDate) {
    if (!msDate) {
        return "";
    }
    let jO = new Date(((msDate - MS_DAY_OFFSET) * DAY_MILLISECONDS));
    let tz = jO.getTimezoneOffset();
    jO = new Date(((msDate - MS_DAY_OFFSET + (tz / (60 * 24))) * DAY_MILLISECONDS));

    return moment(jO).format("DD MMM, YYYY");
}

export function getCurrencySymbolFromCurrency(currency) {
    let symbol = '';
    switch (currency) {
        case 'USD':
            symbol = '$';
            break;
        case 'CHF':
            symbol = 'fr. ';
            break;
        case 'EUR':
            symbol = '€';
            break;
        case 'GBP':
            symbol = '£';
            break;
        default:
			symbol = '$';
            break;
    }
    return symbol;    
}

export function dateFromOADate(msDate) {
    return moment.fromOADate(msDate)
}

moment.fromOADate = function (msDate) {
    if (!msDate) {
        return "";
    }
    let jO = new Date(((msDate - MS_DAY_OFFSET) * DAY_MILLISECONDS));
    let tz = jO.getTimezoneOffset();
    jO = new Date(((msDate - MS_DAY_OFFSET + (tz / (60 * 24))) * DAY_MILLISECONDS));    

    return moment(jO);
}

export function convertToOADate(fromDate) { 
    return moment(fromDate).toOADate();
}

moment.fn.toOADate = function(jsDateInput){
	let jsDate = jsDateInput || this._d || new Date();
	let timezoneOffset = jsDate.getTimezoneOffset() / (60 * 24);
	let msDateObj = ( jsDate.getTime() / DAY_MILLISECONDS ) + (MS_DAY_OFFSET - timezoneOffset);
	return msDateObj;
}

export function formatOADate(msDate, format) {
    if (!msDate) {
        return "";
    }
    let jO = new Date(((msDate - MS_DAY_OFFSET) * DAY_MILLISECONDS));
    let tz = jO.getTimezoneOffset();
    jO = new Date(((msDate - MS_DAY_OFFSET + (tz / (60 * 24))) * DAY_MILLISECONDS));

    return moment(jO).format(format);
} 

export function removeDuplicateItemsInArray(arr, comp) {
    return arr
        .map(e => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);
}
export function getStartDateOfCurrentMonth() {
    return moment(moment().startOf('month')).toOADate();
}

export function getNyearOldDate(year) {
    return Math.floor(moment(moment().add(-year, 'year').endOf('year')).toOADate());
}

export function getNyearNmonthOldDate(year, month) {
    return Math.floor(moment(moment().add(-year, 'year').add(-month, 'month').endOf('month')).toOADate());
}

export function getNyearNmonthStartDate(year, month, msDate) {
    if(!msDate){
        return Math.floor(moment(moment().add(-year, 'year').add(-month, 'month').endOf('month')).toOADate());
    }
    return Math.floor(moment(moment.fromOADate(msDate).add(-year, 'year').add(-month, 'month').endOf('month')).toOADate());   
}

export function getEndDateOfPreviousMonth() {
    return Math.floor(moment(moment().add(-1, 'month').endOf('month')).toOADate());
}

export function getEndDateOfPrevNextNMonth(msDate,nMonth) {   
   return Math.floor(moment(moment.fromOADate(msDate).add(nMonth, 'month')).toOADate());   
}

export function getEndDateOfMonth(msDate) {
    return Math.floor(moment.fromOADate(msDate).endOf('month').toOADate());
}

export function getDiffInMonths(beginDate, endDate) {
    if(!endDate){
        endDate =Math.floor(moment(moment().add(-1, 'month').endOf('month')).toOADate()); // Performance end date for the fund is not available here
    }
    return moment.fromOADate(endDate).diff(moment.fromOADate(beginDate), 'months');
}

export function getPerformanceEndDateValidation() {
    let endDate =Math.floor(moment(moment().add(-1, 'month').endOf('month')).toOADate()); // Performance end date for the fund is not available here
    return moment.fromOADate(endDate).subtract(60, 'days').toOADate();
}

export function getPreviousPeriodYear(msDate, years) {
    return moment.fromOADate(msDate).subtract(years, 'years').add(1, 'days').toOADate();
}

export function getStartDateOfNextFullMonth(msDate) {
    return Math.floor(moment(moment.fromOADate(msDate).add(1, 'month').endOf('month').add(1, 'days')).toOADate());
}

export function getDateIfLessThan3Years(earliestCommonDate, asOfPerformanceDate) {
    if(earliestCommonDate > moment.fromOADate(asOfPerformanceDate).subtract(3, 'years').add(1, 'days').toOADate()) {
        return moment.fromOADate(asOfPerformanceDate).subtract(3, 'years').add(1, 'days').toOADate();
    }
    return earliestCommonDate;
}

/**
 * Returns true if user has keypress following key:
 * 0-9 number, 0-9 number on numpad
 * decimal (only one point)
 * Arrow keys, Home, End, PageUp, PageDown
 * Backspace, Delete, Tab & Shift+Tab.
 * @param {number} keyCode key code
 */
export function isNumberKeyPressed(e) {
    let keyCode = e.which,
        value = e.target.value,
        isShift = e.shiftKey;
    return (
        (
            !isShift || // Shift NOT allowed
            (isShift && keyCode === 9)  // Shift+Tab allowed
        ) &&
        (
            (keyCode >= 48 && keyCode <= 57) ||   // 0-9
            (keyCode >= 96 && keyCode <= 105) ||  // 0-9 (Numpad)
            (keyCode >= 33 && keyCode <= 40) ||   // Arrow keys, Home, End, PageUp, PageDown
            ((keyCode === 190 || keyCode === 110) && value.indexOf('.') === -1) || // decimal (only one allowed)
            keyCode === 8 ||    // Backspace
            keyCode === 46 ||   // Delete
            keyCode === 9       // Tab
        )
    );
}

/**
 * Use this function to allow user to enter only positve numbers with decimal and 
 * other normal operation (Arrow keys, Home, End, PageUp, PageDown, Backspace, Delete, Tab & Shift+Tab)
 * @param {event} e event object
 */
export function allowOnlyNumbers(e) {
    return (!isNumberKeyPressed(e) ? e.preventDefault(): true);
}

export const getRandomNumber = () => {     
	const crypto = window.crypto || window.msCrypto;
	let array = new Uint32Array(1);
	return crypto.getRandomValues(array)[0]; 
};

export const setArgument = (operator,field, value) => {
    return {
        argsOperator: "AND",
        conditions: [
          {
            operator: operator,
            values: value,
            valueOperator: "OR",
          },
        ],
        field: field,
      }
}

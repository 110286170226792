import React from 'react';

const circleStyle = {
'stroke-width':'5px'
};

const CheckIconGray = props => {
	return (
		<span role="presentation">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" width="118px" height="118px" version="1.1">
				<defs />
				<g id="Final" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
					<g id="Download-Complete-(Confirmation)" transform="translate(-575 -282)">
						<g id="Group-7" transform="translate(367 219)">
							<g id="Check-" transform="translate(208 63)">
								<g id="Group-4">
									<g id="Group-6">
										<g id="Group-2" stroke="#919399" style={circleStyle} stroke-width="5">
											<circle id="Oval" cx="64.5" cy="64.5" r="62" />
										</g>
										<g id="Group-5" fill="#919399" transform="translate(33 41)">
											<rect id="Rectangle-12" transform="translate(40.4597 28.1663) rotate(40) translate(-40.4597 -28.1663)" x="34.9212" y="-2.80555" width="11.0769" height="61.9437" />
											<rect id="Rectangle-12-Copy" transform="translate(14.5654 39.5032) rotate(-49) translate(-14.5654 -39.5032)" x="9.02693" y="26.2399" width="11.0769" height="26.5265" />
										</g>
									</g>
									<g id="Group-3" transform="translate(33 48)" />
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);
};
export default CheckIconGray;

import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';

import genericModalStyles from '../../../styles/components/modal/validationModal.scss';

class ValidationModal extends React.PureComponent {
	render() {
		let indexQuoteStyle = {
			'pointer-events': 'none',
			opacity: 0.4
		};
		if (!this.props.isSaveDisabled) {
			indexQuoteStyle = {};
		}

		return (
			<Modal
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				isOpen={this.props.validationModalIsOpen}
				style={this.props.customStyles}
			>
                <div className={'validationModal'}>
                    <div className={'main-container'}>
                        <div className={'headerContainer'}>
                            <div className={'header'}>
                                <div className={'header-text'}>Alert(s)</div>
                            </div>
                        </div>
                        <div className={'validationMainModal'}>
                            <div className={'div-errormessage'}>
								{this.props.errorMessages && this.props.errorMessages.map((item, index) => {
									if (index < 1){
										return <div>{item}</div>
									}
								})}
								<ul>
                                    {this.props.errorMessages && this.props.errorMessages.map((item, index) => {
										if (index == 1 || index == 2 ){
											return <li>{item}</li>
										}
									})}
									{this.props.isWithdrawalModal && this.props.errorMessages && this.props.errorMessages.map((item, index) => {
										if (index > 2){
											return <li>{item}</li>
										}
									})}
									
								</ul>	
								{!this.props.isWithdrawalModal && this.props.errorMessages && this.props.errorMessages.map((item, index) => {
									if (index > 2){
										return <div>{item}</div>
									}
								})}
                            </div>
                        </div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								
								<button
									onClick={this.props.closeValidationModal}
									style={indexQuoteStyle}
									className={'save-button'}
								>
									OK
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

ValidationModal.propTypes = {
	validationModalIsOpen: PropTypes.bool,
	customStyles: PropTypes.object,
	saveTemplateSettings: PropTypes.func,
    closeValidationModal: PropTypes.func,
    errorMessages: PropTypes.array,
};

export default ValidationModal;

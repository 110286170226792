import React from 'react';
import { PropTypes } from 'prop-types';

import Container from './dragContainer';

import LockIcon from '../../common/Icons/LockIcon.js';
import * as Constant from "../../../utils/constants";

class LeftRailModuleSelection extends React.Component {
	render() {
		return (
			<div className={'leftRailModuleSelection'}>
				<div className={'leftRailSecond'}>
					<div className={'labelHead'}>
            Report Layout
					</div>
					<div className={'modulesList'}>
						<ul className={'parentul'}>
							<li className={'parentli'}>
								<div className={'group-lock-icon'}><LockIcon /></div>
								<div className={'fund-name'} title="Table of Contents">Table of Contents</div>
							</li>
							<li className={'parentli'}>
								<div className={'group-lock-icon'}><LockIcon /></div>
								<div className={'fund-name'} title="Marketing Page">Marketing Page</div>
								<label htmlFor="chkMarketingPage" className={'switch'}>
									<input id="chkMarketingPage" name="chkMarketingPage" checked={this.props.isChecked} onClick={this.props.toggleMarketingPage} type="checkbox" />
									<span className="slider round" />
								</label>
							</li>
							<div>
								<li className="parentli">
									<div className="group-lock-icon"><LockIcon /></div>
									{(this.props.isFromPortfolio && this.props.userType === 'International') ?
									<div className="fund-name" title="Portfolios in Focus">Portfolios in Focus</div>:
									<div className="fund-name" title="Funds in Focus">Funds in Focus</div>}
								</li>
								<ul className="childul">
								{(!this.props.isFromPortfolio && this.props.userType === 'International') ? 
								<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>										
										<div className="fund-name" title="Fund Basic">Fund Basics</div>
									</li>: ''}
									{(!this.props.isFromPortfolio && this.props.userType === 'International') ? 
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>										
										<div className="fund-name" title="MIFID 5 Year Rolling Returns">MIFID 5 Year Rolling Returns</div>
									</li>: ''}
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>
										<div className="fund-name" title="Portfolio Basics">Portfolio Basics</div>
									</li>
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>
										{this.props.isFromPortfolio ?
										<div className="fund-name" title="Fund Basics"> Planning Assumptions </div>:
										this.props.userType === 'International' ?
										<div className="fund-name" title="Key Statistics"> Key Statistics </div>
										:<div className="fund-name" title="Fund Basics"> Fund Basics </div>}
									</li>
									{(this.props.isFromPortfolio && this.props.userType === 'International') ? 
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>										
										<div className="fund-name" title="5 Year Rolling Returns">5 Year Rolling Returns</div>
									</li>:''}
									{(this.props.isFromPortfolio && this.props.userType === 'International') ?
									<li className="childli">
									<div className="group-lock-icon"><LockIcon /></div>										
									<div className="fund-name" title="Key Statistics">Key Statistics</div>
									</li>
									: ''}
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>
										<div className="fund-name" title="Trailing Returns & Standard Deviation">Trailing Returns & Standard Deviation</div>
									</li>
								</ul>
							</div>
							<Container moveCardLeftRail={this.props.moveCardLeftRail}
								list={this.props.list}
								handleDeleteFundClick={this.props.handleDeleteFundClick} />
							<div>
								<li className={'parentli'}>
									<div className={'group-lock-icon'}><LockIcon /></div>
									{(this.props.isFromPortfolio && this.props.userType === 'International') ?
									<div className={'fund-name'} title="Fund Information">Fund Information</div>:
									<div className={'fund-name'} title="Standardized Performance">Standardized Performance</div> }
									
								</li>
								<ul className="childul">
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>
										<div className="fund-name" title="Fund Information">Fund Information</div>
									</li>
									{(this.props.isFromPortfolio && this.props.userType === 'International') ?
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>
										<div className="fund-name" title="Fund 5 Year Rolling Returns">Fund 5 Year Rolling Returns</div>
									</li>:''}
									{this.props.userType === 'International' ? '' :
										<li className="childli">
											<div className="group-lock-icon"><LockIcon /></div>
											<div className="fund-name" title="Total & Load Adjusted Returns">Total & Load Adjusted Returns</div>
										</li>}					
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>
										<div className="fund-name" title="Market Returns">Market Returns</div>
									</li>
									{this.props.isFromPortfolio ?
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>
										<div className="fund-name" title="Fund Basics"> Fund Dates</div>
									</li>
									:''}
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>
										<div className="fund-name" title="Morningstar Ratings">Morningstar Ratings</div>
									</li>
									{this.props.isFromPortfolio && Constant.showSustainabilityRating === true?
									<li className="childli">
										<div className="group-lock-icon"><LockIcon /></div>
										<div className="fund-name" title="Sustainability Rating">Sustainability Rating</div>
									</li>
									:''}
								</ul>
							</div>
							<li className={'parentli'}>
								<div className={'group-lock-icon'}><LockIcon /></div>
								<div className={'fund-name'} title="Disclosures">Disclosures</div>
							</li>
						</ul>
					</div>
				</div>
			</div>

		);
	}
}

export default LeftRailModuleSelection;

LeftRailModuleSelection.propTypes = {
	moveCardLeftRail: PropTypes.func,
	handleDeleteFundClick: PropTypes.func,
	toggleMarketingPage: PropTypes.func,
	isChecked: PropTypes.bool,
	list: PropTypes.object
};

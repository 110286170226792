import { connect } from 'react-redux';

import * as portfolioActions from '../../../actions/portfolioComparisonActions.js';
import * as actions from '../../../actions/fundComparisonActions.js';

import Portfolio from './PortfolioPage';

import { reportTypes } from "../../../utils/constants";


function mapStateToProps(state) {
	return {
		portfolios: state.portfolioComparisonReducer.singlePortfolioTemplatesResult,
		comparisonPortfolios: state.portfolioComparisonReducer.portfolioComparisonTemplatesResult,
		pdfOutput: state.portfolioComparisonReducer.pdfOutput,
		deleteTemplateDetails: state.fundComparisonReducer.deleteTemplateDetails,
		template: state.fundComparisonReducer.templateResult,
		TemplateId: state.fundComparisonReducer.TemplateId,
		TemplateName: state.fundComparisonReducer.TemplateName,
		errorCode: state.fundComparisonReducer.errorCode,
		fetching: state.fundComparisonReducer.fetching,
		benchmarks: state.fundComparisonReducer.benchmarksResults,
		mfsSiteInactive: state.fundComparisonReducer.mfsSiteInactive,
		mfsUSFvFinactive: state.fundComparisonReducer.mfsUSFvFinactive,
    	mfsUSPvPinactive: state.fundComparisonReducer.mfsUSPvPinactive,
    	mfsMILFvFinactive: state.fundComparisonReducer.mfsMILFvFinactive,
    	mfsMILPvPinactive: state.fundComparisonReducer.mfsMILPvPinactive,
		portfolioContainerIds: state.portfolioComparisonReducer.portfolioConatainerIdsResult,
		mfsUserData: state.fundComparisonReducer.mfsUserData,
		userType: state.fundComparisonReducer.userType,
		xref: state.fundComparisonReducer.xref,
		xrefETF: state.fundComparisonReducer.xrefETF		
	};
}

function mapDispatchToProps(dispatch) {
	return {
		requestGeneratePDFApi: inputParams => {
			return dispatch(portfolioActions.requestPortfolioComparisonPdf(inputParams));
		},
		requestGetTemplatesApi: (reportType,_templateId) => {
			if(reportType === reportTypes.singleportfolio) {
				return dispatch(portfolioActions.requestGetSinglePortfoioTemplatesApi(reportType));
			} else if(reportType == reportTypes.portfoliocomparison){
				return dispatch(portfolioActions.requestGetPortfolioComparisonTemplatesApi(reportType,_templateId));
			}else if(reportType === reportTypes.mil_singleportfolio){
				return dispatch(portfolioActions.requestGetSinglePortfoioTemplatesApi(reportType,_templateId));
			}else if(reportType === reportTypes.mil_portfoliocomparison){
				return dispatch(portfolioActions.requestGetPortfolioComparisonTemplatesApi(reportType,_templateId));
			}
		},
		requestGetTemplatesByIdApi: (templateId, reportType) => {
			return dispatch(actions.requestGetTemplatesByIdApi(templateId, reportType));
		},
		requestDeleteTemplateApi: templateId => {
			return dispatch(actions.requestDeleteTemplateApi(templateId));
		},
		updateTemplateInternalDetails: inputs => {
			return dispatch(actions.updateTemplateInternalDetails(inputs));
		},
		updatePortfolioTemplateInternalDetails: inputs => {
			return dispatch(portfolioActions.updatePortfolioTemplateInternalDetails(inputs));
		},
		requestGetBenchmarksApi: (input, _isMFSInternational, _currency) => { 
			return dispatch(actions.requestGetBenchmarksApi(input, _isMFSInternational, _currency));
		},
		requestMfsSiteInactiveFlag: inputs => {
			return dispatch(actions.requestMfsSiteInactiveFlag());
		},
		requestGetPortfoioContainerIdsApi: () => {
			return dispatch(portfolioActions.requestGetPortfoioContainerIdsApi());
		},
		requestMfsUserData: () => {
			return dispatch(actions.requestMfsUserData());
		},
		setUserType: result => {
			return dispatch(
				actions.setUserType(result));
		},
		requestXrefByTerm: (term) => {
            return dispatch(actions.requestXrefByTerm(term))
		},
		requestXrefByTermETF: (term) => {
            return dispatch(actions.requestXrefByTermETF(term))
        }
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);

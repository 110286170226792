import React from 'react';

const ModuleAddIconPop = props => {
	return (
		<span role="presentation" >
			<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
				<title>Add</title>
				<desc>Created with Sketch.</desc>
				<g id="Add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="Group-16-Copy-14">
						<g id="Group" transform="translate(4.000000, 4.000000)" fill="#FFFFFF">
							<g id="Group-15">
								<rect id="Rectangle-12" x="3.2" y="0" width="1.6" height="8"></rect>
								<rect id="Rectangle-12-Copy" transform="translate(4.000000, 4.000000) rotate(90.000000) translate(-4.000000, -4.000000) " x="3.2" y="0" width="1.6" height="8"></rect>
							</g>
						</g>
						<circle id="Oval" stroke="#FFFFFF" stroke-width="2" cx="8" cy="8" r="7"></circle>
					</g>
				</g>
			</svg>
		</span >
	);
};

export default ModuleAddIconPop;

import React from 'react';

const TriangleGreyImage = () => {
    return (
        <svg width="218px" height="193px" viewBox="0 0 218 193" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Final" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Logout-(Confirmation)" transform="translate(-531.000000, -331.000000)" fill="#A8A6B2" fill-rule="nonzero">
                    <g id="Group-3" transform="translate(341.000000, 331.000000)">
                        <g id="noun_Alert_808455" transform="translate(190.000000, 0.000000)">
                            <g id="Triangle-Greay-" transform="translate(0.000000, 0.500000)">
                                <path d="M200.8525,192 L17.1475,192 C11.0425,192 5.77,188.93913 2.7175,183.652174 C-0.335,178.365217 -0.335,172.243478 2.7175,166.956522 L94.57,8.34782609 C97.6225,3.06086957 102.895,0 109,0 C115.105,0 120.3775,3.06086957 123.43,8.34782609 L215.2825,166.956522 C218.335,172.243478 218.335,178.365217 215.2825,183.652174 C212.23,188.93913 206.68,192 200.8525,192 Z M109,11.1304348 C108.1675,11.1304348 105.67,11.4086957 104.2825,13.9130435 L12.43,172.521739 C11.0425,175.026087 11.875,177.252174 12.43,178.086957 C12.985,178.921739 14.3725,180.869565 17.1475,180.869565 L200.575,180.869565 C203.35,180.869565 205.015,178.921739 205.2925,178.086957 C205.8475,177.252174 206.68,175.026087 205.2925,172.521739 L113.7175,13.9130435 C112.33,11.4086957 109.8325,11.1304348 109,11.1304348 Z" id="Shape" />
                                <rect id="Rectangle" x="103.45" y="69.5652174" width="11.1" height="64" />
                                <rect id="Rectangle" x="103.45" y="144.695652" width="11.1" height="13.9130435" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default TriangleGreyImage;


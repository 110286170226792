import React, { Component } from 'react';
import Card from './card';
import { DropTarget } from 'react-dnd';

class dragContainer extends Component {
	constructor(props) {
		super(props);
		this.state = { cards: this.props.list };
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.list !== this.props.list) {
			this.setState({ cards: this.props.list });
		}
	}

	getElement(count, listType) {
		const blankLiElements = [];
		let text = '';
		let iteration = 0;
		if (listType === 1) {
			text = 'Select a MFS fund';
			iteration = 1 - count;
		} else if (listType === 2) {
			text = 'Select a fund';
			iteration = 4 - count;
		} else if (listType === 3) {
			text = 'Select a benchmark';
			iteration = 1 - count;
		}
		for (let index = 0; index < iteration; index++) {
			blankLiElements.push(<li>{text}</li>);
		}
		return blankLiElements;
	}

	getHeightElement(listType) {
		let heightList = '';
		if (listType === 1 || listType === 3) {
			heightList = '47';
		} else if (listType === 2) {
			heightList = '188';
		}
		return heightList;
	}

	render() {
		const cards = this.props.list;
		const { canDrop, isOver, connectDropTarget } = this.props;
		const isActive = canDrop && isOver;
		const cardLength = cards.length === undefined ? 0 : cards.length;
		const style = {
			width: '100%',
			height: `${this.getHeightElement(this.props.listType)}px`
		};

		return connectDropTarget(
			<ul style={style}>
				{cardLength > 0 ? cards.map((card, i) => {
					return (
						<Card
							index={i}
							listId={this.props.id}
							card={card}
							pushCardLeftRail={this.props.pushCardLeftRail}
							removeCardLeftRail={this.props.removeCardLeftRail}
							moveCardLeftRail={this.props.moveCardLeftRail}
							checkValidation={this.props.checkValidation}
							handleDeleteFundClick={this.props.handleDeleteFundClick} />
					);
				}) : ''}
				{this.getElement(cardLength, this.props.id)}
			</ul>
		);
	}
}

const cardTarget = {
	drop(props, monitor, component) {
		const { id } = props;
		const sourceObj = monitor.getItem();
		let pushed = false;
		if (id !== sourceObj.listId) {
			pushed = true;
		}
		return {
			listId: id,
			pushed: pushed
		};
	}
};
/*eslint-disable */

export default DropTarget('CARD', cardTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop()
}))(dragContainer);

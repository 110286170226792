import React from 'react';
import { PropTypes } from "prop-types";

import { Button, OverlayTrigger, ButtonToolbar, Tooltip, Form, Checkbox } from 'react-bootstrap';

import ClockIcon from '../../common/Icons/clockIcon.js';

import tooltipStyles from '../../../styles/components/fundReturnsTooltip.scss';

class FundReturnsTooltip extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoadcheckboxCheckeding: false,
			show: false
		};
	}

	handleClick = ({ target }) => {
		this.setState(s => ({ target, show: !s.show }));
	};


	tooltip() {
		let enable = false;
		return (
			<Tooltip>
				<div>
					<span className={'title-text-timeperiod-tooltip'} >{this.props.label} </span>
					{this.props.checkboxes.map(item => (
						<div>
							<label key={`chb ${item.key} Year`} >
								<input type={'checkbox'}
									name={item.name} checked={this.props.checkedItems.has(item.name)}
									onChange={(event) => { this.props.handleChange(event) }}
								/>
								<span className={'label-checkbox-timeperiod-tooltip'}>{item.name!= "Longest Available" ? item.name + 'Year' :'Longest Available'}</span>
								<span class="checkmark"></span>
							</label>
						</div>
					))

					}
				</div>

			</Tooltip>
		);
	}

	positionerInstance() {
		return (
			<ButtonToolbar>
				<OverlayTrigger
					ref={this.props.setRef}
					placement="bottom"
					overlay={this.tooltip()}
					rootClose
					trigger="click"
				>
					<ClockIcon click={this.handleClick} />
				</OverlayTrigger>
			</ButtonToolbar>
		);
	}

	render() {
		return <div>{this.positionerInstance()}</div>;
	}
}

FundReturnsTooltip.propTypes = {
	checkedItems: PropTypes.object,
	checkboxes: PropTypes.object,
	handleChange: PropTypes.func,
	setRef: PropTypes.object
};



export default FundReturnsTooltip;


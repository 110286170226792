import React from 'react';

const HorizontalIcon = () => {
	return (
		<svg width="680px" height="2px" viewBox="0 0 1161 2" version="1.1" xmlns="http://www.w3.org/2000/svg" >
			<g id="Table/Line/Horizontal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
				<path d="M0,1.5 L1161,1.5" id="Line-4-Copy" stroke="#D7D7D7"></path>
			</g>
		</svg>
	);
};

export default HorizontalIcon;

import Types from "../actions/types";
import { createReducer } from "reduxsauce";

import INITIAL_STATE from "./initial-state/portfolioComparison";
import {getRandomNumber} from "../utils/commonMethods";

const generatePortfolioComparisonPdf = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    pdfOutput: action.response.data
  });

  const receiveSuccessGetSinglePortfoioTemplatesApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    singlePortfolioTemplatesResult: action.response.data.templates
  });

  const receiveSuccessUpdateInvestmentsDetailsApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    comparisonPortfolioInvestmentResult: action.response.Results
  });

  const receiveSuccessUpdateInvestmentsDetailsWithCloningApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    comparisonPortfolioInvestmentWithCloningResult: action.response.Results
  });
  
  const receiveSuccessGetHypoComparisonAllocationsApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    portfolioAllocationsResult: action.response.Results
  });
  const receiveSuccessGetHypoWorkingCopyContainerIdsApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    workingCopyConatainerIdsResult: action.response.Results
  });

  const receiveSuccessGetPortfoioContainerIdsApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    portfolioConatainerIdsResult: action.response.Results
  });

  const receiveSuccessGetPortfolioComparisonTemplatesApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    portfolioComparisonTemplatesResult: action.response.data.templates
  });

  const receiveSuccessGetInternationalXidByFundShareClaasId = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    xidFundShareClassMappings: action.response.data
  });

  const receiveSuccessGetPortfolioTemplateById = (state, action) => {
    const templateId = action.response.data.templates["0"].templateId;
    const templateName = action.response.data.templates["0"].templateName;
    return Object.assign({}, state, {
      fetching: false,
      error: null,
      portfolioTemplatesResult: action.response.data.templates,
      TemplateId: templateId,
      TemplateName: templateName
    });
  }
  
  
  const receiveSuccessPerformanceAsOfScreenerDataApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    performanceAsOfScreenerData: action.response.data,
  });
  const requestPortfolioComparisonScreenerDataApi = (state, action) =>
  Object.assign({}, state, {
    fetching: true,
    error: null
  });

  const requestPortfolioTwoComparisonScreenerDataApi = (state, action) =>
  Object.assign({}, state, {
    fetching: true,
    error: null
  });
  
  const receiveSuccessPortfolioComparisonScreenerDataApi = (state, action) =>
   Object.assign({}, state, {
    fetching: false,
    error: null,
    portfolioComparisonScreenerData: action.response.data,
  });
  
  const receiveSuccessPortfolioTwoComparisonScreenerDataApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    portfolioTwoComparisonScreenerData: action.response.data,
  });

  const receiveSuccessSaveTemplateDetailsP2Api = (state, action) => {
    const templateId =
      action.response.data.success === 0
        ? state.TemplateId
        : action.response.data.templateId;
    const templateName =
      action.response.data.success === 0
        ? state.TemplateName
        : action.response.data.templateName;
  
    return Object.assign({}, state, {
      fetching: false,
      error: null,
      saveTemplateDetailsP2: action.response.data,
      TemplateId: templateId,
      TemplateName: templateName
    });
  };

  const receiveSuccessSaveComparisonPortfolioTemplateDetailsApi = (state, action) => {
    const templateId =
      action.response.data.success === 0
        ? state.TemplateId
        : action.response.data.templateId;
    const templateName =
      action.response.data.success === 0
        ? state.TemplateName
        : action.response.data.templateName;
  
    return Object.assign({}, state, {
      fetching: false,
      error: null,
      saveComparisonPortfolioTemplateDetails: action.response.data,
      comparisonPortfolioTemplateId: templateId,
      comparisonPortfolioTemplateName: templateName
    });
  };

  
  const receiveSuccessSavePvPComparisonTemplateDetailsApi = (state, action) => {
    const templateId =
      action.response.data.success === 0
        ? state.templateDataset.response.templateId
        : action.response.data.templateId;
    const templateName =
      action.response.data.success === 0
        ? state.templateDataset.response.templateName
        : action.response.data.templateName;
  
    return Object.assign({}, state, {
      fetching: false,
      error: null,
      pvpSaveComparisonTemplateDetails: action.response.data,
      pvpComparisonTemplateId: templateId,
      pvpComparisonTemplateName: templateName
    });
  };

  const updatePortfolioTemplateInternalDetails = (state, result) => {
    let portfoliotemplateId = 0;
    let portfoliotemplateName = "";    
    let portfoliooldestInceptionDates = {};
    if (result && result.response.resetFlag) {
      portfoliotemplateId = 0;
      portfoliotemplateName = "";
      portfoliooldestInceptionDates = {};
    } else {
      portfoliotemplateId = result.response.templateId || result.response.templateId === 0
        ? result.response.templateId
        : state.TemplateId;
        portfoliotemplateName = result.response.templateName || result.response.templateName === ''
        ? result.response.templateName
        : state.TemplateName;
        portfoliooldestInceptionDates = result.response.oldestInceptionDates ? result.response.oldestInceptionDates : state.oldestInceptionDates;
    }
    let output = {
      comparisonPortfolioTemplateId: portfoliotemplateId,
      comparisonPortfolioTemplateName: portfoliotemplateName,
      oldestInceptionDates: portfoliooldestInceptionDates,
    }
    let resetOutput = {
      comparisonPortfolioTemplateId: portfoliotemplateId,
      comparisonPortfolioTemplateName: portfoliotemplateName,
      oldestInceptionDates: portfoliooldestInceptionDates,
      pvpComparisonTemplateId: 0,
      pvpComparisonTemplateName: '',
      TemplateId: -1,
      TemplateName: "",
      templateDataset: {response: {}}
    }
    return Object.assign({}, state, ((result && result.response.resetFlag) ? resetOutput: output));
  };

  const receiveSuccessOldestInceptionDates = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    oldestInceptionDates: action.response.data
  });

  const setTemplateData = (state, result) => {
    return Object.assign({}, state, {
      templateDataset: result
    });
  };

  const receiveSuccessSaveEventApi = (state, action) => {
    return Object.assign({}, state, {
      scheduleCount: action.response.data.scheduleCount, scheduleCountId: getRandomNumber()
    });
  };

  const receiveSuccessSaveWithdrawalEventApi = (state, action) => {
    return Object.assign({}, state, {
      scheduleWithdrawalCount: action.response.data.scheduleCount, scheduleWithdrawalCountId: getRandomNumber()
    });
  };

  const receiveSuccessModifyEventApi = (state, action) => {
    return Object.assign({}, state, {
      modifyScheduleCount: action.response.data.scheduleCount, modifyScheduleCountId: getRandomNumber()
    });
  };

  const receiveSuccessGetEventSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      schedules: result.response.data
    });
  };

  const receiveSuccessGetEventSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      schedulesP2: result.response.data
    });
  };

  const receiveSuccessInvestmentSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      investmentSchedules: result.response.data
    });
  };

  const receiveSuccessInvestmentSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      investmentSchedulesP2: result.response.data
    });
  };

  const receiveSuccessRemoveEventSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      removeScheduleResult: result // {success: true/false, message: '', index: 1/2 }
    });
  };

  const receiveSuccessRemoveEventSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      removeScheduleP2Result: result // {success: true/false, message: '', index: 1/2 }
    });
  };

  /**
   * Fee Schedules
   */

  const receiveSuccessAddEventFeeSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      addFeeScheduleResult: {
        scheduleCount: result.response.data.scheduleCount, 
        feeSchedules: result.response.data.feeSchedules,
        identifier: getRandomNumber()
      } 
    });
  };

  const receiveSuccessAddEventFeeSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      addFeeScheduleP2Result: {
        scheduleCount: result.response.data.scheduleCount, 
        feeSchedules: result.response.data.feeSchedules,
        identifier: getRandomNumber()
      } 
    });
  };

  const receiveSuccessModifyEventFeeSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      modifyFeeScheduleResult: result.response.data.scheduleCount, identifier: getRandomNumber()
    });
  };

  const receiveSuccessModifyEventFeeSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      modifyFeeScheduleP2Result: result.response.data.scheduleCount, identifier: getRandomNumber()
    });
  };

  const receiveSuccessRemoveEventFeeSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      removeFeeScheduleResult: result.response.data.scheduleCount, identifier: getRandomNumber()
    });
  };

  const receiveSuccessRemoveEventFeeSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      removeFeeScheduleP2Result: result.response.data.scheduleCount, identifier: getRandomNumber()
    });
  };

  const receiveSuccessGetEventFeeSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      feeSchedules: result.response.data, identifier: getRandomNumber()
    });
  };

  const receiveSuccessGetEventFeeSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      feeSchedulesP2: result.response.data, identifier: getRandomNumber()
    });
  };
 
  const requestContributionToRiskApi = (state, result) => {
    return Object.assign({}, state, {
      contributionToRisk: result.response.data
    });
  };

  const requestContributionToRiskFinalAllocationApi = (state, result) => {
    return Object.assign({}, state, {
      contributionToRiskFinalAllocation: result.response.data
    });
  };

  const requestContributionToRiskP2Api = (state, result) => {
    return Object.assign({}, state, {
      contributionToRiskP2: result.response.data
    });
  };

  const receiveSuccessRemoveAllEventSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      removeAlleventScheduleResult: result.response.data.scheduleCount, identifier: getRandomNumber()
    });
  };

  const receiveSuccessRemoveAllEventSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      removeAlleventScheduleP2Result: result.response.data.scheduleCount, identifier: getRandomNumber()
    });
  };

  const receiveSuccessGetEventFrontFeeSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      frontFeeSchedules: result.response.data, identifier: getRandomNumber()
    });
  };

  /**
   * Fund Investment Schedules: Modify, Delete
   */

  const receiveSuccessModifyEventFundInvestmentSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      modifyFrontInvestmentScheduleResult: result.response.data, identifier: getRandomNumber()
    });
  };

  const receiveSuccessModifyEventFundInvestmentSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      modifyFrontInvestmentScheduleP2Result: result.response.data, identifier: getRandomNumber()
    });
  };

  const receiveSuccessRemoveEventFundInvestmentSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      removeFrontInvestmentScheduleResult: result.response.data, identifier: getRandomNumber()
    });
  };

  const receiveSuccessRemoveEventFundInvestmentSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      removeFrontInvestmentScheduleP2Result: result.response.data, identifier: getRandomNumber()
    });
  };

  const receiveSuccessBenchmarkLatestDate = (state, result) => {
    return Object.assign({}, state, {
      benchmarkLatestDate: result.response.data, identifier: getRandomNumber()
    });
  };

  const receiveSuccessUpdateEventSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      updateScheduleResult: result
    });
  };
  
  const receiveSuccessWithdrawalSchedulesApi = (state, result) => {
    return Object.assign({}, state, {
      withdrawalSchedules: result.response.data
    });
  };

  const receiveSuccessWithdrawalSchedulesP2Api = (state, result) => {
    return Object.assign({}, state, {
      withdrawalSchedulesP2: result.response.data
    });
  };

  const receiveSuccessFundEventsApi = (state, result) => {
    return Object.assign({}, state, {
      fundEvents: result.response.data
    });
  };

  const receiveFundAmountByDateApi = (state, result) => {
    return Object.assign({}, state, {
      fundsPresentAmountList: result.response.data
    });
  };

  const receiveSuccessFundEventsP2Api = (state, result) => {
    return Object.assign({}, state, {
      fundEventsP2: result.response.data
    });
  };
 
  const resetPortfolioComparisonScreenerData = (state) => {
    return Object.assign({}, state, {
      portfolioComparisonScreenerData: {},
      portfolioTwoComparisonScreenerData: {},
    });
  };

  const ACTION_HANDLERS = {
    [Types.RECEIVE_PORTFOLIOCOMPARISON_PDF]: generatePortfolioComparisonPdf,
    [Types.RECEIVE_SUCCESS_GET_SINGLEPORTFOLIOTEMPLATES]: receiveSuccessGetSinglePortfoioTemplatesApi,
    [Types.RECEIVE_SUCCESS_GET_PORTFOLIO_TEMPLATES_BY_ID]:receiveSuccessGetPortfolioTemplateById,
    [Types.RECIEVE_SUCCESS_GET_PERFORMANCE_AS_OF_DATE]:receiveSuccessPerformanceAsOfScreenerDataApi,
    [Types.REQUEST_SUCCESS_GET_PORTFOLIOCOMPARISON_SCREENERDATA_API]:requestPortfolioComparisonScreenerDataApi,
    [Types.REQUEST_SUCCESS_GET_PORTFOLIOTWOCOMPARISON_SCREENERDATA_API]:requestPortfolioTwoComparisonScreenerDataApi,
    [Types.RECEIVE_SUCCESS_GET_PORTFOLIOCOMPARISON_SCREENER_DATA]:receiveSuccessPortfolioComparisonScreenerDataApi,
    [Types.RECEIVE_SUCCESS_GET_PORTFOLIOTWOCOMPARISON_SCREENER_DATA]:receiveSuccessPortfolioTwoComparisonScreenerDataApi,
    [Types.RECEIVE_SUCCESS_SAVETEMPLATEDETAILSP2]: receiveSuccessSaveTemplateDetailsP2Api,
    [Types.RECEIVE_SUCCESS_GET_PORTFOLIO_COMPARISON_TEMPLATES]: receiveSuccessGetPortfolioComparisonTemplatesApi,
    [Types.RECEIVE_SUCCESS_SAVE_COMPARISON_PORTFOLIO_TEMPLATE_DETAILS]: receiveSuccessSaveComparisonPortfolioTemplateDetailsApi,
    [Types.UPDATE_PORTFOLIOTEMPLATE_INTERNAL_DETAILS]: updatePortfolioTemplateInternalDetails,
    [Types.RECEIVE_SUCCESS_OLDEST_INCEPTION_DATES]: receiveSuccessOldestInceptionDates,
    [Types.RECEIVE_SUCCESS_GET_PORTFOLIOCONTAINERIDS]: receiveSuccessGetPortfoioContainerIdsApi,
    [Types.RECEIVE_SUCCESS_GET_UPDATEINVESTMENTDETAILS]: receiveSuccessUpdateInvestmentsDetailsApi,
    [Types.RECEIVE_SUCCESS_GET_UPDATEINVESTMENTDETAILSWITHCLONING]: receiveSuccessUpdateInvestmentsDetailsWithCloningApi,
    [Types.RECEIVE_SUCCESS_GET_PORTFOLIOALLOCATIONS]: receiveSuccessGetHypoComparisonAllocationsApi,
    [Types.RECEIVE_SUCCESS_GET_WORKINGCOPYCONTAINERIDS]: receiveSuccessGetHypoWorkingCopyContainerIdsApi,
    [Types.RECEIVE_SUCCESS_OLDEST_INCEPTION_DATES]: receiveSuccessOldestInceptionDates,
    [Types.SET_TEMPLATE_DATASET]: setTemplateData,
    [Types.RECEIVE_SUCCESS_SAVEEVENT]:receiveSuccessSaveEventApi,
    [Types.RECEIVE_SUCCESS_SAVEEVENT_WITHDRAWAL]:receiveSuccessSaveWithdrawalEventApi,
    [Types.RECEIVE_SUCCESS_GETEVENTSCHEDULES]:receiveSuccessGetEventSchedulesApi,
    [Types.RECEIVE_SUCCESS_GETEVENTSCHEDULESP2]:receiveSuccessGetEventSchedulesP2Api,
    [Types.RECEIVE_SUCCESS_REMOVEEVENTSCHEDULES]:receiveSuccessRemoveEventSchedulesApi,
    [Types.RECEIVE_SUCCESS_REMOVEEVENTSCHEDULESP2]:receiveSuccessRemoveEventSchedulesP2Api,
    // Fee Schedules
    [Types.RECEIVE_SUCCESS_ADDEVENTFEESCHEDULES]:receiveSuccessAddEventFeeSchedulesApi,
    [Types.RECEIVE_SUCCESS_ADDEVENTFEESCHEDULESP2]:receiveSuccessAddEventFeeSchedulesP2Api,
    [Types.RECEIVE_SUCCESS_MODIFYEVENTFEESCHEDULES]:receiveSuccessModifyEventFeeSchedulesApi,
    [Types.RECEIVE_SUCCESS_MODIFYEVENTFEESCHEDULESP2]:receiveSuccessModifyEventFeeSchedulesP2Api,
    [Types.RECEIVE_SUCCESS_REMOVEEVENTFEESCHEDULES]:receiveSuccessRemoveEventFeeSchedulesApi,
    [Types.RECEIVE_SUCCESS_REMOVEEVENTFEESCHEDULESP2]:receiveSuccessRemoveEventFeeSchedulesP2Api,
    [Types.RECEIVE_SUCCESS_GETEVENTFEESCHEDULES]:receiveSuccessGetEventFeeSchedulesApi,
    [Types.RECEIVE_SUCCESS_GETEVENTFEESCHEDULESP2]:receiveSuccessGetEventFeeSchedulesP2Api,
    [Types.RECEIVE_SUCCESS_MODIFYEVENT]:receiveSuccessModifyEventApi,
    [Types.RECEIVE_SUCCESS_CONTRIBUTION_TO_RISK]:requestContributionToRiskApi,
    [Types.RECEIVE_SUCCESS_CONTRIBUTION_TO_RISKP2]:requestContributionToRiskP2Api,
    [Types.RECEIVE_SUCCESS_CONTRIBUTION_TO_RISK_FINAL_ALLOCATION]: requestContributionToRiskFinalAllocationApi,
    [Types.RECEIVE_SUCCESS_REMOVEALLEVENTSCHEDULES]:receiveSuccessRemoveAllEventSchedulesApi,
    [Types.RECEIVE_SUCCESS_REMOVEALLEVENTSCHEDULESP2]:receiveSuccessRemoveAllEventSchedulesP2Api,
    // Load Fee Schedules (Front, Deferred, Redemption)
    [Types.RECEIVE_SUCCESS_GETEVENTFRONTFEESCHEDULES]:receiveSuccessGetEventFrontFeeSchedulesApi,
    // Fund Investment Schedules: Modify, Delete
    [Types.RECEIVE_SUCCESS_MODIFYEVENTFUNDINVESTMENTSCHEDULES]:receiveSuccessModifyEventFundInvestmentSchedulesApi,
    [Types.RECEIVE_SUCCESS_MODIFYEVENTFUNDINVESTMENTSCHEDULESP2]:receiveSuccessModifyEventFundInvestmentSchedulesP2Api,
    [Types.RECEIVE_SUCCESS_REMOVEEVENTFUNDINVESTMENTSCHEDULES]:receiveSuccessRemoveEventFundInvestmentSchedulesApi,
    [Types.RECEIVE_SUCCESS_REMOVEEVENTFUNDINVESTMENTSCHEDULESP2]:receiveSuccessRemoveEventFundInvestmentSchedulesP2Api,
    [Types.RECEIVE_SUCCESS_GETINVESTMENTSCHEDULES]: receiveSuccessInvestmentSchedulesApi, 
    [Types.RECEIVE_SUCCESS_GETINVESTMENTSCHEDULESP2]: receiveSuccessInvestmentSchedulesP2Api,
    [Types.RECEIVE_SUCCESS_BENCHMARK_LATEST_DATE]: receiveSuccessBenchmarkLatestDate,
    // Maintain Initial Allocation
    [Types.RECEIVE_SUCCESS_UPDATEEVENTSCHEDULES]:receiveSuccessUpdateEventSchedulesApi,
    [Types.RECEIVE_SUCCESS_SAVE_PVP_COMPARISON_TEMPLATE_DETAILS]: receiveSuccessSavePvPComparisonTemplateDetailsApi,
    [Types.RECEIVE_SUCCESS_GETWITHDRAWALSCHEDULES]: receiveSuccessWithdrawalSchedulesApi, 
    [Types.RECEIVE_SUCCESS_GETWITHDRAWALSCHEDULESP2]: receiveSuccessWithdrawalSchedulesP2Api,
    [Types.RECEIVE_SUCCESS_GETFUNDEVENTS]: receiveSuccessFundEventsApi, 
    [Types.RECEIVE_SUCCESS_GETFUNDEVENTSP2]: receiveSuccessFundEventsP2Api,
    [Types.RECEIVE_SUCCESS_GET_FUND_AMOUNT_BY_DATE]: receiveFundAmountByDateApi,
    [Types.RECEIVE_SUCCESS_INTERNATIONAL_XID_BY_FUNDSHARECLASSID]: receiveSuccessGetInternationalXidByFundShareClaasId,
    [Types.RESET_PORTFOLIOCOMPARISON_SCREENERDATA]: resetPortfolioComparisonScreenerData
  };

  export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
import React from 'react';
import { Tooltip } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import Select, { components } from 'react-select';
import * as Constant from '../../../utils/constants';

import InfoTooltip from './InfoTooltip';
import Container from './dragContainer';
import ErrorIcon from '../../common/Icons/ErrorIcon';

import closeSVG from '../../../images/Icon/Close.svg';

import modalStyles from '../../../styles/components/selectFundComparison/LeftRail.scss';

class LeftRail extends React.Component {
	bindBenchmark(card, handleDeleteFundClick) {
		return <li className="border-fund-name">
			<span className={'fund-name'} title={card.symbol}>{card.symbol}</span>
			<img className={'close-image'} src={closeSVG} data-flag={card.fundfamily} data-wsodissue={card.name} data-symbol={card.symbol} data-listId={3} onClick={handleDeleteFundClick} />
		</li>;
	}

	render() {
		const style = {
			width: '100%',
			height: '47 px'
		};
		const DropdownIndicator = props => {
			return (
				components.DropdownIndicator && (
					<components.DropdownIndicator {...props}>
						<i
							className="dropdown"
							aria-hidden="true"
						/>
					</components.DropdownIndicator>
				)
			);
		};

		const customStyles = {
			control: (base, state) => ({
				...base,
				border: '1px solid rgba(34,36,38,.15) !important',
				boxShadow: '0 !important',
				'&:hover': {
					border: '1px solid rgba(34,36,38,.15) !important'
				},
				height: '34px',
				'min-height': '34px'
			}),
			option: (provided, state) => ({
				...provided,
				background: state.isSelected ? '#f4f4f4' : (state.isFocused ? '#f4f4f4' : '#FFF'),
				color: '#333',
				'font-weight': state.isSelected ? 'bold' : 'normal'
			}),
			menu: styles => ({ ...styles, margin: '0px' }),
			noOptionsMessage: styles => ({ ...styles, 'text-align': 'left', color: 'rgba(0,0,0,.4)' })
		}
		const customTheme = {
			borderRadius: 0,
			border: 0,
			colors: {
				primary: '#f4f4f4',
				primary50: '#f4f4f4',
				neutral0: '#FFF',
			},
		}


		const cardLength = this.props.templateResult.funds.benchmark.length === undefined ? 0 : this.props.templateResult.funds.benchmark.length;
		return (
			<div className={'leftRail'}>
				<div className={'left-rail'}>
					<div className={'left-rail-container'}>
						<div className={'helper-icon'}>
							<InfoTooltip />
						</div>
						<div className={'helper-text'}> To get started, select up to five funds and a benchmark.</div>
						{this.props.userType.response === 'International' ? <p>Currency</p> : ''}					
						{this.props.userType.response === 'International' ? <div className={ `label-style ${this.props.disableCurrency ? 'disabledropdown' : ""}` }>
							<Select
								name="Morningstar Overall Rating"
								className={ `dropdown-mfs ${this.props.disableCurrency ? 'disabledropdown' : ""}` }
								theme={customTheme}
								styles={customStyles}
								isSearchable={false}
								defaultValue={{ label: this.props.templateResult.currency, value: this.props.templateResult.currency }}
								value={{ label: this.props.templateResult.currency, value: this.props.templateResult.currency }}
								onChange={this.props.changeCurrency}
								options={Constant.CurrencyList}
								components={{ DropdownIndicator }}
								id="ddnCurrency" />
						</div> : ''}
						<p>MFS Fund in Focus</p>
						<Container moveCardLeftRail={this.props.moveCardLeftRail}
							removeCardLeftRail={this.props.removeCardLeftRail}
							pushCardLeftRail={this.props.pushCardLeftRail} id={1}
							list={this.props.templateResult.funds.fundinfocus}
							checkValidation={this.props.checkValidation}
							handleDeleteFundClick={this.props.handleDeleteFundClick} />
						{this.props.mfsFundError ? <Tooltip placement="right" className="in" id="tooltip-right">
							<div>
								<div className={'error-icon'}><ErrorIcon /></div>
								<div className={'error-message'}>PLEASE ADD A MFS FUND ONLY</div>
							</div>
						</Tooltip> : ''}
						<p>Compared to <br /><span className={'compared-fund-text'}> (up to 4 funds or ETFs)</span></p>
						<Container moveCardLeftRail={this.props.moveCardLeftRail}
							removeCardLeftRail={this.props.removeCardLeftRail}
							pushCardLeftRail={this.props.pushCardLeftRail} id={2}
							list={this.props.templateResult.funds.nonfundinfocus}
							checkValidation={this.props.checkValidation}
							handleDeleteFundClick={this.props.handleDeleteFundClick} />
						<p>Calculation Benchmark</p>
						<ul style={style}>
							{cardLength > 0 ? this.bindBenchmark(this.props.templateResult.funds.benchmark[0], this.props.handleDeleteFundClick) : <li>Select a benchmark</li>}
						</ul>
					</div>

				</div>
			</div>
		);
	}
}

export default LeftRail;

LeftRail.propTypes = {
	handleDeleteFundClick: PropTypes.func,
	checkValidation: PropTypes.func,
	templateResult: PropTypes.shape({
		funds: PropTypes.shape({
			fundinfocus: PropTypes.shape({
				symbol: PropTypes.string,
				wsodissue: PropTypes.string,
				fundfamily: PropTypes.string
			}),
			nonfundinfocus: PropTypes.shape({
				symbol: PropTypes.string,
				wsodissue: PropTypes.string,
				fundfamily: PropTypes.string
			}),
			benchmark: PropTypes.shape({
				symbol: PropTypes.string,
				wsodissue: PropTypes.string,
				fundfamily: PropTypes.string
			})
		})
	})
};

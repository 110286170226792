import React from "react";
import Modal from "react-modal";
import { PropTypes } from "prop-types";
import Select, { components } from "react-select";

import * as Constant from "../../../utils/constants";

import ScreenerFiltersInternational from "./screenerCommonFilterInternational";
import ResultHeader from "./resultHeader";
import ScreenerResultsTableInternational from "./screenerResultsTableInternational";
import BackArrowIcon from "../../common/Icons/BackArrowIcon";
import ResultSetTooLargeIcon from "../../common/Icons/ResultSetTooLargeIcon";
import HorizontalIcon from "../../common/Icons/HorizontalIcon";

import screenerFlyoutStyles from "../../../styles/components/screenerFlyout.scss";

export default class ScreenerFlyoutInternational extends React.Component {
  isDefaultScreenerFilterArguments = true;
  constructor(props) {
    super(props);
    this.state = {
      resetScreenerFilters: true,
      resetFiltersToNone: false,
      openScreenerFlyout: false,
      reloadTable: false,
    };
  }

  resetScreenerFilters = (flag) => {
    if (flag === "None") {
      this.setState((prevState) => {
        return {
          resetScreenerFilters: true,
          reloadTable: false,
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          resetScreenerFilters: false,
          resetFiltersToNone: false,
          reloadTable: true,
        };
      });
    }
  };

  resetTab = () => {
    this.setState(
      {
        resetScreenerFilters: true,
        disableFiltersOnSearchClick: false,
        resetFiltersToNone: true,
        reloadTable: false,
      },
      () => {
        (() => this.props.resetScreenerFiltersOutput())();
      }
    );
  };

  closeScreener = () => {
    this.setState(
      {
        resetScreenerFilters: true,
        resetFiltersToNone: true,
        reloadTable: true,
      },
      () => {
        (() => this.props.closeScreenerFlyout())();
      }
    );
    this.isDefaultScreenerFilterArguments = true;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.screenerFiltersOutput !== null && this.state.reloadTable) {
      this.setState((prevState) => {
        return {
          resetScreenerFilters: false,
          resetFiltersToNone: false,
        };
      });
    }
  }
  updateScreenerFilterArguments = () => {
    this.isDefaultScreenerFilterArguments = false;
  };

  render() {
    return (
      <Modal
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        isOpen={this.props.openScreenerFlyout}
        style={this.props.customStyles}
        onRequestClose={this.closeScreener}
      >
        <div className={"screenerflyout-styles"}>
          <span>
            <span
              className={"screener-flyout-title-backArrow"}
              onClick={this.closeScreener}
            >
              <BackArrowIcon />
            </span>
            <span className={"screener-flyout-title"}>
              Filter to find funds or ETFs
            </span>
          </span>
          <span className={"screener-flyout-horizontal"}>
            <HorizontalIcon />
          </span>
          <div className={"filter-styles"}>
            <ScreenerFiltersInternational
              screenerMasterData={this.props.screenerMasterData}
              requestScreenerFiltersDataApi={
                this.props.requestScreenerFiltersDataApi
              }
              updateScreenerFilterArguments={this.updateScreenerFilterArguments}
              resetScreenerFilters={this.resetScreenerFilters}
              resetFiltersToNone={this.state.resetFiltersToNone}
              selectedTab={this.props.selectedTab}
              selectedTabForScreenerData={this.props.selectedTabForScreenerData}
              setSelectedTabForScreenerData={
                this.props.setSelectedTabForScreenerData
              }
              resetScreenerFiltersOutput={this.props.resetScreenerFiltersOutput}
            />
          </div>
          <div className={"filter-result-table"}>
            <ResultHeader
              rowCount={
                this.state.resetScreenerFilters ||
                this.isDefaultScreenerFilterArguments
                  ? "0"
                  : this.props.screenerFiltersOutput.matches > 200
                  ? this.props.screenerFiltersOutput.matches
                  : this.props.screenerFiltersOutput.rows
                  ? this.props.screenerFiltersOutput.rows.length
                  : this.props.screenerFiltersOutput.matches
              }
              resetTab={this.resetTab}
              showResetButton={true}
              componentType={"ScreenerFlyOut"}
            />

            {this.props.screenerFiltersOutput.matches >
              Constant.MAX_SCREENER_OUTPUT_LIMIT &&
            !this.state.resetScreenerFilters &&
            !this.isDefaultScreenerFilterArguments ? (
              <div className={"resultSetTooLargeIconStyles"}>
                <ResultSetTooLargeIcon />
              </div>
            ) : (
              <ScreenerResultsTableInternational
                fundtype="Funds & ETFs"
                addFundsToFundResultsTable={
                  this.props.addFundsToFundResultsTable
                }
                screenerData={
                  this.state.resetScreenerFilters ||
                  this.isDefaultScreenerFilterArguments
                    ? []
                    : this.props.screenerFiltersOutput
                }
                //									screenerData={this.state.resetScreenerFilters ? [] : cloneDeep(this.props.screenerFiltersOutput)}

                resetScreenerFilters={this.state.resetScreenerFilters}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

ScreenerFlyoutInternational.propTypes = {
  openScreenerFlyout: PropTypes.bool,
  customStyles: PropTypes.object,
  logoutUser: PropTypes.func,
  closeScreenerFlyout: PropTypes.func,
  requestScreenerFiltersDataApi: PropTypes.func,
  selectedTabForScreenerData: PropTypes.string,
  selectedTab: PropTypes.string,
  setSelectedTabForScreenerData: PropTypes.func,
  resetScreenerFilters: PropTypes.func,
  screenerFiltersOutput: PropTypes.Array,
  resetScreenerFiltersOutput: PropTypes.func,
  addFundsToFundResultsTable: PropTypes.func,
};

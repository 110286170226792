import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';

import genericModalStyles from '../../../styles/components/modal/SaveChangesAlertModal.scss';

class SaveChangesAlertModal extends React.PureComponent {
	render() {
		return (
			<Modal shouldCloseOnOverlayClick shouldCloseOnEsc
				isOpen={this.props.isAlertModalOpen} style={this.props.saveAlertModalStyles} >
				<div className={'SaveChangesAlertModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'header-text'}>Save Changes</div>
							</div>
						</div>
						<div className={'lbl-alert-template'}> You have changes that will be lost if you dont save them. Do you want to save your changes now?</div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<button onClick={this.props.closeAlertModal} className={'alert-cancel-button'}>CANCEL</button>
								<button onClick={this.props.dontSaveModal} className={'alert-dont-save-button'}>DON'T SAVE</button>
								<button onClick={this.props.openSaveOrReplaceModal} className={'alert-save-button'}>SAVE</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>

		);
	}
}

SaveChangesAlertModal.propTypes = {
	isAlertModalOpen: PropTypes.bool,
	saveAlertModalStyles: PropTypes.object,
	closeAlertModal: PropTypes.func,
	dontSaveModal: PropTypes.func,
	openSaveOrReplaceModal: PropTypes.func
};

export default SaveChangesAlertModal;


import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';

import genericModalStyles from '../../../styles/components/modal/benchmarkConfirmationModal.scss';

class BenchmarkConfirmationModal extends React.PureComponent {
	render() {
		let indexQuoteStyle = {
			'pointer-events': 'none',
			opacity: 0.4
		};
		if (!this.props.isSaveDisabled) {
			indexQuoteStyle = {};
		}

		return (
			<Modal
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				isOpen={this.props.benchmarkModalIsOpen}
				style={this.props.customStyles}
			>
                <div className={'benchmarkModal'}>
                    <div className={'main-container'}>
                        <div className={'headerContainer'}>
                            <div className={'header'}>
                                <div className={'header-text'}>Alert(s)</div>
                            </div>
                        </div>
                        <div className={'benchmarkMainModal'}>
                            <div className={'div-errormessage'}>
								{this.props.errorMessages && this.props.errorMessages.map((item, index) => {
									if (index < 1){
										return <div>{item}</div>
									}
								})}
                            </div>
                        </div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
							<button
									onClick={this.props.reviewValidationModal}
									style={indexQuoteStyle}
									className={'review-button'}
								>
									REVIEW FUNDS
								</button>
								<button
									onClick={this.props.continueValidationModal}
									style={indexQuoteStyle}
									className={'continue-button'}
								>
									CONTINUE TO MODULE SELECTION
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

BenchmarkConfirmationModal.propTypes = {
	benchmarkModalIsOpen: PropTypes.bool,
	customStyles: PropTypes.object,
    reviewValidationModal: PropTypes.func,
	errorMessages: PropTypes.array,
	continueValidationModal: PropTypes.func
};

export default BenchmarkConfirmationModal;

import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';

import errorMessageModalStyles from '../../../styles/components/modal/signInErrorMessageModal.scss';

class SignInErrorMessageModal extends React.PureComponent {
	render() {
		return (
			<Modal
				shouldCloseOnOverlayClick
				shouldCloseOnEsc
				isOpen={this.props.isOpen}
				style={this.props.customStyles}
			>
				<div className={'errorMessageModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'header-text'}>{this.props.requiredErrorHeader}</div>
							</div>
						</div>
						<div className={'error-message'}>
							{this.props.errorMessage}
						</div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<div
									onClick={this.props.closeAlertModal}
									className={'ok-button'}
								>
									<span>OK</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

SignInErrorMessageModal.propTypes = {
	isOpen: PropTypes.bool,
	customStyles: PropTypes.object,
	openAlertModal: PropTypes.func,
	closeAlertModal: PropTypes.func,
	errorMessage: PropTypes.string,
	requiredErrorHeader: PropTypes.string
};

export default SignInErrorMessageModal;

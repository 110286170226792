import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';

import gripperImg from '../../../images/Icon/Grey.png';
import closeSVG from '../../../images/Icon/Close.svg';

/*eslint-disable*/
const style = {
	cursor: 'move'
};

class card extends Component {
	render() {
		const { card, isDragging, connectDragSource, connectDropTarget } = this.props;
		const opacity = isDragging ? 0 : 1;

		return connectDragSource(connectDropTarget(
			<li className="border-fund-name">
				<img className={'gripper-image'} src={gripperImg} />
				<span className={'fund-name'} title={card.isInternationalUser && card.isInternationalUser.toString() !== 'false' ? card.name : card.symbol}>{card.isInternationalUser && card.isInternationalUser.toString() !== 'false' ? card.name : card.symbol}</span>
				<img className={'close-image'} src={closeSVG} data-flag={card.fundfamily} data-symbol={card.symbol} data-wsodIssue={card.wsodissue} data-listId={this.props.listId} onClick={this.props.handleDeleteFundClick} />
			</li>
		));
	}
}

const cardSource = {

	beginDrag(props) {
		return {
			index: props.index,
			listId: props.listId,
			card: props.card
		};
	},

	endDrag(props, monitor) {
		const item = monitor.getItem();
		const dropResult = monitor.getDropResult();

		if (dropResult && dropResult.listId !== item.listId) {
			if (dropResult.pushed === true) {
				if (props.checkValidation(item.listId, dropResult.listId, item.card)) {
					props.removeCardLeftRail(item.index, item.listId);
					props.pushCardLeftRail(item.card, dropResult.listId);
				}
			}
		}
	}
};

const cardTarget = {

	hover(props, monitor, component) {
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;
		const sourceListId = monitor.getItem().listId;

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return;
		}

		// Determine rectangle on screen
		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

		// Determine mouse position
		const clientOffset = monitor.getClientOffset();

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top;

		// Only perform the move when the mouse has crossed half of the items height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			return;
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
			return;
		}

		// Time to actually perform the action
		if (props.listId === sourceListId) {
			props.moveCardLeftRail(dragIndex, hoverIndex, sourceListId);
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			monitor.getItem().index = hoverIndex;
		}
	}
};
export default flow(
  DropTarget('CARD', cardTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  })),
  DragSource('CARD', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)(card)

import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';

class GenericModal extends React.Component {
    constructor(props) {
        super(props);


    }

    render() {
        let indexQuoteStyle = {
            'pointer-events': 'none',
            opacity: 0.4
        };
        let indexQuoteStyleP2 = {
            'pointer-events': 'none',
            opacity: 0.4
        };
        let indexQuoteStyleAll = {
            'pointer-events': 'none',
            opacity: 0.4
        };
        let p1Status = false, p2Status = false;
        if (!this.props.isSaveDisabled || this.props.p1Count == 0) {
            indexQuoteStyle = {};
            p1Status = true;
        }
        if (!this.props.isSaveDisabledP2 || this.props.p2Count == 0) {
            indexQuoteStyleP2 = {};
            p2Status = true;
        }
        if (p1Status && p2Status) {
            indexQuoteStyleAll = {};
        }

        return (
            <Modal
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                isOpen={this.props.saveModalIsOpen}
                style={this.props.customStyles}
            >
                <div className={'saveComparisonPortfoliosModal'}>
                    <div className={'main-container'}>
                        <div className={'headerContainer'}>
                            <div className={'header'}>
                                <div className={'header-text'}>{this.props.isPortfoliosToSave ? 'Save Portfolios' : 'Save Portfolio'}</div>
                            </div>
                        </div>
                        <div className={'searchMainModal'}>
                            <span className={'lbl-template'}>Portfolio 1 </span>
                            <span className={'lbl-errormessage'}>{this.props.status}</span>
                            {(this.props.p1Count != 0) ?
                                <div>
                                    <div className={'searchText'}>
                                        {((this.props.templateIdP1)) ?
                                            (<div>
                                                <div className={'lblText'}>{'Would you like to replace  "' + this.props.p1Name + '"  or save as a new portfolio?'} </div>
                                                <div style={{ 'padding-bottom': '5px' }}>
                                                    <input type="radio" className={"radio"} data-portfoliotype="P1" name="Portfolio1" value={'Save as New'} checked={this.props.radioSaveReplaceSelectedP1 == 'Save as New'} onChange={this.props.onSaveReplaceChanged} />
                                                    <label for="Save2">Save as New</label>
                                                    <input type="radio" className={"radio marginLeft-25"} data-portfoliotype="P1" name="Portfolio1" value={'Replace'} checked={this.props.radioSaveReplaceSelectedP1 == 'Replace'} onChange={this.props.onSaveReplaceChanged} />
                                                    <label for="Replace2">Replace</label>
                                                </div>
                                            </div>) : null}
                                        <div className={'text'}>
                                            {this.props.radioSaveReplaceSelectedP1 == 'Replace' ?
                                                (<input
                                                    autoComplete="off"
                                                    data-name="search"
                                                    data-portfoliotype="P1"
                                                    type="text"
                                                    id="search"
                                                    placeholder={"Enter Portfolio Name"}
                                                    className={'template-name-text'}
                                                    onChange={this.props.handleInputChangePortfolio}
                                                    onKeyPress={this.props.handleEnterClickOnTextbox}
                                                    value={this.props.p1Name}
                                                    disabled
                                                />) :
                                                (<input
                                                    autoComplete="off"
                                                    data-name="search"
                                                    data-portfoliotype="P1"
                                                    type="text"
                                                    id="search"
                                                    placeholder={"Enter Portfolio Name"}
                                                    className={'template-name-text'}
                                                    onChange={this.props.handleInputChangePortfolio}
                                                    onKeyPress={this.props.handleEnterClickOnTextbox}

                                                />)}
                                        </div>
                                    </div>

                                    <div className={'searchDescription'}>
                                        <div className={'text'}>
                                            {this.props.radioSaveReplaceSelectedP1 == 'Replace' ?
                                                (<input
                                                    autoComplete="off"
                                                    data-name="description"
                                                    data-portfoliotype="P1"
                                                    type="text"
                                                    id="txt-description"
                                                    placeholder="Enter a description of 120 characters or less"
                                                    className={'template-name-text'}
                                                    onChange={this.props.handleInputChangePortfolio}
                                                    onKeyPress={this.props.handleEnterClickOnTextbox}
                                                    value={this.props.p1Description}
                                                    disabled
                                                />) :
                                                (<input
                                                    autoComplete="off"
                                                    data-name="description"
                                                    data-portfoliotype="P1"
                                                    type="text"
                                                    id="txt-description"
                                                    placeholder="Enter a description of 120 characters or less"
                                                    className={'template-name-text'}
                                                    onChange={this.props.handleInputChangePortfolio}
                                                    onKeyPress={this.props.handleEnterClickOnTextbox}

                                                />)}
                                        </div>
                                        <div className={'btnSaveDiv'}>
                                            <button
                                                data-portfoliotype="P1"
                                                onClick={this.props.radioSaveReplaceSelectedP1 == 'Replace' ? this.props.replaceTemplateSettings : this.props.saveTemplateSettings}
                                                style={indexQuoteStyle}
                                                className={'save-individual-button'}
                                            >
                                                SAVE
                                    </button>
                                        </div>
                                    </div>

                                </div>
                                : <div className={'empty-lbl-template'}>No funds have been selected for this portfolio.</div>
                            }
                        </div>

                        <div className={'searchMainModal'}>
                            <span className={'lbl-template'}>Portfolio 2 </span>
                            <span className={'lbl-errormessage'}>{this.props.statusP2}</span>
                            {(this.props.p2Count != 0) ?
                                <div>
                                    <div className={'searchText'}>
                                        {((this.props.templateIdP2)) ?
                                            (<div>
                                                <div className={'lblText'}>{'Would you like to replace "' + this.props.p2Name + '" or save as a new portfolio?'} </div>
                                                <div style={{ 'padding-bottom': '5px' }}>
                                                    <input type="radio" className={"radio"} data-portfoliotype="P2" name="Portfolio2" value={'Save as New'} checked={this.props.radioSaveReplaceSelectedP2 == 'Save as New'} onChange={this.props.onSaveReplaceChanged} />
                                                    <label for="Save2">Save as New</label>
                                                    <input type="radio" className={"radio marginLeft-25"} data-portfoliotype="P2" name="Portfolio2" value={'Replace'} checked={this.props.radioSaveReplaceSelectedP2 == 'Replace'} onChange={this.props.onSaveReplaceChanged} />
                                                    <label for="Replace2">Replace</label>
                                                </div>
                                            </div>) : null}

                                        <div className={'text'}>
                                            {this.props.radioSaveReplaceSelectedP2 == 'Replace' ?
                                                (<input
                                                    autoComplete="off"
                                                    data-name="search"
                                                    data-portfoliotype="P2"
                                                    type="text"
                                                    id="searchP2"
                                                    placeholder={"Enter Portfolio Name"}
                                                    className={'template-name-text'}
                                                    onChange={this.props.handleInputChangePortfolio}
                                                    value={this.props.p2Name}
                                                    disabled
                                                />) :
                                                (<input
                                                    autoComplete="off"
                                                    data-name="search"
                                                    data-portfoliotype="P2"
                                                    type="text"
                                                    id="searchP2"
                                                    placeholder={"Enter Portfolio Name"}
                                                    className={'template-name-text'}
                                                    onChange={this.props.handleInputChangePortfolio}

                                                />)}
                                        </div>
                                    </div>

                                    <div className={'searchDescription'}>
                                        <div className={'text'}>
                                            {this.props.radioSaveReplaceSelectedP2 == 'Replace' ?
                                                (<input
                                                    autoComplete="off"
                                                    data-name="description"
                                                    data-portfoliotype="P2"
                                                    type="text"
                                                    id="txt-descriptionP2"
                                                    placeholder="Enter a description of 120 characters or less"
                                                    className={'template-name-text'}
                                                    onChange={this.props.handleInputChangePortfolio}
                                                    value={this.props.p2Description}
                                                    disabled
                                                />) :
                                                (<input
                                                    autoComplete="off"
                                                    data-name="description"
                                                    data-portfoliotype="P2"
                                                    type="text"
                                                    id="txt-descriptionP2"
                                                    placeholder="Enter a description of 120 characters or less"
                                                    className={'template-name-text'}
                                                    onChange={this.props.handleInputChangePortfolio}
                                                />)}

                                        </div>
                                        <div className={'btnSaveDiv'}>
                                            <button
                                                data-portfoliotype="P2"
                                                onClick={this.props.radioSaveReplaceSelectedP2 == 'Replace' ? this.props.replaceTemplateSettings : this.props.saveTemplateSettings}
                                                style={indexQuoteStyleP2}
                                                className={'save-individual-button'}
                                            >
                                                SAVE
                                    </button>
                                        </div>
                                    </div>

                                    
                                </div>
                                : <div className={'empty-lbl-template'}>No funds have been selected for this portfolio.</div>}
                        </div>
                        <div className={'footerContainer'}>
                            <div className={'footer'}>
                                <button
                                    onClick={this.props.closeSaveModal}
                                    className={'cancel-button'}
                                >
                                    CLOSE
								</button>
                                <button
                                    onClick={this.props.saveReplaceAllTemplateSettings}
                                    data-portfoliotype="All"
                                    style={indexQuoteStyleAll}
                                    className={'save-button'}
                                >
                                    SAVE ALL
								</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

GenericModal.propTypes = {
    saveModalIsOpen: PropTypes.bool,
    customStyles: PropTypes.object,
    saveTemplateSettings: PropTypes.func,
    closeSaveModal: PropTypes.func,
    handleInputChangePortfolio: PropTypes.func,
    isSaveDisabled: PropTypes.bool,
    status: PropTypes.string,
    handleEnterClickOnTextbox: PropTypes.func,
    isPortfolio: PropTypes.bool,
    onSaveReplaceChanged: PropTypes.func,
    replaceTemplateSettings: PropTypes.func,
    saveReplaceAllTemplateSettings: PropTypes.func
};

export default GenericModal;

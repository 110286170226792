import React from 'react';
import { PropTypes } from 'prop-types';

import * as Constant from '../../../utils/constants';
import tabsStyles from '../../../styles/components/common/subcomponents/Tabs.scss';


export default class Tabs extends React.Component {
	getStyle(item) {
		let disableTabStyle = {
			'pointer-events': 'none',
			opacity: 0.4
		};
		if(this.props.isShowMaintainInitialSection && item.substring(0, 5) === 'Event') {
			return disableTabStyle;
		} else {
			return {};
		}
	}
	getCssClass() {
		let css = this.props.isPortfolio ? 'tab width' : 'tab';
		if(this.props.tabsList && this.props.tabsList.length === 4) {
			css = 'tabFundCompare'
		}
		return css;
	}
	render() {
		let css = this.getCssClass()
		return (
			<div className={'tabs'}>
				{this.props.tabsList && this.props.tabsList.map(
					(item, index) => (
						<div id={item.substring(0, 5)} 
						style={this.getStyle(item)} 
						className={this.props.selectedTab.toString().indexOf(item.substring(0, 5)) > -1 ? css + ' selected' : css} onClick={this.props.onClick}>
							{this.props.linkText ? this.props.linkText(item): item}
							{this.props.selectedTab.toString().indexOf(item.substring(0, 5)) > -1 && <div className={'inner-tab-absolute'}></div>}
						</div>
					)
				)}
			</div>
		);
	}
}

Tabs.propTypes = {
	onClick: PropTypes.func,
	isActive: PropTypes.boolean,
	title: PropTypes.string,
	selectedTab: PropTypes.string,
	isPortfolio: PropTypes.boolean
};

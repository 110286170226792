import React from 'react';

const gripperwhiteTooltipStyles = {
	height: '14px',
	width: '11px'
};


const GripperWhiteTooltipIcon = props => {
	return (
		<svg
			viewBox="0 0 18 18"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={gripperwhiteTooltipStyles}
		>
			<title>Gripper</title>
			<desc>Created with Sketch.</desc>
			<defs />
			<g
				id="Icon/Gripper/White"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
				stroke-linecap="square"
			>
				<g id="Symbols/Gripper" stroke="#F3F4F6" stroke-width="2.25">
					<path d="M1.5,3.85714286 L16.5,3.85714286" id="Line-3-Copy-6" />
					<path d="M1.5,9 L16.5,9" id="Line-3-Copy-7" />
					<path d="M1.5,14.1428571 L16.5,14.1428571" id="Line-3-Copy-8" />
				</g>
			</g>
		</svg>
	);
};

export default GripperWhiteTooltipIcon;

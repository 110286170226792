import React from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import IdleTimer from 'react-idle-timer'

import { DragDropContext as dragDropContext } from 'react-dnd';
import '../node_modules/bootstrap/dist/css/bootstrap.css';

import './App.css';
import * as urlMethods from "../src/utils/urlMethods";
import * as Constants from "../src/utils/constants";

import * as format from './utils/format'

import modalStyles from './styles/components/screenerFilters.scss';
import genericModalStyles from './styles/components/modal/saveModal.scss';
import selectFundsModal from './styles/components/selectFundComparison/SelectFundsModal.scss';

import TagManager from 'react-gtm-module'

class App extends React.PureComponent {
	constructor(props) {
		super(props)
		this.idleTimer = null
	}

	render() {
		return (
			<div>
				<IdleTimer
					ref={ref => { this.idleTimer = ref }}
					element={document}
					onActive={this.onActive}
					onIdle={this.onIdle}
					onAction={this.onAction}
					debounce={250}
					timeout={1000 * 60 * 120} />
				<div>
					{this.props.children}

				</div>

			</div>
		)
	}
	_onAction = (e) => {
		
	}

	_onActive = (e) => {
		
	}

	_onIdle = (e) => {
		console.log('user is idle', e)
		localStorage.setItem('logout-token', format.getQueryString("REF"));
		if (document.querySelector("meta[name='access_token']")) {
			document.querySelector("meta[name='access_token']").value = '';
		}
		localStorage.setItem('access_token', "");
		
		this.props.router.push('/LogOut');
	}
}

function getGADataByEnv(previewOrAuth) {
	const environmentName = urlMethods.getEnvironmentName();
	let auth = '';
	let preview = '';

	switch (environmentName.toLowerCase()) {
		case 'dev':
			auth = 'IxYE6zmMsPg6bAMBMDAUOw';
			preview = 'env-4';
			break;
		case 'qa':
			auth = 'CWVo5YjZjR1TGWHCYnVEYw';
			preview = 'env-3';
			break;
		case 'a2':
			auth = 'eLGE4_2SrtWcYGQClh6FMA';
			preview = 'env-12';
			break;
		case 'prod':
			auth = 'Kl5CUpsP44vCM_10YByaMg';
			preview = 'env-1';
			break;
	}

	if (previewOrAuth === 'auth') {
		return auth;
	} else {
		return preview;
	}
}

const tagManagerArgs = {
    gtmId: 'GTM-5PWXCDG',
    auth: getGADataByEnv('auth'),
    preview: getGADataByEnv('preview')
};

TagManager.initialize(tagManagerArgs);

export default dragDropContext(HTML5Backend)(App);

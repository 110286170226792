import { connect } from 'react-redux';

import * as mfsActions from '../../../actions/fundComparisonActions.js';
import * as portfolioActions from '../../../actions/portfolioComparisonActions.js';

import SelectPortfolioFundsModal from './SelectPortfolioFundsModal';

function mapStateToProps(state) {
	return {
		saveTemplateDetails: state.fundComparisonReducer.saveTemplateDetails,
		screenerOutput: state.fundComparisonReducer.screenerData,
		pdfOutput: state.fundComparisonReducer.pdfOutputOnSelectModal,
		symbolSearchResults: state.fundComparisonReducer.symbolSearchResults,
		wsodIssue: state.fundComparisonReducer.wsodIssue,
		primaryBenchmark: state.fundComparisonReducer.primaryBenchmarksResult,
		screenerMasterData: state.fundComparisonReducer.screenerMasterData,
		TemplateId: state.fundComparisonReducer.TemplateId,
		TemplateName: state.fundComparisonReducer.TemplateName,
		portfolioTemplate: state.portfolioComparisonReducer.portfolioTemplatesResult,
		screenerFiltersOutput: state.fundComparisonReducer.screenerFiltersOutput,
		portfolioComparisonScreenerData: state.portfolioComparisonReducer.portfolioComparisonScreenerData,
		isFetchingPortfolioComparisonData: state.portfolioComparisonReducer.fetching,
		portfolioTwoComparisonScreenerData: state.portfolioComparisonReducer.portfolioTwoComparisonScreenerData,
		benchmarks: state.fundComparisonReducer.benchmarksResults,
		saveTemplateDetailsP2: state.portfolioComparisonReducer.saveTemplateDetailsP2,
		saveComparisonPortfolioTemplateDetails: state.portfolioComparisonReducer.saveComparisonPortfolioTemplateDetails,
		oldestInceptionDates: state.portfolioComparisonReducer.oldestInceptionDates,
		comparisonPortfolioInvestments: state.portfolioComparisonReducer.comparisonPortfolioInvestmentResult,
		workingCopyConatainerIds: state.portfolioComparisonReducer.workingCopyConatainerIdsResult,
		comparisonPortfolioAllocations: state.portfolioComparisonReducer.portfolioAllocationsResult,
		schedules: state.portfolioComparisonReducer.schedules,
		schedulesP2: state.portfolioComparisonReducer.schedulesP2,
		removeScheduleResult: state.portfolioComparisonReducer.removeScheduleResult,
		benchmarkLatestDate: state.portfolioComparisonReducer.benchmarkLatestDate,
		mfsSiteInactive: state.fundComparisonReducer.mfsSiteInactive,
		investmentSchedules: state.portfolioComparisonReducer.investmentSchedules,
		investmentSchedulesP2: state.portfolioComparisonReducer.investmentSchedulesP2,
		withdrawalSchedules: state.portfolioComparisonReducer.withdrawalSchedules,
		withdrawalSchedulesP2: state.portfolioComparisonReducer.withdrawalSchedulesP2,
		removeFrontInvestmentScheduleResult: state.portfolioComparisonReducer.removeFrontInvestmentScheduleResult,
		userType: state.fundComparisonReducer.userType,
		performanceAsOfScreenerData: state.portfolioComparisonReducer.performanceAsOfScreenerData,
		xref: state.fundComparisonReducer.xref,
		xrefETF: state.fundComparisonReducer.xrefETF,
		xidFundShareClassMappings: state.portfolioComparisonReducer.xidFundShareClassMappings
	};
}

function mapDispatchToProps(dispatch) {
	return {
		requestSymbolSearchApi: (input, fundType) => {
			return dispatch(mfsActions.requestSymbolSearchApi(input, fundType));
		},
		requestScreenerDataApi: (screenerType, screenerCriteria, isInternationalUser, currency) => {
			return dispatch(
				mfsActions.requestScreenerDataApi(screenerType, screenerCriteria, isInternationalUser, currency)
			);
		},
		requestGetScreenerMasterDataApi: (fieldname, type, isInternationalUser) => {
			return dispatch(
				mfsActions.requestGetScreenerMasterDataApi(fieldname, type, isInternationalUser));
		},
		resetScreenerFiltersOutput: () => {
			return dispatch(
				mfsActions.resetScreenerFiltersOutput());
		},
		requestDecodeApi: key => {
			return dispatch(mfsActions.requestDecodeApi(key));
		},
		requestScreenerFiltersDataApi: (screenerType, screenerCriteria, isInternationalUser) => {
			return dispatch(
				mfsActions.requestScreenerFiltersDataApi(screenerType, screenerCriteria, isInternationalUser)
			);
		},
		requestSaveTemplateDetailsApi: templateData => {
			return dispatch(mfsActions.requestSaveTemplateDetailsApi(templateData));
		},
		requestSaveTemplateDetailsP2Api: templateData => {
			return dispatch(portfolioActions.requestSaveTemplateDetailsP2Api(templateData));
		},
		requestGetSinglePortfoioTemplatesByIdApi: (templateId, reportType) => {
			return dispatch(portfolioActions.requestGetSinglePortfoioTemplatesByIdApi(templateId, reportType));
		},
		requestPortfolioComparisonScreenerDataApi: (screenerType, screenerCriteria, isInternationalUser) => {
			return dispatch(
				portfolioActions.requestPortfolioComparisonScreenerDataApi(screenerType, screenerCriteria, isInternationalUser)
			);
		},
		requestPortfolioTwoComparisonScreenerDataApi: (screenerType, screenerCriteria, isInternationalUser) => {
			return dispatch(
				portfolioActions.requestPortfolioTwoComparisonScreenerDataApi(screenerType, screenerCriteria, isInternationalUser)
			);
		},
		resetPortfolioComparisonScreenerData: () => {
			return dispatch(
				portfolioActions.resetPortfolioComparisonScreenerData()
			);
		},
		requestGetBenchmarksApi: (input, _isMFSInternational, _currency) => {
			return dispatch(mfsActions.requestGetBenchmarksApi(input, _isMFSInternational, _currency));
		},
		requestGetTemplatesApi: (reportType) => {
			return dispatch(portfolioActions.requestGetSinglePortfoioTemplatesApi(reportType));
		},
		requestSaveComparisonPortfolioTemplateDetailsApi: templateData => {
			return dispatch(portfolioActions.requestSaveComparisonPortfolioTemplateDetailsApi(templateData));
		},
		requestOldestInceptionDatesApi: (wsodIssue, isInternational) => {
			return dispatch(portfolioActions.requestOldestInceptionDatesApi(wsodIssue, isInternational));
		},
		requestUpdateInvestmentsDetailsApi: (inputParams) => {
			return dispatch(portfolioActions.requestUpdateInvestmentsDetailsApi(inputParams));
		},
		requestGetHypoWorkingCopyContainerIdsApi: (containerIds) => {
			return dispatch(portfolioActions.requestGetHypoWorkingCopyContainerIdsApi(containerIds));
		},	
		requestGetHypoComparisonAllocationsApi:(containerIds) => {
			return dispatch(portfolioActions.requestGetHypoComparisonAllocationsApi(containerIds));
		},
		// To set the data in the state
		setTemplateData: result => {
			return dispatch(
				mfsActions.setTemplateDataset(result));
		},
		requestValidRollingReturnPeriodDataApi: (screenerCriteria, isInternationalUser, fundShareClassIds) => {
			return dispatch(
				mfsActions.requestValidRollingReturnPeriodDataApi(screenerCriteria, isInternationalUser, fundShareClassIds, null));
		},
		requestGetEventSchedulesP2Api: (containerId) => {
			return dispatch(portfolioActions.requestGetEventHypotheticalSchedulesP2Api(containerId))
		},
		requestRemoveEventSchedulesApi: (containerId, scheduleId) => {
			return dispatch(portfolioActions.requestRemoveEventHypotheticalSchedulesApi(containerId, scheduleId))
		},
		requestGetEventSchedulesApi: (containerId) => {
			return dispatch(portfolioActions.requestGetEventHypotheticalSchedulesApi(containerId))
		},
		requestRemoveAllEventSchedulesApi: (containerId, eventType) => {
			return dispatch(portfolioActions.requestRemoveAllEventHypotheticalSchedulesApi(containerId, eventType))
		},
		requestRemoveAllEventSchedulesP2Api: (containerId, eventType) => {
			return dispatch(portfolioActions.requestRemoveAllEventHypotheticalSchedulesP2Api(containerId, eventType))
		},
		requestBenchmarkLatestDate: (benchmarkWsodIssues) => {
			return dispatch(portfolioActions.requestBenchmarkLatestDate(benchmarkWsodIssues))
		},
		requestMfsSiteInactiveFlag: inputs => {
			return dispatch(mfsActions.requestMfsSiteInactiveFlag());
		},
		requestGetInvestmentSchedulesApi: (containerId) => {
			return dispatch(portfolioActions.requestGetInvestmentHypotheticalSchedulesApi(containerId))
		},
		requestGetInvestmentSchedulesP2Api: (containerId) => {
			return dispatch(portfolioActions.requestGetInvestmentHypotheticalSchedulesP2Api(containerId))
		},
		requestGetWithdrawalSchedulesApi: (containerId) => {
			return dispatch(portfolioActions.requestGetWithdrawalHypotheticalSchedulesApi(containerId))
		},
		requestGetWithdrawalSchedulesP2Api: (containerId) => {
		return dispatch(portfolioActions.requestGetWithdrawalHypotheticalSchedulesP2Api(containerId))
		},
		requestRemoveEventFrontinvestmentSchedulesApi: (schedules, containerId) => {
			return dispatch(portfolioActions.requestRemoveEventHypotheticalFrontInvestmentSchedulesApi(schedules, containerId))
		},
		requestPerformanceAsOfScreenerDataApi: (screenerType, screenerCriteria, isInternationalUser) => {
			return dispatch(
				portfolioActions.requestPerformanceAsOfScreenerDataApi(screenerType, screenerCriteria, isInternationalUser)
			);
		},
		requestInternationalXidByFundShareClassId: (fundShareClassIds, currency) => {
			return dispatch(
				portfolioActions.requestGetInternationalXidByFundShareClaasIdApi(fundShareClassIds, currency)
			);
		},
		setUserType: result => {
			return dispatch(
				mfsActions.setUserType(result));
		},
        requestXrefByTerm: (term) => {
            return dispatch(mfsActions.requestXrefByTerm(term))
		},
		requestXrefByTermETF: (term) => {
            return dispatch(mfsActions.requestXrefByTermETF(term))
        }
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SelectPortfolioFundsModal);

import React from 'react';

const ErrorIcon = props => {
	return (
		<span role="presentation">
			<svg width="15px" height="15px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" >
				<title>Error Icon</title>
				<desc>Created with Sketch.</desc>
				<defs></defs>
				<g id="Lite-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="Select-Funds-Lite-(Error)" transform="translate(-356.000000, -351.000000)">
						<g id="Group-3" transform="translate(335.000000, 336.000000)">
							<g id="Error-Icon" transform="translate(21.000000, 15.000000)">
								<circle id="Oval" stroke="#FFFFFF" fill-rule="nonzero" cx="10" cy="10" r="9.5"></circle>
								<text id="!" font-family="Calibri-Bold, Calibri" font-size="14" font-weight="bold" fill="#FFFFFF">
									<tspan x="8" y="15">!</tspan>
								</text>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</span >
	);
};

export default ErrorIcon;

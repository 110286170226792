import Types from './types';
import * as urlMethods from "../utils/urlMethods";
import * as format from '../utils/format'

const receivePortfolioComparisonPdf = response => ({
	type: Types.RECEIVE_PORTFOLIOCOMPARISON_PDF,
	response
});

const receiveSuccessGetSinglePortfoioTemplatesApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_SINGLEPORTFOLIOTEMPLATES,
	response
});

const receiveSuccessGetPortfoioContainerIdsApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_PORTFOLIOCONTAINERIDS,
	response
});

const receiveSuccessGetHypoComparisonAllocationsApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_PORTFOLIOALLOCATIONS,
	response
});

const receiveSuccessGetHypoWorkingCopyContainerIdsApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_WORKINGCOPYCONTAINERIDS,
	response
});

const receiveSuccessUpdateInvestmentsDetailsApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_UPDATEINVESTMENTDETAILS,
	response
});

const receiveSuccessUpdateInvestmentsDetailsWithCloningApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_UPDATEINVESTMENTDETAILSWITHCLONING,
	response
});

const receiveSuccessGetPortfolioComparisonTemplatesApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_PORTFOLIO_COMPARISON_TEMPLATES,
	response
});

const requestPortfolioComparisonPdf = inputParams => ({
	type: Types.REQUEST_PORTFOLIOCOMPARISON_PDF_API,
	endPoint: `/GeneratePortfolioComparisonPDF?containerId=${inputParams.containerId}&reportId=${inputParams.reportId}&environmentName=${urlMethods.getEnvironmentName()}&showReportErrors=${format.getQueryString('..showdebuginfo..')}`,
	params:
		inputParams.templateData,
	ajaxType: 'POST',
	onSuccess: receivePortfolioComparisonPdf
});

const requestGetSinglePortfoioTemplatesApi = (reportType) => ({
	type: Types.API_REQUEST_SUCCESS_GET_TEMPLATES_API,
	endPoint: '/getTemplates',
	params: { templateId: 0, reportType: reportType },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetSinglePortfoioTemplatesApi
});

const requestGetPortfoioContainerIdsApi = () => ({
	type: Types.API_REQUEST_SUCCESS_GET_PORTFOLIOCONTAINER_API,
	endPoint: '/getHypoContainerIds',
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetPortfoioContainerIdsApi
});

const requestGetHypoComparisonAllocationsApi = (containerId) => ({
	type: Types.API_REQUEST_SUCCESS_GET_PORTFOLIOALLOCATIONS_API,
	endPoint: '/getHypoComparisonAllocations',
	params: {containerId : containerId },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetHypoComparisonAllocationsApi
});

const requestGetHypoWorkingCopyContainerIdsApi = (containerId) => ({
	type: Types.API_REQUEST_SUCCESS_GET_WORKINGCOPYCONTAINERIDS_API,
	endPoint: '/getHypoWorkingCopyContainerIds',
	params: {containerId : containerId },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetHypoWorkingCopyContainerIdsApi
});

const requestUpdateInvestmentsDetailsApi = (inputParams) => ({
	type: Types.API_REQUEST_SUCCESS_GET_UPDATEINVESTMENTDETAILS_API,
	endPoint: `/updateInvestmentsDetails?containerId=${inputParams.containerIds}&isInternational=${inputParams.isInternational}`,
	params: { templateData: inputParams.templateData },
	ajaxType: 'POST',
	onSuccess: receiveSuccessUpdateInvestmentsDetailsApi
});

const requestUpdateInvestmentsDetailsWithCloningApi = (inputParams) => ({
	type: Types.API_REQUEST_SUCCESS_GET_UPDATEINVESTMENTDETAILSWITHCLONING_API,
	endPoint: `/updateBasicInvestmentsWithCloning?containerId=${inputParams.containerIds}`,
	params: { templateData: inputParams.templateData },
	ajaxType: 'POST',
	onSuccess: receiveSuccessUpdateInvestmentsDetailsWithCloningApi
});

const requestGetSinglePortfoioTemplatesByIdApi = (templateId, reportType) => ({
	type: Types.RECEIVE_SUCCESS_GET_TEMPLATES_BY_ID_API,
	endPoint: '/getTemplates',
	params: { templateId: templateId, reportType: reportType },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetPortfolioTemplatesByIdApi
});

const requestGetPortfolioComparisonTemplatesApi = (reportType,_templateId) => ({
	type: Types.API_REQUEST_SUCCESS_GET_TEMPLATES_API,
	endPoint: '/getTemplates',
	params: { templateId: _templateId, reportType: reportType },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetPortfolioComparisonTemplatesApi
});


const requestPerformanceAsOfScreenerDataApi = (screenerType, screenInput, _isInternationalUser) => ({
	type: Types.REQUEST_SUCCESS_GET_PERFORMANCE_AS_OF_DATE_API,
	endPoint: `${'/screen' + '?type='}${screenerType}&isInternationalUser=${_isInternationalUser}`,
	params: screenInput,
	ajaxType: 'POST',
	onSuccess: receiveSuccessPerformanceAsOfScreenerDataApi
});


const requestPortfolioComparisonScreenerDataApi = (screenerType, screenInput, _isInternationalUser) => ({
	type: Types.REQUEST_SUCCESS_GET_PORTFOLIOCOMPARISON_SCREENERDATA_API,
	endPoint: `${'/screen' + '?type='}${screenerType}&isInternationalUser=${_isInternationalUser}`,
	params: screenInput,
	ajaxType: 'POST',
	onSuccess: receiveSuccessPortfolioComparisonScreenerDataApi
});

const requestPortfolioTwoComparisonScreenerDataApi = (screenerType, screenInput, _isInternationalUser) => ({
	type: Types.REQUEST_SUCCESS_GET_PORTFOLIOTWOCOMPARISON_SCREENERDATA_API,
	endPoint: `${'/screen' + '?type='}${screenerType}&isInternationalUser=${_isInternationalUser}`,
	params: screenInput,
	ajaxType: 'POST',
	onSuccess: receiveSuccessPortfolioTwoComparisonScreenerDataApi
});
const resetPortfolioComparisonScreenerData = () => ({
	type: Types.RESET_PORTFOLIOCOMPARISON_SCREENERDATA
});

const requestSaveTemplateDetailsP2Api = templateData => ({
	type: Types.API_REQUEST_SAVETEMPLATEDETAILSP2_API,
	endPoint: '/saveTemplate',
	params: templateData,
	ajaxType: 'POST',
	onSuccess: receiveSuccessSaveTemplateDetailsP2Api
});
const requestSavePvPComparisonTemplateDetailsApi = templateData => ({
	type: Types.API_REQUEST_SAVEPVPCOMPARISONTEMPLATEDETAILS_API,
	endPoint: '/saveTemplate',
	params: templateData,
	ajaxType: 'POST',
	onSuccess: receiveSuccessSavePvPComparisonTemplateDetailsApi
});
const requestSaveComparisonPortfolioTemplateDetailsApi = templateData => ({
	type: Types.API_REQUEST_SAVECOMPARISONPORTFOLIOTEMPLATEDETAILS_API,
	endPoint: '/saveTemplate',
	params: templateData,
	ajaxType: 'POST',
	onSuccess: receiveSuccessSaveComparisonPortfolioTemplateDetailsApi
});
const receiveSuccessSaveTemplateDetailsP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_SAVETEMPLATEDETAILSP2,
	response
});
const receiveSuccessSaveComparisonPortfolioTemplateDetailsApi = response => ({
	type: Types.RECEIVE_SUCCESS_SAVE_COMPARISON_PORTFOLIO_TEMPLATE_DETAILS,
	response
});

const receiveSuccessSavePvPComparisonTemplateDetailsApi = response => ({
	type: Types.RECEIVE_SUCCESS_SAVE_PVP_COMPARISON_TEMPLATE_DETAILS,
	response
});
const receiveSuccessGetPortfolioTemplatesByIdApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_PORTFOLIO_TEMPLATES_BY_ID,
	response
});

const receiveSuccessPerformanceAsOfScreenerDataApi = response => ({
	type: Types.RECIEVE_SUCCESS_GET_PERFORMANCE_AS_OF_DATE,
	response
});

const receiveSuccessPortfolioComparisonScreenerDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_PORTFOLIOCOMPARISON_SCREENER_DATA,
	response
});

const receiveSuccessPortfolioTwoComparisonScreenerDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_PORTFOLIOTWOCOMPARISON_SCREENER_DATA,
	response
});

const updatePortfolioTemplateInternalDetails = response => ({
	type: Types.UPDATE_PORTFOLIOTEMPLATE_INTERNAL_DETAILS,
	response
});

const requestOldestInceptionDatesApi = (wsodIssue, isInternational) => ({
	type: Types.REQUEST_OLDEST_INCEPTION_DATES_API,
	endPoint: '/getOldestInceptionDates',
	params: { wsodIssue: wsodIssue, isInternational: isInternational },
	ajaxType: 'GET',
	onSuccess: receiveOldestInceptionDatesApi
});

const receiveOldestInceptionDatesApi = response => ({
	type: Types.RECEIVE_SUCCESS_OLDEST_INCEPTION_DATES,
	response
});

const requestContributionToRiskApi = containerIds => ({
	type: Types.API_REQUEST_CONTRIBUTION_TO_RISK_API,
	endPoint: '/getContributionToRisk',
	params: {containerId:containerIds, timeInMonths: 0, dateEnd:0},
	ajaxType: 'GET',
	onSuccess: receiveContributionToRiskApi
});

const requestFundAmountByDateApi = (investmentList, containerId, dateBegin, dateEnd) => ({
	type: Types.API_REQUEST_GET_FUND_AMOUNT_BY_DATE_API,
	endPoint: '/getFundsAmountByDate?containerId='+containerId+'&dateBegin='+dateBegin+'&dateEnd='+dateEnd,
	params: investmentList,
	ajaxType: 'POST',
	onSuccess: receiveFundAmountByDateApi
});

const requestContributionToRiskFinalAllocationApi = (_containerIds, _timeInMonths, _dateEnd) => ({
	type: Types.API_REQUEST_CONTRIBUTION_TO_RISK_FINAL_ALLOCATION_API,
	endPoint: '/getContributionToRisk',
	params: {containerId:_containerIds, timeInMonths: _timeInMonths, dateEnd:_dateEnd},
	ajaxType: 'GET',
	onSuccess: receiveContributionToRiskFinalAllocationApi
});

const requestGetInternationalXidByFundShareClaasIdApi = (fundshareClassIds, currency) => ({
	type: Types.API_REQUEST_INTERNATIONAL_XID_BY_FUNDSHARECLASSID_API,
	endPoint: '/getXidsFromFundShareClassIds',
	params: {fundShareClassIds: fundshareClassIds, currency: currency },
	ajaxType: 'GET',
	onSuccess: receiveSuccessGetInternationalXidByFundShareClaasIdApi
});

const requestContributionToRiskP2Api = containerIds => ({
	type: Types.API_REQUEST_CONTRIBUTION_TO_RISKP2_API,
	endPoint: '/getContributionToRisk',
	params: {containerId:containerIds, timeInMonths: 0, dateEnd:0},
	ajaxType: 'GET',
	onSuccess: receiveContributionToRiskP2Api
});

const receiveContributionToRiskApi = response => ({
	type: Types.RECEIVE_SUCCESS_CONTRIBUTION_TO_RISK,
	response
});

const receiveFundAmountByDateApi = response => ({
	type: Types.RECEIVE_SUCCESS_GET_FUND_AMOUNT_BY_DATE,
	response
});

const receiveContributionToRiskFinalAllocationApi = response => ({
	type: Types.RECEIVE_SUCCESS_CONTRIBUTION_TO_RISK_FINAL_ALLOCATION,
	response
});

const receiveSuccessGetInternationalXidByFundShareClaasIdApi = response => ({
	type: Types.RECEIVE_SUCCESS_INTERNATIONAL_XID_BY_FUNDSHARECLASSID,
	response
});

const receiveContributionToRiskP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_CONTRIBUTION_TO_RISKP2,
	response
});

const setTemplateDataset = response => ({
	type: Types.SET_TEMPLATE_DATASET,
	response
});

const requestSaveEventHypotheticalDetailsApi = (schedule,containerId) => ({
	type: Types.API_REQUEST_SAVEEVENTHYPOTHETICALDETAILS_API,
	endPoint: '/addRebalanceSchedules?containerId='+containerId,
	params: schedule,
	ajaxType: 'POST',
	onSuccess: receiveSuccessEventHypotheticalDetailsApi
});

const requestSaveInvestmentEventApi = (schedule,containerId) => ({
	type: Types.API_REQUEST_SAVEEVENTADDINVESTMENT_API,
	endPoint: '/addInvestmentSchedules?containerId='+containerId,
	params: schedule,
	ajaxType: 'POST',
	onSuccess: receiveSuccessAddInvestmentDetailsApi
});

const receiveSuccessEventHypotheticalDetailsApi = response => ({
	type: Types.RECEIVE_SUCCESS_SAVEEVENT,
	response
});

const receiveSuccessAddInvestmentDetailsApi = response => ({
	type: Types.RECEIVE_SUCCESS_SAVEEVENT,
	response
});

const requestModifyEventHypotheticalDetailsApi = (schedule,containerId) => ({
	type: Types.API_REQUEST_MODIFYEVENTHYPOTHETICALDETAILS_API,
	endPoint: '/modifyRebalanceSchedules?containerId='+containerId,
	params: schedule,
	ajaxType: 'POST',
	onSuccess: receiveSuccessModifyEventHypotheticalDetailsApi
});

const receiveSuccessModifyEventHypotheticalDetailsApi = response => ({
	type: Types.RECEIVE_SUCCESS_MODIFYEVENT,
	response
});

/**
 * Get schedules for the containerId
 * @param {string} containerId ContainerId for which schedules need to be get
 */
const requestGetEventHypotheticalSchedulesApi = (containerId) => ({
	type: Types.API_REQUEST_GETEVENTHYPOTHETICALSCHEDULES_API,
	endPoint: '/getRebalanceSchedules',
	params: {containerId: containerId},
	ajaxType: 'GET',
	onSuccess: receiveSuccessEventHypotheticalSchedulesApi
});

const requestGetInvestmentHypotheticalSchedulesApi = (containerId) => ({
	type: Types.API_REQUEST_GETINVESTMENTHYPOTHETICALSCHEDULES_API,
	endPoint: '/getInvestmentSchedules',
	params: {containerId: containerId},
	ajaxType: 'GET',
	onSuccess: receiveSuccessInvestmentHypotheticalSchedulesApi
});

const requestGetInvestmentHypotheticalSchedulesP2Api = (containerId) => ({
	type: Types.API_REQUEST_GETINVESTMENTHYPOTHETICALSCHEDULESP2_API,
	endPoint: '/getInvestmentSchedules',
	params: {containerId: containerId},
	ajaxType: 'GET',
	onSuccess: receiveSuccessInvestmentHypotheticalSchedulesP2Api
});

const requestGetWithdrawalHypotheticalSchedulesApi = (containerId) => ({
	type: Types.API_REQUEST_GETWITHDRAWALHYPOTHETICALSCHEDULES_API,
	endPoint: '/getWithdrawalSchedules',
	params: {containerId: containerId},
	ajaxType: 'GET',
	onSuccess: receiveSuccessWithdrawalHypotheticalSchedulesApi
});

const requestGetWithdrawalHypotheticalSchedulesP2Api = (containerId) => ({
	type: Types.API_REQUEST_GETWITHDRAWALHYPOTHETICALSCHEDULESP2_API,
	endPoint: '/getWithdrawalSchedules',
	params: {containerId: containerId},
	ajaxType: 'GET',
	onSuccess: receiveSuccessWithdrawalHypotheticalSchedulesP2Api
});

/**
 * Get schedules for the containerId
 * @param {string} containerId ContainerId for which schedules need to be get
 */
const requestGetEventHypotheticalSchedulesP2Api = (containerId) => ({
	type: Types.API_REQUEST_GETEVENTHYPOTHETICALSCHEDULESP2_API,
	endPoint: '/getRebalanceSchedules',
	params: {containerId: containerId},
	ajaxType: 'GET',
	onSuccess: receiveSuccessEventHypotheticalSchedulesP2Api
});

/**
 * Handler 
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalSchedulesApi = response => ({
	type: Types.RECEIVE_SUCCESS_GETEVENTSCHEDULES,
	response
});

const receiveSuccessInvestmentHypotheticalSchedulesApi = response => ({
	type: Types.RECEIVE_SUCCESS_GETINVESTMENTSCHEDULES,
	response
});

const receiveSuccessInvestmentHypotheticalSchedulesP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_GETINVESTMENTSCHEDULESP2,
	response
});

const receiveSuccessWithdrawalHypotheticalSchedulesApi = response => ({
	type: Types.RECEIVE_SUCCESS_GETWITHDRAWALSCHEDULES,
	response
});

const receiveSuccessWithdrawalHypotheticalSchedulesP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_GETWITHDRAWALSCHEDULESP2,
	response
});

/**
 * Handler 
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalSchedulesP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_GETEVENTSCHEDULESP2,
	response
});

/**
 * Remove schedule from the container
 * @param {string} containerId ContainerId from which schedule need to be remove
 * @param {string} scheduleId ScheduleId to be removed
 */
const requestRemoveEventHypotheticalSchedulesApi = (containerId, scheduleId) => ({
	type: Types.API_REQUEST_REMOVEEVENTHYPOTHETICALSCHEDULES_API,
	endPoint: '/removeRebalanceSchedules',
	params: {containerId: containerId, scheduleId: scheduleId},
	ajaxType: 'DELETE',
	onSuccess: receiveSuccessEventHypotheticalRemoveScheduleApi
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalRemoveScheduleApi = response => ({
	type: Types.RECEIVE_SUCCESS_REMOVEEVENTSCHEDULES,
	response
});

/**
 * Remove schedule from the container
 * @param {string} containerId ContainerId from which schedule need to be remove
 * @param {string} scheduleId ScheduleId to be removed
 */
const requestRemoveEventHypotheticalSchedulesP2Api = (containerId, scheduleId) => ({
	type: Types.API_REQUEST_REMOVEEVENTHYPOTHETICALSCHEDULESP2_API,
	endPoint: '/removeRebalanceSchedules',
	params: {containerId: containerId, scheduleId: scheduleId},
	ajaxType: 'DELETE',
	onSuccess: receiveSuccessEventHypotheticalRemoveScheduleP2Api
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalRemoveScheduleP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_REMOVEEVENTSCHEDULESP2,
	response
});

/**
 * Fee Schedules APIs: Add, Modify, Remove, Get
 */

// Fee - Add ===========================================================================
/**
 * Add fee schedule from the container
 * @param {string} containerId ContainerId from which fee schedule need to be add
 * @param {string} scheduleId Fee scheduleId to be add
 */
const requestAddEventHypotheticalFeeSchedulesApi = (containerId, schedules) => ({
	type: Types.API_REQUEST_ADDEVENTHYPOTHETICALFEESCHEDULES_API,
	endPoint: '/addFeeSchedules?containerId='+containerId,
	params: schedules,
	ajaxType: 'POST',
	onSuccess: receiveSuccessEventHypotheticalAddFeeScheduleApi
});

/**
 * Add fee schedule from the container
 * @param {string} containerId ContainerId from which schedule need to be add
 * @param {string} scheduleId Fee scheduleId to be add
 */
const requestAddEventHypotheticalFeeSchedulesP2Api = (containerId, schedules) => ({
	type: Types.API_REQUEST_ADDEVENTHYPOTHETICALFEESCHEDULESP2_API,
	endPoint: '/addFeeSchedules?containerId='+containerId,
	params: schedules,
	ajaxType: 'POST',
	onSuccess: receiveSuccessEventHypotheticalAddFeeScheduleP2Api
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalAddFeeScheduleApi = response => ({
	type: Types.RECEIVE_SUCCESS_ADDEVENTFEESCHEDULES,
	response
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalAddFeeScheduleP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_ADDEVENTFEESCHEDULESP2,
	response
});


// Fee - Modify ========================================================================

/**
 * Modify fee schedule from the container
 * @param {string} containerId ContainerId from which fee schedule need to be modify
 * @param {string} scheduleId Fee scheduleId to be modify
 */
const requestModifyEventHypotheticalFeeSchedulesApi = (containerId, schedule) => ({
	type: Types.API_REQUEST_MODIFYEVENTHYPOTHETICALFEESCHEDULES_API,
	endPoint: '/modifyFeeSchedules?containerId='+containerId,
	params: schedule,
	ajaxType: 'POST',
	onSuccess: receiveSuccessEventHypotheticalModifyFeeScheduleApi
});

/**
 * Modify fee schedule from the container
 * @param {string} containerId ContainerId from which schedule need to be modify
 * @param {string} scheduleId Fee scheduleId to be modify
 */
const requestModifyEventHypotheticalFeeSchedulesP2Api = (containerId, schedule) => ({
	type: Types.API_REQUEST_MODIFYEVENTHYPOTHETICALFEESCHEDULESP2_API,
	endPoint: '/modifyFeeSchedules?containerId='+containerId,
	params: schedule,
	ajaxType: 'POST',
	onSuccess: receiveSuccessEventHypotheticalModifyFeeScheduleP2Api
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalModifyFeeScheduleApi = response => ({
	type: Types.RECEIVE_SUCCESS_MODIFYEVENTFEESCHEDULES,
	response
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalModifyFeeScheduleP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_MODIFYEVENTFEESCHEDULESP2,
	response
});

// Fee - Remove ========================================================================

/**
 * Remove fee schedule from the container
 * @param {string} containerId ContainerId from which fee schedule need to be removed
 * @param {string} scheduleId Fee scheduleId to be removed
 */
const requestRemoveEventHypotheticalFeeSchedulesApi = (containerId, schedules) => ({
	type: Types.API_REQUEST_REMOVEEVENTHYPOTHETICALFEESCHEDULES_API,
	endPoint: '/removeFeeSchedules?containerId='+containerId,
	params: schedules,
	ajaxType: 'DELETE',
	onSuccess: receiveSuccessEventHypotheticalRemoveFeeScheduleApi
});

/**
 * Remove fee schedule from the container
 * @param {string} containerId ContainerId from which schedule need to be remove
 * @param {string} scheduleId Fee scheduleId to be removed
 */
const requestRemoveEventHypotheticalFeeSchedulesP2Api = (containerId, schedules) => ({
	type: Types.API_REQUEST_REMOVEEVENTHYPOTHETICALFEESCHEDULESP2_API,
	endPoint: '/removeFeeSchedules?containerId='+containerId,
	params: schedules,
	ajaxType: 'DELETE',
	onSuccess: receiveSuccessEventHypotheticalRemoveFeeScheduleP2Api
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalRemoveFeeScheduleApi = response => ({
	type: Types.RECEIVE_SUCCESS_REMOVEEVENTFEESCHEDULES,
	response
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalRemoveFeeScheduleP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_REMOVEEVENTFEESCHEDULESP2,
	response
});

// Fee - Get ===========================================================================

/**
 * Get fee schedules for the containerId
 * @param {string} containerId ContainerId for which fee schedules need to be get
 */
const requestGetEventHypotheticalFeeSchedulesApi = (containerId) => ({
	type: Types.API_REQUEST_GETEVENTHYPOTHETICALFEESCHEDULES_API,
	endPoint: '/getFeeSchedules',
	params: {containerId: containerId},
	ajaxType: 'GET',
	onSuccess: receiveSuccessEventHypotheticalFeeSchedulesApi
});

/**
 * Get fee schedules for the containerId
 * @param {string} containerId ContainerId for which fee schedules need to be get
 */
const requestGetEventHypotheticalFeeSchedulesP2Api = (containerId) => ({
	type: Types.API_REQUEST_GETEVENTHYPOTHETICALFEESCHEDULESP2_API,
	endPoint: '/getFeeSchedules',
	params: {containerId: containerId},
	ajaxType: 'GET',
	onSuccess: receiveSuccessEventHypotheticalFeeSchedulesP2Api
});

/**
 * Handler 
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalFeeSchedulesApi = response => ({
	type: Types.RECEIVE_SUCCESS_GETEVENTFEESCHEDULES,
	response
});

/**
 * Handler 
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalFeeSchedulesP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_GETEVENTFEESCHEDULESP2,
	response
});

// ===========================================================================

/**
 * Events - General
 */

/**
 * Remove all schedule from the container
 * @param {string} containerId ContainerId from which events schedule need to be removed
 * @param {string} scheduleId Event scheduleId to be removed
 */
const requestRemoveAllEventHypotheticalSchedulesApi = (containerId, eventType) => ({
	type: Types.API_REQUEST_REMOVEALLEVENTHYPOTHETICALSCHEDULES_API,
	endPoint: '/removeAllEventsSchedules?containerId='+containerId+'&eventType='+eventType,
	params: {},
	ajaxType: 'DELETE',
	onSuccess: receiveSuccessEventHypotheticalRemoveAllEventScheduleApi
});

/**
 * Remove all schedule from the container
 * @param {string} containerId ContainerId from which events schedule need to be removed
 * @param {string} scheduleId Event scheduleId to be removed
 */
const requestRemoveAllEventHypotheticalSchedulesP2Api = (containerId, eventType) => ({
	type: Types.API_REQUEST_REMOVEALLEVENTHYPOTHETICALSCHEDULESP2_API,
	endPoint: '/removeAllEventsSchedules?containerId='+containerId+'&eventType='+eventType,
	params: {},
	ajaxType: 'DELETE',
	onSuccess: receiveSuccessEventHypotheticalRemoveAllEventScheduleP2Api
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalRemoveAllEventScheduleApi = response => ({
	type: Types.RECEIVE_SUCCESS_REMOVEALLEVENTSCHEDULES,
	response
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalRemoveAllEventScheduleP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_REMOVEALLEVENTSCHEDULESP2,
	response
});

// ===========================================================================

/**
 * Fee Schedules - LoadFront, LoadDeferred, LoadRedemption
 */

/**
 * Get front-fee schedules for the wsod Issues
 * @param {string} wsodIssues Comma-separated wsod issues for which front-fee schedules is required
 */
const requestGetEventHypotheticalFrontFeeSchedulesApi = (wsodIssues) => ({
	type: Types.API_REQUEST_GETEVENTHYPOTHETICALFRONTFEESCHEDULES_API,
	endPoint: '/getLoadFees',
	params: { loadFeeType: 'front', wsodIssues: wsodIssues },
	ajaxType: 'GET',
	onSuccess: receiveSuccessEventHypotheticalFrontFeeSchedulesApi
});

/**
 * Handler 
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalFrontFeeSchedulesApi = response => ({
	type: Types.RECEIVE_SUCCESS_GETEVENTFRONTFEESCHEDULES,
	response
});

// ===========================================================================

/**
 * Fund Investment Schedules: Modify, Delete
 */

const requestModifyEventHypotheticalFrontInvestmentSchedulesApi = (schedules,containerId) => ({
	type: Types.API_REQUEST_MODIFYEVENTHYPOTHETICALFRONTINVESTMENTSCHEDULES_API,
	endPoint: '/modifyInvestmentSchedules?containerId='+containerId,
	params: schedules,
	ajaxType: 'POST',
	onSuccess: receiveSuccessModifyEventHypotheticalFundInvestmentSchedulesApi
});

const requestModifyEventHypotheticalFrontInvestmentSchedulesP2Api = (schedules,containerId) => ({
	type: Types.API_REQUEST_MODIFYEVENTHYPOTHETICALFRONTINVESTMENTSCHEDULESP2_API,
	endPoint: '/modifyInvestmentSchedules?containerId='+containerId,
	params: schedules,
	ajaxType: 'POST',
	onSuccess: receiveSuccessModifyEventHypotheticalFundInvestmentSchedulesP2Api
});

const requestRemoveEventHypotheticalFrontInvestmentSchedulesApi = (schedules,containerId) => ({
	type: Types.API_REQUEST_REMOVEEVENTHYPOTHETICALFRONTINVESTMENTSCHEDULES_API,
	endPoint: '/removeInvestmentSchedules?containerId='+containerId+'&schedules='+schedules.join(','),
	params: {},
	ajaxType: 'DELETE',
	onSuccess: receiveSuccessRemoveEventHypotheticalFundInvestmentSchedulesApi
});

const requestRemoveEventHypotheticalFrontInvestmentSchedulesP2Api = (schedules,containerId) => ({
	type: Types.API_REQUEST_REMOVEEVENTHYPOTHETICALFRONTINVESTMENTSCHEDULESP2_API,
	endPoint: '/removeInvestmentSchedules?containerId='+containerId+'&schedules='+schedules.join(','),
	params: {},
	ajaxType: 'DELETE',
	onSuccess: receiveSuccessRemoveEventHypotheticalFundInvestmentSchedulesP2Api
});

const requestBenchmarkLatestDate = input => ({
	type: Types.API_REQUEST_BENCHMARK_LATEST_DATE_API,
	endPoint: '/getBenchmarkLatestDate',
	params: { wsodIssueList: input },
	ajaxType: 'GET',
	onSuccess: receiveBenchmarkLatestDate
});

const receiveBenchmarkLatestDate = response => ({
	type: Types.RECEIVE_SUCCESS_BENCHMARK_LATEST_DATE,
	response
});

const receiveSuccessModifyEventHypotheticalFundInvestmentSchedulesApi = response => ({
	type: Types.RECEIVE_SUCCESS_MODIFYEVENTFUNDINVESTMENTSCHEDULES,
	response
});

const receiveSuccessModifyEventHypotheticalFundInvestmentSchedulesP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_MODIFYEVENTFUNDINVESTMENTSCHEDULESP2,
	response
});

const receiveSuccessRemoveEventHypotheticalFundInvestmentSchedulesApi = response => ({
	type: Types.RECEIVE_SUCCESS_REMOVEEVENTFUNDINVESTMENTSCHEDULES,
	response
});

const receiveSuccessRemoveEventHypotheticalFundInvestmentSchedulesP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_REMOVEEVENTFUNDINVESTMENTSCHEDULESP2,
	response
});

// ===========================================================================
/**
 * Remove events then add events
 * @param {object} updateData data need for remove and add events
 */
const requestUpdateEventHypotheticalSchedulesApi = (updateData) => ({
	type: Types.API_REQUEST_UPDATEEVENTHYPOTHETICALSCHEDULES_API,
	endPoint: '/updateRebalanceSchedules',
	params: {updateData},
	ajaxType: 'POST',
	onSuccess: receiveSuccessEventHypotheticalUpdateScheduleApi
});

/**
 * Handler
 * @param {object} response Response from api call
 */
const receiveSuccessEventHypotheticalUpdateScheduleApi = response => ({
	type: Types.RECEIVE_SUCCESS_UPDATEEVENTSCHEDULES,
	response
});

// ===========================================================================

const requestSaveWithdrawalEventApi = (schedule,containerId) => ({
	type: Types.API_REQUEST_SAVEEVENTADDINVESTMENT_API,
	endPoint: '/addWithdrawalSchedules?containerId='+containerId,
	params: schedule,
	ajaxType: 'POST',
	onSuccess: receiveSuccessAddWithdrawalDetailsApi
});

const receiveSuccessAddWithdrawalDetailsApi = response => ({
	type: Types.RECEIVE_SUCCESS_SAVEEVENT_WITHDRAWAL,
	response
});

// ===========================================================================
const requestGetFundEventsHypotheticalApi = (containerId, dateBegin, dateEnd) => ({
	type: Types.API_REQUEST_GETFUNDEVENTS_API,
	endPoint: '/getFundEvents',
	params: { containerId: containerId, dateBegin: dateBegin, dateEnd: dateEnd },
	ajaxType: 'GET',
	onSuccess: receiveSuccessFundEventsHypotheticalApi
});

const requestGetFundEventsHypotheticalP2Api = (containerId, dateBegin, dateEnd) => ({
	type: Types.API_REQUEST_GETFUNDEVENTSP2_API,
	endPoint: '/getFundEvents',
	params: { containerId: containerId, dateBegin: dateBegin, dateEnd: dateEnd },
	ajaxType: 'GET',
	onSuccess: receiveSuccessFundEventsHypotheticalP2Api
});

const receiveSuccessFundEventsHypotheticalApi = response => ({
	type: Types.RECEIVE_SUCCESS_GETFUNDEVENTS,
	response
});

const receiveSuccessFundEventsHypotheticalP2Api = response => ({
	type: Types.RECEIVE_SUCCESS_GETFUNDEVENTSP2,
	response
});

const requestXrefByTerm = (term) => ({
	type: Types.API_REQUEST_XREF_USER_DATA_API,
	endPoint: 'xref/v1/venues?term="' + encodeURIComponent(term) + '"&classificationIds=5,3&exchangeIds=2825&limit=15',
	ajaxType: 'GET',
	onSuccess: recieveSuccessXrefUserDataApi
  });

const recieveSuccessXrefUserDataApi = response => ({
	type: Types.RECEIVE_SUCCESS_XREF_USER_DATA,
	response
  });

  const requestXrefByTermETF = (term) => ({
	type: Types.API_REQUEST_XREF_USER_DATA_ETF_API,
	endPoint: 'xref/v1/venues?term="' + encodeURIComponent(term) + '"&classificationIds=18&exchangeIds=2825&limit=15',
	ajaxType: 'GET',
	onSuccess: recieveSuccessXrefUserDataApis
  });

const recieveSuccessXrefUserDataApis = response => ({
	type: Types.RECEIVE_SUCCESS_XREF_USER_DATA_ETF,
	response
  });  

export {
    requestPortfolioComparisonPdf,
	receivePortfolioComparisonPdf,
	requestGetSinglePortfoioTemplatesApi,
	requestGetSinglePortfoioTemplatesByIdApi,
	receiveSuccessGetSinglePortfoioTemplatesApi,
	receiveSuccessGetPortfolioTemplatesByIdApi,
	requestPerformanceAsOfScreenerDataApi,
	requestPortfolioComparisonScreenerDataApi,
	receiveSuccessPerformanceAsOfScreenerDataApi,
	receiveSuccessPortfolioComparisonScreenerDataApi,
	receiveSuccessPortfolioTwoComparisonScreenerDataApi,
	requestPortfolioTwoComparisonScreenerDataApi,
	requestSaveTemplateDetailsP2Api,
	receiveSuccessSaveTemplateDetailsP2Api,
	requestGetPortfolioComparisonTemplatesApi,
	receiveSuccessGetPortfolioComparisonTemplatesApi,
	requestSaveComparisonPortfolioTemplateDetailsApi,
	receiveSuccessSaveComparisonPortfolioTemplateDetailsApi,
	updatePortfolioTemplateInternalDetails,
	requestOldestInceptionDatesApi,
	receiveOldestInceptionDatesApi,
	requestGetPortfoioContainerIdsApi,
	receiveSuccessGetPortfoioContainerIdsApi,
	requestUpdateInvestmentsDetailsApi,
	receiveSuccessUpdateInvestmentsDetailsApi,
	requestUpdateInvestmentsDetailsWithCloningApi,
	receiveSuccessUpdateInvestmentsDetailsWithCloningApi,
	requestGetHypoComparisonAllocationsApi,
	receiveSuccessGetHypoComparisonAllocationsApi,
	requestGetHypoWorkingCopyContainerIdsApi,
	receiveSuccessGetHypoWorkingCopyContainerIdsApi,
	setTemplateDataset,
	requestSaveEventHypotheticalDetailsApi,
	receiveSuccessEventHypotheticalDetailsApi,
	requestGetEventHypotheticalSchedulesApi,
	requestRemoveEventHypotheticalSchedulesApi,
	requestRemoveEventHypotheticalSchedulesP2Api,
	requestGetEventHypotheticalSchedulesP2Api,
	receiveSuccessEventHypotheticalSchedulesP2Api,
	// Fee Schedules
	requestAddEventHypotheticalFeeSchedulesApi,
	receiveSuccessEventHypotheticalAddFeeScheduleApi,
	requestAddEventHypotheticalFeeSchedulesP2Api,
	receiveSuccessEventHypotheticalAddFeeScheduleP2Api,
	requestModifyEventHypotheticalFeeSchedulesApi,
	receiveSuccessEventHypotheticalModifyFeeScheduleApi,
	requestModifyEventHypotheticalFeeSchedulesP2Api,
	receiveSuccessEventHypotheticalModifyFeeScheduleP2Api,
	requestRemoveEventHypotheticalFeeSchedulesApi,
	receiveSuccessEventHypotheticalRemoveFeeScheduleApi,
	requestRemoveEventHypotheticalFeeSchedulesP2Api,
	receiveSuccessEventHypotheticalRemoveFeeScheduleP2Api,
	requestGetEventHypotheticalFeeSchedulesApi,
	receiveSuccessEventHypotheticalFeeSchedulesApi,
	requestGetEventHypotheticalFeeSchedulesP2Api,
	receiveSuccessEventHypotheticalFeeSchedulesP2Api,
	requestModifyEventHypotheticalDetailsApi,
	receiveSuccessModifyEventHypotheticalDetailsApi,
	requestContributionToRiskApi,
	requestContributionToRiskP2Api,
	receiveContributionToRiskApi,
	receiveContributionToRiskP2Api,
	requestRemoveAllEventHypotheticalSchedulesApi,
	requestRemoveAllEventHypotheticalSchedulesP2Api,
	receiveSuccessEventHypotheticalRemoveAllEventScheduleApi,
	receiveSuccessEventHypotheticalRemoveAllEventScheduleP2Api,
	// Load Fee Schedules (Front, Deferred, Redemption)
	requestGetEventHypotheticalFrontFeeSchedulesApi,
	receiveSuccessEventHypotheticalFrontFeeSchedulesApi,
	requestSaveInvestmentEventApi,
	// Fund Investment Schedules: Modify, Delete
	requestModifyEventHypotheticalFrontInvestmentSchedulesApi,
	requestModifyEventHypotheticalFrontInvestmentSchedulesP2Api,
	requestRemoveEventHypotheticalFrontInvestmentSchedulesApi,
	requestRemoveEventHypotheticalFrontInvestmentSchedulesP2Api,
	receiveSuccessModifyEventHypotheticalFundInvestmentSchedulesApi,
	receiveSuccessModifyEventHypotheticalFundInvestmentSchedulesP2Api,
	receiveSuccessRemoveEventHypotheticalFundInvestmentSchedulesApi,
	receiveSuccessRemoveEventHypotheticalFundInvestmentSchedulesP2Api,
	requestGetInvestmentHypotheticalSchedulesApi,
	receiveSuccessInvestmentHypotheticalSchedulesApi,
	requestGetInvestmentHypotheticalSchedulesP2Api,
	receiveSuccessInvestmentHypotheticalSchedulesP2Api,
	requestBenchmarkLatestDate,
	receiveBenchmarkLatestDate,
	// Maintain Initial Allocation
	requestUpdateEventHypotheticalSchedulesApi,
	receiveSuccessEventHypotheticalUpdateScheduleApi,
	requestSavePvPComparisonTemplateDetailsApi,
	requestSaveWithdrawalEventApi,
	receiveSuccessAddWithdrawalDetailsApi,
	requestGetWithdrawalHypotheticalSchedulesApi,
	requestGetWithdrawalHypotheticalSchedulesP2Api,
	requestGetFundEventsHypotheticalApi,
	requestGetFundEventsHypotheticalP2Api,
	requestContributionToRiskFinalAllocationApi,
	requestGetInternationalXidByFundShareClaasIdApi,
	receiveSuccessGetInternationalXidByFundShareClaasIdApi,
	requestFundAmountByDateApi,
	receiveFundAmountByDateApi,
	requestXrefByTerm,
	requestXrefByTermETF,
	resetPortfolioComparisonScreenerData
}
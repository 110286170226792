import Types from '../actions/types';

function getApiTypes() {
	const apiTypes = [];
	const apiReg = /_API$/;

	for (const t in Types) {
		if (apiReg.test(t)) {
			apiTypes.push(Types[t]);
		}
	}

	return apiTypes;
}

export { getApiTypes };

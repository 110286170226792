import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import LocalApiService from '../../services/local-api-service';

import SearchForm from './subComponents/searchForm';
import TabularSelectedFunds from './subComponents/tabularSelectedFunds';


export default class FundComparison extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showList: '0',
			products: [],
			errorMsg: '',
			searchText: '',
			searchedItems: [],
			allFunds: [
				{
					symbol: 'ARWFX',
					name: 'American Century Investments One Choice 2025 Portfolio I Class'
				},
				{
					symbol: 'AMHIX',
					name: 'American High-Income Municipal Bond Fund® Class A'
				},
				{
					symbol: 'ABIYX',
					name: 'AB International Value Fund Advisor Class'
				},
				{
					symbol: 'DSEFX',
					name: 'Domini Impact Equity Fund Investor Shares'
				},
				{
					symbol: 'DXRLX',
					name: 'Direxion Monthly Small Cap Bull 2X Fund'
				},
				{
					symbol: 'RBCGX',
					name: 'Reynolds Blue Chip Growth Fund'
				},
				{
					symbol: 'RYFSX',
					name: 'Royce Global Financial Services Fund Service Class'
				},
				{
					symbol: 'SWSCX',
					name: 'Schwab Small-Cap Equity Fund™'
				},
				{
					symbol: 'NCATX',
					name: 'Northern California Tax-Exempt Fund'
				},
				{
					symbol: 'ZFLAX',
					name: 'Ziegler Floating Rate Fund Class A'
				},
				{
					symbol: 'ZMNVX',
					name: 'Zacks Market Neutral Fund Investor Class'
				},
				{
					symbol: 'UBPIX',
					name: 'ProFunds UltraLatin America Fund Investor Class'
				},
				{
					symbol: 'UBPSX',
					name: 'ProFunds UltraLatin America Fund Service Class'
				},
				{
					symbol: 'MALTX',
					name: 'BlackRock Latin America Fund, Inc. Institutional Shares'
				},

				{
					symbol: 'MDLTX',
					name: 'BlackRock Latin America Fund, Inc. Investor A Shares'
				},
				{
					symbol: 'MCLTX',
					name: 'BlackRock Latin America Fund, Inc. Investor C Shares'
				},
				{
					symbol: 'NYSAX',
					name: 'Nysa Fund'
				},
				{
					symbol: 'FLFAX',
					name: 'Fidelity Advisor® Latin America Fund Class A'
				},
				{
					symbol: 'FLFIX',
					name: 'Fidelity Advisor® Latin America Fund Class I'
				},
				{
					symbol: 'FLFTX',
					name: 'Fidelity Advisor® Latin America Fund Class M'
				},
				{
					symbol: 'FLATX',
					name: 'Fidelity® Latin America Fund'
				}
			]
		};
	}

	triggerUpdateList = (e) => {
		const key = e.target.parentElement.getAttribute('data-key');
		this.setState({ errorMsg: '' });

		const tempProducts = this.state.products;
		let removeIndex = -1;

		removeIndex = tempProducts.findIndex(i => {
			return i.symbol === key;
		});

		if (removeIndex > -1) {
			tempProducts.splice(removeIndex, 1);
		}
		this.setState({ products: tempProducts });
	}

	handleInputChange = (event) => {
		const inputValue = event;
		if (inputValue !== '') {
			const results = [];
			this.state.allFunds.forEach(item => {
				if (item.symbol.includes(inputValue.toUpperCase())) {
					results.push(item);
				}
			});

			this.setState({
				showList: '1',
				searchText: inputValue,
				searchedItems: results
			});

			this.setState(prevState => {
				return { searchText: inputValue };
			});
		} else {
			this.setState({
				showList: '1',
				searchText: ''
			});
			this.setState(prevState => {
				return { showList: '0' };
			});
		}
	}

	handleFocusOut = (event) => {
		this.setState({
			showList: '0',
			searchText: ''
		});
	}

	selectSymbol = (event) => {
		this.setState({ errorMsg: '', searchText: '' });
		if (this.state.products.length > 4) {
			this.setState({ errorMsg: 'You can add maximum 5 funds' });
			return;
		}

		const symbol = event.target.parentElement.getAttribute('data-symbol');
		const securityType = event.target.parentElement.lastChild.innerText;

		//To check if item already exist
		const tempProducts = this.state.products;
		const existIndex = tempProducts.map(item => {
			return item.symbol;
		}).indexOf(symbol);

		if (existIndex > -1) {
			this.setState({ errorMsg: 'Selected fund already exist' });
			return;
		}

		const product = {};
		product.symbol = symbol;
		product.name = securityType;
		product.action = '-';

		this.setState({ products: this.state.products.concat(product) });
	}

	validatePost = (e) => {
		this.setState({ errorMsg: '' });
		let count = -1;
		let isValid = false;
		const postUrl = '';
		count = this.state.products.length;

		if (count === 0) {
			this.setState({ errorMsg: 'Select atleast one fund' });
		} else if (count > 5) {
			this.setState({ errorMsg: 'You can add maximum 5 funds' });
		} else {
			isValid = true;
		}

		const tempProducts = this.state.products;
		let funds = '';
		tempProducts.forEach(item => {
			funds += `${item.symbol},`;
		});

		if (!isValid) {
			e.preventDefault();
		}

		if (funds.length > 0) {
			funds = funds.substr(0, funds.length - 1);
			// LocalApiService.getEndpointsPromise()
			// 	.then(responseEndpoints => {
			// 		window.open(`${responseEndpoints.data.MFS}/OldFundComparisonPDF?fundNames=${funds}`);
			// 		return false;
			// 	});
			window.open(`${window.MD.MFS}/OldFundComparisonPDF?fundNames=${funds}`);
		}
		
		return isValid;
	}
	render() {
		if (this.props.fetching) {
			return (
				<div className={'loader'}>
					<div className={'rollingloader'}></div>
				</div>
			);
		}
		return (
			<div>
				<div>
					<Link to="/home">Back</Link>
					<br/>
					<span><h1>Select Fund and Generate Report</h1></span>
				</div>
				<br/>
				<div style={{ color: 'red' }}>{this.state.errorMsg}&nbsp;</div>


				<br/>
				<div style={{ width: '20%', float: 'left' }}>

					<SearchForm onClick={this.selectSymbol} onBlur={this.handleFocusOut} onChange={this.handleInputChange}
						searchItemsStocks={this.state.searchedItems}
						showList={this.state.showList}
						searchText={this.state.searchText} />
					<br/>


				</div>
				<div style={{ paddingLeft: '10%', width: '50%', float: 'left' }}>

					<TabularSelectedFunds products={this.state.products} triggerUpdateList= {this.triggerUpdateList} />
					<div style={{ paddingLeft: '20%' }}>
						<br/>
						<br/>
						<a onClick={this.validatePost} >
							<button>
              Generate Report
							</button>
						</a>
					</div>

				</div>
				<br/>


			</div>
		);
	}
}

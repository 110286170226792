import { connect } from 'react-redux';

import * as actions from '../../actions/fundComparisonActions.js';
import Dashboard from './Dashboard';

function mapStateToProps(state) {
	return {
		errorCode: state.fundComparisonReducer.errorCode,
		mfsSiteInactive: state.fundComparisonReducer.mfsSiteInactive,
		mfsUSFvFinactive: state.fundComparisonReducer.mfsUSFvFinactive,
    	mfsUSPvPinactive: state.fundComparisonReducer.mfsUSPvPinactive,
    	mfsMILFvFinactive: state.fundComparisonReducer.mfsMILFvFinactive,
    	mfsMILPvPinactive: state.fundComparisonReducer.mfsMILPvPinactive,
		siteInactiveId: state.fundComparisonReducer.siteInactiveId,
		mfsUserData: state.fundComparisonReducer.mfsUserData,
		userType: state.fundComparisonReducer.userType
	};
}

function mapDispatchToProps(dispatch) {
	return {
		requestMfsSiteInactiveFlag: inputs => {
			return dispatch(actions.requestMfsSiteInactiveFlag());
		},
		requestMfsUserData: () => {
			return dispatch(actions.requestMfsUserData());
		},
		setUserType: result => {
			return dispatch(
				actions.setUserType(result));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
import React from 'react';

const BackArrowIcon = () => {
	return (
		<span role="presentation">
			<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" >
				<title>Back</title>
				<g id="Icon/BackArrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="Back-Arrow-">
						<circle id="Oval" fill="#7E2D40" cx="10" cy="10" r="10"></circle>
						<g id="arrow-left2" transform="translate(4.000000, 5.000000)" fill="#FFFFFF" fill-rule="nonzero">
							<path d="M4.53679051,9.75593242 L0.251037956,5.58917894 C-0.0836793186,5.26375549 -0.0836793186,4.73624451 0.251037956,4.41082106 L4.53679051,0.244067585 C4.87150778,-0.0813558616 5.41408405,-0.0813558616 5.74880133,0.244067585 C6.0835186,0.569491031 6.0835186,1.09700202 5.74880133,1.42242547 L2.9262047,4.16664931 L11.1428495,4.16664931 C11.6164251,4.16664931 12,4.53957374 12,5 C12,5.46042626 11.6164251,5.83335069 11.1428495,5.83335069 L2.9262047,5.83335069 L5.74880133,8.57757453 C5.91594568,8.74007792 5.99994643,8.9534157 5.99994643,9.16675347 C5.99994643,9.38009125 5.91637425,9.59342903 5.74880133,9.75593242 C5.41408405,10.0813559 4.87150778,10.0813559 4.53679051,9.75593242 Z" id="Path"></path>
						</g>
					</g>
				</g>
			</svg>
		</span >
	);
};
export default BackArrowIcon;

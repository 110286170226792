import "babel-polyfill";
import React from "react";
import { PropTypes } from "prop-types";
import Select, { components } from 'react-select';
import { Link, Redirect } from "react-router";

import xmlToJSON from "xmljson";
import moment from 'moment';
import numeral from 'numeral';
import lodash from 'lodash';
import cloneDeep from "lodash/cloneDeep";

import * as urlMethods from "../../utils/urlMethods";
import * as utilityFunctions from "../../utils/commonMethods";
import * as ValidationMessages from "../../utils/validationMessages";
import * as UsageReporting from "../../utils/usageReporting";
import * as CustomModalStyles from '../../utils/customModalStyles';
import * as Constant from '../../utils/constants';

import Header from "../common/Header";
import LogoutModal from "../common/modal/LogoutModal";
import RightRail from "./SubComponents/rightRailModuleSelection";
import LeftRailModuleSelection from "./SubComponents/leftRailModuleSelection";
import EditComparingSection from "./SubComponents/editComparingSection";
import DownloadingSpinnerModal from "../common/modal/DownloadingSpinnerModal";
import SaveModal from "../common/modal/saveModal.js";
import ReplaceTemplateModal from "../common/modal/ReplaceTemplateModal";
import ConfirmationModal from "../common/modal/ConfirmationModal";
import SaveChangesAlertModal from "../common/modal/SaveChangesAlertModal";

import { reportTypes,portfolioLandingPageTabs } from "../../utils/constants";
import ModuleInfoTooltip from './SubComponents/ModuleInfoTooltip';

import "../../styles/components/ModuleSelection.scss";
import "../../styles/components/Header.scss";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

class ModuleSelection extends React.Component {
  constructor(props, context) {
    UsageReporting.trackPage("Module Selection", "");
    super(props, context);
    this.state = {
      isGeneratePDF: false,
      selectedFunds: "",
      isUserLoggedOut: false,
      downloadSpinnerModalIsOpen: false,
      isSelectFundModalOpen: false,
      isSelectBuildPortfolioModalOpen: false,
      statusMessage: "",
      saveModalIsOpen: false,
      replaceModalIsOpen: false,
      IsconfirmationModalOpen: false,
      isSaveDisabled: true,
      isAlertModalOpen: false,
      moduleTemplateData: {},
      templateName: "",
      shouldLoadDefaultTimePeriods: false,
	  shouldLoadDefaultCMTimePeriods: false,
      enableFundReturns: false,
      timePeriodOptions: [],
      timePeriodDisplayLabel: "",
      checkedItems: new Map(),
      
      enableFundReturnsCM: false,
      timePeriodOptionsCM: [],
      enableFundReturnsCR: false,
      timePeriodOptionsCR: [],
      timePeriodDisplayLabelCM: "",
      checkedItemsCM: new Map(),
      isFromPortfolio: false,
      checkedItemsCR: new Map(),
      
      modalIsPortfolioCompare: false,
      isShowBenchmarkResults: false,
      showHypotheticals: false,
      editComparisonTemplateId:-1,
      editComparisonTemplateName:'',
      comparisonPortfolioSavedBenchmarkResult: {},
      selectedTab: portfolioLandingPageTabs.comparisonportfolio,
      currentP1Name: '',
			currentP2Name: '',
			currentP1Id: -1,
      currentP2Id: -1,
      eventsCount: 0
    };
  }

  UNSAFE_componentWillMount() {
    this.state.isFromPortfolio = (this.props.location.query['p'] === "1");
    try {
      let trackPage = this.state.isFromPortfolio ? 'Portfolio Module Selection': 'Module Selection';
      UsageReporting.trackPage(trackPage, '');
    } catch (error) { }
  }
  callMaintenacePage = () => {
    this.props.router.push('/MaintenancePage');
  }
  resetModuleSelectionFlag = () => {
    this.state.moduleTemplateData.isFromModuleSelection = false;
  }
  resetComparePortfolios = () => {
    this.props.updatePortfolioTemplateInternalDetails({
      templateId: 0,
      templateName: "",
      resetFlag: true,
      pvpComparisonTemplateId: -1,
      pvpComparisonTemplateName: ""
    });
  }
  componentDidMount() {
    
    let tempData = this.state.isFromPortfolio ? this.props.templateDataNew.response.templateDataPortfolio:
                                                this.props.templateDataNew.response.templateData;
    const templateDataset = JSON.parse(tempData);

    const shouldLoadDefaultTimePeriods = JSON.parse(
      this.props.templateDataNew.response.shouldLoadDefaultTimePeriods
    );

	// this paramter is used for fund only
	const shouldLoadDefaultCMTimePeriods = JSON.parse(
	  this.props.templateDataNew.response.shouldLoadDefaultCMTimePeriods
	);

    let funds = null;
    let nonFif = null;
    let comparisonText = '';
    let timeOptions= [];
    if (this.state.isFromPortfolio) {
      let benchmark = '';
      if(templateDataset.benchmark.blendedBenchmarkNames !== ''){
        benchmark = templateDataset.benchmark.blendedBenchmarkNames
      } else {
        benchmark = templateDataset.benchmark.name
      }
      comparisonText = utilityFunctions.getCurrencySymbolFromCurrency(templateDataset.currency)  + ' ' + numeral(templateDataset.totalInvestment).format('0,0.00') + ', ' + 
                      (templateDataset.selectedPeriod.label) + ', ' + 
                      (benchmark);
    } else {
      funds = templateDataset.funds.fundinfocus.symbol;
      nonFif = templateDataset.funds.nonfundinfocus
        .map(elem => {
          return elem.symbol;
        })
        .join(", ");
      comparisonText = `${funds}, ${nonFif}`;
    }

    let inputValues = [];
    templateDataset.sections.forEach((section) => {
      if ((section.sectionname === "Rolling Time Frames" && section.isActive) || 
          (section.sectionname === "Correlation Matrix" && section.isActive) ||
          (section.sectionname === "Contribution to Risk" && section.isActive)) {
        inputValues = section.inputtype[0].inputvalues;
        if (!inputValues.includes(",") && inputValues!='') {
          inputValues = inputValues.replace(/Y/g, '');
          inputValues = inputValues.split(";");
          this.setLoadTimePeriodLabelAndCheckBox(inputValues, true, (section.sectionname === "Correlation Matrix" || "Contribution to Risk"),section.sectionname)
        }
      }
    });

    // move all funds from both portfolios to a single array
    let areAllFundsHaveMin3YrData = false;
    let validTimeOptions = [];
    let eventsCount = 0;
    if (this.state.isFromPortfolio === true) {
      
      let allPortfolioFunds = [];  
      
      for (let i = 0; i < templateDataset.Portfolios.length; i++) {
        for (let j = 0; j < templateDataset.Portfolios[i].Portfolio.length; j++) {
          allPortfolioFunds.push(templateDataset.Portfolios[i].Portfolio[j]);
        }
      }
      
      // remove all funds with zero allocation
      allPortfolioFunds = allPortfolioFunds.filter(fund => { 
        return fund.amountInvestedPercent > 0 
      });

      // Check if all funds have atleast 3 years data (with extended performance data) and also add 
      // those time periods for which all funds have data.
      timeOptions = this.getTimePeriodOptions(templateDataset);

	  if (timeOptions !== "Longest Available") {
		for (let i = 0; i < timeOptions.length; i++) {
			let period = templateDataset.periodList.find(timeperiod => {
			  return timeperiod.label == timeOptions[i]
			});
			if (period && period.value > 0) {
			  let allFundsValid = allPortfolioFunds.filter(fund => { 
				return utilityFunctions.getStartDateOfNextFullMonth(fund.oldestShareClassInceptionDate) <= period.value
			  });
			  if (allFundsValid.length === allPortfolioFunds.length) {
				if (i === 0) { 
				  areAllFundsHaveMin3YrData = true; 
				}
				let timeOption = timeOptions[i].replace(' Years', '');
				validTimeOptions.push({name: parseInt(timeOption), key: parseInt(timeOption)});
			  }
			}
		  }
	  }

      if (shouldLoadDefaultTimePeriods) { // for loading default templates...
        let inputValues ='';
        let last_max_value_in_time_period  = '';
        templateDataset.sections.forEach(item => {
          if(item.sectionname === "Contribution to Risk"){
            if(timeOptions.includes("Longest Available")){
              let allFundsValid = allPortfolioFunds.filter(fund => { 
                return utilityFunctions.getStartDateOfNextFullMonth(fund.oldestShareClassInceptionDate) <= (templateDataset.startPeriod+1)
              });
              //newtemplateData.startPeriod is 1 day less to be saved in backend but for comparison add 1 day              
              if (allFundsValid.length === allPortfolioFunds.length) {
                validTimeOptions.push({name: "Longest Available", key: "Longest Available"});
                last_max_value_in_time_period = "Longest Available";
                inputValues ="Longest Available"; 
                item.inputtype[0].inputvalues = inputValues;
              }
            } else{
              last_max_value_in_time_period = validTimeOptions[validTimeOptions.length - 1]
              if(last_max_value_in_time_period && last_max_value_in_time_period.key){
                inputValues =  last_max_value_in_time_period.key + 'Y';
                item.inputtype[0].inputvalues = inputValues;
              }
            }
            this.setDefaultTimePeriodLabelAndCheckBox(last_max_value_in_time_period, true, true, true,item.sectionname);
        }else if (item.sectionname === "Correlation Matrix" ) {
          last_max_value_in_time_period = validTimeOptions[validTimeOptions.length - 1]
          if(last_max_value_in_time_period && last_max_value_in_time_period.key){
            inputValues =  last_max_value_in_time_period.key + 'Y';
          }
          item.inputtype[0].inputvalues = inputValues;
          this.setDefaultTimePeriodLabelAndCheckBox(last_max_value_in_time_period, true, true, true,item.sectionname);

        }          
        });
      }  
      eventsCount = this.props.templateDataNew.response.eventsCount;
    }

    if (this.props.userType.response === 'International' && !templateDataset.language) {
        templateDataset.language = "en-GB";
    }
    this.setState({
      selectedFunds: comparisonText,
      moduleTemplateData: templateDataset,
      shouldLoadDefaultTimePeriods: shouldLoadDefaultTimePeriods,
      enableFundReturnsCM: areAllFundsHaveMin3YrData,
      timePeriodOptionsCM: validTimeOptions.filter(x=> x.key !== "Longest Available"),
      enableFundReturnsCR: areAllFundsHaveMin3YrData,
      timePeriodOptionsCR: validTimeOptions,
      eventsCount: eventsCount,
	  shouldLoadDefaultCMTimePeriods: shouldLoadDefaultCMTimePeriods
    });
  }

  getTimePeriodOptions(templateDataset){
    const diffInMonths = utilityFunctions.getDiffInMonths(templateDataset.startPeriod, templateDataset.endPeriod);
    let timeOptions = []
    if (diffInMonths >= 36) {
      timeOptions.push("3 Years");
    }
    
    if (diffInMonths >=60) {
      timeOptions.push("5 Years");
    }
    
    if (diffInMonths >= 120) {
      timeOptions.push("10 Years");
    }
 // Showing longest available option in case months falling between 3 year and 5 year , 5 year and 10 year and greater then 10 year
    if ((diffInMonths > 36 && diffInMonths < 60)  || (diffInMonths > 60 && diffInMonths < 120) || diffInMonths > 120 ) {    
      timeOptions.push("Longest Available");
    }

    return timeOptions;
  }

  updatePortfolioNameList = (totalInvestment) => {
    this.props.requestGetTemplatesApi(reportTypes.singleportfolio);
    const inputParams = {
      templateData: JSON.stringify(this.state.moduleTemplateData),
      containerId: this.props.templateDataNew.response.containerId,
      reportId: this.props.templateDataNew.response.reportId,
      templateId: this.props.TemplateId,
      templateName:this.props.TemplateName.trim(),
      shouldLoadDefaultTimePeriods: this.props.templateDataNew.response.shouldLoadDefaultTimePeriods,
	  shouldLoadDefaultCMTimePeriods: this.props.templateDataNew.response.shouldLoadDefaultCMTimePeriods
    };
    inputParams.comparsionWorkingContainerIds=this.props.templateDataNew.response.containerId;
    inputParams.comparisonContainerIds=this.props.templateDataNew.response.containerId;
    inputParams.balanceInitial = totalInvestment;
    this.props.setTemplateData(inputParams);
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) { 
    let validTimeOptionsCM = [];
    if(this.props.ComparisonPortfolioTemplateId != nextProps.ComparisonPortfolioTemplateId) {
      if(nextProps.ComparisonPortfolioTemplateId > 0) {
        const inputParams = {
          templateData: JSON.stringify(this.state.moduleTemplateData),
          
          containerId: this.props.templateDataNew.response.comparisonContainerIds,
					comparsionWorkingContainerIds: this.props.templateDataNew.response.comparsionWorkingContainerIds,
          editComparisonTemplateId: nextProps.ComparisonPortfolioTemplateId,
          editComparisonTemplateName:nextProps.ComparisonPortfolioTemplateName.trim(),
          shouldLoadDefaultTimePeriods: this.props.templateDataNew.response.shouldLoadDefaultTimePeriods,
		  shouldLoadDefaultCMTimePeriods: this.props.templateDataNew.response.shouldLoadDefaultCMTimePeriods,
          balanceInitial:this.props.templateDataNew.response.balanceInitial,
        };
        this.props.setPvPTemplateData(inputParams);
      }
    }
    
    if(this.props.TemplateId != nextProps.TemplateId) {
      if(nextProps.TemplateId > 0) {
        const inputParams = {
          templateData: JSON.stringify(this.state.moduleTemplateData),
          containerId: this.props.templateDataNew.response.containerId,
          reportId: this.props.templateDataNew.response.reportId,
          templateId: nextProps.TemplateId,
          templateName:nextProps.TemplateName.trim(),
          shouldLoadDefaultTimePeriods: this.props.templateDataNew.response.shouldLoadDefaultTimePeriods,
		  shouldLoadDefaultCMTimePeriods: this.props.templateDataNew.response.shouldLoadDefaultCMTimePeriods
        };
        this.props.setTemplateData(inputParams);
      }
    }
    if(this.props.siteInactiveId !== nextProps.siteInactiveId && nextProps.siteInactiveId > 0) {
      if (nextProps.mfsSiteInactive === true) {
        this.props.router.push('/MaintenancePage');
      }
    }

    let tempData = this.state.isFromPortfolio ? (nextProps.templateDataNew.response.templateDataPortfolio?
      nextProps.templateDataNew.response.templateDataPortfolio :nextProps.templateDataNew.response.templateData):
      nextProps.templateDataNew.response.templateData;
    if (this.props.template !== nextProps.template) {
      this.cwrpTemplateDataBind(nextProps);
    }

    let templateDataset = null;
    if(this.props.errorCode != nextProps.errorCode && nextProps.errorCode){
      this.props.router.push({
        pathname: '/Logout',
        search: '?logoutError=true'
        });
    }
    if (!lodash.isEqual(this.props.templateDataNew.response, nextProps.templateDataNew.response
    )) {
      let templateDataset =JSON.parse(tempData);

      const shouldLoadDefaultTimePeriods = JSON.parse(
        nextProps.templateDataNew.response.shouldLoadDefaultTimePeriods
      );

	  const shouldLoadDefaultCMTimePeriods = JSON.parse(
        nextProps.templateDataNew.response.shouldLoadDefaultCMTimePeriods
      );

      let funds = null;
      let nonFif = null;
      let comparisonText = '';

      if (this.state.isFromPortfolio) {
        let benchmark = '';
        if(templateDataset.benchmark.blendedBenchmarkNames !== ''){
          benchmark = templateDataset.benchmark.blendedBenchmarkNames
        } else {
          benchmark = templateDataset.benchmark.name
        }
        comparisonText = utilityFunctions.getCurrencySymbolFromCurrency(templateDataset.currency) + ' ' + numeral(templateDataset.totalInvestment).format('0,0.00') + ', ' + 
                        (templateDataset.selectedPeriod.label) + ', ' + 
                        (benchmark);
      } else {
        funds = templateDataset.funds.fundinfocus.symbol;
        nonFif = templateDataset.funds.nonfundinfocus
          .map(elem => {
            return elem.symbol;
          })
          .join(", ");
          comparisonText = `${funds}, ${nonFif}`;
      }

      // Load sections
      let inputValues = [];

      if (shouldLoadDefaultTimePeriods) {
      templateDataset.sections.forEach((section) => {
        if ((section.sectionname === "Rolling Time Frames" && section.isActive) || 
          (section.sectionname === "Correlation Matrix" && section.isActive)
          ||   (section.sectionname === "Contribution to Risk" && section.isActive)) {
          inputValues = section.inputtype[0].inputvalues;
          if (!inputValues.includes(",") && inputValues!='') {
            inputValues = inputValues.replace(/Y/g, '');
            inputValues = inputValues.split(";");
            this.setLoadTimePeriodLabelAndCheckBox(inputValues, true, (section.sectionname === "Correlation Matrix" || "Contribution to Risk"),section.sectionname)
          }
        }
      });
    }
	if (shouldLoadDefaultCMTimePeriods && !this.state.isFromPortfolio) {
		templateDataset.sections.forEach((section) => {
			if (section.sectionname === "Correlation Matrix" && section.isActive) {
			  inputValues = section.inputtype[0].inputvalues;
			  if (!inputValues.includes(",") && inputValues!='') {
				inputValues = inputValues.replace(/Y/g, '');
				inputValues = inputValues.split(";");
				this.setLoadTimePeriodLabelAndCheckBox(inputValues, true, (section.sectionname === "Correlation Matrix"),section.sectionname)
			  }
			}
		  });
	}
	let eventsCount = 0 
    if(!this.isMaintainInitialAlloc(templateDataset.hypotheticalStatePvP)) {
	 eventsCount = nextProps.templateDataNew.response.eventsCount !== undefined ? nextProps.templateDataNew.response.eventsCount : 
                (this.props.templateDataNew.response.eventsCount !== undefined ? this.props.templateDataNew.response.eventsCount  : 0);
    }

      if (eventsCount > 0 ) {
        templateDataset.sections.forEach(section => {
          if (section.sectionname === 'Contribution to Risk') {
            section.isActive = false;
          }
        })
      }
      
      this.setState({
        selectedFunds: comparisonText,
        moduleTemplateData: templateDataset,
        shouldLoadDefaultTimePeriods: shouldLoadDefaultTimePeriods,
        eventsCount: eventsCount,
		shouldLoadDefaultCMTimePeriods: shouldLoadDefaultCMTimePeriods
      });
    }

    // Changes for MFS-2067
    const inputs = nextProps.templateDataNew.response;
    if (this.state.isGeneratePDF) {
      const inputParams = {
        templateData: inputs.templateData,
        containerId: this.state.isFromPortfolio ? inputs.comparsionWorkingContainerIds : inputs.containerId,
        reportId: inputs.reportId,
        isPortfolioPdf:this.state.isFromPortfolio ? true:false
      };

      const modifieddata =JSON.parse(inputParams.templateData);

      delete modifieddata.hypotheticalStatePvP;
      delete modifieddata.oldestInceptionDatesPvP;
      delete modifieddata.periodList;
      delete modifieddata.selectedPeriod;
      inputParams.templateData = JSON.stringify(modifieddata);
      this.props.requestGeneratePDFApi(inputParams);
      this.setState({
        isGeneratePDF: false
      });
    }

    // Handle Portfolio Comparison Save
    if (this.state.moduleTemplateData.modalIsPortfolioCompare) {
      if (nextProps.saveComparisonPortfolioTemplateDetails !== this.props.saveComparisonPortfolioTemplateDetails) {
        if (nextProps.saveComparisonPortfolioTemplateDetails.success === 1) {
          this.setState({
            IsconfirmationModalOpen: true,
            saveModalIsOpen: false,
            replaceModalIsOpen: false,
            statusMessage: ''
          });
        } else if (nextProps.saveComparisonPortfolioTemplateDetails.success === 0) {
          this.setState({
            IsconfirmationModalOpen: false,
            statusMessage: nextProps.saveComparisonPortfolioTemplateDetails.returnMessage
          });
        }
      }
    } else {
      if (
        nextProps.saveTemplate !== this.props.saveTemplate &&
        nextProps.saveTemplate.success === 1
      ) {
        this.setState({
          IsconfirmationModalOpen: true,
          saveModalIsOpen: false,
          replaceModalIsOpen: false,
          statusMessage: ""
        });
      } else if (
        nextProps.saveTemplate.success === 0 &&
        nextProps.saveTemplate !== this.props.saveTemplate
      ) {
        this.setState({
          confirmationModalIsOpen: false,
          statusMessage: nextProps.saveTemplate.returnMessage
        });
      }
    }


    if(nextProps.fundIds != this.props.fundIds && nextProps.fundIds){
        this.updateTemplateDataState(nextProps.fundIds);
    }
    /// for generate PDF output :
    if (nextProps.pdfOutput !== null && nextProps.pdfOutput !== undefined && nextProps.pdfOutput.pdfUrl !== '') {
      const source = nextProps.pdfOutput.pdfUrl;
      const outputObject = source !== null ? utilityFunctions.convertEncodedStringtoPDF(source) : '';

      if (outputObject !== null) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE specific
          this.setState({ downloadSpinnerModalIsOpen: false });
          window.navigator.msSaveOrOpenBlob(outputObject.blob);
        } else { //for all other browsers
          this.setState({ downloadSpinnerModalIsOpen: false });
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = outputObject.dataUrl;
          a.target = '_blank';
          a.click();
        }
      }

      nextProps.pdfOutput.pdfUrl = '';
    } else {
      if (nextProps.pdfOutput && nextProps.pdfOutput.errorCode === 400) {
        this.setState({ downloadSpinnerModalIsOpen: false });
      } else if(nextProps.pdfOutput && nextProps.pdfOutput.errorCode === 403) {
        this.props.router.push('/MaintenancePage');
      }
    }

    let timeOptions = [];
    //for rolling period calculations... 
    if (this.props.validRollingReturnsPeriod !== nextProps.validRollingReturnsPeriod
    ) {
      let validRollingReturnsPeriod = nextProps.validRollingReturnsPeriod;
      let newtemplateData = cloneDeep(this.state.moduleTemplateData);
      let _timePeriodOptions = [];
      // unselect Section Inactive:
      if (validRollingReturnsPeriod && validRollingReturnsPeriod.length <= 0) { // no data available
        newtemplateData.sections.forEach(item => {
          if (item.sectionname === "Rolling Time Frames") {
            item.isActive = false;
            // unselect subSections Inactive:
            item.subsections.forEach(
              subsections => {
                subsections.isActive = false;
              }
            );
          }
        });
      } else { //data available
        if (this.state.isFromPortfolio) {
          validRollingReturnsPeriod = validRollingReturnsPeriod.filter(function(value, index, arr){
            return newtemplateData.startPeriod <= utilityFunctions.getNyearNmonthStartDate(value, 11, newtemplateData.endPeriod);
          });
       }
        validRollingReturnsPeriod.forEach(function (item) {
          _timePeriodOptions.push({ name: item, key: item });
       });

        //set default options:.

        if (nextProps.templateDataNew.response.shouldLoadDefaultTimePeriods) { // for loading default templates...
          let last_max_value_in_time_period = validRollingReturnsPeriod[validRollingReturnsPeriod.length - 1]
          let inputValues = last_max_value_in_time_period + 'Y';
          if(inputValues.length ==0){
            inputValues = last_max_value_in_time_period.key + 'Y'
          }

          newtemplateData.sections.forEach(item => {
            if (item.sectionname === "Rolling Time Frames") {
              this.setDefaultTimePeriodLabelAndCheckBox(last_max_value_in_time_period, true, true, true,item.sectionname);
              item.inputtype[0].inputvalues = inputValues;
            }
          });

          if (validRollingReturnsPeriod && validRollingReturnsPeriod.length <= 0) { // no data available
            newtemplateData.sections.forEach(item => {
              if (item.sectionname === "Rolling Time Frames") {
                item.isActive = false;
                // unselect subSections Inactive:
                item.subsections.forEach(
                  subsections => {
                    subsections.isActive = false;
                  }
                );
              }
            });
          }
        }

      }

      // move all funds from both portfolios to a single array
      let areAllFundsHaveMin3YrData = false;
      let validTimeOptions = [];
      if (this.state.isFromPortfolio === true) {

        let allPortfolioFunds = []; 
        let tempData =  this.props.templateDataNew.response.templateDataPortfolio;
      
        for (let i = 0; i < newtemplateData.Portfolios.length; i++) {
          for (let j = 0; j < newtemplateData.Portfolios[i].Portfolio.length; j++) {
            allPortfolioFunds.push(newtemplateData.Portfolios[i].Portfolio[j]);
          }
        }
        
        // remove all funds with zero allocation
        allPortfolioFunds = allPortfolioFunds.filter(fund => { return fund.amountInvestedPercent > 0 });

        // Check if all funds have atleast 3 years data (with extended performance data) and also add 
        // those time periods for which all funds have data.
        timeOptions = this.getTimePeriodOptions(newtemplateData);
		if (timeOptions != "Longest Available") {
			for (let i = 0; i < timeOptions.length; i++) {
				let period = newtemplateData.periodList.find(timeperiod => { 
				  return timeperiod.label == timeOptions[i] 
				});
				if (period && period.value > 0) {
				  let allFundsValid = allPortfolioFunds.filter(fund => { 
					return utilityFunctions.getStartDateOfNextFullMonth(fund.oldestShareClassInceptionDate) <= period.value
				  });
				  if (allFundsValid.length == allPortfolioFunds.length) {
					if (i === 0) { 
					  areAllFundsHaveMin3YrData = true; 
					}
					let timeOption = timeOptions[i].replace(' Years', '');
					validTimeOptions.push({name: parseInt(timeOption), key: parseInt(timeOption)});
				  }
				}  
			  }
		}
        let inputValues ='';
        let last_max_value_in_time_period  = '';
        let setDefaultTimePeriods = this.state.shouldLoadDefaultTimePeriods;
        newtemplateData.sections.forEach(item => {
              if(item.sectionname === "Contribution to Risk"){
                if(timeOptions.includes("Longest Available")){
                  let allFundsValid = allPortfolioFunds.filter(fund => { 
                    return utilityFunctions.getStartDateOfNextFullMonth(fund.oldestShareClassInceptionDate) <= (newtemplateData.startPeriod+1)
                  });//newtemplateData.startPeriod is 1 day less to be saved in backend but for comparison add 1 day                  
                  if (allFundsValid.length === allPortfolioFunds.length) {
                    validTimeOptions.push({name: "Longest Available", key: "Longest Available"});
                    last_max_value_in_time_period = "Longest Available";
                    inputValues ="Longest Available"; 
                    item.inputtype[0].inputvalues = inputValues;
                  }
                } else{
                  last_max_value_in_time_period = validTimeOptions[validTimeOptions.length - 1]
                  if(last_max_value_in_time_period && last_max_value_in_time_period.key){
                    inputValues =  last_max_value_in_time_period.key + 'Y';
                    item.inputtype[0].inputvalues = inputValues;
                  }
                }
                if(setDefaultTimePeriods) {
                  this.setDefaultTimePeriodLabelAndCheckBox(last_max_value_in_time_period, true, true, true,item.sectionname);                  
                }
              } else if(item.sectionname === "Correlation Matrix"){

                // 'Correlation Matrix' module cannot have 'Longest Available' time period option, so if 
                // last option is 'Longest Available' then we should select second last option. Since we 
                // have always minimum 3 years option so we can be assure that in case of 'Longest Available'
                // option we will have '3 year' otion.
                if (validTimeOptions[validTimeOptions.length - 1].name === 'Longest Available') {
                  last_max_value_in_time_period = validTimeOptions[validTimeOptions.length - 2];
                } else {
                  last_max_value_in_time_period = validTimeOptions[validTimeOptions.length - 1];
                }

                if(last_max_value_in_time_period && last_max_value_in_time_period.key){
                  inputValues =  last_max_value_in_time_period.key + 'Y';
                  item.inputtype[0].inputvalues = inputValues;
                  if(setDefaultTimePeriods) {
                    this.setDefaultTimePeriodLabelAndCheckBox(last_max_value_in_time_period, true, true, true,item.sectionname);   
                  }
                }
                validTimeOptionsCM = [...validTimeOptions];
                if (validTimeOptionsCM[validTimeOptionsCM.length - 1].name === 'Longest Available') {
                  validTimeOptionsCM.length -=1;
                }
              }
			});
			this.setState({
				enableFundReturnsCM: areAllFundsHaveMin3YrData,
				timePeriodOptionsCM: validTimeOptionsCM
			});
        }
      

      this.setState({
        moduleTemplateData: newtemplateData,
        enableFundReturns: validRollingReturnsPeriod && validRollingReturnsPeriod.length > 0,
		timePeriodOptions: _timePeriodOptions,
		enableFundReturnsCR: areAllFundsHaveMin3YrData,
	    timePeriodOptionsCR: validTimeOptions
      });
	}
	
	//for corelation matrix calculations... 
    if (this.props.validCMPeriod !== nextProps.validCMPeriod && !this.state.isFromPortfolio) {
      let validCMPeriod = nextProps.validCMPeriod;
      let newtemplateData = cloneDeep(this.state.moduleTemplateData);
	  let _timePeriodOptionsCM = [];
	  let fundsHaveMin3YrData = false;
      // unselect Section Inactive:
      if (validCMPeriod && validCMPeriod.length <= 0) {
        newtemplateData.sections.forEach(item => {
          if (item.sectionname === "Correlation Matrix") {
            item.isActive = false;
            // unselect subSections Inactive:
            item.subsections.forEach(
              subsections => {
                subsections.isActive = false;
              }
            );
          }
        });
	  } else { //data available
		fundsHaveMin3YrData = true;
	    validCMPeriod.forEach(function (item) {
			_timePeriodOptionsCM.push({ name: item, key: item });
        });
        //set default options:.
		if (nextProps.templateDataNew.response.shouldLoadDefaultCMTimePeriods) { // for loading default templates...
          let last_max_value_in_time_period = validCMPeriod[validCMPeriod.length - 1]
          let inputValues = last_max_value_in_time_period + 'Y';
          if(inputValues.length ==0){
            inputValues = last_max_value_in_time_period.key + 'Y'
          }

          newtemplateData.sections.forEach(item => {
            if (item.sectionname === "Correlation Matrix") {
              this.setDefaultTimePeriodLabelAndCheckBox(last_max_value_in_time_period, true, true, true,item.sectionname);
              item.inputtype[0].inputvalues = inputValues;
            }
          });

          if (validCMPeriod && validCMPeriod.length <= 0) { // no data available
            newtemplateData.sections.forEach(item => {
              if (item.sectionname === "Correlation Matrix") {
                item.isActive = false;
                // unselect subSections Inactive:
                item.subsections.forEach(
                  subsections => {
                    subsections.isActive = false;
                  }
                );
              }
            });
          }
        }
      }

      this.setState({
		moduleTemplateData: newtemplateData,
        enableFundReturnsCM: fundsHaveMin3YrData,
        timePeriodOptionsCM: _timePeriodOptionsCM
      });
	}
  }

  cwrpTemplateDataBind(nextProps) {
    xmlToJSON.to_json(`<data>${nextProps.template[0].xmlData}</data>`, (error, data) => {
      const newTemplateData = cloneDeep(data);
      let funds = [];
      let portfolios = [];
      if (this.state.modalIsPortfolioCompare) {
        newTemplateData.Portfolios = [];
        for (let i = 0; i < Object.keys(data.Portfolios).length; i++) {
          funds = [];
          portfolios = [];
          let weightageCalc = (1 / Object.keys(data.Portfolios[i].Portfolio).length).toString();
          portfolios = this.getPortfolios(data, i, portfolios);
          if (portfolios[0] && portfolios[0].symbol !== undefined) {
            for (const index in portfolios) {
              const fund = portfolios[index];
              fund.weightage = weightageCalc;
              funds.push(fund);
            }
          } else if (portfolios.symbol !== undefined) {
            funds.push(portfolios);
          }
          newTemplateData.Portfolios.push({ Portfolio: funds });
        }
        this.setState({ comparisonPortfolioSavedBenchmarkResult: data.benchmark });
      }
    });
  }

  getPortfolios = (data, i, portfolios) => {
    let portfoliosData = portfolios;
    if (data.Portfolios[i] && !data.Portfolios[i].hasOwnProperty('Portfolio')) {
      portfoliosData.push([]);
    } else {
      portfoliosData = data.Portfolios[i] ? data.Portfolios[i].Portfolio :
        data.Portfolios.Portfolio;
    }
    return portfoliosData
  }

  closeSelectFundModal = () => {
    if (this.state.isFromPortfolio) {
      this.setState({ 
        isSelectFundModalOpen: false,
        showAddAllTooltip: false,
        isShowBenchmarkResults: false,
        isResetDropdowns: false,
        startNewFundComparison: false,
        showHypotheticals: false,
      });
    } else {
      this.setState({ 
        isSelectFundModalOpen: false 
      });
    }
  }

  openSelectFundModal = () => {
    if(this.state.isFromPortfolio && this.props.userType.response === 'International'){
      this.state.moduleTemplateData.Portfolios.forEach(portfolio => {
        portfolio.Portfolio.forEach(fund => {
           delete fund.fundId;
        });
      });
    }
    this.updateTemplateDataState();
    if (this.state.isFromPortfolio) {
      this.setState({ 
        modalIsPortfolioCompare: true,
        showAddAllTooltip: true,
        isShowBenchmarkResults: false,
        isResetDropdowns: true,
        showHypotheticals: false,
        startNewFundComparison: false,
        isPortfolio: true,
        saveModalIsOpen: false,
        isSelectFundModalOpen: true
        });
      try {
        UsageReporting.trackPage("Build Portfolio", "");
      } catch (error) { }  
    } else {
      this.setState({ 
        isSelectFundModalOpen: true,
        saveModalIsOpen: false,
      });
      try {
        UsageReporting.trackPage("Fund Selection", "");
      } catch (error) { }
    }
  }

  showBenchmarksModal = () => {
    this.setState({
      isShowBenchmarkResults: true,
      showHypotheticals: false,
    });
  }

  showHypotheticalsModal = () => {
    this.setState({
      showHypotheticals : true,
      isShowBenchmarkResults : false,
    });
  }

  openAlertModal = (e) => {
    this.setState({ isAlertModalOpen: true });
  }

  closeAlertModal = () => {
    this.setState({ isAlertModalOpen: false });
  }

  dontSaveModal = () => {
    this.setState(
      {
        isAlertModalOpen: false
      },
      () => {
        this.props.router.push(this.state.isFromPortfolio ? this.props.userType.response === 'International' ? "/PortfolioInternational" :"/portfolio": this.props.userType.response === 'International'? "/FundInternational" : "/home");

        this.props.updateTemplateInternalDetails({
          templateId: 0,
          templateName: "",
          resetFlag: true
        });
      }
    );
  }

  updateTemplateDataState(fundIds) {

    const newTemplateData = this.state.moduleTemplateData;
    //Adding property FundId to each fund for MFS International
    if(this.props.userType.response === 'International' && fundIds) {
        let dictWsodFundId = {};
        fundIds.items.forEach(item=>{        
          item.supplementalData && item.supplementalData.forEach(xref=>{
            if (xref.name == "morningstarfundid") {
              dictWsodFundId[item.xids.venue] = xref.value;
            }
          })         
        });
        if(this.state.isFromPortfolio){
          newTemplateData.Portfolios.forEach(portfolio => {
            portfolio.Portfolio.forEach(fund => {
              fund.fundId = dictWsodFundId[fund.wsodissue] ? dictWsodFundId[fund.wsodissue] : '';
            });
          });
        } else {              
        newTemplateData.funds.fundinfocus.fundId = dictWsodFundId[newTemplateData.funds.fundinfocus.wsodissue] ? dictWsodFundId[newTemplateData.funds.fundinfocus.wsodissue] : "";
        newTemplateData.funds.nonfundinfocus.forEach(item=>{
          item.fundId = dictWsodFundId[item.wsodissue] ? dictWsodFundId[item.wsodissue] : "";
        });
      }
    }
    if(newTemplateData.hasOwnProperty('language') && this.props.userType.response !== 'International') {
      delete newTemplateData.language; 
    }
    newTemplateData.sections.forEach(item => {
      let inputValues = [];
      if(!this.checkForContributionToRisk() && item.sectionname === "Contribution to Risk" && this.state.isFromPortfolio){
			item.isActive = false;
			item.subsections[0].isActive=false 
      }
      if (item.sectionname === "Contribution to Risk" && item.isActive) {
        this.state.checkedItemsCR.forEach(function (key, value) {
          inputValues.push(value);
        });
      } else if (item.sectionname === "Correlation Matrix" && item.isActive) {
        this.state.checkedItemsCM.forEach(function (key, value) {
          inputValues.push(value);
        });
      } else if (item.sectionname === "Rolling Time Frames" && item.isActive) {
        this.state.checkedItems.forEach(function (key, value) {
          inputValues.push(value);
        });
      }
      inputValues.sort(function (a, b) { return a - b });
      inputValues = inputValues.map(function (e) { return e!== "Longest Available" ? e + 'Y' : e });
      if(item.inputtype[0] && typeof item.inputtype[0] === 'object') {
        item.inputtype[0].inputvalues = inputValues.join(";");
      }
    })

    if(this.props.userType.response === 'International' && newTemplateData.benchmark && newTemplateData.benchmark.blendedBenchmarkNames !='' && newTemplateData.funds && newTemplateData.funds.benchmark) {      
      if(newTemplateData.funds.primarybenchmark && newTemplateData.funds.benchmark && newTemplateData.funds.benchmark.symbol == newTemplateData.funds.primarybenchmark.symbol)
            newTemplateData.funds.benchmark = {symbol: '',wsodissue: 0,fundfamily: 'Benchmark'};      
    }


    const inputParams = {
      templateData: JSON.stringify(newTemplateData),
      containerId: this.props.templateDataNew.response.containerId,
      reportId: this.props.templateDataNew.response.reportId,
      shouldLoadDefaultTimePeriods: false,
      isPortfolio:false,
      eventsCount:this.state.eventsCount,
	    shouldLoadDefaultCMTimePeriods: false
    };

    // Calculate templateId & templateName
    let compPortTempId = this.props.ComparisonPortfolioTemplateId;
    let compPortTempName = this.props.ComparisonPortfolioTemplateName;
    let editCompTempId = this.props.templateDataNew.response.editComparisonTemplateId;
    let editCompTempName = this.props.templateDataNew.response.editComparisonTemplateName;
    let templateId = this.props.templateDataNew.response.templateId;
    let templateName = this.props.templateDataNew.response.templateName;

    let _templateId = templateId;
    let _templateName = templateName;

    if (this.state.isFromPortfolio) {
      if (editCompTempId > 0) {
        _templateId = compPortTempId > 0 ? compPortTempId: editCompTempId;
        _templateName = compPortTempId > 0 ? compPortTempName: editCompTempName;
      } else {
        _templateId = compPortTempId > 0 ? compPortTempId: 0;
        _templateName = compPortTempId > 0 ? compPortTempName: '';
      }  
    }

    if(this.state.isFromPortfolio){
      inputParams.portfolioScreenerOutput = this.props.templateDataNew.response.portfolioScreenerOutput;
      inputParams.comparsionWorkingContainerIds=this.props.templateDataNew.response.comparsionWorkingContainerIds;
      inputParams.comparisonContainerIds=this.props.templateDataNew.response.comparisonContainerIds;
      inputParams.balanceInitial=this.props.templateDataNew.response.balanceInitial;
      inputParams.portfolioNamesList=this.props.templateDataNew.response.portfolioNamesList;
      inputParams.isPortfolio=true;
      inputParams.editComparisonTemplateId = editCompTempId;
      inputParams.editComparisonTemplateName = editCompTempName;
      inputParams.id = utilityFunctions.getRandomNumber();
    }

    inputParams.templateId = _templateId;
    inputParams.templateName = _templateName;

    this.props.setTemplateData(inputParams);
    if (this.props.userType.response === 'International' && fundIds) {
      this.setState({isGeneratePDF: true});
    }
    if (this.state.isFromPortfolio) {
      this.props.updatePortfolioTemplateInternalDetails({ 
        templateId: _templateId,
        templateName: _templateName
      });
    } else {
      this.props.updateTemplateInternalDetails({ 
        templateId: this.props.templateDataNew.response.templateId,
        templateName: this.props.templateDataNew.response.templateName
      });
    }
  }

  // check MaintainInitialAlloc is check or not (If MaintainInitialAlloc is checked enable CTR)
  isMaintainInitialAlloc = (hypotheticalState) =>{
	return hypotheticalState && hypotheticalState.maintainInitialAlloc && hypotheticalState.maintainInitialAlloc.isCheck
  }

  generatePDF = () => {
    this.handleGeneratePdf();
  }

  handleGeneratePdf = () => {
    let xids= [];
    if (this.props.userType.response === 'International') {
      const newTemplateData = this.state.moduleTemplateData;
      if(this.state.isFromPortfolio) {      
        const wsodIssuesSet = new Set();
        newTemplateData.Portfolios.forEach(portfolio => {
          portfolio.Portfolio.forEach(issue => {
            wsodIssuesSet.add(issue.wsodissue);
          });
        });
        xids = [...wsodIssuesSet];
        if(xids.length == 1 && (Constant.CustomFundXids.includes(parseInt(xids[0])))) {
          xids = [];
        }
      } else {        
        xids.push(newTemplateData.funds.fundinfocus.wsodissue);
        newTemplateData.funds.nonfundinfocus
            .map(elem => {
              xids.push(elem.wsodissue);
            });
      }
  }
    this.setState(
      { downloadSpinnerModalIsOpen: true, isGeneratePDF: (this.props.userType.response !== 'International') ||  (this.props.userType.response === 'International' && xids.length == 0)},
      () => {
        this.props.userType.response === 'International' && (xids.length > 0) ? this.props.requestFundLevelIds(xids.join(",")) 
                                                          : this.updateTemplateDataState();
      }
    );
  }

  logOut = () => {
    this.setState({ isUserLoggedOut: true });
  }

  logoutUser = () => {
    localStorage.setItem("logout-token", localStorage.getItem("access_token"));
    if (document.querySelector("meta[name='access_token']")) {
      document.querySelector("meta[name='access_token']").value = "";
    }
    localStorage.removeItem("access_token");
    localStorage.setItem("loggedOut", true);
    const url = urlMethods.getEnvironment();
    const a = document.createElement("a");
    a.target = "_self";
    a.href = url;
    document.body.appendChild(a);
    a.click();
  }

  closeLogOutModal = () => {
    this.setState({ isUserLoggedOut: false });
  }

  toggleMarketingPage = () => {
    this.state.moduleTemplateData.isMarketingPage = !this.state
      .moduleTemplateData.isMarketingPage;
    this.setState({ moduleTemplateData: this.state.moduleTemplateData,
    isMarketingPage : this.state.moduleTemplateData.isMarketingPage});
  }

  moveCardLeftRail = (dragIndex, hoverIndex, listId, isSection) => {
    const newtemplateResultState = this.state.moduleTemplateData;
    if (isSection) {
      const dragItem = newtemplateResultState.sections[dragIndex];
      newtemplateResultState.sections.splice(dragIndex, 1);
      newtemplateResultState.sections.splice(hoverIndex, 0, dragItem);
    } else {
      const dragItem =
        newtemplateResultState.sections[listId].subsections[dragIndex];
      newtemplateResultState.sections[listId].subsections.splice(dragIndex, 1);
      newtemplateResultState.sections[listId].subsections.splice(
        hoverIndex,
        0,
        dragItem
      );
    }
    this.setState({ moduleTemplateData: newtemplateResultState });
  }

  handleDeleteFundClick = (e) => {
    const isSection = e.target.getAttribute("data-isSection");
    const index = e.target.getAttribute("data-index");
    const listId = e.target.getAttribute("data-listId");
    const newtemplateResultState = this.state.moduleTemplateData;
    if (isSection === "true") {
      newtemplateResultState.sections[index].isActive = false;
      newtemplateResultState.sections[index].subsections.forEach(
        subsections => {
          subsections.isActive = false;
        }
      );
    } else {
      let removeSection = false;
      newtemplateResultState.sections[listId].subsections[
        index
      ].isActive = false;
	  let isMStarSR  = newtemplateResultState.sections[listId].subsections[
        index
      ].name === 'Morningstar Sustainability Ratings'
      newtemplateResultState.sections[listId].subsections.forEach(
        subsections => {
		if (isMStarSR && ['Corporate and Soverign', 'ESG Risk Score Distribution'].includes(subsections.name)) {
			subsections.isActive = false
		} else if (subsections.isActive) {
            removeSection = true;
          }
        }
      );
      newtemplateResultState.sections[listId].isActive = removeSection;
    }
    this.setState({ moduleTemplateData: newtemplateResultState });
  }

  pushCardLeftRail(isSection, sectionId, subsectionId) {
    const newtemplateResultState = this.state.moduleTemplateData;
    const index = newtemplateResultState.sections.findIndex(
      section => section.sectionid === sectionId
    );
    const item = newtemplateResultState.sections[index];
    if (isSection) {
      item.subsections.sort((a, b) => {
        return a.id - b.id;
      });
      newtemplateResultState.sections.splice(index, 1);
      newtemplateResultState.sections.push(item);
    } else {
      const indexSubsection = newtemplateResultState.sections[
        index
      ].subsections.findIndex(section => section.id === subsectionId);
      const itemSubsection =
        newtemplateResultState.sections[index].subsections[indexSubsection];

      if (item.sectionname === "Equity & Multi-Asset" || item.sectionname === "Fixed Income") {
        itemSubsection.isActive = true;
      } else {
        item.subsections.splice(indexSubsection, 1);
        item.subsections.push(itemSubsection);
      }

      const activeSubsections = newtemplateResultState.sections[index].subsections.filter(x => x.isActive === true)
      //Section should go in the last whenever I am adding first subsection of the section
      //After that , its position should not change.. 
      //Hence check of active subsection being 1 is placed below
      if (activeSubsections && activeSubsections.length === 1) {
        newtemplateResultState.sections.splice(index, 1);
        newtemplateResultState.sections.push(item);
      }

    }
    this.setState({ moduleTemplateData: newtemplateResultState });
  }

  setLoadTimePeriodLabelAndCheckBox = (itemArray, isChecked, isCM , sectionName) => {
    let _CheckedItems = itemArray;
    let prevCheckedItems = new Map();
    if (isChecked) {
      _CheckedItems.forEach(item => {
        if(item == "Longest Available"){
          prevCheckedItems.set("Longest Available", isChecked)
        } else{ 
        prevCheckedItems.set(parseInt(item, 10), isChecked)
        }
      });
    }

    let finalArray = [];
    prevCheckedItems.forEach(function (key, value) {
      finalArray.push(value);
    });

    finalArray.sort(function (a, b) { return a - b });
    let _finalArray = finalArray.map(function (e) { return e!="Longest Available" ? e + ' Year' : e });
    if (sectionName === "Correlation Matrix") {
      this.setState({
        checkedItemsCM: prevCheckedItems,
        timePeriodDisplayLabelCM: _finalArray.filter(x=> x.key!= "Longest Available").join(", ")
      });  
    } else if(sectionName === "Contribution to Risk" ){
      this.setState({
        checkedItemsCR: prevCheckedItems,
        timePeriodDisplayLabelCR: _finalArray.join(", ")
      });  
    } else {
      this.setState({
        checkedItems: prevCheckedItems,
        timePeriodDisplayLabel: _finalArray.join(", ")
      });
    }
  }

  setDefaultTimePeriodLabelAndCheckBox = (item, isChecked, isDefault, isCM, sectionName) => {
    if (item && item.key) { item = item.key; }
    let prevCheckedItems = new Map();
    if (!isDefault) {
      if (sectionName === "Correlation Matrix"){
        prevCheckedItems =this.state.checkedItemsCM;
      } else if (sectionName === "Contribution to Risk" ){
        prevCheckedItems =this.state.checkedItemsCR;
      } else{
        prevCheckedItems =this.state.checkedItems;
      }
    }

    let labelTimeperiod = new Set();
    if (isChecked) {
      if(item == "Longest Available"){
        labelTimeperiod.add("Longest Available");
        prevCheckedItems.set("Longest Available", isChecked)
      } else{
      labelTimeperiod.add(parseInt(item, 10));
      prevCheckedItems.set(parseInt(item, 10), isChecked)
      }
    } else {
      if(item == "Longest Available"){
        labelTimeperiod.delete("Longest Available");
        prevCheckedItems.delete("Longest Available")
      } else{
      labelTimeperiod.delete(parseInt(item, 10));
      prevCheckedItems.delete(parseInt(item, 10));
    }
  }
    let finalArray = [];
    prevCheckedItems.forEach(function (key, value) {
      finalArray.push(value);
    });

    finalArray.sort(function (a, b) { return a - b })
    let _finalArray = finalArray.map(function (e) { return e!="Longest Available" ? e + ' Year' : e });
    if (!isDefault) {

      let templateArray = finalArray.map(function (e) { return e!="Longest Available" ? e + 'Y' : e }).join(";");
      let newtemplateResultState = cloneDeep(this.state.moduleTemplateData);
      newtemplateResultState.sections.forEach(item => {
        if (item.sectionname === sectionName){
          item.inputtype[0].inputvalues = templateArray;
        }
      });

      if (sectionName === "Correlation Matrix") {
        this.setState({
          checkedItemsCM: prevCheckedItems,
          timePeriodDisplayLabelCM: _finalArray.join(", "),
          moduleTemplateData: newtemplateResultState
        });
      } else if(sectionName === "Contribution to Risk"){
        this.setState({
          checkedItemsCR: prevCheckedItems,
          timePeriodDisplayLabelCR: _finalArray.join(", "),
          moduleTemplateData: newtemplateResultState
        });
      } else {
        this.setState({
          checkedItems: prevCheckedItems,
          timePeriodDisplayLabel: _finalArray.join(", "),
          moduleTemplateData: newtemplateResultState
        });
      }
    } else {
      if (sectionName === "Correlation Matrix") {
        this.setState({
          checkedItemsCM: prevCheckedItems,
          timePeriodDisplayLabelCM: _finalArray.join(", ")
        });
      } else if(sectionName === "Contribution to Risk"){
        this.setState({
          checkedItemsCR: prevCheckedItems,
          timePeriodDisplayLabelCR: _finalArray.join(", ")
        });
      } else{
        this.setState({
          checkedItems: prevCheckedItems,
          timePeriodDisplayLabel: _finalArray.join(", ")
        });
      }
    }
  }


  addSections = (e) => {
    let isSection = false;
    let sectionId = 0;
    let subsectionId = 0;
    let shouldPush = false;
    let newtemplateResultState = this.state.moduleTemplateData;
    newtemplateResultState.sections.forEach(item => {
      if (
        e.currentTarget.children[0].getAttribute("class") === "add" &&
        item.sectionname === e.currentTarget.getAttribute("data-name")
      ) {
        item.isActive = true;
        item.subsections.forEach(subsections => {
          subsections.isActive = true;
        });
        isSection = true;
        sectionId = item.sectionid;
        subsectionId = 0;
        shouldPush = true;
      } else if (
        e.currentTarget.children[0].getAttribute("class") === "check" &&
        item.sectionname === e.currentTarget.getAttribute("data-name")
      ) {
        item.isActive = false;
        item.subsections.forEach(subsections => {
          subsections.isActive = false;
        });
      } else {
        item.subsections.forEach(subsections => {
          if (
            e.currentTarget.children[0].getAttribute("class") === "add" &&
            subsections.name === e.currentTarget.getAttribute("data-name")
          ) {
            subsections.isActive = true;
            item.isActive = true;
            e.currentTarget.children[0].removeAttribute("class");
            e.currentTarget.children[0].setAttribute("class", "check");
            isSection = false;
            sectionId = item.sectionid;
            subsectionId = subsections.id;
            shouldPush = true;
          } else if (
            e.currentTarget.children[0].getAttribute("class") === "check" &&
            subsections.name === e.currentTarget.getAttribute("data-name")
          ) {
            subsections.isActive = false;
            e.currentTarget.children[0].removeAttribute("class");
            e.currentTarget.children[0].setAttribute("class", "add");
          }
        });
        const sectionName = e.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].getAttribute(
          "data-name"
        );
        if (
          e.currentTarget.parentElement.parentElement.parentElement.getElementsByClassName(
            "check"
          ).length === item.subsections.length &&
          sectionName === item.sectionname
        ) {
          item.isActive = true;
        } else if (
          e.currentTarget.parentElement.parentElement.parentElement.getElementsByClassName(
            "add"
          ).length === item.subsections.length &&
          sectionName === item.sectionname
        ) {
          item.isActive = false;
        }
      }

      if (item.sectionname == 'Regional Inflection Points - Select a Maximum of 2') {
        if (item.isActive) {
          let activeSectionsCount = item.subsections.filter(x => x.isActive).length;
          if (activeSectionsCount > 2) {
            item.subsections.forEach((subsec, index) => {
              subsec.isActive = index > 1 ? false: true;
            })
          }  
        } else {
          item.subsections.forEach((subsec) => {
            subsec.isActive = false;
          })
        }
      }

	  if (item.sectionname == 'Sustainability Ratings') {
        if (item.isActive) {
			let isAnySubSecActive = false;
			let isMStarSRActive = item.subsections.filter(x => x.name === 'Morningstar Sustainability Ratings')[0].isActive;
			if (!isMStarSRActive) {
			  item.subsections.forEach((subsec, index) => {
				if(['Corporate and Soverign', 'ESG Risk Score Distribution'].includes(subsec.name))
				subsec.isActive = false;
				else if (subsec.isActive) isAnySubSecActive = true
			  })
			  if(!isAnySubSecActive) item.isActive = false;
			}  
        } else {
          item.subsections.forEach((subsec) => {
            subsec.isActive = false;
          })
        }
      }
    });

    this.setState({ moduleTemplateData: newtemplateResultState });
    if (shouldPush) {
      this.pushCardLeftRail(isSection, sectionId, subsectionId);
    }
  }

  openSaveOrReplaceModal = () => {
    
    let _editCompTempId = this.props.templateDataNew.response.editComparisonTemplateId;
    let _compPortTempId = this.props.ComparisonPortfolioTemplateId;

    if (this.state.isFromPortfolio) {
      if (_editCompTempId > 0 || _compPortTempId > 0) {
        this.openReplaceModal();
      } else {
        this.openSaveModal();
      }
    } else {
      if (
        this.props.templateDataNew.response.templateId > 0 ||
        this.props.TemplateId > 0 ||
        this.props.templateDataNew.response.templateName != ''
      ) {
        this.openReplaceModal();
      } else {
        this.openSaveModal();
      }
    }
  }

  openSaveModal = () => {
    this.setState({
      saveModalIsOpen: true,
      replaceModalIsOpen: false,
      statusMessage: "",
      isAlertModalOpen: false
    });
  }

  openReplaceModal = () => {
    this.setState({
      replaceModalIsOpen: true,
      isAlertModalOpen: false
    });
  }

  closeReplaceModal = () => {
    this.setState({
      replaceModalIsOpen: false
    });
  }

  closeSaveModal = () => {
    this.setState({
      saveModalIsOpen: false,
      statusMessage: "",
      replaceModalIsOpen: false
    });
  }

  closeConfirmationModal = () => {
    this.setState({
      IsconfirmationModalOpen: false,
      statusMessage: ""
    });
  }

  /**
   * Remove portfolio funds from "this.state.moduleTemplateData" and return the object
   */
  removePortfoliosFunds = () => {
    let newTempData = Object.assign({}, this.state.moduleTemplateData);
    for (let i = 0; i < newTempData.Portfolios.length; i++) {
      if (newTempData.Portfolios[i]) {
        newTempData.Portfolios[i].Portfolio = []; // empty the   
      }
    }
    return newTempData;
  }

  /**
   * Reset funds, benchmarks, periodList, selectedPeriod & balanceInitial and returns object
   * @param {*} moduleTemplateData Saved template data state
   */
  resetTemplateData = (moduleTemplateData) => {
    
    let resetTemplateData = JSON.parse(JSON.stringify(moduleTemplateData));

    for (let i = 0; i < resetTemplateData.Portfolios.length; i++) {
      resetTemplateData.Portfolios[i].Portfolio = [];
    }

    resetTemplateData.benchmark = {};
    resetTemplateData.periodList = [];
    resetTemplateData.selectedPeriod = {};
    resetTemplateData.balanceInitial = 100000;

    return resetTemplateData;
  }

  replaceTemplateSettingsPortfolio = () => {
    this.setState({
			statusMessage: ''
		});

    const templateSettingsData = { };

    // Don't save funds, benchmarks, periodList, selectedPeriod & balanceInitial
    let resetTemplateData = this.resetTemplateData(this.state.moduleTemplateData);

    templateSettingsData.templateData = JSON.stringify(resetTemplateData);
    
    // Calculate templateId & templateName
    let compPortTempId = this.props.ComparisonPortfolioTemplateId;
    let compPortTempName = this.props.ComparisonPortfolioTemplateName;
    let editCompTempId = this.props.templateDataNew.response.editComparisonTemplateId;
    let editCompTempName = this.props.templateDataNew.response.editComparisonTemplateName;
    let templateId = this.props.templateDataNew.response.templateId;
    let templateName = this.props.templateDataNew.response.templateName;

    let _templateId = templateId;
    let _templateName = templateName;

    if (this.state.isFromPortfolio) {
      if (editCompTempId > 0) {
        if (compPortTempId > 0) {
          _templateId = compPortTempId;
          _templateName = compPortTempName;
        } else {
          _templateId = editCompTempId;
          _templateName = editCompTempName;
        }
      } else {
        if (compPortTempId > 0) {
          _templateId = compPortTempId;
          _templateName = compPortTempName;
        } else {
          _templateId = templateId;
          _templateName = templateName;
        }
      }  

      templateSettingsData.templateId = _templateId;
      templateSettingsData.templateName = _templateName;

    } else {
      templateSettingsData.templateId = this.props.templateId;
      templateSettingsData.templateName = this.props.templateName;
    }
    
    templateSettingsData.containerId = this.props.templateDataNew.response.comparsionWorkingContainerIds;
    templateSettingsData.saveType = "replace";
    templateSettingsData.reportType = (this.props.userType.response === 'International') ? Constant.reportTypes.mil_portfoliocomparison : Constant.reportTypes.portfoliocomparison;
    
    this.props.requestSavePvPComparisonTemplateDetailsApi(templateSettingsData);
	}

  replaceTemplateSettings = () => {
    const templateSettingsData = {
      templateId:
        this.props.templateDataNew.response.templateId > 0
          ? this.props.templateDataNew.response.templateId
          : this.props.TemplateId,
      templateName: this.props.templateDataNew.response.templateName.trim(),
      templateData: JSON.stringify(this.state.moduleTemplateData) //JSON.stringify(newTemplateData)
    };
    if(this.props.userType.response === 'International' && this.state.moduleTemplateData.benchmark && this.state.moduleTemplateData.benchmark.blendedBenchmarkNames !='') {
      let blendedBenchmarks = [];					
      blendedBenchmarks['symbol']='';
      blendedBenchmarks['wsodissue']=0;
      blendedBenchmarks['fundfamily']='Benchmark';
      this.state.moduleTemplateData.funds.benchmark=blendedBenchmarks;
      templateSettingsData.templateData = JSON.stringify(this.state.moduleTemplateData);
    }

    if(this.props.userType.response === 'International'){
      templateSettingsData.reportType = 'mil_fundcomparison';
    }
    
    if (this.state.isFromPortfolio === true) {
        // [CODE_HERE]
    } else {
      this.setState({ statusMessage: "" }, () => {
        this.props.requestSaveTemplateApi(templateSettingsData);
      });
    }
  }

  /**
   * Save Portfolio Comparison
   */
  saveTemplateSettingsPortfolio = () => {
    // If template name is not empty (prosp and state)
		if (this.props.TemplateName.trim() !== '' || this.state.templateName.trim() !== '') {

      // Get template description
		  const txtDescriptionValue = document.getElementById('txt-description').value;

			// If template name is greater than 50 characters, disable "Save" button and show error name msg
			if (this.props.TemplateName.trim().length > 50 || this.state.templateName.length > 50) {
        
        this.setState({
					isSaveDisabled: true,
					statusMessage: ValidationMessages.PortfolioTemplateLengthCheck
        });

      } else if (txtDescriptionValue.length > 120) { // If template description is greater than 120 characters, disable "Save" button and show error desc msg
        
        this.setState({
					isSaveDisabled: true,
					statusMessage: ValidationMessages.PortfolioDescriptionLengthCheck
        });
        
      } else { // If template name & description are valid

        // Don't save funds, benchmarks, periodList, selectedPeriod & balanceInitial
        let resetTemplateData = this.resetTemplateData(this.state.moduleTemplateData);

        // Create template settings: Template (id, name & data), reportType, desc and handle the international user case
        const templateSettingsData = this.setPvPTemplateSettingData(resetTemplateData, txtDescriptionValue);

				// Disbale Save button, status message set to empty
				this.setState({
					isSaveDisabled: true,
					statusMessage: ''
				});

				// Portfolio Compare
				if (this.state.moduleTemplateData.modalIsPortfolioCompare) {

					// For "Save As New" comparison template					
          if (this.props.templateDataNew.response.editComparisonTemplateId > 0) {
            templateSettingsData.containerId = this.props.templateDataNew.response.comparsionWorkingContainerIds;
						templateSettingsData.saveType = "saveasnew";
					} else {
						// For comparison template to be saved from scratch
						templateSettingsData.containerId = this.props.templateDataNew.response.comparisonContainerIds;
						templateSettingsData.workingCopyContainerId = this.props.templateDataNew.response.comparsionWorkingContainerIds;
						templateSettingsData.saveType = "new";
          }

          // Save the portfolio comparison details 
					this.props.requestSavePvPComparisonTemplateDetailsApi(templateSettingsData);
				}
			}
		}
  }

  setPvPTemplateSettingData = (resetTemplateData, txtDescriptionValue) => {
    let templateSettingsData =  {
      templateId: 0,
      templateName: this.state.templateName.length > 0 ? this.state.templateName.trim() : this.props.TemplateName.trim(),
      templateData: JSON.stringify(resetTemplateData),
      reportType: this.state.moduleTemplateData.modalIsPortfolioCompare ? Constant.reportTypes.portfoliocomparison : Constant.reportTypes.singleportfolio, // Saving Single Portfolio
      description: txtDescriptionValue,
      shouldLoadDefaultTimePeriods: this.props.templateDataNew.response.shouldLoadDefaultTimePeriods,
      shouldLoadDefaultCMTimePeriods: this.props.templateDataNew.response.shouldLoadDefaultCMTimePeriods
    }

    // handle the international user case
    if (this.props.userType.response === 'International' && this.state.moduleTemplateData.modalIsPortfolioCompare) {
      templateSettingsData.reportType = Constant.reportTypes.mil_portfoliocomparison;
    }

    return templateSettingsData;
  }

  saveTemplateSettings = () => {
    if (
      this.props.TemplateName.trim() !== "" ||
      this.state.templateName.trim() !== ""
    ) {
      if (
        this.props.TemplateName.trim().length > 50 ||
        this.state.templateName.length > 50
      ) {
        this.setState({
          isSaveDisabled: true,
          statusMessage: ValidationMessages.TemplateLengthCheck
        });
      } else {
        const templateSettingsData = {
          templateId: 0,
          templateName:
            this.state.templateName.length > 0
              ? this.state.templateName.trim()
              : this.props.TemplateName.trim(),
          templateData: JSON.stringify(this.state.moduleTemplateData)
        };

        if(this.props.userType.response === 'International' && this.state.moduleTemplateData.benchmark && this.state.moduleTemplateData.benchmark.blendedBenchmarkNames !='') {
          let blendedBenchmarks = [];					
          blendedBenchmarks['symbol']='';
          blendedBenchmarks['wsodissue']=0;
          blendedBenchmarks['fundfamily']='Benchmark';
          this.state.moduleTemplateData.funds.benchmark=blendedBenchmarks;
          templateSettingsData.templateData = JSON.stringify(this.state.moduleTemplateData);
        }

        //check for international
        templateSettingsData.reportType = this.saveTemplateSettingsDataForInternational(templateSettingsData.reportType);
        
        
        this.setState(
          {
            isSaveDisabled: true,
            statusMessage: ""
          },
          () => {
            //SettemplateData to be called when template is saved.
            this.props.requestSaveTemplateApi(templateSettingsData);
          }
        );
      }
    }
  }

  saveTemplateSettingsDataForInternational = (reportType) => {
    let reportTypeData = reportType;
    if(this.props.userType.response === 'International'){
      reportTypeData =  this.state.moduleTemplateData.modalIsPortfolioCompare ? reportTypes.mil_portfoliocomparison: reportTypes.mil_fundcomparison;
    }
    return reportTypeData;
  }

  checkForContributionToRisk = () => {
    let enabled =false;
    if (this.state.isFromPortfolio) {
      if(this.state.eventsCount === 0){
        enabled = true;
        return enabled;
      }
    }
      return enabled;
  }

  goToHomePage = () => {
    this.props.updateTemplateInternalDetails({
      templateId: 0,
      templateName: "",
      resetFlag: true
    });
  }

  handleEnterClickOnTextbox = (e) => {
    if (e.key === "Enter") {
      this.state.moduleTemplateData.modalIsPortfolioCompare ? this.saveTemplateSettingsPortfolio() : this.saveTemplateSettings();
    }
  }

  handleInputChange = (event) => {
    if (event.target.value.trim() !== "") {
      this.setState({
        isSaveDisabled: false,
        statusMessage: "",
        templateName: event.target.value
      });
    } else {
      this.setState({
        isSaveDisabled: true,
        statusMessage: "",
        templateName: ""
      });
    }
  }

  dateFormatter(msDate, format) {
    if (!msDate) {
      return "--";
    }
    if (format) {
      return utilityFunctions.formatOADate(msDate, format);
    } else {
      return utilityFunctions.convertFromOADate(msDate);
    }
  }

  /**
   * Returns true if funds exists in the pass portfolio index otherwise false
   * @param {number} portfolioIndex 0-based, Portfolio index whose existence needs to be check
   * @param {object} hypotheticalState hypothetical state object
   */
  isPortfolioExists = (portfolioIndex, hypotheticalState) => {
    return hypotheticalState.portfolios[portfolioIndex].funds.length > 0;
  }
  onClickInternational = () => {
    this.props.setUserType("International");
  }

  onClickNonInternational = () => {
    this.props.setUserType("");
  }

  /**
   * Called when user change report language, update the moduleTemplateData state.
   * @param {EventObject} e Event Object
   */
  onSelectedReportLanguage = (e) => {
    let newTempData = Object.assign({}, this.state.moduleTemplateData);
    newTempData.language = e.value;
    this.setState({moduleTemplateData: newTempData});
  }

  render() {

    // Calculate template name to be passed in replace template modal
    let compPortTempId = this.props.ComparisonPortfolioTemplateId;
    let compPortTempName = this.props.ComparisonPortfolioTemplateName;
    let editCompTempId = this.props.templateDataNew.response.editComparisonTemplateId;
    let editCompTempName = this.props.templateDataNew.response.editComparisonTemplateName;
    let tempName = this.props.templateDataNew.response.templateName;
    let _templateName = tempName;
    let isShowReportLanguageSection = (this.props.userType.response === 'International' && 
                                      this.props.mfsUserData && (this.props.mfsUserData.userTier == 5 || this.props.mfsUserData.userTier == 6));

    let cssReportLang = isShowReportLanguageSection ? "report-lang": "";
    let cssPortComp = this.state.moduleTemplateData.modalIsPortfolioCompare ? "port-comp": "fund-comp";

    if (this.state.isFromPortfolio) {
      if (editCompTempId > 0) {
        _templateName = compPortTempId > 0 ? compPortTempName: editCompTempName;
      } else {
        _templateName = compPortTempId > 0 ? compPortTempName: tempName;
      }  
    }

    const customTheme = {
      borderRadius: 0,
      border: 0,
      colors: {
        primary: '#f4f4f4',
        primary50: '#f4f4f4',
        neutral0: '#FFF',
      },
    }
    const customStyles = {
      control: (base, state) => ({
        ...base,
        border: '1px solid rgba(34,36,38,.15) !important',
        boxShadow: '0 !important',
        '&:hover': {
          border: '1px solid rgba(34,36,38,.15) !important'
        },
        height: '34px',
        'min-height': '34px'
      }),
      option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? '#f4f4f4' : (state.isFocused ? '#f4f4f4' : '#FFF'),
        color: '#333',
        'font-weight': state.isSelected ? 'bold' : 'normal'
      }),
      menu: styles => ({ ...styles, margin: '0px' }),
      noOptionsMessage: styles => ({ ...styles, 'text-align': 'left', color: 'rgba(0,0,0,.4)' })
    }
    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <i
              className="dropdown"
              aria-hidden="true"
            />
          </components.DropdownIndicator>
        )
      );
    };
    const SingleValue = ({ children, ...props }) => (
			<components.SingleValue {...props}>{children} {children === 'English' ? <i>(Default)</i> : ''}</components.SingleValue>
		);

    return (
      <div className={"moduleSelection"}>
        <SaveChangesAlertModal
          isAlertModalOpen={this.state.isAlertModalOpen}
          saveAlertModalStyles={CustomModalStyles.SaveAlertModalStyles}
          closeAlertModal={this.closeAlertModal}
          dontSaveModal={this.dontSaveModal}
          openSaveOrReplaceModal={this.openSaveOrReplaceModal}
        />
        <Header onClickInternational={this.onClickInternational} onClickNonInternational={this.onClickNonInternational} resetComparePortfolios={this.resetComparePortfolios} logOut={this.logOut} openAlertModal={this.openAlertModal} activeTab={ this.state.isFromPortfolio ? (this.props.userType.response === 'International' ? 4 :2) : (this.props.userType.response === 'International'? 3: 1)}
        mfsUserData={this.props.mfsUserData}
        />
        <LogoutModal
          isUserLoggedOut={this.state.isUserLoggedOut}
          customStyles={CustomModalStyles.LogOutModalStyles}
          logoutUser={this.logoutUser}
          closeLogOutModal={this.closeLogOutModal}
        />
        <div className={"body"}>
          <div className={"bodyContainer"}>
            <div className={"sub-HeaderModule"}>
              <div className={"link-module-header"}>
              <div className={"link-module-header-1"}><Link onClick={e => this.openAlertModal(e)}>Home </Link></div>
              <div className={"link-module-header-2"}> > Add Modules</div>
              </div>
              <div className={"header-div"}>
                <div className={"header-text"}>
                  <div className={"header-text-1"}>Add Modules & Customize Your Report</div>
                  <div className={"header-text-2"}><ModuleInfoTooltip/></div>
                </div>
                <div className={"buttons-div"}>
                  <div
                    onClick={this.openSaveOrReplaceModal}
                    className={"save-button"}
                  >
                    SAVE AS TEMPLATE
                  </div>
                  <div onClick={this.generatePDF} className="generate-button">
                    GENERATE PDF
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"tableData tableStyle " + cssPortComp + " " + cssReportLang}>
    <EditComparingSection
            isFromPortfolio={this.state.isFromPortfolio}
            funds={this.state.selectedFunds}
            templateData={this.props.templateDataNew}
            openSelectFundModal={this.openSelectFundModal}
            isSelectFundModalOpen={this.state.isSelectFundModalOpen}
            isResetDropdowns={this.state.isResetDropdowns}
            modalIsPortfolioCompare={true}
            showAddAllTooltip={this.state.showAddAllTooltip}
            isShowBenchmarkResults= {this.state.isShowBenchmarkResults}
            startNewFundComparison={this.state.startNewFundComparison}
            showBenchmarksModal={this.showBenchmarksModal}
            showHypotheticalsModal = {this.showHypotheticalsModal}
            showHypotheticals = {this.state.showHypotheticals}
            closeSelectFundModal={this.closeSelectFundModal}
            comparisonPortfolioBenchmarkResult={this.state.moduleTemplateData.benchmark}    //{this.state.comparisonPortfolioSavedBenchmarkResult}
            portfolioNamesList={this.props.templateDataNew.response.portfolioNamesList ? this.props.templateDataNew.response.portfolioNamesList : this.props.portfolios}
            portfolioScreenerOutput = {this.props.templateDataNew.response.portfolioScreenerOutput}
            comparsionWorkingContainerIds={this.props.templateDataNew.response.comparsionWorkingContainerIds}
            comparisonContainerIds={this.props.templateDataNew.response.comparisonContainerIds}
            balanceInitial= {this.props.templateDataNew.response.balanceInitial}
            updatePortfolioNameList={this.updatePortfolioNameList}
            hypotheticalStatePvP = {this.state.moduleTemplateData.hypotheticalStatePvP}
            isFromModuleSelection= {this.state.moduleTemplateData.isFromModuleSelection}
            resetModuleSelectionFlag = {this.resetModuleSelectionFlag}
            oldestInceptionDatesPvP = {this.state.moduleTemplateData.oldestInceptionDatesPvP}
            editComparisonTemplateId={editCompTempId}
            editComparisonTemplateName={editCompTempName}
            callMaintenace={this.callMaintenacePage} 
            currency={this.state.moduleTemplateData.currency}
            fifFundName={this.state.moduleTemplateData.funds && this.state.moduleTemplateData.fifFundName}
            nonFifFundName={this.state.moduleTemplateData.funds && this.state.moduleTemplateData.nonFifFundNames}
            isInternationalUser={this.props.userType.response === 'International'}
          />
          {/* Report Language Selection */}
          {/* Only admin and inernaitonal user can see below link, for Fund Compare */}
          {isShowReportLanguageSection &&
          <div className={'filter-styles report-language'}>
              <span>Report Language</span>
              <Select
                  name="select-report-language"
                  className="dropdown-mfs"
                  theme={customTheme}
                  styles={customStyles}
                  isSearchable={false}
                  defaultValue={{ label: Constant.reportLanguageDic[this.state.moduleTemplateData.language], value: this.state.moduleTemplateData.language }}
                  value={{ label: Constant.reportLanguageDic[this.state.moduleTemplateData.language], value: this.state.moduleTemplateData.language }}
                  onChange={this.onSelectedReportLanguage}
                  options={Constant.reportLanguage}
                  components={{ DropdownIndicator, SingleValue }}
                  id="selectReportLanguage" />
          </div>
          }
          <LeftRailModuleSelection
            isFromPortfolio={this.state.isFromPortfolio}
            moveCardLeftRail={this.moveCardLeftRail}
            list={this.state.moduleTemplateData.sections}
            checkValidation={this.checkValidation}
            handleDeleteFundClick={this.handleDeleteFundClick}
            toggleMarketingPage={this.toggleMarketingPage}
            isChecked={this.state.moduleTemplateData.isMarketingPage}
            userType={this.props.userType.response}
          />
          <div className={"rightRail"}>
            <RightRail
              isFromPortfolio={this.state.isFromPortfolio}
              sections={this.state.moduleTemplateData}
              addSections={this.addSections}
              setDefaultTimePeriodLabelAndCheckBox={this.setDefaultTimePeriodLabelAndCheckBox}
              enableFundReturns={this.state.enableFundReturns}
              timePeriodOptions={this.state.timePeriodOptions}//checkbox options
              timePeriodDisplayLabel={this.state.timePeriodDisplayLabel} // label Header
              checkedItems={this.state.checkedItems} // already selected checkboxes
              enableFundReturnsCM={this.state.enableFundReturnsCM}
              timePeriodOptionsCM={this.state.timePeriodOptionsCM}//checkbox options
              enableFundReturnsCR={this.state.enableFundReturnsCR}
              timePeriodOptionsCR={this.state.timePeriodOptionsCR}//checkbox options
              timePeriodDisplayLabelCM={this.state.timePeriodDisplayLabelCM} // label Header
              checkedItemsCM={this.state.checkedItemsCM} // already selected checkboxes
              timePeriodDisplayLabelCR={this.state.timePeriodDisplayLabelCR} //mapping logic for correlation matrix to contributio to risk time period options
              checkedItemsCR={this.state.checkedItemsCR} //mapping logic for correlation matrix to contributio to risk time period options
              eventscount ={this.state.eventsCount}
              isHypostateMaintainInitialAlloc={this.state.moduleTemplateData.hypotheticalStatePvP && this.state.moduleTemplateData.hypotheticalStatePvP.maintainInitialAlloc.isCheck}
              isInternationalUser={this.props.userType.response === 'International'}
              currency={this.state.moduleTemplateData.currency}
            />
          </div>
        </div>
        <DownloadingSpinnerModal
          downloadSpinnerModalIsOpen={this.state.downloadSpinnerModalIsOpen}
        >
          {" "}
        </DownloadingSpinnerModal>
        {/* <div className={"footerContainer"}>
          <div className={"footer"}>
            <div
              onClick={this.openSaveOrReplaceModal}
              className={"save-button"}
            >
              SAVE AS TEMPLATE
            </div>
            <div onClick={this.generatePDF} className="generate-button">
              GENERATE PDF
            </div>
          </div>
        </div> */}
        <ReplaceTemplateModal
              templateName={_templateName}
              openSaveModal={this.openSaveModal}
              closeReplaceModal={this.closeReplaceModal}
              customStyles={CustomModalStyles.ReplaceTemplateModalStyles}
              saveTemplateSettings={this.state.moduleTemplateData.modalIsPortfolioCompare ? this.replaceTemplateSettingsPortfolio: this.replaceTemplateSettings}
              replaceModalIsOpen={this.state.replaceModalIsOpen}
              isPortfolio={this.state.isFromPortfolio}
              isPortfolioCompare={this.state.moduleTemplateData.modalIsPortfolioCompare}
            />
        <SaveModal
          isSaveDisabled={this.state.isSaveDisabled}
          saveTemplateSettings={this.state.moduleTemplateData.modalIsPortfolioCompare ? this.saveTemplateSettingsPortfolio: this.saveTemplateSettings}
          customStyles={CustomModalStyles.SaveModalStyles}
          closeSaveModal={this.closeSaveModal}
          saveModalIsOpen={this.state.saveModalIsOpen}
          handleInputChange={this.handleInputChange}
          status={this.state.statusMessage}
          handleEnterClickOnTextbox={this.handleEnterClickOnTextbox}
          isPortfolio={this.state.isFromPortfolio}
          isPortfolioCompare={this.state.moduleTemplateData.modalIsPortfolioCompare}
        />
        <ConfirmationModal
          userType={this.props.userType}
          goToHomePage={this.goToHomePage}
          closeConfirmationModal={this.closeConfirmationModal}
          confirmationModalIsOpen={this.state.IsconfirmationModalOpen}
          isPortfolio={this.state.isFromPortfolio}
          isPortfolioCompare={this.state.moduleTemplateData.modalIsPortfolioCompare}
        />
      </div>
    );
  }
}

ModuleSelection.propTypes = {
  templateDataNew: PropTypes.object
};

export default ModuleSelection;

import Types from "../actions/types";
import { createReducer } from "reduxsauce";

import INITIAL_STATE from "./initial-state/fundComparison";
import { getRandomNumber } from "../utils/commonMethods";

const generateFundComparisonPdf = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    pdfOutput: action.response.data
  });

const generateFundComparisonPdfOnSelectModal = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    pdfOutputOnSelectModal: action.response.data
  });

const receiveSuccessSaveTemplateDetailsApi = (state, action) => {
  const templateId =
    action.response.data.success === 0
      ? state.TemplateId
      : action.response.data.templateId;
  const templateName =
    action.response.data.success === 0
      ? state.TemplateName
      : action.response.data.templateName;

  return Object.assign({}, state, {
    fetching: false,
    error: null,
    saveTemplateDetails: action.response.data,
    TemplateId: templateId,
    TemplateName: templateName
  });
};

const receiveSuccessSaveTemplateApi = (state, action) => {
  const templateId =
    action.response.data.success === 0
      ? state.TemplateId
      : action.response.data.templateId;
  const templateName =
    action.response.data.success === 0
      ? state.TemplateName
      : action.response.data.templateName;

  return Object.assign({}, state, {
    fetching: false,
    error: null,
    saveTemplate: action.response.data,
    TemplateId: templateId,
    TemplateName: templateName
  });
};

const receiveSuccessGetTemplatesApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    templatesResult: action.response.data.templates
  });

const receiveSuccessGetTemplatesByIdApi = (state, action) => {
  const templateId = action.response.data.templates["0"].templateId;
  const templateName = action.response.data.templates["0"].templateName;
  return Object.assign({}, state, {
    fetching: false,
    error: null,
    templateResult: action.response.data.templates,
    TemplateId: templateId,
    TemplateName: templateName
  });
};

const receiveDeleteTemplateDetailsApi = (state, action) => {
  const templateId = action.response.data.success === 1 ? 0 : 0;
  const templateName = action.response.data.success === 1 ? "" : "";

  return Object.assign({}, state, {
    fetching: false,
    error: null,
    deleteTemplateDetails: action.response.data,
    TemplateId: templateId,
    TemplateName: templateName
  });
};

const receiveSuccessScreenerDataApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    screenerData: action.response.data,
  });

  const receiveSuccessScreenerDataFundNameApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    screenerDataFundName: action.response.data,
  });

  
  const receiveSuccessScreenerDataForMstarCategoryApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    screenerDataMstarCategory: action.response.data,
  });
  
  const receiveSuccessScreenerFundPerdormanceAsOfDateApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    screenerFundPerdormanceAsOfDate: action.response.data,
    screenerPerfDateId: getRandomNumber()
  });

const receiveSuccessScreenerFiltersDataApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    screenerFiltersOutput: action.response.data
  });

const receiveSuccessScreenerMasterDataApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    screenerMasterData: action.response.data
  });

const receiveSymbolSearchApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    symbolSearchResults: action.response.data
  });

const receiveDecodeApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    wsodIssue: action.response
  });

const receiveGetBenchmarksApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    benchmarksResults: action.response.data
  });

const receiveGetPrimaryBenchmarkApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    primaryBenchmarksResult: action.response.data
  });

const setAccessToken = (state, action) =>
  Object.assign({}, state, { accessToken: action.accessToken });

const genericApifailure = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    errorCode: action.data
  });

const setTemplateData = (state, result) => {
  return Object.assign({}, state, {
    templateDataset: result
  });
};

const setUserType = (state, result) => {
  return Object.assign({}, state, {
    userType: result
  });
};

const updateTemplateInternalDetails = (state, result) => {
  let templateId = 0;
  let templateName = "";
  if (result && result.response.resetFlag) {
    templateId = 0;
    templateName = "";
  } else {
    templateId = result.response.templateId
      ? result.response.templateId
      : state.TemplateId;
    templateName = result.response.templateName
      ? result.response.templateName
      : state.TemplateName;
  }
  return Object.assign({}, state, {
    TemplateId: templateId,
    TemplateName: templateName
  });
};

const receiveSuccessValidRollingReturnsDataApi = (state, action) => {
  return Object.assign({}, state, {
    validRollingReturnsPeriod: action.response.data.commonDataPeriod
  });

}

const receiveSuccessValidCMDataApi = (state, action) => {
	return Object.assign({}, state, {
	  validCMPeriod: action.response.data.commonDataPeriod
	});
}

const resetScreenerFiltersOutput = (state, action) => {
  return Object.assign({}, state, {
    screenerFiltersOutput: {}
  });
};

const receiveSuccessMfsSiteInactiveFlag = (state, action) => {
  return Object.assign({}, state, {
    mfsSiteInactive: action.response.data ?  action.response.data.maintenaceModeOn :  action.response.maintenaceModeOn,
    mfsUSFvFinactive: action.response.data ?  action.response.data.isMfsUsFvFInactive :  action.response.isMfsUsFvFInactive,
    mfsUSPvPinactive: action.response.data ?  action.response.data.isMfsUsPvPInactive :  action.response.isMfsUsPvPInactive,
    mfsMILFvFinactive: action.response.data ?  action.response.data.isMfsMilFvFInactive :  action.response.isMfsMilFvFInactive,
    mfsMILPvPinactive: action.response.data ?  action.response.data.isMfsMilPvPInactive :  action.response.isMfsMilPvPInactive,
    siteInactiveId: getRandomNumber()
  });
}

const receiveSuccessMFSUserData = (state, action) => {
  return Object.assign({}, state, {
    mfsUserData: action.response.data 
  });
}

const receiveSuccessXrefUserData = (state, action) => {
    return Object.assign({}, state, {
      fetching: false,
      error: null,
      xref: action.response.data 
  });
}

const receiveSuccessXrefFundData = (state, action) => {
  return Object.assign({}, state, {
    fetching: false,
    error: null,
    xrefFund: action.response.data 
  });
}

const receiveSuccessXrefUserDataETF = (state, action) => {
  return Object.assign({}, state, {
    fetching: false,
    error: null,
    xrefETF: action.response.data 
});
}

const receiveGetMfsBenchmarksApi = (state, action) =>
  Object.assign({}, state, {
    fetching: getRandomNumber(),
    error: null,
    mfsBenchmarksResults: action.response.data
});

const ACTION_HANDLERS = {
  [Types.RECEIVE_FUNDCOMPARISON_PDF]: generateFundComparisonPdf,
  [Types.RECEIVE_FUNDCOMPARISON_PDF_ON_SELECTMODAL]: generateFundComparisonPdfOnSelectModal,
  [Types.RECEIVE_SUCCESS_SAVETEMPLATEDETAILS]: receiveSuccessSaveTemplateDetailsApi,
  [Types.RECEIVE_SUCCESS_SAVETEMPLATE]: receiveSuccessSaveTemplateApi,
  [Types.SET_ACCESS_TOKEN]: setAccessToken,
  [Types.GENERIC_FAILURE]: genericApifailure,
  [Types.RECEIVE_SUCCESS_GET_TEMPLATES]: receiveSuccessGetTemplatesApi,
  [Types.RECEIVE_SUCCESS_GET_TEMPLATES_BY_ID]: receiveSuccessGetTemplatesByIdApi,
  [Types.RECEIVE_SUCCESS_DELETETEMPLATE]: receiveDeleteTemplateDetailsApi,
  [Types.RECEIVE_SUCCESS_SCREENERDATA]: receiveSuccessScreenerDataApi,
  [Types.RECIEVE_SYMBOLSEARCH]: receiveSymbolSearchApi,
  [Types.RECIEVE_DECODE]: receiveDecodeApi,
  [Types.RECEIVE_SUCCESS_GET_BENCHMARKS]: receiveGetBenchmarksApi,
  [Types.RECEIVE_SUCCESS_GET_PRIMARY_BENCHMARK]: receiveGetPrimaryBenchmarkApi,
  [Types.RECEIVE_SUCCESS_GET_SCREENER_MASTER_DATA]: receiveSuccessScreenerMasterDataApi,
  [Types.SET_TEMPLATE_DATASET]: setTemplateData,
  [Types.UPDATE_TEMPLATE_INTERNAL_DETAILS]: updateTemplateInternalDetails,
  [Types.RECEIVE_SUCCESS_GET_VALID_ROLLING_RETURNS_DATA]: receiveSuccessValidRollingReturnsDataApi,
  [Types.RECEIVE_SUCCESS_GET_VALID_CM_DATA]: receiveSuccessValidCMDataApi,
  [Types.RESET_SCREENER_FILTERS_OUTPUT]: resetScreenerFiltersOutput,
  [Types.RECEIVE_SUCCESS_SCREENERFILTERS_DATA]: receiveSuccessScreenerFiltersDataApi,
  [Types.RECEIVE_SUCCESS__MFSSITEINACTIVEFLAG]: receiveSuccessMfsSiteInactiveFlag,
  [Types.RECEIVE_SUCCESS_MFS_USER_DATA]: receiveSuccessMFSUserData,
  [Types.SET_USER_TYPE]: setUserType,
  [Types.RECEIVE_SUCCESS_SCREENERDATAFUNDNAME]: receiveSuccessScreenerDataFundNameApi,
  [Types.RECEIVE_SUCCESS_XREF_USER_DATA]: receiveSuccessXrefUserData,
  [Types.RECEIVE_SUCCESS_XREF_USER_DATA_ETF]: receiveSuccessXrefUserDataETF,
  [Types.RECEIVE_SUCCESS_GET_MFS_PRIMARY_BENCHMARK]: receiveGetMfsBenchmarksApi,
  [Types.RECEIVE_SUCCESS_XREF_FUND_DATA]: receiveSuccessXrefFundData,
  [Types.RECEIVE_SUCCESS_SCREENERFUND_PERFORMANCEASOFDATE]: receiveSuccessScreenerFundPerdormanceAsOfDateApi,
  [Types.RECEIVE_SUCCESS_SCREENERDATA_MSTARCATEGORY]: receiveSuccessScreenerDataForMstarCategoryApi
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

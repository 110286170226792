import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';

import CheckIcon from '../Icons/CheckIcon';

import genericModalStyles from '../../../styles/components/modal/deleteConfirmationModal.scss';


class DeleteConfirmationModal extends React.PureComponent {

	render() {
		const customModalStyles = {
			content: {
				top: '24%',
				left: '15%',
				right: '15%',
				bottom: '12%',
				height: '311px',
				width: '544px',
				padding: 0,
				boxShadow: '0 1px 13px 4px #d0cfce'
			}
		};

		return (
			<Modal shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true}
				isOpen={this.props.deleteConfirmationModalIsOpen} style={customModalStyles}>
				<div className={'confirmationModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<CheckIcon />
								<div className={'header-text'}>{this.props.deleteConfirmationMessage}</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

		);

	}
}

DeleteConfirmationModal.propTypes = {
	deleteConfirmationModalIsOpen: PropTypes.bool,
	closeDeleteConfirmationModal: PropTypes.func,
	deleteConfirmationMessage: PropTypes.string
};


export default DeleteConfirmationModal;

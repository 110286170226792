import React from 'react';
import { PropTypes } from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import CompareButtonIcon from '../../../common/Icons/CompareButtonIcon';
import ModuleCheckIcon from "../../../common/Icons/ModuleCheckIcon";

class BenchmarkResultsTable extends React.Component {

	comparebutton = (cell, row) => {
		let disableButtonStyle = { 'pointer-events': 'all' }
		let enableButtonStyle = { 'pointer-events': 'all' }
		if (this.props.benchmarksResults && this.props.benchmarksResults.filter(x => x.isChecked).length > 0) {
			disableButtonStyle = {
				'pointer-events': 'none',
				opacity: 0.4
			};
		}
		const html = (
			<div
				className={'add-icon'}
				onClick={this.props.toggleCompareButton}
				data-flag={'Benchmark'}
				data-symbol={row.name}
				data-wsodIssue={row.xId}
				style={row.isChecked ? enableButtonStyle : disableButtonStyle}
			>
				{row.isChecked ? <ModuleCheckIcon /> : <CompareButtonIcon />}
			</div>
		);

		return html;
	}

	headerColumnClassNameFormat() {
		return 'tdcompareStyle';
	}

	render() {
		const data = this.props.benchmarksResults;
		return (
			<div className={'tableData fundResultsTableStyles benchmarkResultTable'}>
				<BootstrapTable
					data={data}
					options={{
						noDataText:
							'No available benchmarks were found.'
					}}
					maxHeight={'510px'}
					tableStyle={{ width: '100%', height: '510px' }}
				>
					<TableHeaderColumn
						isKey
						dataField="comparebutton"
						dataFormat={this.comparebutton}
						className={this.headerColumnClassNameFormat}
						columnClassName={this.headerColumnClassNameFormat}
						width="12%"
					>
						Add to Comparison
					</TableHeaderColumn>
					<TableHeaderColumn dataField="name" width="34%" columnClassName={'benchmarkNameVerticalAlignMiddle'}>
						Benchmark Name
						</TableHeaderColumn>
					<TableHeaderColumn dataField="blendedBenchmarkNames" tdStyle={{'padding-bottom': '10px'}}>
						Benchmark Composition
					</TableHeaderColumn>
				</BootstrapTable>
			</div>
		);
	}
}

BenchmarkResultsTable.PropTypes = {
	benchmarksResults: PropTypes.Array,
	toggleCompareButton: PropTypes.func,
};

export default BenchmarkResultsTable;
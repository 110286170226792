import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';

import genericModalStyles from '../../../styles/components/modal/deleteModal.scss';

class DeleteTemplateModal extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	deleteMessage(isPortfolio) {
		return isPortfolio ? 'Are you sure you want to permanently delete this portfolio? This action can not be reversed once complete.'
							: 'Are you sure you want to permanently delete this template from your saved templates? This action can not be reversed once complete.';
	}
	render() {
		return (
			<Modal shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true}
				isOpen={this.props.deleteModalIsOpen} style={this.props.customStyles} >
				<div className={'deleteModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'header-text'}>{this.props.isPortfolio ? 'Delete Portfolio' : 'Delete Template'}</div>
							</div>
						</div>
						<div className={'delete-warning'}>
							<div className={'lbl-template'}>{this.deleteMessage(this.props.isPortfolio)} </div>
							<div className={'errorMsg'}> {this.props.erroMessage} </div>
						</div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<div onClick={this.props.closeDeleteModal} className={'cancel-button'}><span>CANCEL</span></div>
								<div onClick={this.props.deleteHandleClick} className={'button'}><span>DELETE</span></div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

		);
	}
}

DeleteTemplateModal.propTypes = {
	deleteModalIsOpen: PropTypes.bool,
	customStyles: PropTypes.object,
	deleteHandleClick: PropTypes.func,
	closeDeleteModal: PropTypes.func,
	erroMessage: PropTypes.string,
	isPortfolio: PropTypes.bool
};

export default DeleteTemplateModal;


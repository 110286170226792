import { connect } from 'react-redux';

import * as actions from '../../actions/fundComparisonActions.js';

import Logout from './Logout';

function mapStateToProps(state) {
	return {

	};
}

function mapDispatchToProps(dispatch) {
	return {

	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

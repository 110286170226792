const INITIAL_STATE = {
	fetching: true,
	singlePortfolioTemplatesResult: [],
	portfolioComparisonTemplatesResult: [],
	pdfOutput: {
		pdfUrl: ''
	},
	saveTemplateDetailsP2: {
		success: 0,
		returnMessage: '',
		templateId: 0,
		templateName: ''
	},
	saveComparisonPortfolioTemplateDetails: {
		success: 0,
		returnMessage: '',
		comparisonPortfolioTemplateId: 0,
		comparisonPortfolioTemplateName: ''
	},
	pvpSaveComparisonTemplateDetails: {
		success: 0,
		returnMessage: '',
		pvpComparisonTemplateId: 0,
		pvpComparisonTemplateName: ''
	},
	portfolioConatainerIdsResult: '',
	workingCopyConatainerIdsResult:'',
	comparisonPortfolioInvestmentResult: {
		balanceInitial: 100000,
		containerIdP1: '',
		investmentListP1: [
			{wsod: '', weightage: 0}
		]
	},
	comparisonPortfolioInvestmentWithCloningResult: {
		balanceInitial: 100000,
		containerIdP1: '',
		investmentListP1: [
			{wsod: '', weightage: 0}
		]
	},
	portfolioAllocationsResult: {
		containerIdP1: '',
		investmentListP1: [
			{wsod: '', weightage: 0}
		]
	},
	comparisonPortfolioTemplateId: 0,
	comparisonPortfolioTemplateName:'',
	TemplateId: 0,
	TemplateName: '',
	portfolioTemplatesResult: [],
	performanceAsOfScreenerData:{},
	portfolioComparisonScreenerData:{},
	portfolioTwoComparisonScreenerData:{},
	oldestInceptionDates: {},
	scheduleCount: 0,
	schedules: [],
	schedulesP2: [],
	investmentSchedules: [],
	investmentSchedulesP2: [],
	removeScheduleResult: {},
	removeScheduleP2Result: {},
	// Fee Schedules
	addFeeScheduleResult: {},
	addFeeScheduleP2Result: {},
	modifyFeeScheduleResult: {},
	modifyFeeScheduleP2Result: {},
	removeFeeScheduleResult: {},
	removeFeeScheduleP2Result: {},
	feeSchedules: [],
	feeSchedulesP2: [],
	modifyScheduleCount: 0,
	contributionToRisk: {},
	contributionToRiskP2: {},
	contributionToRiskFinalAllocation: {},
	removeAllEventScheduleResult: {},
	removeAllEventScheduleP2Result: {},
	// Load Fee Schedules (Front, Deferred, Redemption)
	frontFeeSchedules: [],
	// Fund Investment Schedules: Modify, Delete
	modifyFrontInvestmentScheduleResult: {},
	modifyFrontInvestmentScheduleP2Result: {},
	removeFrontInvestmentScheduleResult: {},
	removeFrontInvestmentScheduleP2Result: {},
	benchmarkLatestDate: {},
	fundEvents: [],
	fundEventsP2: [],
	fundsPresentAmountList: [],
	xidFundShareClassMappings: {}
};
export default INITIAL_STATE;
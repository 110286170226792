import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';

import CheckIcon from '../Icons/CheckIcon';

import genericModalStyles from '../../../styles/components/modal/confirmationModal.scss';

class GenericModal extends React.PureComponent {
	render() {


		const customModalStyles = {
			content: {
				top: '24%',
				left: '15%',
				right: '15%',
				bottom: '12%',
				height: '535px',
				width: '544px',
				padding: 0,
				boxShadow: '0 1px 13px 4px #d0cfce'
			}
		};

		let indexQuoteStyle = {};
		if (this.props.isSaveDisabled) {
			indexQuoteStyle = {
				pointerevents: 'none',
				opacity: 0.4
			};
		}

		return (
			<Modal shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true}
				isOpen={this.props.confirmationModalIsOpen} style={customModalStyles} >
				<div className={'confirmationModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<CheckIcon />
								<div className={'header-text'}>{!this.props.isPortfolio ? 'Template Saved!' : (this.props.isPortfolioCompare ? 'Template Saved!' : ((this.props.showBuildCompareButton && this.props.isPortfoliosToSave) ? 'Portfolios Saved!' : 'Portfolio Saved!'))}</div>
							</div>
						</div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<div className={'txt-footer'}>What would you like to do?</div>
								<button onClick={this.props.closeConfirmationModal} id='returnReport' className={'btn-confirmation'}>{!this.props.isPortfolio ? 'RETURN TO REPORT' : (this.props.isPortfolioCompare ? 'RETURN TO REPORT' : ((this.props.showBuildCompareButton) ? 'RETURN TO BUILD YOUR COMPARISON' : 'RETURN TO BUILD NEW PORTFOLIO'))}</button>
								<button className="btn-confirmation" id='goHome' onClick={this.props.goToHomePage}>

									<Link to={!this.props.isPortfolio ? (this.props.userType.response === 'International'? "/FundInternational" : "/home") : (this.props.isPortfolioCompare ? (this.props.userType.response === 'International' ? "/PortfolioInternational/compare" : '/portfolio/compare') : this.props.userType.response === 'International' ? "/PortfolioInternational/single" : '/portfolio/single')}
										style={indexQuoteStyle}
										className="gotoHomeLink"
									>
										{this.props.isPortfolio ? 'GO TO PORTFOLIO HOME' : 'GO TO HOME'}
									</Link>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>

		);

	}
}

GenericModal.propTypes = {
	confirmationModalIsOpen: PropTypes.bool,
	customStyles: PropTypes.object,
	closeConfirmationModal: PropTypes.func,
	goToHomePage: PropTypes.func,
	isPortfolio: PropTypes.bool
};


export default GenericModal;

const INITIAL_STATE = {
	fetching: true,
	fundComparisonPdf: {
		pdfUrl: ''
	},
	saveTemplateDetails: {
		success: 0,
		returnMessage: '',
		templateId: 0,
		templateName: ''
	},
	saveTemplate: {
		success: 0,
		returnMessage: '',
		templateId: 0,
		templateName: ''
	},
	templatesResult: [],
	deleteTemplateDetails: {
		success: 0,
		returnMessage: ''
	},
	benchmarksResults: {
		benchmarks: [{
			name: '',
			shareClassId: '',
			xId: '',
			groupId: ''
		}]
	},
	primaryBenchmarksResult: {
		primarybenchmarks: [{
			benchmarkXid: ''
		}]
	},
	screenerData: {},
	screenerDataFundName: {},
	screenerDataMstarCategory:{},
	
	screenerFiltersOutput: {},
	pdfOutputOnSelectModal: {
		pdfUrl: ''
	},
	symbolSearchResults: {
		fundResults: [
			{
				key: null,
				xid: null,
				xtype: null,
				mfsName: null,
				mfsSymbol: null
			}
		],
		etfResults: [
			{
				key: null,
				xid: null,
				xtype: null,
				mfsName: null,
				mfsSymbol: null
			}
		]
	},
	wsodIssue: [],
	TemplateId: 0,
	TemplateName: '',
	templateDataset: {},
	validRollingReturnsPeriod: {},
	validCMPeriod: {},
	errorCode:{},
	mfsSiteInactive:false,
	mfsUSFvFinactive : false,
	mfsUSPvPinactive: false,
    mfsMILFvFinactive: false,
    mfsMILPvPinactive: false,
	siteInactiveId:0,
	mfsUserData: {},
	userType:{}
};
export default INITIAL_STATE;

import { push } from 'react-router-redux';
import { put, fork, call, take } from 'redux-saga/effects';

import * as format from '../utils/format';

import Types from '../actions/types';
import LocalApiService from '../services/local-api-service';
import AccessTokenService from '../services/access-token-service';
import MarkitApiService from '../services/markit-api-service';
import writeToDebug from '../utils/writeToDebug';

import { setAccessToken, onGenericApiFailure, recieveSuccessMfsInactiveFlagApi } from '../actions/fundComparisonActions';
import { getApiTypes } from './getApiTypes';
import { isDebugInfoOn } from '../utils/pathUtils';
import { getToken } from '../utils/helpers';

// Use a 'watcher/worker' pattern to listen for and handle redux Actions
export default () => {
	let api = null;
	let url = null;
	let platformApi = null;

	function getApiFunction(httpMethod) {
		let apiType;

		switch (httpMethod) {
			case 'POST':
				apiType = api.postData;
				break;
			case 'PUT':
				apiType = api.putData;
				break;
			case 'DELETE':
				apiType = api.deleteData;
				break;
			case 'GET':
			default:
				apiType = api.getData;
		}

		return apiType;
	}

	function getPlatformApiFunction(httpMethod) {
		let apiType

		switch (httpMethod) {
			case 'POST':
				apiType = platformApi.postData
				break
			case 'PUT':
				apiType = platformApi.putData
				break
			case 'DELETE':
				apiType = platformApi.deleteData
				break
			case 'GET':
			default:
				apiType = platformApi.getData
		}

		return apiType
	}

	// Worker
	function* worker(action) {

		const localAccessTokenQueryString =  format.getQueryString("access_token") || window.MD.ACCESS_TOKEN;
		const localAccessToken = localAccessTokenQueryString ;
		const host = window.location.host;
	
		let accessToken = AccessTokenService.retrieve(localAccessToken)
		if ((host.indexOf('localhost') > -1 || host.indexOf('mfs.dev.services.mdgapp.net') > -1) && !accessToken) {
			accessToken = getToken()
		} 
		// used to make platform api calls
		platformApi = MarkitApiService.create(window.MD.ApigeeUrl, accessToken)

		if (!api) {
			localStorage.setItem('access_token', accessToken)	
			api =  MarkitApiService.create(window.MD.MFS, accessToken);
			yield put(setAccessToken(accessToken));
		}
		let demoToken = "";
		
		// if (host.indexOf('localhost') > -1 || host.indexOf('mfs.dev.services.mdgapp.net') > -1) { // Running DEV site with demo-token
		// 	demoToken = action.endPoint.indexOf("?") > -1 ? "&access_token=demo-token" : "?access_token=demo-token"
		// }
		let endPoint = action.endPoint + demoToken;
		const parameters = action.params;
		const successAction = action.onSuccess;
		const failureAction = action.onFailure || onGenericApiFailure;
		const httpMethod = action.ajaxType;

		let apiType = getApiFunction(httpMethod);

		if (action.type === Types.API_REQUEST_XREF_USER_DATA_API || action.type === Types.API_REQUEST_XREF_USER_DATA_ETF_API) {
			apiType = getPlatformApiFunction(httpMethod);
			endPoint = action.endPoint
		}

		try {
			let response;
			let x;
			if (action.type === Types.GET_URLBASE_API) {
				response = {
					data: {
						url: url
					},
					ok: true
				};
			} else {
				if (action.type === Types.API_REQUEST_DECODE_API) {
					response = yield parameters.key.map(p =>
						call(apiType, endPoint, p)
					);
					isDebugInfoOn() &&
						writeToDebug(response[0]);
				} else {
					response = yield call(apiType, endPoint, parameters);
					isDebugInfoOn() &&
						writeToDebug(response);
				}

			}

			if (response.ok) {
				// 'data' keys the entire response object... always.
				yield put(successAction(response.data));
			} else if (action.type === Types.API_REQUEST_DECODE_API) {
				yield put(successAction(response));
			} else {
					yield put(failureAction(response));
			}
		} catch (error) {
			yield put(failureAction(error));
		}
	}

	// Watcher
	function* watcher() {
		const typesArray = getApiTypes();
		while (true) {
			const action = yield take(typesArray);
			yield fork(worker, action);
		}
	}

	return {
		watcher
	};
};

import React from 'react';
import { PropTypes } from 'prop-types';

import * as ValidationMessages from '../../../utils/validationMessages';

import resultHeaderStyles from '../../../styles/components/resultHeader.scss';

export default class ResultHeader extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const rowCount = this.props.rowCount > 0 ? this.props.rowCount : 0;

		const ScreenerFlyOutDisclaimerText = ValidationMessages.disclaimerTextScreenerFlyout.split('\n').map((item, i) => <p style={{ 'line-height': i === 0 ? '22px' : '0px' }} key={i}>{item}</p>);
		
		return (
			<div className={'lblResultHeader'}>
				<div className={'resultHeader'} style={{ height: this.props.componentType === "ScreenerFlyOut" ? '55px' : '43px' }} >
					<p id='lblRowCount' className={'lblRowCount'} style={{ 'vertical-align': this.props.componentType === "ScreenerFlyOut" ? top : null }} > {rowCount} {"Result(s)"}   </p >
					<p id='lbldisclaimer' className={'lbldisclaimer'}
					>
						{this.props.componentType === "ScreenerFlyOut" ? ScreenerFlyOutDisclaimerText : ValidationMessages.disclaimerText}
					</p>
					{this.props.showResetButton ?
						<div className={'reset'}>
							<button onClick={this.props.resetTab} className={'resetButton'} id="input-restsearch-btn">RESET FILTERS</button>
						</div> : null}
					{/* Include Cash in Portfolios */}
					{this.props.isPortfolio ?
						<div className={'total-investment'} style={{float: "right", margin: '12px 20px 0px 0px'}}>
							<label className="lbl-include-cash-portfolios">
								<input type={'checkbox'}
									id="chkIncludeCashInPortfolios"
									name='include-cash-portfolios'
								  onChange={this.props.onChangeIncludeCashInPortfolios}
								  checked={this.props.isIncludeCashInPortfolios}
								/>
								Include Cash in Portfolios
						</label>
						</div> : null}
				</div>
			</div>
		);
	}
}

ResultHeader.propTypes = {
	rowCount: PropTypes.number.isRequired,
	resetTab: PropTypes.func,
	showResetButton: PropTypes.bool,
	componentType: PropTypes.string,
	isPortfolio: PropTypes.bool,
	isPortfolioCompare: PropTypes.bool,
	onChangeIncludeCashInPortfolios: PropTypes.func,
	isIncludeCashInPortfolios: PropTypes.bool
};

import React from 'react';
import { PropTypes } from 'prop-types';
import Select, { components } from 'react-select';

import * as Constant from '../../../utils/constants';

import OneStarImage from '../../../images/StarImages/1Stars@1x.png';
import TwoStarImage from '../../../images/StarImages/2Stars@1x.png';
import ThreeStarImage from '../../../images/StarImages/3Stars@1x.png';
import FourStarImage from '../../../images/StarImages/4Stars@1x.png';
import FiveStarImage from '../../../images/StarImages/5Stars@1x.png';

import { SCREENER_TYPES } from '../../../utils/screenerConstants';


export default class ScreenerFilters extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedFundFamily: 'None',
			selectedMorningStarRating: 'None',
			selectedMorningStarCategory: 'None',
			screenerMasterData: {},
			morningStarFundFamily: [],
			morningStarCategory: [],
			starElement: 'None',
			fundFamilyElement: 'None',
			fundCategoryElement: 'None',
			resetTab: false
		};
	}

	componentDidMount() {
		if (
			this.props.screenerMasterData &&
			this.props.screenerMasterData.Columns &&
			this.props.screenerMasterData.Columns2 &&
			this.props.screenerMasterData.Columns3 //&&
		) {
			let optionsMorningStarCategory = this.props.screenerMasterData && this.props.screenerMasterData.Columns2 && this.props.screenerMasterData.Columns2.map(x => x);
			let optionsMorningStarFundFamily = this.props.screenerMasterData && this.props.screenerMasterData.Columns3 && this.props.screenerMasterData.Columns3.map(x => x);

			optionsMorningStarFundFamily = optionsMorningStarFundFamily !== null ? optionsMorningStarFundFamily.sort((a, b) => a.localeCompare(b)) : '';
			optionsMorningStarCategory = optionsMorningStarCategory !== null ? optionsMorningStarCategory.sort((a, b) => a.localeCompare(b)) : '';

			this.setState({
				screenerMasterData: this.props.screenerMasterData,
				morningStarCategory: optionsMorningStarCategory,
				morningStarFundFamily: optionsMorningStarFundFamily
			});
		}


	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.screenerMasterData &&
			nextProps.screenerMasterData.Columns !== this.props.screenerMasterData.Columns &&
			nextProps.screenerMasterData.Columns2 !== this.props.screenerMasterData.Columns2 &&
			nextProps.screenerMasterData.Columns3 !== this.props.screenerMasterData.Columns3 //&&
		) {
			let optionsMorningStarCategory = nextProps.screenerMasterData && nextProps.screenerMasterData.Columns2 && nextProps.screenerMasterData.Columns2.map(x => x);
			let optionsMorningStarFundFamily = nextProps.screenerMasterData && nextProps.screenerMasterData.Columns3 && nextProps.screenerMasterData.Columns3.map(x => x);

			optionsMorningStarFundFamily = optionsMorningStarFundFamily !== null ? optionsMorningStarFundFamily.sort((a, b) => a.localeCompare(b)) : '';
			optionsMorningStarCategory = optionsMorningStarCategory !== null ? optionsMorningStarCategory.sort((a, b) => a.localeCompare(b)) : '';

			this.setState({
				screenerMasterData: nextProps.screenerMasterData,
				morningStarCategory: optionsMorningStarCategory,
				morningStarFundFamily: optionsMorningStarFundFamily
			});

		}

		if (nextProps.resetFiltersToNone) {
			this.setState({
				selectedFundFamily: 'None',
				selectedMorningStarRating: 'None',
				selectedMorningStarCategory: 'None',
				starElement: 'None',
				fundFamilyElement: 'None',
				fundCategoryElement: 'None'
			});
		}
	}

	resetScreenerArguments = () => {
		return {
			argsOperator: 'AND',
			conditions: [
				{
					operator: 'like',
					values: [],
					valueOperator: 'OR'
				}
			],
			field: ''
		};
	}

	resetTab = () => {
		this.props.resetTabOnDropDownChange(this.state.resetTab);
	}

	setScreenerFilterCriteria = () => {
		let screenerInput = {
			argsOperator: 'AND',
			arguments: [
				{
					argsOperator: 'AND',
					conditions: [
						{
							operator: 'like',
							values: [],
							valueOperator: 'OR'
						}
					],
					field: 'MSDWSODIssue'
				}
			],
			firstRow: 0,
			resultFields: [
				'MSDFundName',
				'MSDPerformanceAsOfDate',
				'MSDLastClose',
				'MFSSymbol',
				'MSDFundFamilyName',
				'MSDWSODIssue',
				'MSDMorningstarOverallRating',
				'MSDMorningstarCategoryName',
				'MSDInceptionDate'
			],
			resultFormat: 'MarketsTopMutualFunds',
			rowCount: 200,
			sortArguments: [
				{
					direction: 'A',
					field: 'MSDFundName'
				}
			]
		};

		let isValidInput = false;

		let screenerArguments = {
			argsOperator: 'AND',
			conditions: [
				{
					operator: 'like',
					values: [],
					valueOperator: 'OR'
				}
			],
			field: ''
		};


		if (this.state.selectedFundFamily !== null && this.state.selectedFundFamily !== 'None' &&
			this.state.selectedMorningStarRating !== null && this.state.selectedMorningStarRating !== 'None' &&
			this.state.selectedMorningStarCategory !== null && this.state.selectedMorningStarCategory !== 'None'
		) {

			screenerInput = this.setScreenerInputs(screenerInput, 'MSDFundFamilyName', this.state.selectedFundFamily, 1);
			screenerInput.arguments.push(this.setScreenerInputs(screenerArguments, 'MSDMorningstarOverallRating', this.state.selectedMorningStarRating.toString(), 0));

			screenerArguments = this.resetScreenerArguments(); //resetting screener input
			screenerInput.arguments.push(this.setScreenerInputs(screenerArguments, 'MSDMorningstarCategoryName', this.state.selectedMorningStarCategory));

			isValidInput = true;
		} else if (this.state.selectedFundFamily !== null && this.state.selectedFundFamily !== 'None' &&
			this.state.selectedMorningStarRating !== null && this.state.selectedMorningStarRating !== 'None') {


			screenerInput = this.setScreenerInputs(screenerInput, 'MSDFundFamilyName', this.state.selectedFundFamily, 1);

			screenerInput.arguments.push(this.setScreenerInputs(screenerArguments, 'MSDMorningstarOverallRating', this.state.selectedMorningStarRating.toString(), 0));
			isValidInput = true;

		} else if (this.state.selectedFundFamily !== null && this.state.selectedFundFamily !== 'None' &&
			this.state.selectedMorningStarCategory !== null && this.state.selectedMorningStarCategory !== 'None') {

			screenerInput = this.setScreenerInputs(screenerInput, 'MSDFundFamilyName', this.state.selectedFundFamily, 1);
			screenerInput.arguments.push(this.setScreenerInputs(screenerArguments, 'MSDMorningstarCategoryName', this.state.selectedMorningStarCategory, 0));

			isValidInput = true;

		} else if (this.state.selectedMorningStarCategory !== null && this.state.selectedMorningStarCategory !== 'None'
			&& this.state.selectedMorningStarRating !== null && this.state.selectedMorningStarRating !== 'None') {


			screenerInput = this.setScreenerInputs(screenerInput, 'MSDMorningstarCategoryName', this.state.selectedMorningStarCategory, 1);
			screenerInput.arguments.push(this.setScreenerInputs(screenerArguments, 'MSDMorningstarOverallRating', this.state.selectedMorningStarRating.toString(), 0));

			isValidInput = true;
		} else if (this.state.selectedMorningStarCategory !== null && this.state.selectedMorningStarCategory !== 'None') {
			screenerInput = this.setScreenerInputs(screenerInput, 'MSDMorningstarCategoryName', this.state.selectedMorningStarCategory, 1);

			isValidInput = true;
		} else if (this.state.selectedFundFamily !== null && this.state.selectedFundFamily !== 'None') {
			if (this.props.tabType === 'MFSfund' && this.state.selectedFundFamily === 'MFS') {
				isValidInput = false;
			} else {
				screenerInput = this.setScreenerInputs(screenerInput, 'MSDFundFamilyName', this.state.selectedFundFamily, 1);
				isValidInput = true;
			}
		} else if (this.state.selectedMorningStarRating !== null && this.state.selectedMorningStarRating !== 'None') {
			screenerInput = this.setScreenerInputs(screenerInput, 'MSDMorningstarOverallRating', this.state.selectedMorningStarRating.toString(), 1);
			isValidInput = true;
		}

		if (isValidInput) {
			this.props.resetScreenerFilters('false');
			this.props.requestScreenerFiltersDataApi(
				SCREENER_TYPES.MutualFund,
				screenerInput,
				'no'
			);
			if(this.props.updateScreenerFilterArguments) {
				this.props.updateScreenerFilterArguments(screenerInput.arguments);
			}
		} else {
			this.props.resetScreenerFiltersOutput();
			this.props.resetScreenerFilters('None');			
		}
	}

	setScreenerInputs = (screenerInputs, fieldName, value, isScreenerInput) => {
		// screenerInput
		if (isScreenerInput) {
			screenerInputs.arguments[0].field = fieldName;
			screenerInputs.arguments[0].conditions[0].values.push(value);
		} else {
			screenerInputs.field = fieldName;
			screenerInputs.conditions[0].values.push(value);
		}
		return screenerInputs;
	}

	onSelectedMorningstarFundFamily = (values, e) => {
		const value = values.value;
		if (this.state.selectedFundFamily !== value) {
			if (value && value !== 'None') {
				this.setState({
					selectedFundFamily: value, fundFamilyElement: this.formatStringValues(value, 0),
					resetTab: true
				}, () => {
					(() => this.setScreenerFilterCriteria())();
				});
			} else {
				this.setState({
					selectedFundFamily: value, fundFamilyElement: value,
					resetTab: true
				}, () => {
					(() => this.setScreenerFilterCriteria())();
				});
			}
		}
	}

	onSelectedMorningstarRating = (values, e) => {
		const value = values.value;
		if (this.state.selectedMorningStarRating !== value) {
			this.setState({
				selectedMorningStarRating: value, starElement: this.displayStarsAsSelectedItem(value),
				resetTab: true
			}, () => {
				(() => this.setScreenerFilterCriteria())();
			});
		}
	}

	onSelectedMorningstarCategory = (values, e) => {
		const value = values.value;
		if (this.state.selectedMorningStarCategory !== value) {
			if (value && value !== 'None') {
				this.setState({
					selectedMorningStarCategory: value, fundCategoryElement: this.formatStringValues(value, 1),
					resetTab: true
				}, () => {
					(() => this.setScreenerFilterCriteria())();
				});
			} else {
				this.setState({
					selectedMorningStarCategory: value, fundCategoryElement: value,
					resetTab: true
				}, () => {
					(() => this.setScreenerFilterCriteria())();
				});
			}
		}
	}

	displayStarsAsSelectedItem = (rating) => {
		switch (rating) {
			case 5: return <div className={'selectedStar'}>  <img src={FiveStarImage} /> </div>;
			case 4: return <div className={'selectedStar'}>  <img src={FourStarImage} /> </div>;
			case 3: return <div className={'selectedStar'}>  <img src={ThreeStarImage} /> </div>;
			case 2: return <div className={'selectedStar'}>  <img src={TwoStarImage} /> </div>;
			case 1: return <div className={'selectedStar'}>  <img src={OneStarImage} /> </div>;
			case 'None': return 'None';
			default: return 'None';
		}
	}


	formatStringValues = (value, type) => {
		if (type === 0) { // fund Family column
			return <div className="fundFamily">   {value} </div>;
		}
		return <div className="categoryName">   {value} </div>;
	}


	render() {
		const DropdownIndicator = props => {
			return (
				components.DropdownIndicator && (
					<components.DropdownIndicator {...props}>
						<i
							className="dropdown"
							aria-hidden="true"
						/>
					</components.DropdownIndicator>
				)
			);
		};

		const customStyles = {
			control: (base, state) => ({
				...base,
				border: '1px solid rgba(34,36,38,.15) !important',
				boxShadow: '0 !important',
				'&:hover': {
					border: '1px solid rgba(34,36,38,.15) !important'
				},
				height: '34px',
				'min-height': '34px'
			}),
			option: (provided, state) => ({
				...provided,
				background: state.isSelected ? '#f4f4f4' : (state.isFocused ? '#f4f4f4' : '#FFF'),
				color: '#333',
				'font-weight': state.isSelected ? 'bold' : 'normal'
			}),
			menu: styles => ({ ...styles, margin: '0px' }),
			noOptionsMessage: styles => ({ ...styles, 'text-align': 'left', color: 'rgba(0,0,0,.4)' })
		}
		const customTheme = {
			borderRadius: 0,
			border: 0,
			colors: {
				primary: '#f4f4f4',
				primary50: '#f4f4f4',
				neutral0: '#FFF',
			},
		}

		const fundFamilyList = [{ label: 'None', value: 'None' }];
		const morningstarRatingList = [
			{ label: 'None', value: 'None' },
			{ label: <img src={FiveStarImage} />, value: 5 },
			{ label: <img src={FourStarImage} />, value: 4 },
			{ label: <img src={ThreeStarImage} />, value: 3 },
			{ label: <img src={TwoStarImage} />, value: 2 },
			{ label: <img src={OneStarImage} />, value: 1 }
		];
		const morningStarCategoryList = [{ label: 'None', value: 'None' }];
		this.state.morningStarFundFamily && this.state.morningStarFundFamily.map((item, index) => (
			fundFamilyList.push({ label: item, value: item })
		));
		this.state.morningStarCategory && this.state.morningStarCategory.map((item, index) => (
			morningStarCategoryList.push({ label: item, value: item })
		));
		return (<div id="screenerFilters">
			<div>
				<label className="label-style">Fund Family  </label>
				<label className="label-style morningstar-rating"> Morningstar Overall Rating  </label>
				<label className="label-style morningstar-category"> Morningstar Category </label>
			</div>
			<div>
				<div className="label-style">
					<Select
						name="Fund Family"
						className="dropdown-mfs"
						theme={customTheme}
						styles={customStyles}
						isSearchable={true}
						defaultValue={this.state.selectedFundFamily}
						value={{ label: this.state.selectedFundFamily, value: this.state.selectedFundFamily }}
						onChange={this.onSelectedMorningstarFundFamily}
						options={fundFamilyList}
						components={{ DropdownIndicator }}
						noOptionsMessage={() => "No results found."}
						id="ddnFundFamilyName" />
				</div>

				<div className="label-style">
					<Select
						name="Morningstar Overall Rating"
						className="dropdown-mfs"
						theme={customTheme}
						styles={customStyles}
						isSearchable={false}
						defaultValue={this.state.selectedMorningStarRating}
						value={{ label: this.state.starElement, value: this.state.selectedMorningStarRating }}
						onChange={this.onSelectedMorningstarRating}
						options={morningstarRatingList}
						components={{ DropdownIndicator }}
						id="ddnMorningStarRating" />
				</div>
				<div className="label-style">
					<Select
						name="Morningstar Category"
						className="dropdown-mfs"
						theme={customTheme}
						styles={customStyles}
						isSearchable={true}
						defaultValue={this.state.selectedMorningStarCategory}
						value={{ label: this.state.selectedMorningStarCategory, value: this.state.selectedMorningStarCategory }}
						onChange={this.onSelectedMorningstarCategory}
						options={morningStarCategoryList}
						components={{ DropdownIndicator }}
						noOptionsMessage={() => "No results found."}
						id="ddnMorningStarCategory" />
				</div>
			</div>
		</div>
		);
	}
}

ScreenerFilters.propTypes = {
	requestScreenerFiltersDataApi: PropTypes.func,
	screenerMasterData: PropTypes.Array,
	resetScreenerFilters: PropTypes.func,
	resetFiltersToNone: PropTypes.boolean,
	resetScreenerFiltersOutput: PropTypes.func
};

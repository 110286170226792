import React from 'react';

import TriangleGreySvg from "./Icons/TriangleGreyImage";
import maintenancePageStyles from "../../styles/components/MaintenancePage.scss";

const MaintenancePage = () => {
	return (
			<div className={'maintenancePage'}>
                <TriangleGreySvg></TriangleGreySvg>
				<div className={'warning-message1'}>Temporarily Down For Maintenance</div>
                <div className={'waning-message2'}>We apologize for any inconvenience, we will be back </div>
			</div>
    );
}

export default MaintenancePage;
import React from 'react';

import { Button, OverlayTrigger, ButtonToolbar, Tooltip } from 'react-bootstrap';

import CloseWhiteTooltipIcon from '../../common/Icons/CloseWhiteTooltipIcon';
import GripperWhiteTooltipIcon from '../../common/Icons/GripperWhiteTooltipIcon';
import LineWhiteTooltipIcon from '../../common/Icons/LineWhiteTooltipIcon';

import tooltipStyles from '../../../styles/components/ModuleInfotoolTip.scss';
import LockIcon from "../../common/Icons/ModuleLockIcon";
import ModuleAddIcon from "../../common/Icons/ModuleAddIconPop";

class ModuleInfoTooltip extends React.Component {
	tooltip() {
		return (
			<Tooltip>
				<p className={'info-tooltip-text-description'}>
					<br></br>Customize your report in the table below. 
				</p>
				<p className={'info-tooltip-line-break'}>
						<LineWhiteTooltipIcon />
				</p>
				<p className={'info-tooltip-text-description'}>
					Groups and modules with a
					lock icon <LockIcon /> are in a fixed order and cannot be added or
					removed. 
				</p>
				<p className={'info-tooltip-line-break'}>
						<LineWhiteTooltipIcon />
				</p>
				<p className={'info-tooltip-text-description'}>
					Groups and modules with an add icon <ModuleAddIcon /> do not
					have a fixed order and can be added and removed from the report.
				</p>
				<p className={'info-tooltip-line-break'}>
						<LineWhiteTooltipIcon />
				</p>
				<p className={'info-tooltip-text-description'}>
					Modules with both lock and add icons <ModuleAddIcon /> <LockIcon />{" "}
					have a fixed order, but can be added and removed from the report
					independent of the other modules in their group.
				</p>
				
			</Tooltip>
		);
	}

	positionerInstance() {
		return (
			<ButtonToolbar>
				<OverlayTrigger
					ref="tooltipTrigger"
					placement="bottom"
					overlay={this.tooltip()}
					rootClose
					trigger="click"
				>
					<p id="infoIcon" className={'info-img'} />
				</OverlayTrigger>
			</ButtonToolbar>
		);
	}

	render() {
		return <div>{this.positionerInstance()}</div>;
	}
}

export default ModuleInfoTooltip;

import React, { Component } from 'react';

import { DropTarget } from 'react-dnd';
import { PropTypes } from 'prop-types';

import Card from './card';

class dragContainer extends Component {
	constructor(props) {
		super(props);
		this.state = { cards: this.props.list };
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.list !== this.state.cards) {
			this.setState({ cards: nextProps.list });
		}
	}

	render() {
		const cards = this.state.cards;
		const { canDrop, isOver, connectDropTarget } = this.props;
		const isActive = canDrop && isOver;
		const cardLength = cards === undefined ? 0 : cards.length;
		const staticList = ['Funds In Focus', 'Standardized Performance', 'Disclosures'];
		return (
			<div>
				{cardLength > 0 ? cards.map((card, i) => {
					return (
						!staticList.includes(card.sectionname) && card.isActive === true
							? <div>
								<Card
									index={i}
									listId={'A'}
									isSection
									card={card}
									moveCardLeftRail={this.props.moveCardLeftRail}
									handleDeleteFundClick={this.props.handleDeleteFundClick} />

								{card.subsections.length > 0 ? <ul className="childul">
									{card.subsections.map((subcard, j) => {
										return (
											subcard.isActive === true ?
												<Card
													index={j}
													listId={i}
													isSection={false}
													card={subcard}
													moveCardLeftRail={this.props.moveCardLeftRail}
													handleDeleteFundClick={this.props.handleDeleteFundClick} />
												: ''
										);
									})}
								</ul> : ''}
							</div> : ''
					);
				}) : ''}
			</div>
		);
	}
}

const cardTarget = {
	drop(props, monitor, component) {
		const { list } = props;
		const sourceObj = monitor.getItem();
		const pushed = false;

		return {
			listId: sourceObj,
			pushed: pushed
		};
	}
};

export default dragContainer;

dragContainer.propTypes = {
	moveCardLeftRail: PropTypes.func,
	handleDeleteFundClick: PropTypes.func,
	connectDropTarget: PropTypes.func,
	list: PropTypes.object
};

import React from 'react';

const ExpandedArrow = props => {
	return (
		<span role="presentation" className={'add'} >
			<svg width="12px" height="7px" viewBox="0 0 12 7" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<title>Icon/Expanded</title>
				<desc>Created with Sketch.</desc>
				<g id="Icon/Expanded" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g fill="#7E2D40" fill-rule="nonzero" id="Path">
						<polygon points="10.594 0 12 1.3289225 6 7 0 1.3289225 1.406 0 6 4.34215501"></polygon>
					</g>
				</g>
			</svg>
		</span >
	);
};

export default ExpandedArrow;

import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';

function GenericModal(props) {

	useEffect(() => {
		window.setTimeout(function () {
			if(document.getElementsByClassName('cancel-button').length> 0){
			document.getElementsByClassName('cancel-button')[0].style.opacity = '1';
			document.getElementsByClassName('cancel-button')[0].style['pointer-events'] = 'all';
			}
		}.bind(this),6000);
	});

	let cancelDisabled = {
			'pointer-events': 'none',
			opacity: 0.4
	}
	let indexQuoteStyle = {
		'pointer-events': 'none',
		opacity: 0.4
	};
	
	if (!props.isSaveDisabled) {
		indexQuoteStyle = {};
	}

		return (
			<Modal
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				isOpen={props.saveModalIsOpen}
				style={props.customStyles}
			>
				<div className={!props.isPortfolio ? 'saveModal' : 'savePortfolioModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								{!props.isPortfolio ? <div className={'header-text'}>Save as Template</div>
									: props.isPortfolioCompare ? <div className={'header-text'}>Save Template</div> : <div className={'header-text'}>Save Portfolio</div>}
							</div>
						</div>
						<div className={'searchMainModal'}>
							<div className={'searchText'}>
								{!props.isPortfolio ? <div className={'lbl-template'}> Template Name </div>
									: props.isPortfolioCompare ? <div className={'lbl-template'}>Enter Comparison Name</div> : 
									<div className={'lbl-template'}>Enter Portfolio Name </div>}
								<input
									autoComplete="off"
									data-name="search"
									type="text"
									id="search"
									placeholder={props.isPortfolioCompare? "Enter Comparison Name":(!props.isPortfolio ? "" :"Enter Portfolio Name")}
									className={'template-name-text'}
									onChange={props.handleInputChange}
									onKeyPress={props.handleEnterClickOnTextbox}
									autoFocus={true}
								/>
							</div>
							{props.isPortfolio ?
								<div className={'searchText'}>
									<div className={'lbl-template'}> Description </div>
									<input
										autoComplete="off"
										data-name="description"
										type="text"
										id="txt-description"
										placeholder="Enter a description of 120 characters or less"
										className={'template-name-text'}
										onKeyPress={props.handleEnterClickOnTextbox}
									/>
								</div>
								: null}
							<div className={'lbl-errormessage'}>{props.status}</div>
						</div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<button
									onClick={props.closeSaveModal}
									className={'cancel-button'}
									style={cancelDisabled}
								>
									CANCEL
								</button>
								<button
									onClick={props.saveTemplateSettings}
									style={indexQuoteStyle}
									className={'save-button'}
								>
									SAVE
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}

export default GenericModal;

import React from 'react';

const LineWhiteTooltipIcon = props => {
	return (
		<svg
			width="160px"
			height="1px"
			viewBox="0 0 160 1"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Layout/Line</title>
			<desc>Created with Sketch.</desc>
			<defs />
			<g
				id="Final"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
				opacity="0.169475446"
			>
				<g
					id="Help-Modal-"
					transform="translate(-20.000000, -365.000000)"
					fill="#D7D7D7"
					fill-rule="nonzero"
				>
					<g id="Modal-">
						<g id="Layout/Line" transform="translate(20.000000, 365.000000)">
							<polygon
								id="Rectangle-4-Copy-4"
								points="0 0 160 5.68434189e-14 160 1 0 1"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default LineWhiteTooltipIcon;

import 'babel-polyfill';
import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';
import xmlToJSON from "xmljson";
import lodash from 'lodash';
import SaveModal from '../common/modal/saveModal.js';
import ConfirmationModal from '../common/modal/ConfirmationModal';
import ReplaceTemplateModal from '../common/modal/ReplaceTemplateModal';
import LeftRail from './subComponents/leftRail';
import RightRail from './subComponents/rightRail';
import DownloadingSpinnerModal from '../common/modal/DownloadingSpinnerModal';
import * as Constant from '../../utils/constants';
import * as ValidationMessages from '../../utils/validationMessages';
import * as utilityFunctions from '../../utils/commonMethods';
import * as urlMethods from '../../utils/urlMethods';
import * as CustomModalStyles from '../../utils/customModalStyles';
import { SCREENER_FIELDS_MUTUALFUND, SCREENER_TYPES, SORT_ARGUMENTS } from '../../utils/screenerConstants';
import * as Format from "../../utils/format";
import { getFormattedEtfFunds } from "../../utils/helpers"
import BenchmarkConfirmationModal from '../../components/common/modal/BenchmarkConfirmationModal.js';
import ValidationModal from '../../components/common/modal/ValidationModal.js';
import BenchmarkValidationModal from '../../components/common/modal/ValidationModal.js';
import ErrorMessageModal from "../common/modal/ErrorMessageModal"; 

class SelectFundsModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			saveModalIsOpen: false,
			replaceModalIsOpen: false,
			confirmationModalIsOpen: false,
			isSaveDisabled: true,
			templateResult: {},
			templateData: {},
			statusMessage: '',
			searchResults: {},
			wsodIssue: 0,
			mfsFundError: false,
			benchmarkError: false,
			screenerOutput: [],
			screenerFiltersOutput: {},
			benchmarks: [],
			isFIFAdded: 0,
			screenerMasterData: {},
			templateName: '',
			fifFundName: '',
			nonFifFundName: [],
			benchmarkModalIsOpen: false,
			isFromSelectModules: false,
			benchmarkMessage: ["Your comparison includes more than one MFS fund with different prospectus benchmarks. For European region reports, all MFS funds must have the the same prospectus benchmark. Please review your fund selection."],
			validationModalIsOpen: false,
			errors: [],
			benchmarkValidationModalIsOpen: false,
			shouldLoadDefaultTimePeriods: false,
			isFundChangeDone: false,
			disableCurrency: false,
			validationModal3MonthIsOpen: false, // to handle logic to show other modal popup if this conditon is paased
			shouldLoadDefaultCMTimePeriods: false,
			validationModal3MonthOpen: false,
			isvalidationModal3MonthOpen: false,
			validRollingReturnsPeriod: [],
			downloadSpinnerModalIsOpen: false,
			isFromSaveButtonCalled: false, // to handle review funds < 3 months popup
			endDateValidationerrors: ["The funds selected have an older performance end date compared to Fund in Focus. Select another fund to proceed."]
		};
		this.fifFundName = '';
		this.nonFifFundName = [];
		this.CounterLoad = 0;
		this.validationModal3MonthCounter = 0;
		this.isDiffPrimarybenchmark = false;
		this.openModuleSelection =false;
		this.isSaveTemplate = false;
		this.currency = this.props.isComingFromModuleSelection ? '' : 'USD'; //Handle currency fro MFSInternational User
		this.sectionNames = ['Standardized Performance', 'Risk & Performance Statistics']
	}

	UNSAFE_componentWillMount() {
		let _templateResult = cloneDeep(Constant.templateResult);
		let _templateData = cloneDeep(Constant.templateData);
		if (this.props.userType.response === 'International') {
			_templateResult.currency = 'USD';
			_templateData.currency = 'USD';
			_templateResult.language = 'en-GB';
			_templateData.language = 'en-GB';
			// _templateData.currency = 'USD';
		}
		this.setState({
			templateResult: _templateResult,
			templateData: _templateData
		});
	}


	changeCurrency = (values, e) => {
		const value = values.value;
		if (this.state.templateResult.currency !== value) {
			this.state.templateData.currency = value;
			this.state.templateResult.currency = value;
			this.currency = value;
			this.setState({
				templateResult: this.state.templateResult,
				templateData: this.state.templateData
			});
			
			if (this.state.templateResult.funds && this.state.templateResult.funds.fundinfocus && this.state.templateResult.funds.fundinfocus.length > 0) {
				this.props.requestGetPrimaryBenchmarkApi(this.state.templateResult.funds.fundinfocus[0].wsodissue, (this.props.userType.response === 'International') ? 'yes' : 'no', this.currency);
			}
		}
		this.requestGetBenchmarksApi('');
		this.loadBlendedBenchmark();
	}
	setPrimaryBenchmark = (data) => {
		const newtemplateResultState = Object.assign({}, this.state.templateResult);
		const primaryBenchmark = [{ symbol: data.name, wsodissue: data.wsod, fundfamily: 'PrimaryBenchmark' }];
		newtemplateResultState.funds.primarybenchmark = primaryBenchmark;
		this.setState({ templateResult: newtemplateResultState });
	}
	getPrimaryProspectusBenchmarkData = (data) => {
		if (this.state.templateResult.funds.primarybenchmark?.length > 0
			&& data.wsod !== this.state.templateResult.funds.primarybenchmark[0].wsodissue) {
			this.setPrimaryBenchmark(data);
		} else if (this.state.templateResult.funds.primarybenchmark?.length === 0) {
			this.setPrimaryBenchmark(data);
		}
	}
	disableScrolling = () => {
		document.body.style.overflow = 'hidden';
	}

	enableScrolling = () => {
		document.body.style.overflow = 'auto';
	}


	handleEnterClickOnTextbox = (e) => {
		if (e.key === 'Enter') {
			this.saveTemplateSettings();
		}
	}

	checkValidation(sourceListId, DestinationListId, item) {
		let canMove = false;
		const newtemplateResultState = Object.assign({}, this.state.templateResult);
		if (sourceListId === 2 && DestinationListId === 1) {
			if (newtemplateResultState.funds.fundinfocus.length < 1) {
				canMove = true;
				if (!(item.fundfamily === 'MFS')) {
					canMove = false;
					!canMove
						? this.setState({ mfsFundError: true, benchmarkError: false })
						: this.setState({ mfsFundError: false, benchmarkError: false });
				}
			}
		}
		if (sourceListId === 2 && DestinationListId === 3) {
			if (newtemplateResultState.funds.benchmark.length < 1) {
				canMove = true;
				if (!(item.fundfamily === 'Benchmark')) {
					canMove = false;
					!canMove
						? this.setState({ mfsFundError: false, benchmarkError: true })
						: this.setState({ mfsFundError: false, benchmarkError: false });
				}
			}
		}
		if (sourceListId === 1 && DestinationListId === 3) {
			canMove = false;
			!canMove
				? this.setState({ mfsFundError: false, benchmarkError: true })
				: this.setState({ mfsFundError: false, benchmarkError: false });
		}
		if (sourceListId === 3 && DestinationListId === 1) {
			canMove = false;
			!canMove
				? this.setState({ mfsFundError: true, benchmarkError: false })
				: this.setState({ mfsFundError: false, benchmarkError: false });
		}
		if ((sourceListId === 1 || sourceListId === 3) && DestinationListId === 2) {
			canMove =
				newtemplateResultState.funds.nonfundinfocus.length < 4;
		}
		setTimeout(
			() => {
				this.setState({ mfsFundError: false });
			},
			3000
		);

		setTimeout(
			() => {
				this.setState({ benchmarkError: false });
			},
			3000
		);

		return canMove;
	}

	pushCardLeftRail = (item, listId) => {
		const newtemplateResultState = Object.assign({}, this.state.templateResult);
		if (listId === 1) {
			if(Constant.milCurrencySpecificFunds.find(x=>x.fund == item.name) !== undefined) {
				newtemplateResultState.funds.fundinfocus.push(item);
				this.props.requestGetPrimaryBenchmarkApi(item.wsodissue,
				(this.props.userType.response === 'International') ? 'yes' : 'no',
				(this.state.templateResult.currency !== undefined) ? ((Constant.milCurrencySpecificFunds.find(x=>x.fund == item.name) !== undefined && Constant.milCurrencySpecificFunds.find(x=>x.fund == item.name).baseCurrency)? Constant.milCurrencySpecificFunds.find(x=>x.fund == item.name).baseCurrency : this.state.templateResult.currency ) : 'USD'
			);
				newtemplateResultState.currency = this.state.templateResult.currency = Constant.milCurrencySpecificFunds.find(x=>x.fund == item.name).baseCurrency;
				this.requestGetBenchmarksApi('');
				this.setState({						
					disableCurrency: true
				});
			} else{
			newtemplateResultState.funds.fundinfocus.push(item);
			this.props.requestGetPrimaryBenchmarkApi(item.wsodissue,
				(this.props.userType.response === 'International') ? 'yes' : 'no',
				(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'
			);
			}
			this.setState({ isFIFAdded: 1 });
		} else if (listId === 2) {
			newtemplateResultState.funds.nonfundinfocus.push(item);
		} else if (listId === 3) {
			newtemplateResultState.funds.benchmark.push(item);
		}
		this.setState({ templateResult: newtemplateResultState });
	}

	removeCardLeftRail = (index, listId) => {
		const newtemplateResultState = Object.assign({}, this.state.templateResult);
		if (listId === 1) {
			newtemplateResultState.funds.fundinfocus.splice(index, 1);
			if(this.props.userType.response === 'International') {
				this.setState({						
					disableCurrency: false
				});
			}
		} else if (listId === 2) {
			newtemplateResultState.funds.nonfundinfocus.splice(index, 1);
		} else if (listId === 3) {
			newtemplateResultState.funds.benchmark.splice(index, 1);
		}
		this.setState({ templateResult: newtemplateResultState });
	}

	moveCardLeftRail = (dragIndex, hoverIndex, listId) => {
		const newtemplateResultState = Object.assign({}, this.state.templateResult);
		if (listId === 1) {
			const dragItem = newtemplateResultState.funds.fundinfocus[dragIndex];
			if(Constant.milCurrencySpecificFunds.find(x=>x.fund == dragIndex.name) !== undefined) {
				newtemplateResultState.funds.fundinfocus.splice(dragIndex, 1);
				newtemplateResultState.funds.fundinfocus.splice(hoverIndex, 0, dragItem);
				newtemplateResultState.currency = this.state.templateResult.currency = Constant.milCurrencySpecificFunds.find(x=>x.fund == dragIndex.name).baseCurrency;
				this.setState({						
					disableCurrency: true
				});
				} else{
					newtemplateResultState.funds.fundinfocus.splice(dragIndex, 1);
					newtemplateResultState.funds.fundinfocus.splice(hoverIndex, 0, dragItem);
				}
		} else if (listId === 2) {
			const dragItem = newtemplateResultState.funds.nonfundinfocus[dragIndex];
			newtemplateResultState.funds.nonfundinfocus.splice(dragIndex, 1);
			newtemplateResultState.funds.nonfundinfocus.splice(
				hoverIndex,
				0,
				dragItem
			);
		} else if (listId === 3) {
			const dragItem = newtemplateResultState.funds.benchmark[dragIndex];
			newtemplateResultState.funds.benchmark.splice(dragIndex, 1);
			newtemplateResultState.funds.benchmark.splice(hoverIndex, 0, dragItem);
		}
		this.setState({ templateResult: newtemplateResultState });
	}

	handleDeleteFundClick = (e) => {
		const wsodIssue = e.target.getAttribute('data-wsodIssue');
		const symbol = e.target.getAttribute('data-symbol');
		const listId = e.target.getAttribute('data-listId');
		const newtemplateResultState = Object.assign({}, this.state.templateResult);
		if (listId === '1') {
			const mfsFund = [];
			newtemplateResultState.funds.fundinfocus = mfsFund;
			this.setState({ isFundChangeDone: true });
			if(this.props.userType.response === 'International') {
				this.setState({						
					disableCurrency: false
				});
			}
		} else if (listId === '2') {
			const newNonFundInFocus = this.state.templateResult.funds.nonfundinfocus;
			let removeIndex = -1;
			removeIndex = newNonFundInFocus.findIndex(i => {
				return i.symbol === symbol;
			});
			if (removeIndex > -1) {
				newNonFundInFocus.splice(removeIndex, 1);
				this.setState({ isFundChangeDone: true });
			}

			newtemplateResultState.funds.nonfundinfocus = newNonFundInFocus;
		} else if (listId === '3') {
			const benchmark = [];
			newtemplateResultState.funds.benchmark = benchmark;
		}
		let screenerData = cloneDeep(this.state.screenerOutput);
		screenerData.forEach(item => {
			if (item.MSDWSODIssue && item.MSDWSODIssue.toString() === wsodIssue) {
				item["isActive"] = false;
			}
		});
		this.setState({ templateResult: newtemplateResultState, screenerOutput: screenerData });
	}

	setFundInFocusArray = (templateResultFunds) => {
		if(!Array.isArray(templateResultFunds.fundinfocus)){
			templateResultFunds.fundinfocus = [templateResultFunds.fundinfocus];
			templateResultFunds.benchmark = [templateResultFunds.benchmark];
		}
	}

	openSaveOrReplaceModal = () => {
		this.isSaveTemplate = true;
			
		this.setFundInFocusArray(this.state.templateResult.funds);

		if(this.state.isFundChangeDone) {
			let _fundsArray = [];
			let _fundshareclassId = [];
			let _funds = this.state.templateResult.funds.nonfundinfocus;

			let benchmarkWsodissue = this.state.templateResult.funds.benchmark[0].wsodissue;
			_fundsArray.push(this.state.templateResult.funds.fundinfocus[0].wsodissue);
			_fundshareclassId.push(this.state.templateResult.funds.fundinfocus[0].fundShareClassId);
			
			_funds.forEach(function (item) {
				_fundsArray.push(item.wsodissue)			
			});
			_fundsArray = _fundsArray.toString();
			_fundshareclassId = _fundshareclassId.toString();
			this.props.requestValidRollingReturnPeriodDataApi(_fundsArray,this.props.userType.response === 'International' ? 'yes' : 'no', _fundshareclassId, benchmarkWsodissue);

		}
		this.setState({ 
			isFromSelectModules: false,
			isFromSaveButtonCalled: true
		});
		this.props.requestMfsSiteInactiveFlag();
		if (this.props.userType.response === 'International') {
			let funds = []
			funds.push(this.state.templateResult.funds.fundinfocus[0].fundShareClassId);
			this.state.templateResult.funds.nonfundinfocus.forEach(item => {
				funds.push(item.fundShareClassId)
			});
			let currency = this.state.templateResult.currency ? this.state.templateResult.currency : "USD";
			const screenerInput = Constant.screenerInternationalInputs;
			screenerInput.arguments[0].conditions[0].values = funds;
			screenerInput.arguments[1].conditions[0].values = utilityFunctions.getPerformanceEndDateValidation().toString().split(",");
			this.props.requestScreenerDataFundNameApi(SCREENER_TYPES.MutualFund, screenerInput, 'yes', currency);


			//Validate funds as per selected currency
			// let fundshareids = funds.join();
			// this.props.requestInternationalXidByFundShareClassId(fundshareids, currency);
		} else {
			this.saveTemplate = true;
			let funds = []
			funds.push(this.state.templateResult.funds.fundinfocus[0].wsodissue);
			this.state.templateResult.funds.nonfundinfocus.forEach(item => {
				funds.push(item.wsodissue)
			});
			const screenerInput = Constant.screenerInputs;
			screenerInput.arguments[0].conditions[0].values = funds;
			this.props.requestPerformanceAsOfScreenerDataApi(SCREENER_TYPES.MutualFund, screenerInput, 'no', (this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD');	
			
			// if (this.props.TemplateId > 0) {
			// 	this.openReplaceModal();
			// } else {
			// 	this.openSaveModal();
			// }
		}
	}

	validateDuplicationBeforeFundAddition(key, symbol) {
		let isValid = true;

		//Check in Fund in Focus section
		if (
			this.state.templateResult.funds.fundinfocus.length > 0 &&
			this.state.templateResult.funds.fundinfocus[0].symbol === symbol
		) {
			isValid = false;
		} else if (
			this.state.templateResult.funds.benchmark.length > 0 &&
			this.state.templateResult.funds.benchmark[0].symbol === symbol
		) {
			isValid = false;
		} else {
			const nonFundInFocusData = this.state.templateResult.funds.nonfundinfocus;
			let removeIndex = -1;

			removeIndex = nonFundInFocusData.findIndex(i => {
				return i.symbol === symbol;
			});

			if (removeIndex > -1) {
				isValid = false;
			}
		}
		return isValid;
	}

	addFundDataToLeftRail(key, flag, symbol, wsodIssue, name, _fundShareClassId, _performanceAsOfDate) {
		const data = {
			symbol: symbol,
			wsodissue: wsodIssue,
			fundfamily: flag,
			fundShareClassId: _fundShareClassId,
			name: name,
			isInternationalUser: this.props.userType.response === 'International',
			performanceAsOfDate: _performanceAsOfDate
		};
		const newtemplateResultState = Object.assign({}, this.state.templateResult);
		switch (key) {
			case 'FIF':
				if (this.validateDuplicationBeforeFundAddition(key, symbol)) {
					if(Constant.milCurrencySpecificFunds.find(x=>x.fund === data.name) !== undefined) {
						newtemplateResultState.funds.fundinfocus.push(data);
						newtemplateResultState.currency = this.state.templateResult.currency = Constant.milCurrencySpecificFunds.find(x=>x.fund == data.name).baseCurrency;
						this.requestGetBenchmarksApi('');
						this.setState({						
							disableCurrency: true
						});
						} else{
							newtemplateResultState.funds.fundinfocus.push(data);
						}
					this.props.requestGetPrimaryBenchmarkApi(wsodIssue,
						(this.props.userType.response === 'International') ? 'yes' : 'no',
						(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'
					);
					this.setState({ isFIFAdded: 1, isFundChangeDone: true });

				}
				break;
			case 'NFIF':
				if (this.validateDuplicationBeforeFundAddition(key, symbol)) {
					newtemplateResultState.funds.nonfundinfocus.push(data);
					this.setState({ isFundChangeDone: true });
				}
				break;
			case 'Benchmark':
				if (this.validateDuplicationBeforeFundAddition(key, symbol)) {
					newtemplateResultState.funds.benchmark.push(data);
					this.setState({ isFundChangeDone: true });
				}
				break;
			default:
				break;
		}

		this.setState({ templateResult: newtemplateResultState });
	}

	removeFund = (symbol) => {
		let screenerData = cloneDeep(this.state.screenerOutput);
		let removeIndexScreener = screenerData.findIndex(i => {
			return this.props.userType.response === 'International' ? i.MFSSymbolISIN === symbol : i.MFSSymbol === symbol;
		});
		if (removeIndexScreener > -1) {
			screenerData.splice(removeIndexScreener, 1);
		}
		this.setState({ screenerOutput: symbol !== undefined ? screenerData : [] });
		document.getElementsByClassName('rbt-input-main')[0].click();
	}

	pushFund = (data) => {
		let screenerData = cloneDeep(this.state.screenerOutput);
		screenerData.push(data);
		this.setState({ screenerOutput: screenerData });
	}

	toggleCompareButton = (e) => {
		const flag = e.currentTarget.getAttribute('data-flag');
		const symbol = e.currentTarget.getAttribute('data-symbol');		
		const wsodIssue = e.currentTarget.getAttribute('data-wsodIssue');
		const fundName = e.currentTarget.getAttribute('data-fundName');
		let screenerData = cloneDeep(this.state.screenerOutput);
		const newtemplateResultState = Object.assign({}, this.state.templateResult);

		if (flag != "Benchmark") {
			screenerData.forEach(item => {
				if (item.MSDWSODIssue && item.MSDWSODIssue.toString() === wsodIssue) {
					if (item["isActive"]) {
						if (newtemplateResultState.funds.fundinfocus[0] &&
							newtemplateResultState.funds.fundinfocus[0].wsodissue.toString() === wsodIssue.toString()) {
							const mfsFund = [];
							newtemplateResultState.funds.fundinfocus = mfsFund;
							if(this.props.userType.response === 'International') {
								this.setState({
									disableCurrency: false
								});
							}							
						} else {
							const newNonFundInFocus = this.state.templateResult.funds.nonfundinfocus;
							let removeIndex = -1;
							removeIndex = newNonFundInFocus.findIndex(i => {
								return i.wsodissue.toString() === wsodIssue.toString();
							});
							if (removeIndex > -1) {
								newNonFundInFocus.splice(removeIndex, 1);
							}
							newtemplateResultState.funds.nonfundinfocus = newNonFundInFocus;
						}
						item["isActive"] = false;
					} else {
						let isAdding = false;
						let selectedLengthFIF = newtemplateResultState.funds.fundinfocus[0] !== undefined ? 1 : 0;
						let selectedLengthNonFIF = this.state.templateResult.funds.nonfundinfocus.length;

						if (selectedLengthFIF < 1 && flag === "MFS") {
							isAdding = true;
						}
						if (selectedLengthNonFIF < 4) {
							isAdding = true;
						}

						if (isAdding) {
							if (this.validate3YrsTrackRecord(item)) {
								this.AddFundToLeftRail(flag, symbol, wsodIssue, item.MSDFundName, item.MSDFundShareClassID, item.MSDPerformanceAsOfDate);
								item["isActive"] = true;
							} else {
								if (this.state.errors.length > 0) {
									this.setState({
										validationModalIsOpen: true
									});
								}
							}
						}
					}
				}
			});

			this.setState({
				screenerOutput: screenerData
			});
		} else {
			this.AddFundToLeftRail(flag, symbol, wsodIssue, fundName)
		}
	}
	validate3YrsTrackRecord(item) {
		let isValidFundForComparison =  false;
		const currentMSDate =  utilityFunctions.convertToOADate(new Date());
		const dateFieldToCompareForValidation  = item.PerformanceStartDate;
		const  difference =  utilityFunctions.getDiffInMonths(dateFieldToCompareForValidation, currentMSDate);
		if (difference >= 36) {
			isValidFundForComparison =true;
		} else {
			this.state.errors =[ValidationMessages.threeYearsTrackRecordErrorMessage];	
			this.state.errors.push(item.MSDFundName);
		}
		return isValidFundForComparison
	}
	addFundsToFundResultsTable = (e) => {
		const flag = e.getAttribute('data-flag');
		const symbol = e.getAttribute('data-symbol');
		const wsodIssue = e.getAttribute('data-wsodIssue');
		let screenerFiltersOutput = cloneDeep(this.state.screenerFiltersOutput);
		let screenerData = cloneDeep(this.state.screenerOutput);//result in Select Fund Table.
		let selectedLength = screenerData && screenerData.length ? screenerData.length : 0;

		screenerFiltersOutput.rows.forEach(item => {
			if (item.MSDWSODIssue && item.MSDWSODIssue.toString() === wsodIssue) {
				if (item.isActive) {
					let _recordIndex = screenerData.findIndex(
						x => x.MSDWSODIssue == wsodIssue
					);
					screenerData.splice(_recordIndex, 1);
					item.isActive = false;
				} else {
					let fundResultsTableItem = cloneDeep(item);
					fundResultsTableItem.isActive = false;
					screenerData.push(fundResultsTableItem);
					item.isActive = true;
				}
			}
		});

		this.setState({
			screenerFiltersOutput: screenerFiltersOutput,
			screenerOutput: screenerData
		});
	}

	calledFromFundSearchbar = (isCalled) => {
		this.isCalledFromFundSearchbar = isCalled;
	}

	AddFundToLeftRail = (flag, symbol, wsodIssue, name, fundShareClassId, performanceAsOfDate) => {
		let key;
			switch (flag) {
				case 'MFS':
					if (
						this.state.templateResult.funds.fundinfocus.length === undefined ||
						this.state.templateResult.funds.fundinfocus.length < 1
					) {
						key = 'FIF';
						this.addFundDataToLeftRail(key, flag, symbol, wsodIssue, name, fundShareClassId, performanceAsOfDate);
					} else if (
						this.state.templateResult.funds.nonfundinfocus.length === 0 ||
						this.state.templateResult.funds.nonfundinfocus.length < 4
					) {
						//Push item
						key = 'NFIF';
						this.addFundDataToLeftRail(key, flag, symbol, wsodIssue, name, fundShareClassId, performanceAsOfDate);
					} else {
						//TODO set error
					}
					break;
				case 'OtherFund':
					if (
						this.state.templateResult.funds.nonfundinfocus.length === 0 ||
						this.state.templateResult.funds.nonfundinfocus.length < 4
					) {
						//Push item
						key = 'NFIF';
						this.addFundDataToLeftRail(key, flag, symbol, wsodIssue, name, fundShareClassId, performanceAsOfDate);
					} else {
						//TODO set error
					}
					break;
				case 'Benchmark':
					if (
						this.state.templateResult.funds.benchmark.length === undefined ||
						this.state.templateResult.funds.benchmark.length < 1
					) {
						key = 'Benchmark';
						this.addFundDataToLeftRail(key, flag, symbol, wsodIssue, name);
					}
					break;
				default:
					break;
			}	
	}

	openSaveModal = () => {
		//this.isSaveTemplate = false;
		if (!this.state.validationModal3MonthIsOpen) {
			this.setState({
				saveModalIsOpen: true,
				replaceModalIsOpen: false,
				statusMessage: '',
				isFromSaveButtonCalled: false
			});
		}
	}

	openReplaceModal = () => {
		//this.isSaveTemplate = false;
		if (!this.state.validationModal3MonthIsOpen) {
			this.setState({
				replaceModalIsOpen: true,
				isFromSaveButtonCalled: false
			});
		}
	}

	closeReplaceModal = () => {
		this.isSaveTemplate = false;
		this.setState({
			replaceModalIsOpen: false
		});
	}

	closeSaveModal = () => {
		this.isSaveTemplate = false;
		this.setState({
			saveModalIsOpen: false,
			statusMessage: '',
			replaceModalIsOpen: false
		});
	}

	closeConfirmationModal = () => {
		this.setState({
			confirmationModalIsOpen: false,
			statusMessage: '',
			replaceModalIsOpen: false,
			isFromSaveButtonCalled: false
		});
	}

	replaceTemplateSettings = () => {
		this.props.requestMfsSiteInactiveFlag();
		const newTemplateData = Object.assign({}, this.state.templateData);
		newTemplateData.funds.fundinfocus = this.state.templateResult.funds.fundinfocus[0];
		newTemplateData.funds.nonfundinfocus = this.state.templateResult.funds.nonfundinfocus;
		if(this.isBlended(this.state.templateResult.funds.benchmark[0])){
			let blendedBenchmarks = {};					
			blendedBenchmarks.symbol='';
			blendedBenchmarks.wsodissue=0;
			blendedBenchmarks.fundfamily='Benchmark';
			newTemplateData.funds.benchmark = blendedBenchmarks;								
			//For saving blended benchmark
			newTemplateData.benchmark =this.state.blendedBenchmarks[0];
		} else{
			newTemplateData.funds.benchmark = this.state.templateResult.funds.benchmark[0];
		}
		if (this.state.templateResult.funds.primarybenchmark?.length > 0) {
			newTemplateData.funds.primarybenchmark = this.state.templateResult.funds.primarybenchmark[0];
			if (newTemplateData.funds.primarybenchmark.symbol.includes('+')) {
				newTemplateData.funds.primarybenchmark.wsodissue = cloneDeep(Constant.blendedBenchmarksMilFunds.filter(x=>x.currency == this.state.templateResult.currency))[0].wsodissue;
				if(this.isBlended(this.state.templateResult.funds.primarybenchmark[0])){
					let benchmarks = this.state.blendedBenchmarks;
					newTemplateData.benchmark =benchmarks[0];
				}
			}
		} else {
			newTemplateData.funds.primarybenchmark = newTemplateData.funds.benchmark ? newTemplateData.funds.benchmark : { symbol: '', wsodissue: 0, fundfamily: 'PrimaryBenchmark' };
		}

		newTemplateData.isMarketingPage = this.state.templateResult.isMarketingPage !== undefined ?
			this.state.templateResult.isMarketingPage : true;

			if(this.state.validRollingReturnsPeriod) {
				let validCommonPeriod = this.state.validRollingReturnsPeriod;
			if(this.state.validRollingReturnsPeriod) {
				this.state.templateResult.sections.forEach(function(part, index) {
					if(this[index].sectionid === '3' || this[index].sectionid === '10') {
						let timePeriods = this[index].inputtype[0].inputvalues.replace(new RegExp("Y", "g"), '').split(";");
						let validTimePeriods = [];
						if(validCommonPeriod !== timePeriods && validCommonPeriod.length >0) {
							validTimePeriods = timePeriods.filter(p => validCommonPeriod.includes(parseInt(p)));
							if(validTimePeriods.length > 0) {							
								this[index].inputtype[0].inputvalues = validTimePeriods.join("Y;") + "Y";
							} else if(validCommonPeriod.length > 0){
								this[index].inputtype[0].inputvalues = validCommonPeriod[validCommonPeriod.length - 1] + "Y";
							} else{
								this[index].isActive = false;
							}
						}
					}
				}, this.state.templateResult.sections);
			}
		}				
		//initializing sections for old templates
		newTemplateData.sections = this.state.templateResult.sections !== undefined ? this.state.templateResult.sections :
			(this.props.userType.response === 'International' ? Constant.sectionMasterDataIntl.sections : Constant.sectionMasterData.sections);
		if (this.props.userType.response === 'International') {
			newTemplateData.currency = this.state.templateResult.currency;
			newTemplateData.language = this.state.templateResult.language;
			// setting the correlation matrix to return correlation matrix for international
            let updateSectionIndex = newTemplateData.sections.findIndex((obj => obj.sectionname === 'Correlation Matrix'));
            if(updateSectionIndex > -1) {
			  newTemplateData.sections[updateSectionIndex].subsections[0].subsectiondisplayname = "Return Correlation Matrix";
            }
			let funds = []
			funds.push(this.state.templateResult.funds.fundinfocus[0].fundShareClassId);
			this.state.templateResult.funds.nonfundinfocus.forEach(item => {
				funds.push(item.fundShareClassId)
			});
			let currency = this.state.templateResult.currency ? this.state.templateResult.currency : "USD";
			const screenerInput = Constant.screenerInternationalInputs;
			screenerInput.arguments[0].conditions[0].values = funds;
			screenerInput.arguments[1].conditions[0].values = utilityFunctions.getPerformanceEndDateValidation().toString().split(",");
			this.props.requestScreenerDataFundNameApi(SCREENER_TYPES.MutualFund, screenerInput, 'yes', currency);

			//Validate funds as per selected currency
			let fundshareids = funds.join();
			this.setState({ isFromSelectModules: false });
			this.props.requestInternationalXidByFundShareClassId(fundshareids, currency);
			this.CounterLoad = 0;
			this.saveTemplate = true;

			newTemplateData.fifFundName = this.fifFundName;
			newTemplateData.nonFifFundNames = this.nonFifFundName.join(",");
		}

		const templateSettingsData = {
			templateId: this.props.TemplateId,
			templateName: this.props.TemplateName.trim(),
			templateData: JSON.stringify(newTemplateData)
		};

		if (this.props.userType.response === 'International') {
			templateSettingsData.reportType = 'mil_fundcomparison';
		}

		this.setState({
			statusMessage: ''
		});
		this.props.requestSaveTemplateDetailsApi(templateSettingsData);
	}

	saveTemplateSettings = () => {
		this.props.requestMfsSiteInactiveFlag();
		if (this.props.TemplateName.trim() !== '' || this.state.templateName.trim() !== '') {
			if (this.props.TemplateName.trim().length > 50 || this.state.templateName.length > 50) {
				this.setState({
					isSaveDisabled: true,
					statusMessage: ValidationMessages.TemplateLengthCheck
				});
			} else {
				const newTemplateData = Object.assign({}, this.state.templateData);
				newTemplateData.funds.fundinfocus = this.state.templateResult.funds.fundinfocus[0];
				newTemplateData.funds.nonfundinfocus = this.state.templateResult.funds.nonfundinfocus;
				if(this.isBlended(this.state.templateResult.funds.benchmark[0])){
					let blendedBenchmarks = {};					
					blendedBenchmarks.symbol='';
					blendedBenchmarks.wsodissue=0;
					blendedBenchmarks.fundfamily='Benchmark';
					newTemplateData.funds.benchmark = blendedBenchmarks;									
					//For saving blended benchmark
					newTemplateData.benchmark =this.state.blendedBenchmarks[0];
				} else {
					newTemplateData.funds.benchmark = this.state.templateResult.funds.benchmark[0];
				}
				if (this.state.templateResult.funds.primarybenchmark && this.state.templateResult.funds.primarybenchmark?.length > 0) {
					newTemplateData.funds.primarybenchmark = this.state.templateResult.funds.primarybenchmark[0];
					if (this.state.templateResult.funds.primarybenchmark[0].symbol.includes('+')) {
						newTemplateData.funds.primarybenchmark.wsodissue = cloneDeep(Constant.blendedBenchmarksMilFunds.filter(x=>x.currency == this.state.templateResult.currency))[0].wsodissue;
						if(this.isBlended(this.state.templateResult.funds.primarybenchmark[0])){
							let benchmarks = this.state.blendedBenchmarks;
							newTemplateData.benchmark =benchmarks[0];
						}
					}
				} else {
					newTemplateData.funds.primarybenchmark = newTemplateData?.funds?.benchmark ? newTemplateData.funds.benchmark : { symbol: '', wsodissue: 0, fundfamily: 'PrimaryBenchmark' };
				}
				newTemplateData.isMarketingPage = this.state.templateResult.isMarketingPage !== undefined ?
					this.state.templateResult.isMarketingPage : true;

					if(this.state.validRollingReturnsPeriod) {
						let validCommonPeriod = this.state.validRollingReturnsPeriod;			
						if (this.state.templateResult.sections) {
							this.state.templateResult.sections.forEach(function(part, index) {
								if(this[index].sectionid === '3' || this[index].sectionid === '10') {
									let timePeriods = this[index].inputtype[0].inputvalues.replace(new RegExp("Y", "g"), '').split(";");
									let validTimePeriods = [];
									if(validCommonPeriod !== timePeriods && validCommonPeriod.length >0) {
										validTimePeriods = timePeriods.filter(p => validCommonPeriod.includes(parseInt(p)));
										if(validTimePeriods.length > 0) {							
											this[index].inputtype[0].inputvalues = validTimePeriods.join("Y;") + "Y";
										} else if(validCommonPeriod.length > 0){
											this[index].inputtype[0].inputvalues = validCommonPeriod[validCommonPeriod.length - 1] + "Y";
										} else{
											this[index].isActive = false;
										}
									}
								}
							}, this.state.templateResult.sections);
						}
					}

				newTemplateData.sections = this.state.templateResult.sections !== undefined ? this.state.templateResult.sections :
					(this.props.userType.response === 'International' ? Constant.sectionMasterDataIntl.sections : Constant.sectionMasterData.sections);

				if (this.props.userType.response === 'International') {

					newTemplateData.currency = this.state.templateResult.currency;
					newTemplateData.language = this.state.templateResult.language;

					newTemplateData.fifFundName = this.fifFundName;
					newTemplateData.nonFifFundNames = this.nonFifFundName.join(",");
				}

				const templateSettingsData = {
					templateId: 0,
					templateName: this.state.templateName.length > 0 ? this.state.templateName.trim() : this.props.TemplateName.trim(),
					templateData: JSON.stringify(newTemplateData)
				};
				if (this.props.userType.response === 'International') {
					templateSettingsData.reportType = 'mil_fundcomparison';
				}
				this.setState({
					isSaveDisabled: true,
					statusMessage: ''
				});
				this.props.requestSaveTemplateDetailsApi(templateSettingsData);				
			}
		}
	}

	goToHomePage = () => {
		window.location.reload();
	}
	loadTimePeriod = () => {
		this.setState({
			shouldLoadDefaultTimePeriods: true,
			shouldLoadDefaultCMTimePeriods: true
		}, () => {
			document.getElementById('btnSelectModules').click();
		});
	}
	//MFS-4273
	SetTimePeriod = () => {
		this.setState({
			shouldLoadDefaultTimePeriods: false,
			shouldLoadDefaultCMTimePeriods: false
		}, () => {
			if(document.getElementById('btnSelectModules')) {
				document.getElementById('btnSelectModules').click();
			}
		});
	}
	clickModuleSelection = () => {
		this.isSaveTemplate = false;
		if (this.props.userType.response === 'International') {
			this.setState({ 
				isFromSelectModules: true,
				isFromSaveButtonCalled: false
			});
			this.CounterLoad = 0;
			this.validationModal3MonthCounter = 0;
			let funds = []
			funds.push(this.state.templateResult.funds.fundinfocus[0].fundShareClassId);
			this.state.templateResult.funds.nonfundinfocus.forEach(item => {
				funds.push(item.fundShareClassId)
			});

			let currency = this.state.templateResult.currency ? this.state.templateResult.currency : "USD";
			const screenerInput = Constant.screenerInternationalInputs;
			screenerInput.arguments[0].conditions[0].values = funds;
			screenerInput.arguments[1].conditions[0].values = utilityFunctions.getPerformanceEndDateValidation().toString().split(",");
			this.props.requestScreenerDataFundNameApi(SCREENER_TYPES.MutualFund, screenerInput, 'yes', currency);
		
		} else {
			let funds = []
			funds.push(this.state.templateResult.funds.fundinfocus[0].wsodissue);
			this.state.templateResult.funds.nonfundinfocus.forEach(item => {
				funds.push(item.wsodissue)
			});
			const screenerInput = Constant.screenerInputs;
			screenerInput.arguments[0].conditions[0].values = funds;
			this.props.requestPerformanceAsOfScreenerDataApi(SCREENER_TYPES.MutualFund, screenerInput, 'no', (this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD');	
		}
	}

	loadBlendedBenchmark=()=>{
		let blendedBenchmarks = [];
		let tempBlendedBenchmark = cloneDeep(Constant.blendedBenchmarksMilFunds.filter(x=>x.currency == this.state.templateResult.currency));
		tempBlendedBenchmark.forEach(function (elem) {
			elem.blendedBenchmarkNames=elem.name;
			blendedBenchmarks.push(elem);
		});
		this.setState({blendedBenchmarks: blendedBenchmarks});
	}

	isBlended = (selectedBenchmark) => {
		let isBlendedSelected=false;
		let benchmarks = (this.state.blendedBenchmarks);
		if(selectedBenchmark){
		benchmarks.forEach(blended=>{
			if(blended.blendedBenchmarkNames==selectedBenchmark.symbol){
				isBlendedSelected= true;
			}
		});
		}
		return isBlendedSelected;
	}

	validateFunds(oldestInceptionDates) {
		//this.isSaveTemplate = false;
		let result = [];
		this.state.templateResult.funds.fundinfocus.forEach(item => {
			oldestInceptionDates.map(inceptionDates => {
				if (inceptionDates.wsodIssue === item.wsodissue) {
					result.push({
						symbol: item.name,
						wsodissue: item.wsodissue,
						inceptionDate: inceptionDates.inceptionDate,
						oldestShareClassInceptionDate: inceptionDates.oldestShareClassInceptionDate,
						diffInMonths: utilityFunctions.getDiffInMonths(utilityFunctions.getStartDateOfNextFullMonth(inceptionDates.oldestShareClassInceptionDate) -1, item.performanceAsOfDate)
					});
				}
			});
		});
		this.state.templateResult.funds.nonfundinfocus.forEach(item => {
			oldestInceptionDates.map(inceptionDates => {
				if (inceptionDates.wsodIssue === item.wsodissue) {
					result.push({
						symbol: item.name,
						wsodissue: item.wsodissue,
						inceptionDate: inceptionDates.inceptionDate,
						oldestShareClassInceptionDate: inceptionDates.oldestShareClassInceptionDate,
						diffInMonths: utilityFunctions.getDiffInMonths(utilityFunctions.getStartDateOfNextFullMonth(inceptionDates.oldestShareClassInceptionDate) -1, item.performanceAsOfDate)
					});
				}
			});
		});
		let _p1LessThan3MFund = result.filter(x=>x.diffInMonths < 3);
		
		if (_p1LessThan3MFund && _p1LessThan3MFund.length > 0) {
			this.state.errors = ["Funds selected do not have sufficient data to allow for a comparison. Funds must have a minimum of 3 months of history. Funds with less than 3 months of history :"];	
			_p1LessThan3MFund.forEach(fund => {
				this.state.errors.push(fund.symbol);
			});
			this.setState({ 
				validationModal3MonthIsOpen: true,
				validationModal3MonthOpen: true,
				isvalidationModal3MonthOpen : true
			});
			this.validationModal3MonthCounter = 1;
		} else {
			this.validationModal3MonthCounter = 2;
			this.setState({
				validationModal3MonthIsOpen: false,
				isvalidationModal3MonthOpen : true
			});
			if (this.state.isFromSaveButtonCalled) {
				let funds = []
				funds.push(this.state.templateResult.funds.fundinfocus[0].fundShareClassId);
				this.state.templateResult.funds.nonfundinfocus.forEach(item => {
					funds.push(item.fundShareClassId)
				});
				let currency = this.state.templateResult.currency ? this.state.templateResult.currency : "USD";
		
				const screenerInput = Constant.screenerInternationalInputs;
				screenerInput.arguments[0].conditions[0].values = funds;
				this.props.requestScreenerDataFundNameApi(SCREENER_TYPES.MutualFund, screenerInput, 'yes', currency);
				this.saveTemplate = true;
				this.CounterLoad = 0;
			}
		}
	}

	navigateToModuleSelection = () => {
		this.props.requestMfsSiteInactiveFlag();
		
		const newTemplateData = Object.assign({}, this.state.templateData);
		newTemplateData.funds.fundinfocus = this.state.templateResult.funds.fundinfocus[0];
		newTemplateData.funds.nonfundinfocus = this.state.templateResult.funds.nonfundinfocus;
		newTemplateData.funds.benchmark = this.state.templateResult.funds.benchmark[0];
		newTemplateData.fifFundName = this.fifFundName;
		newTemplateData.nonFifFundNames = this.nonFifFundName.join(",");
		if (this.isBlended(this.state.templateResult.funds.benchmark[0])) {
			let benchmarks = this.state.blendedBenchmarks;
			newTemplateData.benchmark = benchmarks[0];
		}
		if (this.state.templateResult.funds.primarybenchmark?.length > 0) {
			newTemplateData.funds.primarybenchmark = this.state.templateResult.funds.primarybenchmark[0];
			if (this.state.templateResult.funds.primarybenchmark[0].symbol.includes('+')) {
				newTemplateData.funds.primarybenchmark.wsodissue = cloneDeep(Constant.blendedBenchmarksMilFunds.filter(x=>x.currency == this.state.templateResult.currency))[0].wsodissue;
				if(this.isBlended(this.state.templateResult.funds.primarybenchmark[0])){
					let benchmarks = this.state.blendedBenchmarks;
					newTemplateData.benchmark =benchmarks[0];
				}
			}
		} else {
			newTemplateData.funds.primarybenchmark = newTemplateData.funds.benchmark ? newTemplateData.funds.benchmark : { symbol: '', wsodissue: 0, fundfamily: 'PrimaryBenchmark' };
		}

		newTemplateData.sections = this.state.templateResult.sections !== undefined ? this.state.templateResult.sections : newTemplateData.sections;


		if (newTemplateData.sections && newTemplateData.sections.length === 0) {  // new template creation.			
			let sectionMasterData = cloneDeep(this.props.userType.response === 'International' ? Constant.sectionMasterDataIntl.sections : Constant.sectionMasterData.sections);
			newTemplateData.sections = sectionMasterData
		}
		// Remove "Contribution to Risk" group for Funds
		newTemplateData.sections = newTemplateData.sections.filter(section => {
			return (section.sectionname !== 'Contribution to Risk')
		})

		// change the section order
		newTemplateData.sections.forEach(section => {
			if (section.tabName == 'Fund Details, Statistics & Performance') {
				if (section.sectionid == '5') {
					section.sectionid = '12';
				}
			
			}
            if(this.props.userType.response === 'International' && section.sectionname === 'Correlation Matrix') {
			  section.subsections[0].subsectiondisplayname = "Return Correlation Matrix";
            }
		})
		
		newTemplateData.sections.forEach(section => {
			if (this.sectionNames.includes(section.sectionname)) {
					section.subsections = section.subsections.filter(subsection => {
						return (this.props.userType.response === 'International' ?  subsection.name !== 'Sustainability Rating Intl' : subsection.name !== 'Sustainability Rating');
					})
			}
		})

		newTemplateData.isMarketingPage = this.state.templateResult.isMarketingPage !== undefined ? this.state.templateResult.isMarketingPage : newTemplateData.isMarketingPage;
		if (this.props.userType.response === 'International') {
			newTemplateData.language = this.state.templateResult.language !== undefined ? this.state.templateResult.language : newTemplateData.language;
			newTemplateData.currency = this.state.templateResult.currency !== undefined ? this.state.templateResult.currency : newTemplateData.currency;
		}


		if (this.props.closeModal) {
			this.props.closeModal();
		}

		//pull out all Wsod Issues....
		let _fundsArray = [];
		let _fundshareclassId = [];
		let _funds = newTemplateData.funds.nonfundinfocus;
		let milBenchmarkWsodList = '';
		if (newTemplateData.benchmark) {
			newTemplateData.benchmark.benchmarks.forEach(item => {
				milBenchmarkWsodList += item.wsodissue + "," ;
			})
		}
		let benchmarkWsodissue = newTemplateData.funds.benchmark.wsodissue
			? newTemplateData.funds.benchmark.wsodissue
			: milBenchmarkWsodList;
		_fundsArray.push(newTemplateData.funds.fundinfocus.wsodissue);
		_fundshareclassId.push(newTemplateData.funds.fundinfocus.fundShareClassId);
		_funds.forEach(function (item) {
			_fundsArray.push(item.wsodissue)			
		});
		_fundsArray = _fundsArray.toString();
		_fundshareclassId = _fundshareclassId.toString();
		this.props.requestValidRollingReturnPeriodDataApi(_fundsArray,this.props.userType.response === 'International' ? 'yes' : 'no', _fundshareclassId, benchmarkWsodissue);
		this.props.requestValidCMPeriodDataApi(_fundsArray,this.props.userType.response === 'International' ? 'yes' : 'no', _fundshareclassId, benchmarkWsodissue);
		let savedTemplateData = null;
		let timeperiod;
		let timePeriodCM;
		//check whether template has been changed or not....
		if (this.props.template) {
			xmlToJSON.to_json(
				`<data>${this.props.template[0].xmlData}</data>`,
				(error, data) => {
					let isInternationalUser = data.funds.fundinfocus.isInternationalUser;
					data.funds.fundinfocus.isInternationalUser = isInternationalUser && isInternationalUser.toLowerCase() === "true" ? true : false;
					savedTemplateData = data;
					const nonfundinfocus = [];

					if (data.funds.nonfundinfocus.symbol === undefined) {
						for (const index in data.funds.nonfundinfocus) {
							const nonFIF = data.funds.nonfundinfocus[index];
							nonfundinfocus.push(nonFIF);
						}
					} else {
						nonfundinfocus.push({
							fundfamily: data.funds.nonfundinfocus.fundfamily,
							symbol: data.funds.nonfundinfocus.symbol,
							wsodissue: data.funds.nonfundinfocus.wsodissue
						});
					}
					savedTemplateData.funds.nonfundinfocus = nonfundinfocus;
				});
			let _savedSections = newTemplateData.sections;
			let _fundReturnSection = _savedSections.find(x =>
				x.sectionname === "Rolling Time Frames"
			);
			let _fundReturnCMSection = _savedSections.find(x =>
				x.sectionname === "Correlation Matrix"
			);

			timeperiod = _fundReturnSection.inputtype[0].inputvalues.includes(",") || _fundReturnSection.inputtype[0].inputvalues == '' ? true : false;
			timePeriodCM = _fundReturnCMSection.inputtype[0].inputvalues.includes(",") || _fundReturnCMSection.inputtype[0].inputvalues == '' ? true : false;
		} else {
			//check whether that template has timePeriod set or not....			
			let _savedSections = newTemplateData.sections;
			let _fundReturnSection = _savedSections.find(x =>
				x.sectionname === "Rolling Time Frames"
			);
			timeperiod = _fundReturnSection.inputtype[0].inputvalues.includes(",") ? true : false;
			let _fundReturnCMSection = _savedSections.find(x =>
				x.sectionname === "Correlation Matrix"
			);
			timePeriodCM = _fundReturnCMSection.inputtype[0].inputvalues.includes(",") ? true : false;
			if (this.props.userType.response !== 'International') {
				this.setState({ isFundChangeDone: false })
			}
		}

		const inputParams = {
			templateData: JSON.stringify(newTemplateData),
			containerId: this.props.containerId,
			reportId: this.props.reportId,
			templateId: this.props.TemplateId,
			templateName: this.props.TemplateName,
			shouldLoadDefaultTimePeriods: (this.state.shouldLoadDefaultTimePeriods || timeperiod),
			shouldLoadDefaultCMTimePeriods: (this.state.shouldLoadDefaultCMTimePeriods || timePeriodCM),
			isPortfolio: false
		};

		this.props.setTemplateData(inputParams);


	}
	
	UNSAFE_componentWillReceiveProps(nextProps) {
		//this.setState({ downloadSpinnerModalIsOpen: false 
				
		if (nextProps.modalIsOpen !== this.props.modalIsOpen && nextProps.modalIsOpen &&  nextProps.selectedRowCurrency && nextProps.selectedRowCurrency !== this.state.templateResult.currency ) {
			this.setState({ templateResult: { ...this.state.templateResult, currency: nextProps.selectedRowCurrency } })
		}
		
		if(this.state.validRollingReturnsPeriod !== nextProps.validRollingReturnsPeriod){
			this.setState({...this.state, validRollingReturnsPeriod : nextProps.validRollingReturnsPeriod });
		}

		if (this.props.xidFundShareClassMappings != nextProps.xidFundShareClassMappings) {
			let dictFundshareWsod = {};
			nextProps.xidFundShareClassMappings.forEach(item => {
				dictFundshareWsod[item.FundShareclassID] = item.XID;
			});
			this.setState({ shouldLoadDefaultTimePeriods: false });
			this.setState({ shouldLoadDefaultCMTimePeriods: false });
			let tempResult = cloneDeep(this.state.templateResult);
			if (this.props.isComingFromModuleSelection) {
				this.setState({ isFundChangeDone: false });
			}
			if (!lodash.isEqual(tempResult.funds.nonfundinfocus, nextProps.templateData.funds.nonfundinfocus) || !lodash.isEqual(tempResult.funds.fundinfocus, nextProps.templateData.funds.fundinfocus) || this.state.isFundChangeDone) {
				this.openModuleSelection = false;
				this.setState({ shouldLoadDefaultTimePeriods: true });
				this.setState({ shouldLoadDefaultCMTimePeriods: true });
			}
			tempResult.funds.fundinfocus[0].wsodissue = dictFundshareWsod[tempResult.funds.fundinfocus[0].fundShareClassId];
			this.state.templateResult.funds.fundinfocus[0].wsodissue = tempResult.funds.fundinfocus[0].wsodissue

			let wsodIssueList = this.state.templateResult.funds.fundinfocus[0].wsodissue.toString();

			this.state.templateResult.funds.nonfundinfocus.forEach(item => {
				item.wsodissue = dictFundshareWsod[item.fundShareClassId];
				wsodIssueList = wsodIssueList + "," + item.wsodissue;
			});			

			if (this.props.userType.response === 'International') {
				if(this.state.isFromSelectModules) {
					 this.CounterLoad++;
				}
				this.props.requestOldestInceptionDatesApi(wsodIssueList, 'yes');
			}
		}
		if(this.state.templateResult.funds && this.state.templateResult.funds.fundinfocus[0] && (Constant.milCurrencySpecificFunds.find(x=>x.fund === this.state.templateResult.funds.fundinfocus[0].name) !== undefined) ){
			this.state.templateResult.currency = Constant.milCurrencySpecificFunds.find(x=>x.fund === this.state.templateResult.funds.fundinfocus[0].name).baseCurrency;
			this.setState({ disableCurrency: true				
			})	
		} else{
			this.setState({ disableCurrency: false });
		}
		if(this.state.templateResult.funds.fundinfocus.length>0 && this.props.xidFundShareClassMappings != nextProps.xidFundShareClassMappings ){
			let dictFundshareWsod = {};
			let wsodIssueFundName = {};
			nextProps.xidFundShareClassMappings.forEach(item => {
				dictFundshareWsod[item.FundShareclassID] = item.XID;
			});
			let fifWsodIssue = this.state.templateResult.funds.fundinfocus[0].wsodissue.toString();
			let wsodIssueList = fifWsodIssue;
			wsodIssueFundName[fifWsodIssue] = this.state.templateResult.funds.fundinfocus[0].name;
			this.state.templateResult.funds.nonfundinfocus.forEach(item => {
				item.wsodissue = dictFundshareWsod[item.fundShareClassId];
				wsodIssueFundName[item.wsodissue.toString()] = item.name;
				if (item.fundfamily === "MFS") {
					wsodIssueList = wsodIssueList + "," + item.wsodissue;
				}
		 	});
			let wsodMfslength = wsodIssueList.split(',').length;
			if (wsodIssueList && wsodMfslength > 1) {
				this.props.requestGetMfsPrimaryBenchmarkApi(wsodIssueList,
					(this.props.userType.response === 'International') ? 'yes' : 'no',
					(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'
				);
				this.isDiffPrimarybenchmark = true;
			}
		}
		if (this.props.oldestInceptionDates !== nextProps.oldestInceptionDates) {
			let dictFundshareWsod = {};
			nextProps.xidFundShareClassMappings.forEach(item => {
				dictFundshareWsod[item.FundShareclassID] = item.XID;
			});
			this.state.errors = ["Performance data is not available to the inception of all funds in your report. Funds will be included based on performance availability"];
			let wsodIssueFundName = {};

			//To check if we have more than one MFS fund with differing benchmarks
			if(this.state.templateResult.funds.fundinfocus.length>0){
			let fifWsodIssue = this.state.templateResult.funds.fundinfocus[0].wsodissue.toString();
			let wsodIssueList = fifWsodIssue;
			wsodIssueFundName[fifWsodIssue] = this.state.templateResult.funds.fundinfocus[0].name;
			this.state.templateResult.funds.nonfundinfocus.forEach(item => {
				item.wsodissue = dictFundshareWsod[item.fundShareClassId];
				wsodIssueFundName[item.wsodissue.toString()] = item.name;
				if (item.fundfamily === "MFS") {
					wsodIssueList = wsodIssueList + "," + item.wsodissue;
				}
			});

			// MFS-3283
			// nextProps.oldestInceptionDates.forEach(item => {
			// 	if (item.oldestShareClassInceptionDate > item.inceptionDate) {
			// 		let errMsg = wsodIssueFundName[item.wsodIssue.toString()] + ", Inception " + utilityFunctions.formatOADate(item.inceptionDate, "DD/MM/YYYY") +
			// 			", Performance " + utilityFunctions.formatOADate(item.oldestShareClassInceptionDate, "DD/MM/YYYY");
			// 		this.state.errors.push(errMsg);
			// 	}
			// });

			if (this.state.isFromSelectModules) {
				if (this.state.errors.length > 1) {
					this.setState({
						validationModalIsOpen: true
					});
				} else {
					let wsodMfslength = wsodIssueList.split(',').length;
					if (wsodIssueList && wsodMfslength > 1 && !this.isDiffPrimarybenchmark) {
						this.props.requestGetMfsPrimaryBenchmarkApi(wsodIssueList,
							(this.props.userType.response === 'International') ? 'yes' : 'no',
							(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'
						);
						this.isDiffPrimarybenchmark = true;
					} else if (this.CounterLoad === 2 && this.props.userType.response !== 'International') {
						document.getElementById('btnSelectModules').click();
					}
				}
			}
		}

			if (this.props.userType.response === 'International') {
				this.validateFunds(nextProps.oldestInceptionDates);
			}
		}

		if (
			this.props.fundNamesFromScreenerData != nextProps.fundNamesFromScreenerData &&
			nextProps.fundNamesFromScreenerData.rows
		) {
			let fundShareClassId = 0;
			let fundName = '';
			let fif = '';
			let nonFif = [];
			let fiFundShareClassId = this.state.templateResult.funds.fundinfocus[0].fundShareClassId;
			// const dictWsodName = {};
			// dictWsodName[this.props.templateData.funds.fundinfocus.wsodissue] = '';
			// this.props.templateData.funds.nonfundinfocus.forEach(item=>{
			// 	dictWsodName[item.wsodissue] = '';
			// });
			let performanceDateValidation = this.validateFundsPerformanceEndDate(nextProps.fundNamesFromScreenerData);
  		
			if (performanceDateValidation.length > 0) {
				this.saveTemplate = false;
				this.setState({ endDateValidationMessage: true});
				performanceDateValidation.forEach(item => {
					this.state.endDateValidationerrors.push(item);
				});
				return false;
			} else{
					//Validate funds as per selected currency
				let funds = []
				funds.push(this.state.templateResult.funds.fundinfocus[0].fundShareClassId);
				this.state.templateResult.funds.nonfundinfocus.forEach(item => {
					funds.push(item.fundShareClassId)
				});
				let currency = this.state.templateResult.currency ? this.state.templateResult.currency : "USD";
				let fundshareids = funds.join();
				this.props.requestInternationalXidByFundShareClassId(fundshareids, currency);
			}

			nextProps.fundNamesFromScreenerData.rows.map(screenerItem => {
				screenerItem.results.map(item => {
					if (item.field == "MSDFundShareClassID") {
						fundShareClassId = item.value;
					} else if (item.field == "MSDFundName") {
						fundName = item.value;
					}
				});

				if (fiFundShareClassId == fundShareClassId) {
					fif = fundName;
				} else {
					nonFif.push(fundName);
				}
			});


			this.fifFundName = fif;
			this.nonFifFundName = nonFif;
			if (this.saveTemplate) {
				if (this.props.TemplateId > 0) {
					this.openReplaceModal();
				} else {
					this.openSaveModal();
				}
				this.saveTemplate = false;
			} else {
				this.CounterLoad++;

				if (this.CounterLoad === 2 && this.props.userType.response !== 'International') {
					document.getElementById('btnSelectModules').click();
				}
			}
			// this.setState({
			// 	fifFundName: fif,
			//   	nonFifFundName: nonFif
			//   }, () => {
			// 	(() => document.getElementById('btnSelectModules').click())
			//   });							
		}

		if (this.props.siteInactiveId != nextProps.siteInactiveId && nextProps.siteInactiveId > 0) {
			if (nextProps.mfsSiteInactive === true) {
				this.props.callMaintenace();
			}
		}
		if (
			nextProps.saveTemplateDetails !== this.props.saveTemplateDetails &&
			nextProps.saveTemplateDetails.success === 1
		) {
			this.setState({
				confirmationModalIsOpen: true, saveModalIsOpen: false,
				replaceModalIsOpen: false
			});
		} else if (nextProps.saveTemplateDetails.success === 0 && nextProps.saveTemplateDetails !== this.props.saveTemplateDetails) {
			this.setState({
				confirmationModalIsOpen: false,
				statusMessage: nextProps.saveTemplateDetails.returnMessage
			});
		}


		// to receive predictive search results.
		if (this.props.userType.response === 'International') {
			if ((nextProps.xref !== this.props.xref || nextProps.xref !== undefined) && nextProps.xrefETF !== undefined) {
				let item = nextProps.xref;
				if ((nextProps.xref && nextProps.xref.items && nextProps.xref.items.length > 0) || (nextProps.xrefETF && nextProps.xrefETF.items && nextProps.xrefETF.items.length > 0))
					item = (nextProps.xref.items).concat(nextProps.xrefETF.items);
				this.setState({
					searchResults: getFormattedEtfFunds(item)
				});
			}
		} else {
			if (nextProps.symbolSearchResults !== this.props.symbolSearchResults) {
				this.setState({
					searchResults: nextProps.symbolSearchResults
				});
			}
		}

		//To get benchmarks results
		if ((nextProps.benchmarks !== this.props.benchmarks)) {
			if (this.state.templateResult.funds.benchmark[0] !== undefined && this.props.userType.response === 'International') {
				let currentBenchmark = cloneDeep(nextProps.benchmarks);
				currentBenchmark.benchmarks.forEach(function (x) { x.name = x.name.slice(0, -3) });
				let previousBenchmark = currentBenchmark.benchmarks.find(x => x.name == this.state.templateResult.funds.benchmark[0].symbol.slice(0, -3));
				if (previousBenchmark !== "" && previousBenchmark !== undefined) {
					this.state.templateResult.funds.benchmark[0].symbol = previousBenchmark.name + this.state.templateResult.currency;
					this.state.templateResult.funds.benchmark[0].wsodissue = previousBenchmark.xId;
					if (this.state.templateResult.funds.fundinfocus.length > 0 && this.state.templateResult.funds.fundinfocus[0].name !== undefined)
						this.checkDefaultedBenchmarks(this.state.templateResult.funds.benchmark[0].wsodissue, this.state.templateResult.funds.benchmark[0].symbol, this.state.templateResult.funds.fundinfocus[0].name);
				} else{
				let currentBlendedBenchmark = cloneDeep(Constant.blendedBenchmarksMilFunds.filter(x=>x.currency == this.state.templateResult.currency));
				let previousBlendedBenchmark = cloneDeep(Constant.blendedBenchmarksMilFunds.filter(x=>x.name ==  this.state.templateResult.funds.benchmark[0].symbol));
				if (previousBlendedBenchmark !== undefined && previousBlendedBenchmark.length>0) {
					this.state.templateResult.funds.benchmark[0].symbol = currentBlendedBenchmark[0].name;
					this.state.templateResult.funds.benchmark[0].wsodissue = currentBlendedBenchmark[0].xId;
					} else{
						//when no benchmark exist at particular currency then bind the Primary benchmark
						if(currentBenchmark.benchmarks.length > 0 && this.state.templateResult.funds.primarybenchmark?.length > 0 
							&& this.state.templateResult.funds.primarybenchmark[0]?.symbol){
						this.state.templateResult.funds.benchmark[0]=this.state.templateResult.funds.primarybenchmark[0];
						} else {
							//when the Primary benchmark doesn't exist 
							 this.state.templateResult.funds.benchmark = [];
						}
					}
				}		
			} else if(this.props.userType.response === 'International' && this.state.templateResult.funds.fundinfocus 
			&& this.state.templateResult.funds.fundinfocus.length > 0 && this.state.templateResult.funds.primarybenchmark){
				//this block will execute when user has removed the benchmark and then change the currency, so we will add Primary benchmark
				if(nextProps.benchmarks.benchmarks.length > 0 && this.state.templateResult.funds.primarybenchmark?.length > 0 && this.state.templateResult.funds?.primarybenchmark[0]?.symbol 
					&& (nextProps.benchmarks.benchmarks.filter(x => x.name == this.state.templateResult.funds.primarybenchmark[0].symbol).length > 0
					 || Constant.blendedBenchmarksMilFunds.filter(x=>x.currency == this.state.templateResult.currency && x.name == this.state.templateResult.funds.primarybenchmark[0].symbol).length > 0)){
				this.state.templateResult.funds.benchmark[0]=this.state.templateResult.funds.primarybenchmark[0];
				}
			}
			this.loadBlendedBenchmark();
			let combinedBenchmarks =[];
			let tempBlendedBenchmark = cloneDeep(Constant.blendedBenchmarksMilFunds.filter(x=>x.currency == this.state.templateResult.currency));
			tempBlendedBenchmark.forEach(function (elem) {
				combinedBenchmarks.push(elem);
			});			
			nextProps.benchmarks.benchmarks = (combinedBenchmarks).concat(nextProps.benchmarks.benchmarks); 
			this.setState({ benchmarks: nextProps.benchmarks});
		}

		//process wsodIsues:
		if (
			nextProps.wsodIssue !== undefined &&
			nextProps.wsodIssue !== this.props.wsodIssue
		) {
			const screenerInput = Constant.screenerInputs;

			const _wsodArray = [];
			const _decodeResponse = nextProps.wsodIssue;
			_decodeResponse.forEach(element => {
				_wsodArray.push(element.data.data.xid);
			});

			this.setState({
				wsodIssue: _wsodArray
			});

			const _wsodStringArray = _wsodArray.map(e => {
				return e.toString();
			});

			// screener call
			screenerInput.arguments[0].conditions[0].values = _wsodStringArray;
			this.props.requestScreenerDataApi(
				SCREENER_TYPES.MutualFund,
				screenerInput,
				(this.props.userType.response === 'International') ? 'yes' : 'no',
				(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'
			);
		}

		if (nextProps.fundShareclassId && nextProps.fundShareclassId !== "" &&
			nextProps.fundShareclassId !== this.props.fundShareclassId &&
			this.props.userType.response === 'International') {
			this.cwrpTemplateFundsEtfDataBind(nextProps.fundShareclassId);
			this.props.setFundShareclassId("");
		}

		if (!nextProps.modalIsOpen) {
			if (nextProps.userType.response === 'International') {
				this.setState({
					templateResult: {
						funds: { fundinfocus: [], nonfundinfocus: [], benchmark: [] },
						currency: 'USD',
						language: 'en-GB',
						reportType: 'mil_fundcomparison'
					},
					screenerOutput: []
				});
			} else {
				this.setState({
					templateResult: {
						funds: { fundinfocus: [], nonfundinfocus: [], benchmark: [] },
					},
					screenerOutput: []
				});
			}
		}

		if (
			nextProps.screenerOutput !== this.props.screenerOutput &&
			nextProps.screenerOutput && nextProps.screenerOutput.rows
		) {
			let screenerData = cloneDeep(this.state.screenerOutput);
			let categoryName = ''
			nextProps.screenerOutput.rows.map(item => {
				let row = {};
				
				if (!item) {
					row = null;
				} else {
					item.results.map(x => {
						row[x.field] = x.value;
						if(x.field == 'MSDMorningstarCategoryName' && categoryName == ''){
							categoryName = x.value;
						}
						return row;
					});
					row["isActive"] = false;

					screenerData.push(row);
				}
				return row;
			});
			if (this.props.userType.response === 'International'){
				this.requestGetScreenerCategoryDataApi(categoryName)
			}	
			this.setState({
				screenerOutput: screenerData
			});
		}

		if (
			nextProps.screenerFiltersOutput !== this.props.screenerFiltersOutput
			&& nextProps.screenerFiltersOutput && nextProps.screenerFiltersOutput.rows
		) {
			let screenerFiltersOutput = {
				rows: [],
				matches: 0

			}
			nextProps.screenerFiltersOutput.rows.map(item => {
				let row = {};
				if (!item) {
					row = null;
				} else {
					item.results.map(x => {
						row[x.field] = x.value;
						return row;
					});
					row["isActive"] = false;
					screenerFiltersOutput["rows"].push(row);
				}
				return row;
			});
			screenerFiltersOutput["matches"] = nextProps.screenerFiltersOutput.matches;

			this.setState({
				screenerFiltersOutput: screenerFiltersOutput
			});
		}


		// receive master data from Screener
		if (
			nextProps.screenerMasterData !== this.props.screenerMasterData
		) {
			this.setState({
				screenerMasterData: nextProps.screenerMasterData
			});
		}


		if (this.props.templateData !== nextProps.templateData && !this.state.confirmationModalIsOpen) {
			//TODO- We can change XML to have array for fundinfocus, non-fundinfocus, benchmark
			const nonFIF = [];
			if (!nextProps.isComingFromModuleSelection && (this.props.userType.response === 'International')) {
				this.currency = nextProps.templateData.currency;
			}

			if (nextProps.templateData.funds.nonfundinfocus !== undefined) {
				if (nextProps.templateData.funds.nonfundinfocus.symbol === undefined) {
					for (const key in nextProps.templateData.funds.nonfundinfocus) {
						if (nextProps.templateData.funds.nonfundinfocus.hasOwnProperty(key)) {
							nonFIF.push(nextProps.templateData.funds.nonfundinfocus[key]);
						}
					}
				} else {
					nonFIF.push({ symbol: nextProps.templateData.funds.nonfundinfocus.symbol, wsodissue: nextProps.templateData.funds.nonfundinfocus.wsodissue, fundfamily: nextProps.templateData.funds.nonfundinfocus.fundfamily });
				}
			}
			const _templateData = nextProps.templateData;
			if (this.props.userType.response === 'International') {
				_templateData.currency = this.currency === '' ? nextProps.templateData.currency : this.currency;
			}
			_templateData.funds = {
				primarybenchmark: nextProps.templateData.funds.primarybenchmark !== undefined ? [{ symbol: nextProps.templateData.funds.primarybenchmark.symbol, wsodissue: nextProps.templateData.funds.primarybenchmark.wsodissue, fundfamily: nextProps.templateData.funds.primarybenchmark.fundfamily }] : [],
				benchmark: nextProps.templateData.funds.benchmark !== undefined ? [{ symbol: nextProps.templateData.funds.benchmark.symbol, wsodissue: nextProps.templateData.funds.benchmark.wsodissue, fundfamily: nextProps.templateData.funds.benchmark.fundfamily }] : [],
				fundinfocus: [{ fundShareClassId: nextProps.templateData.funds.fundinfocus.fundShareClassId, symbol: nextProps.templateData.funds.fundinfocus.symbol, wsodissue: nextProps.templateData.funds.fundinfocus.wsodissue, fundfamily: nextProps.templateData.funds.fundinfocus.fundfamily, name: nextProps.templateData.fifFundName, isInternationalUser: this.props.userType.response === 'International' }],
				nonfundinfocus: nonFIF
			};
			
			if (nextProps.templateData.sections && nextProps.templateData.sections.length === 0) {  // new template creation.
				let sectionMasterData = cloneDeep(this.props.userType.response === 'International' ? Constant.sectionMasterDataIntl.sections : Constant.sectionMasterData.sections);
				if(this.props.userType.response === 'International') {
					let updateSectionIndex = sectionMasterData.findIndex((obj => obj.sectionname === 'Correlation Matrix'));
					if(updateSectionIndex > -1) {
						sectionMasterData[updateSectionIndex].subsections[0].subsectiondisplayname = "Return Correlation Matrix";
					}
				}
				_templateData.sections = sectionMasterData
			}
			
			if(this.props.userType.response === 'International' && _templateData.benchmark && _templateData.benchmark.blendedBenchmarkNames != '') {
				if(!(_templateData.funds.benchmark && _templateData.funds.benchmark.length >0 && _templateData.funds.benchmark[0].symbol != _templateData.benchmark.blendedBenchmarkNames && _templateData.benchmark.blendedBenchmarkNames.includes('+'))){
					let currentBlendedBenchmark = cloneDeep(Constant.blendedBenchmarksMilFunds.filter(x=>x.currency == _templateData.currency));
					let bench = [{symbol : currentBlendedBenchmark[0].name, wsodissue: currentBlendedBenchmark[0].xId}];
					_templateData.funds.benchmark = bench;	
				}			
			}

			this.setState({
				templateResult: _templateData
			});
		}


		if (nextProps.primaryBenchmark && nextProps.primaryBenchmark.primarybenchmarks.length > 0 && this.props.primaryBenchmark !== nextProps.primaryBenchmark) {
			const newtemplateResultState = Object.assign({}, this.state.templateResult);
			const primaryBenchmark = [{ symbol: (nextProps.primaryBenchmark.primarybenchmarks[0].benchmarkName ? nextProps.primaryBenchmark.primarybenchmarks[0].benchmarkName : ''), wsodissue: nextProps.primaryBenchmark.primarybenchmarks[0].benchmarkXid, fundfamily: 'PrimaryBenchmark' }];
			if (primaryBenchmark[0].symbol && primaryBenchmark[0].wsodissue !== 0) {
				newtemplateResultState.funds.primarybenchmark = primaryBenchmark;
				this.setState({ templateResult: newtemplateResultState });
				if (nextProps.primaryBenchmark.primarybenchmarks[0].benchmarkXid !== undefined && this.props.userType.response === 'International' && this.state.templateResult.funds.fundinfocus.length > 0 && this.state.templateResult.funds.fundinfocus[0].name !== undefined) {
					this.checkDefaultedBenchmarks(nextProps.primaryBenchmark.primarybenchmarks[0].benchmarkXid, nextProps.primaryBenchmark.primarybenchmarks[0].benchmarkName, this.state.templateResult.funds.fundinfocus[0].name);
				}
				if (this.state.isFIFAdded === 1) {
					newtemplateResultState.funds.benchmark = primaryBenchmark;
					this.setState({ isFIFAdded: 0 });
				}
			} else if (primaryBenchmark[0].wsodissue === 0 || !primaryBenchmark[0].wsodissue) {
				this.state.errors = ["The Calculation Benchmark is unavailable in your selected currency. Please select a different Fund in Focus or currency for your report."];
				if (this.state.errors.length > 0) {
					this.setState({
						benchmarkValidationModalIsOpen: true
					});
				}
				newtemplateResultState.funds.primarybenchmark = [];
				newtemplateResultState.funds.benchmark = [];
			}
		}

		if(this.props.screenerOutput != nextProps.screenerOutput &&  nextProps.screenerOutput.rows && nextProps.screenerOutput.rows.length === 0  &&  this.isCalledFromFundSearchbar  && this.props.userType.response === 'International'){
			document.getElementsByClassName(
				'rbt-close rbt-token-remove-button'
			)[document.getElementsByClassName(
				'rbt-close rbt-token-remove-button'
			).length-1].click();
			this.isCalledFromFundSearchbar = false;
		}
		if (
			this.props.performanceAsOfScreenerData != nextProps.performanceAsOfScreenerData &&
			nextProps.performanceAsOfScreenerData.rows
		) {
			let performanceDateValidation = this.validateFundsPerformanceEndDate(nextProps.performanceAsOfScreenerData);
  		
			if (performanceDateValidation.length > 0) {
				this.saveTemplate = false;
				this.setState({ endDateValidationMessage: true});
				performanceDateValidation.forEach(item => {
					this.state.endDateValidationerrors.push(item);
				});
				return false;
			}


			if (this.saveTemplate) {
				if (this.props.TemplateId > 0) {
					this.openReplaceModal();
				} else {
					this.openSaveModal();
				}
				this.saveTemplate = false;
			} else {
			    	if (this.state.isFundChangeDone){ 
				
						if (lodash.isEqual(this.props.performanceAsOfScreenerData,nextProps.performanceAsOfScreenerData)) {
							this.SetTimePeriod(); //MFS-4273
						} else{
								this.loadTimePeriod();
							}
		
				} else {
				
					this.SetTimePeriod(); //MFS-4273
				}
			}
		}
		if ((this.props.mfsPrimaryBenchmark != nextProps.mfsPrimaryBenchmark) && nextProps.mfsPrimaryBenchmark.primarybenchmarks &&
			  this.props.userType.response === 'International') {
			let items = nextProps.mfsPrimaryBenchmark.primarybenchmarks.filter((item, index, array) => {
				return array.map((mapItem) => mapItem['benchmarkXid']).indexOf(item['benchmarkXid']) === index
			})
			this.isDiffPrimarybenchmark = false;
			
			if (!this.state.validationModal3MonthOpen && !this.isSaveTemplate) {
				if (items.length > 1) {
					this.openModuleSelection =true;
					this.setState({
						benchmarkModalIsOpen: true
					});
				} else if (this.CounterLoad === 2) {
					this.openModuleSelection =true;
					if(document.getElementById('btnSelectModules')) {
						document.getElementById('btnSelectModules').click();
					}
				}
			}else if (!this.state.validationModal3MonthOpen && this.CounterLoad === 2 && this.state.isvalidationModal3MonthOpen && nextProps.mfsPrimaryBenchmark==undefined) {
				this.openModuleSelection =true;
				this.setState({ isvalidationModal3MonthOpen : false});
				document.getElementById('btnSelectModules').click();
			} 
		}

		if (this.props.screenerDataMstarCategory !== nextProps.screenerDataMstarCategory && (this.props.userType.response === 'International')) {
			this.setState({ downloadSpinnerModalIsOpen: false })
		}
		//If there is no validation for 3 month popup then validationModal3MonthCounter=2 
		//If nextProps.mfsPrimaryBenchmark= undefined then  there is no primary benchmark and check for benchmarkModalIsOpen validation
		if (!this.isDiffPrimarybenchmark && this.CounterLoad === 2 && this.validationModal3MonthCounter === 2 && this.props.userType.response === 'International' && !this.openModuleSelection && (nextProps.mfsPrimaryBenchmark == undefined || !this.state.benchmarkModalIsOpen)) {
			this.openModuleSelection = false;
			this.validationModal3MonthCounter = 0;
			document.getElementById('btnSelectModules').click();
			}

	}
	
	handleInputChange = (event) => {
		if (event.target.value.trim() !== '') {
			this.setState({
				isSaveDisabled: false,
				statusMessage: '',
				templateName: event.target.value
			});
		} else {
			this.setState({
				isSaveDisabled: true,
				statusMessage: '',
				templateName: ''
			});
		}
	}
	closeBenchmarkValidationModal = () => {
		this.setState({ benchmarkValidationModalIsOpen: false });
		const newtemplateResultState = Object.assign({}, this.state.templateResult);
		let screenerData = cloneDeep(this.state.screenerOutput);
		screenerData.forEach(item => {
			if (item.MSDWSODIssue && item.MSDWSODIssue.toString() === newtemplateResultState.funds.fundinfocus[0].wsodissue) {
				item["isActive"] = false;
			}
		});

		newtemplateResultState.funds.fundinfocus = [];
		newtemplateResultState.funds.benchmark = [];

		this.setState({ templateResult: newtemplateResultState, screenerOutput: screenerData });
	}
	checkDefaultedBenchmarks = (benchmarkXid, benchmarkSymbol, fifname) => {
		let isDefaulted = Constant.defaultedBenchmarks.find(item => item == benchmarkXid);
		if (isDefaulted !== undefined) {
			this.state.errors = ["The Calculation Benchmark is unavailable in your selected currency. Please select a different Fund in Focus or currency for your report."];
			if (this.state.errors.length > 0) {
				this.setState({
					benchmarkValidationModalIsOpen: true
				});
			}
		}
	}
	requestSymbolSearchApi = (searchtext, fundType) => {
		if (this.props.userType.response === 'International') {
			let token = Format.getQueryString("access_token") || localStorage.getItem('access_token');
			if (token !== null && token !== undefined && token !== "") {
				this.props.requestXrefByTerm(searchtext);
				this.props.requestXrefByTermETF(searchtext);
			}
		} else {
			this.props.requestSymbolSearchApi(searchtext, fundType);
		}
	}

	requestDecodeApi = (key) => {
		if (this.props.userType.response === 'International') {
			this.props.setFundShareclassId(key[0].key);
		} else {
			this.props.requestDecodeApi(key);
		}
	}

	requestGetBenchmarksApi = (input) => {
		this.props.requestGetBenchmarksApi(input,
			(this.props.userType.response === 'International') ? 'yes' : 'no',
			(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD');
	}

	requestGetPrimaryBenchmarkApi = (input) => {
		this.props.requestGetPrimaryBenchmarkApi(input,
			(this.props.userType.response === 'International') ? 'yes' : 'no',
			(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'
		);
	}


	resetScreenerFiltersOutput = () => {
		this.setState({
			screenerFiltersOutput: [],
		}, () => this.props.resetScreenerFiltersOutput);
	}

	validateFundsPerformanceEndDate(screenerData) {
		let performanceDateValidation = [];
		let fifPerformanceEndDate = '';
		let fundShareClassID = 0;
		let fundName = '';
		let performanceEndDate = '';
		let fif = '';
		let nonFif = {};
		let fiFFundShareClassId = this.state.templateResult.funds.fundinfocus.length >0 ? this.state.templateResult.funds.fundinfocus[0].fundShareClassId : "";
		let row = [];

		screenerData.rows.map(screenerItem => {
			screenerItem.results.map(item => {
				if (item.field == "MSDFundShareClassID") {
					fundShareClassID = item.value;
				} else if (item.field == "MSDFundName") {
					fundName = item.value;
				} else if (item.field == "MSDPerformanceAsOfDate") {
					performanceEndDate = item.value;
				}
			});

			if (fiFFundShareClassId == fundShareClassID) {
				fif = fundName;
				fifPerformanceEndDate = performanceEndDate;
			} else {
				nonFif = {
					"fundname": fundName,
					"performanceEndDate": performanceEndDate
				};
				row.push(nonFif);
			}
		});

		row.forEach(item => {
			if (item.performanceEndDate < fifPerformanceEndDate) {
				performanceDateValidation.push(item.fundname);
			}
		});
		return performanceDateValidation;
	}

	cwrpTemplateFundsEtfDataBind(fundShareclassId) {
		// screener call
		if (fundShareclassId !== "") {
			let screenerInput = Constant.screenerInternationalInputs;
			screenerInput.arguments[0].conditions[0].values = [fundShareclassId];
			screenerInput.arguments[1].conditions[0].values = utilityFunctions.getPerformanceEndDateValidation().toString().split(",");
			this.props.requestScreenerDataApi(
				SCREENER_TYPES.MutualFund,
				screenerInput,
				(this.props.userType.response === 'International') ? 'yes' : 'no',
				(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'
			);
		}
	}

	requestGetScreenerCategoryDataApi = (categoryName) => {
		if (categoryName !== "") {
			this.setState({ downloadSpinnerModalIsOpen: true })
			let screenerInput = Constant.screenerInternationalInputsForCategoryFunds;
			screenerInput.arguments[0].conditions[0].values = [categoryName];
			screenerInput.arguments[1].conditions[0].values = utilityFunctions.getPerformanceEndDateValidation().toString().split(",");
			this.props.requestScreenerMstarCategoryDataApi(
				categoryName,
				(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'
			);
		}
	}

	reviewValidationModal = () => {
		this.setState({
			benchmarkModalIsOpen: false
		});
	}

	continueValidationModal = () => {
		this.setState({
			benchmarkModalIsOpen: false
		});
		document.getElementById('btnSelectModules').click();
	}

	closeValidation3MonthModal = () => {
		this.validationModal3MonthCounter = 0;		
		this.setState({
			validationModal3MonthOpen: false,
			benchmarkModalIsOpen: false
		//	validationModal3MonthIsOpen:false
		});
	}

	closeValidationModal = () => {
		this.setState({
			validationModalIsOpen: false
		});

		const fundInFocus =  this.state.templateResult.funds.fundinfocus[0];
		let wsodIssueList = fundInFocus ? fundInFocus.wsodissue.toString() : null;
		this.state.templateResult.funds.nonfundinfocus.forEach(item => {
			if (item.fundfamily === "MFS") {
				wsodIssueList = wsodIssueList + "," + item.wsodissue;
			}
		});

		let wsodMfslength =wsodIssueList ? wsodIssueList.split(',').length : 0;
		if (wsodIssueList && wsodMfslength > 1 && this.state.isFromSelectModules) {
			this.props.requestGetMfsPrimaryBenchmarkApi(wsodIssueList,
				(this.props.userType.response === 'International') ? 'yes' : 'no',
				(this.state.templateResult.currency !== undefined) ? this.state.templateResult.currency : 'USD'
			);
		} else if (this.CounterLoad === 2) {
			document.getElementById('btnSelectModules').click();
		}
	}

	closeAlertModal = () => {
		  this.setState({ endDateValidationMessage: false });
		  this.state.endDateValidationerrors = ["The funds selected have an older performance end date compared to Fund in Focus. Select another fund to proceed."];
	  }

	render() {
		let disableButtonStyle = {};
		if (
			this.state.templateResult.funds.fundinfocus.length <= 0 ||
			this.state.templateResult.funds.nonfundinfocus.length <= 0 ||
			this.state.templateResult.funds.benchmark.length <= 0
		) {
			disableButtonStyle = {
				'pointer-events': 'none',
				opacity: 0.4
			};
		}


		this.props.modalIsOpen ? this.disableScrolling() : this.enableScrolling();

		return (
			<Modal
				isOpen={this.props.modalIsOpen}
				style={CustomModalStyles.SelectFundModalStyles}
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
			>
				<div className={'selectFundModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'header-text'}>Select Funds</div>
								<div className={'close-img'} onClick={this.props.closeModal} />
							</div>
						</div>
						<div className={'buttonContainer'}>
							<div className={'buttons'}>
								<Link
									onClick={this.clickModuleSelection}
									style={disableButtonStyle}
									className="select-modules-button"
								>
									SELECT MODULES
								</Link>
								<Link to={'/Modules'}
									id='btnSelectModules'
									style={{ 'display': 'none' }}
									onClick={this.navigateToModuleSelection}
								>
									SELECT MODULES
								</Link>
								<div
									
								onClick={lodash.debounce(async () => {
									await this.openSaveOrReplaceModal()
								}, 1000)}
									style={disableButtonStyle}
									className={'save-button'}
								>
									SAVE AS TEMPLATE
								</div>
							</div>
						</div>
						<div className={'body'}>
							<LeftRail
								templateResult={this.state.templateResult}
								handleDeleteFundClick={this.handleDeleteFundClick}
								moveCardLeftRail={this.moveCardLeftRail}
								removeCardLeftRail={this.removeCardLeftRail}
								pushCardLeftRail={this.pushCardLeftRail}
								checkValidation={this.checkValidation.bind(this)}
								mfsFundError={this.state.mfsFundError}
								benchmarkError={this.state.benchmarkError}
								userType={this.props.userType}
								changeCurrency={this.changeCurrency}
								disableCurrency = {this.state.disableCurrency}
								requestGetBenchmarksApi={this.requestGetBenchmarksApi}
							/>
							<div className={'right-rail'}>
								<RightRail
									screenerData={this.state.screenerOutput}
									screenerDataMstarCategory={this.props.screenerDataMstarCategory}
									screenerFiltersOutput={this.state.screenerFiltersOutput}
									toggleCompareButton={this.toggleCompareButton}
									removeFund={this.removeFund}
									pushFund={this.pushFund}
									requestSymbolSearchApi={this.requestSymbolSearchApi}
									searchResults={this.state.searchResults}
									requestDecodeApi={this.requestDecodeApi}
									wsodIssue={this.state.wsodIssue}
									benchmarksResults={this.state.benchmarks}
									fundInFocus={this.state.templateResult.funds.fundinfocus}
									primaryBenchmarksResult={this.props.primaryBenchmark}
									requestGetPrimaryBenchmarkApi={this.requestGetPrimaryBenchmarkApi}
									getPrimaryProspectusBenchmarkData={this.getPrimaryProspectusBenchmarkData}
									screenerMasterData={this.state.screenerMasterData}
									requestScreenerDataApi={this.props.requestScreenerDataApi}
									requestScreenerFiltersDataApi={this.props.requestScreenerFiltersDataApi}
									requestGetScreenerMasterDataApi={this.props.requestGetScreenerMasterDataApi}
									isPortfolio={false}
									resetScreenerFiltersOutput={this.resetScreenerFiltersOutput}
									addFundsToFundResultsTable={this.addFundsToFundResultsTable}
									calledFromFundSearchbar={this.calledFromFundSearchbar}
									userType={this.props.userType}
									isInternationalUser={this.props.userType.response === 'International'}
									currency={this.state.templateResult.currency ? this.state.templateResult.currency:'USD'}
									requestGetBenchmarksApi={this.requestGetBenchmarksApi}
									requestGetScreenerCategoryDataApi = {this.requestGetScreenerCategoryDataApi.bind(this)}
								/>
								<DownloadingSpinnerModal
										title = {"Loading..."}
										downloadSpinnerModalIsOpen={
										this.state.downloadSpinnerModalIsOpen
										}
								/>
							</div>
						</div>
					</div>
					<ReplaceTemplateModal
						templateName={this.props.TemplateName}
						openSaveModal={this.openSaveModal}
						closeReplaceModal={this.closeReplaceModal}
						customStyles={CustomModalStyles.ReplaceTemplateModalStyles}
						saveTemplateSettings={this.replaceTemplateSettings}
						replaceModalIsOpen={this.state.replaceModalIsOpen}
						isPortfolio={false}
					/>
					<SaveModal
						isSaveDisabled={this.state.isSaveDisabled}
						saveTemplateSettings={this.saveTemplateSettings}
						customStyles={CustomModalStyles.SaveModalStyles}
						closeSaveModal={this.closeSaveModal}
						saveModalIsOpen={this.state.saveModalIsOpen}
						handleInputChange={this.handleInputChange}
						status={this.state.statusMessage}
						handleEnterClickOnTextbox={this.handleEnterClickOnTextbox}
					/>
					<ConfirmationModal
						userType={this.props.userType}
						goToHomePage={this.goToHomePage}
						closeConfirmationModal={this.closeConfirmationModal}
						confirmationModalIsOpen={this.state.confirmationModalIsOpen}
					/>
					<BenchmarkConfirmationModal
						errorMessages={this.state.benchmarkMessage}
						customStyles={CustomModalStyles.BenchmarkModalStyles}
						reviewValidationModal={this.reviewValidationModal}
						continueValidationModal={this.continueValidationModal}
						benchmarkModalIsOpen={this.state.benchmarkModalIsOpen}
					/>
					<ValidationModal
						errorMessages={this.state.errors}
						customStyles={CustomModalStyles.ValidationModalStyles}
						closeValidationModal={this.closeValidationModal}
						validationModalIsOpen={this.state.validationModalIsOpen}
						isWithdrawalModal={this.state.errors.length > 0}
					/>
					<ValidationModal
						errorMessages={this.state.endDateValidationerrors}
						customStyles={CustomModalStyles.ValidationModalStyles}
						closeValidationModal={this.closeAlertModal}
						validationModalIsOpen={this.state.endDateValidationMessage}
						isWithdrawalModal={this.state.endDateValidationerrors.length > 0}
					/>
					<BenchmarkValidationModal
						errorMessages={this.state.errors}
						customStyles={CustomModalStyles.ValidationModalStyles}
						closeValidationModal={this.closeBenchmarkValidationModal}
						validationModalIsOpen={this.state.benchmarkValidationModalIsOpen}
						isWithdrawalModal={this.state.errors.length > 0}
					/>
					<ValidationModal
						errorMessages={this.state.errors}
						customStyles={CustomModalStyles.ValidationModalStyles}
						closeValidationModal={this.closeValidation3MonthModal}
						validationModalIsOpen={this.state.validationModal3MonthOpen}
						isWithdrawalModal={this.state.errors.length > 0}
					/>
				</div>
			</Modal>
		);
	}
}

SelectFundsModal.propTypes = {
	modalIsOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	containerId: PropTypes.string,
	reportId: PropTypes.string,
	startNewFundComparison: PropTypes.bool,
	callMaintenace: PropTypes.func,
	disableCurrency: PropTypes.bool
};

export default SelectFundsModal;

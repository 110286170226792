import React from 'react';
import { PropTypes } from 'prop-types';
import Select, { components } from 'react-select';

import cloneDeep from 'lodash/cloneDeep';
import lodash from 'lodash';
import numeral from 'numeral';

import * as utilityFunctions from "../../../utils/commonMethods";
import * as Constants from "../../../utils/constants";

import { customTheme, customStyles, salesChargeType, WrapFeeFrequencyTypes } from "../../../utils/constants";

import salesChargesModule from '../../../styles/components/portfolio/SalesChargesModule.scss';

export default class SalesChargesModule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            portfolioOneFunds: [],
            portfolioTwoFunds: [],
        }
        
        // Constants
        this.defaultNoneOption = { label: 'None', value: 'None' }
        this.workingCopyContainerIdOne = '';
        this.workingCopyContainerIdTwo = '';
    }

    componentDidMount() {

        // Set working containerIds in state
        let containerIds = this.props.workingCopyConatainerIds.split(',');
        this.workingCopyContainerIdOne = containerIds[0];
        this.workingCopyContainerIdTwo = containerIds[1];
        
        // Create local state from hypothetical state
        this.createDefaultStateFromHypoState(this.props.hypotheticalState);

        // Getting fee schedules in the next props
        this.props.requestGetEventFeeSchedulesApi(this.workingCopyContainerIdOne);
        this.props.requestGetEventFeeSchedulesP2Api(this.workingCopyContainerIdTwo);
    }

    componentDidUpdate() {
        this.populateFundValues();
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        // get-fee-schedules: 1
        if (!lodash.isEqual(this.props.feeSchedules, nextProps.feeSchedules)) {
            let funds = cloneDeep(this.state.portfolioOneFunds);
            funds.forEach(fund => {
                let result = nextProps.feeSchedules.find(item => item.InvestmentId == fund.investmentId);
                if (result) {
                    fund.salesChargeType = { label: result.ApplyType, value: result.ApplyType };
                    fund.breakpoint = result.Breakpoint;
                    fund.front = result.Front*100;
                    fund.scheduleId = result.Id;
                    fund.waiveAllNormalSalesCharges = result.WaiveAllNormalSalesCharges;
                    fund.wrapFee = result.WrapFee.Value * 100;
                    fund.wrapFeeFrequency = result.WrapFee.Frequency;
                    fund.wrapFeeSnapPeriods = result.WrapFee.SnapPeriods !== '' ? result.WrapFee.SnapPeriods: 'End';
                }
            })
            this.setState({portfolioOneFunds: funds});
        }

        // get-fee-schedules: 2
        if (!lodash.isEqual(this.props.feeSchedulesP2, nextProps.feeSchedulesP2)) {
            let funds = cloneDeep(this.state.portfolioTwoFunds);
            funds.forEach(fund => {
                let result = nextProps.feeSchedulesP2.find(item => item.InvestmentId == fund.investmentId);
                if (result) {
                    fund.salesChargeType = { label: result.ApplyType, value: result.ApplyType };
                    fund.breakpoint = result.Breakpoint;
                    fund.front = result.Front*100;
                    fund.scheduleId = result.Id;
                    fund.waiveAllNormalSalesCharges = result.WaiveAllNormalSalesCharges;
                    fund.wrapFee = result.WrapFee.Value * 100;
                    fund.wrapFeeFrequency = result.WrapFee.Frequency;
                    fund.wrapFeeSnapPeriods = result.WrapFee.SnapPeriods !== '' ? result.WrapFee.SnapPeriods: 'End'
                }
            })
            this.setState({portfolioTwoFunds: funds});
        }

        // add-fee-schedules: 1
        if (!lodash.isEqual(this.props.addFeeScheduleResult, nextProps.addFeeScheduleResult)) {
            let funds = cloneDeep(this.state.portfolioOneFunds);
            funds.forEach(fund => {
                let result = nextProps.addFeeScheduleResult.feeSchedules.find(item => item.InvestmentId == fund.investmentId);
                if (result) {
                    fund.salesChargeType = { label: result.ApplyType, value: result.ApplyType };
                    fund.breakpoint = result.Breakpoint;
                    fund.front = result.Front*100;
                    fund.scheduleId = result.Id;
                    fund.waiveAllNormalSalesCharges = result.WaiveAllNormalSalesCharges;
                    fund.wrapFee = result.WrapFee.Value;
                    fund.wrapFeeFrequency = result.WrapFee.Frequency;
                    fund.wrapFeeSnapPeriods = result.WrapFee.SnapPeriods !== '' ? result.WrapFee.SnapPeriods: 'End';
                }
            })
            this.setState({portfolioOneFunds: funds});
        }

        // add-fee-schedules: 2
        if (!lodash.isEqual(this.props.addFeeScheduleP2Result, nextProps.addFeeScheduleP2Result)) {
            let funds = cloneDeep(this.state.portfolioTwoFunds);
            funds.forEach(fund => {
                let result = nextProps.addFeeScheduleP2Result.feeSchedules.find(item => item.InvestmentId == fund.investmentId);
                if (result) {
                    fund.salesChargeType = { label: result.ApplyType, value: result.ApplyType };
                    fund.breakpoint = result.Breakpoint;
                    fund.front = result.Front*100;
                    fund.scheduleId = result.Id;
                    fund.waiveAllNormalSalesCharges = result.WaiveAllNormalSalesCharges;
                    fund.wrapFee = result.WrapFee.Value;
                    fund.wrapFeeFrequency = result.WrapFee.Frequency;
                    fund.wrapFeeSnapPeriods = result.WrapFee.SnapPeriods !== '' ? result.WrapFee.SnapPeriods: 'End';
                }
            })
            this.setState({portfolioTwoFunds: funds});
        }

        // modify-fee-schedules: 1
        if (!lodash.isEqual(this.props.modifyFeeScheduleResult, nextProps.modifyFeeScheduleResult)) {
            
        }

        // modify-fee-schedules: 2
        if (!lodash.isEqual(this.props.modifyFeeScheduleP2Result, nextProps.modifyFeeScheduleP2Result)) {
            
        }

        // selected-tab
        if (!lodash.isEqual(this.props.selectedTab, nextProps.selectedTab)) {
            // Moving from "Sales Charge" to "Initial Investment" or "Events"
            if ((this.props.selectedTab === 'Sales Charge' && nextProps.selectedTab == 'Initial Investment') ||
                (this.props.selectedTab === 'Sales Charge' && nextProps.selectedTab == 'Events')) {
                
                // Create/Modify fee schedules
                this.createOrModifyFeeSchedules(1);
                this.createOrModifyFeeSchedules(2);
            }
        }

        // Funds in the portfolios have been added/updated/deleted
        let portWsodIssue = {
            wsodIssuesOne: this.props.hypotheticalState.portfolios[0].funds.map(item => item.symbol),
            wsodIssuesTwo: this.props.hypotheticalState.portfolios[1].funds.map(item => item.symbol)
        }
        
        let nextPortWsodIssue = {
            wsodIssuesOne: nextProps.hypotheticalState.portfolios[0].funds.map(item => item.symbol),
            wsodIssuesTwo: nextProps.hypotheticalState.portfolios[1].funds.map(item => item.symbol)
        }
        
        if (!lodash.isEqual(portWsodIssue, nextPortWsodIssue)) {

            // Create local state from hypothetical state
            this.createDefaultStateFromHypoState(nextProps.hypotheticalState);

            // Getting fee schedules in the next props
            this.props.requestGetEventFeeSchedulesApi(this.workingCopyContainerIdOne);
            this.props.requestGetEventFeeSchedulesP2Api(this.workingCopyContainerIdTwo);
        }
    }

    componentWillUnmount() {
        this.createOrModifyFeeSchedules(1);
        this.createOrModifyFeeSchedules(2);
    }

    /**
     * Create or modify fee schedules for passed portfolio index
     * @param {number} portfolioIndex Portfolio index either 1 or 2
     */
    createOrModifyFeeSchedules = (portfolioIndex) => {

        let feeSchedulesAdd = [];
        let feeSchedulesModify = [];
        let funds = portfolioIndex == 1 ? this.state.portfolioOneFunds: this.state.portfolioTwoFunds;
        
        funds.forEach(item => {
            item.scheduleId == '' ? feeSchedulesAdd.push(item): feeSchedulesModify.push(item);
        });

        // Create fee schedules
        if (feeSchedulesAdd.length > 0) {
            let feeSchedules = [];
            feeSchedulesAdd.forEach(item => {
                feeSchedules.push({
                    "applyType": item.salesChargeType.value,
                    "back": item.back > -1 ? item.back/100: 0,
                    "breakpoint": item.breakpoint,
                    "front": item.front/100,
                    "investmentId": item.investmentId,
                    "waiveAllNormalSalesCharges": item.waiveAllNormalSalesCharges,
                    "wrapFee": item.wrapFee/100,
                    "wrapFeeFrequency": item.wrapFeeFrequency,
                    "wrapFeeSnapPeriods": item.wrapFeeSnapPeriods,

                });
            })
        }

        // Modify fee schedules
        if (feeSchedulesModify.length > 0) {
            let feeModifySchedules = [];
            feeSchedulesModify.forEach(item => {
                feeModifySchedules.push({
                    "applyType": item.salesChargeType.value,
                    "back": item.back > -1 ? item.back / 100 : 0,
                    "breakpoint": item.breakpoint,
                    "front": item.front / 100,
                    "investmentId": item.investmentId,
                    "waiveAllNormalSalesCharges": item.waiveAllNormalSalesCharges,
                    "scheduleId": item.scheduleId,
                    "wrapFee": item.wrapFee/100,
                    "wrapFeeFrequency": item.wrapFeeFrequency,
                    "wrapFeeSnapPeriods": item.wrapFeeSnapPeriods,
                });

            })
        }
    }

    /**
     * Create state from hypothetical state 
     */
    createDefaultStateFromHypoState = (hypotheticalState) => {
        if (hypotheticalState.portfolios.length > 0) {
            let fundsOne = [];
            let fundsTwo = [];
            for (let i = 0; i < hypotheticalState.portfolios.length; i++) {
                hypotheticalState.portfolios[i].funds.forEach(fund => {
                    if (i == 0) {
                        fundsOne.push({
                            name: fund.name,
                            symbol: fund.symbol,
                            investmentId: fund.investmentId,
                            salesChargeType: this.defaultNoneOption,
                            breakpoint: 0,
                            front: 0,
                            back: fund.salesLoadDeferred,
                            scheduleId: '',
                            waiveAllNormalSalesCharges: 0,
                            wrapFee: 0,
                            wsodIssue: fund.wsodissue,
                            wrapFeeFrequency: 'None',
                            wrapFeeSnapPeriods: 'End'
                        });
                    } else if (i == 1) {
                        fundsTwo.push({
                            name: fund.name,
                            symbol: fund.symbol,
                            investmentId: fund.investmentId,
                            salesChargeType: this.defaultNoneOption,
                            breakpoint: 0,
                            front: 0,
                            back: fund.salesLoadDeferred,
                            scheduleId: '',
                            waiveAllNormalSalesCharges: 0,
                            wrapFee: 0,
                            wsodIssue: fund.wsodissue,
                            wrapFeeFrequency: 'None',
                            wrapFeeSnapPeriods: 'End'
                        });
                    }
                });
            }
            this.setState({
                portfolioOneFunds: fundsOne,
                portfolioTwoFunds: fundsTwo
            });
        }
    }

    /**
     * When user select sales charge type dropdown in either portfolio two
     * @param {object} selectedSalesCharge selected sales charge type for fund
     */
    onBlurChargeTypeFront = (e) => {
        this.updatePortfolioFundBreakpointOrCustom(e, false, true);
    }

    /**
     * When user select sales charge type dropdown in either portfolio two
     * @param {object} selectedSalesCharge selected sales charge type for fund
     */
    onBlurChargeTypeBreakpoint = (e) => {
        this.updatePortfolioFundBreakpointOrCustom(e, true, false);
    }

    /**
     * When user move focus from wrap fee in portfolio 1
     * @param {Object} e event object
     */
    onBlurChargeTypeWrapFee = (e) => {
        this.updatePortfolioFundWrapFee(e);
    }

    /**
	 * Return content based on fund charge type
	 * @param {string} fundFeeDetails fund fee details. {salesChargeType, breakpoint, front, back }
	 * @param {string} portfolioIndex Portfolio index. {1/2}
	 */
	getSalesChargeTypeDetails = (fundFeeDetails, portfolioIndex) => {
        if (fundFeeDetails.salesChargeType) {
            let salesChargeType = fundFeeDetails.salesChargeType.value;
            if (salesChargeType === 'None' || salesChargeType === 'Schedule') {
                return '';
            } else if (salesChargeType === 'Breakpoint') {
                return <div className={'dv-charge-type-breakpoint'}>
                    <span>$</span>
                    <input type="text"
                        className={'input-charges raw-value'}
                        data-index={portfolioIndex}
                        data-symbol={fundFeeDetails.symbol}
                        data-raw-value={fundFeeDetails.breakpoint}
                        onBlur={this.onBlurChargeTypeBreakpoint}
                        onKeyDown={utilityFunctions.allowOnlyNumbers}
                    />
                </div>
            } else if (salesChargeType === 'Custom') {
                let valueCDSC = fundFeeDetails.back > -1 ? fundFeeDetails.back.toFixed(2) + '%': '--';
                let valueCDSCStyle1 = fundFeeDetails.back > -1 ? {}: { 'position': 'absolute', 'left': '8px' };
                let valueCDSCStyle2 = fundFeeDetails.back > -1 ? { 'font-weight': 'bold' }: { 'font-weight': 'bold', 'padding-right': '26px' };
                return <div className={'dv-input-charges'}>
                    <input type="text"
                        className={'input-charges input-charges-custom raw-value'}
                        data-index={portfolioIndex}
                        data-symbol={fundFeeDetails.symbol}
                        data-raw-value={fundFeeDetails.front}
                        data-is-percent='true'
                        onBlur={this.onBlurChargeTypeFront}
                        onKeyDown={utilityFunctions.allowOnlyNumbers}
                    />
                    <div className={'dv-charges-custom'}>
                        <span style={valueCDSCStyle1}>CDCS: </span><span style={valueCDSCStyle2}>{valueCDSC}</span>
                    </div>
                </div>
            }    
        }
    }

    /**
     * Get wrap fee from state based on portfolio index
     * @param {number} portfolioIndex Portfolio number, possible value 1/2
     */
    getWrapFeeFromState = (portfolioIndex) => {
        let wrapFee = 0;
        if (portfolioIndex == 1 && this.state.portfolioOneFunds.length > 0) {
            wrapFee = this.state.portfolioOneFunds[0].wrapFee;
        } else if (portfolioIndex == 2 && this.state.portfolioTwoFunds.length > 0) {
            wrapFee = this.state.portfolioTwoFunds[0].wrapFee;
        }
        return wrapFee;
    }

    /**
     * Get wrap frquency from state based on portfolio index
     * @param {number} portfolioIndex Portfolio number, possible value 1/2
     */
    getWrapFeeFrequencyFromState = (portfolioIndex) => {
        let wrapFrequency = this.defaultNoneOption;
        let _wrapFrequency = 'None';
        if (portfolioIndex == 1 && this.state.portfolioOneFunds.length > 0) {
            _wrapFrequency = this.state.portfolioOneFunds[0].wrapFeeFrequency;
        } else if (portfolioIndex == 2 && this.state.portfolioTwoFunds.length > 0) {
            _wrapFrequency = this.state.portfolioTwoFunds[0].wrapFeeFrequency;
        }
        if (_wrapFrequency !== 'None') {
            wrapFrequency = WrapFeeFrequencyTypes.find(item => item.value == _wrapFrequency);
        }
        return wrapFrequency;
    }

    /**
     * Update portfolio's fund's sales charge type in the state
     * @param {number} portfolioIndex Portfolio number, possible value 1/2
     */
    updatePortfolioFundSalesChargeType = (portfolioIndex, symbol, selectedSalesCharge) => {
        let funds = portfolioIndex == 1 ? cloneDeep(this.state.portfolioOneFunds): cloneDeep(this.state.portfolioTwoFunds);
        if (funds.length > 0) {
            funds.forEach(fund => {
                if (fund.wsodIssue !== Constants.CustomFunds.Cash.MSDWSODIssue) {
                    if (symbol !== '') {
                        if (fund.symbol == symbol) {
                            fund.salesChargeType = selectedSalesCharge
                        }
                    } else {
                        fund.salesChargeType = selectedSalesCharge
                    }
                }
            })
        }

        portfolioIndex == 1 ? this.setState({ portfolioOneFunds: funds }) : this.setState({ portfolioTwoFunds: funds });
    }

    /**
     * Update either front or breakpoint
     * @param {event} e event object
     * @param {bool} isBreakpoint is update for breakpoint
     * @param {boll} isFront is update for front
     */
	updatePortfolioFundBreakpointOrCustom = (e, isBreakpoint, isFront) => {
		
		let elem = e.target;
		let value = elem.value.replace('$', '').replace('%', '');
        value = value !== '' ? value : '0.00';
        let portfolioIndex = elem.getAttribute('data-index');
        let funds = portfolioIndex == 1 ? cloneDeep(this.state.portfolioOneFunds): cloneDeep(this.state.portfolioTwoFunds);
		let symbol = elem.getAttribute('data-symbol');

		for (let i = 0; i < funds.length; i++) {
			if (funds[i].symbol === symbol) {
				if (isBreakpoint) {
					funds[i].breakpoint = value;
				} else if (isFront) {
					funds[i].front = value;
				}
			}
        }
        
        if (portfolioIndex == 1) {
            this.setState({
                portfolioOneFunds: funds
            })
        } else {
            this.setState({
                portfolioTwoFunds: funds
            })
        }
    }

    /**
     * Update portfolio's fund's wrap frequency in the state
     * @param {number} portfolioIndex Portfolio number, possible value 1/2
     * @param {number} selectedSalesChargeFreq Selected wrap frequency option
     */
    updatePortfolioFundSalesChargeFrequency = (portfolioIndex, selectedSalesChargeFreq) => {
        let funds = portfolioIndex == 1 ? cloneDeep(this.state.portfolioOneFunds): cloneDeep(this.state.portfolioTwoFunds);
        if (funds.length > 0) {
            funds.forEach(fund => {
                fund.wrapFeeFrequency = selectedSalesChargeFreq.value
            })
        }
        if (portfolioIndex == 1) {
            this.setState({
                portfolioOneFunds: funds
            })
        } else {
            this.setState({
                portfolioTwoFunds: funds
            })
        }
    }

    /**
     * Update portfolio's fund's wrap fee in the state
     * @param {Event} e event object
     */
    updatePortfolioFundWrapFee = (e) => {

        let elem = e.target;
		let wrapFee = elem.value.replace('%', '');
        wrapFee = wrapFee !== '' ? wrapFee : '0.00';
        let portfolioIndex = elem.getAttribute('data-index');
        let funds = portfolioIndex == 1 ? cloneDeep(this.state.portfolioOneFunds): cloneDeep(this.state.portfolioTwoFunds);

		for (let i = 0; i < funds.length; i++) {
			funds[i].wrapFee = wrapFee;
        }

        if (portfolioIndex == 1) {
            this.setState({
                portfolioOneFunds: funds
            })
        } else {
            this.setState({
                portfolioTwoFunds: funds
            })
        }
    }
    
    // Populate fund dollars textbox from original values
    populateFundValues = () => {
        let elements = document.querySelectorAll(".raw-value");
        elements.forEach((element) => {
            if (element && element.tagName === 'INPUT' && element.type === 'text') {
                let rawValue = element.getAttribute('data-raw-value');
                let isPercent = element.getAttribute('data-is-percent');
                element.value = numeral(parseFloat(rawValue)).format('0,0.00') + (isPercent ? '%': '');
            }
        });
    }

    render() {
        let cssClass = '';
        if (this.props.isSelected) {
			cssClass = 'display';
		} else {
			cssClass = 'no-display';
		}
        const DropdownIndicator = props => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <i
                            className="dropdown"
                            aria-hidden="true"
                        />
                    </components.DropdownIndicator>
                )
            );
        };

        const customTheme = {
            borderRadius: 0,
            border: 0,
            colors: {
                primary: '#f4f4f4',
                primary50: '#f4f4f4',
                neutral0: '#FFF',
            },
        }
        const customStyles = {
            control: (base, state) => ({
                ...base,
                border: '1px solid rgba(34,36,38,.15) !important',
                boxShadow: '0 !important',
                '&:hover': {
                    border: '1px solid rgba(34,36,38,.15) !important'
                },
                height: '34px',
                'min-height': '34px',

            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isSelected ? '#f4f4f4' : (state.isFocused ? '#f4f4f4' : '#FFF'),
                color: '#333',
                'font-weight': state.isSelected ? 'bold' : 'normal'
            }),
            menu: styles => ({ ...styles, margin: '0px' }),
            noOptionsMessage: styles => ({ ...styles, 'text-align': 'left', color: 'rgba(0,0,0,.4)' })
        }

        let wrapFee1 = 0.00;
        let wrapFee2 = 0.00;
        if (this.state.portfolioOneFunds && this.state.portfolioOneFunds.length > 0) {
            wrapFee1 = this.state.portfolioOneFunds[0].wrapFee;
        }
        if (this.state.portfolioTwoFunds && this.state.portfolioTwoFunds.length > 0) {
            wrapFee2 = this.state.portfolioTwoFunds[0].wrapFee;
        }

        return (
            <div className={'SalesChargesModule '+ cssClass}>
                <div className={'hypothetical-data'}>
                    {/* Filter - Header - Portfolio One */}
                    <div className={'events-header'}>
                        {this.state.portfolioOneFunds.length > 0 ? <div>
                            <div className="select-investment-period">
                                <span className="label-investment-period" style={{'padding-left':'21px'}}>Portfolio One</span>
                                <div className="custom-calendar">

                                </div>
                            </div>
                            <div className={'filter-styles'} style={{'padding-top':'35px'}}>
                                {/* One - Portfolio Sales Charge */}
                                <div className="label-style dv-portfolio-charge-type" style={{'float':'left','margin-top':'0px !important' }}>
                                    <span className="label-period">Portfolio Sales Charge</span>
                                    <Select
                                        name="sales-charge-type"
                                        className="dropdown-mfs"
                                        theme={customTheme}
                                        styles={customStyles}
                                        isSearchable={false}
                                        defaultValue={this.defaultNoneOption}
                                        onChange={selSalesCharge => this.updatePortfolioFundSalesChargeType(1, '', selSalesCharge)}
                                        options={salesChargeType}
                                        components={{ DropdownIndicator }}
                                        id="selectSalesChargeTimePeriod1" />
                                </div>
                                <div class="label-style dv-portfolio-charge-type" style={{'border': '1px solid #d8d1d1','float': 'left','width': '1px','height': '60px'}}></div>
                                {/* One - Wrap Fee */}
                                <div className={'label-style dv-portfolio-charge-type dv-wrap-fee'} style={{'float':'left'}}>
                                    <span className="label-period" style={{'display':'block'}}>Wrap Fee</span>
                                    <input type="text"
                                           className={'raw-value txt-wrap-fee'}
                                           style={{'width':'110px', 'padding': '5px', 'text-align': 'right'}}
                                           id="txtSalesChargeWrapFee1"
                                           data-index={1}
                                           data-raw-value={wrapFee1}
                                           onBlur={this.onBlurChargeTypeWrapFee}
                                    /> %
                                </div>
                                {/* One - Frequency */}
                                <div className={'label-style dv-portfolio-charge-type'} style={{'margin':'0px !important'}}>
                                    <span className="label-period">Frequency</span>
                                    <Select
                                        name="sales-charge-wrap-frequency"
                                        className="dropdown-mfs"
                                        theme={customTheme}
                                        styles={customStyles}
                                        isSearchable={false}
                                        value={this.getWrapFeeFrequencyFromState(1)}
                                        onChange={freq => this.updatePortfolioFundSalesChargeFrequency(1, freq)}
                                        options={WrapFeeFrequencyTypes}
                                        components={{ DropdownIndicator }}
                                        id="selectSalesChargeWrapFreq1" />
                                </div>
                            </div></div> : null}
                    </div>
                    {/* Filter - Header - Portfolio Two */}
                    <div className={'events-header'}>
                        {this.state.portfolioTwoFunds.length > 0 ? <div>
                            <div className="select-investment-period">
                                <span className="label-investment-period" style={{'padding-left':'21px'}}>Portfolio Two</span>
                                <div className="custom-calendar">

                                </div>
                            </div>
                            <div className={'filter-styles'} style={{'padding-top':'35px'}}>
                                {/* Two - Portfolio Sales Charge */}
                                <div className="label-style dv-portfolio-charge-type" style={{'float':'left','margin-top':'0px !important' }}>
                                    <span className="label-period">Portfolio Sales Charge</span>
                                    <Select
                                        name="sales-charge-type"
                                        className="dropdown-mfs"
                                        theme={customTheme}
                                        styles={customStyles}
                                        isSearchable={false}
                                        defaultValue={this.defaultNoneOption}
                                        onChange={selSalesCharge => this.updatePortfolioFundSalesChargeType(2, '', selSalesCharge)}
                                        options={salesChargeType}
                                        components={{ DropdownIndicator }}
                                        id="selectSalesChargeTimePeriod2" />
                                </div>
                                <div class="label-style dv-portfolio-charge-type" style={{'border': '1px solid #d8d1d1','float': 'left','width': '1px','height': '60px'}}></div>
                                {/* Two - Wrap Fee */}
                                <div className={'label-style dv-portfolio-charge-type dv-wrap-fee'} style={{'float':'left'}}>
                                    <span className="label-period" style={{'display':'block'}}>Wrap Fee</span>
                                    <input type="text"
                                           className={'raw-value txt-wrap-fee'}
                                           style={{'width':'110px', 'padding': '5px', 'text-align': 'right'}}
                                           id="txtSalesChargeWrapFee2"
                                           data-index={2}
                                           data-raw-value={wrapFee2}
                                           onBlur={this.onBlurChargeTypeWrapFee}
                                    /> %
                                </div>
                                {/* Two - Frequency */}
                                <div className={'label-style dv-portfolio-charge-type'} style={{'margin':'0px !important'}}>
                                    <span className="label-period">Frequency</span>
                                    <Select
                                        name="sales-charge-wrap-frequency"
                                        className="dropdown-mfs"
                                        theme={customTheme}
                                        styles={customStyles}
                                        isSearchable={false}
                                        value={this.getWrapFeeFrequencyFromState(2)}
                                        onChange={freq => this.updatePortfolioFundSalesChargeFrequency(2, freq)}
                                        options={WrapFeeFrequencyTypes}
                                        components={{ DropdownIndicator }}
                                        id="selectSalesChargeWrapFreq2" />
                                </div>
                            </div> </div> : null}
                    </div>
                    {/* Portfolio Investment table - Main */}

                    {/* Portfolio Investment table - First */}
                    <div className={'portfolio-investment1 event-portfolio'}>
                        {this.state.portfolioOneFunds.length > 0 ? <div>
                            <div className={'PortfolioInvestmentTable'}>
                                <div className={'portfolio-heading'}>
                                    <label className={'portfolio-name'}>Portfolio One</label>
                                    <a className={'portfolio-edit-link'} onClick={this.props.hideBenchmarkAndHypotheticalsResults}>Edit</a>
                                </div>

                            </div>
                            <div>
                                <table>
                                    <tr className={'bottom-line'}>
                                        <td width="30%"></td>
                                        <td width="30%" className={'table-header'}>Sales Charge Type</td>
                                        <td width="40%"></td>
                                    </tr>
                                </table>
                            </div>
                            {this.state.portfolioOneFunds.length > 0 && this.state.portfolioOneFunds.map(item => {
                                return item.wsodIssue !== Constants.CustomFunds.Cash.MSDWSODIssue ? <div>
                                    <table>
                                        <tr className={'bottom-line'}>
                                            <td width="30%">
                                                <label className={'fund-symbol'}>{item.symbol}</label>
                                                <span className={'view-schedules-link'} 
                                                      data-wsod-issue={item.wsodIssue}
                                                      data-fund-symbol={item.symbol}
                                                      data-fund-name={item.name}
                                                      onClick={this.props.handleShowFeeSchedules}
                                                      title="View Schedule">View Schedule</span>
                                                <br />
                                                <span className={'fund-name'} title={item.name}>{item.name}</span>
                                            </td>
                                            <td width="30%" className={'filter-styles'}>
                                                <Select
                                                    name={"sales-charge-type-" + item.symbol}
                                                    className="dropdown-mfs"
                                                    theme={customTheme}
                                                    styles={customStyles}
                                                    isSearchable={false}
                                                    defaultValue={this.defaultNoneOption}
                                                    value={item.salesChargeType}
                                                    onChange={(option, evt)=> this.updatePortfolioFundSalesChargeType(1, evt.name.replace('sales-charge-type-', ''), option)}
                                                    options={salesChargeType}
                                                    components={{ DropdownIndicator }}
                                                />
                                            </td>
                                            <td width="40%">
                                                {this.getSalesChargeTypeDetails(item, 1)}
                                            </td>
                                        </tr>
                                    </table>
                                </div> : ''
                            })
                            } </div> : <div className="no-funds">No funds selected for this portfolio</div>}
                    </div>
                    {/* Portfolio Investment table - Second */}
                    <div className={'portfolio-investment2 event-portfolio'}>
                        {this.state.portfolioTwoFunds.length > 0 ? <div>
                            <div className={'PortfolioInvestmentTable'}>
                                <div className={'portfolio-heading'}>
                                    <label className={'portfolio-name'}>Portfolio Two</label>
                                    <a className={'portfolio-edit-link'} onClick={this.props.hideBenchmarkAndHypotheticalsResults}>Edit</a>
                                </div>
                            </div>
                            <div>
                                <table>
                                    <tr className={'bottom-line'}>
                                        <td width="30%"></td>
                                        <td width="30%" className={'table-header'}>Sales Charge Type</td>
                                        <td width="40%"></td>
                                    </tr>
                                </table>
                            </div>
                            {this.state.portfolioTwoFunds.length > 0 && this.state.portfolioTwoFunds.map(item => {
                                return item.wsodIssue !== Constants.CustomFunds.Cash.MSDWSODIssue ? <div>
                                    <table>
                                        <tr className={'bottom-line'}>
                                            <td>
                                                <label className={'fund-symbol'}>{item.symbol}</label>
                                                <span className={'view-schedules-link'} 
                                                        data-wsod-issue={item.wsodIssue}
                                                        data-fund-symbol={item.symbol}
                                                        data-fund-name={item.name}
                                                        onClick={this.props.handleShowFeeSchedules}
                                                        title="View Schedule">View Schedule</span>
                                                <br />
                                                <span className={'fund-name'} title={item.name}>{item.name}</span>
                                            </td>
                                            <td width="30%" className={'filter-styles'}>
                                                <Select
                                                    name={"sales-charge-type-" + item.symbol}
                                                    className="dropdown-mfs"
                                                    theme={customTheme}
                                                    styles={customStyles}
                                                    isSearchable={false}
                                                    defaultValue={this.defaultNoneOption}
                                                    value={item.salesChargeType}
                                                    onChange={(option, evt)=> this.updatePortfolioFundSalesChargeType(2, evt.name.replace('sales-charge-type-', ''), option)}
                                                    options={salesChargeType}
                                                    components={{ DropdownIndicator }}
                                                />
                                            </td>
                                            <td width="40%">
                                                {this.getSalesChargeTypeDetails(item, 2)}
                                            </td>
                                        </tr>
                                    </table>
                                </div> : ''
                            })
                            }
                        </div> : <div className="no-funds">No funds selected for this portfolio</div>}
                    </div>
                </div>
            </div>
        );
    }
}
import React from 'react';

const fourStarRatingIconStyles = {
	height: '191px',
	width: '200px'
};

const FourStarRatingIcon = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="238px" height="200px" viewBox="0 0 238 200" version="1.1"
			style={fourStarRatingIconStyles}>
			<g id="Final" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="Select-Funds-(Error-Message)" transform="translate(-722.000000, -737.000000)">
					<rect fill="#FFFFFF" x="0" y="0" width="300" height="300" />
					<g id="Table" transform="translate(280.000000, 467.000000)" fill="#918883">
						<g id="Table-">
							<g transform="translate(0.000000, 3.000000)" id="Overall-Rating">
								<g transform="translate(466.000000, 0.000000)">
									<g id="Icon/MorningstarLockup" transform="translate(0.000000, 257.000000)">
										<g id="Icon/Star">
											<g>
												<polygon id="Fill-1-Copy" points="7.5008726 0 5.72949389 5.348 0 5.348 4.63525305 8.65375 2.86387435 14 7.5008726 10.696 12.1343805 14 10.3630017 8.65375 15 5.348 9.26876091 5.348" />
											</g>
											<g transform="translate(18.206897, 0.000000)">
												<polygon id="Fill-1-Copy" points="7.5008726 0 5.72949389 5.348 0 5.348 4.63525305 8.65375 2.86387435 14 7.5008726 10.696 12.1343805 14 10.3630017 8.65375 15 5.348 9.26876091 5.348" />
											</g>
											<g transform="translate(54.620690, 0.000000)">
												<polygon id="Fill-1-Copy" points="7.5008726 0 5.72949389 5.348 0 5.348 4.63525305 8.65375 2.86387435 14 7.5008726 10.696 12.1343805 14 10.3630017 8.65375 15 5.348 9.26876091 5.348" />
											</g>
											<g transform="translate(37.425287, 0.000000)">
												<polygon id="Fill-1-Copy" points="7.5008726 0 5.72949389 5.348 0 5.348 4.63525305 8.65375 2.86387435 14 7.5008726 10.696 12.1343805 14 10.3630017 8.65375 15 5.348 9.26876091 5.348" />
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default FourStarRatingIcon;

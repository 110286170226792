import 'babel-polyfill';
import React from 'react';
import { PropTypes } from 'prop-types';

import * as urlMethods from '../../utils/urlMethods';
import * as format from '../../utils/format'
import * as CustomModalStyles from '../../utils/customModalStyles';
import * as ValidationMessages from '../../utils/validationMessages';

import SignInErrorMessageModal from "../common/modal/SignInErrorMessageModal";

import logoutStyle from '../../styles/components/Logout.scss';
import CheckIconGray from '../common/Icons/CheckIconGray';

class Logout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		}
	}

componentDidMount() {
	localStorage.setItem('logout-token', format.getQueryString("REF"));
		if (document.querySelector("meta[name='access_token']")) {
			document.querySelector("meta[name='access_token']").value = '';
		}
		localStorage.setItem('access_token',"");
		let isOpened = format.getQueryString("logoutError")
		this.setState({ isOpen: isOpened === "true" });
}


logoutUser = () => {
	const url = urlMethods.getEnvironment();
		const a = document.createElement('a');
		a.target = '_self';
		a.href = url;
		document.body.appendChild(a);
		a.click();
}

closeAlertModal = () => {
    this.setState({ isOpen: false });
  }

  openAlertModal = () => {
    this.setState({ isOpen: true });
  }

	render() {
		return (
			<div className={'logout-page'}>
				<div className={'logout-message-div'}>
					<div className={'logout-check-icon'}>
						<CheckIconGray />
					</div>
					<div className={'logout-message'}>Logout Successful</div>
					<div className={'logout-return-button'} onClick={this.logoutUser}>
						<button className={'logout-return-link'}>
							<a>
								<span className={'logout-return-main-text'} >RETURN TO IRIS</span>
								<span className={'logout-return-sub-text'}>
									SM
								</span></a>
						</button>
					</div>
					<SignInErrorMessageModal
						isOpen={this.state.isOpen}
						customStyles={CustomModalStyles.LoginErrorMessageModalStyles}
						closeAlertModal={this.closeAlertModal}
						openAlertModal={this.openAlertModal}
						errorMessage={ValidationMessages.errorHeaderLogoutMessage}
						requiredErrorHeader={ValidationMessages.errorHeaderLogout}
					/>
				</div>
			</div>
		)
	}
}

export default Logout;

import React from 'react';
import { PropTypes } from 'prop-types';

import BenchmarkResultsTable from './benchmarkResultsTable';
import { BenchmarkResults } from "../../../../utils/constants";

import closeSVG from "../../../../images/Icon/Close.svg";

export default class BenchmarksResults extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchItem: '',
			fifWsodIssue: '',
			totalBenchmarkResults: [], // containing non-blended + blended
		};
	}
	
	componentDidMount() {
		this.props.requestGetBenchmarksApi('',this.props.isInternationalUser ? 'yes' : 'no',(this.props.isInternationalUser && this.props.currency !=='USD') ? this.props.currency : 'USD' );
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.benchmarksResults) {
			const searchElem = document.getElementById('search-benchmark');
			const searchValue = searchElem ? searchElem.value.toLowerCase() : '';
			let filteredBenchmarks = nextProps.benchmarksResults;
			if (searchValue !== '') {
				filteredBenchmarks = nextProps.benchmarksResults.filter(function (item) {
					return item && item.name !== '' && (item.name.toLowerCase().indexOf(searchValue) > -1 || item.blendedBenchmarkNames.toLowerCase().indexOf(searchValue) > -1)
				})
			}
			this.setState({
				totalBenchmarkResults: nextProps.benchmarksResults,
				filteredBenchmarks: filteredBenchmarks,
			});
		}
	}

	// Returns blended and non-blended benchmarks combined
	getCombinedBenchmarks = (data) => {

		let combinedBenchmarks = []; // filtered benchmarks with search
		let nonblendedBenchmarks = data; // from API, in the end will have both non-blended and blended benchmarks
		let blendedBenchmarks = this.state.blendedBenchmarks; // from static json

		// Update non-blended benchmark with "blendedBenchmarkName" and add non-blended benchmark from API to combineBenchmarks
		nonblendedBenchmarks.forEach(function (elem) {
			elem.blendedBenchmarkNames = '';
			elem.xId=elem.xId.toString();
			combinedBenchmarks.push(elem);
		});

		// Add blended benchmark from static json to combineBenchmarks
		blendedBenchmarks.forEach(function (elem) {
			elem.blendedBenchmarkNames = '';
			elem.benchmarks.forEach(function (item) {
				elem.blendedBenchmarkNames += item.symbol + ", ";
				elem.xId = item.wsodissue + ",";
			});
			elem.blendedBenchmarkNames = elem.blendedBenchmarkNames.slice(0, -2);
			combinedBenchmarks.push(elem);
		});

		return combinedBenchmarks;
	}

	// When user key press, filter benchmarks
	handleKeyUp = (e) => { 

		let filteredBenchmarks = [];

		// Get all benchmarks 
		let combinedBenchmarks = this.state.totalBenchmarkResults;

		// Get the search term
		let searchValue = e.target.value;
		searchValue = searchValue ? searchValue.toLowerCase() : '';

		// If user search something then filter the results
		if (searchValue !== '') {
			filteredBenchmarks = combinedBenchmarks.filter(function (item) {
				return item && item.name !== '' && (item.name.toLowerCase().indexOf(searchValue) > -1 || item.blendedBenchmarkNames.toLowerCase().indexOf(searchValue) > -1)
			})
		} else {
			filteredBenchmarks = combinedBenchmarks;
		}

		// Update state and re-render
		this.setState({
			filteredBenchmarks: filteredBenchmarks
		})
	}

	// When user key press, filter benchmarks
	clearSearch = () => {
		const searchElem = document.getElementById('search-benchmark');
		if (searchElem) {
			searchElem.value = '';
			this.setState({
				totalBenchmarkResults: this.state.totalBenchmarkResults,
				filteredBenchmarks: this.state.totalBenchmarkResults,
			});
		}
	}

	render() {

		let cssClass = 'searchbox ';
		let data = this.state.filteredBenchmarks;

		return (
			<div className={cssClass}>
				<span className={'searchIcon'} />
				<div className={'searchBenchmarkText'}>
					<input
						autoComplete="off"
						type="text"
						id="search-benchmark"
						placeholder="Search by benchmark name"
						className={'search-text-input'}
						onKeyUp={this.handleKeyUp}
					/>
					<img src={closeSVG} onClick={this.clearSearch} />
				</div>
				<div>
					<BenchmarkResultsTable
						benchmarksResults={data}
						toggleCompareButton={this.props.toggleCompareButton}
					/>
				</div>
			</div>
		);
	}
}

BenchmarksResults.PropTypes = {
	toggleCompareButton: PropTypes.func,
	requestGetBenchmarksApi: PropTypes.func,
	requestGetPrimaryBenchmarkApi: PropTypes.func,
	getPrimaryProspectusBenchmarkData: PropTypes.func,
	isSelected: PropTypes.bool
};

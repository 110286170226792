export const getEnvironment = () => {
	const host = window.location.host;
	let redirectUrl = 'http://markit-dev';

	if (
		host.indexOf('mfs.markitondemand.com') > -1 ||
    host.indexOf('wd-appsp701.prod.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsp702.prod.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsp801.prod.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsp802.prod.mdgapp.net:3029') > -1
	) {
		redirectUrl = 'http://markit';
	} else if (
		host.indexOf('mfs.markitqa.com') > -1 ||
    host.indexOf('wd-appsa701.qa.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsa702.qa.mdgapp.net:3029') > -1
	) {
		redirectUrl = 'http://markit-dev';
	} else if (
		host.indexOf('mfs-a2.markitqa.com') > -1 ||
    host.indexOf('wd-appsa701.qa.mdgapp.net:3030') > -1 ||
    host.indexOf('wd-appsa702.qa.mdgapp.net:3030') > -1
	) {
		redirectUrl = 'http://markit-dev';
	} else if (host.indexOf('localhost') > -1) {
		redirectUrl = 'http://markit-dev';
	}
	return redirectUrl;
};

export const getAPiUrl = () => {
	const host = window.location.host;
	let redirectUrl = 'http://mfd-stage/';

	if (
		host.indexOf('mfs.markitondemand.com') > -1 ||
    host.indexOf('wd-appsp701.prod.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsp702.prod.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsp801.prod.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsp802.prod.mdgapp.net:3029') > -1
	) {
		redirectUrl = 'https://mfs.api.markitondemand.com/mfs/1.0/generatePDF?access_token=';
	} else if (
		host.indexOf('mfs.markitqa.com') > -1 ||
    host.indexOf('wd-appsa701.qa.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsa702.qa.mdgapp.net:3029') > -1
	) {
		redirectUrl = 'https://mfs.api.markitqa.com/mfs/1.0/generatePDF?access_token=';
	} else if (
		host.indexOf('mfs-a2.markitqa.com') > -1 ||
    host.indexOf('wd-appsa701.qa.mdgapp.net:3030') > -1 ||
    host.indexOf('wd-appsa702.qa.mdgapp.net:3030') > -1
	) {
		redirectUrl = 'https://mfs.api.markitqa.com/mfs/1.0/generatePDF?access_token=';
	} else if (host.indexOf('localhost') > -1 || host.indexOf('mfs.dev.services.mdgapp.net') > -1) {
		redirectUrl = 'http://ddt.dev.local/mfs-api/1.0/generatePDF?access_token=';
	}
	return redirectUrl;
};

export const getEnvironmentName = () => {
	const host = window.location.host;
	let redirectUrl = 'dev';

	if (
		host.indexOf('mfs.markitondemand.com') > -1 ||
    host.indexOf('wd-appsp701.prod.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsp702.prod.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsp801.prod.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsp802.prod.mdgapp.net:3029') > -1
	) {
		redirectUrl = 'prod';
	} else if (
		host.indexOf('mfs.markitqa.com') > -1 ||
    host.indexOf('wd-appsa701.qa.mdgapp.net:3029') > -1 ||
    host.indexOf('wd-appsa702.qa.mdgapp.net:3029') > -1
	) {
		redirectUrl = 'qa';
	} else if (
		host.indexOf('mfs-a2.markitqa.com') > -1 ||
    host.indexOf('wd-appsa701.qa.mdgapp.net:3030') > -1 ||
    host.indexOf('wd-appsa702.qa.mdgapp.net:3030') > -1
	) {
		redirectUrl = 'a2';
	} else if (host.indexOf('localhost') > -1 || host.indexOf('mfs.dev.services.mdgapp.net') > -1) {
		redirectUrl = 'dev';
	}
	return redirectUrl;
};
/**
 * Method used to get query string parameters
 * @param {A query string to parse} query
 */
export const getQueryStringParameters = query => {
	if (!query) return {}
  
	return (/^[?#]/.test(query) ? query.slice(1) : query)
	  .split('&')
	  .reduce((params, param) => {
		const [ key, value ] = param.split('=')
		params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
		return params
	  }, {})
  }
/**
 * Method used to determine whether debug is on
 */
export const isDebugOn = () =>
  getUrlParameter('..showdebuginfo..') === 'on'

  /**
 * Method used to get a query string parameter value
 * @param {A parameter key} key
 */
export const getUrlParameter = key => {
	if (!window.location.search) return undefined
  
	const params = getQueryStringParameters(window.location.search)
  
	if (!params || Object.keys(params).length < 1) return undefined
  
	return params[key]
  }
  
/* eslint-disable guard-for-in */
import "babel-polyfill";
import React from "react";
import { Link } from "react-router";
import * as Constants from "../../utils/constants";
import {getToken} from '../../utils/helpers'
import Header from "../common/Header";
import headerStyles from "../../styles/components/JumpPage.scss";
import * as urlMethods from "../../utils/urlMethods";


class jumpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {token: '',
    url: '',
	alertText: ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    const url = urlMethods.getEnvironmentName();
    if(url === 'prod'){ // restrict the jump page for prod
      this.props.router.push({
        pathname: '/Logout',
        search: '?logoutError=true'
      });
    }
   
  }
  handleChange(event) { this.setState({token: event.target.value}); }
  handleSubmit(event) {
	this.setState({
		alertText: ''
	});
    if(Constants.tafToken === this.state.token){
      getToken();
      this.setState({token: event.target.value});
      this.props.router.push('/Dashboard');
    } else {
		this.setState({
			alertText: 'Please enter valid key'
		});
	}
    event.preventDefault();
  }

  render() {
    return (
      <div className={"jumpPage"}>
      <form onSubmit={this.handleSubmit} >
        <label class="header-text">
          Jump Page
        </label>
        <label className={"label-text"}>
          Authentication Key:
        	<input type="text" value={this.state.token} className={'template-name-text'} onChange={this.handleChange} />
			<label className={"alert-text"}>{this.state.alertText}</label>
		</label>
        <input type="submit" class="submit-button" value="Submit" />
      </form>
      </div>
    );

  }
}

export default jumpPage;

import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';

class SelectTemplateWarningModal extends React.PureComponent {
	render() {
		let indexQuoteStyle = {
			'pointer-events': 'none',
			opacity: 0.4
		};
		if (!this.props.isSaveDisabled) {
			indexQuoteStyle = {};
		}

		return (
			<Modal
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				isOpen={this.props.templateWarningModalIsOpen}
				style={this.props.customStyles}
			>
				<div className={'saveModal selectTemplateModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'header-text'}>Portfolio Selection Overwrite</div>
							</div>
						</div>
						<div className={'searchMainModal'}>
							<div className={'lbl-errormessage'}>By selecting a new saved portfolio all funds associated with your previous saved portfolio selection will be removed. Click OK to continue. Click CANCEL to keep your previous selections</div>
						</div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<button
									onClick={this.props.closetemplateWarningModal}
									className={'cancel-button'}
								>
									CANCEL
								</button>
								<button
									onClick={this.props.replaceTemplateData}
									style={indexQuoteStyle}
									className={'save-button'}
								>
									OK
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

SelectTemplateWarningModal.propTypes = {
	templateWarningModalIsOpen: PropTypes.bool,
	customStyles: PropTypes.object,
	saveTemplateSettings: PropTypes.func,
	closetemplateWarningModal: PropTypes.func,
	handleInputChange: PropTypes.func,
	isSaveDisabled: PropTypes.bool,
	status: PropTypes.string,
	handleEnterClickOnTextbox: PropTypes.func,
	isPortfolio: PropTypes.bool
};

export default SelectTemplateWarningModal;

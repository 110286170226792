import React from 'react';

const closewhiteTooltipStyles = {
	height: '8px',
	width: '9px'
};

const CloseWhiteTooltipIcon = props => {
	return (
		<svg
			viewBox="0 0 9 9"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={closewhiteTooltipStyles}
		>
			<title>Delete</title>

			<defs />
			<g
				id="Icon/Close/White"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
			>
				<g id="Icon/Close" fill="#FCFCFC">
					<polygon
						id="Rectangle-11"
						points="8.20673295 4.54747351e-13 9 0.61263363 0.793267047 9 5.68434189e-13 8.38736637"
					/>
					<polygon
						id="Rectangle-11-Copy"
						points="0.793267047 4.54747351e-13 9 8.38736637 8.20673295 9 5.68434189e-13 0.61263363"
					/>
				</g>
			</g>
		</svg>
	);
};

export default CloseWhiteTooltipIcon;

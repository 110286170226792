import React from 'react';

import * as Constant from '../../../utils/constants';

import BenchmarkResultsTable from './benchmarkResultsTable';
import ResultHeader from './resultHeader';
import ResultSetTooLargeIcon from '../../common/Icons/ResultSetTooLargeIcon';
import { PropTypes } from 'prop-types';

import closeSVG from "../../../images/Icon/Close.svg";

export default class BenchmarksTab extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchItem: '',
			benchmarkResult: this.props.primaryBenchmarksResult,
			fifWsodIssue: '',
			filteredBenchmarksResults: this.props.benchmarksResults
		};
	}
	updateItem(item) {
		this.setState({
			_benchmarkResults: item
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.primaryBenchmarksResult !== this.props.primaryBenchmarksResult) {
			this.setState({
				benchmarkResult: nextProps.primaryBenchmarksResult,
				fifWsodIssue: (this.props.fundInFocus.length > 0) ? this.props.fundInFocus[0].wsodissue : ''
			});
		}
		if (this.props.benchmarksResults && nextProps.benchmarksResults) {
			this.setState({ filteredBenchmarksResults: nextProps.benchmarksResults },
				function () {
					this.updateItem(nextProps.benchmarksResults);
				}.bind(this));

		}
		if (nextProps.fundInFocus.length > 0) {
			if (nextProps.fundInFocus[0].wsodissue !== this.state.fifWsodIssue) {
				if (nextProps.isInternationalUser) {
					this.props.requestGetPrimaryBenchmarkApi(nextProps.fundInFocus[0].wsodissue, 'yes', nextProps.currency);
				} else {
					this.props.requestGetPrimaryBenchmarkApi(nextProps.fundInFocus[0].wsodissue, 'no', '');
				}
			}
		} else {
			const primaryBenchmarksResult = {
				primarybenchmarks: [{
					benchmarkXid: ''
				}]
			};
			this.setState({
				benchmarkResult: primaryBenchmarksResult,
				fifWsodIssue: ''
			});
		}

	}

	componentDidMount() {
		this.props.requestGetBenchmarksApi('', this.props.isInternationalUser ? 'yes' : 'no', this.props.isInternationalUser ? this.props.currency : 'USD');
		if (this.props.fundInFocus.length > 0) {
			if (this.props.isInternationalUser) {
				this.props.requestGetPrimaryBenchmarkApi(this.props.fundInFocus[0].wsodissue, 'yes', this.props.currency);
			} else {
				this.props.requestGetPrimaryBenchmarkApi(this.props.fundInFocus[0].wsodissue, 'no', '');
			}
			this.setState({ fifWsodIssue: this.props.fundInFocus[0].wsodissue });
		}
	}
	searchBenchmarksData = (e) => {
		this.props.requestGetBenchmarksApi(document.getElementById('search-benchmark').value.trim());
	}

	handleKeyUp = (e) => {
		let filteredBenchmarks = [];
		let allBenchmarks = this.props.benchmarksResults.benchmarks;
		let searchValue = e.target.value;
		searchValue = searchValue ? searchValue.toLowerCase() : '';
		if (searchValue !== '') {
			filteredBenchmarks = allBenchmarks.filter(function (item) {
				return (item && item.name !== '' && (item.name.toLowerCase().indexOf(searchValue) > -1));
			})
		} else {
			filteredBenchmarks = allBenchmarks;
		}
		this.setState({
			searchItem: searchValue,
			filteredBenchmarksResults: {
				benchmarks: filteredBenchmarks
			}
		})
	}

	clearSearch = () => {
		const searchElem = document.getElementById('search-benchmark');
		if (searchElem) {
			searchElem.value = '';
			this.setState({
				searchItem: '',
				filteredBenchmarksResults: this.props.benchmarksResults
			})
		}
	}

	render() {
		let cssClass = 'searchbox ';
		if (this.props.isSelected) {
			cssClass += 'display';
		} else {
			cssClass += 'no-display';
		}
		return (
			<div className={cssClass}>
				<span className={'searchIcon'} />
				<div className={'searchBenchmarkText'}>
					<input
						autoComplete="off"
						type="text"
						id="search-benchmark"
						placeholder="Search by benchmark name"
						className={'search-text-input'}
						onKeyUp={this.handleKeyUp}
					/>
					<img src={closeSVG} onClick={this.clearSearch} />
				</div>
				<div>
					<BenchmarkResultsTable
						toggleCompareButton={this.props.toggleCompareButton}
						fundInFocus={this.props.fundInFocus}
						benchmarksResults={this.state.filteredBenchmarksResults}
						requestGetBenchmarksApi={this.props.requestGetBenchmarksApi}
						primaryBenchmarksResult={this.state.benchmarkResult}
						getPrimaryProspectusBenchmarkData={this.props.getPrimaryProspectusBenchmarkData}>
					</BenchmarkResultsTable>
				</div>
			</div>
		);
	}
}

BenchmarksTab.PropTypes = {
	toggleCompareButton: PropTypes.func,
	requestGetBenchmarksApi: PropTypes.func,
	requestGetPrimaryBenchmarkApi: PropTypes.func,
	getPrimaryProspectusBenchmarkData: PropTypes.func,
	isSelected: PropTypes.bool
};

// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import showDebugRequestTransform from './showDebugTransforms/showDebugRequestTransform';

const create = (baseURL, accessToken) => {
	const api = apisauce.create({
		// base URL is read from the "constructor"
		baseURL,
		// here are some default headers
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${accessToken}`
		},
		// 10 second timeout...
		timeout: 120000
	});

	const getData = (endPoint, parameters) => api.get(endPoint, parameters);
	const postData = (endPoint, parameters) => api.post(endPoint, parameters);
	const putData = (endPoint, parameters) => api.put(endPoint, parameters);
	const deleteData = (endPoint, parameters) => api.delete(endPoint, parameters);
  	
  	api.addRequestTransform(showDebugRequestTransform);
	return {
		// a list of the API functions from step 2
		getData,
		postData,
		putData,
		deleteData
	};
};

// let's return back our create method as the default.
export default {
	create
};

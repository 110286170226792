import React, {useEffect} from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';

import genericModalStyles from '../../../styles/components/modal/replaceModal.scss';

function ReplaceTemplateModal(props) {
	
	useEffect(() => {
			window.setTimeout(function () {
				if(document.getElementsByClassName('replace-cancel-button').length> 0){
				document.getElementsByClassName('replace-cancel-button')[0].style.opacity = '1';
				document.getElementsByClassName('replace-cancel-button')[0].style['pointer-events'] = 'all';
				}
			}.bind(this),6000);
		});
		let cancelDisabled = {
				'pointer-events': 'none',
				opacity: 0.4
		}

		return (
			<Modal shouldCloseOnOverlayClick shouldCloseOnEsc
				isOpen={props.replaceModalIsOpen} style={props.customStyles} >
				<div className={'replaceModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'header-text'}> {(!props.isPortfolio) ? 'Replace Template' :  (props.isPortfolioCompare ? 'Replace Template' : 'Replace Portfolio')}</div>
							</div>
						</div>
						<div className={'lbl-replace-template'}> <span className={'lbl-replace-text'}> Do you want to replace "{props.templateName}" or do you want to create a new {!props.isPortfolio ? 'template' : (props.isPortfolioCompare ? 'template' : 'portfolio')}? </span></div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<button onClick={props.closeReplaceModal} 	style={cancelDisabled} className={'replace-cancel-button'}>CANCEL</button>
								<button onClick={props.openSaveModal} className={'save-as-button'}>SAVE AS NEW</button>
								<button onClick={props.saveTemplateSettings} className={'replace-button'}>REPLACE EXISTING</button>
							</div>
						</div>
					</div>
				</div>
			</Modal >

		);
}


export default ReplaceTemplateModal;


import * as format from '../utils/format'

const retrieve = localAccessToken => {
	// token from meta > token from local storage > local access token
	const accessTokenMeta = document.querySelector("meta[name='access_token']");
	const accessTokenFromMeta = accessTokenMeta ? accessTokenMeta.getAttribute('value') : null;
	const accessTokenFromLocalStorage = localStorage.getItem('access_token');
	const accessToken = accessTokenFromMeta || (accessTokenFromLocalStorage || localAccessToken || '');
	if ( localStorage.getItem('logout-token') === format.getQueryString("REF")) {
		return '';
	}
	return accessToken;
};

export default {
	retrieve
};

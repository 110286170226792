import { restrictedFundsIsin } from './constants'
import LocalApiService from '../../src/services/local-api-service';
import axios from 'axios';

export const getFormattedEtfFunds = (
    inputData
  ) => {
    let openfundedResults = [];
    let etfResults = [];
    if (inputData && inputData.length > 0) {
        inputData.forEach(item => {
            let keyItem = "";
            let isinItem = "";
            item.supplementalData.forEach(supplementalItem => {
                if (supplementalItem.name === "morningstarfundshareclassidsymbol") {
                    keyItem = supplementalItem.value;
                } else if (supplementalItem.name === "isin") {
                    if(restrictedFundsIsin.indexOf(supplementalItem.value) == -1){
                        isinItem = supplementalItem.value;
                    }
                }
            });
            if (keyItem !== "" && isinItem !== "") {
                if (item.classification && item.classification.name === "Fund" || item.classification.name === "Equity") {
                    let fundItemFormat = {key: keyItem, xid: item.xids.venue, xtype: "OF", mfsName: item.name, mfsSymbol: isinItem};
                    openfundedResults.push(fundItemFormat);
                } else if (item.classification && item.classification.name === "ETF") {
                    let etfItemFormat = {key: keyItem, xid: item.xids.venue, xtype: "ETF", mfsName: item.name, mfsSymbol: isinItem};
                    etfResults.push(etfItemFormat);
                }
            }
        });
    }

    return {"etfResults" : etfResults, "openfundedResults" : openfundedResults}
  }

  
  export const getToken = () => {     
    let token = localStorage.getItem('access_token');
    const data = 'grant_type=client_credentials';
     !token && axios.post(window.MD.tokenApiUrl, data,  {
        headers: {
          'Authorization': `Basic ${window.MD.auth}` 
        }
    }).then(response => {
      if (response.data.access_token) {
          token = response.data.access_token;
      }
      localStorage.setItem('access_token', token);
      localStorage.setItem('is_taf', true);
      })
      .catch(error => {
      console.log(error);
      });
      return token;
  };

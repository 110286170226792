export const ErrorMessageModalStyles = {
  content: {
    top: "30%",
    left: "15%",
    right: "15%",
    height: "230px",
    width: "352px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
  },
};

export const LoginErrorMessageModalStyles = {
  content: {
    top: "26%",
    left: "15%",
    right: "15%",
    height: "230px",
    width: "352px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
  },
};

export const LogOutModalStyles = {
  content: {
    top: "30%",
    left: "15%",
    right: "15%",
    height: "243px",
    width: "544px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
  },
};

export const ReplaceTemplateModalStyles = {
  content: {
    top: "25%",
    left: "15%",
    right: "15%",
    bottom: "12%",
    height: "235px",
    width: "544px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    overflow: "hidden",
  },
};

export const SaveModalStyles = {
  content: {
    top: "25%",
    left: "15%",
    right: "15%",
    bottom: "12%",
    height: "320px",
    width: "544px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
  },
};

export const ValidationModalStyles = {
  content: {
    top: "25%",
    left: "15%",
    right: "15%",
    bottom: "12%",
    height: "345px",
    width: "650px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
  },
};

export const SaveComparisonPortfolioModalStyles = {
  content: {
    top: "25%",
    left: "15%",
    right: "15%",
    bottom: "12%",
    height: "560px",
    width: "544px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
  },
};

export const SelectFundModalStyles = {
  content: {
    top: "0%",
    left: "5%",
    right: "5%",
    bottom: "10%",
    height: "768px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    "min-width": "1160px",
    overflow: "hidden",
  },
};

export const SelectPortfolioFundsModalStyles = {
  content: {
    top: "0%",
    left: "5%",
    right: "5%",
    bottom: "10%",
    height: "803px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    "min-width": "1160px",
    overflow: "hidden",
  },
};

export const SelectPortfolioFundsModalStylesWithBothSaveNReplace = {
  content: {
    top: "16%",
    left: "5%",
    right: "5%",
    bottom: "10%",
    height: "530px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    width: "544px",
    overflow: "hidden",
  },
};

export const SelectPortfolioFundsModalStylesWithNoSaveNReplace = {
  content: {
    top: "20%",
    left: "5%",
    right: "5%",
    bottom: "10%",
    height: "380px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    width: "544px",
    overflow: "hidden",
  },
};

export const SelectPortfolioFundsModalStylesWithReplaceNsimpleSave = {
  content: {
    top: "18%",
    left: "5%",
    right: "5%",
    bottom: "10%",
    height: "455px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    width: "544px",
    overflow: "hidden",
  },
};

export const SelectPortfolioFundsModalStylesWithReplaceNnoFunds = {
  content: {
    top: "20%",
    left: "5%",
    right: "5%",
    bottom: "10%",
    height: "393px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    width: "544px",
    overflow: "hidden",
  },
};
export const SelectPortfolioFundsModalStylesWithSimpleSaveNnoFunds = {
  content: {
    top: "25%",
    left: "5%",
    right: "5%",
    bottom: "10%",
    height: "320px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    width: "544px",
    overflow: "hidden",
  },
};

export const SaveAlertModalStyles = {
  content: {
    top: "30%",
    left: "15%",
    right: "15%",
    height: "258px",
    width: "544px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
  },
};

export const DeleteTemplateModalStyles = {
  content: {
    top: "25%",
    left: "15%",
    right: "15%",
    bottom: "12%",
    height: "285px",
    width: "510px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
  },
};

export const ScreenerFlyoutModalStyles = {
  content: {
    top: "6%",
    left: "42%",
    right: "0%",
    height: "773px",
    width: "720px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    overflow: "hidden",
  },
};

export const EventFlyoutModalStyles = {
  content: {
    top: "6%",
    left: "42%",
    right: "0%",
    height: "773px",
    width: "720px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    overflow: "hidden",
  },
};

export const EventPortFlyoutModalStyles = {
  content: {
    top: "6%",
    left: "34%",
    right: "0%",
    height: "773px",
    width: "810px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    overflow: "hidden",
  },
};

export const FeeScheduleModalStyles = {
  content: {
    top: "25%",
    left: "15%",
    right: "15%",
    bottom: "12%",
    height: "350px",
    width: "544px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    overflow: "unset",
  },
};

export const BenchmarkModalStyles = {
  content: {
    top: "25%",
    left: "15%",
    right: "15%",
    bottom: "12%",
    height: "262px",
    width: "650px",
    padding: 0,
    boxShadow: "0 1px 13px 4px #d0cfce",
    overflow: "hidden",
  },
};

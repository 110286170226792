import React from 'react';

const AddIcon = props => {
	return (
		<span role="presentation" className={'add'} >
			<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20" version="1.1"
				style={props.style}
			>
				<g id="Icon/Add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="Group-16-Copy-14">
						<g id="Group" transform="translate(5.000000, 5.000000)" fill="#7A0022">
							<g id="Group-15">
								<rect id="Rectangle-12" x="4" y="0" width="2" height="10" />
								<rect id="Rectangle-12-Copy" transform="translate(5.000000, 5.000000) rotate(90.000000) translate(-5.000000, -5.000000) " x="4" y="0" width="2" height="10" />
							</g>
						</g>
						<circle id="Oval" stroke="#7A0022" strokeWidth="2" cx="10" cy="10" r="9" />
					</g>
				</g>
			</svg>
		</span >
	);
};

export default AddIcon;

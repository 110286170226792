import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';
import * as Constant from "../../../utils/constants.js";

import genericModalStyles from '../../../styles/components/modal/SaveChangesAlertModal.scss';


class EventPortfolioChangeAlertModal extends React.PureComponent {
	render() {
		return (
			<Modal shouldCloseOnOverlayClick shouldCloseOnEsc
				isOpen={this.props.isAlertModalOpen} style={this.props.customStyles} >
				<div className={'SaveChangesAlertModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'header-text'}>Alert</div>
							</div>
						</div>
						<div className={'lbl-alert-template'}> {(this.props.isInternationalUser === true) ? Constant.alertEventIntPortMessage : Constant.alertEventPortMessage }</div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<button onClick={this.props.cancelEventValidation} className={'alert-cancel-button'}>CANCEL</button>
								<button onClick={this.props.confirmToChangePortfolio} className={'alert-save-button'}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>

		);
	}
}

EventPortfolioChangeAlertModal.propTypes = {
	isAlertModalOpen: PropTypes.bool,
	customStyles: PropTypes.object,
	cancelEventValidation: PropTypes.func,	
	confirmToChangePortfolio: PropTypes.func,
	isInternationalUser: PropTypes.bool
};

export default EventPortfolioChangeAlertModal;


/* eslint-disable guard-for-in */
import "babel-polyfill";
import React from "react";
import { Link } from "react-router";

import xmlToJSON from "xmljson";
import { cloneDeep } from "lodash";

import * as urlMethods from "../../../utils/urlMethods";
import * as UsageReporting from "../../../utils/usageReporting";
import * as CustomModalStyles from '../../../utils/customModalStyles';
import * as utilityFunctions from "../../../utils/commonMethods";
import * as Format from "../../../utils/format";
import * as Constant from "../../../utils/constants";

import Header from "../../common/Header";
import LogoutModal from "../../common/modal/LogoutModal";
import Tabs from "../../common/subcomponents/Tabs";
import DownloadingSpinnerModal from "../../common/modal/DownloadingSpinnerModal";
import DeleteTemplateModal from "../../common/modal/DeleteTemplateModal";
import DeleteConfirmationModal from "../../common/modal/DeleteConfirmationModal";

import SelectPortfolioFundsModal from "../selectPortfolioFund/Container";
import { reportTypes,portfolioLandingPageTabs } from "../../../utils/constants";

import closeSVG from "../../../images/Icon/Close.svg";

import headerStyles from "../../../styles/components/PortfolioPage.scss";

class PortfolioPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserLoggedOut: false,
      downloadSpinnerModalIsOpen: false,
      isGeneratePDF: false,
      isMarketingPage: true,
      modalIsOpen: false,
      modalIsPortfolioCompare: false,
      containerId: "",
      reportId: "",
      erroMessage: "",
      showAddAllTooltip: true,
      isShowBenchmarkResults : false,
      isResetDropdowns:false,
      comparisonPortfolioSavedBenchmarkResult:{}, //used on Use this template to populate Benchmak
      editComparisonTemplateId:-1,
      editComparisonTemplateName:'',
      comparisonContainerIds:'',
      comparsionWorkingContainerIds:'',
      comparisonPortfolioBalanceInitial: 100000,
      //Tabs
      tabsList: [
        portfolioLandingPageTabs.comparisonportfolio,
        portfolioLandingPageTabs.singleportfolio        
      ],
      selectedTab: portfolioLandingPageTabs.comparisonportfolio,
      fundShareclassId: ""
    };
    this.options = {
      defaultSortName: "lastUpdated", // default sort column name
      defaultSortOrder: "desc" // default sort order
    };
  }

  UNSAFE_componentWillMount() {
    let _templateData = cloneDeep(Constant.portfolioTemplateResult);    
    if (this.props.userType.response === 'International') {
      _templateData.language = 'en-GB';
    }
    this.setState({
      templateData: _templateData
    });
    try {
      UsageReporting.trackPage("Portfolio Page", "");
    } catch (error) { }
  }
  callMaintenacePage = () => {
    this.props.router.push('/MaintenancePage');
  }
  handleDeleteClick = (id) => {
    this.props.requestMfsSiteInactiveFlag();
    this.props.updateTemplateInternalDetails({
      templateId: id,
      templateName: ""
    });

    this.setState({
      deleteModalIsOpen: true
    });
  }
  componentDidMount() {
    if(window.location.href.toLowerCase().indexOf("international") > -1){
      this.onClickInternational();
    } else {
      this.props.requestGetTemplatesApi(reportTypes.portfoliocomparison,0);
      this.props.requestGetTemplatesApi(reportTypes.singleportfolio);
      //Handle which tab is to be opened like when opening first time or 
    //coming from single save confirmation modal or coming from comparison portfolio save
    this.setSelectedSingleOrPortCompareTab();
    }
    this.props.requestMfsSiteInactiveFlag();
    
        
    this.props.requestMfsUserData();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.portfolioContainerIds != nextProps.portfolioContainerIds && (nextProps.portfolioContainerIds != '')) {
      this.setState({comparisonContainerIds: nextProps.portfolioContainerIds});
    }
    if(nextProps.mfsSiteInactive === true){
      this.props.router.push('/MaintenancePage');
    }else if(this.props.userType.response === 'International' && nextProps.mfsMILPvPinactive){
      this.props.router.push('/');
    }else if (nextProps.mfsUSPvPinactive && ! (this.props.userType.response === 'International')){
      this.props.router.push('/');
    }
    if (this.props.errorCode != nextProps.errorCode && nextProps.errorCode) {
      this.props.router.push({
        pathname: '/Logout',
        search: '?logoutError=true'
      });
    }
    if (this.props.template !== nextProps.template) {
      this.cwrpTemplateDataBind(nextProps);
    }
    if (this.props.deleteTemplateDetails !== nextProps.deleteTemplateDetails) {
      if (nextProps.deleteTemplateDetails.success === 1) {
        // Repopulate template again
        let templateToLoad = this.state.selectedTab == portfolioLandingPageTabs.singleportfolio ? reportTypes.singleportfolio : reportTypes.portfoliocomparison;
        if(this.props.userType.response === 'International') {
          templateToLoad = this.state.selectedTab == portfolioLandingPageTabs.singleportfolio ? reportTypes.mil_singleportfolio : reportTypes.mil_portfoliocomparison;
        }

        this.props.requestGetTemplatesApi((templateToLoad),0);

        this.setState({
          deleteConfirmationModalIsOpen: true,
          deleteModalIsOpen: false
        });
        setTimeout(() => {
          this.setState({
            deleteConfirmationModalIsOpen: false
          });
        }, 3000);
      } else {
        this.setState({
          statusMessage: nextProps.deleteTemplateDetails.resultOutput.returnMessage,
          erroMessage: "We are sorry, template is not deleted. Please try later..."
        });
      }
    }
    /// for generate PDF output :
    if (nextProps.pdfOutput !== null && nextProps.pdfOutput !== undefined && nextProps.pdfOutput.pdfUrl !== '') {
      const source = nextProps.pdfOutput.pdfUrl;
      const outputObject = source !== null ? utilityFunctions.convertEncodedStringtoPDF(source) : '';

      if (outputObject !== null) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE specific
          this.setState({ downloadSpinnerModalIsOpen: false });
          window.navigator.msSaveOrOpenBlob(outputObject.blob);
        } else { //for all other browsers
          this.setState({ downloadSpinnerModalIsOpen: false });
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = outputObject.dataUrl;
          a.target = '_blank';
          a.click();
        }
      }

      nextProps.pdfOutput.pdfUrl = '';
    } else {
      if (nextProps.pdfOutput && nextProps.pdfOutput.errorCode === 400) {
        this.setState({ downloadSpinnerModalIsOpen: false });
      }
    }
  }
  workingCopyContainerId = (containerId) => {
    this.setState({comparsionWorkingContainerIds: containerId});
  }
  updateBalanceInitial = (balanceInitial) => {
    this.setState({comparisonPortfolioBalanceInitial: balanceInitial});
  }
  cwrpTemplateDataBind(nextProps) {
    if(this.state.modalIsPortfolioCompare) {
      this.setState({comparisonContainerIds: nextProps.template[0].containerId});
    }

    /**
     * When user click on "Use this template" link in PvP then get the new 
     * containerIds and use them in further workflow. This is done so that hypothetical 
     * tabs don't show previously saved data.
     */
    if(this.state.modalIsPortfolioCompare && nextProps.TemplateId > 0) {
      this.props.requestGetPortfoioContainerIdsApi();
    }

    xmlToJSON.to_json(`<data>${nextProps.template[0].xmlData}</data>`, (error, data) => {
      if(this.state.modalIsPortfolioCompare) {
        this.setState({comparisonPortfolioBalanceInitial:data.balanceInitial});
      }
      const newTemplateData = cloneDeep(data);
      let funds = [];
      let portfolios = [];
      if (this.state.modalIsPortfolioCompare) {
        
        newTemplateData.Portfolios = [{Portfolio: []},{Portfolio: []}];
        
        this.setState({ comparisonPortfolioSavedBenchmarkResult: data.benchmark });
      }
      if (!this.state.modalIsPortfolioCompare) {
        portfolios = data.Portfolios[0] ? data.Portfolios[0].Portfolio :
          data.Portfolios.Portfolio;
        if (portfolios[0] && portfolios[0].symbol !== undefined) {
          for (const index in portfolios) {
            const fund = portfolios[index];
            funds.push(fund);
          }
        } else if (portfolios.symbol !== undefined) {
          funds.push(portfolios);
        }
        newTemplateData.Portfolios[0].Portfolio = funds;
      }
      let section = data.sections ? Object.values(data.sections) : [];
      let selectedLeftRail = [];
      let finalArray = {
        sections: []
      };
      if (section && section.length > 0) {
        let sectionMasterValues = cloneDeep(this.props.userType.response === 'International' ? Constant.portfolioTemplateDataIntl.sections : Constant.portfolioTemplateData.sections);
        Object.keys(section).forEach(function (key) {
          sectionMasterValues.forEach(element => {
            if (element.sectionname === section[key].sectionname &&
              section[key].isActive === "true") {
              element.isActive = section[key].isActive === "true";
              const inputType = [];
              inputType.push(section[key].inputtype);
              section[key].isActive = section[key].isActive === "true"; // only pick active status from saved template
              section[key].inputtype = inputType; //loading it as it is from saved template
              section[key].isLocked = element.isLocked; //loading it from master config
              section[key].canbeAddedAndLocked = element.canbeAddedAndLocked; //loading it from master config
              section[key].sectionid = element.sectionid; //loading it from master config
              //updating new display name in case template is saved with old display name
              section[key].sectiondisplayname = element.sectiondisplayname;
              let matchedsection = section[key];
              //looping through subsections.....
              let _configSubSections = element.subsections; //subsections in master config
              //subsections saved in DB
              let _savedSubSections = Object.values(matchedsection.subsections);
              _configSubSections.forEach((subsection, configsubsectionindex) => {
                let matchedsubsection = _savedSubSections.find(x => x.name === subsection.name);
                let _subsectionIndex = _savedSubSections.findIndex(x => x.name === subsection.name);
                if (_subsectionIndex != -1) {
                  _savedSubSections[_subsectionIndex].isActive =
                    matchedsubsection &&
                    matchedsubsection.isActive === "true";
                  _savedSubSections[_subsectionIndex].isLocked =
                    subsection.isLocked; //reading it from master config
                  _savedSubSections[_subsectionIndex].canbeAddedAndLocked = subsection.canbeAddedAndLocked; //reading it from master config
                  _savedSubSections[_subsectionIndex].id = subsection.id; //reading it from master config
                  _savedSubSections[_subsectionIndex].subsectiondisplayname = subsection.subsectiondisplayname; //reading it from master config
                } else {
                  //get elements which are not selected from master config..
                  _savedSubSections.splice(configsubsectionindex, 0, subsection);
                }
                section[key].subsections = _savedSubSections;
              });
              selectedLeftRail.push(section[key]); //contains only active sections and subsections
            }
          });
        });
        finalArray.sections.unshift(selectedLeftRail); //insert all active elements to the array at position 0
        let filtered = sectionMasterValues.filter(function (item) {
          //get elements which are not selected from master config..
          return (!selectedLeftRail.includes(item.sectionname) &&
            item.isActive === false);
        });
        filtered.forEach(x => finalArray.sections[0].push(x));
        newTemplateData.sections = finalArray.sections[0];
      } else {
        newTemplateData.sections =
        (this.props.userType.response === 'International' ? Constant.sectionMasterDataIntl.sections :Constant.sectionMasterData.sections);
      }
      newTemplateData.isMarketingPage = data.isMarketingPage ?
        data.isMarketingPage === "true" :
        false;
        this.setState({
          isMarketingPage:  newTemplateData.isMarketingPage
        });
      newTemplateData.currency = data.currency ? data.currency : "USD";
      newTemplateData.language = data.language ? data.language : "en-GB";
      if (this.state.modalIsPortfolioCompare) {
        if (newTemplateData.Portfolios[0] && !newTemplateData.Portfolios[0].hasOwnProperty('Portfolio')) {
          newTemplateData.Portfolios[0].Portfolio = [];
        }
        if (newTemplateData.Portfolios[1] && !newTemplateData.Portfolios[1].hasOwnProperty('Portfolio')) {
          newTemplateData.Portfolios[1].Portfolio = [];
        }
      }
      newTemplateData.id = utilityFunctions.getRandomNumber();
      if (this.state.isGeneratePDF) {
        const inputParams = {
          templateData: JSON.stringify(newTemplateData),
          containerId: this.state.containerId,
          reportId: this.state.reportId
        };
        this.props.requestGeneratePDFApi(inputParams);
        this.setState({
          isGeneratePDF: false
        });
      } else {
        this.setState({
          templateData: newTemplateData
        });
      }
    });
  }

  openDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: true
    });
  }

  openDeleteConfirmationModal = () => {    
    this.props.requestDeleteTemplateApi(this.props.TemplateId);
  }
  closeDeleteConfirmationModal() {
    this.setState({
      deleteConfirmationModalIsOpen: false
    });
  }
  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false
    }); //Reset templateId to 0,if cancelling Delete action
  }

  resetComparePortfolios = () => {
    this.props.updateTemplateInternalDetails({
      templateId: 0,
      templateName: "",
      resetFlag: true
    });
    this.props.updatePortfolioTemplateInternalDetails({
      templateId: 0,
      templateName: "",
      resetFlag: true
    });
  }

  logOut = () => {
    this.setState({
      isUserLoggedOut: true
    });
  }

  logoutUser = () => {
    localStorage.setItem("logout-token", localStorage.getItem("access_token"));
    if (document.querySelector("meta[name='access_token']")) {
      document.querySelector("meta[name='access_token']").value = "";
    }
    localStorage.removeItem("access_token");
    localStorage.setItem("loggedOut", true);
    const url = urlMethods.getEnvironment();
    const a = document.createElement("a");
    a.target = "_self";
    a.href = url;
    document.body.appendChild(a);
    a.click();
  }

  closeLogOutModal = () => {
    this.setState({
      isUserLoggedOut: false
    });
  }

  openModal = (e) => {
    this.props.requestMfsSiteInactiveFlag();
    this.resetComparePortfolios();
    if (e.currentTarget.getAttribute('data-IsPortfolioCompare') === 'true') {
      this.props.requestGetPortfoioContainerIdsApi();
    }
    
    this.setState({
      modalIsOpen: true,
      modalIsPortfolioCompare: (e.currentTarget.getAttribute('data-IsPortfolioCompare') === 'true'),
      showAddAllTooltip: true,
      isShowBenchmarkResults: false,
      isResetDropdowns: true,
      resetPills: true,
      showHypotheticals: false,
      comparisonPortfolioBalanceInitial: 100000
    });
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      showAddAllTooltip: false,
      isShowBenchmarkResults : false,
      isResetDropdowns: false,
      resetPills:true,
      comparisonPortfolioSavedBenchmarkResult:{},
      editComparisonTemplateId: -1,
      editComparisonTemplateName: '',
      showHypotheticals: false,
      comparisonPortfolioBalanceInitial: 100000,
      sceenerOutputFromModule:{}
    });
    if(this.props.userType.response === 'International') {
      this.props.requestGetTemplatesApi(reportTypes.mil_portfoliocomparison,0);
      this.props.requestGetTemplatesApi(reportTypes.mil_singleportfolio);
    } else {
      this.props.requestGetTemplatesApi(reportTypes.portfoliocomparison,0);
      this.props.requestGetTemplatesApi(reportTypes.singleportfolio);
    }
    this.props.updatePortfolioTemplateInternalDetails({
      templateId: 0,
      templateName: "",
      resetFlag: true
    });
    
  }
  showBenchmarksModal = () => {
    this.setState({
      isShowBenchmarkResults : true,
      showHypotheticals : false,
      resetPills:false
    });
  }
  showHypotheticalsModal = () => {
    this.setState({
      showHypotheticals : true,
      isShowBenchmarkResults : false,
      resetPills:false
    });
  }
  loadTabUI = (e) => {
    this.props.requestMfsSiteInactiveFlag();
    if (this.state.selectedTab !== e.target.innerText) {
      //Which templates to be loaded
      let templateToLoad = e.target.innerText == portfolioLandingPageTabs.singleportfolio ? reportTypes.singleportfolio : reportTypes.portfoliocomparison;
      if(this.props.userType.response === 'International') {
        templateToLoad = e.target.innerText == portfolioLandingPageTabs.singleportfolio ? reportTypes.mil_singleportfolio : reportTypes.mil_portfoliocomparison;
      }

      this.setState({
        selectedTab: e.target.innerText
      }, () => {
        (() => this.props.requestGetTemplatesApi((templateToLoad),0))()
      });
    }
  }
  formatDate(cell, row) {   
    return Format.dateMonthDateYear(cell);
  }
  formatIntlDate(cell, row) {    
    return Format.dtDateMonthYear(cell);
    }

  handleEditPortfolioClick(row) {
    this.props.requestMfsSiteInactiveFlag();
    if (this.state.selectedTab == portfolioLandingPageTabs.singleportfolio) {
      this.props.requestGetTemplatesByIdApi(row.templateId, (this.props.userType.response === 'International') ? reportTypes.mil_singleportfolio : reportTypes.singleportfolio);
      this.setState({
        modalIsOpen: true,
        containerId: row.containerId,
        reportId: row.reportId,
        startNewFundComparison: false,
        modalIsPortfolioCompare: false
      });
    } else if (this.state.selectedTab == portfolioLandingPageTabs.comparisonportfolio) {
      this.props.requestGetTemplatesByIdApi(row.templateId, (this.props.userType.response === 'International') ? reportTypes.mil_portfoliocomparison : reportTypes.portfoliocomparison);
      this.setState({
        modalIsOpen: true,
        modalIsPortfolioCompare:true,
        containerId: row.containerId,
        reportId: row.reportId,
        showAddAllTooltip: true,
        isShowBenchmarkResults : false,
        isResetDropdowns: true,
        resetPills:true,
        editComparisonTemplateId:row.templateId,
        editComparisonTemplateName:row.templateName
      });
    }

  }
  editPortfolioLink = (cell, row) => {
    return (
      <a
        onClick={() => this.handleEditPortfolioClick(row)}
        className={"link"}
      >
        {this.state.selectedTab == portfolioLandingPageTabs.singleportfolio ? 'Edit Portfolio' : 'Use This Template'}
			</a>
    );
  }

  deleteTemplateButton = (cell, row) => {
    return (
      <div
        id={`template-id-${cell}`}
        onClick={() => this.handleDeleteClick(cell)}
        className={"closeButton"}
      >
        <img src={closeSVG} />{" "}
      </div>
    );
  }
  onClickInternational = () => {
    this.props.setUserType("International");
    this.props.requestGetTemplatesApi(reportTypes.mil_portfoliocomparison,0);
    this.props.requestGetTemplatesApi(reportTypes.mil_singleportfolio);
    this.setSelectedSingleOrPortCompareTab();
  }

  setSelectedSingleOrPortCompareTab = () => {
    if (this.props.params && this.props.params.type === 'compare') {
      this.setState({ selectedTab: portfolioLandingPageTabs.comparisonportfolio });
    } else if (this.props.params && this.props.params.type === 'single') {
      this.setState({ selectedTab: portfolioLandingPageTabs.singleportfolio });
    } else {
      this.setState({ selectedTab: portfolioLandingPageTabs.comparisonportfolio });
    }
  }
  onClickNonInternational = () => {
    this.props.setUserType("");
    this.props.requestGetTemplatesApi(reportTypes.portfoliocomparison,0);
    this.props.requestGetTemplatesApi(reportTypes.singleportfolio);
    this.setSelectedSingleOrPortCompareTab();
  }

  setFundShareclassId = (argFundShareclassId) => {
    this.setState({ fundShareclassId: argFundShareclassId });
  }

  render() {
    return (
      <div className={"homePage"}>
        <Header onClickInternational={this.onClickInternational} 
        onClickNonInternational={this.onClickNonInternational} 
        resetComparePortfolios={this.resetComparePortfolios} 
        logOut={this.logOut} activeTab={this.props.userType.response === 'International' ? 4 :2} 
        mfsUserData={this.props.mfsUserData} 
        mfsUSFvFinactive = {this.props.mfsUSFvFinactive} 
        mfsUSPvPinactive = {this.props.mfsUSPvPinactive} 
        mfsMILFvFinactive = {this.props.mfsMILFvFinactive}
        mfsMILPvPinactive = {this.props.mfsMILPvPinactive}/>
        <LogoutModal
          isUserLoggedOut={this.state.isUserLoggedOut}
          customStyles={CustomModalStyles.LogOutModalStyles}
          logoutUser={this.logoutUser}
          closeLogOutModal={this.closeLogOutModal}
        />
        <div className={"body"}>
          <div className={"bodyContainer"}>
            <div className={"sub-HeaderModule"}>
              <label className={"header-text"}>My {this.props.userType.response === 'International' ? 'International' : ''} Portfolio Library </label>
              <button
                onClick={this.openModal}
                className={"btn-portfolio portfolio-comparison"}
                data-isPortfolioCompare='true'
              >
                <Link className={"button-text"}>
                  START NEW PORTFOLIO COMPARISON
                </Link>
              </button>

              <button
                onClick={this.openModal}
                className={"btn-portfolio portfolio-build-new"}
                data-isPortfolioCompare='false'
              >
                <Link className={"button-text"}>
                  BUILD NEW PORTFOLIO
                </Link>
              </button>
              <SelectPortfolioFundsModal
                modalIsOpen={this.state.modalIsOpen}
                modalIsPortfolioCompare={this.state.modalIsPortfolioCompare}
                containerId={this.state.containerId}
                reportId={this.state.reportId}
                templateData={this.state.templateData}
                closeModal={this.closeModal}
                startNewFundComparison={this.state.startNewFundComparison}
                showAddAllTooltip={this.state.showAddAllTooltip}
                portfolioNamesList={this.props.portfolios}
                isShowBenchmarkResults= {this.state.isShowBenchmarkResults}
                showBenchmarksModal={this.showBenchmarksModal}
                isResetDropdowns={this.state.isResetDropdowns}
                resetPills={this.state.resetPills}
                comparisonPortfolioBenchmarkResult={this.state.comparisonPortfolioSavedBenchmarkResult}
                editComparisonTemplateId ={this.state.editComparisonTemplateId}
                editComparisonTemplateName={this.state.editComparisonTemplateName}
                showHypotheticals = {this.state.showHypotheticals}
                showHypotheticalsModal = {this.showHypotheticalsModal}
                comparisonContainerIds = {this.state.comparisonContainerIds}
                workingCopyContainerId={this.workingCopyContainerId}
                comparsionWorkingContainerIds = {this.state.comparsionWorkingContainerIds}
                balanceInitial = {this.state.comparisonPortfolioBalanceInitial}
                updateBalanceInitial={this.updateBalanceInitial}
                sceenerOutputFromModule={this.state.sceenerOutputFromModule}
                callMaintenace={this.callMaintenacePage}
                fundShareclassId={this.state.fundShareclassId}
                setFundShareclassId={this.setFundShareclassId}
                isMarketingPage ={this.state.isMarketingPage}
              />
              <DeleteTemplateModal
                erroMessage={this.state.erroMessage}
                closeDeleteModal={this.closeDeleteModal}
                customStyles={CustomModalStyles.DeleteTemplateModalStyles}
                deleteHandleClick={this.openDeleteConfirmationModal}
                deleteModalIsOpen={this.state.deleteModalIsOpen}
                isPortfolio={this.state.selectedTab == portfolioLandingPageTabs.singleportfolio}
              />
              <DeleteConfirmationModal
                deleteConfirmationModalIsOpen={
                  this.state.deleteConfirmationModalIsOpen
                }
                closeDeleteConfirmationModal={this.closeDeleteConfirmationModal}
                deleteConfirmationMessage={this.state.selectedTab == portfolioLandingPageTabs.singleportfolio?'Portfolio Deleted!' : 'Template Deleted!'}
              />
              <DownloadingSpinnerModal
                downloadSpinnerModalIsOpen={
                  this.state.downloadSpinnerModalIsOpen
                }
              />

            </div>
          </div>
        </div>
        <div className={"tableData tableStyle"}>
          <Tabs
            tabsList={this.state.tabsList}
            onClick={this.loadTabUI}
            selectedTab={this.state.selectedTab}
            isPortfolio={true}
          />
        </div>
        <div className={"tableData tableStyle"}>
          {this.state.selectedTab == portfolioLandingPageTabs.singleportfolio ?
          (<BootstrapTable
            data={((this.props.portfolios != null) && this.state.selectedTab == portfolioLandingPageTabs.singleportfolio) ? this.props.portfolios : []}
            striped={false}
            hover={false}
            options={this.options}
            tableStyle={{ width: "100%" }}
          >
            <TableHeaderColumn
              isKey
              dataField="templateName"
              columnTitle={true}
              dataSort
              width="18%"
            >
              Portfolio Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="description" dataSort width="35%" columnTitle>
              Description
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="lastUpdated"
              dataFormat= {(this.props.userType.response === 'International') ? this.formatIntlDate : this.formatDate}
              dataSort
              width="17%"
            >
              Last Updated
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="templateId"
              dataFormat={this.editPortfolioLink}
              width="10%"
            />
            <TableHeaderColumn
              dataField="templateId"
              dataFormat={this.deleteTemplateButton}
              width="5%"
            />
          </BootstrapTable>)
          : 
          (<BootstrapTable
            data={((this.props.comparisonPortfolios != null) && this.state.selectedTab === 'My Report Templates') ? this.props.comparisonPortfolios : []}
            striped={false}
            hover={false}
            options={this.options}
            tableStyle={{ width: "100%" }}
          >
            <TableHeaderColumn
              isKey
              dataField="templateName"
              columnTitle={true}
              dataSort
              width="18%"
            >
              Report Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="description" dataSort width="35%" columnTitle>
              Description
            </TableHeaderColumn>
            
            <TableHeaderColumn
              dataField="language"              
              dataSort
              width="15%"
              hidden ={this.props.userType.response === 'International' ? false : true}
            >
              Language
            </TableHeaderColumn>
            
            <TableHeaderColumn
              dataField="lastUpdated"
              dataFormat={(this.props.userType.response === 'International') ? this.formatIntlDate : this.formatDate}
              dataSort
              width="17%"
            >
              Last Updated
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="templateId"
              dataFormat={this.editPortfolioLink}
              width="10%"
            />
            <TableHeaderColumn
              dataField="templateId"
              dataFormat={this.deleteTemplateButton}
              width="5%"
            />
          </BootstrapTable>)
          }
        </div>        
      </div>
    );
  }
}

export default PortfolioPage;
import React from 'react';
import { PropTypes } from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import fundResultTableStyles from '../../../styles/components/selectFundComparison/BenchmarkResultsTable.scss';

import CompareButtonIcon from '../../common/Icons/CompareButtonIcon';
import PrimaryProspectus from '../../common/Icons/PrimaryProspectusBenchmarkRibbonIcon';

class BenchmarkResultsTable extends React.Component {
	imageFormatter(cell, row) {
		return `${PrimaryProspectus}Primary Prospactus Benchmark`;
	}
	comparebutton = (cell, row) => {
		const html = (
			<div
				className={'add-icon'}
				onClick={this.props.toggleCompareButton}
				data-flag={'Benchmark'}
				data-symbol={row.name}
				data-wsodIssue={row.wsod}
			>
				<CompareButtonIcon />
			</div>
		);

		return html;
	}

	headerColumnClassNameFormat() {
		return 'tdcompareStyle';
	}

	getBenchmarkWithPP = (cell, row) => {
		const primaryBenchmarkName = (this.props.primaryBenchmarksResult && this.props.primaryBenchmarksResult.primarybenchmarks.success === undefined) ? this.props.primaryBenchmarksResult.primarybenchmarks[0].benchmarkName : '';
		return (row.name === primaryBenchmarkName) ?
			<div>
				<div className={'benchmark'}>{row.name}</div>
				<div className={'float-right'}><PrimaryProspectus />
					<span className={'primary-prospectus-benchmark'}>Primary Prospectus Benchmark </span>
				</div>
			</div>
			: <div>{row.name}</div>;
	}

	render() {
		const data = [];
		if (this.props.benchmarksResults && this.props.benchmarksResults.benchmarks &&
			this.props.benchmarksResults.benchmarks.length > 0) {
			let allBenchmarks = this.props.benchmarksResults.benchmarks;
			let otherBenchmarks = [];
			const primaryBenchmarkName = (this.props.primaryBenchmarksResult && this.props.primaryBenchmarksResult.primarybenchmarks.success === undefined) ? this.props.primaryBenchmarksResult.primarybenchmarks[0].benchmarkName : '';
			if (primaryBenchmarkName && primaryBenchmarkName !== '') {
				otherBenchmarks = allBenchmarks.filter(val => (val.name) !== primaryBenchmarkName);//TODO add logic to exclude primary benchmark from result set
				const primaryBenchmark = allBenchmarks.find(obj => { return (obj.name) === primaryBenchmarkName; });
				if (primaryBenchmark) {
					//To push data to top most parent - primary prospectus benchmark data
					this.props.getPrimaryProspectusBenchmarkData({ name: primaryBenchmark.name, wsod: primaryBenchmark.xId });
					data.push({ name: primaryBenchmark.name, wsod: primaryBenchmark.xId });
				}
				allBenchmarks = otherBenchmarks;
			}
			allBenchmarks.map(item => {
				if (!item) return null;
				const row = {};
				row.name = item.name;
				row.wsod = item.xId;
				data.push(row);
				return row;
			});

		}

		return (
			<div>
				<div className={'tableData benchmarkResultsTableStyles'}>
					<BootstrapTable
						data={data}
						options={{
							noDataText:
								'No available benchmarks were found.'
						}}
						maxHeight={'510px'}
						tableStyle={{ width: '100%', height: '510px' }}
					>
						<TableHeaderColumn
							isKey
							dataField="comparebutton"
							dataFormat={this.comparebutton}
							className={this.headerColumnClassNameFormat}
							width="10%"
						>
							Compare
						</TableHeaderColumn>
						<TableHeaderColumn dataField="name" width="90%" dataFormat={this.getBenchmarkWithPP}>
							Benchmark Name
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
			</div>
		);
	}
}

BenchmarkResultsTable.PropTypes = {
	benchmarksResults: PropTypes.Array,
	toggleCompareButton: PropTypes.func,
	getPrimaryProspectusBenchmarkData: PropTypes.func
};

export default BenchmarkResultsTable;

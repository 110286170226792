import React from 'react';
import ReactDOM from 'react-dom';
import routes from './routes';
import { Router, hashHistory } from 'react-router';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import Types from './actions/types'

const store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<Router history={hashHistory} routes={routes} />
	</Provider>,
	document.getElementById('root')
);


import React from 'react';

const ModuleCheckIcon = props => {
	return (
		<span role="presentation" className={'check'}>
			<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
				style={props.style}
			>
				<g id="Icon/Check" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="Group-7-Copy-3">
						<g id="Group-16-Copy-12" fill="#7E2D40" stroke="#7E2D40" stroke-width="2">
							<circle id="Oval" cx="8" cy="8" r="7"></circle>
						</g>
						<g id="Group-6" transform="translate(3.000000, 4.000000)" fill="#FFFFFF">
							<polygon id="Rectangle-12" transform="translate(6.177775, 4.263419) rotate(40.000000) translate(-6.177775, -4.263419) " points="5.31239048 -0.575935332 7.04315971 -0.575935332 7.04315971 9.10277336 5.31239048 9.10277336"></polygon>
							<polygon id="Rectangle-12-Copy" transform="translate(2.131792, 6.034799) rotate(-49.000000) translate(-2.131792, -6.034799) " points="1.26640774 3.96241401 2.99717698 3.96241401 2.99717698 8.10718443 1.26640774 8.10718443"></polygon>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);
};

export default ModuleCheckIcon;

import React from 'react';
import { PropTypes } from 'prop-types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";


import * as utilityFunctions from "../../../utils/commonMethods";

import CalendarIcon from "../../common/Icons/CalendarIcon";

import modalStyles from '../../../styles/components/react-datepicker.scss';

export default class CustomCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(2017, 0, 1),
            showCustomCalendar: false
        }
    }

    componentDidMount() {
        let startPeriod = 0;
        if (this.props.hypotheticalState.selectedPeriod.label != "Custom"){
            startPeriod =this.props.hypotheticalState.startPeriod
        }else{
            startPeriod = this.props.hypotheticalState.earliestCommon
        }
        startPeriod = startPeriod < this.props.hypotheticalState.indexInception ? this.props.hypotheticalState.indexInception: startPeriod;
    
    let startDate = new Date(moment(utilityFunctions.dateFromOADate(this.props.hypotheticalState.oldestCommon)).startOf('month'));
        this.setState({
            selectedDate: new Date(moment(utilityFunctions.dateFromOADate(startPeriod)).startOf('month')),
            startDate: startDate,
            endDate:this.props.module === "Events" ? new Date(moment(utilityFunctions.dateFromOADate(this.props.hypotheticalState.endPeriod)).subtract(2,'months'))  : 
             new Date(moment(utilityFunctions.dateFromOADate(this.props.hypotheticalState.endPeriod)).subtract(3, 'years').add(1, 'months'))
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.hypotheticalState !== nextProps.hypotheticalState) {
            let startPeriod = 0;
            if (this.props.hypotheticalState.selectedPeriod.label != "Custom"){
                startPeriod =this.props.hypotheticalState.startPeriod
            }else{
                startPeriod = this.props.hypotheticalState.earliestCommon
            }
            startPeriod = startPeriod < this.props.hypotheticalState.indexInception ? this.props.hypotheticalState.indexInception: startPeriod;
        
        let startDate = new Date(moment(utilityFunctions.dateFromOADate(this.props.hypotheticalState.oldestCommon)).startOf('month'));
            this.setState({
                selectedDate: new Date(moment(utilityFunctions.formatOADate(startPeriod)).startOf('month')),
                startDate: startDate,
                endDate: this.props.module == "Events" ? new Date(moment(utilityFunctions.dateFromOADate(this.props.hypotheticalState.endPeriod)).subtract(2,'months'))  : 
                new Date(moment(utilityFunctions.dateFromOADate(this.props.hypotheticalState.endPeriod)).subtract(3, 'years').add(1, 'months'))
            })
        }

        if(this.props.module != nextProps.module && nextProps.module == "Events") {
            if (nextProps.hypotheticalState && nextProps.hypotheticalState.selectedPeriod && nextProps.hypotheticalState.selectedPeriod.label == "Custom") {
                    let startDate = new Date(moment(utilityFunctions.dateFromOADate(nextProps.hypotheticalState.customDate)).startOf('month'));
                    this.setState({startDate: startDate });
            } 

        }
    }

    render() {
        return (
        <div>
        <CalendarIcon/>
        <DatePicker
            selected={this.props.selectedTimePeriodDate}
            onChange={this.props.handleChange}
            dateFormat="MM/yyyy"
            showMonthYearPicker= {true}
            shouldCloseOnSelect= {true}
            minDate={this.state.startDate}
            maxDate ={this.state.endDate}
            placeholderText = {this.props.module == "Events"? "Event Date" : "" }
            showDisabledMonthNavigation
            onFocus={this.props.onFocus}
        />
        </div>
        );
    }
}

CustomCalendar.PropTypes = {
    hypotheticalState: PropTypes.object,
    handleChange : PropTypes.func
};
import React from 'react';
import PropTypes from 'prop-types';

import searchFormStyle from '../../../styles/components/searchform.scss';

export default class SearchForm extends React.Component {
	constructor(props) {
		super(props);
	}

	submitHandler = (event) => {
		event.preventDefault();
	}

	changeHandler = (event) => {
		this.props.onChange(event.target.value);
		this.setState({
			searchText: event.target.value
		});
	}

	matchingTextBoldHandler(suggestion, currentValue) {
		const cv = currentValue;
		const reg = RegExp(suggestion, 'gi');
		return cv.replace(reg, '<b>$&</b>');
	}

	createMarkup(fullText) {
		return { __html: fullText !== null ? fullText.replace(/\n/g, '<br />') : '' };
	}

	render() {
		return <form className={'search-form'} onSubmit={this.submitHandler}>
			<label htmlFor="search">
        Search symbol or company
				<input
					autoComplete="off"
					type="text"
					id="search"
					placeholder="Search by symbol or name"
					onChange={this.changeHandler}
					onBlur={this.props.onBlur}
					value={this.props.searchText}
				/>
				<div className={'icon-wrapper'}>

				</div>
			</label>
			{this.props.showList === '1' && <div id="searchList" className={'search-list'}>

				{
					this.props.searchItemsStocks.length > 0 ? this.props.searchItemsStocks.map(
						(item, index) => (
							<div role="presentation" className={'row row-hover'}
								data-key={item.key} key={index} onMouseDown={this.props.onClick}
								data-symbol={item.symbol} data-security={item.securityType}>
								<div data-key={item.key} data-symbol={item.symbol} data-security={item.securityType} className={'symbol row-hover'} dangerouslySetInnerHTML={this.createMarkup(this.matchingTextBoldHandler(this.props.searchText, item.symbol))} />
								<div className={'name row-hover'} title={item.name}>{item.name}</div>
							</div>
						)) : <div className={'empty-message'}>Oops! We couldn’t find a quote for this company</div>
				}
			</div>}

		</form>;
	}
}

SearchForm.propTypes = {
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onClick: PropTypes.func,
	searchItemsStocks: PropTypes.array,
	showList: PropTypes.string,
	searchText: PropTypes.string
};

import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';

import Spinner from '../../../images/Spinner.gif';

import genericModalStyles from '../../../styles/components/modal/downloadingSpinnerModal.scss';

class GenericModal extends React.PureComponent {
	render() {


		const customModalStyles = {
			content: {
				top: '24%',
				left: '15%',
				right: '15%',
				bottom: '12%',
				height: '311px',
				width: '544px',
				padding: 0,
				boxShadow: '0 1px 13px 4px #d0cfce'
			}
		};

		return (
			<Modal shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true}
				isOpen={this.props.downloadSpinnerModalIsOpen} style={customModalStyles} >
				<div className={'downloadingSpinnerModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'spinner'}>
									<img src={Spinner}/>
								</div>
								<div className={'header-text'}>{this.props.title ? this.props.title : 'Generating PDF Please Wait'}</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

		);

	}
}

GenericModal.propTypes = {
	downloadSpinnerModalIsOpen: PropTypes.bool,
	customStyles: PropTypes.object,
	closeConfirmationModal: PropTypes.func,
	goToHomePage: PropTypes.func
};


export default GenericModal
;

import React from 'react';

const CalendarIcon = props => {
	return (
        <span role="presentation" className={'CalendarIcon'} >
      <svg xmlns="http://www.w3.org/2000/svg" width="115px" height="36px" viewBox="0 0 115 36" version="1.1">
    <title>Elements/Input/Calendar</title>
    <desc>Created with Sketch.</desc>
    <g id="Elements/Input/Calendar" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        
        <g id="Icon/Calendar" transform="translate(10.000000, 10.000000)" fill="#4C4C4C">
            <g id="calendar">
                <path d="M5,6 L7,6 L7,8 L5,8 L5,6 Z M8,6 L10,6 L10,8 L8,8 L8,6 Z M11,6 L13,6 L13,8 L11,8 L11,6 Z M2,12 L4,12 L4,14 L2,14 L2,12 Z M5,12 L7,12 L7,14 L5,14 L5,12 Z M8,12 L10,12 L10,14 L8,14 L8,12 Z M5,9 L7,9 L7,11 L5,11 L5,9 Z M8,9 L10,9 L10,11 L8,11 L8,9 Z M11,9 L13,9 L13,11 L11,11 L11,9 Z M2,9 L4,9 L4,11 L2,11 L2,9 Z M13,0 L13,1 L11,1 L11,0 L4,0 L4,1 L2,1 L2,0 L0,0 L0,16 L15,16 L15,0 L13,0 Z M14,15 L1,15 L1,4 L14,4 L14,15 Z" id="Shape" fill-rule="nonzero"/>
            </g>
        </g>
        
    </g>
</svg>
</span >
	);
};

export default CalendarIcon;
import React from 'react';
import Select, { components } from 'react-select';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import { PropTypes } from 'prop-types';

import numeral from 'numeral';
import moment from "moment";
import { cloneDeep } from "lodash";

import * as Constant from '../../../utils/constants';
import * as utilityFunctions from "../../../utils/constants";
import * as utilityMethods from "../../../utils/commonMethods";
import * as CustomModalStyles from '../../../utils/customModalStyles';

import RebalanceTableDetails from './RebalanceTableDetails';
import ValidationModal from '../../common/modal/ValidationModal.js';

import ResultSetTooLargeIcon from '../../common/Icons/ResultSetTooLargeIcon';
import BackArrowIcon from '../../common/Icons/BackArrowIcon';
import ExpandedArrow from '../../common/Icons/ExpandedArrow';
import ClosedArrow from '../../common/Icons/ClosedArrow';
import CalendarIcon from "../../common/Icons/CalendarIcon";

import closeSVG from "../../../images/Icon/Close.svg";

import screenerFlyoutStyles from '../../../styles/components/screenerFlyout.scss';

export default class WithdrawalFlyout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedFrequencyType: "Monthly",
			disableSaveEventButton: false,
			fundWithdrawalEvent: [],
			includeRebalance: false,
			rebalanceValid: true,
			rebalanceOpen: true,
			validationModalIsOpen: false,
			errors: []
		};

		this.defaultIncreaseByType = { label: '%', value: '%' }
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.fundInvestmentEditData.data !== this.props.fundInvestmentEditData.data && nextProps.fundInvestmentEditData.data) {
			this.setState({
				fundWithdrawalEvent: nextProps.fundInvestmentEditData.data,
				includeRebalance: nextProps.fundInvestmentEditData.includeRebalance
			})
		}
		if(this.props.openEventFlyout != nextProps.openEventFlyout && nextProps.openEventFlyout  && !nextProps.fundInvestmentEditData.data) {
			this.setState({
				selectedFrequencyType: "Monthly",
				fundWithdrawalEvent: [],
				includeRebalance: false,
				disableSaveEventButton: false,
				rebalanceValid: true
			});
		} 
	}

	getOptionsList = (index, index1) => {
		if(index1 === 0){
			return utilityFunctions.fundInvestFrequencyTypes;
		} else {
			let fundWithdrawalEvent = cloneDeep(this.state.fundWithdrawalEvent);
			let frequency = fundWithdrawalEvent[index].data[0].frequency.value;
			if(frequency === 'One-Time'){
				return utilityFunctions.fundInvestOnlyFrequencyTypes;
			} else {
				return utilityFunctions.fundInvestOnlyOneTime;
			}
		}
		
	}
	handleRebalanceAccordionClick = () => {
		let rebalanceOpen = !(this.state.rebalanceOpen);
		this.setState({
			rebalanceOpen: rebalanceOpen
		});
	}
	handleHeaderAccordionClick = (e, index) => {
		let fundWithdrawalEvent = cloneDeep(this.state.fundWithdrawalEvent);
		fundWithdrawalEvent[index].isOpen = !(fundWithdrawalEvent[index].isOpen);
		this.setState({
			fundWithdrawalEvent: fundWithdrawalEvent
		});
	}
	handleWithdrawalListChange(option, dataIndex, dataIndex1, dataName) {
		let fundWithdrawalEvent = cloneDeep(this.state.fundWithdrawalEvent);
		let rebalanceValid = true;
		
		if(dataName === 'endDate') {			
			(fundWithdrawalEvent[dataIndex].data[dataIndex1])[dataName] = utilityMethods.convertToOADate(option) - 1;
		} else {
			(fundWithdrawalEvent[dataIndex].data[dataIndex1])[dataName] = option;
		}		
		if (dataName === 'frequency') {
			if(dataIndex1 === 0 && fundWithdrawalEvent[dataIndex].data.length >= 2) {
				(fundWithdrawalEvent[dataIndex].data[1])[dataName] = option.value === 'One-Time' ? { label: 'Every 1 Month', value: '1' } : { label: 'One-Time', value: 'One-Time' };
			}
			
			fundWithdrawalEvent.map(item=>{
				return item.data.map(item1=>{
					if(item1.frequency.value !== 'One-Time'){
						return rebalanceValid = false;
					}
				})
			})
			
			this.setState({
				fundWithdrawalEvent: fundWithdrawalEvent,
				rebalanceValid: rebalanceValid
			});
		
		} else {
			this.setState({
				fundWithdrawalEvent: fundWithdrawalEvent
			});
		}
	}
	handleWithdrawalChange = (event) => {
		let dataIndex = event.target.getAttribute('data-index');
		let dataIndex1 = event.target.getAttribute('data-index1');
		let dataName = event.target.getAttribute('data-name');

		this.handleWithdrawalListChange(event.target.value, dataIndex, dataIndex1, dataName);
	}
	handleCheckChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.setState({
			includeRebalance: value
		});
		this.updateStateRebalanceValid();
	}
	addFundWithdrawalEvent = () => {
		let wsodIssue = document.getElementById("selectFund").childNodes[1].value;
		let fundWithdrawalEvent = cloneDeep(this.state.fundWithdrawalEvent);
		let data = this.props.hypotheticalState;
		data.portfolios[this.props.index].funds.forEach(item => {
			if (wsodIssue === item.wsodissue.toString()) {
				let wsodIndex = fundWithdrawalEvent.findIndex(x => {
					return x.wsodissue.toString() === wsodIssue;
				});
				if (wsodIndex > -1) {
					if (fundWithdrawalEvent[wsodIndex].data.length < 2) {
						fundWithdrawalEvent[wsodIndex].data.push({
							invest: 0.00,
							frequency: fundWithdrawalEvent[wsodIndex].data[0].frequency.value === 'One-Time' ? { label: 'Every 1 Month', value: '1' } : { label: 'One-Time', value: 'One-Time' },
							endDate: this.props.endDate,
							dateBegin: utilityMethods.convertToOADate(this.props.selectedEventDate)-1,
							increaseBy: { label: '%', value: '%' },
							increaseByValue: 0,
							increaseFrequency: { label: 'Every 1 Month', value: 1 }
						});
					}
				} else {
					fundWithdrawalEvent.push({
						wsodissue: item.wsodissue,
						symbol: item.symbol,
						name: item.name,
						investmentId: item.investmentId,
						isOpen: true,
						data: [{
							invest: 0.00,
							frequency: { label: 'Every 1 Month', value: 1 },
							endDate: this.props.endDate,
							dateBegin : utilityMethods.convertToOADate(this.props.selectedEventDate)-1,
							increaseBy: { label: '%', value: '%' },
							increaseByValue: 0,
							increaseFrequency: { label: 'Every 1 Month', value: 1 }
						}]
					})
				}
				this.setState({
					fundWithdrawalEvent: fundWithdrawalEvent,
					rebalanceValid: false
				});
			}
		});

	}

	closeFlyout = () => {
		this.setState({
			selectedFrequencyType: "Monthly",
			fundWithdrawalEvent: [],
			includeRebalance: false,
			disableSaveEventButton: false,
			rebalanceValid: true
		});
		this.props.closeEventFlyout();
	}

	onChangeFunds = (e) => {
		if (document.getElementById("selectFund") && document.getElementById("selectFund").childNodes[1].value === '') {
			document.getElementsByClassName('investment-add-button')[0].style.opacity = '0.4'
			document.getElementsByClassName('investment-add-button')[0].style['pointer-events'] = 'none'
		} else {
			document.getElementsByClassName('investment-add-button')[0].style.opacity = '1'
			document.getElementsByClassName('investment-add-button')[0].style['pointer-events'] = 'all'
		}
	}

	onSelectedFrequencyType = (e) => {
		let value = document.getElementById('percentageTotal').getAttribute('data-percentageTotal');
		this.validatePercentValueTotal(value);
	}

	validatePercentValueTotal = (value) => {
		if (document.getElementsByClassName('event-save-button')[0]) {
			if(this.state.includeRebalance === true && this.state.rebalanceValid && this.state.fundWithdrawalEvent.length > 0){
			if (parseInt(value) !== 100 || document.getElementById("selectFrequency").childNodes[1].value === '') {
				document.getElementsByClassName('event-save-button')[0].style.opacity = '0.4'
				document.getElementsByClassName('event-save-button')[0].style['pointer-events'] = 'none'
			} else {
				document.getElementsByClassName('event-save-button')[0].style.opacity = '1'
				document.getElementsByClassName('event-save-button')[0].style['pointer-events'] = 'all'
			}
		}else if (this.state.fundWithdrawalEvent.length > 0) {
				document.getElementsByClassName('event-save-button')[0].style.opacity = '1'
				document.getElementsByClassName('event-save-button')[0].style['pointer-events'] = 'all'
			}
		}
	}

	saveEventsHypotheticalState = (hypoState) => {
		this.hypotheticalSaveEventState = hypoState;
	}
	setAllocations() {
		let fund = document.getElementsByClassName('portfolio-invested text-right event-set-amount')
		const dictSymbolWeightage = {};
		for (let count = 0; count < fund.length; count++) {
			dictSymbolWeightage[fund[count].getAttribute('data-symbol')] = parseFloat((parseFloat(fund[count].getAttribute('data-raw-value')) * .01).toFixed(4));
		}
		return dictSymbolWeightage;
	}
	closeValidationModal = () => {		
		this.setState({validationModalIsOpen: false});
	}
	validateFundWithdrawalFrequency = () => {
		let error = ['Please ensure that all percent values are less than or equal to 100.'];
		this.setState({ validationModalIsOpen: true, errors: error });
	}

	validateWithdrawalAmount = (errorMsg) => {
		this.setState({ validationModalIsOpen: true, errors: errorMsg });
	}
	// When user click on "Save Event" when adding withdrawal fund
	saveEvent = () => {
		let isInvalid = false;
		let invalidWithdrawalFundsList = [];
		this.state.fundWithdrawalEvent.forEach(item => {
			item.data.forEach(x => {				
				if (x.increaseBy.value === "%" && parseFloat(x.increaseByValue) > 100) {
					return isInvalid = true;
				}
			});
		});

		if(!isInvalid) {			
			//Check if all withdrawal amounts for funds are valid			
			this.state.fundWithdrawalEvent.forEach(f=>{
				let amount = this.props.fundsPresentAmountList.filter(item=>item.wsodIssue == f.wsodissue)[0].investAmount;
				amount = amount.toFixed(2);
				let _amountEntered = 0;
				f.data.forEach(fundAmount => {
					_amountEntered += parseFloat(fundAmount.invest);
				})
				_amountEntered > amount &&
          		invalidWithdrawalFundsList.push({
          		  symbol: f.symbol,
          		  wsodissue: f.wsodissue,
          		  amountEntered: numeral(_amountEntered).format("0,0.00"),
          		  amountCanBeWithdrawn: numeral(amount).format("0,0.00"),
          		});			
			});
		}




		if (isInvalid) {
			this.validateFundWithdrawalFrequency();
		} else if(invalidWithdrawalFundsList.length > 0) {
			let errorMsg = [];
			errorMsg.push('The entered withdrawal amount exceeds the value of the following fund(s):');
			invalidWithdrawalFundsList.forEach(item=>{
				errorMsg.push(item.symbol +", withdrawal entered "+this.props.currencySymbol + item.amountEntered+", available for withdrawal " + this.props.currencySymbol + item.amountCanBeWithdrawn);
			});
			this.validateWithdrawalAmount(errorMsg);			
		} else {
			let schedules = {};
			if (this.state.rebalanceValid === true && this.state.includeRebalance === true && this.state.fundWithdrawalEvent.length > 0) {
				let investmentsWithAllocations = [];
				let data = this.props.hypotheticalState;
				schedules.dateBegin = utilityMethods.convertToOADate(this.props.selectedEventDate);
				schedules.dateEnd = data.endPeriod;
				schedules.frequency = document.getElementById("selectFrequency").childNodes[1].value;
				schedules.snapPeriods = "End";
				schedules.includeFees = false;
				this.dictSymbolWeightage = this.setAllocations();
				data.portfolios[this.props.index].funds.forEach(item => {
					investmentsWithAllocations.push({
						allocation: this.dictSymbolWeightage[item.symbol] == undefined ? 0 : this.dictSymbolWeightage[item.symbol],
						investmentId: item.investmentId
					})
				})
				schedules.investmentsWithAllocations = investmentsWithAllocations;

				// If already a rebalance exists on same start date, then delete it
				let sameRebalanceOnStartDate = [];
				sameRebalanceOnStartDate = this.setSameRebalanceOnStartDateOnSaveEvent(schedules);
				
				sameRebalanceOnStartDate.length > 0 &&
         		this.props.handleRebalanceAdjustment(
         		  this.props.index,
         		  this.props.workingCopyConatainerId,
         		  null,
         		  sameRebalanceOnStartDate[0].Id
         		);
				
				this.props.requestSaveEventTemplateDetailsApi(this.props.index, this.props.workingCopyConatainerId, schedules, '');
			}

			this.props.requestSaveEventTemplateDetailsApi(this.props.index, this.props.workingCopyConatainerId, null, '', this.state.fundWithdrawalEvent[0].data[0]);
			//To set end date in OADate format as end date of selected month
			this.state.fundWithdrawalEvent.map(item => {
				return item.data.map(x => {	
					return x.endDate = (x.endDate > this.props.endDate ? this.props.endDate : x.endDate);
				});
			});
			let withdrawalSchedules = JSON.stringify(this.state.fundWithdrawalEvent);
			this.props.requestSaveWithdrawalEventApi(withdrawalSchedules, this.props.workingCopyConatainerId);
			this.props.calledSave(this.props.selectedPortfolio);
			this.closeFlyout();
		}
	}

	setSameRebalanceOnStartDateOnSaveEvent = (schedules) => {
		let sameRebalanceOnStartDate = []
		if (this.props.index === 0) {
			sameRebalanceOnStartDate = this.props.schedules.filter(item => { return item.DateBegin == schedules.dateBegin-1 });
		} else {
			sameRebalanceOnStartDate = this.props.schedulesP2.filter(item => { return item.DateBegin == schedules.dateBegin-1 });
		}
		return sameRebalanceOnStartDate;
	}

	// When user click on "Save Event" when editing investmemt fund
	editEvent = (e) => {
		let isInvalid = false;
		let invalidWithdrawalFundsList = [];
		this.state.fundWithdrawalEvent.forEach(item => {
			item.data.forEach(x => {				
				if (x.increaseBy.value === "%") {
					if (parseFloat(x.increaseByValue) > 100) {
						return isInvalid = true;
					}
				}
			});
		});
		if(!isInvalid) {			
			//Check if all withdrawal amounts for funds are valid						
			this.state.fundWithdrawalEvent.forEach(f=>{
				let amount = this.props.fundsPresentAmountList.filter(item=>item.wsodIssue == f.wsodissue)[0].investAmount;
				
				let _amountEntered = 0;
				f.data.forEach(fundAmount => {
					_amountEntered += parseFloat(fundAmount.invest);
				});
				let oldData = this.props.fundInvestmentEditData.data.filter(t=>t.wsodissue == f.wsodissue);
				if(oldData && oldData.length > 0 ) {
					console.log(oldData);
					oldData[0].data.forEach(x=>{
						amount += parseFloat(x.invest);
					});					
				}
				amount = amount.toFixed(2);
				
				if(_amountEntered > amount) {					
					invalidWithdrawalFundsList.push({symbol: f.symbol, wsodissue: f.wsodissue, amountEntered: numeral(_amountEntered).format('0,0.00'), amountCanBeWithdrawn: numeral(amount).format('0,0.00')});
				}				
			});
		}
		if (isInvalid) {
			this.validateFundWithdrawalFrequency();
		} else if(invalidWithdrawalFundsList.length > 0) {
			let errorMsg = [];
			let currencySymbol = this.props.currencySymbol;
			errorMsg.push('The entered withdrawal amount exceeds the value of the following fund(s):');
			invalidWithdrawalFundsList.forEach(item=>{
				errorMsg.push(item.symbol +", withdrawal entered "+currencySymbol+item.amountEntered+", available for withdrawal "+currencySymbol+item.amountCanBeWithdrawn);
			});
			this.validateWithdrawalAmount(errorMsg);			
		} else {
			// Step 1: If user is modifying investment event
			if (this.props.fundInvestmentEditData.scheduleIds !== '') {

				// Step 2: delete previous fund investment
				let arrSchedules = this.props.fundInvestmentEditData.scheduleIds.split(',');
				this.props.requestRemoveEventFrontwithdrawalSchedulesApi(arrSchedules, this.props.workingCopyConatainerId)

				// Step 3: add new modified fund investment
				//To set end date is greater than end date of portfolio then, set portfolio end date
				this.state.fundWithdrawalEvent.map(item => {
					return item.data.map(x => {	
						return x.endDate = (x.endDate > this.props.endDate ? this.props.endDate : x.endDate);
					});
				});
			
				let withdrawalSchedules = JSON.stringify(this.state.fundWithdrawalEvent);
				this.props.requestSaveWithdrawalEventApi(withdrawalSchedules, this.props.workingCopyConatainerId);

				// Step 4: Check if rebalance has been included
				if (this.state.includeRebalance && this.state.rebalanceValid) {

					// Step 5: Check if rebalance is valid and there are atleast one investment event
					if (this.state.fundWithdrawalEvent.length > 0) {

						// Common property setting hypo for rebalance creating/modifying
						let schedules = {};

						schedules.frequency = document.getElementById("selectFrequency").childNodes[1].value;
						schedules.snapPeriods = "End";
						schedules.includeFees = false;

						let investmentsWithAllocations = [];
						this.dictSymbolWeightage = this.setAllocations();
						let data = this.props.hypotheticalState;
						data.portfolios[this.props.index].funds.forEach(item => {
							investmentsWithAllocations.push({
								allocation: this.dictSymbolWeightage[item.symbol] == undefined ? 0 : this.dictSymbolWeightage[item.symbol],
								investmentId: item.investmentId
							})
						})
						schedules.investmentsWithAllocations = investmentsWithAllocations;

						// Step 5: If rebalance is being created
						if (this.props.editscheduleId === '') {

							schedules.dateBegin = utilityMethods.convertToOADate(this.props.selectedEventDate);
							schedules.dateEnd = data.endPeriod;

							this.props.requestSaveEventTemplateDetailsApi(this.props.index, this.props.workingCopyConatainerId, schedules, '');

						} else { // Step 5: If rebalance is being modifed

							let portfoliotype = e.target.getAttribute('data-portfoliotype');

							schedules.id = this.props.editscheduleId;
							schedules.dateBegin = this.props.editDateBegin;
							schedules.dateEnd = this.props.editDateEnd;

							this.props.requestModifyEventTemplateDetailsApi(schedules, this.props.workingCopyConatainerId, portfoliotype);
						}
					}
				} else {

					// Step 6: If rebalance need to be deleted
					if (this.props.fundInvestmentEditData && this.props.fundInvestmentEditData.rebalanceSchedule) {
						let _scheduleId = this.props.fundInvestmentEditData.rebalanceSchedule.Id;
						this.props.requestSaveEventTemplateDetailsApi(this.props.index, this.props.workingCopyConatainerId, null, _scheduleId);
					} 
				}
			}

			this.props.calledSave(this.props.selectedPortfolio);
			this.closeFlyout();
		}
	}

	handleDeleteWithdrawalRow = (e) => {
		let fundWithdrawalEvent = cloneDeep(this.state.fundWithdrawalEvent);
		const dataIndex = e.currentTarget.getAttribute('data-index1');
		const index = e.currentTarget.getAttribute('data-index');
		if(fundWithdrawalEvent[index].data.length === 1){
			fundWithdrawalEvent.splice(index,1)
		} else{
		fundWithdrawalEvent[index].data.splice(dataIndex,1)
		}
		let rebalanceValid = true;
		fundWithdrawalEvent.map(item=>{
			return item.data.map(item1=>{
				if(item1.frequency.value !== 'One-Time'){
					return rebalanceValid = false;
				}
			})
		})
		this.setState({fundWithdrawalEvent :fundWithdrawalEvent, rebalanceValid: rebalanceValid})
	}

	updateStateRebalanceValid = () => {
		let fundWithdrawalEvent = cloneDeep(this.state.fundWithdrawalEvent);
		let rebalanceValid = true;
		fundWithdrawalEvent.map(item=>{
			return item.data.map(item1=>{
				if(item1.frequency.value !== 'One-Time'){
					return rebalanceValid = false;
				}
			})
		})
		this.setState({ rebalanceValid: rebalanceValid });
	}

	render() {
		const DropdownIndicator = props => {
			return (
				components.DropdownIndicator && (
					<components.DropdownIndicator {...props}>
						<i
							className="dropdown"
							aria-hidden="true"
						/>
					</components.DropdownIndicator>
				)
			);
		};
		let disableButtonStyle = {
			'pointer-events': 'none',
			opacity: 0.4
		};
		return (
			<Modal
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				isOpen={this.props.openEventFlyout}
				style={this.props.customStyles}
				onRequestClose={this.closeFlyout}
			>
				<div className={'screenerflyout-styles investment-flyout'}>
					<span className={'event-flyout-header'}>
						<span className={'screener-flyout-title-backArrow'} onClick={this.closeFlyout} ><BackArrowIcon /></span>
						<span className={'screener-flyout-title'}>Fund Withdrawal starting on {this.props.selectedEventDate != '' ? ((this.props.userType==='International') ? <span className={'event-selected-date'}> {utilityMethods.formatOADate(utilityMethods.convertToOADate(this.props.selectedEventDate),"DD MMM, YYYY")} </span> : <span className={'event-selected-date'}> {this.props.selectedEventDate} </span>) : ''}</span>
						<span className={'span-save-button'}>
							<button
								onClick={this.props.fundInvestmentEditData.scheduleIds === undefined ? this.saveEvent : this.editEvent}
								data-portfoliotype={this.props.selectedPortfolio}
								className={'event-save-button'}
								style={this.state.fundWithdrawalEvent.length > 0 ? {} : disableButtonStyle}>
							
								SAVE EVENT
								</button>

						</span>
						<span className={'screener-flyout-horizontal'} >
							<div className={'investment-message'}>
							Select a fund or funds to withdraw from on your selected start date. You may set both a one-time and a recurring withdrawals for the same fund. A recurring fund withdrawal event can not include a rebalance schedule.
							</div>
							<div className={'filter-styles investment-filter-styles'}>
								<div className="label-style investment-label-style">
									<Select
										name="select-time-period\"
										className="dropdown-mfs"
										theme={utilityFunctions.customTheme}
										styles={utilityFunctions.customStyles}
										isSearchable={false}
										onChange={this.onChangeFunds}
										options={this.props.fundsData}
										components={{ DropdownIndicator }}
										defaultValue={{ label: 'Select a fund to add an withdrawal', value: '' }}
										id="selectFund" />
									<div className={'chkBoxDiv'}>
										<input
											id={'chkIncludeRebalance'}
											type="checkbox"
											checked={this.state.includeRebalance}
											onChange={this.handleCheckChange} />
										<label className={'lblIncludeRebalance'}>Include Rebalance</label>
									</div>
								</div>
								<label className="lbl-include-fees">
									<button
										onClick={this.addFundWithdrawalEvent}
										data-portfoliotype={this.props.selectedPortfolio}
										className={'investment-add-button'}
										style={{ 'opacity': '0.4', 'pointer-events': 'none' }}
									>
										ADD WITHDRAWAL
								</button>
								</label>
							</div>
						</span>
					</span>
					<div className={'filter-styles fund-investment-div'}>
						{this.state.fundWithdrawalEvent.map((item, index) => {
							return <div className={'fund-invest-module'}>
								<div className={'accordion'} onClick={e=> this.handleHeaderAccordionClick(e, index)}>
									<div className={'symbol'}>
										<span className={'arrow'}>{item.isOpen ? <ExpandedArrow /> : <ClosedArrow />}</span>
										<label className={'symbolChar'}>{this.props.userType === 'International' ? item.name : item.symbol}</label>
									</div>
									<div className={'event'}>{item.data.length} event</div>
									<div className={'name'}>
										{this.props.userType === 'International' ? item.symbol : item.name}
									</div>
								</div>
								<div className={'fund-data-div'} style={{ display: item.isOpen ? 'block' : 'none' }}>
							
									{item.data && item.data.map((x, index1) => {
										return <div className={'tile'}>
											<div
                                				onClick={this.handleDeleteWithdrawalRow}
                                    			className={'dv-investment-delete'}
												data-index1={index1}
												data-index={index}>                                                                
                                    			 <img src={closeSVG} />{" "}                                                                
                                			</div>
											<div className={'first-row'}>
												<div className={'control-list'}>
													<div className={'invest-label padding-left-invest'}>
														Withdrawal
										</div>
													<div className={'invest-control'}>
														{this.props.currencySymbol} <input className={'fund-invest'}
															type="text"
															onBlur={this.onBlurFundInvest}
															onKeyDown={utilityMethods.allowOnlyNumbers}
															data-symbol={item.symbol}
															data-wsodissue={item.wsodissue}
															data-raw-value={x.invest}
															data-index={index}
															data-index1={index1}
															data-name={'invest'}
															onChange={this.handleWithdrawalChange}
															value={x.invest}
														/>
													</div>
												</div>
												<div className={'control-list'}>
													<div className={'invest-label'}>
														Frequency
										</div>
													<div className={'invest-control'}>
														<Select
															name="select-time-period\"
															className="dropdown-mfs"
															theme={utilityFunctions.customTheme}
															styles={utilityFunctions.customStyles}
															isSearchable={false}
															onChange={e => this.handleWithdrawalListChange(e, index, index1, 'frequency')}
															options={this.getOptionsList(index, index1)}
															components={{ DropdownIndicator }}
															defaultValue={x.frequency}
															value={x.frequency}
															id="fundInvestFrequency" />
													</div>
												</div>
												<div className={'control-list'}>
													<div className={'invest-label end-date'}>
														End Date
										</div>
													<div className={'invest-control'}>
														<CalendarIcon />
														<DatePicker
															selected={new Date(moment(utilityMethods.formatOADate(parseInt(x.endDate) + 1)))}
															onChange={e => this.handleWithdrawalListChange(e, index, index1, 'endDate')}
															dateFormat="MM/yyyy"
															showMonthYearPicker={true}
															shouldCloseOnSelect={true}
															placeholderText={"End Date"}
															showDisabledMonthNavigation
															disabled={x.frequency.value === 'One-Time'}
														/>
													</div>
												</div>
											</div>
											<div className={'second-row'}>
												<div className={'control-list'}>
													<div className={'invest-label'}>
														Increase by
													</div>
													<div className={`invest-control increase-by-type ${x.frequency.value === 'One-Time' ? 'disabled-style' : ''}`}>
														<Select
															name="increase-by-type"
															className="dropdown-mfs"
															theme={utilityFunctions.customTheme}
															styles={utilityFunctions.customStyles}
															isSearchable={false}
															onChange={e => this.handleWithdrawalListChange(e, index, index1, 'increaseBy')}
															options={[
																{ label: '%', value: '%' },
																{ label: this.props.currencySymbol, value: this.props.currencySymbol }
															]}
															components={{ DropdownIndicator }}
															defaultValue={this.defaultIncreaseByType}
															value={x.increaseBy}
															id="fundIncreaseByType"
															isDisabled={x.frequency.value === 'One-Time'}
														/>
													</div>
													<div className={'invest-control fund-increase-by'}>
														<input className={'fund-invest'}
															type="text"
															onBlur={this.onBlurFundInvest}
															onKeyDown={utilityMethods.allowOnlyNumbers}
															data-symbol={item.symbol}
															data-wsodissue={item.wsodissue}
															data-raw-value={x.invest}
															data-index={index}
															data-index1={index1}
															data-name={'increaseByValue'}
															onChange={this.handleWithdrawalChange}
															value={x.increaseByValue}
															disabled={x.frequency.value === 'One-Time'}
														/>
													</div>
												</div>
												<div className={'control-list'}>
													<div className={'invest-label'}>
														Increase Frequency
													</div>
													<div className={`invest-control ${x.frequency.value === 'One-Time' ? 'disabled-style' : ''}`}>
														<Select
															name="increase-by-frequency"
															className="dropdown-mfs"
															theme={utilityFunctions.customTheme}
															styles={utilityFunctions.customStyles}
															isSearchable={false}
															onChange={e => this.handleWithdrawalListChange(e, index, index1, 'increaseFrequency')}
															options={utilityFunctions.fundInvestFrequencyTypesIncrease}
															components={{ DropdownIndicator }}
															defaultValue={x.frequency}
															value={x.increaseFrequency}
															id="fundIncreaseByFrequency"
															isDisabled={x.frequency.value === 'One-Time'}
														/>
													</div>
												</div>
											</div>
										</div>
									})}
								</div>
							</div>
						})}
						<div id={'includeRebalace'} className={'includeRebalance'} style={{ display: this.state.includeRebalance ? 'block' : 'none' }}>
							<div class="accordion" onClick={this.handleRebalanceAccordionClick}>
								<div class="symbol">
									<span className={'arrow'}>{this.state.rebalanceOpen ? <ExpandedArrow /> : <ClosedArrow />}</span>
									<label className={'symbolChar'}>Adjust Rebalance After One-time Withdrawals</label>
								</div>
								<div class="event">
									{this.state.rebalanceValid ? '' : 'Rebalance Unavailable'}
								</div>
								<div class="subtitle">
									Allocations Based on Previous Allocation
								</div>
							</div>
							<div style={this.state.rebalanceOpen ? (this.state.rebalanceValid ? {} : disableButtonStyle) : {display:'none'} }>
							<span className={'investment-flyout-horizontal'}>
								<div className={'filter-styles investment-filter-styles'}>
									<div className={'label-frequency'}>Frequency </div>
									<div className="label-style event-label-style">
										<Select
											name="select-time-period\"
											className="dropdown-mfs"
											theme={utilityFunctions.customTheme}
											styles={utilityFunctions.customStyles}
											isSearchable={false}
											defaultValue={this.props.editFrequencyType}
											onChange={this.onSelectedFrequencyType}
											options={utilityFunctions.frequencyTypes}
											components={{ DropdownIndicator }}
											id="selectFrequency" />
									</div>
								</div>
							</span>
							<div className={'filter-styles'}>
								<div className={'portfolio-investment2 investment-table-details event-table-details'}>
									{this.props.hypotheticalState.portfolios && this.props.hypotheticalState.portfolios.length > 0 &&
										<RebalanceTableDetails
											updateHypothicalStateFundValuesPercents={this.props.updateHypothicalStateFundValuesPercents}
											index={this.props.selectedPortfolio == 'One' ? 0 : 1}
											hypotheticalState={this.props.hypotheticalState}
											validatePercentValueTotal={this.validatePercentValueTotal}
											selectedEventDate={this.props.selectedEventDate}
											saveEventsHypotheticalState={this.saveEventsHypotheticalState}
											autoDistribute={this.props.autoDistribute}
											includeRebalance= {this.state.includeRebalance}
											rebalanceValid ={this.state.rebalanceValid && this.state.fundWithdrawalEvent.length > 0}
											userType={this.props.userType}
										/>}
								</div>
							</div>
							</div>
						</div>
					</div>
					<ValidationModal
								errorMessages={this.state.errors}
								customStyles={CustomModalStyles.ValidationModalStyles}
								closeValidationModal={this.closeValidationModal}
								validationModalIsOpen={this.state.validationModalIsOpen}
								isWithdrawalModal={this.state.errors.length>0}
							/>
				</div>
				
			</Modal>)
	}
}

WithdrawalFlyout.propTypes = {
	openEventFlyout: PropTypes.bool,
	customStyles: PropTypes.object,
	closeEventFlyout: PropTypes.func,
	calledSave: PropTypes.func,
	autoDistribute: PropTypes.func,
	fundsData: PropTypes.object,
	validateFundWithdrawalFrequency: PropTypes.func
};
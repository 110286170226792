import React from 'react';

const lockIconStyles = {
	'margin-bottom': '-1px'
};

const LockIcon = props => {
	return (
		<span className="lock-icon">
			<svg width="12px" height="14px" viewBox="0 0 15 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
				style={lockIconStyles}>
				>
				<g id="Icon/Lock" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="Group-5-Copy-8" stroke="#979797">
						<rect id="Rectangle-2" fill="#979797" x="0.5" y="6.5" width="14" height="11" />
						<ellipse id="Oval-3" strokeWidth="2" cx="7.5" cy="6" rx="3.5" ry="5" />
					</g>
				</g>
			</svg>
		</span >
	);
};

export default LockIcon;

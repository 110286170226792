import React from 'react';
import { PropTypes } from 'prop-types';
import Select, { components } from 'react-select';

import numeral from 'numeral';
import moment from "moment";
import lodash from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import * as utilityFunctions from '../../utils/commonMethods'
import * as CustomModalStyles from '../../utils/customModalStyles'
import * as Constant from '../../utils/constants';

import {BenchmarkResults , customTheme , customStyles, frequencyTypes, frequencyTypeDict, investmentFrequencyTypeDict } from "../../utils/constants";

import modalStyles from '../../styles/components/portfolio/Hypotheticals.scss';

import BenchmarkResultsTable from '../portfolio/selectPortfolioFund/subComponents/benchmarkResultsTable';
import Tabs from "../common/subcomponents/Tabs"
import PortfolioInvestmentTable from '../portfolio/selectPortfolioFund/subComponents/PortfolioInvestmentTable'
import CustomCalendar from './subComponents/CustomCalendar'
import FundScenarioUnavailable from '../common/modal/FundScenarioUnavailable'
import TimeRangeScenarioAlertModal from '../common/modal/TimeRangeScenarioAlertModal'
import LoadFeeScheduleModal from '../common/modal/LoadFeeScheduleModal'
import EventsModule  from './subComponents/EventsModule'
import SalesChargesModule  from './subComponents/SalesChargesModule'


export default class Hypotheticals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "Initial Investment",
      fundUnavailableModalIsOpen: false,
      timeRangeScenarioAlertModalIsOpen:false,
      selectedTimePeriodDate: '',
      tempSelectedTimePeriod: {label: 'Earliest Common', value: 0}, 
      customDate: 0,
      showCancel: false,
      eventDetails: [],
      eventDetailsP2: [],
      calledSaveFromPortfolio:'',
      schedulesTobeModified:[],
      // Load Fee Schedules (Front, Deferred, Redemption)
      feeSchedulesIsOpen: false,
      feeSchedulesFund: {},
      frontSchedules: [],
      investmentScheduleDetails: [],
      investmentScheduleDetailsP2: [],
      withdrawalScheduleDetails: [],
      withdrawalScheduleDetailsP2: [],
      fundEvents: [],
      fundEventsP2: [],
      eventDetailsAll: [],
      eventDetailsAllP2: [],
      fundP1CtrPositiveAllocations: [],//This is used to have only array of wsodissue
      fundP2CtrPositiveAllocations: [],//This is used to have only array of wsodissue
      ctrAllFundsAllocationsP1: [],//This is used to have only array of object with wsodissue and allocation
      ctrAllFundsAllocationsP2: [],//This is used to have only array of object with wsodissue and allocation
      fundsPresentAmountList: [] // have array of object with wsodIssue and Amount available at that time
    };
    
    this.tabsList = [
      "Initial Investment",
      Constant.eventTabName,
    ];
    this.invalidFundCount = 0;

    this.defaultInitAlloPortFreq = { label: 'Every 1 Month', value: '1Months' };
    this.updateScheduleInitAlloc = false;
    this.userClickSelectModuleInitAlloc = false;

    this.IsChangeSchedulesTobeModifiedP1 = false;
    this.IsChangeSchedulesTobeModifiedP2 = false;
    this.IsUpdateInitialInvestment = false;
    this.ctrIndex = -1;
    this.p1CtrRandom = 0;
    this.p2CtrRandom = 0;
  }

  handleEventCount = (count) => {
    this.invalidFundCount = this.props.hypotheticalState.portfolios[0].funds.filter(x => !x.rebalance && (x.Invalid || x.amountInvestedPercent == 0)).length
                                + this.props.hypotheticalState.portfolios[1].funds.filter(x => !x.rebalance && (x.Invalid || x.amountInvestedPercent == 0)).length;
  }

  populateFundsByCtr = (index, ctrFinalAllocations) => {
    let ctrAllFundsAllocations = [];
    let ctrPositiveFinalAllocation = [];
    ctrFinalAllocations.map(item=>{
      item.Investment && item.Investment.forEach(investment=>{
        if(investment.TimeFrame[0].FinalAllocation > 0) {
          ctrPositiveFinalAllocation.push(investment.TimeFrame[0].IssueID);
          ctrAllFundsAllocations.push({wsodIssue:investment.TimeFrame[0].IssueID, allocationPer:investment.TimeFrame[0].FinalAllocation});
        }
      })     
    });
    if(index == 1) {
        this.setState({
          fundP1CtrPositiveAllocations: ctrPositiveFinalAllocation,
          ctrAllFundsAllocationsP1: ctrAllFundsAllocations
        });
        
        this.p1CtrRandom = utilityFunctions.getRandomNumber();
        this.setState({fundP2CtrPositiveAllocations: [], ctrAllFundsAllocationsP2:[]});
    } else {        
        this.setState({
          fundP2CtrPositiveAllocations: ctrPositiveFinalAllocation,
          ctrAllFundsAllocationsP2: ctrAllFundsAllocations
        });
        this.p2CtrRandom = utilityFunctions.getRandomNumber();
        this.setState({fundP1CtrPositiveAllocations: [], ctrAllFundsAllocationsP1:[]});
    }
  }
  contributionToRiskFinalAllocation = (index, selectedEventDate) => {
    this.ctrIndex = index;
    let timeInMonths = utilityFunctions.getDiffInMonths(this.props.hypotheticalState.startPeriod, selectedEventDate);
    this.props.requestContributionToRiskFinalAllocationApi(this.props.workingCopyConatainerIds.split(',')[index-1], timeInMonths, selectedEventDate)
  }

  getFundsAmountByDate = (index, selectedEventDate) => {
    this.ctrIndex = index;
    let investmentList = [];
    let dateBegin = this.props.hypotheticalState.startPeriod - 1;
    //If dateBegin and dateEnd are same, then add 1 day to dateEnd
    selectedEventDate = selectedEventDate - 1;
    selectedEventDate = dateBegin == selectedEventDate ? (selectedEventDate + 1) : selectedEventDate;
    this.props.hypotheticalState.portfolios[index-1].funds.forEach(item=>{
      investmentList.push({investmentId: item.investmentId, wsodIssue: item.wsodissue})
    });

    this.props.requestFundAmountByDateApi(investmentList, this.props.workingCopyConatainerIds.split(',')[index-1], dateBegin, selectedEventDate);
  }
  
  componentDidMount() {
    this.props.comingFromHypotheticals(true);
    this.populateTotalInvestment();
    if(this.props.isFirstTimeToHypotheticals && !this.props.isFromModuleSelection) {
      this.onSelectedTimePeriod(this.props.hypotheticalState.selectedPeriod, 1, this.props.hypotheticalState);
      this.props.firstTimeToHypotheticals(false);
    }

    this.props.requestGetEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
    this.props.requestGetEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);

    //For Fund investment schedules
    this.props.requestGetInvestmentSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
    this.props.requestGetInvestmentSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);

    //For Fund investment schedules
    this.props.requestGetWithdrawalSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
    this.props.requestGetWithdrawalSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);

    this.updateInitAllocCheckbox(this.props.hypotheticalState);
    this.props.hypoInitAlloc(this);

    // Whenever user changes view, update
    this.props.setComparisonInvestmentDetails();
    this.IsUpdateInitialInvestment = true;
  }

  componentDidUpdate() {
    this.populateTotalInvestment();
  }

  componentWillUnmount() {
    this.props.hypoInitAlloc(undefined);
  }

  calledSaveFromPortfolio = (type) => {
    this.setState({calledSaveFromPortfolio:type});
  }
  
  setScheduleDetails(schedules, portfolioFunds) {
    let eventResult = [];
    if (schedules.length > 0) {
      let _type = null;
      let _title = null;
      if (schedules[0].Type === 'Deposit') {
        _type = ", Invest " + this.props.currencySymbol;  
        _title = 'Investment';
      } else if (schedules[0].Type === 'Withdrawal') {
        _type = ", Withdraw " + this.props.currencySymbol;
        _title = 'Withdrawal';
      }
      let uniqueDates = [...new Set(schedules.map(item => item.DateBegin))];
      for (let i = 0; i < uniqueDates.length; i++) {
        let uniqueInvestments = [...new Set(schedules.filter(x => x.DateBegin == uniqueDates[i]).map(item => item.InvestmentId))];
        let event = { investmentScheduleId: 0 };
        event.investmentInfo = [];
        event.investmentScheduleId = '';
        for (let j = 0; j < uniqueInvestments.length; j++) {
          schedules.filter(x => x.DateBegin == uniqueDates[i] && x.InvestmentId == uniqueInvestments[j]).map(item => {
            event.investmentScheduleId += item.Id + ",";
            event.Type = item.Type;
            event.date = item.DateBegin;
            let fund = portfolioFunds.filter(t => (t.investmentId == item.InvestmentId));
            let info = fund.length > 0 && fund[0].symbol + ", "
            info += (item.Description != 'One-Time') ? investmentFrequencyTypeDict[item.Frequency] : 'One-Time'
            info += _type;
            info += item.CashAmount ? numeral(parseFloat(item.CashAmount.toFixed(2))).format('0,0.00')  : "0";
            if (item.Description != 'One-Time' && (item.IncreaseCashPercent || item.IncreaseCashAmount)) {
              info += ", Increase by " ; 
              if(!item.IncreaseCashPercent && !item.IncreaseCashAmount) {
                  info += "0%"
              } else {
                  info += item.IncreaseCashPercent >= 0 ? ((item.IncreaseCashPercent * 100).toFixed(2) + "%") :  (this.props.currencySymbol + numeral(parseFloat(item.IncreaseCashAmount.toFixed(2))).format('0,0.00')); 
              }
              info += " " + investmentFrequencyTypeDict[item.IncreaseFrequency];
            }
            event.investmentInfo.push(info);
          })
        }
        event.Title = _title;
        if (event.investmentScheduleId !== '') {
          let lastChar = event.investmentScheduleId.slice(-1);
          if (lastChar == ',') {
            event.investmentScheduleId = event.investmentScheduleId.slice(0, -1);
          }
          eventResult.push(event);
        }
      }
      eventResult.forEach(item => {
        if (item.investmentInfo.length > 2) {
          item.investmentInfo = item.investmentInfo.slice(0,2);
          item.investmentInfo[1] = item.investmentInfo[1] + '...';
        }
      })
    }
    return eventResult;
  }

  setFundEventDetails = (fundEvents, portfolioFunds) => {
    let eventResults = [];
    fundEvents.forEach(item => {
      let fund = portfolioFunds.find(t => (t.wsodissue == item.IssueId));
      if (fund) {
        eventResults.push({
          symbol: fund.symbol,
          Type: 'FundTile',
          change: item.Change,
          date: item.Date
        });
      }
    })
    eventResults = this.sortData(eventResults, 'symbol');
    let uniqueDates = [...new Set(eventResults.map(item => item.date))];
    let result = [];
    let fundDetails = {};
    uniqueDates.forEach(x=>{
      fundDetails = {Type: 'FundTile',date:'',convertedToNonZeroAllocation:[],convertedToZeroAllocation:[]};
      fundDetails.date = x;
      fundDetails.convertedToNonZeroAllocation = eventResults.filter(item=>item.date == x && item.change == 1).map(f=>f.symbol);
      fundDetails.convertedToZeroAllocation = eventResults.filter(item=>item.date == x && item.change == -1).map(f=>f.symbol);
      if(fundDetails.convertedToNonZeroAllocation.length > 0 || fundDetails.convertedToZeroAllocation.length > 0) {
        result.push(fundDetails);
      }
    });

    return result;
  }  

  setRebalanceEventDetails(rebalanceSchedules) {
    let rebalanceResult = [];

    rebalanceSchedules.forEach(item => {
      rebalanceResult.push({
        scheduleId: item.Id,
        date: item.DateBegin,
        Frequency: item.Frequency,
        Type: item.Type
      });
    })
    
    return rebalanceResult;
  }

  /**
   * Handle invalid events based on CTR API call (Statistics)
   * If fund has change from zero to non-zero during the portfolio scenario then the fund's ReturnAttributionPercent will be non-zero.
   * @param {number} portfolioIndex portfolio index, either 0 or 1
   * @param {array} ctrResults CTR API call result
   */
  handleInvalidEventCounts = (portfolioIndex, ctrResults) => {
    let investInfo = ctrResults && ctrResults[0] && ctrResults[0].Investment ? ctrResults[0].Investment: [];
    let invalidFunds = this.props.hypotheticalState.portfolios[portfolioIndex].funds.filter(t => t.Invalid || t.amountInvestedPercent == 0);

    invalidFunds.forEach(fund => {
      fund.rebalance = false;
      investInfo.forEach(item => {
        let obj = item.TimeFrame.find(x => x.IssueID == fund.wsodissue);
        if (obj && obj.ReturnAttributionPercent != 0) {
          fund.rebalance = true;
        }
      });
    })

    this.handleInvalidEventCountsUsingFundEvents(portfolioIndex);
  }

  /**
   * Handle invalid events based on fund events API
   * @param {number} portfolioIndex portfolio index, either 0 or 1
   */
  handleInvalidEventCountsUsingFundEvents = (portfolioIndex) => {

    let fundEvents = portfolioIndex == 0 ? this.props.fundEvents: this.props.fundEventsP2;
    fundEvents = fundEvents ? fundEvents.filter(x => x.Change == 1): [];

    if (fundEvents.length > 0) {
      let invalidFunds = this.props.hypotheticalState.portfolios[portfolioIndex].funds.filter(t => !t.rebalance && (t.Invalid || t.amountInvestedPercent == 0));
      invalidFunds.forEach(fund => {
        if (!fund.rebalance) {
          fund.rebalance = (fundEvents.filter(x => x.IssueId == fund.wsodissue).length > 0);  
        }
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) { 
    if(this.props.contributionToRiskFinalAllocation != nextProps.contributionToRiskFinalAllocation) {
      this.populateFundsByCtr(this.ctrIndex, nextProps.contributionToRiskFinalAllocation);
    }

    if(this.props.fundsPresentAmountList != nextProps.fundsPresentAmountList) {
      this.setState({fundsPresentAmountList: nextProps.fundsPresentAmountList});
    }

    if(!lodash.isEqual(this.props.hypotheticalState,nextProps.hypotheticalState) && this.props.hypotheticalState.Id != nextProps.hypotheticalState.Id && nextProps.hypotheticalState.Id) {
      this.onSelectedTimePeriod(nextProps.hypotheticalState.selectedPeriod, 1, nextProps.hypotheticalState);
    }
    
    if(!lodash.isEqual(this.props.hypotheticalState.portfolios,nextProps.hypotheticalState.portfolios)) {
      if(
        this.props.hypotheticalState.portfolios[0].funds.filter(x => (x.Invalid || x.amountInvestedPercent == 0)).length 
        < nextProps.hypotheticalState.portfolios[0].funds.filter(x => (x.Invalid || x.amountInvestedPercent == 0)).length 
     ) {
        this.props.requestGetEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);    
     }

     if(
      this.props.hypotheticalState.portfolios[1].funds.filter(x => (x.Invalid || x.amountInvestedPercent == 0)).length 
      < nextProps.hypotheticalState.portfolios[1].funds.filter(x => (x.Invalid || x.amountInvestedPercent == 0)).length 
   ) {
      this.props.requestGetEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);    
   }

      // Maintain Initial Allocation
      this.updateInitAllocCheckbox(nextProps.hypotheticalState);
    }
    
    if(this.props.scheduleCountId != nextProps.scheduleCountId && nextProps.scheduleCountId) {
      if(this.state.calledSaveFromPortfolio == 'One') {
        if(this.state.schedulesTobeModified.length > 0) {
          this.props.requestModifyEventTemplateDetailsApi(this.state.schedulesTobeModified,this.props.workingCopyConatainerIds.split(',')[0]);
        }

        this.props.requestGetEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);

        this.props.requestGetInvestmentSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
        this.props.requestGetFundEventsApi(this.props.workingCopyConatainerIds.split(',')[0], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
      } else {
        if(this.state.schedulesTobeModified.length > 0) {
          this.props.requestModifyEventTemplateDetailsApi(this.state.schedulesTobeModified,this.props.workingCopyConatainerIds.split(',')[1]);
        }
        
        this.props.requestGetEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);

        this.props.requestGetInvestmentSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
        this.props.requestGetFundEventsP2Api(this.props.workingCopyConatainerIds.split(',')[1], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
      }      
    }

    if(this.props.scheduleWithdrawalCountId != nextProps.scheduleWithdrawalCountId && nextProps.scheduleWithdrawalCountId) {
      if(this.state.calledSaveFromPortfolio == 'One') {
        this.props.requestGetWithdrawalSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
      } else {
        this.props.requestGetWithdrawalSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
      }      
    }

    if(this.props.modifyScheduleCountId != nextProps.modifyScheduleCountId && nextProps.modifyScheduleCountId) {
      if(this.state.calledSaveFromPortfolio == 'One') {
        this.props.requestGetEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
        this.props.requestGetFundEventsApi(this.props.workingCopyConatainerIds.split(',')[0], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
      } else {
        this.props.requestGetEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
        this.props.requestGetFundEventsP2Api(this.props.workingCopyConatainerIds.split(',')[1], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
      }      
    }

    /**
     * Don't use "nextProps.schedules.length > 0" check because it stops 
     * updating events list when there are no events.
     */
    if (this.props.schedules != nextProps.schedules) {
      this.state.eventDetails = this.setRebalanceEventDetails(nextProps.schedules); 
      this.handleEventCount(nextProps.schedules.length);
    }

    if (this.props.schedulesP2 != nextProps.schedulesP2) {
      this.state.eventDetailsP2 = this.setRebalanceEventDetails(nextProps.schedulesP2);
      this.handleEventCount(nextProps.schedulesP2.length);  
    }

    if (this.props.investmentSchedules != nextProps.investmentSchedules) {
      this.state.investmentScheduleDetails = this.setScheduleDetails(nextProps.investmentSchedules, this.props.hypotheticalState.portfolios[0].funds);
    }

    if (this.props.investmentSchedulesP2 != nextProps.investmentSchedulesP2) {
      this.state.investmentScheduleDetailsP2 = this.setScheduleDetails(nextProps.investmentSchedulesP2, this.props.hypotheticalState.portfolios[1].funds);  
    }

    if (this.props.withdrawalSchedules != nextProps.withdrawalSchedules) {
      this.state.withdrawalScheduleDetails = this.setScheduleDetails(nextProps.withdrawalSchedules, this.props.hypotheticalState.portfolios[0].funds);
    }

    if (this.props.withdrawalSchedulesP2 != nextProps.withdrawalSchedulesP2) {
      this.state.withdrawalScheduleDetailsP2 = this.setScheduleDetails(nextProps.withdrawalSchedulesP2, this.props.hypotheticalState.portfolios[1].funds);  
    }

    if (this.props.fundEvents != nextProps.fundEvents) {
      this.state.fundEvents = this.setFundEventDetails(nextProps.fundEvents, this.props.hypotheticalState.portfolios[0].funds);
      // Making CTR API call to handle invalid funds
      this.props.requestContributionToRiskApi(this.props.workingCopyConatainerIds.split(',')[0]);
    }

    if (this.props.fundEventsP2 != nextProps.fundEventsP2) {
      this.state.fundEventsP2 = this.setFundEventDetails(nextProps.fundEventsP2, this.props.hypotheticalState.portfolios[1].funds);
      // Making CTR API call to handle invalid funds
      this.props.requestContributionToRiskP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
    }

    if (this.props.contributionToRisk != nextProps.contributionToRisk) {
      this.handleInvalidEventCounts(0, nextProps.contributionToRisk);
    }

    if (this.props.contributionToRiskP2 != nextProps.contributionToRiskP2) {
      this.handleInvalidEventCounts(1, nextProps.contributionToRiskP2);
    }

    let _result1 = cloneDeep(this.state.investmentScheduleDetails).concat(cloneDeep(this.state.withdrawalScheduleDetails)).concat(cloneDeep(this.state.eventDetails)).concat(cloneDeep(this.state.fundEvents));
    let _result2 = cloneDeep(this.state.investmentScheduleDetailsP2).concat(cloneDeep(this.state.withdrawalScheduleDetailsP2)).concat(cloneDeep(this.state.eventDetailsP2)).concat(cloneDeep(this.state.fundEventsP2));
    
    this.updateEventsList(0, _result1);
    this.updateEventsList(1, _result2);

    if (this.props.removeScheduleResult != nextProps.removeScheduleResult) {
      if (nextProps.removeScheduleResult.response.data.success === true) {
        if(this.state.schedulesTobeModified.length > 0) {
          this.props.requestModifyEventTemplateDetailsApi(this.state.schedulesTobeModified,this.props.workingCopyConatainerIds.split(',')[0]);
        }
        this.props.requestGetEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
        this.props.requestGetFundEventsApi(this.props.workingCopyConatainerIds.split(',')[0], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
      }
    }
    if (this.props.removeScheduleP2Result != nextProps.removeScheduleP2Result) {
      if (nextProps.removeScheduleP2Result.response.data.success === true) {
        if(this.state.schedulesTobeModified.length > 0) {
          this.props.requestModifyEventTemplateDetailsApi(this.state.schedulesTobeModified,this.props.workingCopyConatainerIds.split(',')[1]);
        }
        this.props.requestGetEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
        this.props.requestGetFundEventsP2Api(this.props.workingCopyConatainerIds.split(',')[1], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
      }
    }

    if (this.IsChangeSchedulesTobeModifiedP1) {
      this.IsChangeSchedulesTobeModifiedP1 = false;
      if(this.state.schedulesTobeModified.length > 0) {
        this.props.requestModifyEventTemplateDetailsApi(this.state.schedulesTobeModified,this.props.workingCopyConatainerIds.split(',')[0]);
      }
      this.props.requestGetEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
      this.props.requestGetFundEventsApi(this.props.workingCopyConatainerIds.split(',')[0], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
    }

    if (this.IsChangeSchedulesTobeModifiedP2) {
      this.IsChangeSchedulesTobeModifiedP2 = false;
      if(this.state.schedulesTobeModified.length > 0) {
        this.props.requestModifyEventTemplateDetailsApi(this.state.schedulesTobeModified,this.props.workingCopyConatainerIds.split(',')[1]);
      }
      this.props.requestGetEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
      this.props.requestGetFundEventsP2Api(this.props.workingCopyConatainerIds.split(',')[1], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
    }
    
    // front-fee schedules
    if (!lodash.isEqual(this.props.frontFeeSchedules, nextProps.frontFeeSchedules)) {
      this.setState({
        frontSchedules: nextProps.frontFeeSchedules
      });
    }

    // Fund investment - Remove - Portfolio 1
    if (nextProps.removeFrontInvestmentScheduleResult && (this.props.removeFrontInvestmentScheduleResult !== nextProps.removeFrontInvestmentScheduleResult)) {
      if (nextProps.removeFrontInvestmentScheduleResult.success) {
        this.props.requestGetEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
        this.props.requestGetInvestmentSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
        this.props.requestGetWithdrawalSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
        this.props.requestGetFundEventsApi(this.props.workingCopyConatainerIds.split(',')[0], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
      }
    }

    // Fund investment - Remove - Portfolio 2
    if (nextProps.removeFrontInvestmentScheduleP2Result && (this.props.removeFrontInvestmentScheduleP2Result !== nextProps.removeFrontInvestmentScheduleP2Result)) {
      if (nextProps.removeFrontInvestmentScheduleP2Result.success) {
        this.props.requestGetEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
        this.props.requestGetInvestmentSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
        this.props.requestGetWithdrawalSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
        this.props.requestGetFundEventsP2Api(this.props.workingCopyConatainerIds.split(',')[1], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
      }
    }

    // Initial Allocation - Rebalance
    if (!lodash.isEqual(this.props.updateScheduleResult, nextProps.updateScheduleResult)) {
      if (this.userClickSelectModuleInitAlloc) {
        this.userClickSelectModuleInitAlloc = false;
        let responseData = nextProps.updateScheduleResult.response.data.updateRebalanceResult;
        let maintainInitialAlloc = cloneDeep(this.props.hypotheticalState.maintainInitialAlloc);
        maintainInitialAlloc.scheduleIdP1 = responseData[4].length == 1 ? responseData[4][0].Id: '';
        maintainInitialAlloc.scheduleIdP2 = responseData[5].length == 1 ? responseData[5][0].Id: '';
        this.props.updateHypothicalStateMaintainInitialAlloc(maintainInitialAlloc);
        setTimeout(() => {
          document.getElementById('btnSelectModules').click();  
        }, 1000);
      } else {
        document.getElementById('btnSelectModules').click();  
      }
    }

    // If "Update Inital Investment" is updated, then call fund events
    if (!lodash.isEqual(this.props.comparisonPortfolioInvestments, nextProps.comparisonPortfolioInvestments)) {
      if (this.IsUpdateInitialInvestment) {
        this.IsUpdateInitialInvestment = false;
        if (this.props.hypotheticalState.portfolios.length > 0 && this.props.hypotheticalState.portfolios[0].funds.length > 0) {
          this.props.requestGetFundEventsApi(this.props.workingCopyConatainerIds.split(',')[0], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
          }
          if (this.props.hypotheticalState.portfolios.length > 1 && this.props.hypotheticalState.portfolios[1].funds.length > 0) {
          this.props.requestGetFundEventsP2Api(this.props.workingCopyConatainerIds.split(',')[1], this.props.hypotheticalState.startPeriod, this.props.hypotheticalState.endPeriod);
          }
        }
    }
  }
  modifyEventApi = (schedule,containerId,portfolioType) => {
      let scheduleList = [];
      scheduleList.push(schedule);
      this.props.requestModifyEventTemplateDetailsApi(scheduleList,containerId);
    
  }

  loadTabUI = (e) => {
    let tabErrors = [];
    if (this.state.selectedTab === "Initial Investment") {
      if (e.target.innerText.substring(0, 6) === "Events") {
        this.props.validateInitialInvestment(tabErrors);
        if (this.props.hypotheticalState.maintainInitialAlloc.isCheck == false ) {
          this.updateScheduleInitAlloc = true;
          this.removeRebalanceEventsInitAlloc();
        } else {
          this.props.requestGetEventSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
          this.props.requestGetEventSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
          //For Fund investment schedules
          this.props.requestGetInvestmentSchedulesApi(this.props.workingCopyConatainerIds.split(',')[0]);
          this.props.requestGetInvestmentSchedulesP2Api(this.props.workingCopyConatainerIds.split(',')[1]);
        }
      } 
    }

    if (tabErrors.length > 0) {
      this.props.setValidationModalTabs(tabErrors);
      return false;
    }
    if (this.state.selectedTab !== e.target.innerText) {
      if(e.target.innerText.substring(0, 6) === "Events"){
        Constant.isEventEnabled &&
          this.setState({ selectedTab: e.target.innerText });

        this.props.hypotheticalState.portfolios.length > 0 &&
          this.props.hypotheticalState.portfolios[0].funds.length > 0 &&
          this.props.requestGetFundEventsApi(
            this.props.workingCopyConatainerIds.split(",")[0],
            this.props.hypotheticalState.startPeriod,
            this.props.hypotheticalState.endPeriod
          );

        this.props.hypotheticalState.portfolios.length > 1 &&
          this.props.hypotheticalState.portfolios[1].funds.length > 0 &&
          this.props.requestGetFundEventsP2Api(
            this.props.workingCopyConatainerIds.split(",")[1],
            this.props.hypotheticalState.startPeriod,
            this.props.hypotheticalState.endPeriod
          );
      } else {
        this.setState({ selectedTab: e.target.innerText });
      }
    }

    // Whenever user changes view
    this.props.setComparisonInvestmentDetails();
    this.IsUpdateInitialInvestment = true;
    this.setState({timeRangeScenarioAlertModalIsOpen: false});
  }


  handleChange = (date) => {
    let dateOA = utilityFunctions.convertToOADate(date);
    this.onCustomCalenderDateSelection(dateOA);
  }

  // Populate fund dollars textbox from original values
  populateTotalInvestment = () => {
    let totalInvestment = document.getElementById('total-investment')
    if (totalInvestment) {
      let rawValue = totalInvestment.getAttribute('data-raw-value');
      totalInvestment.value = numeral(parseFloat(rawValue)).format('0,0.00');
    }
  }

  // When user focus out from total investment textbox
  onBlurTotalInvestment = (e) => {
    let totalInvestment = parseFloat(e.target.value.replace(/,/gi, ''));
    if (totalInvestment) {
      this.props.updateHypothicalStateTotalInvestment(totalInvestment);
    }
  }

 // When user selects "Period" in dropdown
 onSelectedTimePeriod = (e, bindingCount, hypotheticalState) => {
   
  let periodSelected = e.value;
  let periodSelectedLabel = e.label;

  // Manage custom calender show/hide
  if (periodSelectedLabel === "Custom") {
    this.setState({ 
      tempSelectedTimePeriod: e,
      fundUnavailableModalIsOpen: false
    });
    this.props.bindPeriodStartDate({label:'Custom', value: -1 }, true, hypotheticalState.customDate, (()=> {
      this.onCustomCalenderDateSelection(hypotheticalState.customDate, 1);
    }));
  } else {
    let fundUnavailableModalIsOpen = false;
    if (periodSelected > 0) {
      this.state.selectedTimePeriodDate = periodSelected;
      for (let i = 0; i < hypotheticalState.portfolios.length; i++) {
        hypotheticalState.portfolios[i].funds.forEach((element) => {
          if (!fundUnavailableModalIsOpen && (utilityFunctions.getStartDateOfNextFullMonth(element.oldestShareClassInceptionDate) > periodSelected)) {
            fundUnavailableModalIsOpen = true;
          }
        })
      }
    }

    // Get selected period
    let selPeriod = hypotheticalState.periodList.find(elem => { 
      return elem.value == e.value; 
    });
    if (fundUnavailableModalIsOpen === true) {
      this.setState({
        tempSelectedTimePeriod: selPeriod,
        fundUnavailableModalIsOpen: true,
        showCancel: !(bindingCount === 1)
      })
    } else {
      this.setState({
        timeRangeScenarioAlertModalIsOpen: bindingCount!==1
    }, () => {
      this.props.bindPeriodStartDate(selPeriod, false, hypotheticalState.customDate, (() => {
        let elem = document.getElementById('selectTimePeriod');
        if (elem) {
          elem.setAttribute('data-period', selPeriod);
        }
        this.props.updateUnavailableFunds(this.state.selectedTimePeriodDate);
      }));
    });
       
    }
  }
}

// When user selects "Period" in dropdown
onSelectedTimePeriod2 = (e, bindingCount) => {

  let periodSelected = e.value;
  let periodSelectedLabel = e.label;

  // Manage custom calender show/hide
  if (periodSelectedLabel === "Custom") {
    this.setState({ 
      tempSelectedTimePeriod: e,
      fundUnavailableModalIsOpen: false
    });
    this.props.bindPeriodStartDate({label:'Custom', value: -1 }, true, this.props.hypotheticalState.customDate, (()=> {
      this.onCustomCalenderDateSelection(this.props.hypotheticalState.customDate, 1);
    }));
  } else {
    let fundUnavailableModalIsOpen = false;
    if (periodSelected > 0) {
      this.state.selectedTimePeriodDate = periodSelected;
      for (let i = 0; i < this.props.hypotheticalState.portfolios.length; i++) {
        this.props.hypotheticalState.portfolios[i].funds.forEach((element) => {
          if (!fundUnavailableModalIsOpen && (utilityFunctions.getStartDateOfNextFullMonth(element.oldestShareClassInceptionDate) > periodSelected)) {
            return fundUnavailableModalIsOpen = true;
          }
        })
      }
    }

    // Get selected period
    let selPeriod = this.props.hypotheticalState.periodList.find(elem => { 
      return elem.value == e.value; 
    });
    if (fundUnavailableModalIsOpen === true) {
      this.setState({
        tempSelectedTimePeriod: selPeriod,
        fundUnavailableModalIsOpen: true,
        showCancel: !(bindingCount === 1)
      })
    } else {
      this.setState({
        timeRangeScenarioAlertModalIsOpen: bindingCount!==1
    }, () => {
      this.props.bindPeriodStartDate(selPeriod, false, this.props.hypotheticalState.customDate, (() => {
        let elem = document.getElementById('selectTimePeriod');
        if (elem) {
          elem.setAttribute('data-period', selPeriod);
        }
        this.props.updateUnavailableFunds(this.state.selectedTimePeriodDate);
      }));
    });
    }
  }
}

// When user clicks on "Cancel" button on funds unavailable confirmation popup
  closeFundUnavailableModal = () => {
    this.setState({
      fundUnavailableModalIsOpen: false,
      showCancel: false
    })
  }

// When user clicks on "OK" button on Time Range Scenario Alert  popup
  closeTimeRangeScenarioAlertModal= () => {
    this.setState({
      timeRangeScenarioAlertModalIsOpen: false
    })
  }

  // When user selects date from "Custom Calender"
  onCustomCalenderDateSelection(e,bindingCount) {
    let fundUnavailableModalIsOpen = false;
    
    // Custom date should not come before oldest common
    e = this.props.hypotheticalState.oldestCommon < e ? e : this.props.hypotheticalState.oldestCommon;
    
    for (let i = 0; i < this.props.hypotheticalState.portfolios.length; i++) {
      this.props.hypotheticalState.portfolios[i].funds.forEach((element) => {
        if (!fundUnavailableModalIsOpen && (utilityFunctions.getStartDateOfNextFullMonth(element.oldestShareClassInceptionDate) > e)) {
          fundUnavailableModalIsOpen = true;
        }
      })
    }
    this.state.selectedTimePeriodDate = e;
    let selPeriod = {label:'Custom', value:-1 };
    if (fundUnavailableModalIsOpen === true) {
      this.setState({
        tempSelectedTimePeriod: selPeriod,
        fundUnavailableModalIsOpen: true,
        customDate: e,
        showCancel: !(bindingCount === 1)
      })
    } else {
      this.setState({
        timeRangeScenarioAlertModalIsOpen: bindingCount!==1
    }, () =>{
      this.props.bindPeriodStartDate(selPeriod, true, e, (() => {
        let elem = document.getElementById('selectTimePeriod');
        if (elem) {
          elem.setAttribute('data-period', selPeriod);
        }
        this.props.updateUnavailableFunds(this.state.selectedTimePeriodDate);
      }));
    });
  }
  }


  // When user clicks on "OK" button on funds unavailable confirmation popup
  handleupdateUnavailableFunds = () => {
    let selectedPeriodValue = this.state.tempSelectedTimePeriod;
    let showCustomDate = !(selectedPeriodValue.value !== -1);
    let dateValue = !(selectedPeriodValue.value !== -1) ? this.state.customDate : 0;
    this.props.bindPeriodStartDate(selectedPeriodValue, showCustomDate, dateValue, (() => {
      this.setState({
        fundUnavailableModalIsOpen: false
      });
      
      let elem = document.getElementById('selectTimePeriod');
      if (elem) {
        elem.setAttribute('data-period', this.state.selectedTimePeriodDate);
      }
  
      this.props.updateUnavailableFunds(this.state.selectedTimePeriodDate);
    }));
  }

 
  handleEventText = (linkText) => {
    if (linkText.substring(0, 5) == 'Event') {
      if (this.props.hypotheticalState.maintainInitialAlloc.isCheck) {
        if (Constant.isEventEnabled) {
              return linkText + ' (Inactive)'
        } 
        return linkText;
      }
      if (this.props.hypotheticalState.portfolios) {
        this.invalidFundCount =
          this.props.hypotheticalState.portfolios[0].funds.filter(
            (x) => !x.rebalance && (x.Invalid || x.amountInvestedPercent == 0)
          ).length +
          this.props.hypotheticalState.portfolios[1].funds.filter(
            (x) => !x.rebalance && (x.Invalid || x.amountInvestedPercent == 0)
          ).length;
      }

      if (Constant.isEventEnabled && this.invalidFundCount > 0) {
        return Constant.eventTabName + " (" + this.invalidFundCount + ")";
      }

      return Constant.eventTabName;
    }
    return linkText;
  }

  /**
   * When user click on delete button to remove the schedules
   * @param {Event} e 
   */
  handleDeleteSchedule = (e) => {
    
    // Get portfolioIndex, containerId and scheduleId for the schedule deletion
    let portfolioIndex = e.currentTarget.getAttribute('data-index');
    let containerId = e.currentTarget.getAttribute('data-containerId');
    let scheduleId = e.currentTarget.getAttribute('data-scheduleId');

    // Handle the rebalance end date adjustment
    this.handleRebalanceAdjustment(portfolioIndex-1, containerId, null, scheduleId);
  }

  /**
   * Called when user clicks on "View Schedule" link button
   * @param {event} e event object
   */
  handleShowFeeSchedules = (e) => {

    // Get required fund details needed for the modal and API call
    let fund = {
      wsodIssue: e.target.getAttribute('data-wsod-issue'),
      symbol: e.target.getAttribute('data-fund-symbol'),
      name: e.target.getAttribute('data-fund-name')
    }

    // Make front-fee schedule API call
    this.props.requestGetEventFrontFeeSchedulesApi(fund.wsodIssue);
    
    // Open modal and fund details
    this.setState({
      feeSchedulesFund: fund,
      feeSchedulesIsOpen: true
    })
  }

  /**
   * Closes the "View Schedule" modal
   */
  closeFeeScheduleModal = () => {
    this.setState({
      feeSchedulesIsOpen: false
    })
  }

  /**
   * When user click on delete button to remove the fund investment
   * @param {Event} e 
   */
  handleDeleteInvestmentSchedule = (e) => {

    // Get portfolioIndex, containerId and scheduleId for the schedule deletion
    let portfolioIndex = e.currentTarget.getAttribute('data-index');
    let containerId = e.currentTarget.getAttribute('data-containerId');
    let scheduleId = e.currentTarget.getAttribute('data-scheduleId');
    
    // Make schedule deletion API call
    if (scheduleId !== '') {
      let arrSchedules = scheduleId.split(',');
      if (portfolioIndex === "1") {
        this.props.requestRemoveEventFrontinvestmentSchedulesApi(arrSchedules, containerId); 
      } else {
        this.props.requestRemoveEventFrontinvestmentSchedulesP2Api(arrSchedules, containerId);
      }  
    }
  }

  /**
   * Returns true if there are user created events (Rebalance/Investments)
   */
  isUserEventsPresent = () => {
    return (this.props.schedules.length > 0) ||
           (this.props.schedulesP2.length > 0) ||
           (this.props.investmentSchedules.length > 0) ||
           (this.props.investmentSchedulesP2.length > 0) ||
           (this.props.withdrawalSchedules.length > 0) ||
           (this.props.withdrawalSchedulesP2.length > 0);
  }

  /**
   * Called when user check/uncheck "Maintain Initial Allocation" checkbox.
   * @param {event} evt event object
   */
  handleInitAllocationCheck = (evt) => {
    if(!evt.target.checked && this.props.isFromModuleSelection && this.isUserEventsPresent()) {      
      this.removeRebalanceEventsInitAlloc(true);     
    } else if (evt.target.checked && this.isUserEventsPresent()) {
      
      let errors = ['The maintain initial allocation functionality can not be used in conjunction with other events. Before you can enable this functionality you must remove all other events.']
      this.props.setValidationModalTabs(errors);

    } else {
      
      let hypotheticalState = cloneDeep(this.props.hypotheticalState);
      hypotheticalState.maintainInitialAlloc.isCheck = evt.target.checked,
      hypotheticalState.maintainInitialAlloc.portOneFreq = evt.target.checked ? this.defaultInitAlloPortFreq: { label: '', value: '' },
      hypotheticalState.maintainInitialAlloc.portTwoFreq = evt.target.checked ? this.defaultInitAlloPortFreq: { label: '', value: '' },
      this.props.updateHypothicalStateMaintainInitialAlloc(hypotheticalState.maintainInitialAlloc);
    }
  }

  /**
   * Update state by checking all funds in both portfolio: non-zero allocation and portfolio start date
   */
  updateInitAllocCheckbox = (hypotheticalState) => {
    if (!this.areAllFundsValidInPortfolios(hypotheticalState)) {
      hypotheticalState.maintainInitialAlloc.isCheck = false;
      this.props.updateHypothicalStateMaintainInitialAlloc(hypotheticalState.maintainInitialAlloc);  
    }
  }
  
  /**
   * Called when user select maintain initial allocation portfolio one dropdown
   */
  onSelectedInitAllocPortFreq = (evt, portIndex) => {
    let hypotheticalState = cloneDeep(this.props.hypotheticalState);
    if (portIndex == 1) {
      hypotheticalState.maintainInitialAlloc.portOneFreq = evt;
    } else if (portIndex == 2) {
      hypotheticalState.maintainInitialAlloc.portTwoFreq = evt;
    }
    this.props.updateHypothicalStateMaintainInitialAlloc(hypotheticalState.maintainInitialAlloc);
  }

  /**
   * Returns true or false if all funds in both the portfolios are valid (non-zero allocation and start period validation)
   * @param {object} hypotheticalState hypotheticalState
   * @param {number} portfolioIndex optional, 0-based, if passed, only that portfolio funds are consider
   */
  areAllFundsValidInPortfolios = (hypotheticalState, portfolioIndex) => {
    let invalidFundsCount = 0;
    if (portfolioIndex > -1) { // portfolio specific
      invalidFundsCount = hypotheticalState.portfolios[portfolioIndex].funds.filter(x => (x.Invalid || x.amountInvestedPercent == 0)).length;
    } else { // In general
      hypotheticalState.portfolios.forEach(portfolio => {
        invalidFundsCount += portfolio.funds.filter(x => (x.Invalid || x.amountInvestedPercent == 0)).length;
      })
    }
    return (invalidFundsCount == 0);
  }

  /**
   * Return style used to enable and disable the "Maintain Initial Allocation" checkbox
   */
  getInitAllocationStyle = () => {
    let validStyle = { 'padding-top': '20px' };
    let invalidStyle = { 'padding-top': '20px','pointer-events': 'none', opacity: 0.4};
    return this.areAllFundsValidInPortfolios(this.props.hypotheticalState) ? validStyle : invalidStyle;
  }
  
  /**
   * Returns investment allocation for the rebalance adding or modifying object
   * @param {number} portfolioIndex portfolio index, 0-based index
   */
  getInitAllocationInvestmentsWithAllocations = (portfolioIndex) => {
    let result = [];
    this.props.hypotheticalState.portfolios[portfolioIndex].funds.forEach((fund) => {
      result.push({
        allocation: fund.amountInvestedPercent, 
        investmentId: fund.investmentId
      })
    })
    return result;
  }

  /**
   * Delete non-"Initial Allocation" events: Rebalance and Fund-Investment
   */
  removeEventsInitAlloc(isCallAddRebalanace) {

    // Step 1: If user has checked "Maintain Initial Allocation"
    if (this.props.hypotheticalState.maintainInitialAlloc.isCheck) {
      
      let arrSchedules = null;

      let containerIdP1 = this.props.workingCopyConatainerIds.split(',')[0];
      let containerIdP2 = this.props.workingCopyConatainerIds.split(',')[1];

      // Step 2-A: Delete existing rebalance for portfolio-1
      arrSchedules = [];
      if (this.props.schedules.length > 0) {
        this.props.schedules.forEach(schedule => {
          arrSchedules.push(schedule.Id);
        })
        this.props.requestRemoveEventSchedulesApi(containerIdP1, arrSchedules.join(','));
      }

      // Step 2-B: Delete existing rebalance for portfolio-2
      arrSchedules = [];
      if (this.props.schedulesP2.length > 0) {
        this.props.schedulesP2.forEach(schedule => {
          arrSchedules.push(schedule.Id);
        })
        this.props.requestRemoveEventSchedulesApi(containerIdP2, arrSchedules.join(','));
      }

      // Step 3-A: Delete existing fund investment for portfolio-1
      arrSchedules = [];
      if (this.props.investmentSchedules.length > 0) {
        this.props.investmentSchedules.forEach(schedule => {
          arrSchedules.push(schedule.Id);
        })
        this.props.requestRemoveEventFrontinvestmentSchedulesApi(arrSchedules, containerIdP1);
      }

      // Step 3-B: Delete existing fund investment for portfolio-2
      arrSchedules = [];
      if (this.props.investmentSchedulesP2.length > 0) {
        this.props.investmentSchedulesP2.forEach(schedule => {
          arrSchedules.push(schedule.Id);
        })
        this.props.requestRemoveEventFrontinvestmentSchedulesP2Api(arrSchedules, containerIdP2);
      }
    }
  }

  

  /**
   * Add "Initial Allocation" Rebalance
   */
	addRebalanceInitAlloc() {

    // Step 1: If user has checked "Maintain Initial Allocation"
    if (this.props.hypotheticalState.maintainInitialAlloc.isCheck) {

      // Step 2-A: Add rebalance for portfolio-1
      if (this.areAllFundsValidInPortfolios(this.props.hypotheticalState, 0)) {

        let schedules1 = {};

        schedules1.dateBegin = this.props.hypotheticalState.startPeriod - 1;
        schedules1.dateEnd = this.props.hypotheticalState.endPeriod;
        schedules1.frequency = this.props.hypotheticalState.maintainInitialAlloc.portOneFreq.value;        
        schedules1.snapPeriods = "End";
        schedules1.includeFees = false;
        schedules1.investmentsWithAllocations = this.getInitAllocationInvestmentsWithAllocations(0);

        this.props.requestSaveEventTemplateDetailsApi(schedules1, this.props.workingCopyConatainerIds.split(',')[0]);
      }

      // Step 2-A: Add rebalance for portfolio-2
      if (this.areAllFundsValidInPortfolios(this.props.hypotheticalState, 1)) {

        let schedules2 = {};

        schedules2.dateBegin = this.props.hypotheticalState.startPeriod - 1;
        schedules2.dateEnd = this.props.hypotheticalState.endPeriod;
        schedules2.frequency = this.props.hypotheticalState.maintainInitialAlloc.portTwoFreq.value;
        schedules2.snapPeriods = "End";
        schedules2.includeFees = false;
        schedules2.investmentsWithAllocations = this.getInitAllocationInvestmentsWithAllocations(1);

        this.props.requestSaveEventTemplateDetailsApi(schedules2, this.props.workingCopyConatainerIds.split(',')[1]);
      }
    }
  }

  /**
   * Delete "Initial Allocation" events
   */
  removeRebalanceEventsInitAlloc(isMainainInitialAllocationUnchecked) {

    // Step 1: If user has unchecked "Maintain Initial Allocation"
    if (this.props.hypotheticalState.maintainInitialAlloc.isCheck == false || isMainainInitialAllocationUnchecked) {

      let containerIdP1 = this.props.workingCopyConatainerIds.split(',')[0];
      let containerIdP2 = this.props.workingCopyConatainerIds.split(',')[1];
      let rebalanceScheduleIdP1 = this.props.hypotheticalState.maintainInitialAlloc.scheduleIdP1;
      let rebalanceScheduleIdP2 = this.props.hypotheticalState.maintainInitialAlloc.scheduleIdP2;

      // Step 2-A: Delete existing "Initial Allocation" rebalance for portfolio-1
      if (rebalanceScheduleIdP1 !== '') {
        this.props.requestRemoveEventSchedulesApi(containerIdP1, rebalanceScheduleIdP1);
      }

      // Step 2-B: Delete existing "Initial Allocation" rebalance for portfolio-2
      if (rebalanceScheduleIdP2 !== '') {
        this.props.requestRemoveEventSchedulesP2Api(containerIdP2, rebalanceScheduleIdP2);
      }

      // Step 3: Set scheduleIdP1 & scheduleIdP2 as empty so that API calls are not made
      let hypotheticalState = cloneDeep(this.props.hypotheticalState);
      hypotheticalState.maintainInitialAlloc.scheduleIdP1 = '';
      hypotheticalState.maintainInitialAlloc.scheduleIdP2 = '';
      hypotheticalState.maintainInitialAlloc.isCheck = false;
      this.props.updateHypothicalStateMaintainInitialAlloc(hypotheticalState.maintainInitialAlloc);
    }
  }

  /**
   * Update the initial allocation: Remove rebalance & fund investment events 
   * then add "Initial Allocation" events
   */
  updateEventsInitAlloc() {
    
    let containerIdP1 = this.props.workingCopyConatainerIds.split(',')[0];
    let containerIdP2 = this.props.workingCopyConatainerIds.split(',')[1];

    let removeData = [];
    let maintainInitialAlloc = this.props.hypotheticalState.maintainInitialAlloc;

    // Step 1: If user has checked "Maintain Initial Allocation"
    if (maintainInitialAlloc.isCheck) {

      let arrSchedules = null;
      
      // Step 2-A: Delete existing rebalance for portfolio-1
      if (maintainInitialAlloc.scheduleIdP1 === '') {
        arrSchedules = [];
        if (this.props.schedules.length > 0) {
          this.props.schedules.forEach(schedule => {
            arrSchedules.push(schedule.Id);
          })
          removeData.push({
            containerId: containerIdP1, scheduleIds: arrSchedules.join(','), type: 'Rebalance'
          })
        } else {
          removeData.push({ containerId: containerIdP1, scheduleIds: '', type: 'Rebalance' });
        }
      } else {
        arrSchedules = [];
        removeData.push({
          containerId: containerIdP1, scheduleIds: maintainInitialAlloc.scheduleIdP1, type: 'Rebalance'
        })
      }

      // Step 2-B: Delete existing rebalance for portfolio-2
      if (maintainInitialAlloc.scheduleIdP2 === '') {
        arrSchedules = [];
        if (this.props.schedulesP2.length > 0) {
          this.props.schedulesP2.forEach(schedule => {
            arrSchedules.push(schedule.Id);
          })
          removeData.push({
            containerId: containerIdP2, scheduleIds: arrSchedules.join(','), type: 'Rebalance'
          })
        } else {
          removeData.push({ containerId: containerIdP2, scheduleIds: '', type: 'Rebalance' });
        }
      } else {
        arrSchedules = [];
        removeData.push({
          containerId: containerIdP2, scheduleIds: maintainInitialAlloc.scheduleIdP2, type: 'Rebalance'
        })
      }

      // Step 3-A: Delete existing fund investment for portfolio-1
      arrSchedules = [];
      if (this.props.investmentSchedules.length > 0) {
        this.props.investmentSchedules.forEach(schedule => {
          arrSchedules.push(schedule.Id);
        })
        removeData.push({
          containerId: containerIdP1, scheduleIds: arrSchedules.join(','), type: 'FundInvestment'
        })
      } else {
        removeData.push({ containerId: containerIdP1, scheduleIds: '', type: 'FundInvestment' });
      }

      // Step 3-B: Delete existing fund investment for portfolio-2
      arrSchedules = [];
      if (this.props.investmentSchedulesP2.length > 0) {
        this.props.investmentSchedulesP2.forEach(schedule => {
          arrSchedules.push(schedule.Id);
        })
        removeData.push({
          containerId: containerIdP2, scheduleIds: arrSchedules.join(','), type: 'FundInvestment'
        })
      } else {
        removeData.push({ containerId: containerIdP1, scheduleIds: '', type: 'FundInvestment' });
      }
    } else {

      // Step 3-A: Delete existing initial allocation rebalance for portfolio-1
      removeData.push({
        containerId: containerIdP1, scheduleIds: this.props.hypotheticalState.maintainInitialAlloc.scheduleIdP1, type: 'Rebalance'
      })

      // Step 3-A: Delete existing initial allocation rebalance for portfolio-2
      removeData.push({
        containerId: containerIdP2, scheduleIds: this.props.hypotheticalState.maintainInitialAlloc.scheduleIdP2, type: 'Rebalance'
      })

      removeData.push({ containerId: containerIdP1, scheduleIds: '', type: 'FundInvestment' });
      removeData.push({ containerId: containerIdP2, scheduleIds: '', type: 'FundInvestment' });
    }

    let addData = [];

    // Step 1: If user has checked "Maintain Initial Allocation"
    if (maintainInitialAlloc.isCheck) {

      if (!maintainInitialAlloc.scheduleIdP1 || maintainInitialAlloc.scheduleIdP1 === '') {
        // Step 2-A: Add rebalance for portfolio-1
        if (this.areAllFundsValidInPortfolios(this.props.hypotheticalState, 0)) {

          let schedules1 = {};

          schedules1.dateBegin = this.props.hypotheticalState.startPeriod - 1;
          schedules1.dateEnd = this.props.hypotheticalState.endPeriod;
          schedules1.frequency = this.props.hypotheticalState.maintainInitialAlloc.portOneFreq.value;
          schedules1.snapPeriods = "End";
          schedules1.includeFees = false;
          schedules1.investmentsWithAllocations = this.getInitAllocationInvestmentsWithAllocations(0);

          addData.push({
            schedule: schedules1, containerId: containerIdP1, type: 'Rebalance'
          })
        } else {
          addData.push({ containerId: containerIdP1, type: 'Rebalance' });
        }
      } else {
        if (this.areAllFundsValidInPortfolios(this.props.hypotheticalState, 0)) {

          let schedules1 = {};

          schedules1.dateBegin = this.props.hypotheticalState.startPeriod - 1;
          schedules1.dateEnd = this.props.hypotheticalState.endPeriod;
          schedules1.frequency = this.props.hypotheticalState.maintainInitialAlloc.portOneFreq.value;
          schedules1.snapPeriods = "End";
          schedules1.includeFees = false;
          schedules1.investmentsWithAllocations = this.getInitAllocationInvestmentsWithAllocations(0);

          addData.push({
            schedule: schedules1, containerId: containerIdP1, type: 'Rebalance'
          })
        }
      }

      if (!maintainInitialAlloc.scheduleIdP2 || maintainInitialAlloc.scheduleIdP2 === '') {
        // Step 2-A: Add rebalance for portfolio-2
        if (this.areAllFundsValidInPortfolios(this.props.hypotheticalState, 1)) {

          let schedules2 = {};

          schedules2.dateBegin = this.props.hypotheticalState.startPeriod - 1;
          schedules2.dateEnd = this.props.hypotheticalState.endPeriod;
          schedules2.frequency = this.props.hypotheticalState.maintainInitialAlloc.portTwoFreq.value;
          schedules2.snapPeriods = "End";
          schedules2.includeFees = false;
          schedules2.investmentsWithAllocations = this.getInitAllocationInvestmentsWithAllocations(1);

          addData.push({
            schedule: schedules2, containerId: containerIdP2, type: 'Rebalance'
          })
        } else {
          addData.push({ containerId: containerIdP2, type: 'Rebalance' });
        }
      } else {
        if (this.areAllFundsValidInPortfolios(this.props.hypotheticalState, 1)) {

          let schedules2 = {};

          schedules2.dateBegin = this.props.hypotheticalState.startPeriod - 1;
          schedules2.dateEnd = this.props.hypotheticalState.endPeriod;
          schedules2.frequency = this.props.hypotheticalState.maintainInitialAlloc.portTwoFreq.value;
          schedules2.snapPeriods = "End";
          schedules2.includeFees = false;
          schedules2.investmentsWithAllocations = this.getInitAllocationInvestmentsWithAllocations(1);

          addData.push({
            schedule: schedules2, containerId: containerIdP2, type: 'Rebalance'
          })
        }
      }

    } else {
      addData.push({ containerId: containerIdP1, type: 'Rebalance' });
      addData.push({ containerId: containerIdP2, type: 'Rebalance' });
    }

    let inputParam = { removeData: removeData, addData: addData }
    if (maintainInitialAlloc.isCheck) {
      this.userClickSelectModuleInitAlloc = true;
    }
    this.props.requestUpdateEventSchedulesApi(inputParam);
  }

  /**
   * @param {number} portfolioIndex 0-based
   * @param {string} containerId containerId
   * @param {object} addSchedule schedule object to be added
   * @param {string} deletedScheduleId scheduleId to be deleted
   * @param {string} invWithSchedule invest/withdrawal schedule
   * Note: either add or delete or invest/withdrawal
   */
  handleRebalanceAdjustment = (portfolioIndex, containerId, addSchedule, deletedScheduleId, invWithSchedule) => {
    let modifySchedules = [];
    let tmpSchedules = portfolioIndex == 0 ? this.props.schedules : this.props.schedulesP2;
    
    let dateBegin = null;
    if (addSchedule) {
      dateBegin = addSchedule.dateBegin;
    } else if (deletedScheduleId !== '') {
      dateBegin = tmpSchedules.find(x => x.Id == deletedScheduleId).DateBegin;
    } else if (invWithSchedule) {
      dateBegin = invWithSchedule.dateBegin - 1;
    }

    if (tmpSchedules.length > 0) {
      let schedulesEarlier = tmpSchedules.filter(x => x.DateBegin < dateBegin);
      let schedulesLater = tmpSchedules.filter(x => x.DateBegin > dateBegin);

      if (schedulesEarlier.length > 0) {
        let _dateEnd = null;
        if (addSchedule) {
          _dateEnd = addSchedule.dateBegin - 1;
        } else if (deletedScheduleId !== '') {
          _dateEnd = schedulesLater.length == 0 ? this.props.hypotheticalState.endPeriod: schedulesLater[0].DateBegin;
        } else if (invWithSchedule) {
          _dateEnd = invWithSchedule.dateBegin;
        }
        
        // To make rebalances non-consecutive first rebalance will close 1 day prior to next rebalance.
        if(!(deletedScheduleId !== '' && schedulesLater.length == 0)) {
          _dateEnd = _dateEnd - 1;
        }

        modifySchedules.push({
          id: schedulesEarlier[schedulesEarlier.length - 1].Id,
          frequency: schedulesEarlier[schedulesEarlier.length - 1].Frequency,
          dateBegin: schedulesEarlier[schedulesEarlier.length - 1].DateBegin,
          dateEnd: _dateEnd,
          investmentsWithAllocations: [],
          includeFees: false,
          snapPeriods: 'End'
        })
      }
      // To make rebalances non-consecutive first rebalance will close 1 day prior to next rebalance.
      if (addSchedule && schedulesLater.length > 0) {
        addSchedule.dateEnd = schedulesLater[0].DateBegin - 1;  
      }
    }

    this.setState({ schedulesTobeModified: modifySchedules });
    
    if (invWithSchedule) {
      if (portfolioIndex == 0) {
        this.IsChangeSchedulesTobeModifiedP1 = true;
      } else {
        this.IsChangeSchedulesTobeModifiedP2 = true;
      }  
    }

    if (addSchedule) {
      addSchedule.dateBegin = addSchedule.dateBegin - 1;
      this.props.requestSaveEventTemplateDetailsApi(addSchedule, containerId);
    } else if (deletedScheduleId !== '') {
      if (portfolioIndex === 0) {
        this.props.requestRemoveEventSchedulesApi(containerId, deletedScheduleId);
      } else {
        this.props.requestRemoveEventSchedulesP2Api(containerId, deletedScheduleId);
      }
    }
  }

  sortData = (array, key) => {
    return array.sort(function (a, b) {
      let x = a[key]; let y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  updateEventsList = (portfolioIndex, allEvents) => {
    
    if (portfolioIndex === 0) {
      if (!lodash.isEqual(allEvents, this.state.eventDetailsAll)) {
        allEvents = this.sortData(allEvents, 'date');
        this.setState({ eventDetailsAll: allEvents });
      }  
    } else if (portfolioIndex === 1) {
      if (!lodash.isEqual(allEvents, this.state.eventDetailsAllP2)) {
        allEvents = this.sortData(allEvents, 'date')
        this.setState({ eventDetailsAllP2: allEvents });
      }
    }
  };

  render() {

    // Maintain Inital Allocation: Start
    let isCheck = this.props.hypotheticalState.maintainInitialAlloc.isCheck;
    let initAlloPortOneFreq = this.props.hypotheticalState.maintainInitialAlloc.portOneFreq;
    let initAlloPortTwoFreq = this.props.hypotheticalState.maintainInitialAlloc.portTwoFreq;
    let isShowPortOneFreq = this.props.hypotheticalState.portfolios[0].funds.length > 0;
    let isShowPortTwoFreq = this.props.hypotheticalState.portfolios[1].funds.length > 0;
    let isEnableInitAllocCheckbox = this.areAllFundsValidInPortfolios(this.props.hypotheticalState);
    // Maintain Inital Allocation: End

    // Get portfolio for PortfolioInvestmentTable
    let totalInvestment = 0;

    if (this.props.hypotheticalState) {

      if (this.props.hypotheticalState.portfolios) {
      }

      if (this.props.hypotheticalState.totalInvestment) {
        totalInvestment = this.props.hypotheticalState.totalInvestment;
      }

    }
    const customTheme = {
      borderRadius: 0,
      border: 0,
      colors: {
          primary: '#f4f4f4',
          primary50: '#f4f4f4',
          neutral0: '#FFF',
      },
  }
  const customStyles = {
      control: (base, state) => ({
          ...base,
          border: '1px solid rgba(34,36,38,.15) !important',
          boxShadow: '0 !important',
          '&:hover': {
              border: '1px solid rgba(34,36,38,.15) !important'
          },
          height: '34px',
          'min-height': '34px'
      }),
      option: (provided, state) => ({
          ...provided,
          background: state.isSelected ? '#f4f4f4' : (state.isFocused ? '#f4f4f4' : '#FFF'),
          color: '#333',
          'font-weight': state.isSelected ? 'bold' : 'normal'
      }),
      menu: styles => ({ ...styles, margin: '0px' }),
      noOptionsMessage: styles => ({ ...styles, 'text-align': 'left', color: 'rgba(0,0,0,.4)' })
  }

  const DropdownIndicator = props => {
      return (
          components.DropdownIndicator && (
              <components.DropdownIndicator {...props}>
                  <i
                      className="dropdown"
                      aria-hidden="true"
                  />
              </components.DropdownIndicator>
          )
      );
  };
  
    return (
      <div className={'hypotheticalsModal'}>
        {/* Tabs section */}
        <div className={'hypothetical'}>
          <Tabs
            tabsList={this.tabsList}
            onClick={this.loadTabUI}
            selectedTab={this.state.selectedTab}
            linkText={this.handleEventText}
            isShowMaintainInitialSection ={Constant.isEventEnabled ? this.props.hypotheticalState.maintainInitialAlloc.isCheck : true}
          />
        </div>
    { this.state.selectedTab == 'Initial Investment' && 
      <div className={'hypothetical-data'}>
          {/* Filter - Header */}
          <div className={'hypothetical-filter'}>
            {/* Total Investment - Textbox */}
            <div className={'total-investment'}>
              <span className={'font-bold'}>Total Investment</span>
              <div>{this.props.currencySymbol + ' '} <input
                autoComplete="off"
                type="text"
                id="total-investment"
                defaultValue={totalInvestment}
                onBlur={this.onBlurTotalInvestment}
                onKeyDown={utilityFunctions.allowOnlyNumbers}
                data-raw-value={totalInvestment}
                className={'text-right text-width-small pad-right-7'}
              /></div>
            </div>
            <div className={'filter-styles'}>
              <div className="label-style-period">
                <span className="label-period">Period <span className={'inception-label-hypo'}> * Index Inception {(this.props.userType.response === 'International') ? utilityFunctions.formatOADate(this.props.indexInception.inceptionDate, "DD/MM/YYYY") : utilityFunctions.formatOADate(this.props.indexInception.inceptionDate, "MM/DD/YYYY")}</span></span>
                <Select
                      name="select-time-period"
                      className="dropdown-mfs"
                      theme={customTheme}
                      styles={customStyles}
                      isSearchable={false}
                      defaultValue={this.props.hypotheticalState.selectedPeriod}
                      value={this.props.hypotheticalState.selectedPeriod}
                      onChange={this.onSelectedTimePeriod2}
                      options={this.props.hypotheticalState.periodList}
                      components={{ DropdownIndicator }}
                      id="selectTimePeriod" />
              </div>
            </div>
            {this.props.hypotheticalState.showCustomCalendar &&
              <div className="select-investment-period">
                <span className="label-investment-period">Custom Start Date</span>
                <CustomCalendar 
                  showCustomCalendar={this.state.showCustomCalendar}
                  hypotheticalState={this.props.hypotheticalState}
                  selectedTimePeriodDate={new Date(moment(utilityFunctions.formatOADate(this.props.hypotheticalState.customDate)))}
                  handleChange = {this.handleChange}
                  onFocus={e => { e.preventDefault(); e.target.blur();}}
                  module = {this.state.selectedTab.toString().indexOf('Events') > -1 ?  "Events": ""}/>
              </div>}
              
              {/* Maintain Initial Allocation */}
              <div className={'total-investment'}>
                <label className="lbl-maintain-initial-allocation" style={this.getInitAllocationStyle()}>
                  <input type={'checkbox'} 
                         id="chkMaintainInitialAllocation" 
                         name='maintain-initial-allocation' 
                         onChange={this.handleInitAllocationCheck} 
                         checked={isCheck} />
                  Maintain Initial Allocation
                </label>
              </div>

              {/* Maintain Initial Allocation - Portfolio One Frequency  */}
              {isEnableInitAllocCheckbox && isCheck && isShowPortOneFreq && 
                <div id="dvPortfolioOneFrequency" className={'total-investment'}>
                <span className={'font-bold'}>Portfolio One Frequency</span>
                <div>
                  <Select
                    name="init-alloc-port-freq-one"
                    className="dropdown-mfs"
                    theme={customTheme}
                    styles={customStyles}
                    isSearchable={false}									
                    value={initAlloPortOneFreq}
                    onChange={e => this.onSelectedInitAllocPortFreq(e, 1)}
                    options={frequencyTypes}
                    components={{ DropdownIndicator }}
                    id="init-alloc-port-one-freq" />
                </div>
                </div>
              }

              {/* Maintain Initial Allocation - Portfolio Two Frequency */}
              {isEnableInitAllocCheckbox && isCheck && isShowPortTwoFreq && 
                <div id="dvPortfolioTwoFrequency" className={'total-investment'}>
                <span className={'font-bold'}>Portfolio Two Frequency</span>
                <div>
                  <Select
                    name="init-alloc-port-freq-two"
                    className="dropdown-mfs"
                    theme={customTheme}
                    styles={customStyles}
                    isSearchable={false}									
                    value={initAlloPortTwoFreq}
                    onChange={e => this.onSelectedInitAllocPortFreq(e, 2)}
                    options={frequencyTypes}
                    components={{ DropdownIndicator }}
                    id="init-alloc-port-two-freq" />
                </div>
                </div>
              }

          </div>
          {/* Portfolio Investment table - Main */}
          <div className={'portfolio-investment'}>
            {/* Portfolio Investment table - First */}
            <div className={'portfolio-investment1'}>
              {this.props.hypotheticalState.portfolios.length > 0 &&
                <PortfolioInvestmentTable
                  updateHypothicalStateFundValuesPercents={this.props.updateHypothicalStateFundValuesPercents}
                  index={0}
                  isFirstTime={this.props.hypotheticalState.isFirstTime}
                  hypotheticalState={this.props.hypotheticalState}
                  hideBenchmarkAndHypotheticalsResults={this.props.hideBenchmarkAndHypotheticalsResults}
                  currencySymbol={this.props.currencySymbol}
                  userType={this.props.userType.response}
                />}
            </div>
            {/* Portfolio Investment table - Second */}
            <div className={'portfolio-investment2'}>
              {this.props.hypotheticalState.portfolios.length > 0 &&
                <PortfolioInvestmentTable
                  updateHypothicalStateFundValuesPercents={this.props.updateHypothicalStateFundValuesPercents}
                  index={1}
                  isFirstTime={this.props.hypotheticalState.isFirstTime}
                  hypotheticalState={this.props.hypotheticalState}
                  hideBenchmarkAndHypotheticalsResults={this.props.hideBenchmarkAndHypotheticalsResults}
                  currencySymbol={this.props.currencySymbol}
                  userType={this.props.userType.response}
                />}
            </div>
          </div>
          </div>
          }
          {this.props.hypotheticalState.portfolios.length > 0 &&
          <EventsModule 
            isSelected={this.state.selectedTab.toString().indexOf('Events') > -1}
            hypotheticalState = {this.props.hypotheticalState}
            hypoStartPeriod = {utilityFunctions.getEndDateOfPrevNextNMonth(this.props.hypotheticalState.startPeriod, 1)}
            hideBenchmarkAndHypotheticalsResults={this.props.hideBenchmarkAndHypotheticalsResults}
            handleChange = {this.handleChange}
            requestSaveEventTemplateDetailsApi = {this.handleRebalanceAdjustment}
            requestSaveInvestmentEventApi = {this.props.requestSaveInvestmentEventApi}
            requestSaveWithdrawalEventApi = {this.props.requestSaveWithdrawalEventApi}
            workingCopyConatainerIds = {this.props.workingCopyConatainerIds}
            calledSave={this.calledSaveFromPortfolio}
            handleDeleteSchedule={this.handleDeleteSchedule}
            schedules={this.props.schedules}
            schedulesP2={this.props.schedulesP2}
            requestModifyEventTemplateDetailsApi = {this.modifyEventApi}     
            handleEventCount = {this.handleEventCount}     
            validateSameDayEvent = {this.props.validateSameDayEvent} 
            handleDeleteInvestmentSchedule={this.handleDeleteInvestmentSchedule}
            investmentSchedules={this.props.investmentSchedules}
            investmentSchedulesP2={this.props.investmentSchedulesP2}
            withdrawalSchedules={this.props.withdrawalSchedules}
            withdrawalSchedulesP2={this.props.withdrawalSchedulesP2}
            selectedTab = {this.state.selectedTab}
            isShowMaintainInitialSection ={this.props.hypotheticalState.maintainInitialAlloc.isCheck}
            requestRemoveEventFrontinvestmentSchedulesApi={this.props.requestRemoveEventFrontinvestmentSchedulesApi}
            handleRebalanceAdjustment={this.handleRebalanceAdjustment}
            eventDetailsAll={this.state.eventDetailsAll}
            eventDetailsAllP2={this.state.eventDetailsAllP2}
            contributionToRiskFinalAllocation={this.contributionToRiskFinalAllocation}
            fundP1CtrPositiveAllocations={this.state.fundP1CtrPositiveAllocations}
            fundP2CtrPositiveAllocations={this.state.fundP2CtrPositiveAllocations}
            ctrAllFundsAllocationsP1 = {this. state.ctrAllFundsAllocationsP1}
            ctrAllFundsAllocationsP2 = {this. state.ctrAllFundsAllocationsP2}
            p1CtrRandom={this.p1CtrRandom}
            p2CtrRandom={this.p2CtrRandom}
            getFundsAmountByDate={this.getFundsAmountByDate}
            fundsPresentAmountList={this.state.fundsPresentAmountList}
            currencySymbol={this.props.currencySymbol}
            userType={this.props.userType.response}
            />
          } 
          {/* {this.props.hypotheticalState.portfolios.length > 0 &&
          <SalesChargesModule 
            isSelected={this.state.selectedTab.toString().indexOf('Sales Charge') > -1}
            hypotheticalState = {this.props.hypotheticalState}
            hideBenchmarkAndHypotheticalsResults={this.props.hideBenchmarkAndHypotheticalsResults}
            handleChange = {this.handleChange}
            requestSaveEventTemplateDetailsApi = {this.props.requestSaveEventTemplateDetailsApi}
            workingCopyConatainerIds = {this.props.workingCopyConatainerIds}
            selectedTab={this.state.selectedTab}
            // Events
            requestAddEventFeeSchedulesApi={this.props.requestAddEventFeeSchedulesApi}
            requestAddEventFeeSchedulesP2Api={this.props.requestAddEventFeeSchedulesP2Api}
            requestModifyEventFeeSchedulesApi={this.props.requestModifyEventFeeSchedulesApi}
            requestModifyEventFeeSchedulesP2Api={this.props.requestModifyEventFeeSchedulesP2Api}
            requestRemoveEventFeeSchedulesApi={this.props.requestRemoveEventFeeSchedulesApi}
            requestRemoveEventFeeSchedulesP2Api={this.props.requestRemoveEventFeeSchedulesP2Api}
            requestGetEventFeeSchedulesApi={this.props.requestGetEventFeeSchedulesApi}
            requestGetEventFeeSchedulesP2Api={this.props.requestGetEventFeeSchedulesP2Api}
            // Events Results
            addFeeScheduleResult={this.props.addFeeScheduleResult}
            addFeeScheduleP2Result={this.props.addFeeScheduleP2Result}
            modifyFeeScheduleResult={this.props.modifyFeeScheduleResult}
            modifyFeeScheduleP2Result={this.props.modifyFeeScheduleP2Result}
            removeFeeScheduleResult={this.props.removeFeeScheduleResult}
            removeFeeScheduleP2Result={this.props.removeFeeScheduleP2Result}
            feeSchedules={this.props.feeSchedules}
            feeSchedulesP2={this.props.feeSchedulesP2}
            // Load Fee Schedules
            requestGetEventLoadFeeSchedulesApi={this.props.requestGetEventLoadFeeSchedulesApi}
            loadFeeSchedules={this.props.loadFeeSchedules}
            handleShowFeeSchedules={this.handleShowFeeSchedules}
          />
          } */}
          {/* Funds Not Available Modal */}
          <FundScenarioUnavailable
            fundUnavailableModalIsOpen={this.state.fundUnavailableModalIsOpen}
            isUserEventsPresent = {this.isUserEventsPresent()}
            customStyles={CustomModalStyles.SaveModalStyles}
            closeFundUnavailableModal={this.closeFundUnavailableModal}
            handleUpdateUnavailableFunds={this.handleupdateUnavailableFunds}
            showCancel={this.state.showCancel}
          />
          {this.isUserEventsPresent() && this.state.selectedTab === "Initial Investment" ?
          <TimeRangeScenarioAlertModal
            timeRangeScenarioAlertModalIsOpen={this.state.timeRangeScenarioAlertModalIsOpen}
            customStyles={CustomModalStyles.SaveModalStyles}
            closeTimeRangeScenarioAlertModal={this.closeTimeRangeScenarioAlertModal}
          /> : null}
      </div>
    );
  }
}

Hypotheticals.PropTypes = {
  toggleCompareButton: PropTypes.func,
  requestGetBenchmarksApi: PropTypes.func,
  requestGetPrimaryBenchmarkApi: PropTypes.func,
  getPrimaryProspectusBenchmarkData: PropTypes.func,
  isSelected: PropTypes.bool,
  hypotheticalState: PropTypes.object,
  bindPeriodStartDate: PropTypes.func,  
  comingFromHypotheticals: PropTypes.func,  
  validateInitialInvestment: PropTypes.func,
	eventsValidate: PropTypes.func,
  setValidationModalTabs: PropTypes.func,
  firstTimeToHypotheticals: PropTypes.func,
  validateSameDayEvent: PropTypes.func
};
import * as utilityFunctions from "../utils/commonMethods";

export const MAX_SCREENER_OUTPUT_LIMIT = 200;

export const CurrencyList = [
	{ label: 'USD', value: 'USD' },
	{ label: 'CHF', value: 'CHF' },
	{ label: 'EUR', value: 'EUR' },
	{ label: 'GBP', value: 'GBP' }
];

export const templateResult = {
	funds: {
		fundinfocus: [],
		nonfundinfocus: [],
		benchmark: [],
		primarybenchmark: []
	}
};

export const idsWhiteListed = ['wweimer@mfs.com', 'fulksa@mfs.com', 'bmcgrath@mfs.com', 'qatester', 'testuser', 'testuser1','chrgriffin@mfs.com', 'ssiegel@mfs.com', 'famado@mfs.com', 'cgrennon@mfs.com', 'dawood@mfs.com', 'gmondavi@mfs.com', 'dmaher@mfs.com', 'tblossom@mfs.com', 'kcoughlin@mfs.com', 'dgrundfast@mfs.com', 'bfancy@mfs.com', 'imcguire@mfs.com','jangelo@mfs.com','brarmstrong@mfs.com','cbent@mfs.com','hbodozian@mfs.com','abrown@mfs.com','mcalabrese@mfs.com','achristopulos@mfs.com','kdejesus@mfs.com','adiozzi@mfs.com','ddriscoll@mfs.com','cedgcomb@mfs.com','eflint@mfs.com','tfraunces@mfs.com','jgarrelick@mfs.com','jgaudino@mfs.com','jhenley@mfs.com','nhill@mfs.com','lhoyt@mfs.com','lhubbard@mfs.com','akazlowski@mfs.com','gkimmerer@mfs.com','nlynch@mfs.com','lmacdonald@mfs.com','jmathews@mfs.com','dmccrossan@mfs.com','kmcgrath@mfs.com','amessner@mfs.com','cmeyer@mfs.com','kmorrisino@mfs.com','kmullen@mfs.com','omurakami@mfs.com','cnangle@mfs.com','mnevill@mfs.com','toc@mfs.com','miodonnell@mfs.com','joliphant@mfs.com','npalazzo@mfs.com','jpeters@mfs.com','spierre-louis@mfs.com','crhodes@mfs.com','dtracy@mfs.com','ngulley@mfs.com','rvanriper@mfs.com','rwheatley@mfs.com','dwilliams@mfs.com','ayosaitis@mfs.com','arkhan@mfs.com','achristopulous@mfs.com','wbobear@mfs.com','jcraig@mfs.com','tfalcone@mfs.com','jharrington@mfs.com','rwyman@mfs.com','cgriffiths@mfs.com'];

export const eventTabName = 'Events';
export const isEventEnabled = true;

export const salesChargesTabName = 'Sales Charge - Coming Soon';
export const isSalesChargesEnabled = false;

export const templateData =
{
	funds: {
		fundinfocus: {
			symbol: 'MBDIX',
			wsodissue: '300282',
			fundfamily: 'MFS'
		},
		nonfundinfocus: [
			{
				symbol: 'PIGIX',
				wsodissue: '305779',
				fundfamily: 'OtherFund'
			}
		],
		benchmark: {
			symbol: 'BBgBarc US Credit TR USD',
			wsodissue: 220205306,
			fundfamily: 'PrimaryBenchmark'
		},
		primarybenchmark: {
			symbol: 'BBgBarc US Credit TR USD',
			wsodissue: 220205306,
			fundfamily: 'PrimaryBenchmark'
		}
	},
	sections: [],
	isMarketingPage: true
};

export const sectionMasterData =
{
	sections: [
		{
			sectionid: '1',
			sectionname: 'Funds In Focus',
			sectiondisplayname: 'Funds In Focus',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Portfolio Basics',
					subsectiondisplayname: 'Portfolio Basics',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Fund Basics',
					subsectiondisplayname: 'Fund Basics',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'Trailing Returns & Standard Deviation',
					subsectiondisplayname: 'Trailing Returns & Standard Deviation',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				}
			],
			isLocked: true,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '2',
			sectionname: 'Performance',
			sectiondisplayname: 'Performance',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Growth Of 100K',
					subsectiondisplayname: 'Growth Of 100K',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Calendar Year Returns',
					subsectiondisplayname: 'Calendar Year Returns',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '3',
			sectionname: 'Rolling Time Frames',
			sectiondisplayname: 'Rolling Time Frames',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Rolling Returns',
					subsectiondisplayname: 'Rolling Returns',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Rolling Sharpe Ratio',
					subsectiondisplayname: 'Rolling Sharpe Ratio',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'Rolling Excess Returns',
					subsectiondisplayname: 'Rolling Excess Returns',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '4',
			sectionname: 'Risk & Performance Statistics',
			sectiondisplayname: 'Risk & Performance Statistics',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Up/Down Capture Ratio',
					subsectiondisplayname: 'Up/Down Capture Ratio',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'MPT Statistics',
					subsectiondisplayname: 'MPT Statistics',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'Morningstar Percentile Rank',
					subsectiondisplayname: 'Morningstar Percentile Rank',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '5',
			sectionname: 'Standardized Performance',
			sectiondisplayname: 'Standardized Performance',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Fund Information',
					subsectiondisplayname: 'Fund Information',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Total & Load Adjusted Returns',
					subsectiondisplayname: 'Total & Load Adjusted Returns',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'Market Returns',
					subsectiondisplayname: 'Market Returns',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 4,
					name: 'Morningstar Ratings',
					subsectiondisplayname: 'Morningstar Ratings',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 5,
					name: 'Sustainability Rating',
					subsectiondisplayname: 'Sustainability Rating',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				}
			],
			isLocked: true,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '6',
			sectionname: 'Equity & Multi-Asset',
			sectiondisplayname: 'Equity & Multi-Asset Overview',
			tabName: 'Fixed Income & Equity Overview',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Equity Characteristics',
					subsectiondisplayname: 'Equity Characteristics',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 2,
					name: 'Equity Geographic Exposure',
					subsectiondisplayname: 'Equity Geographic Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 3,
					name: 'Equity Style Box Exposure',
					subsectiondisplayname: 'Equity Style Box Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 4,
					name: 'Equity Market Cap Exposure',
					subsectiondisplayname: 'Equity Market Cap Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '7',
			sectionname: 'Fixed Income',
			sectiondisplayname: 'Fixed Income Overview',
			tabName: 'Fixed Income & Equity Overview',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Bond Characteristics',
					subsectiondisplayname: 'Bond Characteristics',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 2,
					name: 'Bond Geographic Exposure',
					subsectiondisplayname: 'Bond Geographic Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 3,
					name: 'Credit Quality Exposure',
					subsectiondisplayname: 'Credit Quality Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 4,
					name: 'Coupon Range Exposure',
					subsectiondisplayname: 'Coupon Range Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 5,
					name: 'Maturity Range Exposure',
					subsectiondisplayname: 'Maturity Range Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '8',
			sectionname: 'Equity & Multi-Asset Inflection Points',
			sectiondisplayname: 'Equity & Multi-Asset Inflection Points',
			tabName: 'Market Inflection Points',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Market Recovery 10/09/2007 – 03/28/2013',
					subsectiondisplayname: 'Market Recovery 10/09/2007 – 03/28/2013',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Market Recovery 05/21/2015 – 07/11/2016',
					subsectiondisplayname: 'Market Recovery 09/20/2018 – 04/23/2019',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'Market Recovery 04/29/2011 – 02/24/2012',
					subsectiondisplayname: 'Market Recovery 02/19/2020 – 08/18/2020',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 4,
					name: 'Rising Rates 05/02/2013 – 09/05/2013',
					subsectiondisplayname: 'Rising Rates 05/02/2013 – 09/05/2013',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '9',
			sectionname: 'Fixed Income Inflection Points',
			sectiondisplayname: 'Fixed Income Inflection Points',
			tabName: 'Market Inflection Points',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Market Recovery 10/09/2007 – 03/28/2013',
					subsectiondisplayname: 'Market Recovery 10/09/2007 – 03/28/2013',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Rising Rates 05/02/2013 – 09/05/2013',
					subsectiondisplayname: 'Rising Rates 05/02/2013 – 09/05/2013',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'Rising Rates 07/08/2016 – 12/16/2016',
					subsectiondisplayname: 'Rising Rates 07/08/2016 – 12/16/2016',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 4,
					name: 'Rising Rates 09/08/2017 – 11/08/2018',
					subsectiondisplayname: 'Rising Rates 09/08/2017 – 11/08/2018',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '10',
			sectionname: 'Correlation Matrix',
			sectiondisplayname: 'Correlation Matrix',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: ''
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Correlation Matrix US',
					subsectiondisplayname: 'Correlation Matrix',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Excess Return Correlation Matrix',
					subsectiondisplayname: 'Excess Return Correlation Matrix',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '11',
			sectionname: 'Contribution to Risk',
			sectiondisplayname: 'Contribution to Risk',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: ''
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Contribution to Risk',
					subsectiondisplayname: 'Contribution to Risk',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: true,
			flag: '1'
		},
		{
			sectionid: '13',
			sectionname: 'Sustainability Ratings',
			sectiondisplayname: 'Sustainability Ratings',
			tabName: 'Sustainability Ratings',
			inputtype: [
			  {
				inputname: 'TimeRange',
				inputvalues: ''
			  }
			],
			subsections: [
			  {
				id: 1,
				name: 'Morningstar Sustainability Ratings',
				subsectiondisplayname: 'Morningstar Sustainability Ratings',
				isLocked: true,
				isActive: false,
				canbeAddedAndLocked: true
			  },
			  {
				id: 2,
				name: 'Corporate and Soverign',
				subsectiondisplayname: 'Corporate and Soverign',
				isLocked: true,
				isActive: false,
				canbeAddedAndLocked: true
			  },
			  {
				id: 3,
				name: 'ESG Risk Score Distribution',
				subsectiondisplayname: 'ESG Risk Score Distribution',
				isLocked: true,
				isActive: false,
				canbeAddedAndLocked: true
			  }
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		  }
	],
};

export const tafToken = '3zzytBxULAhVaQ7k';

export const sectionMasterDataIntl =
{
	sections: [
		{
			sectionid: '1',
			sectionname: 'Funds In Focus',
			sectiondisplayname: 'Funds In Focus',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Fund Basic',
					subsectiondisplayname: 'Fund Basics',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'MIFID 5 Year Rolling Returns',
					subsectiondisplayname: 'MIFID 5 Year Rolling Returns',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'Portfolio Basics',
					subsectiondisplayname: 'Portfolio Basics',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 4,
					name: 'Key Statistics',
					subsectiondisplayname: 'Key Statistics',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 5,
					name: 'Trailing Returns & Standard Deviation',
					subsectiondisplayname: 'Trailing Returns & Standard Deviation',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				}
			],
			isLocked: true,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '2',
			sectionname: 'Performance',
			sectiondisplayname: 'Performance',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Growth Of 100K',
					subsectiondisplayname: 'Growth Of 100K',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Calendar Year Returns',
					subsectiondisplayname: 'Calendar Year Returns',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '3',
			sectionname: 'Rolling Time Frames',
			sectiondisplayname: 'Rolling Time Frames',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Rolling Returns',
					subsectiondisplayname: 'Rolling Returns',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Rolling Sharpe Ratio',
					subsectiondisplayname: 'Rolling Sharpe Ratio',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'Rolling Excess Returns',
					subsectiondisplayname: 'Rolling Excess Returns',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '4',
			sectionname: 'Risk & Performance Statistics',
			sectiondisplayname: 'Risk & Performance Statistics',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Up/Down Capture Ratio',
					subsectiondisplayname: 'Up/Down Capture Ratio',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'MPT Statistics',
					subsectiondisplayname: 'MPT Statistics',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'Morningstar Percentile Rank',
					subsectiondisplayname: 'Morningstar Percentile Rank',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '5',
			sectionname: 'Standardized Performance',
			sectiondisplayname: 'Standardized Performance',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Fund Information-MIL',
					subsectiondisplayname: 'Fund Information',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Total & Load Adjusted Returns',
					subsectiondisplayname: 'Total & Load Adjusted Returns',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'Market Returns',
					subsectiondisplayname: 'Market Returns',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 4,
					name: 'Morningstar Ratings',
					subsectiondisplayname: 'Morningstar Ratings',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				},
				{
					id: 5,
					name: 'Sustainability Rating Intl',
					subsectiondisplayname: 'Sustainability Rating',
					isLocked: true,
					isActive: true,
					canbeAddedAndLocked: false
				}
			],
			isLocked: true,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '6',
			sectionname: 'Equity & Multi-Asset',
			sectiondisplayname: 'Equity & Multi-Asset Overview',
			tabName: 'Fixed Income & Equity Overview',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Equity Characteristics',
					subsectiondisplayname: 'Equity Characteristics',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 2,
					name: 'Equity Geographic Exposure',
					subsectiondisplayname: 'Equity Geographic Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 3,
					name: 'Equity Style Box Exposure',
					subsectiondisplayname: 'Equity Style Box Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 4,
					name: 'Equity Market Cap Exposure',
					subsectiondisplayname: 'Equity Market Cap Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '7',
			sectionname: 'Fixed Income',
			sectiondisplayname: 'Fixed Income Overview',
			tabName: 'Fixed Income & Equity Overview',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: '10Y,5Y'
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Bond Characteristics',
					subsectiondisplayname: 'Bond Characteristics',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 2,
					name: 'Bond Geographic Exposure',
					subsectiondisplayname: 'Bond Geographic Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 3,
					name: 'Credit Quality Exposure',
					subsectiondisplayname: 'Credit Quality Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 4,
					name: 'Coupon Range Exposure',
					subsectiondisplayname: 'Coupon Range Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				},
				{
					id: 5,
					name: 'Maturity Range Exposure',
					subsectiondisplayname: 'Maturity Range Exposure',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: true
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '8',
			sectionname: 'Regional Inflection Points - Select a Maximum of 2',
			sectiondisplayname: 'Market Inflection Points - Select a Maximum of 2',
			tabName: 'Market Inflection Points',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: ''
				}
			],
			subsections: [
				{
					id: 1,
					name: 'United States Market Recovery',
					subsectiondisplayname: 'US Market Recovery 09/10/2007 - 28/03/2013 & 19/02/2020 - 18/08/2020',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Global Market Recovery',
					subsectiondisplayname: 'Global Market Recovery 31/10/2007 - 19/06/2014 & 12/02/2020 - 26/08/2020',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 3,
					name: 'European Market Recovery',
					subsectiondisplayname: 'European Market Recover 01/06/2007 - 10/04/2015 & 15/04/2015 - 27/12/2019',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 4,
					name: 'Fixed Income Inflection Points',
					subsectiondisplayname: 'Rising US Interest Rates 02/05/2013 - 05/09/2013 & Credit Stress 11/04/2011 - 23/11/2012',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '10',
			sectionname: 'Correlation Matrix',
			sectiondisplayname: 'Correlation Matrix',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: ''
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Correlation Matrix Intl',
					subsectiondisplayname: 'Correlation Matrix',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				},
				{
					id: 2,
					name: 'Excess Return Correlation Matrix',
					subsectiondisplayname: 'Excess Return Correlation Matrix',
					isLocked: false,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		},
		{
			sectionid: '11',
			sectionname: 'Contribution to Risk',
			sectiondisplayname: 'Contribution to Risk',
			tabName: 'Fund Details, Statistics & Performance',
			inputtype: [
				{
					inputname: 'TimeRange',
					inputvalues: ''
				}
			],
			subsections: [
				{
					id: 1,
					name: 'Contribution to Risk',
					subsectiondisplayname: 'Contribution to Risk',
					isLocked: true,
					isActive: false,
					canbeAddedAndLocked: false
				}
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: true,
			flag: '1'
		},
		{
			sectionid: '13',
			sectionname: 'Sustainability Ratings',
			sectiondisplayname: 'Sustainability Ratings',
			tabName: 'Sustainability Ratings',
			inputtype: [
			  {
				inputname: 'TimeRange',
				inputvalues: ''
			  }
			],
			subsections: [
			  {
				id: 1,
				name: 'EU SFDR',
				subsectiondisplayname: 'EU SFDR',
				isLocked: true,
				isActive: false,
				canbeAddedAndLocked: true
			  },
			  {
				id: 2,
				name: 'Morningstar Sustainability Ratings',
				subsectiondisplayname: 'Morningstar Sustainability Ratings',
				isLocked: true,
				isActive: false,
				canbeAddedAndLocked: true
			  },
			  {
				id: 3,
				name: 'Corporate and Soverign',
				subsectiondisplayname: 'Corporate and Soverign',
				isLocked: true,
				isActive: false,
				canbeAddedAndLocked: true
			  },
			  {
				id: 4,
				name: 'ESG Risk Score Distribution',
				subsectiondisplayname: 'ESG Risk Score Distribution',
				isLocked: true,
				isActive: false,
				canbeAddedAndLocked: true
			  }
			],
			isLocked: false,
			isActive: false,
			canbeAddedAndLocked: false,
			flag: '1'
		  }
	],
};

export const screenerInputs = {
	arguments: [
		{
			conditions: [
				{
					operator: 'equalTo',
					values: [],
					valueOperator: 'OR'
				}
			],
			field: 'MSDWSODIssue'
		}
	],
	firstRow: 0,
	resultFields: [
		'MSDFundName',
		'MSDPerformanceAsOfDate',
		'MSDLastClose',
		'MFSSymbol',
		'MSDFundFamilyName',
		'MSDWSODIssue',
		'MSDMorningstarOverallRating',
		'MSDMorningstarCategoryName',
		'MSDInceptionDate',
		'MSDSalesLoadDeferred',
		'MSDBaseCurrencyID',
		'MFSSymbolISIN'
	],
	resultFormat: 'MarketsTopMutualFunds',
	rowCount: 100,
	sortArguments: [
		{
			direction: 'A',
			field: 'MSDFundName'
		}
	]
};

export const screenerInternationalInputs = {
	arguments: [
		{
			conditions: [
				{
					operator: 'like',
					values: [],
					valueOperator: 'OR'
				}
			],
			field: 'MSDFundShareClassID'
		},
		{
			conditions: [
				{
					operator: 'greaterThanEqualTo',
					values: [],
					valueOperator: 'OR'
				}
			],
			field: 'MSDPerformanceAsOfDate'
		}
	],
	firstRow: 0,
	resultFields: [
		'MSDFundName',
		'MSDPerformanceAsOfDate',
		'MSDLastClose',
		'MFSSymbol',
		'MSDFundFamilyName',
		'MSDWSODIssue',
		'MSDMorningstarOverallRating',
		'MSDMorningstarCategoryName',
		'MSDInceptionDate',
		'MSDSalesLoadDeferred',
		'MSDBaseCurrencyID',
		'MFSSymbolISIN',
		'MSDKIIDOngoingCharge'
	],
	resultFormat: 'MarketsTopMutualFunds',
	rowCount: 100,
	sortArguments: [
		{
			direction: 'A',
			field: 'MSDFundName'
		}
	]
};

export const screenerInternationalInputsForCategoryFunds = {
	arguments: [
		{
			conditions: [
				{
					operator: 'like',
					values: [],
					valueOperator: 'OR'
				}
			],
			field: 'MSDMorningstarCategoryName'
		},
		{
			conditions: [
				{
					operator: 'greaterThanEqualTo',
					values: [],
					valueOperator: 'OR'
				}
			],
			field: 'MSDPerformanceAsOfDate'
		}
	],
	firstRow: 0,
	resultFields: [
		'MSDFundName',
		'MSDAverageMarketCap',
		'MFSSymbol',
		'MSDFundShareClassID',
		'MSDWSODIssue',
		'MSDMorningstarCategoryName',
		'MFSSymbolISIN'
	],
	resultFormat: 'MarketsTopMutualFunds',
	rowCount: 5,
	sortArguments: [
		{
			direction: 'D',
			field: 'MSDAverageMarketCap'
		}
	]
};

export const screenerArguments = {
	argsOperator: 'AND',
	conditions: [
		{
			operator: 'like',
			values: [],
			valueOperator: 'OR'
		}
	],
	field: ''
};


export const MFarguments = {
	argsOperator: 'AND',
	conditions: [
		{
			operator: 'like',
			values: ['1'],
			valueOperator: 'OR'
		}
	],
	field: 'MSDIsMFFlag'
};

export const ETFarguments = {
	argsOperator: 'AND',
	conditions: [
		{
			operator: 'like',
			values: ['1'],
			valueOperator: 'OR'
		}
	],
	field: 'MSDIsETFFlag'
};
export const defaultedBenchmarks = [];//MFS-3377 remove them as data is available now

export const isMilToolHide = false;
export const portfolioTemplateResult = {
	Portfolios: {
		Portfolio: [],
		benchmark: [],
		primarybenchmark: []
	}
};

export const portfolioTemplateData= {
	"Portfolios": [
	  {
		"Portfolio": [
		
		],
		"portfolioName": "Portfolio1"
		},
		{
			"Portfolio": [
			
			],
			"portfolioName": "Portfolio2"
		}
	],
	"benchmark":{
	"benchmarks":[],
	"name":""
	},
	"balanceInitial": 100000,
	"sections": [
	  {
		"sectionid": "2",
		"sectionname": "Performance",
		"sectiondisplayname": "Performance",
		"subsections": [
		  {
			"id": 1,
			"name": "Growth Of 100K",
			"subsectiondisplayname": "Growth Of 100K",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": false
		  },
		  {
			"id": 2,
			"name": "Investment Details",
			"subsectiondisplayname": "Investment Details",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": false
		  }
		],
		"isLocked": false,
		"isActive": true,
		"canbeAddedAndLocked": false,
		"flag": "1"
	  },
	  {
		"sectionid": "3",
		"sectionname": "Rolling Time Frames",
		"sectiondisplayname": "Rolling Time Frames",
		"tabName": "Fund Details, Statistics & Performance",
		"inputtype": [
		  {
			"inputname": "TimeRange",
			"inputvalues": "3Y;5Y;10Y"
		  }
		],
		"subsections": [
		  {
			"id": 1,
			"name": "Rolling Returns",
			"subsectiondisplayname": "Rolling Returns",
			"isLocked": false,
			"isActive": true,
			"canbeAddedAndLocked": false
		  },
		  {
			"id": 2,
			"name": "Rolling Sharpe Ratio",
			"subsectiondisplayname": "Rolling Sharpe Ratio",
			"isLocked": false,
			"isActive": true,
			"canbeAddedAndLocked": false
		  },
		  {
			"id": 3,
			"name": "Rolling Excess Returns",
			"subsectiondisplayname": "Rolling Excess Returns",
			"isLocked": false,
			"isActive": true,
			"canbeAddedAndLocked": false
		  }
		],
		"isLocked": false,
		"isActive": true,
		"canbeAddedAndLocked": false,
		"flag": "1"
	  },
	  {
		"sectionid": "6",
		"sectionname": "Equity & Multi-Asset",
		"sectiondisplayname": "Equity & Multi-Asset Overview",
		"tabName": "Fixed Income & Equity Overview",
		"subsections": [
		  {
			"id": 1,
			"name": "Equity Characteristics",
			"subsectiondisplayname": "Equity Characteristics",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": true
		  },
		  {
			"id": 2,
			"name": "Equity Geographic Exposure",
			"subsectiondisplayname": "Equity Geographic Exposure",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": true
		  },
		  {
			"id": 3,
			"name": "Equity Style Box Exposure",
			"subsectiondisplayname": "Equity Style Box Exposure",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": true
		  },
		  {
			"id": 4,
			"name": "Equity Market Cap Exposure",
			"subsectiondisplayname": "Equity Market Cap Exposure",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": true
		  }
		],
		"isLocked": false,
		"isActive": true,
		"canbeAddedAndLocked": false,
		"flag": "1"
	  },
	  {
		"sectionid": "7",
		"sectionname": "Fixed Income",
		"sectiondisplayname": "Fixed Income Overview",
		"tabName": "Fixed Income & Equity Overview",
		"subsections": [
		  {
			"id": 1,
			"name": "Bond Characteristics",
			"subsectiondisplayname": "Bond Characteristics",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": true
		  },
		  {
			"id": 2,
			"name": "Bond Geographic Exposure",
			"subsectiondisplayname": "Bond Geographic Exposure",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": true
		  },
		  {
			"id": 3,
			"name": "Credit Quality Exposure",
			"subsectiondisplayname": "Credit Quality Exposure",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": true
		  },
		  {
			"id": 4,
			"name": "Coupon Range Exposure",
			"subsectiondisplayname": "Coupon Range Exposure",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": true
		  },
		  {
			"id": 5,
			"name": "Maturity Range Exposure",
			"subsectiondisplayname": "Maturity Range Exposure",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": true
		  }
		],
		"isLocked": false,
		"isActive": true,
		"canbeAddedAndLocked": false,
		"flag": "1"
	  },
	  {
		"sectionid": "8",
		"sectionname": "Equity & Multi-Asset Inflection Points",
		"sectiondisplayname": "Equity & Multi-Asset Inflection Points",
		"tabName": "Market Inflection Points",
		"subsections": [
		  {
			"id": 1,
			"name": "Market Recovery 10/09/2007 – 03/28/2013",
			"subsectiondisplayname": "Market Recovery 10/09/2007 – 03/28/2013",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": false
		  },
		  {
			"id": 2,
			"name": "Market Recovery 05/21/2015 – 07/11/2016",
			"subsectiondisplayname": 'Market Recovery 09/20/2018 – 04/23/2019',
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": false
		  },
		  {
			"id": 3,
			"name": "Market Recovery 04/29/2011 – 02/24/2012",
			"subsectiondisplayname": 'Market Recovery 02/19/2020 – 08/18/2020',
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": false
		  },
		  {
			"id": 4,
			"name": "Rising Rates 05/02/2013 – 09/05/2013",
			"subsectiondisplayname": "Rising Rates 05/02/2013 – 09/05/2013",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": false
		  }
		],
		"isLocked": false,
		"isActive": true,
		"canbeAddedAndLocked": false,
		"flag": "1"
	  },
	  {
		"sectionid": "9",
		"sectionname": "Fixed Income Inflection Points",
		"sectiondisplayname": "Fixed Income Inflection Points",
		"tabName": "Market Inflection Points",
		"subsections": [
		  {
			"id": 1,
			"name": "Market Recovery 10/09/2007 – 03/28/2013",
			"subsectiondisplayname": "Market Recovery 10/09/2007 – 03/28/2013",
			"isLocked": true,
			"isActive": false,
			"canbeAddedAndLocked": false
		  },
		  {
			"id": 2,
			"name": "Rising Rates 05/02/2013 – 09/05/2013",
			"subsectiondisplayname": "Rising Rates 05/02/2013 – 09/05/2013",
			"isLocked": true,
			"isActive": false,
			"canbeAddedAndLocked": false
		  },
		  {
			"id": 3,
			"name": "Rising Rates 07/08/2016 – 12/16/2016",
			"subsectiondisplayname": "Rising Rates 07/08/2016 – 12/16/2016",
			"isLocked": true,
			"isActive": false,
			"canbeAddedAndLocked": false
		  },
		  {
			"id": 4,
			"name": "Rising Rates 09/08/2017 – 11/08/2018",
			"subsectiondisplayname": "Rising Rates 09/08/2017 – 11/08/2018",
			"isLocked": true,
			"isActive": false,
			"canbeAddedAndLocked": false
		  }
		],
		"isLocked": false,
		"isActive": false,
		"canbeAddedAndLocked": false,
		"flag": "1"
	  },
	  {
		"sectionid": "4",
		"sectionname": "Risk & Performance Statistics",
		"sectiondisplayname": "Risk & Performance Statistics",
		"tabName": "Fund Details, Statistics & Performance",
		"subsections": [
		  {
			"id": 1,
			"name": "Up/Down Capture Ratio",
			"subsectiondisplayname": "Up/Down Capture Ratio",
			"isLocked": false,
			"isActive": true,
			"canbeAddedAndLocked": false
		  },
		  {
			"id": 2,
			"name": "MPT Statistics",
			"subsectiondisplayname": "MPT Statistics",
			"isLocked": false,
			"isActive": true,
			"canbeAddedAndLocked": false
		  }
		],
		"isLocked": false,
		"isActive": true,
		"canbeAddedAndLocked": false,
		"flag": "1"
		},
		// PvP - Module Selection: Correlation Matrix
	  {
		"sectionid": "10",
		"sectionname": "Correlation Matrix",
		"sectiondisplayname": "Correlation Matrix",
		"tabName": "Fund Details, Statistics & Performance",
		"inputtype": [
		  {
			"inputname": "TimeRange",
			// For a period to show in the drop down all funds in the portfolio(s) must have 
			// data (extended performance) to support that time period. An error message will display if the is 
			// not enough data to render the module, see rolling returns module behavior
			"inputvalues": "" 
		  }
		],
		"subsections": [
		  {
			"id": 1,
			"name": "Correlation Matrix",
			"subsectiondisplayname": "Correlation Matrix",
			"isLocked": true,
			"isActive": true,
			"canbeAddedAndLocked": false
		  }
		],
		"isLocked": false,
		"isActive": false,
		"canbeAddedAndLocked": true,
		"flag": "1"
		},
		/**
		 * Adding missing modules: "Funds In Focus", "Standardized Performance" & "Contribution to Risk"
		 */
		{
			"sectionid": '1',
			"sectionname": 'Funds In Focus',
			"sectiondisplayname": 'Funds In Focus',
			"tabName": 'Fund Details, Statistics & Performance',
			"subsections": [
				{
					"id": "1",
					"name": 'Portfolio Basics',
					"subsectiondisplayname": 'Portfolio Basics',
					"isLocked": "true",
					"isActive": "true",
					"canbeAddedAndLocked": "false"
				},
				{
					"id": 2,
					"name": 'Fund Basics',
					"subsectiondisplayname": 'Fund Basics',
					"isLocked": "true",
					"isActive": "true",
					"canbeAddedAndLocked": "false"
				},
				{
					"id": 3,
					"name": 'Trailing Returns & Standard Deviation',
					"subsectiondisplayname": 'Trailing Returns & Standard Deviation',
					"isLocked": "true",
					"isActive": "true",
					"canbeAddedAndLocked": "false"
				}
			],
			"isLocked": "true",
			"isActive": "false",
			"canbeAddedAndLocked": "false",
			"flag": '1'
		},
		{
			"sectionid": '5',
			"sectionname": 'Standardized Performance',
			"sectiondisplayname": 'Standardized Performance',
			"tabName": 'Fund Details, Statistics & Performance',
			"subsections": [
				{
					"id": 1,
					"name": 'Fund Information',
					"subsectiondisplayname": 'Fund Information',
					"isLocked": "true",
					"isActive": "true",
					"canbeAddedAndLocked": "false"
				},
				{
					"id": 2,
					"name": 'Total & Load Adjusted Returns',
					"subsectiondisplayname": 'Total & Load Adjusted Returns',
					"isLocked": "true",
					"isActive": "true",
					"canbeAddedAndLocked": "false"
				},
				{
					"id": 3,
					"name": 'Market Returns',
					"subsectiondisplayname": 'Market Returns',
					"isLocked": "true",
					"isActive": "true",
					"canbeAddedAndLocked": "false"
				},
				{
					"id": 4,
					"name": 'Morningstar Ratings',
					"subsectiondisplayname": 'Morningstar Ratings',
					"isLocked": "true",
					"isActive": "true",
					"canbeAddedAndLocked": "false"
				}
			],
			"isLocked": "true",
			"isActive": "false",
			"canbeAddedAndLocked": "false",
			"flag": '1'
		},
		{
			"sectionid": '11',
			"sectionname": 'Contribution to Risk',
			"sectiondisplayname": 'Contribution to Risk',
			"tabName": 'Fund Details, Statistics & Performance',
			"inputtype": [
				{
					"inputname": 'TimeRange',
					"inputvalues": ''
				}
			],
			"subsections": [
				{
					"id": 1,
					"name": 'Contribution to Risk',
					"subsectiondisplayname": 'Contribution to Risk',
					"isLocked": "true",
					"isActive": "false",
					"canbeAddedAndLocked": "false"
				}
			],
			"isLocked": "false",
			"isActive": "false",
			"canbeAddedAndLocked": "true",
			"flag": '1'
		}
	],
	"isMarketingPage": true
  };

export const portfolioTemplateDataIntl = {
    "Portfolios": [
        {
            "Portfolio": [

            ],
            "portfolioName": "Portfolio1"
        },
        {
            "Portfolio": [

            ],
            "portfolioName": "Portfolio2"
        }
    ],
    "benchmark": {
        "benchmarks": [],
        "name": ""
    },
    "balanceInitial": 100000,
    "sections": [
        {
            "sectionid": "2",
            "sectionname": "Performance",
            "sectiondisplayname": "Performance",
            "subsections": [
                {
                    "id": 1,
                    "name": "Growth Of 100K",
                    "subsectiondisplayname": "Growth Of 100K",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": false
                },
                {
                    "id": 2,
                    "name": "Investment Details",
                    "subsectiondisplayname": "Investment Details",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": false
                }
            ],
            "isLocked": false,
            "isActive": true,
            "canbeAddedAndLocked": false,
            "flag": "1"
        },
        {
            "sectionid": "3",
            "sectionname": "Rolling Time Frames",
            "sectiondisplayname": "Rolling Time Frames",
            "tabName": "Fund Details, Statistics & Performance",
            "inputtype": [
                {
                    "inputname": "TimeRange",
                    "inputvalues": "3Y;5Y;10Y"
                }
            ],
            "subsections": [
                {
                    "id": 1,
                    "name": "Rolling Returns",
                    "subsectiondisplayname": "Rolling Returns",
                    "isLocked": false,
                    "isActive": true,
                    "canbeAddedAndLocked": false
                },
                {
                    "id": 2,
                    "name": "Rolling Sharpe Ratio",
                    "subsectiondisplayname": "Rolling Sharpe Ratio",
                    "isLocked": false,
                    "isActive": true,
                    "canbeAddedAndLocked": false
                },
                {
                    "id": 3,
                    "name": "Rolling Excess Returns",
                    "subsectiondisplayname": "Rolling Excess Returns",
                    "isLocked": false,
                    "isActive": true,
                    "canbeAddedAndLocked": false
                }
            ],
            "isLocked": false,
            "isActive": true,
            "canbeAddedAndLocked": false,
            "flag": "1"
        },
        {
            "sectionid": "6",
            "sectionname": "Equity & Multi-Asset",
            "sectiondisplayname": "Equity & Multi-Asset Overview",
            "tabName": "Fixed Income & Equity Overview",
            "subsections": [
                {
                    "id": 1,
                    "name": "Equity Characteristics",
                    "subsectiondisplayname": "Equity Characteristics",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": true
                },
                {
                    "id": 2,
                    "name": "Equity Geographic Exposure",
                    "subsectiondisplayname": "Equity Geographic Exposure",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": true
                },
                {
                    "id": 3,
                    "name": "Equity Style Box Exposure",
                    "subsectiondisplayname": "Equity Style Box Exposure",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": true
                },
                {
                    "id": 4,
                    "name": "Equity Market Cap Exposure",
                    "subsectiondisplayname": "Equity Market Cap Exposure",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": true
                }
            ],
            "isLocked": false,
            "isActive": true,
            "canbeAddedAndLocked": false,
            "flag": "1"
        },
        {
            "sectionid": "7",
            "sectionname": "Fixed Income",
            "sectiondisplayname": "Fixed Income Overview",
            "tabName": "Fixed Income & Equity Overview",
            "subsections": [
                {
                    "id": 1,
                    "name": "Bond Characteristics",
                    "subsectiondisplayname": "Bond Characteristics",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": true
                },
                {
                    "id": 2,
                    "name": "Bond Geographic Exposure",
                    "subsectiondisplayname": "Bond Geographic Exposure",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": true
                },
                {
                    "id": 3,
                    "name": "Credit Quality Exposure",
                    "subsectiondisplayname": "Credit Quality Exposure",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": true
                },
                {
                    "id": 4,
                    "name": "Coupon Range Exposure",
                    "subsectiondisplayname": "Coupon Range Exposure",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": true
                },
                {
                    "id": 5,
                    "name": "Maturity Range Exposure",
                    "subsectiondisplayname": "Maturity Range Exposure",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": true
                }
            ],
            "isLocked": false,
            "isActive": true,
            "canbeAddedAndLocked": false,
            "flag": "1"
        },
        {
            sectionid: '8',
            sectionname: 'Regional Inflection Points - Select a Maximum of 2',
            sectiondisplayname: 'Market Inflection Points - Select a Maximum of 2',
            tabName: 'Market Inflection Points',
            inputtype: [
                {
                    inputname: 'TimeRange',
                    inputvalues: ''
                }
            ],
            subsections: [
                {
                    id: 1,
                    name: 'United States Market Recovery',
                    subsectiondisplayname: 'US Market Recovery 09/10/2007 - 28/03/2013 & 19/02/2020 - 18/08/2020',
                    isLocked: false,
                    isActive: false,
                    canbeAddedAndLocked: false
                },
                {
                    id: 2,
                    name: 'Global Market Recovery',
                    subsectiondisplayname: 'Global Market Recovery 31/10/2007 - 19/06/2014 & 12/02/2020 - 26/08/2020',
                    isLocked: false,
                    isActive: false,
                    canbeAddedAndLocked: false
                },
                {
                    id: 3,
                    name: 'European Market Recovery',
                    subsectiondisplayname: 'European Market Recover 01/06/2007 - 10/04/2015 & 15/04/2015 - 27/12/2019',
                    isLocked: false,
                    isActive: false,
                    canbeAddedAndLocked: false
                },
                {
                    id: 4,
                    name: 'Fixed Income Inflection Points',
                    subsectiondisplayname: 'Rising US Interest Rates 02/05/2013 - 05/09/2013 & Credit Stress 11/04/2011 - 23/11/2012',
                    isLocked: false,
                    isActive: false,
                    canbeAddedAndLocked: false
                }
            ],
            isLocked: false,
            isActive: false,
            canbeAddedAndLocked: false,
            flag: '1'
        },
        {
            "sectionid": "4",
            "sectionname": "Risk & Performance Statistics",
            "sectiondisplayname": "Risk & Performance Statistics",
            "tabName": "Fund Details, Statistics & Performance",
            "subsections": [
                {
                    "id": 1,
                    "name": "Up/Down Capture Ratio",
                    "subsectiondisplayname": "Up/Down Capture Ratio",
                    "isLocked": false,
                    "isActive": true,
                    "canbeAddedAndLocked": false
                },
                {
                    "id": 2,
                    "name": "MPT Statistics",
                    "subsectiondisplayname": "MPT Statistics",
                    "isLocked": false,
                    "isActive": true,
                    "canbeAddedAndLocked": false
                }
            ],
            "isLocked": false,
            "isActive": true,
            "canbeAddedAndLocked": false,
            "flag": "1"
        },
        // PvP - Module Selection: Correlation Matrix
        {
            "sectionid": "10",
            "sectionname": "Correlation Matrix",
            "sectiondisplayname": "Correlation Matrix",
            "tabName": "Fund Details, Statistics & Performance",
            "inputtype": [
                {
                    "inputname": "TimeRange",
                    // For a period to show in the drop down all funds in the portfolio(s) must have 
                    // data (extended performance) to support that time period. An error message will display if the is 
                    // not enough data to render the module, see rolling returns module behavior
                    "inputvalues": ""
                }
            ],
            "subsections": [
                {
                    "id": 1,
                    "name": "Correlation Matrix",
                    "subsectiondisplayname": "Correlation Matrix",
                    "isLocked": true,
                    "isActive": true,
                    "canbeAddedAndLocked": false
                }
            ],
            "isLocked": false,
            "isActive": false,
            "canbeAddedAndLocked": true,
            "flag": "1"
        },
		/**
		 * Adding missing modules: "Funds In Focus", "Standardized Performance" & "Contribution to Risk"
		 */
        {
            "sectionid": '1',
            "sectionname": 'Funds In Focus',
            "sectiondisplayname": 'Funds In Focus',
            "tabName": 'Fund Details, Statistics & Performance',
            "subsections": [
                {
                    "id": "1",
                    "name": 'Portfolio Basics',
                    "subsectiondisplayname": 'Portfolio Basics',
                    "isLocked": "true",
                    "isActive": "true",
                    "canbeAddedAndLocked": "false"
                },
                {
                    "id": 2,
                    "name": 'Fund Basics',
                    "subsectiondisplayname": 'Fund Basics',
                    "isLocked": "true",
                    "isActive": "true",
                    "canbeAddedAndLocked": "false"
                },
                {
                    "id": 3,
                    "name": 'Trailing Returns & Standard Deviation',
                    "subsectiondisplayname": 'Trailing Returns & Standard Deviation',
                    "isLocked": "true",
                    "isActive": "true",
                    "canbeAddedAndLocked": "false"
                }
            ],
            "isLocked": "true",
            "isActive": "false",
            "canbeAddedAndLocked": "false",
            "flag": '1'
        },
        {
            "sectionid": '5',
            "sectionname": 'Standardized Performance',
            "sectiondisplayname": 'Standardized Performance',
            "tabName": 'Fund Details, Statistics & Performance',
            "subsections": [
                {
                    "id": 1,
                    "name": 'Fund Information',
                    "subsectiondisplayname": 'Fund Information',
                    "isLocked": "true",
                    "isActive": "true",
                    "canbeAddedAndLocked": "false"
                },
                {
                    "id": 2,
                    "name": 'Total & Load Adjusted Returns',
                    "subsectiondisplayname": 'Total & Load Adjusted Returns',
                    "isLocked": "true",
                    "isActive": "true",
                    "canbeAddedAndLocked": "false"
                },
                {
                    "id": 3,
                    "name": 'Market Returns',
                    "subsectiondisplayname": 'Market Returns',
                    "isLocked": "true",
                    "isActive": "true",
                    "canbeAddedAndLocked": "false"
                },
                {
                    "id": 4,
                    "name": 'Morningstar Ratings',
                    "subsectiondisplayname": 'Morningstar Ratings',
                    "isLocked": "true",
                    "isActive": "true",
                    "canbeAddedAndLocked": "false"
                }
            ],
            "isLocked": "true",
            "isActive": "false",
            "canbeAddedAndLocked": "false",
            "flag": '1'
        },
        {
            "sectionid": '11',
            "sectionname": 'Contribution to Risk',
            "sectiondisplayname": 'Contribution to Risk',
            "tabName": 'Fund Details, Statistics & Performance',
            "inputtype": [
                {
                    "inputname": 'TimeRange',
                    "inputvalues": ''
                }
            ],
            "subsections": [
                {
                    "id": 1,
                    "name": 'Contribution to Risk',
                    "subsectiondisplayname": 'Contribution to Risk',
                    "isLocked": "true",
                    "isActive": "false",
                    "canbeAddedAndLocked": "false"
                }
            ],
            "isLocked": "false",
            "isActive": "false",
            "canbeAddedAndLocked": "true",
            "flag": '1'
        }
    ],
    "isMarketingPage": true
};

export const sectionImages = {
	'Fund Basic': 'Portfolio-Basics-img',
	'MIFID 5 Year Rolling Returns': 'Portfolio-Basics-img',
	'Key Statistics': 'Fund-Basics-img',
	'Portfolio Basics': 'Portfolio-Basics-img',
	'Fund Basics': 'Fund-Basics-img',
	'Planning Assumptions': 'Fund-Basics-img',
	'Trailing Returns & Standard Deviation': 'Trailing-Returns-img',
	'Growth Of 100K': 'Growth-Of-100K-img',
	'Calendar Year Returns': 'Calendar-Year-Returns-img',
	'Investment Details': 'Calendar-Year-Returns-img',
	'Rolling Returns': 'Calendar-Year-Returns-img',
	'Rolling Sharpe Ratio': 'Calendar-Year-Returns-img',
	'Rolling Excess Returns': 'Calendar-Year-Returns-img',
	'MPT Statistics': 'MPT-Performance-img',
	'Morningstar Percentile Rank': 'MPT-Performance-img',
	'Sustainability Rating': 'Sustain-Rating-img',
	'Sustainability Rating Intl': 'Mil-Fund-Sustain-Rating-img',
	'Up/Down Capture Ratio': 'Up-Down-Capture-Ratio-img',
	'Fund Information': 'Fund-Information-img',
	'Fund Information-MIL': 'Fund-Information-img',
	'Total & Load Adjusted Returns': 'Load-Adjusted-Returns-img',
	'Market Returns': 'Market-Returns-img',
	'Morningstar Ratings': 'Morningstar-Ratings-img',
	'Fund Dates Table': 'Morningstar-Ratings-img',
	'Equity Characteristics': 'Equity-Basics-img',
	'Equity Geographic Exposure': 'Equity-Geographic-Exposure-img',
	'Equity Style Box Exposure': 'Equity-Style-box-img',
	'Equity Market Cap Exposure': 'Equity-Market-Cap-Exposure-img',
	'Bond Characteristics': 'Bond-Characteristics-img',
	'Credit Quality Exposure': 'Credit-Quality-Exposure-img',
	'Coupon Range Exposure': 'Coupon-Range-Exposure-img',
	'Maturity Range Exposure': 'Maturity-Breakdown-img',
	'Market Recovery 10/09/2007 – 03/28/2013': 'Market-Recovery-10092007–03282013-img',
	'Market Recovery 04/29/2011 – 02/24/2012': 'Market-Recovery-05212015–07112016-img',
	'Market Recovery 05/21/2015 – 07/11/2016': 'Market-Recovery-09202018-04232019-img',
	'Rising Rates 05/02/2013 – 09/05/2013': 'Rising-Rates-05022013–09052013-img',
	'Rising Rates 07/08/2016 – 12/16/2016': 'Rising-Rates-07082016–12162016-img',
	'Rising Rates 09/08/2017 – 11/08/2018': 'Rising-Rates-09082017–11082018-img',
	'Morningstar Percentile Rank': 'MPercentile-img',
	'Rolling Excess Returns': 'ExcessReturns-img',
	'Rolling Returns': 'RollingReturns-img',
	'Rolling Sharpe Ratio': 'SharpeRatio-img',
	'Bond Geographic Exposure':'Fixed-Income-Geographic-Exposure-img',
	'Correlation Matrix US':'Fund-Correlation-Matrix-img',
	'Correlation Matrix Intl':'Mil-Fund-Correlation-Matrix-img',
	'Contribution to Risk':'Fixed-Income-Geographic-Exposure-img',
	'MorningstarRatings-TotalNumberofFunds':'MorningstarRatings-TotalNumberofFunds-img',
	'United States Market Recovery': 'Market-Recovery-10092007–03282013-img',
	'Global Market Recovery': 'Global-Market-Recovery-img',
	'European Market Recovery': 'Market-Recovery-09202018-04232019-img',
	'Fixed Income Inflection Points': 'Rising-Rates-05022013–09052013-img',
	'Excess Return Correlation Matrix':'Fund-Excess-Return-Correlation-Matrix-img',
	'EU SFDR':'Eusfdr-img',
	'Morningstar Sustainability Ratings':'Morningstar-Sustain-Rating-img',
	'Corporate and Soverign':'Corporate-Sovereign-img',
	'ESG Risk Score Distribution':'Esg-Risk-Score-Dist-img'
};

export const portfolioSectionImages = {
	
	'Fund Basics': 'Portfolio-Fund-Basics-img',
	'Calendar Year Returns': 'Portfolio-Calendar-Year-Returns-img',
	'Morningstar Percentile Rank': 'Portfolio-MPT-Performance-img',
	'Morningstar Percentile Rank': 'Portfolio-MPercentile-img',
	'MorningstarRatings-TotalNumberofFunds': 'Portfolio-MorningstarRatings-TotalNumberofFunds-img',
	//TO DO Change placeholder image later
	'Key Statistics': 'Portfolio-Planning-Assumptions-img',
	'5 Year Rolling Returns':'Portfolio-Portfolio-Basics-img',
	'Fund 5 Year Rolling Returns':'Portfolio-Portfolio-Basics-img',

	'Portfolio Basics': 'Portfolio-Portfolio-Basics-img',
	'Planning Assumptions': 'Portfolio-Planning-Assumptions-img',
	'Trailing Returns & Standard Deviation': 'Portfolio-Trailing-Returns-img',

	'Growth Of 100K': 'Portfolio-Growth-Of-100K-img',
	'Investment Details': 'Portfolio-Investment-Details-img',

	'Rolling Excess Returns': 'Portfolio-ExcessReturns-img',
	'Rolling Returns': 'Portfolio-RollingReturns-img',
	'Rolling Sharpe Ratio': 'Portfolio-SharpeRatio-img',

	'Up/Down Capture Ratio': 'Portfolio-Up-Down-Capture-Ratio-img',
	'MPT Statistics': 'Portfolio-MPT-Performance-img',

	'Fund Information': 'Portfolio-Fund-Information-img',
	'Fund Information-MIL': 'Portfolio-Fund-Information-MIL-img',
	'Total & Load Adjusted Returns': 'Portfolio-Load-Adjusted-Returns-img',
	'Market Returns': 'Portfolio-Market-Returns-img',
	'Morningstar Ratings': 'Portfolio-Morningstar-Ratings-img',
	'Sustainability Rating': 'Portfolio-Sustain-Rating-img',
	'Sustainability Rating Intl': 'Portfolio-Sustain-Rating-img',
	'Fund Dates Table': 'Portfolio-Fund-Dates-Table-img',

	'Correlation Matrix US': 'Portfolio-Correlation-Matrix-img',
	'Correlation Matrix Intl':'Portfolio-Correlation-Matrix-img',

	'Contribution to Risk': 'Portfolio-Contribution-To-Risk-img',
	
	'Equity Characteristics': 'Portfolio-Equity-Characteristics-img',
	'Equity Geographic Exposure': 'Portfolio-Equity-Geographic-Exposure-img',
	'Equity Style Box Exposure': 'Portfolio-Equity-Style-box-img',
	'Equity Market Cap Exposure': 'Portfolio-Equity-Market-Cap-Exposure-img',

	'Bond Characteristics': 'Portfolio-Fixed-Income-Bond-Characteristics-img',
	'Bond Geographic Exposure': 'Portfolio-Fixed-Income-Geographic-Exposure-img',
	'Credit Quality Exposure': 'Portfolio-Fixed-Income-Credit-Quality-Exposure-img',
	'Coupon Range Exposure': 'Portfolio-Fixed-Income-Coupon-Range-Exposure-img',
	'Maturity Range Exposure': 'Portfolio-Fixed-Income-Maturity-Breakdown-img',
	
	'Market Recovery 10/09/2007 – 03/28/2013': 'Portfolio-Market-Recovery-10092007–03282013-img',
	'Market Recovery 04/29/2011 – 02/24/2012': 'Portfolio-Market-Recovery-05212015–07112016-img',
	'Market Recovery 05/21/2015 – 07/11/2016': 'Portfolio-Market-Recovery-09202018-04232019-img',

	'Rising Rates 05/02/2013 – 09/05/2013': 'Portfolio-Rising-Rates-05022013–09052013-img',
	'Rising Rates 07/08/2016 – 12/16/2016': 'Portfolio-Rising-Rates-07082016–12162016-img',
	'Rising Rates 09/08/2017 – 11/08/2018': 'Portfolio-Rising-Rates-09082017–11082018-img',

	'United States Market Recovery': 'Portfolio-Market-Recovery-10092007–03282013-img',
	'Global Market Recovery': 'Portfolio-Global-Market-Recovery-img',
	'European Market Recovery': 'Portfolio-Market-Recovery-09202018-04232019-img',
	'Fixed Income Inflection Points': 'Portfolio-Rising-Rates-05022013–09052013-img',
	'Excess Return Correlation Matrix': 'Portfolio-Correlation-Matrix-img',
	//-----------------------------------------------------------------------------------------------
};

export const reportTypes = {
	'fundcomparison':'fundcomparison',
	'singleportfolio':'singleportfolio',
	'portfoliocomparison' : 'portfoliocomparison',
	'mil_fundcomparison': 'mil_fundcomparison',
	'mil_singleportfolio': 'mil_singleportfolio',
	'mil_portfoliocomparison' : 'mil_portfoliocomparison'
};

export const portfolioLandingPageTabs = {
	'singleportfolio':'My Saved Portfolios',
	'comparisonportfolio':'My Report Templates'
}

export const blendedBenchmarks = [
	{
		name: "Blended Benchmark 1",
		benchmarks:
			[
				{
					symbol: "S&P 500 TR USD",
					wsodissue: "220205498",
					weightage: "0.20"
				},
				{
					symbol: "BBgBarc US Agg Bond TR USD",
					wsodissue: "220205304",
					weightage: "0.80"
				}
			]
	},
	{
		name: "Blended Benchmark 2",
		benchmarks:
			[
				{
					symbol: "S&P 500 TR USD",
					wsodissue: "220205498",
					weightage: "0.40"
				},
				{
					symbol: "BBgBarc US Agg Bond TR USD",
					wsodissue: "220205304",
					weightage: "0.60"
				}
			]
	},
	{
		name: "Blended Benchmark 3",
		benchmarks:
			[
				{
					symbol: "S&P 500 TR USD",
					wsodissue: "220205498",
					weightage: "0.60"
				},
				{
					symbol: "BBgBarc US Agg Bond TR USD",
					wsodissue: "220205304",
					weightage: "0.40"
				}
			]
	},
	{
		name: "Blended Benchmark 4",
		benchmarks:
			[
				{
					symbol: "S&P 500 TR USD",
					wsodissue: "220205498",
					weightage: "0.80"
				},
				{
					symbol: "BBgBarc US Agg Bond TR USD",
					wsodissue: "220205304",
					weightage: "0.20"
				}
			]
	}
];
export const blendedBenchmarksMil = [
	{
		name: "Blended Benchmark 1",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR USD",
					shareClassId:'XIUSA000CU',
					wsodissue: "220205285",
					weightage: "0.80"
				},
				{
					symbol: "MSCI ACWI NR USD",
					shareClassId: 'XIUSA04EXL',
					wsodissue: "220205446",
					weightage: "0.20"
				}
			],
		currency: 'USD'
	},
	{
		name: "Blended Benchmark 1",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR EUR",
					wsodissue: "569880007",
					shareClassId:'FOUSA08AF6',
					weightage: "0.80"
				},
				{
					symbol: "MSCI ACWI NR EUR",
					wsodissue: "574785565",
					shareClassId:'F00000MV3I',
					weightage: "0.20"
				}
			],
			currency: 'EUR'
	},
	{
		name: "Blended Benchmark 1",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR CHF",
					wsodissue: "569884384",
					shareClassId:'F00000W1D4',
					weightage: "0.80"
				},
				{
					symbol: "MSCI ACWI NR CHF",
					wsodissue: "633076639",
					shareClassId:'F00000WRBQ',
					weightage: "0.20"
				}
			],
			currency: 'CHF'
	},
	{
		name: "Blended Benchmark 1",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR GBP",
					wsodissue: "609431467",
					shareClassId:'FOUSA08AF8',
					weightage: "0.80"
				},
				{
					symbol: "MSCI ACWI NR GBP",
					wsodissue: "635667078",
					shareClassId:'F00000X4EN',
					weightage: "0.20"
				}
			],
			currency: 'GBP'
	},

	{
		name: "Blended Benchmark 2",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR USD",
					shareClassId:'XIUSA000CU',
					wsodissue: "220205285",
					weightage: "0.60"
				},
				{
					symbol: "MSCI ACWI NR USD",
					shareClassId: 'XIUSA04EXL',
					wsodissue: "220205446",
					weightage: "0.40"
				}
			],
		currency: 'USD'
	},
	{
		name: "Blended Benchmark 2",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR EUR",
					wsodissue: "569880007",
					shareClassId:'FOUSA08AF6',
					weightage: "0.60"
				},
				{
					symbol: "MSCI ACWI NR EUR",
					wsodissue: "574785565",
					shareClassId:'F00000MV3I',
					weightage: "0.40"
				}
			],
			currency: 'EUR'
	},
	{
		name: "Blended Benchmark 2",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR CHF",
					wsodissue: "569884384",
					shareClassId:'F00000W1D4',
					weightage: "0.60"
				},
				{
					symbol: "MSCI ACWI NR CHF",
					wsodissue: "633076639",
					shareClassId:'F00000WRBQ',
					weightage: "0.40"
				}
			],
			currency: 'CHF'
	},
	{
		name: "Blended Benchmark 2",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR GBP",
					wsodissue: "609431467",
					shareClassId:'FOUSA08AF8',
					weightage: "0.60"
				},
				{
					symbol: "MSCI ACWI NR GBP",
					wsodissue: "635667078",
					shareClassId:'F00000X4EN',
					weightage: "0.40"
				}
			],
			currency: 'GBP'
	},

	{
		name: "Blended Benchmark 3",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR USD",
					shareClassId:'XIUSA000CU',
					wsodissue: "220205285",
					weightage: "0.40"
				},
				{
					symbol: "MSCI ACWI NR USD",
					shareClassId: 'XIUSA04EXL',
					wsodissue: "220205446",
					weightage: "0.60"
				}
			],
		currency: 'USD'
	},
	{
		name: "Blended Benchmark 3",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR EUR",
					wsodissue: "569880007",
					shareClassId:'FOUSA08AF6',
					weightage: "0.40"
				},
				{
					symbol: "MSCI ACWI NR EUR",
					wsodissue: "574785565",
					shareClassId:'F00000MV3I',
					weightage: "0.60"
				}
			],
			currency: 'EUR'
	},
	{
		name: "Blended Benchmark 3",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR CHF",
					wsodissue: "569884384",
					shareClassId:'F00000W1D4',
					weightage: "0.40"
				},
				{
					symbol: "MSCI ACWI NR CHF",
					wsodissue: "633076639",
					shareClassId:'F00000WRBQ',
					weightage: "0.60"
				}
			],
			currency: 'CHF'
	},
	{
		name: "Blended Benchmark 3",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR GBP",
					wsodissue: "609431467",
					shareClassId:'FOUSA08AF8',
					weightage: "0.40"
				},
				{
					symbol: "MSCI ACWI NR GBP",
					wsodissue: "635667078",
					shareClassId:'F00000X4EN',
					weightage: "0.60"
				}
			],
			currency: 'GBP'
	},

	{
		name: "Blended Benchmark 4",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR USD",
					shareClassId:'XIUSA000CU',
					wsodissue: "220205285",
					weightage: "0.20"
				},
				{
					symbol: "MSCI ACWI NR USD",
					shareClassId: 'XIUSA04EXL',
					wsodissue: "220205446",
					weightage: "0.80"
				}
			],
		currency: 'USD'
	},
	{
		name: "Blended Benchmark 4",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR EUR",
					wsodissue: "569880007",
					shareClassId:'FOUSA08AF6',
					weightage: "0.20"
				},
				{
					symbol: "MSCI ACWI NR EUR",
					wsodissue: "574785565",
					shareClassId:'F00000MV3I',
					weightage: "0.80"
				}
			],
			currency: 'EUR'
	},
	{
		name: "Blended Benchmark 4",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR CHF",
					wsodissue: "569884384", 
					shareClassId:'F00000W1D4',
					weightage: "0.20"
				},
				{
					symbol: "MSCI ACWI NR CHF",
					wsodissue: "633076639",
					shareClassId:'F00000WRBQ',
					weightage: "0.80"
				}
			],
			currency: 'CHF'
	},
	{
		name: "Blended Benchmark 4",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR GBP",
					wsodissue: "609431467",
					shareClassId:'FOUSA08AF8',
					weightage: "0.20"
				},
				{
					symbol: "MSCI ACWI NR GBP",
					wsodissue: "635667078",
					shareClassId:'F00000X4EN',
					weightage: "0.80"
				}
			],
			currency: 'GBP'
	}
];

export const blendedBenchmarksMilFunds = [
	{
		name: "BBgBarc Global Aggregate TR USD 40% + MSCI World NR USD 60%",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR USD",
					shareClassId:'XIUSA000CU',
					wsodissue: "220205285",
					weightage: "0.40"
				},
				{
					symbol: "MSCI World NR USD",
					shareClassId: 'XIUSA000PM',
					wsodissue: "220205329",
					weightage: "0.60"
				}
			],
		currency: 'USD',
		wsodissue: "220205329"
	},
	{
		name: "BBgBarc Global Aggregate TR EUR 40% + MSCI World NR EUR 60%",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR EUR",
					shareClassId:'FOUSA08AF6',
					wsodissue: "569880007",
					weightage: "0.40"
				},
				{
					symbol: "MSCI World NR EUR",
					shareClassId: 'FOUSA06VQW',
					wsodissue: "574776012",
					weightage: "0.60"
				}
			],
			currency: 'EUR',
			wsodissue: "574776012"
	},	
	{
		name: "BBgBarc Global Aggregate TR GBP 40% + MSCI World NR GBP 60%",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR GBP",
					shareClassId:'FOUSA08AF8',
					wsodissue: "609431467",
					weightage: "0.40"
				},
				{
					symbol: "MSCI World NR GBP",
					shareClassId:'F00000NH4S',
					wsodissue: "635667680",
					weightage: "0.60"
				}
			],
			currency: 'GBP',
			wsodissue: "635667680"
	},	
	{
		name: "BBgBarc Global Aggregate TR CHF 40% + MSCI World NR CHF 60%",
		benchmarks:
			[
				{
					symbol: "BBgBarc Global Aggregate TR CHF",
					shareClassId:'F00000W1D4',
					wsodissue: "569884384",
					weightage: "0.40"
				},
				{
					symbol: "MSCI World NR CHF",
					shareClassId:'F00000TGJ4',
					wsodissue: "635670062",
					weightage: "0.60"
				}
			],
			currency: 'CHF',
			wsodissue: "635670062"
	}
];

export const customStyles = {
	control: (base, state) => ({
		...base,
		border: '1px solid rgba(34,36,38,.15) !important',
		boxShadow: '0 !important',
		'&:hover': {
			border: '1px solid rgba(34,36,38,.15) !important'
		},
		height: '34px',
		'min-height': '34px'
	}),
	option: (provided, state) => ({
		...provided,
		background: state.isSelected ? '#f4f4f4' : (state.isFocused ? '#f4f4f4' : '#FFF'),
		color: '#333',
		'font-weight': state.isSelected ? 'bold' : 'normal'
	}),
	menu: styles => ({ ...styles, margin: '0px' }),
	noOptionsMessage: styles => ({ ...styles, 'text-align': 'left', color: 'rgba(0,0,0,.4)' })
};

export const customTheme = {
	borderRadius: 0,
	border: 0,
	colors: {
		primary: '#f4f4f4',
		primary50: '#f4f4f4',
		neutral0: '#FFF',
	},
};

export const eventTypes = [
	{ label: 'Select an Event', value: 'Select an Event' },
	{ label: 'Rebalance', value: 'Rebalance' },
	{ label: 'Fund Investment', value: 'Fund Investment' },
	{ label: 'Fund Withdrawal', value: 'Fund Withdrawal' },
	{ label: 'Portfolio Investment', value: 'Portfolio Investment'},
	{ label: 'Portfolio Withdrawal', value: 'Portfolio Withdrawal' }];

export const salesChargeType = [
		{ label: 'None', value: 'None' },
		{ label: 'Schedule', value: 'Schedule' },
		{ label: 'Breakpoint', value: 'Breakpoint' },
	];

	export const frequencyTypes = [
		{ label: 'Every 1 Month', value: '1Months' },
		{ label: 'Every 3 Months', value: '3Months' },
		{ label: 'Every 6 Months', value: '6Months' },
		{ label: 'Annually', value: '12Months' }];

	export const WrapFeeFrequencyTypes = [
		{ label: 'None', value: 'None' },
		{ label: 'Monthly', value: '1Months' },
		{ label: 'Quarterly', value: '3Months' },
		{ label: 'Semi-Annually', value: '6Months' },
		{ label: 'Calendar Year End', value: '12Months' }];

	export const frequencyTypeDict = {'1Months': 'Monthly', '3Months':'Quarterly','6Months':'Semi-Anually','12Months':'Calendar Year End'}
	export const investmentFrequencyTypeDict = {'1Months': 'Every 1 Month', '3Months':'Every 3 Months','6Months':'Every 6 Months','12Months':'Every 12 Months','1Years': 'Calendar Year End','One-Time':'One-Time'}
	
	export const fundInvestFrequencyTypes = [
		{ label: 'Every 1 Month', value: '1' },
		{ label: 'Every 3 Months', value: '3' },
		{ label: 'Every 6 Months', value: '6' },
		{ label: 'Every 12 Months', value: '12' },
		{ label: 'Calendar Year End', value: 'Calendar Year End' },
		{ label: 'One-Time', value: 'One-Time' }];

	export const fundInvestOnlyFrequencyTypes = [
		{ label: 'Every 1 Month', value: '1' },
		{ label: 'Every 3 Months', value: '3' },
		{ label: 'Every 6 Months', value: '6' },
		{ label: 'Every 12 Months', value: '12' },
		{ label: 'Calendar Year End', value: 'Calendar Year End' }];
	
	export const fundInvestOnlyOneTime = [
		{ label: 'One-Time', value: 'One-Time' }];
	

	export const fundInvestFrequencyTypesIncrease = [
		{ label: 'Every 1 Month', value: '1' },
		{ label: 'Every 3 Months', value: '3' },
		{ label: 'Every 6 Months', value: '6' },
		{ label: 'Every 12 Months', value: '12' },
		{ label: 'Calendar Year End', value: 'Calendar Year End' }];	

	export const reportLanguage = [
		{ label: 'English', value: 'en-GB' },
		{ label: 'German', value: 'de-DE' },
		{ label: 'Italian', value: 'it-IT' },
		{ label: 'Spanish', value: 'es-ES' }];

	export const reportLanguageDic = {
		'en-GB': 'English',
		'de-DE': 'German',
		'it-IT': 'Italian',
		'es-ES': 'Spanish'
	};

	export const CustomFundXids = [220205352,622322921,622293033,622326501];

	export const CustomFunds = {
		Cash: {
			MFSSymbol: "Cash",
			MSDFundFamilyName: "FTSE International Ltd Index",
			MSDFundName: "FTSE Treasury Bill 1 Mo USD", 
			MSDInceptionDate: 28489,
			MSDMorningstarCategoryName: "--",
			MSDMorningstarOverallRating: 0,
			MSDPerformanceAsOfDate: utilityFunctions.getEndDateOfPreviousMonth(),
			MSDWSODIssue: 220205352,
			isActive: false,
			MSDFundShareClassID: "XIUSA000WV",
			MSDLastClose: 0 ,
            MSDBaseCurrencyID: "USD",
		    MFSSymbolISIN: "--"
		},
		CashCHF: {
			MFSSymbol: "Cash",
			MSDFundFamilyName: "FTSE International Ltd Index",
			MSDFundName: "FTSE CHF EuroDep 1 Mon CHF", 
			MSDInceptionDate: 28489,
			MSDMorningstarCategoryName: "--",
			MSDMorningstarOverallRating: 0,
			MSDPerformanceAsOfDate: utilityFunctions.getEndDateOfPreviousMonth(),
			MSDWSODIssue: 622322921,
			isActive: false,
			MSDFundShareClassID: "XIUSA04AE3",
			MSDLastClose: 0 ,
            MSDBaseCurrencyID: "CHF",
		    MFSSymbolISIN: "--"
		},
		CashEUR: {
			MFSSymbol: "Cash",
			MSDFundFamilyName: "FTSE International Ltd Index",
			MSDFundName: "FTSE EUR EuroDep 1 Mon EUR", 
			MSDInceptionDate: 28489,
			MSDMorningstarCategoryName: "--",
			MSDMorningstarOverallRating: 0,
			MSDPerformanceAsOfDate: utilityFunctions.getEndDateOfPreviousMonth(),
			MSDWSODIssue: 622293033,
			isActive: false,
			MSDFundShareClassID: "XIUSA04AE5",
			MSDLastClose: 0 ,
            MSDBaseCurrencyID: "EUR",
		    MFSSymbolISIN: "--"
		},
		CashGBP: {
			MFSSymbol: "Cash",
			MSDFundFamilyName: "FTSE International Ltd Index",
			MSDFundName: "FTSE GBP EuroDep 1 Mon GBP", 
			MSDInceptionDate: 28489,
			MSDMorningstarCategoryName: "--",
			MSDMorningstarOverallRating: 0,
			MSDPerformanceAsOfDate: utilityFunctions.getEndDateOfPreviousMonth(),
			MSDWSODIssue: 622326501,
			isActive: false,
			MSDFundShareClassID: "XIUSA04AE7",
			MSDLastClose: 0 ,
            MSDBaseCurrencyID: "GBP",
		    MFSSymbolISIN: "--"
		}
};
export const alertEventIntPortMessage = "Modifying your portfolio or currency selection will reset all associated hypothetical settings to the default. To continue click OK." ;
export const alertEventPortMessage = "Modifying your portfolio will reset all associated hypothetical settings to the default. To continue click OK." 
export const restrictedBenchmarks = ['BBgBarc Global Aggregate TR CHF','BBgBarc Global Aggregate TR EUR','BBgBarc Global Aggregate TR GBP','BBgBarc Global Aggregate TR USD','FTSE CHF EuroDep 1 Mon CHF','FTSE EUR EuroDep 1 Mon USD','FTSE EUR EuroDep 1 Mon EUR','FTSE GBP EuroDep 1 Mon GBP','ICE BofAML Gbl HY Constnd TR EUR','ICE BofAML Gbl HY Constnd TR USD'];
export const restrictedFundsIsin = ['LU1320599852', 'LU2065730314','LU1340704714','LU1340703823','LU2065730405','LU1340703153','LU1914595043','IE00BFNWYN85', 'IE00BFNWYP00', 'IE00BFNWXY26', 'IE00BFNWZ793', 'IE00BFNWYV69', 'IE00BJ7BPF87', 'IE00BJ7BPG94', 'IE00BFNWYS31', 'IE00BJ7BPT24', 'IE00BFNWZ801', 'IE00BJ7BPV46', 'IE00BFNWZC46', 'IE00BFNWXZ33', 'IE00BFNWY499', 'IE00BJ7BP033', 'IE00BFNWY275', 'IE00BJ7BP140', 'IE00BVFM4839', 'IE00BVFM4722', 'IE00BVFM4H20', 'IE00BVFM4J44', 'IE00BYZXJX76', 'IE00BYZXKD44', 'IE00BFNXRZ48', 'IE00BFNWYT48', 'IE00BFNXS069', 'IE00BS7K1057', 'IE00BS7K1388', 'IE00BS7K1727', 'IE00BS7K1610', 'IE00BS7K0Z35', 'IE00BS7K1M70', 'IE00BS7K1L63', 'IE00BS7K1Q19', 'IE00BYP0Y993', 'IE00BYP0YB11', 'IE00BYZRLD33', 'IE00BYZRLN31', 'IE00BYQG5481', 'IE00BJ7BP363', 'IE00BD8F1931', 'IE00BD89JF77', 'IE00BD89JM45', 'IE00BD89JD53', 'IE00BFNWY382', 'IE00BJ7BPH02', 'IE00BJ7BPJ26', 'IE00BJ7BP256', 'IE00BFNXS390', 'IE00BYZXGH04', 'IE00BGHFHB96', 'IE00BJJ01669', 'IE00BD89JL38', 'IE00BGHFH978', 'IE00BTLK2V61', 'IE00BJ9N4881', 'IE00BJ9N4C21', 'IE00BJ9N4998', 'IE00BJ9N4B14', 'IE00BTLK2F04', 'IE00BJ9M5S24', 'IE00BJ9M5R17', 'IE00BJXPXW70', 'LU2048742550', 'LU2048742477', 'LU2048742980', 'LU2048743442', 'LU2048743103', 'IE00BYZRLR78', 'IE00BYZRLQ61', 'IE00BJ7BQ775', 'IE00BMW2V933', 'IE00BMFPHG85', 'IE00BMW2DP35', 'IE00BKVCZG56', 'IE00BMFPHJ17', 'IE00BKVCZH63', 'LU0648599941', 'LU1761538492', 'LU1914594822', 'LU2055179878',
'LU1112682742', 'LU0111451596', 'LU0098446601', 'CH0012115249', 'CH0012115264', 'CH0012115272', 'CH0016227735', 'LU0190961812', 'IE0000360275', 'LU0143829926', 'LU0143830775', 'CH0017037729', 'CH0147951146', 'CH0147951138', 'LU0146652267', 'LU0194438338', 'LU0196034820', 'AT0000618012', 'LU0236502661', 'IE00B01FHS02', 'LU0130517807', 'LU0107108630', 'LU0113536907', 'LU0164888108', 'LU0209240984', 'IE00B0CQS788', 'CH0117503471', 'VGG7238P1062',
'VGG7238P1146', 'VGG7238PAA45', 'CH0017403574', 'CH0017403509', 'CH0017403467', 'CH0023903567', 'LU0248044298', 'LU0248274374', 'LU0231612655', 'LU0248058678', 'TW000T0816Y6', 'CH0025508901', 'CH0023004127', 'LU0248042839', 'GB0001784577',
'GB0001784791', 'GB0001783389', 'GB0001784353', 'GG00BYNN1D30', 'GG00BYNN2417', 'GG00BYNN3D79', 'GG00BYNN1336', 'GG00BYNN2J66', 'GG00BYNN2M95', 'GG00BYNNND70', 'GG00BYNNPK87', 'GG00BYNNN939', 'GG00BYNNMX02', 'GG00BYNNMT65', 'GG00BYNNPN19',
'GG00BYNNNP92', 'GG00BYNNNH19', 'GG00BYNNPR56', 'CH0019243093', 'GG00BYNN1V12', 'GG00BYNN2T64', 'GG00BYNN5S62', 'GG00BYNN5642', 'GG00BYNN5H57', 'VGG720151379', 'VGG720151296', 'VGG8802B1013', 'GG00BYNN5D10', 'GG00BYNN5P32', 'GG00BYNN4B21',
'GG00BYNN4T05', 'GG00BYNNN608', 'GG00BYNNN046', 'GG00BYNN5L93', 'GG00BYNN6277', 'GG00BYNN6509', 'GG00BYNN6830', 'LU0260352280', 'GB0004467568', 'GB0004467675', 'GB0001787141', 'GB0004466263', 'GB0001786739', 'GB0004466719', 'GG00BYNN3Q09',
'GG00BYNN2H43', 'GG00BYNN2L88', 'GG00BYNN4F68', 'GG00BYNN4M36', 'GG00BYNN4Z64', 'GG00BYNN6C77', 'GG00BYNN6G16', 'GG00BYNN6K51', 'LU0147419252', 'IE00B1W57R51', 'LU0159055499', 'LU0239275513', 'LU0143831310', 'LU0248060906', 'LU0143839644',
'GG00BYNN6V66', 'GG00BYNNLJ92', 'GG00BYNN3H18', 'LU0323240613', 'LU0157922724', 'IE00B1W57T75', 'GG00BYNN7465', 'GG00BYNN6Y97', 'GG00BYQ72V72', 'GG00BYNN5972', 'LI0033042511', 'LI0033042628', 'GG00BYQ72Y04', 'CH0035719936', 'CH0035719993',
'CH0035723649', 'LU0347712274', 'LU0282389328', 'LU1883319391', 'LU0278922561', 'IE00B296W735', 'GG00BYNN7796', 'IE00B1LBTX41', 'LU0333227717', 'LU0333227808', 'LU0333227394', 'LU0298310920', 'LU0232832559', 'LU0232829845', 'GG00BYNNNL54',
'LU0369584999', 'LU0329571961', 'LU0209157659', 'BE0945682293', 'CH0035722625', 'CH0035720041', 'LU0329440605', 'CH0036840848', 'GG00BYNNNM61', 'GG00BYNN7Q88', 'GG00BYNN7T10', 'GG00BYNN7M41', 'GG00BYNN7J12', 'LU0336296644', 'GG00BYNN8L09',
'GG00BYNN8H62', 'GG00BYNN8984', 'GG00BYNN8323', 'IE00B23Z9R57', 'KYG722711028', 'KYG722711101', 'CH0035721064', 'LU0292106167', 'CH0034402450', 'LI0045219693', 'LU0404570201', 'LU0319685698', 'LU0356832245', 'LU0336295836', 'LU0282389757',
'LU0347711896', 'LU0096374862', 'LU0411267874', 'LU0397699488', 'LU0397699561', 'LU0397699645', 'LU0239275513', 'MT7000008736', 'KYG4071M1006', 'LU0411266983', 'IE00B3F05W23', 'LU0336682793', 'VCP8240B1016', 'IE00B42HN743', 'LU0271684184',
'LU0271690231', 'LU0353121378', 'LU0411267791', 'LU0258444248', 'BE0948485199', 'LU0395210163', 'LU0427297527', 'LU0292263935', 'IDN000062403', 'LU0434360664', 'LU0434360318', 'GG00BYNN9G21', 'GG00BYNN9C82', 'GG00BYNN9K66', 'GG00BYNN9R36',
'GG00BYNN9N97', 'GG00BYNN8W14', 'GG00BYNN8Z45', 'GG00BYNN8P47', 'GG00BYNN8S77', 'GG00BYNN9842', 'GG00BYNN9511', 'GG00B3CZNV56', 'GG00B3CTZD37', 'LU0411267288', 'LU0352160815', 'IDN000161502', 'LU0447608430', 'LU0451080401', 'LU0451080823',
'LU0451080583', 'IDN000003001', 'LU0462857607', 'LU0462857789', 'LU0421789008', 'LU0473186707', 'GB00B0NRRV79', 'GG00BYNN9V71', 'LU0441521266', 'LU0440072238', 'LU0329204118', 'LU0476438725', 'IE00B2368V55', 'LU0430559418', 'LU0164852419',
'IE00B551XW55', 'IE00B5633086', 'IE00B57DYK46', 'IE00B51YKT44', 'GG00B4TH5937', 'LU0395883589', 'FR0010819409', 'GG00BYNNB777', 'LI0111017039', 'LI0111017070', 'LI0111017062', 'GG00BYNN9Y03', 'GG00BYNNB447', 'GG00BYNNLM22', 'GG00BYNNB116',
'LU0501950314', 'LU0248001728', 'LU0480038693', 'IE00B5W77D59', 'CH0112330508', 'LU0278909543', 'IE00B4JSJH29', 'LU0365458107', 'LU0399012771', 'LU0528412322', 'GG00B29MQ577', 'LU0526609127', 'LU0526609630', 'LU0528100869', 'LU0527896996',
'IE00B42HM661', 'LU0396332727', 'LU0540970182', 'GG00BYNNPW00', 'IE00B01VCY90', 'IE00B29M4533', 'IE00B0XXF555', 'IE00B5SLTH97', 'CH0117633377', 'LU0543369770', 'LU0543370190', 'LU0543370943', 'LU0543369341', 'LU0543369697', 'CH0019988416',
'CH0101318282', 'CH0019988382', 'CH0101318258', 'CH0019988333', 'CH0019988598', 'CH0019988564', 'CH0019988457', 'LU0499860848', 'LU0506525996', 'GG00BYNNCS50', 'GG00BYNNCW96', 'GG00BYNNC965', 'GG00BYNNCD08', 'GG00BYNNCH46', 'GG00BYNNCZ28',
'GG00BYNNCL81', 'GG00BYNNCP20', 'GG00BYNND260', 'LU0556617743', 'IE00B3MFHV32', 'LU0441852455', 'CH0117503455', 'LU0511406489', 'LU0495654708', 'IE00B5MKZK75', 'GG00B3NKN866', 'LU0553814020', 'LU0553815001', 'LU0553820936', 'LU0553821827',
'LU0553822049', 'LU0553822809', 'LU0553823799', 'LU0553823872', 'LU0553826115', 'LU0554222652', 'LU0554225242', 'LU0554226729', 'LU0554227610', 'LU0554228188', 'LU0554230838', 'LU0554237338', 'LU0554237684', 'LU0554238062', 'LU0562701085',
'LU0532510483', 'MU0230S00237', 'NL0001118015', 'GG00B1BGMV87', 'LU0580566189', 'LU0415185379', 'LU0173770602', 'LU0556184538', 'LU0569317836', 'KYG211621050', 'FR0010985002', 'LU0570480771', 'LU0533024757', 'LU0594694951',
'LU0292263935', 'LU0403314684', 'LU0592995731', 'LU0318933560', 'GB00B09CCP59', 'LU0564627593', 'IE00B40YY418', 'LU0266009520', 'LU0612440262', 'GG00BYNNF315', 'GG00BYNNF646', 'GG00BYNNF083', 'GG00BYNNF422', 'GG00BYNNF752', 'GG00BYNNF190',
'GG00BYNNDR19', 'GG00BYNNDV54', 'GG00BYNNDY85', 'GG00BYNNDN70', 'LU0265267285', 'LU0194438338', 'GG00BYNNFB98', 'LU0555015212', 'LU0555015568', 'IE00B3SJ0764', 'IE00B45RWW92', 'LU0375177804', 'IE00B57F1K94', 'GG00BYNNFM04', 'GG00BYNNFQ42',
'GG00BYNNFX19', 'GG00BYNNG057', 'GG00BYNNFT72', 'GG00BYNNFF37', 'GG00BYNNFJ74', 'GG00BYNNBM24', 'GG00BYNNBQ61', 'IE00B3L6P915', 'GG00BYNNG388', 'GG00BYNNG610', 'LU0430557719', 'GG00BYNNGD87', 'KYG0067P1726', 'KYG0067P1569', 'KYG0067P1643',
'KYG0067P1494', 'GG00BYNNG941', 'GB00B3SL4812', 'GB00B3SWVX84', 'GB00B67BB375', 'HK0000081882', 'IE00B5MJRF34', 'KYG722711366', 'KYG722711440', 'LU0568619711', 'IE00B3FPGY60', 'LU0525380175', 'IE00B4XXMP29', 'LU0082770016', 'LU0039217434',
'IM00B3TN8J56', 'IE00B5KPBC88', 'IE00B5LJDS93', 'IE00B3L0RQ42', 'LU0641480529', 'LU0605964500', 'GG00BYNNGH26', 'FR0011042167', 'LU0557862322', 'LU0613078699', 'LU0356218221', 'LU0539371368', 'GG00B5L4FL18', 'LU0638828524', 'LU0512128512',
'LU0650147423', 'LU0122113094', 'FR0011073774', 'GG00BYNNH246', 'GG00BYNNH576', 'GG00BYNNGZ08', 'LU0629872861', 'GG00BYNNGP00', 'GG00BYNNGL61', 'LU0164899485', 'LU0518437115', 'LU0164892712', 'LU0329931686', 'LU0210635685', 'LU0566116579',
'IE00B67JBJ71', 'GG00BYNNHK20', 'GG00BYNNBF56', 'GG00BYNNBJ94', 'GG00BYNNBB19', 'GG00BYNNC304', 'GG00BYNNC635', 'GG00BYNNC072', 'GG00BYNNBX39', 'GG00BYNNBT92', 'GG00BYNND591', 'GG00BYNNHG83', 'GG00BYNNHC46', 'LU0626906829', 'LU0381761617',
'IE00B4MDT298', 'LU0669554353', 'IE00B1Y4M427', 'LU0535164593', 'IE00B1KQWK86', 'IE00B19FYF55', 'IE00B1KQX383', 'IE00B19FTP01', 'LU0654573939', 'IE00B5L7L670', 'GG00BYNNHY65', 'GG00BYNNHV35', 'GG00BYNNHR98', 'GG00BYNNHN50', 'GG00BYNNJ283',
'LU0640448261', 'IE00B4WLNZ47', 'IE00B560ZH02', 'IE00B4TD9V17', 'LU0640476809', 'LU0640477104', 'GG00BYNNJ515', 'LU0493866569', 'LU0658027080', 'LU0620440395', 'IE00B617N244', 'IE0031142098', 'LU0688581437', 'GG00BYNNJ846', 'LU0642275217',
'LU0506198265', 'LU0704893923', 'LU0686794354', 'LU0477156524', 'LU0692311367', 'IE00B6R2T693', 'LU0129463922', 'LU0551365645', 'LU0551366700', 'LU0404507260', 'LU0551371700', 'CH0117502515', 'CH0117502572', 'LU0549550811', 'LU0575375232',
'LU0577847170', 'LU0577847170', 'IE00B4QL5Y33', 'GG00BYNNJJ54', 'GG00BYNNJF17', 'LU0622164928', 'LU0404495664', 'LU0404495821', 'LU0524292009', 'LU0622165578', 'LU0301639745', 'LU0131210790', 'LU0111491469', 'LU0111491626', 'LU0111493325',
'LU0066794719', 'LU0107059080', 'LU0107049875', 'LU0107058785', 'LU0566116579', 'LU0566116579', 'LU0566116579', 'LU0566116579', 'LU0566116579', 'LU0404504911', 'LU0404504911', 'LU0404504911', 'LU0404504911', 'LU0404504911', 'LU0404504911',
'LU0518437115', 'LU0518437115', 'LU0518437115', 'LU0518437115', 'LU0518437115', 'LU0518437115', 'LU0518437115', 'LU0553870527', 'LU1003011217', 'IE00B3LG4J44', 'IE00B19Z4662', 'IE00B40GGT61', 'IE00B41H5N55', 'LU0589981330', 'IE00B53CN972',
'LU0693856642', 'IE00B3Q48R91', 'IM00B3RYR596', 'LU0555017697', 'LU0658686109', 'GG00BYNNJN90', 'LU0365482669', 'LU0421789420', 'IE00B7D6J849', 'IE00B3XPCW80', 'LU0747349875', 'LU0749326814', 'LU0749328356', 'GG00BYNNJR39', 'LU0248052804',
'GG00BYNNJV74', 'BE0947865789', 'IDN000113404', 'LU0752741651', 'IE00B4Y8B378', 'LU0764821012', 'IE00B44PG779', 'GG00BYNNK141', 'MT7000004925', 'MT7000004917', 'LU0493844590', 'JE00B5V8LK49', 'IE00B4TCZL03', 'IE00B4T88P33', 'IE00B5MS1C59',
'LU0677960717', 'LU0451400591', 'GG00BYNNK695', 'GG00BYNNK703', 'GG00BYNNK364', 'GG00BYNNK471', 'LU0607297198', 'IE00B81VK961', 'LU0549552270', 'LU0673562095', 'IE00B3RCT804', 'LU0794920784', 'LU0773065791', 'GG00BYNNKF89', 'GG00BYNNKB42',
'GG00BYYFGX31', 'LU0572162393', 'LU0572160694', 'IE00B7MDQ966', 'IE00B8JWHG71', 'IE00B8DZ8363', 'LU0809804775', 'GG00BYNNKL40', 'GG00BYNNKS19', 'GG00BYNNKP87', 'IE00B7BYDJ95', 'LU0780249255', 'IE00B6R2TL43', 'LU0622164928', 'GG00BYNNKW54',
'GB00B7NJ0M49', 'GB00B84LZT89', 'GB00B87LP737', 'LI0193820359', 'LU0408847266', 'LU0821217063', 'LU0572161585', 'GB00B84RNC52', 'LU0828908078', 'IE00B3TGMB49', 'IE00B29M7F41', 'LU0750253519', 'LU0800574237', 'LU0846584216', 'IE00B19FYH79',
'LU0807187728', 'LU0329202922', 'LU0828821115', 'LU0852339042', 'LU0845089852', 'LU0845089936', 'IE00B9238163', 'FR0010256412', 'LU0704153484', 'LU0551371700', 'IE00B91N5K51', 'IDN000094802', 'IDN000145802', 'LU0673562178', 'LU0865488281',
'IE00B95F0K20', 'LU0837973477', 'LU0857970346', 'LU0858068744', 'LU0858068405', 'LU0858068405', 'LU0858068405', 'LU0849272793', 'LU0871581103', 'LU0872703946', 'IE00B64LNM30', 'LU0619172413', 'TW000T2541A8', 'TW000T2541B6', 'LU0712124089',
'LU0878583243', 'IE00B87NSN68', 'LU0524292009', 'LU0524292009', 'LU0524292009', 'LU0524292009', 'LU0524292009', 'LU0878858447', 'IE00B9895S40', 'LU0886630713', 'LU0854281283', 'LU0854296984', 'LU0854297016', 'LU0854286837', 'LU0854287215',
'LU0854288379', 'LU0854290516', 'LU0854290607', 'LU0854291753', 'IE00B6R54W91', 'IE00B4L8GV30', 'LI0197035467', 'LU0640477104', 'LU0720463073', 'GB00B1RNPT25', 'IE00B84M0855', 'CH0042915428', 'CH0018316577', 'LU0842203886', 'LU0448068949',
'LU0807187728', 'LU0807187728', 'LU0807187728', 'LU0807187728', 'LU0807187728', 'IE00B9CLX269', 'LU0907928575', 'LU0551365645', 'LU0888108056', 'CH0190273422', 'LU0908572075', 'KYG7110M1069', 'IE00B88VF164', 'IE00B7WJ7T17', 'IE00B664JN33',
'LU0842200353', 'IE00B62Q6870', 'IE00BDD1NC38', 'LU0159050938', 'LI0210408428', 'IE00B8DTBM88', 'TW000T2540B8', 'LU0842199910', 'LU0823431308', 'LU0823438493', 'IE00B93N8153', 'IE00B3TM7Q27', 'IE00B843XM30', 'LU0823390603', 'LU0823395826',
'LU0823398333', 'LU0823427298', 'LU0823432967', 'LU0832085541', 'LU0823415525', 'LU0823417570', 'LU0823418974', 'LU0823422737', 'LU0897029509', 'LU0823405054', 'LU0823412779', 'LU0823438659', 'LU0823438907', 'LU0329205867', 'LU0926764555',
'LU0780249255', 'LU0573560066', 'LU0533021654', 'HK0000130705', 'LU0851818400', 'LU0938962460', 'LU0404507260', 'LU0404507260', 'LU0404507260', 'LU0404507260', 'LU0404507260', 'LU0842200437', 'HK0000151800', 'HK0000151818', 'KYG7359H1074',
'LU0928516649', 'LU0943717164', 'LU0944429587', 'IE00BD89KB03', 'IE00BD89K988', 'LU0947203542', 'LU0947203542', 'IE00B8BJYL03', 'IE00B9C64391', 'LU0951484418', 'LU0798467675', 'LU0871321617', 'IE00B41XXB76', 'IE00B23Z9C05', 'LU0940719254',
'GB00BC1J4B86', 'IE00B1BXJ189', 'IE00B7T3S609', 'IE00B19Z4F54', 'IE00B8XS1D67', 'IE00B7VSFK60', 'LU0863886775', 'LU0679203769', 'LU0679203769', 'IE00B4LXLW41', 'LU0960906922', 'LU0960906765', 'LU0953300703', 'LU0754593787', 'LU0228653746',
'LU0912264057', 'LU0935261551', 'LU0943510494', 'LU0577845711', 'IE00BBT3JQ51', 'LU0577845711', 'LU0295689367', 'IE00BBM55T37', 'LU0975138727', 'GB00BF2H6F06', 'IE00B7VGZP57', 'LU0966596529', 'LU0960650439', 'LU0971934525', 'LU0971934798',
'LU0551366700', 'LU0960650512', 'LU0960650603', 'IE00B872KD71', 'IE00BDGTT593', 'IE00BB2CTV43', 'LU0943624824', 'LU0248059486', 'LU0964819261', 'LU0329203813', 'BE6254420548', 'IE00BCZNWX44', 'IE00BCZNX426', 'LU0289214891', 'LU0973530347',
'LU0973522328', 'LU0973530008', 'LU0441853693', 'LU0973650160', 'LU0973525180', 'LU0930307458', 'IE00B6T5CL29', 'IE00B6T5QZ43', 'LU0973523052', 'IE00BFRSZ172', 'LU0608088307', 'LU0963988752', 'DK0060517076', 'DK0060517662', 'DK0060517316',
'HK0000167319', 'LU0985871440', 'IE00B8N4ZC15', 'IE00B46FGQ44', 'LU0954271382', 'LU0854290516', 'LU0957041402', 'LU0972074701', 'LU0995827663', 'LU0897029764', 'IE00BG800P82', 'LU0635709586', 'LU0396353996', 'IE00B5QKM460', 'LI0222162401',
'LI0222162518', 'LI0222162542', 'IE00BGLP2096', 'LU0973154932', 'LU1883303122', 'GB00BH36TH37', 'LU0966592700', 'LU0964942386', 'LU0968474089', 'LU0746600021', 'LU0465917713', 'LU1002872189', 'LU0723589296', 'LU0970178595', 'LU0823447643',
'LU0898252456', 'LU0898253264', 'LU0996084637', 'IE00B96LYN04', 'LU0823412779', 'IE00BHZKQC78', 'IE00BHZKQF00', 'IE00BH4GY884', 'LU0984157007', 'LU1014773714', 'LU0471720556', 'IE00BGDFFC00', 'IE00BGDFFF31', 'IE00BGDFFD17', 'IE00B8V74V41',
'IE00BFWH3Q02', 'IE00B8BLVW66', 'LI0032373487', 'LI0034296587', 'LI0104530832', 'LU1023284349', 'LU0930307458', 'IE00BJ3VGL56', 'LU0959211326', 'IE00BB2CTS14', 'IE00BB2CV293', 'LU1033658136', 'IDN000162609', 'IE00BDZDQN94', 'LU0990494055',
'LU0912264487', 'IE00BFNWYV69', 'IE00BJ7BPG94', 'IE00BJ7BPT24', 'IE00BJ7BPV46', 'IE00BFNWZC46', 'CH0113947128', 'LI0216797626', 'LU0969341816', 'IE00BKRC1166', 'HK0000186863', 'IE00BB2CTZ80', 'LU0883254517', 'SG9999011399', 'SG9999011415',
'LU0995385977', 'LU0953053773', 'LU0954269212', 'IE00BC1J1951', 'IE00B7VSHT93', 'IE00B23Z8W36', 'LU0533021654', 'LU0533021654', 'LU0533021654', 'KYG722711853', 'KYG722711937', 'KYG722711770', 'KYG722712018', 'LU0935230242', 'GG00BKM3ZT04',
'GG00BKM3ZV26', 'GG00BKM40767', 'GG00BKM40650', 'GG00BKM40098', 'LU1061270184', 'LI0183053573', 'LI0029598070', 'LI0183053722', 'LU0883094483', 'LU0999662033', 'IE00BLG2W338', 'LU0954269212', 'LU0954269212', 'LU0954269212', 'LU0954269212',
'LU0323240613', 'LU0323240613', 'LU0323240613', 'LU0323240613', 'LU0323240613', 'LU0323240613', 'IE00BLP58L37', 'IE00BLP58Q81', 'IE00BLP58X58', 'IE00BLP58K20', 'IE00BK0SCM97', 'IE00BHBX1G94', 'LU0823383657', 'SE0001510488', 'LU0406802925',
'LU1071421777', 'IE00BKXGFG54', 'LU1072257063', 'LU1072257220', 'LU1072257063', 'LU1072257220', 'LU0647999324', 'LU1074935732', 'LU0968472208', 'IE00B5W95P95', 'CH0236334295', 'LU1075799665', 'LU1075804929', 'IE00B8XW1G17', 'LU1077972625',
'LU1077972625', 'LI0247528586', 'LU1066053437', 'LU1066053866', 'LU1069710306', 'LU0972490832', 'LU0971088629', 'LU0972489743', 'LU1087925589', 'LU0863888128', 'LU1096619371', 'LU0686082529', 'LU1060949341', 'LU1090541860', 'IE00BF5S8G57',
'LU0329931686', 'LU0329931686', 'LU0329931686', 'LU0329931686', 'LU0329931686', 'LU0955569065', 'LU0955569065', 'LU0972488935', 'IE00B7WJSH60', 'IE00BH6XS969', 'IDN000083300', 'LU1062144412', 'LU0718510281', 'IE00BCZRQS94', 'LI0252716753',
'HK0000211968', 'LU1109965514', 'LU1091661030', 'LU1074963197', 'LU0525286158', 'LI0253998061', 'LI0253998095', 'IE00BQJZX085', 'TW000T3626C2', 'IE00BRJG1710', 'LU0822042882', 'LU1120824096', 'LU1120824179', 'LU1120824252', 'LU1120824336',
'LU1120824419', 'LU1120824500', 'LU1120824682', 'LU1105450701', 'LU1105450966', 'LU1105450883', 'LU1105451691', 'LU1105447665', 'LU1060356877', 'LU1103150238', 'LU0404495821', 'LU0404495821', 'LU0404495821', 'LU0404495821', 'GB00BRJTG867',
'GB00BRGCDR64', 'GB00BRWQX051', 'GB00BRJTG644', 'TW000T0727D9', 'HK0000220134', 'LU0930305247', 'IE00BRS5SS96', 'IE00BRS5SR89', 'IE00BRS5SZ63', 'IE00BKJ92K19', 'IE00BKJ92P63', 'LU0809674467', 'IE00BFNXS390', 'IE00BQZJ5081', 'LU1135392162',
'LU1107613686', 'IE00BL0RFW42', 'LU0854924973', 'IE00BMNQM245', 'LU1099139443', 'LU1091661386', 'IE00BSTL7535', 'CH0253993403', 'LU0952010832', 'GG00BKM42D63', 'LU1139081738', 'IE00BB36BR51', 'IE00BJGZXW46', 'IE00BPYD8497', 'IE00BPYD8W78',
'LU0839528493', 'LU0945156379', 'IE00BQ5BN660', 'IE00BBT3K395', 'IE00BSZLQH20', 'NL0010831079', 'IE00BKRVJJ72', 'IE00BKRVJD11', 'IE00BQ5BN330', 'LU1164803873', 'LU0164892712', 'LU0164892712', 'LU0164892712', 'LU0164892712', 'LU0164892712',
'LU0863495239', 'LU1163202150', 'IE00B80L1726', 'BMG9727M1495', 'BMG9727M1560', 'BMG9727M1644', 'BMG9727M1727', 'LU0842209909', 'LU0950589571', 'LU1166642592', 'IDN000193604', 'LU1162497827', 'LU0974391509', 'LU1048480955', 'LU1077378633',
'LU0648005311', 'IE00BFN0KY55', 'IE00BTL1H815', 'IE00BBT3JS75', 'GG00B4N0KC64', 'LU1165977999', 'GI000A1JNMB7', 'IE00B78P2765', 'LU1132616415', 'HK0000220399', 'IE00BFLQFF13', 'IE00B7396591', 'LU1060356281', 'LU1060351647', 'IE00BS7K1727',
'LU0979706883', 'IE00BTLJYG20', 'GB00BV0LF938', 'GG00B7GHG499', 'GG00BP268062', 'GB00B0JF6306', 'LU1198820521', 'LU1198822063', 'LU1198822220', 'LU1198822220', 'LU1196383050', 'LU1196383217', 'LU0862297578', 'IE00BSNB8564', 'LU1102789697',
'HK0000220175', 'LU1108653095', 'LU0857973795', 'LU0164899485', 'LU0164899485', 'LU0164899485', 'LU0164899485', 'LU0755948287', 'LU0807707582', 'LU0828911536', 'IE00BKZGXZ81', 'IE00BKZGY116', 'LU0823395826', 'LU1158939980', 'LU1158941457',
'LU1220955410', 'LU1215828564', 'LU1097722752', 'LU1218962063', 'LU1137259153', 'IE00BW0D8977', 'LU1226288097', 'IE00B787CW72', 'LU1104109720', 'LU1104109993', 'IE00B7VSND51', 'LU1155791426', 'LU1236619315', 'IE00BWBSFJ00', 'IE00BX9BZG59',
'LU0508499539', 'IE00BBQ36Z62', 'LU0972489313', 'LU1060352298', 'LU1060354823', 'IE00B986GC63', 'LU1257007481', 'IE00BWZMLP69', 'LU0957587545', 'LU1240811650', 'LU1236619315', 'LU1236619315', 'LU1236619315', 'LU1236619315', 'LU1236619315',
'LU1236546922', 'LU1016060821', 'GG00B55CC870', 'LU0720471928', 'BMG9727M1982', 'BMG9727M1800', 'BMG9727M2063', 'LU1118018867', 'LU0360481237', 'IE00B1KQWJ71', 'GG00B3YQL906', 'LI0243483836', 'LI0243483851', 'LU1251898224', 'LU1278922619',
'LU0890598369', 'LU1248519040', 'LU1248519040', 'LU1248519040', 'LU1248519040', 'IE00B4XPDX38', 'IE00B3RB1258', 'LU1240771177', 'LU1263145580', 'LU0940718876', 'FR0012399731', 'LU0883254350', 'LU0883254863', 'LU0883254608', 'LU1289971720',
'LU0822044748', 'LU1217285912', 'LU0164899485', 'LU0897029848', 'LU1277577638', 'LU0396349291', 'LU1288018044', 'LU1288020370', 'LU1288020966', 'LU1288020701', 'LU1288021188', 'LU1288020024', 'LU1280406171', 'LU1288018390', 'LU0957810129',
'IE00B95PGT31', 'KYG9530N3005', 'AD000A14ZV97', 'LU1258508040', 'LU1258508123', 'LU1258507828', 'LU1272229672', 'LU1272229839', 'LU1258508040', 'LU1258508123', 'LU1288022665', 'IE00B44M8N80', 'LU1340030060', 'LU1303884347', 'LU1303886391',
'IE00BZ0GCQ20', 'IE00BYM84742', 'IE00BYPGK224', 'TW000T4508C1', 'TW000T2109J5', 'IE00BCBVR158', 'TW000T2121L6', 'TW000T2289A4', 'LU1255422120', 'LU1278830093', 'CH0261144866', 'CH0261144817', 'CH0261145574', 'CH0261144767', 'IE00BYQ96226',
'IE00BYSFF105', 'IE00BYVTJY00', 'LU1327397144', 'LU1327397227', 'LU1327397060', 'LU1325857099', 'LU1310446312', 'IE00BKJ92M33', 'LU0862297909', 'LU1327535040', 'LU1291080965', 'LU1278810061', 'KYG0004A1067', 'KYG273161193', 'KYG273161011',
'CH0101318233', 'IE00B7VSM832', 'IDN000168408', 'LU1278655235', 'TW000T2213A4', 'LU0914734883', 'IE00BZ09B756', 'IE00BYZXGH04', 'IE00BYZXJX76', 'IE00BYZXKD44', 'LU1358059126', 'LU0605621480', 'LU1044370879', 'IDN000170305', 'LU0404495664',
'LU0404495664', 'MU0312S00100', 'LU0404495664', 'LU0404495664', 'IE00BH6XSM92', 'IE00BYNG3D66', 'LU1291103338', 'LU1366334651', 'FR0013015534', 'MU0498S00008', 'LU1883837855', 'TW000T2123C1', 'TW000T2123Q1', 'LU1387591644', 'LU1050472742',
'LU1373036711', 'IE00BYZHYR83', 'IE00BYZHYY50', 'LU1365053351', 'IE00BYSZ6179', 'LU1049757047', 'LU1164080316', 'GG00BMNR0J07', 'GG00BMNR0M36', 'GG00BMNR0G75', 'GG00BMNR0Q73', 'LU1270634519', 'LU1270634519', 'IE00BYR8FX24', 'IE00BYR8FM19',
'IE00BYR8G173', 'IE00BTLJYN96', 'LU1319692528', 'IE00BD3CR248', 'LU1212745076', 'IE00BSL77484', 'HK0000292786', 'IE00BWSW3G89', 'IE00BWVFLM65', 'LU1379517037', 'IE00BYZ7YL29', 'GG00BD34LW86', 'GG00BD34LR34', 'GG00BD34M815', 'GG00BD34MF86',
'GG00BD34M252', 'GG00BD34MN60', 'GG00BD34MR09', 'GG00BD34MK30', 'GG00BD34N003', 'GG00BD34N441', 'GG00BD34MX68', 'GG00BD34LX93', 'GG00BD34M039', 'GG00BD34LT57', 'GG00BD34MB49', 'GG00BD34MH01', 'GG00BD34M369', 'GG00BD34MP84', 'GG00BD34MT23',
'GG00BD34ML47', 'GG00BD34N227', 'GG00BD34N557', 'GG00BD34MY75', 'GG00BD34LZ18', 'IE00BDHFW248', 'LU0981835407', 'LU0981846602', 'IE00BD0SDY97', 'LU1342916647', 'IE0031876984', 'GG00BYXF2142', 'GG00BYY1Q194', 'GG00BYY1Q202', 'GG00BYY1Q533',
'GG00BYY1Q970', 'GG00BYY1QF33', 'GG00BYY1QJ70', 'GG00BYY1QK85', 'GG00BYY1QM00', 'GG00BYY1QP31', 'GG00BYY1QR54', 'GG00BYY1QX15', 'GG00BYY1R168', 'GG00BYY1R382', 'LU1435042046', 'IE00B99L8M46', 'IE00BYT1L978', 'LU1076664439', 'LU1390458583',
'HK0000263076', 'LU1420448463', 'CH0259132196', 'LU1438154558', 'IE00BYT35Q80', 'IE00BYT36101', 'LU1433065858', 'LU1433065858', 'LU1455021367', 'LU0513029313', 'IE00B42WHC26', 'LU0946659223', 'IE00BSL79V94', 'IE00BDBBQL54', 'SE0008092290',
'SE0008092282', 'LU1432307756', 'LU1408339320', 'GG00BYT1DX51', 'GG00BYT1DY68', 'GG00BYT1F061', 'GG00BYT1F178', 'GG00BYT1F624', 'GG00BYT1F731', 'GG00BYY6VZ37', 'GG00BYY6W167', 'GG00BYY6W381', 'GG00BYY6WD81', 'GG00BYY6WF06', 'LU1369533200',
'LU1342507552', 'GG00BYT1FQ25', 'GG00BYT1FP18', 'GG00BYT1FT55', 'GG00BYT1FS49', 'GG00BYT1G036', 'GG00BYT1FZ16', 'GG00BYT1FB71', 'GG00BYT1F954', 'GG00BYT1FF10', 'GG00BYT1FD95', 'GG00BYT1FH34', 'GG00BYT1FM86', 'GG00BYT1FL79', 'GG00BYT1G259',
'GG00BYT1G366', 'GG00BYT1G580', 'GG00BYT1G697', 'GG00BYT1G812', 'GG00BYT1G929', 'GG00BYT1GC53', 'GG00BYT1GD60', 'IE00BZ01R064', 'LU1332517405', 'LU1160526791', 'IE00BCBVPK04', 'LI0339233467', 'GG00BKM42C56', 'GG00BWX5H718', 'GG00BWX5H932',
'GG00BWX5HC62', 'GG00BWX5HB55', 'GG00BWX5J086', 'GG00BWX5J425', 'GG00BWX5J318', 'GG00BQSBWB13', 'GG00BQSBW997', 'GG00BQSBWC20', 'GG00BWX5J649', 'GG00BWX5J979', 'GG00BWX5J862', 'GG00BWX5JB95', 'GG00BQSBWJ98', 'GG00BQSBWH74', 'GG00BQSBWK04',
'GG00BWX5JD10', 'GG00BQSBWM28', 'GG00BWX5JJ71', 'GG00BWX5JG41', 'GG00BWX5JH57', 'GG00BWX5HM60', 'GG00BWX5HR16', 'GG00BWX5HQ09', 'GG00BWX5HT30', 'GG00BWX5HY82', 'GG00BWX5HX75', 'GG00BWX5H155', 'GG00BWX5H593', 'GG00BWX5H486', 'LU1258427126',
'LU1495613405', 'LU1479460237', 'LU1467538259', 'LU1319657299', 'LU1460782573', 'LU1450629776', 'LU1450632309', 'LU1494628131', 'HK0000277316', 'LU1469472473', 'LU0755947719', 'IE00BTL1GL76', 'IE00BDCRKR63', 'LU1494628131', 'LU1437021626',
'IE00BD2MFB05', 'LU1511578319', 'LU1078681431', 'LU1078681514', 'IE00BYSPZC13', 'LU1483618358', 'LU1521667375', 'IE00BYXVW230', 'LU1460782573', 'LU1460782573', 'LU1515062245', 'LU1883305416', 'KYG722713008', 'LU1493704123', 'LU1517942113',
'LU1170326984', 'LU1515367354', 'IE00BZ0RSM31', 'IE00BYQRSY24', 'LU1540968507', 'SG9999016018', 'MT7000012431', 'MU0498S00032', 'FR0013223583', 'LU1506406369', 'LU1506407508', 'LU1162498122', 'LU1529682137', 'GG00BD8PLZ90', 'GG00BD8PM232',
'GG00BD8PM562', 'GG00BD8PM893', 'GG00BD8PMC39', 'GG00BD8PMG76', 'GG00BD8PM018', 'GG00BD8PM349', 'GG00BD8PM679', 'GG00BD8PM901', 'GG00BD8PMD46', 'GG00BD8PMH83', 'LU1223406510', 'LU1458397343', 'LU0823398333', 'LU1426150295', 'LU0980595986',
'IE00B454YC49', 'MU0363S00012', 'LU1529950088', 'LU1529950161', 'LU1077379870', 'LU1050472742', 'IE00BYX7ML02', 'LU1545724228', 'LU1557120232', 'LU1569815910', 'BMG639051710', 'IE00BKWBVD04', 'IE00BYXSFV48', 'IE00BSL79X19', 'IE00BSL7B139',
'IE00B86JXG34', 'IE00BD5TM289', 'HK0000252178', 'IE00BP8XZ440', 'LU1490632699', 'LU1547475670', 'LU1437019307', 'IE00BDCJZB15', 'LI0021995852', 'LU1589587911', 'IE00B467CR35', 'IE00BD3VQY60', 'IE00BF1TFX81', 'LU1170059502', 'LU1170056409',
'LU1170059254', 'LU1609660920', 'IE00BDD95Q56', 'SG9999012512', 'IE00BD8Z6M11', 'LU1611257095', 'IE00BBT3JT82', 'LU1605734901', 'GG00BWX5HF93', 'GG00BWX5HH18', 'GG00BWX5HK47', 'GG00BWX5HJ32', 'LU1640684988', 'LU0192062890', 'GG00BZ05S054',
'GG00BZ05S278', 'LU0967049924', 'HK0000349685', 'CH0312215970', 'CH0312224220', 'IE00BDRKSY33', 'LU0968832427', 'LU0959211243', 'LU1313772664', 'LU1529682137', 'LU1529682137', 'LU0866412082', 'IE00BD2MJZ29', 'LU0823040539', 'LU1184246624',
'IE00BD1WYJ29', 'IE00BDRKSZ40', 'IE00BSL73P15', 'LU1110857148', 'LU1598861828', 'LU1548481008', 'LU1674673857', 'LU1598862800', 'LU0862299434', 'BE6295944621', 'SG9999017438', 'SG9999017180', 'IE00BDRK8L01', 'LU1681058860', 'LU1228249931',
'VGG813601777', 'HK0000252152', 'LU1610780006', 'LU1610781319', 'LU1610780774', 'LU1610779925', 'LU1610780691', 'LU1610779842', 'LU1610779768', 'LU1610780345', 'LU1655083191', 'HK0000340569', 'LU1698195135', 'LU2179889261', 'GG00BF8KXT60',
'GG00BF8KXV82', 'IE00BZ1LHJ42', 'LU1668028498', 'IE00B3RW8050', 'NL0012375133', 'LU0802715499', 'LU1088021974', 'LU1678978351', 'IE00BYZ0RX47', 'GB00BFN4HB38', 'GB00BFN4HF75', 'LU1670745709', 'LU0447825026', 'LU0905077961', 'LU1726319913',
'TW000T1611F9', 'TW000T1650D2', 'TW000T1655L4', 'TW000T1657F2', 'TW000T1658L8', 'LU1494416032', 'LU1706618342', 'CH0383292130', 'FR0013296555', 'LI0379624690', 'LI0379624708', 'LI0381461156', 'LI0381461131', 'IE00BF3BN619', 'LU1719066794',
'LU2266983456', 'LU1170322058', 'IE00BYZ5F809', 'IE00BYVZ8M97', 'LU1477740523', 'LU1477740523', 'LU1477740523', 'LU1477740523', 'LU0248050006', 'LU0192063518', 'LU1731859010', 'LU1542191611', 'LU1011483788', 'LU1720112686', 'IE00BVTW2J61',
'LU1732172694', 'LU0950588763', 'LU1769347359', 'IE00BDFKLT15', 'MT7000017406', 'MT7000017422', 'IE00BDGNWB83', 'IE00BF2K4F56', 'LU1727361146', 'LU1490631451', 'LU0943509488', 'LU1434519507', 'LU1727355254', 'LU1727356906', 'LU1770381645',
'IE00BJ38LS82', 'FR0013279981', 'LU1776281823', 'IE00BG0VV904', 'IE00BYXDKD65', 'IE00BSL72C11', 'LU1792391242', 'TW000T0332G1', 'LI0379624658', 'IE00BYNTPS42', 'LU1760135241', 'BMG098571067', 'IE00BG376F62', 'IE00BDZ7RF04', 'IE00BDZ7RD89',
'LU1435387615', 'KYG5S14H1082', 'KYG5S14H1249', 'KYG5S14H1165', 'LU1770381645', 'LU1170328253', 'LU1769347359', 'LU1698195135', 'IE00BYZ7YK12', 'IE00BG0J9X47', 'LU1781277212', 'LU1781277485', 'LU1781277568', 'LU1781277725', 'LU1781276321',
'HK0000421336', 'IE00BD85PC43', 'LU1823377145', 'IE00BFZPF983', 'IE00BFZPKW69', 'LU1822309792', 'FR0013321916', 'LU1814672314', 'LU1814673718', 'IE00BG88PZ73', 'LI0383104671', 'LI0383104689', 'LI0383104648', 'LI0383104655', 'LI0383104663',
'KYG5S0CR1332', 'IE00BD0G6226', 'MT7000019998', 'MT7000020012', 'MT7000019980', 'MT7000020038', 'MT7000020004', 'MT7000020053', 'MT7000020020', 'MT7000020046', 'MT7000020061', 'MT7000020111', 'MT7000020137', 'MT7000020103', 'MT7000020152',
'MT7000020129', 'MT7000020178', 'MT7000020145', 'MT7000020186', 'MT7000020236', 'MT7000020202', 'MT7000020228', 'MT7000020277', 'MT7000020244', 'MT7000020285', 'MT7000020301', 'MT7000020400', 'MT7000020392', 'MT7000020426', 'MT7000020442',
'MT7000020418', 'MT7000020459', 'LI0387334563', 'IE00BDFKF445', 'LU1808466624', 'IE00BFZ4RY41', 'KYG0417B1059', 'KYG036091497', 'LU1805197420', 'LU1805197933', 'LU1805197347', 'LU1805197859', 'LU1805197263', 'LU1805197776', 'LU1805197180',
'LU1713568738', 'LU1840473067', 'LU1379517466', 'IE00BG08NL78', 'LU1550118076', 'LU1858043307', 'IE00BD5FJB49', 'LU1848876493', 'LU1760779055', 'IE00BF5GL377', 'FR0013340742', 'LU1861460423', 'IE00BSBN6501', 'KYG9315A2268', 'LU1861467519',
'LU1799624033', 'IE00BDDNB815', 'IE00BDDNB922', 'IE00BF3SZB17', 'IE00BF4YR230', 'LU1868838456', 'IE00BGLRP408', 'IE00BG1TSM53', 'KYG674961696', 'LU1864483083', 'LU1861453642', 'IE00BZ1NMJ50', 'IE00BGV7N136', 'HK0000439650', 'IE00BD9Y4W38',
'LU0940718447', 'LU1315126430', 'LU1833618991', 'LU1861461405', 'FR0013103629', 'IE00BYPZNP39', 'IE00B94Z5758', 'IE00BD2ZL442', 'IE00BD5DFN25', 'LU1898904948', 'IE00BKZGL980', 'LU0640922356', 'FI4000359765', 'LU1598861232', 'LU1014865916',
'KYG695791031', 'KYG695791114', 'IE00BGPBQG78', 'KYG208666316', 'KYG208664667', 'LU1947926462', 'LU1947926629', 'KYG1988M6375', 'IE00BD2ZLC27', 'LU1952109004', 'IE00BHZSHF11', 'LU1815439226', 'LU1864131138', 'IE00BGK8XY07', 'LU0706924577',
'LU0706925111', 'IE00BJQRF808', 'TW000T0458J8', 'LU1987065239', 'FI4000375415', 'FI4000375407', 'CH0342846067', 'LU1914340218', 'IE00BJRDX387', 'FR0013320314', 'IE00BFXC1N71', 'IE00BMNQKG48', 'LU1926209294', 'IE00BFM0MZ13', 'LI0379624682',
'LU1728557098', 'LU1728557171', 'LU1728557254', 'LU1728557338', 'LU1728557411', 'LU1728557502', 'LU1916439505', 'IE00BD0Q8P95', 'IE00B45YFT81', 'MU0604S00001', 'IE00BFMNVB92', 'LU2027368823', 'FR0013411931', 'KYG741671914', 'IE00BK5STR80',
'LU1906319667', 'LU1906319741', 'LU1906320160', 'LU1906320244', 'IE00BYP0WJ56', 'IE00BKBDRK61', 'LU1998914482', 'KYG757711083', 'LU1984154994', 'LU1918787448', 'LU1998914722', 'FI4000281910', 'FI4000281928', 'FI4000281936', 'FI4000281944',
'FI4000281951', 'LU1455021367', 'LU1455021367', 'LU1455021367', 'LU1455021367', 'KYG7728A1141', 'IE00BJ9NCF11', 'LU2065168283', 'LU2065168366', 'LU2065168796', 'IE00BK1MJ967', 'KYG6401N2454', 'MU0645S00004', 'IE00BHZ65P12', 'IE00BSL73R39',
'LU2062502088', 'LU2063280296', 'LU2064758035', 'LU2064759512', 'LU2064760288', 'LU2064758621', 'IE00BYXBJ775', 'JE00BJJ00149', 'JE00BJJ00255', 'JE00BJJ00362', 'JE00BJJ00479', 'JE00BZ7MCH40', 'IE00BJYJFD31', 'LU1906319824', 'LU1906320327',
'LU1906320590', 'LU1906319584', 'LU1984154648', 'LU1984155538', 'LU1984154721', 'LU1984155702', 'LU1984155967', 'LU1984156775', 'LU1984155298', 'FR0013404407', 'LU1926930394', 'LU1926931103', 'LU1926932507', 'LU1926932176', 'LU1926931954',
'LU1926930048', 'IE00BHR40S19', 'LU1916439414', 'LU0956004096', 'TW000T2132E8', 'LU1989417354', 'LU1989417867', 'LU1989417602', 'LU1989417784', 'SE0012038982', 'TW000T2113N9', 'LU1299839149', 'LU2098356731', 'LU1951228656', 'IE00BJQWRY50',
'LU1984155454', 'LU1984156189', 'IE00BK95C219', 'IE00BKF2HR61', 'LU2112679688', 'LU2146181925', 'LU2146182576', 'LU2146182733', 'LU2146182816', 'LU1939212996', 'LU1939213028', 'LU1939213374', 'MT7000026324', 'MT7000026373', 'MT7000026274',
'MT7000026332', 'MT7000026381', 'MT7000026282', 'MT7000026340', 'MT7000026399', 'MT7000026290', 'MT7000026357', 'MT7000026407', 'MT7000026308', 'MT7000026365', 'MT7000026415', 'MT7000026316', 'SE0012827608', 'IE00BK1KGW23', 'IE00BK1KW469',
'LU2013632760', 'LU2013624072', 'LU2013630715', 'LU2013631523', 'LU2064758209', 'LU2064758381', 'LU2064759868', 'LU2064758977', 'LU0943506203', 'KYG6965A1004', 'KYG0557M1015', 'LU2095302365', 'KYG198751300', 'LU2183146518', 'LU2066067625',
'LU2066067971', 'LU2201952368', 'LU2187712620', 'LU2064758464', 'LU2064758548', 'LU2064759785', 'KYG5558L1196', 'IE00BMVBBC40', 'LU2095318874', 'KYG6129S1619', 'NL0012314603', 'LU1548481263', 'LU1437023085', 'KYG331191018', 'LU1989874760',
'LU2197327757', 'LU2197327831', 'LU2064759942', 'LU2081271285', 'LU2081270121', 'SG9999012223', 'IDN000058500', 'IDN000038007', 'IDN000175007', 'IDN000076700', 'IDN000033800', 'IDN000164001', 'IDN000219300', 'IDN000114402', 'IDN000159902',
'IDN000168903', 'IDN000191004', 'IDN000113107', 'IDN000011301', 'IDN000109501', 'IDN000118809', 'CH0100524492', 'IDN000060902', 'LU1873132440', 'LU1873132952', 'IDN000168507', 'IE00B29R7937', 'NO0010827272', 'IE00BYYJHS74', 'IE00B3L10463',
'IE00B2B3JC63', 'IE00B44QSK78', 'NO0010827702', 'HK2833027330', 'RU000A0EQ3P7', 'HK2828013055', 'NO0010325962', 'ZAE000161402', 'ZAE000161774', 'ZAE000161436', 'LU0904783114', 'LU0904783460', 'LU0875332479', 'RU000A0EQ3Q5', 'NO0010827355',
'HK0000110269', 'LU0933168600', 'LU1873128505', 'RU000A0EQ3R3', 'LU1747646468', 'LU1747646625', 'RU000A0JP4R7', 'RU000A0EQ3T9', 'IE0031442068', 'ZAE000048963', 'HK0000112307', 'IDN000163805', 'ZAE000185252', 'ZAE000019550', 'RU000A0JV8N9',
'ZAE000164836', 'ZAE000164927', 'LU0875337197', 'IE00BD9FK177', 'IS0000021483', 'NO0010827561', 'ZAE000041935', 'ZAE000169025', 'NO0010827678', 'NO0010827132', 'ZAE000181566', 'ZAE000182697', 'ZAE000181871', 'ZAE000182705', 'ZAE000181889',
'ZAE000185237', 'DK0060495596', 'IS0000019644', 'NO0010759905', 'NO0010827074', 'IE00BWDPML08', 'ZAE000159257', 'ZAE000159299', 'NO0010827041', 'NO0010827058', 'ZAE000173217', 'HK0000434412', 'HK0000465929', 'GB00BYQQ0M11', 'ZAE000181517',
'ZAE000182655', 'ZAE000181848', 'ZAE000182663', 'ZAE000181855', 'CH0046159304', 'ZAE000144283', 'ZAE000144275', 'ZAE000144291', 'CH0147419797', 'CH0147419755', 'NO0010827066', 'IE00B8N9LN96', 'IE00B8C1FB24', 'NL0010937074', 'FR0012892412',
'ZAE000041927', 'ZAE000169702', 'ZAE000209946', 'NO0010867146', 'LU0957145286', 'LU0957145369', 'FR0010599431', 'ZAE000229118', 'IE00BK8M9229', 'ZAE000173191', 'ZAE000164844', 'ZAE000164935', 'GB00BK6Z1728', 'ZAE000229126', 'CH0121275595',
'CH0121800822', 'CH0121800749', 'CH0121800764', 'ZAE000173167', 'RU000A0JP7D0', 'ZAE000177598', 'ZAE000179107', 'GB0005201990', 'LU0568621295', 'GB00BD02KV73', 'RU000A0JP4S5', 'ZAE000174785', 'IE00BH3Z8X96', 'CH0189955260', 'CH0260709552',
'CH0336206583', 'NOSB1LBMFOR6', 'NO0010759897', 'CH0214975333', 'CH0214974369', 'ZAE000112165', 'HK0000310042', 'KYG012291061', 'NO0010801954', 'LU0625107734', 'NO0010820533', 'GB00BVYV8L22', 'ZAE000160677', 'ZAE000160685', 'ZAE000160669',
'ZAE000173183', 'IE00B945VV12', 'ZAE000160347', 'ZAE000157798', 'NO0010827082', 'CH0018234564', 'CH0113816166', 'NO0010759863', 'GB00B89PBT70', 'HK0000366184', 'ZAE000266276', 'LU0260641393', 'LU1823219404', 'CH0122756478', 'SE0009664097',
'DK0000580010', 'ZAE000199816', 'NO0010815863', 'CH0125176823', 'TW000T1001Y4', 'ZAE000214698', 'NO0010815889', 'RU000A0JP4P1', 'ZAE000175691', 'ZAE000162137', 'NO0010040231', 'ZAE000179354', 'ZAE000148102', 'HK0000071313', 'NL0012294144',
'ZAE000171112', 'IE00B9NRGV32', 'FI4000060934', 'FI4000387485', 'IE00B19FZP29', 'IE00B9JNN477', 'ZAE000041943', 'ZAE000168993', 'ZAE000173175', 'CH0100078150', 'ES0146944014', 'LU0328475792', 'LU0328475792', 'CH0013457756', 'LU0451522782',
'IE00B1N7Z987', 'LU0562500180', 'LU1368697873', 'NO0010820517', 'CH0220736992', 'CH0220736950', 'KYG9179K1040', 'ZAE000275459', 'ZAE000236386', 'GB00BYP82F50', 'NO0010827926', 'CH0323362506', 'CH0323362522', 'IE00BWWCRF11', 'ZAE000251237',
'CH0192556410', 'NO0010759871', 'ZAE000199808', 'CH0232006095', 'CH0232006079', 'ZAE000148334', 'ZAE000148342', 'ZAE000148359', 'LU0568620214', 'GB00BF09MK59', 'LU1313770965', 'ZAE000146668', 'CH0024447093', 'GB00B543P606', 'FR0010619874',
'CH0024559798', 'LU1313771930', 'LU1720118964', 'GB00B3P4L931', 'ZAE000249470', 'ZAE000175113', 'RU000A0JRLD0', 'CH0111012941', 'CH0345080557', 'CH0345080565', 'ZAE000200580', 'LU0935222223', 'RU000A0JPFK1', 'LU0929189719', 'ZAE000163101',
'ZAE000183992', 'CH0126480497', 'QS0004078919', 'LU0145475843', 'CH0183503272', 'ZAE000214938', 'IE00B9F5YL18', 'LI0466188294', 'ZAE000181442', 'ZAE000182713', 'ZAE000181905', 'ZAE000182721', 'ZAE000181913', 'LU1610781822', 'LU1610781665',
'LU1269737729', 'GB00BYWVFD66', 'LU1118013983', 'IE0034205538', 'IE00BF4N1C86', 'ZAE000193769', 'LU0075938133', 'CH0013793069', 'CH0236278971', 'CH0236279052', 'FR0010793026', 'GB00BD3FVP49', 'GB00BD3FVN25', 'ZAE000209839', 'LU1130035378',
'IE00B15WPP73', 'LU0441707956', 'ZAE000248357', 'TW000T1619B1', 'HK0000248226', 'ZAE000144317', 'ES0112899028', 'ZAE000078622', 'LU0482269734', 'CH0507420146', 'ZAE000019568', 'ZAE000171955', 'CH0206574912', 'IE00B64QMW18', 'IE00B15WPZ71',
'ZAE000164620', 'ZAE000164687', 'ZAE000164851', 'LU0533341052', 'LU1747644257', 'TRYFNBK00055', 'KYG2030A1186', 'GB00BD5CY988', 'GB00BD5CYB01', 'ZAE000222295', 'GB00BK6Z1835', 'CH0028692975', 'TRYFNBK00030', 'BE0948651881', 'LU1687452497',
'IE00B97JJ279', 'GB00B1YXF283', 'ZAE000019501', 'ZAE000218541', 'CH0263627355', 'LU0072816092', 'CH0048666470', 'IT0005244949', 'GB00B0517P11', 'CH0112589673', 'GB00BYWVFB43', 'CH0295060443', 'NOSB1LBMBAN3', 'ZAE000188611', 'LU1024301316',
'ZAE000249496', 'ZAE000173209', 'LU0841675506', 'FI4000369970', 'FI4000170881', 'ZAE000193306', 'GB00B014GV33', 'GB00B1YXH990', 'LU1234788351', 'ZAE000184016', 'HK0000366176', 'FR0010094805', 'LU0086914446', 'ZAE000278883', 'CH0302290124',
'KYG6360A1013', 'KYG6360A1195', 'LU0982876392', 'IE00BF0VTX33', 'GB0033747139', 'CH0295060492', 'CH0199465599', 'CH0020299555', 'CH0011378228', 'BE6254409434', 'ZAE000281879', 'ZAE000199675', 'HK0000434438', 'NOSB1LIVIND8', 'ZAE000179032',
'LU0966249483', 'LU0966249996', 'LU0966250655', 'LU1518617417', 'KYG4923A1067', 'LU1598854963', 'ZAE000190534', 'LU0552643768', 'LU0210076179', 'FI4000306774', 'ZAE000040010', 'GB00B996Y871', 'FR0011524396', 'ZAE000230272', 'LU0322728279',
'LU0209999340', 'ZAE000180337', 'GG00BJ2KJG95', 'LU0415159374', 'LU0401472252', 'LU0495653056', 'LU1354236611', 'BE6254416504', 'IE00BF21WW11', 'ZAE000268645', 'ZAE000191938', 'ZAE000202578', 'FR0013196102', 'CH0236278930', 'CH0236278997',
'CH0016036334', 'CH0101574215', 'CH0146904674', 'CH0022325291', 'CH0022325283', 'LU1105481763', 'LU0190307842', 'LU0190304583', 'BE6253170656', 'CH0013803587', 'SE0008373955', 'LU0174547579', 'LU0336681399', 'LU0177333548', 'GB00B1YXR445',
'GB00B01RWR05', 'CH0037482590', 'FI4000281738', 'FI4000387469', 'CH0273939675', 'IE00B4XCK338', 'BMG294041030', 'CH0049550269', 'GB00B4RKR986', 'HK0000326139', 'LU0260154132', 'ZAE000199527', 'ZAE000246179', 'LU0533720586', 'LU1349991015',
'LU1165136505', 'LU1165136687', 'ZAE000039996', 'GB00B543TC49', 'FR0012355063', 'FI4000108436', 'FI4000387477', 'CH0247745679', 'FR0011445451', 'ZAE000175741', 'ZAE000175758', 'HK0000308285', 'KYG4923A1711', 'ZAE000198081', 'ZAE000023875',
'CH0280933240', 'CH0224042157', 'HK0000313426', 'HK0000313426', 'LU0841676140', 'CH0216442548', 'LU1660423721', 'IM00BDX8PF11', 'ZAE000222170', 'LU0956005069', 'ZAE000215141', 'BE6254410440', 'ZAE000181475', 'ZAE000182671', 'ZAE000181921',
'ZAE000182689', 'ZAE000181939', 'LU1373298592', 'ZAE000152237', 'KYG7387K1224', 'LU0622329273', 'LU0396185323', 'FR0011049535', 'RU000A0JV9B2', 'IE00BF2ZW678', 'GG00BJ2KJB41', 'JE00BNLP6T53', 'LU2127876121', 'CH0132556157', 'CH0192258686',
'CH0245229122', 'LU0545089996', 'LU1164221589', 'LU1275396932', 'ES0173545007', 'ES0173545015', 'ES0173545023', 'ES0173545031', 'ES0173545049', 'IE00BD82R525', 'ES0114578000', 'IM00B7NLSJ35', 'ZAE000187282', 'IT0005189128', 'CH0282527719',
'BE6296690322', 'SE0009983729', 'SE0009983737', 'SE0009983745', 'ZAE000073979', 'CH0254655365', 'IM00B7N9ST79', 'ZAE000268181', 'LU0558113188', 'LU0900384271', 'FI4000087747', 'FI4000087762', 'FI4000281779', 'GB00BYW8RX12', 'FR0010227272',
'TW000T0117A9', 'FR0012347573', 'ZAE000159919', 'FR0013287224', 'ZAE000184057', 'BE6272004811', 'CH0364282456', 'CH0183503199', 'LU0252500284', 'RU000A0HGNG6', 'GB0001510162', 'IE00BFZNGJ98', 'IE00BHPRGG22', 'GB00B7Z59Y51', 'GB00B29KPZ41',
'DE000A0HF491', 'CH0196438680', 'LU0957231367', 'KYG366091083', 'DE0006289481', 'FR0010218289', 'LU0815620082', 'FI4000197801', 'FI4000189261', 'FI4000197793', 'FI4000242979', 'FR0013333127', 'CH0211608895', 'ZAE000019543', 'LU1554272721',
'CH0103625189', 'SE0011750934', 'KYG6361T1094', 'KYG6361T1177', 'ZAE000277893', 'LU0950588920', 'LU1158938669', 'GB00BRJL8085', 'KYG668811063', 'ZAE000247060', 'AT0000A0Z7W4', 'BE6299368918', 'SE0011750959', 'ZAE000254264', 'KYG4923A1224',
'IE00B19FRL23', 'IE00B19FRM30', 'CH0218426606', 'CH0265794625', 'FR0011843952', 'CH0034140100', 'IE00B48X4842', 'AT0000613245', 'ZAE000281556', 'NOSB1LBMMOD2', 'CH0049041186', 'LU0411075020', 'LU0617430904', 'LU0408848405', 'GB00BYW8VL77',
'FR0013200250', 'ZAE000205902', 'FI4000353669', 'FI4000353677', 'FI4000353693', 'JE00B3NG4M51', 'IE00B0NLN662', 'NOSB1LBM1003', 'ZAE000245130', 'LU0794123256', 'SG9999003271', 'GG00B4NBBH11', 'GG00B86SHB38', 'LU1458497218', 'LU1073898733',
'LU0411078552', 'CH0192252309', 'IE00BD0YSC07', 'NL0013995194', 'CH0022985417', 'CH0022985524', 'LU1365257945', 'GB00B1YX6R07', 'LU0107087537', 'LU0401471288', 'HK0000291846', 'IM00BFXBM318', 'IM00BFXBM425', 'IM00BZ1F5F87', 'ZAE000270187',
'LU0145469440', 'FR0013302130', 'CH0029030977', 'LU0904227583', 'ZAE000141677', 'VGG7216G1101', 'GB00BGP6KX79', 'BE6274675360', 'KYG366061128', 'SG9999004766', 'ES0114105002', 'CH0212266750', 'JE00B3QQ3363', 'CH0281609138', 'GB00BM955T30',
'BE6309895454', 'LU0927887504', 'SE0008130033', 'FR0010218271', 'GB00BYW8VG25', 'VGG7216G1283', 'CH0047348633', 'CH0047348674', 'CH0336870537', 'LU1073900653', 'GB00BYRY2Y09', 'FR0010955476', 'LU0229501001', 'LU0804782836', 'LU0847898367',
'HK0000310026', 'LI0385769786', 'LU0284395802', 'LU1777949535', 'KYG291636424', 'CH0117618733', 'FR0010585307', 'AT0000A0DYL1', 'CH0020168677', 'DE000A2P3TP7', 'FR0010922708', 'ES0157638026', 'LU0670223352', 'CH0280933539', 'CH0280933547',
'KYG5905A1132', 'LU0494603177', 'HK0000297793', 'HK0000297793', 'LU0088160774', 'LU0421770834', 'IE00BYXZVG22', 'IE00BYQF5G74', 'GG00B8H64822', 'ZAE000194890', 'ZAE000213203', 'CH0364735180', 'CH0364735198', 'LU0364481308', 'ZAE000209128',
'ZAE000201901', 'ZAE000204756', 'KYG6689A1058', 'LU1215714046', 'LU0636630260', 'LU0411078636', 'LU0572687357', 'ZAE000168688', 'CH0206638907', 'KYG4923A1976', 'LU1022404138', 'LU1073917038', 'GB00BYW8VJ55', 'IE00BYVFRP56', 'IE00BYVFRN33',
'KYG525991009', 'KYG525991181', 'ZAE000181236', 'LU1104112195', 'LU0982366352', 'IE00BYX07P89', 'GB00BF783Q84', 'VGG7216G1028', 'LU1002647060', 'HK0000140985', 'RU000A0HGNH4', 'CH0110740807', 'KYG936151136', 'GB00B06FVL68', 'GB00BM955V51',
'CH0047783045', 'LI0411653251', 'BMG6113X1433', 'LU1867050251', 'LU1867050418', 'LU0201602843', 'LU0975257816', 'IE00BM67HQ30', 'LU0360647498', 'LU0360647068', 'CH0030395138', 'ES0174434052', 'LU0555025369', 'ZAE000249009', 'CH0226422373',
'LU0458258927', 'KYG0697H1039', 'LU0149719808', 'LU0164906959', 'LU0164907411', 'LU0164895061', 'LU0164895061', 'LU0164895061', 'LU0164895061', 'LU1422956877', 'HK0000286804', 'JE00BD5DYP77', 'BE6276498167', 'CH0408680277', 'LU1626536954',
'LU0471216126', 'ZAE000077376', 'GB00BYRY2V77', 'LU0471215409', 'LU1787041331', 'ZAE000158010', 'ZAE000158028', 'ZAE000158036', 'ZAE000158044', 'HK0000226149', 'CH0025256964', 'LU0659579063', 'GG00BYNN4J07', 'BMG0177T1229', 'GB00B1YXFF14',
'DE000A1T6FY8', 'CH0117611654', 'CH0192251954', 'CH0022497405', 'MU0514S00002', 'GB00B5NL0W62', 'IE00BP4KB181', 'LU2020610874', 'LU2020610957', 'CH0181509750', 'FR0010914564', 'LU0159348910', 'LU0488314948', 'GG00B8F1G495', 'FR0013213154',
'LU1506573697', 'MT7000006052', 'LU0755279428', 'IE00BGGM3805', 'KYG5726W1208', 'GG00BJ2KJ814', 'LU0195489025', 'CH0117643764', 'PTYBCHHM0015', 'CH0193767131', 'LU1475740376', 'GB00BYW8RV97', 'CH0047783029', 'DE000A2PS3F7', 'LI0520963666',
'LI0336886119', 'CH0029031017', 'IE00BTGCY991', 'LU0592215668', 'SG9999000202', 'GB0004674346', 'KYG9363R1056', 'IE00B1Y4KV52', 'GB00BYYB5341', 'GB00BYYB5457', 'FR0013197993', 'ES0138873007', 'GB00BM955S23', 'GG00BYNN4H82', 'HK0000414364',
'GB00B0ZRK169', 'GB00BYWWS071', 'LU0533726278', 'LU1820987524', 'HK0000297678', 'HK0000297678', 'CH0200879374', 'CH0200881537', 'LU1668028142', 'GB00BHNC2499', 'GB00BF783P77', 'GB00B00CNV73', 'BE6228534671', 'FR0013055274', 'CH0200685185',
'CH0200685110', 'CH0274580635', 'LU0396824517', 'LU1726120089', 'HK0000081379', 'QS0009115625', 'LU1022392457', 'LU0401475354', 'IE0094H50500', 'GB00BM955W68', 'LU1253388521', 'LU0379090680', 'LI0520964037', 'LI0520964045', 'LI0520964052',
'ES0138661014', 'LU0295320872', 'GG00BYNN4Q73', 'IE00BK35B423', 'LU1956011941', 'LI0433340762', 'NL0009692839', 'GB00BD46DY93', 'LU1373121158', 'LU1361561282', 'GG00BYNN1443', 'CH0420179803', 'LI0037042756', 'CH0013139156', 'SG9999012116',
'LU0213942112', 'ES0171956016', 'LU0401470983', 'NOSB1LBMOFF1', 'LU1730853626', 'FR0012858819', 'MU0217S00127', 'LU2194286006', 'GB00BF782R27', 'FR0011507201', 'LU0794123686', 'LU2168062128', 'LU2168063365', 'HK0000273489', 'LU1238250044',
'CH0192252143', 'LI0028406937', 'QS0009110154', 'LU0871827035', 'FR0011735091', 'LU1532289060', 'LU1280556652', 'TRMCU1WWWWW3', 'NL0010730792', 'GG00BYNNNF94', 'KYG7777A1067', 'GB00BQQFHY34', 'GG00BYNN4P66', 'FR0012538080', 'FR0012658367',
'CH0214415116', 'FR0011742444', 'CH0018801750', 'CH0103625205', 'GG00B7Y2FV66', 'GB00B9MCNZ29', 'IE00BS7K1Q19', 'FR0012553824', 'LU0084323251', 'GG00BYNNFH50', 'NO0010764434', 'GB0007526683', 'GB0007526683', 'GB0007526683', 'GB0007526683',
'LI0134162861', 'HK0000306594', 'FR0011202035', 'FR0010417857', 'FR0010076513', 'CH0186131998', 'GG00BYNN6723', 'JE00B6VVNK49', 'LU1989488371', 'GG00BYNN1P51', 'LU1676054510', 'GG00BYNNGG19', 'CH0117646320', 'DE000A0MS7S6', 'FR0011779099',
'LU0876827493', 'GB00BP4VN600', 'GB00BP4VN717', 'GB00BF783R91', 'LI0037049157', 'FR0011427822', 'GG00BYNN5865', 'JE00B3KTX754', 'LI0037049140', 'GG00BYNNB991', 'LU1396613975', 'KYG4923A2131', 'LU0309812278', 'LU0494602872', 'IT0005154148',
'LU0339555707', 'LU1016034404', 'GG00BYNN4D45', 'FR0013216967', 'IE00BYP0YH72', 'GB00B125N441', 'GB00BGMJSC47', 'GG00BYNNBD33', 'MU0180S00010', 'SG1DB9000009', 'LU2040190709', 'LU2040190881', 'GB00B7KXWG79', 'GB00BG6LVJ49', 'GG00BYNNMV87',
'CH0047783086', 'LU1167329637', 'FR0011678002', 'LU1792375716', 'FR0012044881', 'GB0007494338', 'LU0985830768', 'LI0028406796', 'GG00BYNN3F93', 'FR0011391705', 'FR0011429000', 'FR0013267200', 'DE000A2P3XG8', 'DE000A2P3XH6', 'LI0400253857',
'IE00B4QNK008', 'IE00B4QNK008', 'LI0247754356', 'LI0247754372', 'GG00BYNNNT31', 'QS0009111343', 'BW0000001411', 'KYG4923A2214', 'KYG4923A2396', 'GG00BYNN1W29', 'LI0027247423', 'GG00BYNNF976', 'BE0058441479', 'CH0189697292', 'GB00BG6LVN84',
'GB00BG6LVP09', 'GB00BG6LVQ16', 'CH0339046507', 'GB00BFYMDH41', 'GB00BFYMDK79', 'KYG822011071', 'GG00BYNN5C03', 'LU0730413258', 'CH0018303922', 'GG00BMNR0K12', 'MU0502S00007', 'GB00B5SY5259', 'GB00BY7R2R27', 'LU0379563504', 'GI000A1W4ZE1',
'GG00BYNNMY19', 'FR0011538768', 'JE00BRKDBD77', 'LI0185120313', 'KYG932271821', 'GG00BYNN4L29', 'FR0011022755', 'PTMNSKHE0012', 'FR0013111820', 'KYG932271664', 'CH0103625197', 'LU1792376284', 'LU1792376441', 'GG00BYNNB009', 'LU1377525222',
'LU1628858711', 'GG00BYNNBH70', 'IM00B5TMRY40', 'LU0597553584', 'LI0545342813', 'FR0012098473', 'LU0813479218', 'GG00BYNNPD11', 'AT0000A0NE53', 'LU0841675928', 'GG00BYNNN152', 'GB00BCZYGC41', 'KYG0826A1287', 'FR0013150190', 'LU0381762854',
'GG00BYNN2V86', 'ES0177803006', 'IE00BH05CB83', 'GG00BYYFGV17', 'FR0011716299', 'FR0011804939', 'QS0009111335', 'QS0009095520', 'QS0009111400', 'GB00BM955C62', 'BMG4711E1536', 'GG00BYNNJ069', 'LU0727217480', 'FR0011532019', 'GG00BYNN5535',
'GG00BYNNMN04', 'LU1297949338', 'LI0033472098', 'FR0011014901', 'ES0182748006', 'GG00BYNN5R55', 'GG00BYNN2524', 'FR0013215696', 'QS0009111418', 'GG00BYNNN483', 'GG00BYNNB660', 'FR0012843753', 'LU1592885955', 'IDN000061207', 'IE00BPYD7V96',
'GG00BYNNLH78', 'LU0267559309', 'HK0000297827', 'HK0000297827', 'KYG350811199', 'KYG350811017', 'NL0010700779', 'ES0158289001', 'GG00BYNNB330', 'FR0011160266', 'FI4000123120', 'FI4000123138', 'FI4000123146', 'FI4000123153', 'HK0000302007',
'GG00BYNN7S03', 'FR0010593590', 'ES0110484005', 'GG00BYNN2F29', 'GB00BV8VPJ59', 'LU1288048389', 'GG00BYNNNJ33', 'HK0000305430', 'FR0011426402', 'JE00BQZJ4Y54', 'JE00B9J98Y01', 'JE00BQZJ4Z61', 'JE00B9JHVH86', 'FR0011292366', 'FR0011538347',
'GG00BYNN6160', 'FR0011753839', 'FR0011753805', 'GG00BYNNL339', 'FR0011285931', 'FR0011290006', 'IE00B4XZMZ82', 'LU0971117311', 'MU0281S00024', 'GG00BYNN5G41', 'LU0592288541', 'GB0008298308', 'GB0008298308', 'GB0008298308', 'GB0008298308',
'FR0010926469', 'IE00B4V27563', 'MU0247S00009', 'MU0247S00017', 'LI0355534335', 'KYG4923A1489', 'KYG4923A1554', 'FR0012034759', 'GG00BYNNLL15', 'LI0285316472', 'FR0013353000', 'GG00BYNN7L34', 'GG00BYNNN715', 'FR0010930149', 'FR0011433218',
'GB00B1Z8LD51', 'GG00BYYFGT94', 'GG00BJ2KJD64', 'QS0009119593', 'FR0010917690', 'GG00BYQ72T50', 'GG00BYNN4S97', 'GG00BYNN1773', 'GB00BG12YN72', 'GG00BYNNL669', 'GG00BYNNCG39', 'KYG822011568', 'GG00BYNN5089', 'VGG472631016', 'GG00BYNN7H97',
'FR0011759547', 'GG00BYNN3R16', 'ES0121798005', 'LI0415910970', 'GG00BYNN6491', 'ES0180857031', 'FR0011525625', 'FR0013307386', 'GG00BYNN7Z79', 'GG00BYNNPH58', 'FR0013053154', 'GG00BYNN8G55', 'IM00B6Q9GJ39', 'GG00BYNNMK72', 'GB00BN7HRH06',
'GB00BN7HRJ20', 'ES0164990022', 'GG00BYNN9X95', 'FR0010697243', 'JE00BRKDBB53', 'FR0011221902', 'ZAE000281176', 'KYG822011808', 'GG00BYNN1Z59', 'GG00BYNNK927', 'GG00BYNN6J47', 'FR0011201284', 'FR0012832772', 'GG00BYNN6F09', 'GG00BYNNFS65',
'FR0011860840', 'FR0011202738', 'GG00BYNNNQ00', 'ES0155476007', 'GG00BYNNN376', 'GG00BYNN8877', 'GG00BYNN1Y43', 'GG00BYNNBW22', 'QS0009119437', 'FR0010660647', 'FR0010832451', 'MU0247S00454', 'MU0247S00462', 'GG00BYNNKR02', 'GG00BYNNNB56',
'FR0011533215', 'GG00BYNN1S82', 'GG00BYNNJH31', 'GG00BYNNC296', 'GG00BYNN7689', 'KYG1995X1051', 'LU0794123769', 'GG00BYNNL990', 'FI4000283080', 'FI4000283098', 'FI4000322177', 'GG00BYNN7358', 'GG00BYNNPL94', 'GG00BYNNKD65', 'GG00BYNN1B16',
'GG00BYNN9B75', 'KYG6882S1012', 'GG00BYNN1H77', 'GG00BYNND153', 'GG00BYNN8Y38', 'QS0009118959', 'GG00BYNNFZ33', 'GG00BYNN9F14', 'GG00BYNNHB39', 'GG00BYNNLD31', 'GG00BYNN9J51', 'GG00BYNN8R60', 'IM00BDX87335', 'IM00BDX87335', 'IM00BDX87335',
'IM00BDX87335', 'LU1859349935', 'GG00BYNNCV89', 'GG00BYQ72X96', 'GG00BYNN1L14', 'GG00BYNNKY78', 'GG00BYNN8216', 'GG00BYNNCN06', 'GG00BYNN8K91', 'GG00BYNNJ408', 'GG00BYNNBP54', 'GG00BYYFGW24', 'GG00BYNN2B80', 'GG00BYNNPP33', 'GB00BN7HRF81',
'GB00BN7HRG98', 'FR0011404938', 'FR0010867275', 'GG00BYNNJD92', 'GB00BN7HRC50', 'GB00BN7HRD67', 'GG00BYNNC858', 'SG9999014302', 'GG00BYNNCK74', 'GG00BYNN4900', 'GG00BYNNJM83', 'GG00BYNN5K86', 'GG00BYNN7P71', 'FR0011098847', 'JE00BRKDB934',
'GG00BYNNG271', 'GG00BYNN6B60', 'GG00BYNNBL17', 'KYG8789C1069', 'GG00BYNNBZ52', 'GG00BYNN8V07', 'LU0987164240', 'LU0987164596', 'GG00BYNNCC90', 'GG00BYNNPS63', 'GG00BYNNFL96', 'GG00BYNNCR44', 'GG00BYNNFD13', 'GG00BYNND484', 'FR0010504910',
'FR0011306380', 'GG00BYNN8N23', 'GG00BYNNGY90', 'GG00BYNN9M80', 'GG00BYNNKV48', 'FR0010865352', 'GG00BYNN9Q29', 'FR0010784199', 'GG00BYNN9404', 'GG00BYNNFP35', 'GG00BYNNFW02', 'GG00BYNNGC70', 'FR0010990119', 'GG00BYNNJT52', 'GG00BYNNC528',
'GG00BYNNQ833', 'GG00BYNN1N38', 'GG00BYNNHT13', 'GG00BYNNG503', 'GG00BYNNDX78', 'FR0011206986', 'FR0011231042', 'FR0011086578', 'GG00BYNNPG42', 'GG00BYNNDT33', 'GG00BYNN6T45', 'GG00BYNN6X80', 'IE00BGHFGW35', 'GG00BYNNBS85', 'GG00BYNNKK33',
'GG00BYNNDQ02', 'GG00BYNN5N18', 'GG00BYNN9735', 'GG00BYNNHQ81', 'GG00BYNNJ739', 'GG00BYNNK034', 'GG00BYNNCY11', 'GG00BYNNGN85', 'GG00BYNNHX58', 'GG00BYNNGK54', 'GG00BYNNHF76', 'FR0011205400', 'FR0011230945', 'GG00BYNNHM44', 'GG00BYNNKN63',
'GG00BYNNG834', 'GG00BYNNDM63', 'GG00BYNN9T59', 'AT0000A0JQV3', 'AT0000A1WBA0', 'ZAE000270641', 'GG00BYNNJQ22', 'GG00BYNNH469', 'LU0898826564', 'GG00BYNNQ502', 'GG00BYNNH139', 'GG00BYNNPV92', 'LU0750177155', 'LU0750179870', 'LU0750060393',
'LU0750178716', 'LU0937588209', 'LU0937588464', 'LU1347423482', 'LU1347423649', 'LU1347423722', 'LU0862604922', 'KYG1988M5047', 'KYG1988M4966'
];

export const milCurrencySpecificFunds = [
	{fund: 'MFS Meridian Global Oppc Bd A1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd I1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd N1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd C1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd W1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd A2 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd C2 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd N2 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd I2 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd W2 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd P1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd Z1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global High Yield A2 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global High Yield A1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global High Yield C1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global High Yield C2 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global High Yield I1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global High Yield N1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global High Yield N2 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global High Yield W1 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global High Yield W2 USD', baseCurrency: 'USD'},
	{fund: 'MFS Meridian Global Oppc Bd IH1 EUR', baseCurrency: 'EUR'},
	{fund: 'MFS Meridian Global Oppc Bd AH1 EUR', baseCurrency: 'EUR'},
	{fund: 'MFS Meridian Global Oppc Bd WH1 EUR', baseCurrency: 'EUR'},
	{fund: 'MFS Meridian Global Oppc Bd IH2 EUR', baseCurrency: 'EUR'},
	{fund: 'MFS Meridian Global Oppc Bd PH EUR', baseCurrency: 'EUR'},
	{fund: 'MFS Meridian Global Oppc Bd AH2 EUR', baseCurrency: 'EUR'},
	{fund: 'MFS Meridian Global High Yield AH2 EUR', baseCurrency: 'EUR'},
	{fund: 'MFS Meridian Global High Yield WH1 EUR', baseCurrency: 'EUR'},
	{fund: 'MFS Meridian Global Oppc Bd IH2 GBP', baseCurrency: 'GBP'},
	{fund: 'MFS Meridian Global Oppc Bd IH1 GBP', baseCurrency: 'GBP'},
	{fund: 'MFS Meridian Global Oppc Bd WH1 GBP', baseCurrency: 'GBP'},
	{fund: 'MFS Meridian Global High Yield WH1 GBP', baseCurrency: 'GBP'}
]
export const showSustainabilityRating = true;

export const BenchmarkMILXids = ['220205329','574776012','635667680','635670062'];
export const BenchmarkUSXids = ['220205498'];
import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';

class TimeRangeScenarioAlertModal extends React.PureComponent {
	render() {
		return (
			<Modal
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
				isOpen={this.props.timeRangeScenarioAlertModalIsOpen}
				style={this.props.customStyles}
			>
				<div className={'saveModal selectTemplateModal'}>
					<div className={'main-container'}>
						<div className={'headerContainer'}>
							<div className={'header'}>
								<div className={'header-text'}>Alert</div>
							</div>
						</div>
						<div className={'searchMainModal'}>
					        <div className={'lbl-errormessage'}>The comparison start date has been updated. Please review the scheduled events to ensure they are compatible with the new start date.</div>
						</div>
						<div className={'footerContainer'}>
							<div className={'footer'}>
								<button
									onClick={this.props.closeTimeRangeScenarioAlertModal}
									className={'save-button'}
								>
									OK
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

TimeRangeScenarioAlertModal.propTypes = {
    templateWarningModalIsOpen: PropTypes.bool,
	customStyles: PropTypes.object,
	saveTemplateSettings: PropTypes.func,
	closetemplateWarningModal: PropTypes.func,
	handleInputChange: PropTypes.func,
	isSaveDisabled: PropTypes.bool,
	status: PropTypes.string,
	handleEnterClickOnTextbox: PropTypes.func,
	isPortfolio: PropTypes.bool,
	showCancel: PropTypes.bool
};

export default TimeRangeScenarioAlertModal;

import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import { PropTypes } from 'prop-types';

import flow from 'lodash/flow';

import gripperImg from '../../../images/Icon/Grey.png';
import closeSVG from '../../../images/Icon/Close.svg';
import LockIcon from '../../common/Icons/LockIcon.js';

class card extends Component {
	render() {
		// eslint-disable-next-line no-shadow
		const { card, connectDragSource, connectDropTarget, isSection, listId, index } = this.props;
		const name = isSection ? card.sectiondisplayname : card.subsectiondisplayname;
		const className = isSection ? 'parentli' : 'childli';
		const subsectionclassName = card.canbeAddedAndLocked ? 'sub-section-name' : 'fund-name'

		return connectDragSource(connectDropTarget(
			<li className={className}>
				{card.isLocked
					? <div className={'group-lock-icon'}><LockIcon /></div> : <img alt="gripperIcon" className={'gripper-image'} src={gripperImg} />}
				<div className={subsectionclassName} title={name}>{name}</div>

				{card.canbeAddedAndLocked
					? <img alt="closeIcon" role="presentation" data-index={index} data-listId={listId} data-isSection={isSection} className={'close-image'} src={closeSVG} onKeyUp={this.props.handleDeleteFundClick} onClick={this.props.handleDeleteFundClick} /> :

					!card.isLocked
						? <img alt="closeIcon" role="presentation" data-index={index} data-listId={listId} data-isSection={isSection} className={'close-image'} src={closeSVG} onKeyUp={this.props.handleDeleteFundClick} onClick={this.props.handleDeleteFundClick} /> : ''
				}
			</li>
		));
	}
}

const cardSource = {

	beginDrag(props) {
		return {
			index: props.index,
			listId: props.listId,
			card: props.card,
			isSection: props.isSection
		};
	},

	endDrag(props, monitor) {
		const item = monitor.getItem();
		const dropResult = monitor.getDropResult();

		if (dropResult && dropResult.listId !== item.listId) {
			// eslint-disable-next-line no-empty
			if (dropResult.pushed === true) {
			}
		}
	}
};

const cardTarget = {

	hover(props, monitor, component) {
		const dragIndex = monitor.getItem().index;
		const card = monitor.getItem().card;
		const isSection = monitor.getItem().isSection;
		const hoverIndex = props.index;
		const sourceListId = monitor.getItem().listId;

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return;
		}

		// Determine rectangle on screen
		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

		// Determine mouse position
		const clientOffset = monitor.getClientOffset();

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top;

		// Only perform the move when the mouse has crossed half of the items height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			return;
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
			return;
		}

		// Time to actually perform the action
		if (props.listId === sourceListId) {
			if (card.isLocked === false) {
				props.moveCardLeftRail(dragIndex, hoverIndex, sourceListId, isSection);
				// Note: we're mutating the monitor item here!
				// Generally it's better to avoid mutations,
				// but it's good here for the sake of performance
				// to avoid expensive index searches.
				monitor.getItem().index = hoverIndex;
			}
		}
	}
};

export default flow(
	// eslint-disable-next-line new-cap
	DropTarget('CARD', cardTarget, connect => ({
		connectDropTarget: connect.dropTarget()
	})),
	// eslint-disable-next-line new-cap
	DragSource('CARD', cardSource, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	}))
)(card);

card.propTypes = {
	card: PropTypes.func,
	connectDragSource: PropTypes.func,
	connectDropTarget: PropTypes.func,
	handleDeleteFundClick: PropTypes.func,
	isSection: PropTypes.bool,
	listId: PropTypes.number,
	index: PropTypes.number
};

import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';

import * as Constants from "../../utils/constants";
import headerStyles from '../../styles/components/Header.scss';

export default class Header extends React.PureComponent {
	render() {
		return (
			<div className={'header'}>
				<div className={'headerContainer'}>
					<div className={'headerModule'}>
						<Link className={'logo-img'}
							onClick={this.props.openAlertModal}
						></Link>
						<div className={'side-border'}></div>
						<div className={'fund-compare-header'}>
							{/* Dashboard */}
							<Link to="/dashboard"  onClick={this.props.onClickNonInternational} className={'header-link'}><div className={'header-button ' + (this.props.activeTab===0 ? 'active' : '')}>Dashboard</div></Link>
							
							{/* Fund Compare Tool */}
          		{/* Only admin and USA user can see below link */}
							{ !this.props.mfsUSFvFinactive && (this.props.mfsUserData.userTier == 4 || this.props.mfsUserData.userTier == 6) ? <Link to="/home"  onClick={this.props.onClickNonInternational} className={'header-link'}><div className={'header-button ' + (this.props.activeTab===1 ? 'active' : '')}>Fund Compare</div></Link>: null}
							
							{/* Portfolio Compare Tool */}
            	{/* Only admin and USA user can see below link */}
							{ !this.props.mfsUSPvPinactive && (this.props.mfsUserData.userTier == 4 || this.props.mfsUserData.userTier == 6) ? <Link  onClick={()=>{this.props.onClickNonInternational();this.props.resetComparePortfolios();}} to="/portfolio" className={'header-link'}><div className={'header-button ' + (this.props.activeTab===2 ? 'active' : '')}>Portfolio Compare</div></Link> : null}
							
							{/* Int. Fund Compare */}
            	{/* Only admin and inernaitonal user can see below link */}
							{ !this.props.mfsMILFvFinactive && (this.props.mfsUserData.userTier == 5 || this.props.mfsUserData.userTier == 6) && <Link to="/FundInternational"  onClick={this.props.onClickInternational} className={'header-link'}><div className={'header-button ' + (this.props.activeTab===3 ? 'active' : '')}>Int. Fund Compare</div></Link>}
							
							{/* Int. Portfolio Compare */}
            	{/* Only admin and inernaitonal user can see below link */}
							{ !this.props.mfsMILPvPinactive && (this.props.mfsUserData.userTier == 5 || this.props.mfsUserData.userTier == 6) ? <Link  onClick={()=>{this.props.onClickInternational();this.props.resetComparePortfolios();}} to="/PortfolioInternational" className={'header-link'}><div className={'header-button ' + (this.props.activeTab===4 ? 'active' : '')}>Int. Portfolio Compare</div></Link> : null}
						</div>
						<div className={'side-border'}></div>
						<div className={'logout-img'} onClick={this.props.logOut}></div>
					</div>
				</div>
			</div>
		);
	}
}

Header.PropTypes = {
	logOut: PropTypes.func,
	openAlertModal: PropTypes.func,
	activeTab: PropTypes.integer,
	resetComparePortfolios: PropTypes.func
};

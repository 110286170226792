import React from 'react';
import { PropTypes } from 'prop-types';

import SelectFundsModal from '../../selectFundComparison/Container';
import SelectPortfolioFundsModal from "../../portfolio/selectPortfolioFund/Container";
import * as Constant from '../../../utils/constants';
import {getRandomNumber} from "../../../utils/commonMethods";

import '../../../styles/components/EditComparingSection.scss';
import CompareToFundsTooltip from './CompareToFundsTooltip';

class EditComparingSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: false,
			reportId: 0,
			containerId: 0,
			templateId: 0,
			templateName: '',
            templateData: '',
            fundShareclassId: "",
			disableCurrency: false
		};
	}
	checkMILSpecificFunds(fifFundName){
		if(fifFundName && Constant.milCurrencySpecificFunds.find(x=>x.fund == fifFundName) !== undefined) {
			this.setState({						
				disableCurrency: true
			});
		} else{
			this.setState({						
				disableCurrency: false
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {

		if (nextProps.templateData.response !== this.props.templateData.response) {
			const templateDataset = nextProps.templateData.response;
			const reportId = templateDataset.reportId;
			const containerId = templateDataset.containerId;
			let tempData = this.props.isFromPortfolio === true ?
				(templateDataset.templateDataPortfolio ? templateDataset.templateDataPortfolio : templateDataset.templateData) :
				templateDataset.templateData;
			const templateData = JSON.parse(tempData);
			const templateId = templateDataset.templateId;
			const templateName = templateDataset.templateName;
			templateData.id = getRandomNumber();
			if(this.props.isInternationalUser && !this.props.isFromPortfolio){
			this.checkMILSpecificFunds(templateData.fifFundName);
			}
			this.setState({
				reportId: reportId,
				containerId: containerId,
				templateId: this.props.isFromPortfolio ? this.props.editComparisonTemplateId  : templateId,
				templateName: this.props.isFromPortfolio ? this.props.editComparisonTemplateName : templateName,
				templateData: templateData
			});
		}
	}
	
	componentDidMount() {
		if (this.props.templateData) {
			const templateDataset = this.props.templateData.response;
			const reportId = templateDataset.reportId;
			const containerId = templateDataset.containerId;
			const templateData = JSON.parse(this.props.isFromPortfolio === true ? templateDataset.templateDataPortfolio: templateDataset.templateData);
			const templateId = templateDataset.templateId;
			const templateName = templateDataset.templateName;
			if(this.props.isInternationalUser && !this.props.isFromPortfolio){
			this.checkMILSpecificFunds(templateData.fifFundName);
			}
			this.setState({
				reportId: reportId,
				containerId: containerId,
				templateId: this.props.isFromPortfolio ? this.props.editComparisonTemplateId  : templateId,
				templateName: this.props.isFromPortfolio ? this.props.editComparisonTemplateName : templateName,
				templateData: templateData
			});
		}
	}

	closeModal = () => {
		this.props.closeSelectFundModal();
	}

	getComparingSectionLabel = () => {
		if(this.props.isInternationalUser) {
			if(this.props.isFromPortfolio) {
				return <label title={this.props.funds} style={{'-webkit-box-orient': 'vertical'}} className={'selectedPortfoliosMil'}> {this.props.funds} </label>
			} else {
				return (
					<div className={'div-fund-data'}>
						<div className={'currency'}> {this.props.currency + ', '} </div>
						<div className={'fundInFocus'}><CompareToFundsTooltip fifFundName={this.props.fifFundName} nonFifFundName={this.props.nonFifFundName}/> </div>
					</div>
				)
			}
		} else {
			return <label className={(this.props.isFromPortfolio ? 'selectedPortfolios': 'selectedFunds')}> {this.props.funds} </label>
		}		
	}

    setFundShareclassId = (argFundShareclassId) => {
       this.setState({ fundShareclassId: argFundShareclassId });
    }

	render() {
		return (
			<div className={'leftRailEditSelection'}>
				<div className={'leftRailFirst'}>
					<div className={'labelHead'}>
						{ this.props.isFromPortfolio === true ? 'Comparison Details': 'Comparing' }
					</div>
					<div className={'linkEdit'}>
						{/* <ComparingSection /> */}
						<a role="link"
							onKeyDown={this.props.openSelectFundModal}
							tabIndex={0} onClick={this.props.openSelectFundModal} className={'linkEdit'}>Edit</a>
						{ this.props.isFromPortfolio === true ? 
						<SelectPortfolioFundsModal
								reportId={this.state.reportId}
								containerId={this.state.containerId}
								templateData={this.state.templateData}
								editComparisonTemplateId ={this.state.templateId}
								editComparisonTemplateName={this.state.templateName}
								modalIsOpen={this.props.isSelectFundModalOpen}
								isResetDropdowns={this.props.isResetDropdowns}
								modalIsPortfolioCompare={this.props.modalIsPortfolioCompare}
								showAddAllTooltip={this.props.showAddAllTooltip}
								isShowBenchmarkResults= {this.props.isShowBenchmarkResults}
								startNewFundComparison={this.props.startNewFundComparison}
								showBenchmarksModal={this.props.showBenchmarksModal}
								showHypotheticalsModal = {this.props.showHypotheticalsModal}
								showHypotheticals = {this.props.showHypotheticals}
								closeModal={this.props.closeSelectFundModal}
								comparisonPortfolioBenchmarkResult={this.props.comparisonPortfolioBenchmarkResult}
								portfolioNamesList={this.props.portfolioNamesList}
								comparsionWorkingContainerIds={this.props.comparsionWorkingContainerIds}
								comparisonContainerIds={this.props.comparisonContainerIds}
								balanceInitial= {this.props.balanceInitial}
								updatePortfolioNameList = {this.props.updatePortfolioNameList}
								isFromPortfolio={this.props.isFromPortfolio}
								hypotheticalStatePvP = {this.props.hypotheticalStatePvP}
								isFromModuleSelection = {this.props.isFromModuleSelection}
								oldestInceptionDatesPvP = {this.props.oldestInceptionDatesPvP}
								resetModuleSelectionFlag = {this.props.resetModuleSelectionFlag}
								callMaintenace={this.props.callMaintenace}
								fundShareclassId={this.state.fundShareclassId}
								setFundShareclassId={this.setFundShareclassId}
							/>: 
							<SelectFundsModal
								modalIsOpen={this.props.isSelectFundModalOpen}
								templateId={this.state.templateId}
								templateName={this.state.templateName}
								containerId={this.state.containerId}
								reportId={this.state.reportId}
								templateData={this.state.templateData}
								closeModal={this.closeModal}
								callMaintenace={this.props.callMaintenace}
								isComingFromModuleSelection = {true}
								fundShareclassId={this.state.fundShareclassId}
								setFundShareclassId={this.setFundShareclassId}
								disableCurrency = {this.state.disableCurrency}
							/>}

					</div>
					{/* {this.props.isInternationalUser ?
					<label className={(this.props.isFromPortfolio ? 'selectedPortfolios': 'selectedFunds')}> {this.props.isFromPortfolio ? this.props.funds : this.props.currency + ', '} <CompareToFundsTooltip fifFundName={this.props.fifFundName}/> </label>
					: <label className={(this.props.isFromPortfolio ? 'selectedPortfolios': 'selectedFunds')}> {this.props.funds} </label>
					} */}
					{this.getComparingSectionLabel()}
				</div>
			</div>
		);
	}
}

EditComparingSection.PropTypes = {
	funds: PropTypes.string,
	isFromModuleSelection: PropTypes.func,
	resetModuleSelectionFlag: PropTypes.func,
	callMaintenace: PropTypes.func
};

export default EditComparingSection;

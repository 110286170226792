import React from 'react';

const ScreenerIcon = () => {
    return (
        <svg width="59px" height="28px" viewBox="0 0 59 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Screen Funds</title>
            <g id="Icon/Screener" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g>
                    <g id="Group-19" transform="translate(0.000000, 17.000000)" fill="#BA0C30" font-family="Calibri-Light, Calibri" font-size="10" font-weight="300">
                        <text id="Fund-Screener">
                            <tspan x="0" y="8">Screen Funds</tspan>
                        </text>
                    </g>
                    <g id="filter_list_24px" transform="translate(21.000000, 0.000000)" fill="#7E2D40" fill-rule="nonzero">
                        <g id="Icon/Filter">
                            <path d="M7,12 L11,12 L11,10 L7,10 L7,12 Z M0,0 L0,2 L18,2 L18,0 L0,0 Z M3,7 L15,7 L15,5 L3,5 L3,7 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ScreenerIcon;

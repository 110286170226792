import { connect } from 'react-redux';

import FundInternationalHome from './FundInternationalHome';

import * as actions from '../../actions/fundComparisonActions.js';

function mapStateToProps(state) {
	return {
		templates: state.fundComparisonReducer.templatesResult,
		pdfOutput: state.fundComparisonReducer.pdfOutput,
		deleteTemplateDetails: state.fundComparisonReducer.deleteTemplateDetails,
		template: state.fundComparisonReducer.templateResult,
		TemplateId: state.fundComparisonReducer.TemplateId,
		TemplateName: state.fundComparisonReducer.TemplateName,
		errorCode: state.fundComparisonReducer.errorCode,
		mfsUSFvFinactive: state.fundComparisonReducer.mfsUSFvFinactive,
    	mfsUSPvPinactive: state.fundComparisonReducer.mfsUSPvPinactive,
    	mfsMILFvFinactive: state.fundComparisonReducer.mfsMILFvFinactive,
    	mfsMILPvPinactive: state.fundComparisonReducer.mfsMILPvPinactive,
		siteInactiveId: state.fundComparisonReducer.siteInactiveId,
		mfsUserData: state.fundComparisonReducer.mfsUserData,
        userType: state.fundComparisonReducer.userType,
		xref: state.fundComparisonReducer.xref,
		xrefETF: state.fundComparisonReducer.xrefETF,
		xrefFundDetails: state.fundComparisonReducer.xrefFund,
		fundAsOfDateFromScreenerData: state.fundComparisonReducer.screenerFundPerdormanceAsOfDate,
		screenerPerfDateId: state.fundComparisonReducer.screenerPerfDateId
	};
}

function mapDispatchToProps(dispatch) {
	return {
		requestGetTemplatesApi: (reportType) => {
			return dispatch(actions.requestGetTemplatesApi(reportType));
		},
		requestGetTemplatesByIdApi: (templateId, reportType) => {
			return dispatch(actions.requestGetTemplatesByIdApi(templateId, reportType));
		},
		requestGeneratePDFApi: inputParams => {
			return dispatch(actions.requestFundComparisonPdf(inputParams));
		},
		requestDeleteTemplateApi: templateId => {
			return dispatch(actions.requestDeleteTemplateApi(templateId));
		},
		updateTemplateInternalDetails: inputs => {
			return dispatch(actions.updateTemplateInternalDetails(inputs));
		},
		requestMfsSiteInactiveFlag: inputs => {
			return dispatch(actions.requestMfsSiteInactiveFlag());
		},
		requestMfsUserData: () => {
			return dispatch(actions.requestMfsUserData());
		},
		setUserType: result => {
			return dispatch(
				actions.setUserType(result));
        },
        requestXrefByTerm: (term) => {
            return dispatch(actions.requestXrefByTerm(term))
		},
		requestXrefByTermETF: (term) => {
            return dispatch(actions.requestXrefByTermETF(term))
		},
		requestFundLevelIds: (xids) => {
			return dispatch(actions.requestXrefByXids(xids))
		},
		requestScreenerGetFundPerformanceAsOfDateApi: (screenerType, screenerCriteria, isInternationalUser, currency) => {
			return dispatch(
				actions.requestScreenerGetFundPerformanceAsOfDateApi(screenerType, screenerCriteria, isInternationalUser, currency)
			);
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FundInternationalHome);

import React from 'react';

const ClosedArrow = props => {
	return (
		<span role="presentation" className={'add'} >
			<svg width="7" height="13" xmlns="http://www.w3.org/2000/svg">
				<title>Icon/Expanded</title>
				<desc>Created with Sketch.</desc>
				<g>
					<title>background</title>
					<rect fill="none" id="canvas_background" height="15" width="9" y="-1" x="-1" />
				</g>
				<g>
					<title>Layer 1</title>
					<g transform="rotate(-90.88566589355469 3.50001311302185,6.49998378753662) " fill-rule="evenodd" fill="none" id="Icon/Expanded">
						<g id="Path" fill-rule="nonzero" fill="#7E2D40">
							<polygon id="svg_1" points="8.094012394547462,2.9999845027923584 9.500012055039406,4.328907012939453 3.500012770295143,9.999983787536621 -2.4999860376119614,4.328907012939453 -1.0939869731664658,2.9999845027923584 3.500012770295143,7.34213924407959 " />
						</g>
					</g>
				</g>
			</svg>
		</span >
	);
};

export default ClosedArrow;

import React from 'react';
import { PropTypes } from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Tooltip } from 'react-bootstrap';

import * as utilityFunctions from "../../../utils/commonMethods";

import SelectPortfolioDropDown from "../../portfolio/selectPortfolioFund/subComponents/selectPortfolioDropDown";

import fundResultTableStyles from '../../../styles/components/FundResultsTable.scss';
import CompareButtonIcon from '../../common/Icons/CompareButtonIcon';
import FiveStarRatingIcon from '../../common/Icons/FiveStarRatingIcon';
import FourStarRatingIcon from '../../common/Icons/FourStarRatingIcon';
import ThreeStarRatingIcon from '../../common/Icons/ThreeStarRatingIcon';
import TwoStarRatingIcon from '../../common/Icons/TwoStarRatingIcon';
import OneStarRatingIcon from '../../common/Icons/OneStarRatingIcon';
import ModuleCheckIcon from "../../common/Icons/ModuleCheckIcon";

import closeSVG from '../../../images/Icon/Close.svg';

class FundResultsTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			defaultText: ' ',
		};
		this.mfsFundFamilyArr = ['mfs', 'mfs investment funds', 'mfs investment management company'] 
	}
	comparebutton = (cell, row, portIndex) => {
		const rowisActive = portIndex == 1 ? row.isActive : row.isActiveP2;
		//while comparing we are ignoring " (Lux) S.Ã .r.l", since this contains unicode characters
		const fundFamilyName = row.MSDFundFamilyName.length > 33 ? row.MSDFundFamilyName.substring(0,33) : row.MSDFundFamilyName 
		const symbol = this.props.isInternationalUser ? row.MFSSymbolISIN : row.MFSSymbol
		const _portfolio = portIndex == 1 ? 'P1': 'P2'
		let flag = 'OtherFund'
		if (!this.props.isPortfolio) {
			if(this.mfsFundFamilyArr.includes(fundFamilyName.toLowerCase())) {
				flag = 'MFS'
			} else if(row.MSDFundFamilyName === 'Benchmark'){
				flag = 'Benchmark' 
			}
		}	
		const html = (
			<div
				className={'add-icon'}
				onClick={this.props.toggleCompareButton}
				// eslint-disable-next-line no-nested-ternary
				data-flag={flag}
				data-symbol={symbol}
				data-symbolO={row.MFSSymbol}
				data-wsodIssue={row.MSDWSODIssue}
				data-fundShareClassId={row.MSDFundShareClassID}
				data-isin={row.MFSSymbolISIN}
				data-fundName={row.MSDFundName}
				data-baseCurrency={row.MSDBaseCurrencyID}
				data-portfolio={_portfolio}
			>
				{rowisActive ? <ModuleCheckIcon /> : <CompareButtonIcon />}
			</div >
		);

		return html;
	}

	removeButton = (cell, row) => {
		const html = (
			<div>
			<div data-symbol={this.props.isInternationalUser ? row.MFSSymbolISIN : row.MFSSymbol}
			data-wsodIssue={row.MSDWSODIssue}></div>
				<img className={'close-image'} src={closeSVG}
					onClick={this.props.removeSearchedFund}
				/>
			</div>
		);

		return html;
	}


	overallRatingColumn = (cell, row) => {
		let _customComponent = null;
		switch (cell) {
			case 5:
				_customComponent = <FiveStarRatingIcon />;
				break;

			case 4:
				_customComponent = <FourStarRatingIcon />;
				break;

			case 3:
				_customComponent = <ThreeStarRatingIcon />;
				break;

			case 2:
				_customComponent = <TwoStarRatingIcon />;
				break;

			case 1:
				_customComponent = <OneStarRatingIcon />;
				break;

			default:
				_customComponent = '--';
				break;

		}

		const html = (
			<div
				className={_customComponent === '--' ? '' : 'rating-star'}
			>
				{_customComponent}
			</div>
		);
		return html;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.screenerData &&
			nextProps.screenerData.rows
		) {
			this.setState({ defaultText: 'No results found, please search again!' });
		} else {
			if (!this.props.isPortfolio) {
			let emptyDataText = 'Please use the search bar or filter criteria to find funds and ETFs';
			this.setState({ defaultText: emptyDataText });
			} else {
				let emptyDataText = (this.props.isPortfolio && this.props.isPortfolioCompare) ? 'To begin a comparison please select a portfolio from the dropdown or search for a fund or ETF' : 'To begin search for a fund or ETF';
				this.setState({ defaultText: emptyDataText });
			}
		}


	}
	componentDidMount() {
		if (
			this.props.screenerData &&
			this.props.screenerData.rows
		) {
			this.setState({ defaultText: 'No results found, please search again!' });
		} else {
			if (!this.props.isPortfolio) {
				let emptyDataText = 'Please use the search bar or filter criteria to find funds and ETFs';
				this.setState({ defaultText: emptyDataText });
			} else {
					let emptyDataText = (this.props.isPortfolio && this.props.isPortfolioCompare) ? 'To begin a comparison please select a portfolio from the dropdown or search for a fund or ETF' : 'To begin search for a fund or ETF';
					this.setState({ defaultText: emptyDataText });
				}
			}
	}
	
	columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
		// fieldValue is column value
		// row is whole row object
		// rowIdx is index of row
		// colIdx is index of column

		switch (colIdx) {
			case 6:
				return 'result-table-fund-name';
			case 5:
				return 'result-table-fund-name';
			case 4:
				return 'result-table-star-rating';
			case 3:
				return 'default-column-width';
			case 2:
				return 'default-column-width';
			case 1:
				return 'result-table-fund-name';

			default: return 'default-column-width'
		}

	}

	dateFormatter(msDate) {
		if (!msDate) {
			return "--";
		}
		return utilityFunctions.convertFromOADate(msDate);
	}

	// Returns date for international user
	dateFormatterInt(msDate) {
		if (!msDate) {
			return "--";
		}
		return utilityFunctions.formatOADate(msDate, "DD MMM, YYYY");
	}

	render() {
		let data = this.props.resetScreenerFilters ? [] : this.props.screenerData;
		// Adding ISIN and Base Currency for testing
		this.props.isInternationalUser && data.forEach(item => {
			item.MSDBaseCurrencyID = item.MSDBaseCurrencyID ? item.MSDBaseCurrencyID.replace("CU$$$$$", "") : "USD";
		})
		
		const resultTableCount = this.props.screenerData.filter(x => x.isActive === true).length;
		let resultTableP2Count = 0;
		if (this.props.screenerData.filter(x => x.isActiveP2)) {
			resultTableP2Count = this.props.screenerData.filter(x => x.isActiveP2).length;
		}		
		return (			
			<div style={{position:'relative'}}>
				{ this.props.isInternationalUser ? 
				// International User
				<div className={this.props.isPortfolioCompare ? 'tableData portfolio-comparison':'tableData'}>
					<BootstrapTable
						data={data}
						options={{noDataText:this.state.defaultText}}
						containerClass='fundResultsTableStyles'
						maxHeight={'400px'}
					>
						{!this.props.isPortfolio ?
							<TableHeaderColumn
								isKey
								dataField="comparebutton"
								dataFormat={(cell, row) => this.comparebutton(cell, row, 1)}
								columnClassName="tdcompareStyle"
								width="9%"
							> Compare </TableHeaderColumn>
							: <TableHeaderColumn
								isKey
								dataField="comparebutton"
								dataFormat={(cell, row) => this.comparebutton(cell, row, 1)}
								columnClassName="tdcompareStyle"
								width={this.props.isPortfolioCompare ? '13%':'10%'}
							>
							{this.props.isPortfolioCompare ?
								<SelectPortfolioDropDown
									portfoliosNameList={this.props.portfolioNamesList}
									defaultSelectedPortfolioOneText={this.props.defaultSelectedPortfolioOneText }
									portfolioType="P1"
									getTemplateForSelectedPortfolio={this.props.getTemplateForSelectedPortfolio}
									removeUndoNotifications = {this.props.removeUndoNotifications}
								>
								</SelectPortfolioDropDown>
								: <span>Add to Portfolio </span>}
							
						<div className={'add-All-div'}>
									<span id="count-result" className="count-result" >{resultTableCount}/20</span>
									<a role="link"
										onKeyDown={e => this.props.addAllFunds(1)}
										id={'add-All'}
										tabIndex={0} onClick={e => this.props.addAllFunds(1)} className={'add-All'}>Add All</a>
										{this.props.showAddAllTooltipP1 ? <div data-tooltip = {'close'} className={this.props.isPortfolio ?'custom-tooltip1' : 'custom-tooltip'}><Tooltip placement="right" className="in" id="tooltip-right">
										<div data-tooltip = {'close'} style={{position:'relative'}}>
											<div data-tooltip = {'close'} className={'error-message'}>The funds in your list exceed the maximum allowed. The first 20 funds have been added to this portfolio.</div>
											<div className={'closeTooltip'} data-portfolio = {'P1'} onClick={this.props.closeTooltip}>X</div>
										</div>
									</Tooltip></div> : ''}
								</div> </TableHeaderColumn>}

						{(this.props.isPortfolio && this.props.isPortfolioCompare) ?
							<TableHeaderColumn
								dataField="comparebutton"
								dataFormat={(cell, row) => this.comparebutton(cell, row, 2)}
								columnClassName="tdcompareStyle"
								width="13%"
							>
								<SelectPortfolioDropDown
									portfoliosNameList={this.props.portfolioNamesList}
									defaultSelectedPortfolioTwoText={this.props.defaultSelectedPortfolioTwoText}
									portfolioType="P2"
									getTemplateForSelectedPortfolio={this.props.getTemplateForSelectedPortfolio}
									removeUndoNotifications = {this.props.removeUndoNotifications}
								>
								</SelectPortfolioDropDown>
						<div className={'add-All-div2'}>
									<span id="count-result" className="count-result" >{resultTableP2Count}/20</span>
									<a role="link"
										onKeyDown={e => this.props.addAllFunds(2)}
										id={'add-All2'}
										tabIndex={0}
										onClick={e => this.props.addAllFunds(2)} 
										className={'add-All'}>Add All</a>
										{this.props.showAddAllTooltipP2 ? <div data-tooltip = {'close'} className={'custom-tooltip'}><Tooltip placement="right" className="in" id="tooltip-right">
										<div data-tooltip = {'close'} style={{position:'relative'}}>
											<div data-tooltip = {'close'} className={'error-message'}>The funds in your list exceed the maximum allowed. The first 20 funds have been added to this portfolio.</div>
											<div className={'closeTooltip'} data-portfolio = {'P2'} onClick={this.props.closeTooltip}>X</div>
										</div>
									</Tooltip> </div>: ''}
								</div> </TableHeaderColumn>

							: null}
						<TableHeaderColumn dataField="MSDFundName" width={this.props.isPortfolioCompare ? '15%':'18%'}
							columnClassName={'result-table-fund-name'} 
						>
							{this.props.fundtype.includes('Funds') ? 'Fund Name' : 'ETF Name'}
						</TableHeaderColumn>
						
						<TableHeaderColumn dataField="MFSSymbolISIN" width="13%"
						>
							ISIN
						</TableHeaderColumn>
						<TableHeaderColumn dataField="MSDBaseCurrencyID" width="10%"
						>
							Base <br /> Currency
						</TableHeaderColumn>

						<TableHeaderColumn dataField="PerformanceStartDate" width="10%"
							dataFormat={this.dateFormatterInt}
						>
							Performance<br/>
							Start Date
						</TableHeaderColumn>
						{
							this.props.isInternationalUser  ? <TableHeaderColumn dataField="MSDKIIDOngoingCharge"
							width="10%"
							columnClassName={'result-table-fund-name'}
							>
								KIID Ongoing<br/>Charge
							</TableHeaderColumn> : 
							<TableHeaderColumn dataField="MSDMorningstarOverallRating"
							dataFormat={this.overallRatingColumn}
							width="10%"
							columnClassName={'result-table-fund-name'}
							>
								Morningstar <br /> Overall Rating
							</TableHeaderColumn>
						}
						<TableHeaderColumn dataField="MSDMorningstarCategoryName" width={this.props.isPortfolioCompare ? '11%':'13%'}
							columnClassName={'result-table-fund-name'}
						>
							Morningstar <br />Category
						</TableHeaderColumn>
						<TableHeaderColumn
							dataField="removeButton"
							dataFormat={this.removeButton}
							columnClassName="close-icon"
							width="4%"
						>
						</TableHeaderColumn>
					</BootstrapTable>
				</div> : 
				// Normal User
				<div className={this.props.isPortfolioCompare ? 'tableData portfolio-comparison':'tableData'}>
					<BootstrapTable
						data={this.props.resetScreenerFilters ? [] : data}
						options={{
							noDataText:
								this.state.defaultText
						}}
						containerClass='fundResultsTableStyles'
						maxHeight={'400px'}
					>
						{!this.props.isPortfolio ?
							<TableHeaderColumn
								isKey
								dataField="comparebutton"
								dataFormat={(cell, row) => this.comparebutton(cell, row, 1)}
								columnClassName="tdcompareStyle"
								width="15%"
							> Compare </TableHeaderColumn>
							: <TableHeaderColumn
								isKey
								dataField="comparebutton"
								dataFormat={(cell, row) => this.comparebutton(cell, row, 1)}
								columnClassName="tdcompareStyle"
								width="15%"
							>
							{this.props.isPortfolioCompare ?
								<SelectPortfolioDropDown
									portfoliosNameList={this.props.portfolioNamesList}
									defaultSelectedPortfolioOneText={this.props.defaultSelectedPortfolioOneText }
									portfolioType="P1"
									getTemplateForSelectedPortfolio={this.props.getTemplateForSelectedPortfolio}
									removeUndoNotifications = {this.props.removeUndoNotifications}
								>
								</SelectPortfolioDropDown>
								: <span>Add to Portfolio </span>}
							
						<div className={'add-All-div'}>
									<span id="count-result" className="count-result" >{resultTableCount}/20</span>
									<a role="link"
										onKeyDown={e => this.props.addAllFunds(1)}
										id={'add-All'}
										tabIndex={0} onClick={e => this.props.addAllFunds(1)} className={'add-All'}>Add All</a>
										{this.props.showAddAllTooltipP1 ? <div data-tooltip = {'close'} className={this.props.isPortfolio ?'custom-tooltip1' : 'custom-tooltip'}><Tooltip placement="right" className="in" id="tooltip-right">
										<div data-tooltip = {'close'} style={{position:'relative'}}>
											<div data-tooltip = {'close'} className={'error-message'}>The funds in your list exceed the maximum allowed. The first 20 funds have been added to this portfolio.</div>
											<div className={'closeTooltip'} data-portfolio = {'P1'} onClick={this.props.closeTooltip}>X</div>
										</div>
									</Tooltip></div> : ''}
								</div> </TableHeaderColumn>}

						{(this.props.isPortfolio && this.props.isPortfolioCompare) ?
							<TableHeaderColumn
								dataField="comparebutton"
								dataFormat={(cell, row) => this.comparebutton(cell, row, 2)}
								columnClassName="tdcompareStyle"
								width="15%"
							>
								<SelectPortfolioDropDown
									portfoliosNameList={this.props.portfolioNamesList}
									defaultSelectedPortfolioTwoText={this.props.defaultSelectedPortfolioTwoText}
									portfolioType="P2"
									getTemplateForSelectedPortfolio={this.props.getTemplateForSelectedPortfolio}
									removeUndoNotifications = {this.props.removeUndoNotifications}
								>
								</SelectPortfolioDropDown>
						<div className={'add-All-div2'}>
									<span id="count-result" className="count-result" >{resultTableP2Count}/20</span>
									<a role="link"
										onKeyDown={e => this.props.addAllFunds(2)}
										id={'add-All2'}
										tabIndex={0}
										onClick={e => this.props.addAllFunds(2)} 
										className={'add-All'}>Add All</a>
										{this.props.showAddAllTooltipP2 ? <div data-tooltip = {'close'} className={'custom-tooltip'}><Tooltip placement="right" className="in" id="tooltip-right">
										<div data-tooltip = {'close'} style={{position:'relative'}}>
											<div data-tooltip = {'close'} className={'error-message'}>The funds in your list exceed the maximum allowed. The first 20 funds have been added to this portfolio.</div>
											<div className={'closeTooltip'} data-portfolio = {'P2'} onClick={this.props.closeTooltip}>X</div>
										</div>
									</Tooltip> </div>: ''}
								</div> </TableHeaderColumn>

							: null}

						<TableHeaderColumn dataField="MFSSymbol" width="10%"
							columnClassName={this.columnClassNameFormat}
						>
							Symbol
						</TableHeaderColumn>
						<TableHeaderColumn dataField="MSDFundName" width="27%"
							columnClassName={this.columnClassNameFormat}
						>
							{this.props.fundtype.includes('Funds') ? 'Fund Name' : 'ETF Name'}
						</TableHeaderColumn>
						
						<TableHeaderColumn dataField="MSDInceptionDate" width="14%"
							dataFormat={this.dateFormatter}
						>
							Inception Date
						</TableHeaderColumn>
						<TableHeaderColumn dataField="MSDMorningstarOverallRating"
							dataFormat={this.overallRatingColumn}
							width="16%"
							columnClassName={this.columnClassNameFormat}
						>
							Morningstar Overall <br /> Rating
						</TableHeaderColumn>
						<TableHeaderColumn dataField="MSDMorningstarCategoryName" width="20%"
							columnClassName={this.columnClassNameFormat}
						>
							Morningstar Category
						</TableHeaderColumn>
						<TableHeaderColumn
							dataField="removeButton"
							dataFormat={this.removeButton}
							columnClassName="close-icon"
							width="5%"
						>

						</TableHeaderColumn>
					</BootstrapTable>
				</div>
				}
			</div >
		);
	}
}

FundResultsTable.PropTypes = {
	fundtype: PropTypes.string,
	screenerData: PropTypes.object,
	resetScreenerFilters: PropTypes.boolean,
	removeSearchedFund: PropTypes.func,
	addAllFunds: PropTypes.func,
	isPortfolio: PropTypes.bool,
	isPortfolioCompare: PropTypes.bool,
	searchBarHeight:PropTypes.number,
	closeTooltip: PropTypes.func,
	getTemplateForSelectedPortfolio: PropTypes.func,
	removeUndoNotifications: PropTypes.func
};

export default FundResultsTable;

import React from 'react';
import { PropTypes } from 'prop-types';
import Select, { components } from 'react-select';

import { reportTypes } from "../../../../utils/constants";

export default class selectPortfolioDropDown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplateValue: -1,
            portfolioList: []
        }
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            portfolioList: nextProps.portfoliosNameList
        });
    }

    onSelectedPortfolioName = (templateId, templateName) => {
        const value = templateId.value;
        if (templateName.name === "P1") {
            this.setState({
                selectedTemplateValue: value
            });
        }
        if (templateName.name === "P2") {
            this.setState({
                selectedTemplateValue: value
            });
        }

        if (!templateId.label.indexOf("Select") > -1) {
            this.props.getTemplateForSelectedPortfolio(value, templateName.name, templateId.label);
            this.props.removeUndoNotifications();
        }
    }

    render() {
        const customTheme = {
            borderRadius: 0,
            border: 0,
            colors: {
                primary: '#f4f4f4',
                primary50: '#f4f4f4',
                neutral0: '#FFF',
            },
        }
        const customStyles = {
            control: (base, state) => ({
                ...base,
                border: '1px solid rgba(34,36,38,.15) !important',
                boxShadow: '0 !important',
                '&:hover': {
                    border: '1px solid rgba(34,36,38,.15) !important'
                },
                height: '34px',
                'min-height': '34px'
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isSelected ? '#f4f4f4' : (state.isFocused ? '#f4f4f4' : '#FFF'),
                color: '#333',
                'font-weight': state.isSelected ? 'bold' : 'normal'
            }),
            menu: styles => ({ ...styles, margin: '0px' }),
            noOptionsMessage: styles => ({ ...styles, 'text-align': 'left', color: 'rgba(0,0,0,.4)' })
        }

        const DropdownIndicator = props => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <i
                            className="dropdown"
                            aria-hidden="true"
                        />
                    </components.DropdownIndicator>
                )
            );
        };
        const portfolioNameList =[];

        this.state.portfolioList && this.state.portfolioList.map((item, index) => (
            portfolioNameList.push({ label: item.templateName, value: item.templateId })
        ));
        return (<div className={'filter-styles'}><div className="label-style">
            <Select
                name={this.props.portfolioType}
                className="dropdown-mfs"
                theme={customTheme}
                styles={customStyles}
                isSearchable={true}
                defaultValue={this.props.portfolioType === "P1" ? this.props.defaultSelectedPortfolioOneText : this.props.defaultSelectedPortfolioTwoText}
                value={{ label: this.props.portfolioType === "P1" ? this.props.defaultSelectedPortfolioOneText : this.props.defaultSelectedPortfolioTwoText, value: this.state.selectedTemplateValue }}
                onChange={this.onSelectedPortfolioName}
                options={portfolioNameList}
                components={{ DropdownIndicator }}
                noOptionsMessage={() => "No results found."}
                id={"ddPortfoliotype" + this.props.portfolioType}
                data-portfolio-type={this.props.portfolioType} />
        </div>
        </div>
        )
    }
}

selectPortfolioDropDown.propTypes = {
    defaultSelectedPortfolioText: PropTypes.string,
    portfoliosNameList: PropTypes.object,
    portfolioType: PropTypes.string,
    getTemplateForSelectedPortfolio: PropTypes.func,
    removeUndoNotifications: PropTypes.func
};


import React from 'react';
import PropTypes from 'prop-types';

import remove from '../../../images/Remove.jpg';

export default class TabularSelectedFunds extends React.Component {
	render() {
		return (
			<div>
				<table className="table table-bordered">
					<thead>
						<tr>
							<th style={{ width: '30%', textAlign: 'center' }}>Symbol</th>
							<th style={{ width: '50%', textAlign: 'center' }}>Name</th>
							<th>Action</th>
						</tr>
					</thead>

					<tbody>
						{
							this.props.products.map((item, index) => (
								<tr>
									<td style={{ width: '30%', textAlign: 'center' }}>{item.symbol}</td>
									<td style={{ width: '50%', textAlign: 'center' }}>{item.name}</td>
									<td data-key={item.symbol} style={{ textAlign: 'center' }} onClick={this.props.triggerUpdateList}><img src={remove}/></td>
								</tr>
							)
							)
						}

					</tbody>

				</table>
			</div>


		);

	}
}

TabularSelectedFunds.propTypes = { products: PropTypes.array };

import React from 'react';

const compareButtonIconStyles = {
	height: '20px',
	width: '20px',
	'margin-bottom': '5px'
};

const CompareButtonIcon = props => {
	return (
		<svg
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={compareButtonIconStyles}
		>
			<defs />
			<g
				id="Updates"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
			>
				<g
					id="Select-Funds-(All-Funds---Open-Filters)"
					transform="translate(-376.000000, -783.000000)"
				>
					<g id="Table" transform="translate(356.000000, 517.000000)">
						<g id="Table-">
							<g transform="translate(0.000000, 3.000000)" id="Compare">
								<g transform="translate(3.000000, 0.000000)">
									<g id="Icon/Add" transform="translate(17.000000, 263.000000)">
										<g id="Group-16-Copy-14">
											<g
												id="Group"
												transform="translate(5.000000, 5.000000)"
												fill="#7A0022"
											>
												<g id="Group-15">
													<rect
														id="Rectangle-12"
														x="4"
														y="0"
														width="2"
														height="10"
													/>
													<rect
														id="Rectangle-12-Copy"
														transform="translate(5.000000, 5.000000) rotate(90.000000) translate(-5.000000, -5.000000) "
														x="4"
														y="0"
														width="2"
														height="10"
													/>
												</g>
											</g>
											<circle
												id="Oval"
												stroke="#7A0022"
												stroke-width="2"
												cx="10"
												cy="10"
												r="9"
											/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default CompareButtonIcon;

// This is the root reducer in which all other reducers are registered.
// Naming convention is to use index.js
import { combineReducers } from 'redux';

import fundComparisonReducer from './fundComparisonDataReducer';
import portfolioComparisonReducer from './portfolioComparisonDataReducer';

const rootReducer = combineReducers({
	fundComparisonReducer,
	portfolioComparisonReducer
});

export default rootReducer;
